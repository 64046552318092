export const Songs = [
  {
    "artist": "Beyoncé",
    "song": "1 + 1",
    "year": "2011",
    "seconds": "50",
    "lyric": "When my days look low",
    "number": "1",
  },
  {
    "artist": "Feist",
    "song": "1, 2, 3, 4",
    "year": "2007",
    "seconds": "50",
    "lyric": "Oh, you’re changing your heart",
    "number": "2",
  },
  {
    "artist": "Britney Spears",
    "song": "3",
    "year": "2009",
    "seconds": "50",
    "lyric": "And I’m caught in between",
    "number": "3",
  },
  {
    "artist": "Dominic Fike",
    "song": "3 Nights",
    "year": "2018",
    "seconds": "60",
    "lyric": "Green lights in your body language",
    "number": "4",
  },
  {
    "artist": "Bruce Springsteen",
    "song": "4th of July, Asbury Park (Sandy)",
    "year": "1975",
    "seconds": "40",
    "lyric": "Full of switchblade lovers",
    "number": "5",
  },
  {
    "artist": "Prince",
    "song": "7",
    "year": "1992",
    "seconds": "40",
    "lyric": "An army’s marching feet",
    "number": "6",
  },
  {
    "artist": "Ariana Grande",
    "song": "7 Rings",
    "year": "2019",
    "seconds": "40",
    "lyric": "Look at my neck",
    "number": "7",
  },
  {
    "artist": "Dolly Parton",
    "song": "9 to 5",
    "year": "1980",
    "seconds": "50",
    "lyric": "And the blood starts pumpin’",
    "number": "8",
  },
  {
    "artist": "Rolling Stones",
    "song": "19th Nervous Breakdown",
    "year": "1966",
    "seconds": "60",
    "lyric": "Running up and down the stairs",
    "number": "9",
  },
  {
    "artist": "Green Day",
    "song": "21 Guns",
    "year": "2009",
    "seconds": "40",
    "lyric": "Lay down your arms",
    "number": "10",
  },
  {
    "artist": "Brothers Osborne",
    "song": "21 Summer",
    "year": "2016",
    "seconds": "60",
    "lyric": "Your hair blowing in the breeze",
    "number": "11",
  },
  {
    "artist": "Taylor Swift",
    "song": "22",
    "year": "2013",
    "seconds": "40",
    "lyric": "For breakfast at midnight",
    "number": "12",
  },
  {
    "artist": "Chicago",
    "song": "25 or 6 to 4",
    "year": "1970",
    "seconds": "60",
    "lyric": "Getting up to splash my face",
    "number": "13",
  },
  {
    "artist": "Ariana Grande",
    "song": "34 + 35",
    "year": "2020",
    "seconds": "70",
    "lyric": "We can take it from the top",
    "number": "14",
  },
  {
    "artist": "Paul Simon",
    "song": "50 Ways to Leave Your Lover",
    "year": "1976",
    "seconds": "50",
    "lyric": "Drop off the key, Lee",
    "number": "15",
  },
  {
    "artist": "Bruce Springsteen",
    "song": "57 Channels (And Nothin’ On)",
    "year": "1992",
    "seconds": "60",
    "lyric": "To hook up my cable TV",
    "number": "16",
  },
  {
    "artist": "? and the Mysterians",
    "song": "96 Tears",
    "year": "1966",
    "seconds": "60",
    "lyric": "For one heart to carry on",
    "number": "17",
  },
  {
    "artist": "Keith",
    "song": "98.6",
    "year": "1967",
    "seconds": "60",
    "lyric": "Good to have you back again",
    "number": "18",
  },
  {
    "artist": "Hedy West",
    "song": "500 Miles",
    "year": "1963",
    "seconds": "60",
    "lyric": "Not a shirt on my back",
    "number": "19",
  },
  {
    "artist": "James Blunt",
    "song": "1973",
    "year": "2007",
    "seconds": "50",
    "lyric": "In a club with you",
    "number": "20",
  },
  {
    "artist": "Smashing Pumpkins",
    "song": "1979",
    "year": "1996",
    "seconds": "60",
    "lyric": "June bug skipping like a stone",
    "number": "21",
  },
  {
    "artist": "Prince",
    "song": "1999",
    "year": "1982",
    "seconds": "70",
    "lyric": "I got a lion in my pocket",
    "number": "22",
  },
  {
    "artist": "Childish Gambino",
    "song": "3005",
    "year": "2013",
    "seconds": "50",
    "lyric": "Got so high off volcanoes",
    "number": "23",
  },
  {
    "artist": "Wilson Pickett",
    "song": "634-5789 (Soulsville, U.S.A.)",
    "year": "1966",
    "seconds": "60",
    "lyric": "Pick up your telephone and dial",
    "number": "24",
  },
  {
    "artist": "Logic (ft. Alessia Cara & Khalid)",
    "song": "1-800-273-8255",
    "year": "2017",
    "seconds": "60",
    "lyric": "My tears melt in the snow",
    "number": "25",
  },
  {
    "artist": "Jackson 5",
    "song": "ABC",
    "year": "1970",
    "seconds": "60",
    "lyric": "‘I’ before ‘E’ except after ‘C’",
    "number": "26",
  },
  {
    "artist": "Clint Black & Wynonna",
    "song": "A Bad Goodbye",
    "year": "1993",
    "seconds": "50",
    "lyric": "And the cloud I’m in",
    "number": "27",
  },
  {
    "artist": "The Rascals",
    "song": "A Beautiful Morning",
    "year": "1968",
    "seconds": "50",
    "lyric": "I think I’ll go outside",
    "number": "28",
  },
  {
    "artist": "Clint Black",
    "song": "A Better Man",
    "year": "1989",
    "seconds": "50",
    "lyric": "We’re rollin’ in the clover",
    "number": "29",
  },
  {
    "artist": "Elvis Presley",
    "song": "A Big Hunk O’ Love",
    "year": "1959",
    "seconds": "50",
    "lyric": "Starve me half to death",
    "number": "30",
  },
  {
    "artist": "Johnny Cash",
    "song": "A Boy Named Sue",
    "year": "1969",
    "seconds": "70",
    "lyric": "Daddy left home when I was three",
    "number": "31",
  },
  {
    "artist": "Perry Como and Betty Hutton",
    "song": "A Bushel and a Peck",
    "year": "1950",
    "seconds": "60",
    "lyric": "And a hug around the neck",
    "number": "32",
  },
  {
    "artist": "Sam Cooke",
    "song": "A Change is Gonna Come",
    "year": "1964",
    "seconds": "60",
    "lyric": "I was born by the river",
    "number": "33",
  },
  {
    "artist": "Hank Williams Jr.",
    "song": "A Country Boy Can Survive",
    "year": "1982",
    "seconds": "40",
    "lyric": "I can catch catfish",
    "number": "34",
  },
  {
    "artist": "Sonny & Cher",
    "song": "A Cowboy’s Work is Never Done",
    "year": "1972",
    "seconds": "60",
    "lyric": "I was so handsome, women cried",
    "number": "35",
  },
  {
    "artist": "The Beatles",
    "song": "A Day in the Life",
    "year": "1967",
    "seconds": "80",
    "lyric": "He blew his mind out in a car",
    "number": "36",
  },
  {
    "artist": "The Fontane Sisters and Perry Como",
    "song": "A Dreamer’s Holiday",
    "year": "1949",
    "seconds": "40",
    "lyric": "Climb aboard a butterfly",
    "number": "37",
  },
  {
    "artist": "George Strait",
    "song": "A Fire I Can’t Put Out",
    "year": "1983",
    "seconds": "70",
    "lyric": "A lighted candle burning up my mind",
    "number": "38",
  },
  {
    "artist": "Ray Charles",
    "song": "A Fool For You",
    "year": "1955",
    "seconds": "60",
    "lyric": "Reach over and feel the pillow",
    "number": "39",
  },
  {
    "artist": "George Jones",
    "song": "A Girl I Used to Know",
    "year": "1962",
    "seconds": "60",
    "lyric": "Her name has slipped my mind",
    "number": "40",
  },
  {
    "artist": "Cliff Richard and the Shadows",
    "song": "A Girl Like You",
    "year": "1961",
    "seconds": "50",
    "lyric": "Would you like to dance?",
    "number": "41",
  },
  {
    "artist": "Dallas Smith",
    "song": "A Girl Like You",
    "year": "2014",
    "seconds": "60",
    "lyric": "To catch you in his arms",
    "number": "42",
  },
  {
    "artist": "Easton Corbin",
    "song": "A Girl Like You",
    "year": "2017",
    "seconds": "60",
    "lyric": "Long tan legs and cut-off jeans",
    "number": "43",
  },
  {
    "artist": "Edwyn Collins",
    "song": "A Girl Like You",
    "year": "1994",
    "seconds": "50",
    "lyric": "And my knees are raw",
    "number": "44",
  },
  {
    "artist": "The Smithereens",
    "song": "A Girl Like You",
    "year": "1989",
    "seconds": "60",
    "lyric": "To get a message to you",
    "number": "45",
  },
  {
    "artist": "The Young Rascals",
    "song": "A Girl Like You",
    "year": "1967",
    "seconds": "60",
    "lyric": "I’m holdin’ you close to me",
    "number": "46",
  },
  {
    "artist": "Marion Harris",
    "song": "A Good Man is Hard to Find",
    "year": "1911",
    "seconds": "50",
    "lyric": "Hug him in the morning’",
    "number": "47",
  },
  {
    "artist": "John Legend (feat. BloodPop)",
    "song": "A Good Night",
    "year": "2018",
    "seconds": "50",
    "lyric": "Red stain on your lips",
    "number": "48",
  },
  {
    "artist": "Clint Black",
    "song": "A Good Run of Bad Luck",
    "year": "1994",
    "seconds": "50",
    "lyric": "When the chips are down",
    "number": "49",
  },
  {
    "artist": "George Jones",
    "song": "A Good Year For the Roses",
    "year": "1971",
    "seconds": "60",
    "lyric": "A half filled cup of coffee",
    "number": "50",
  },
  {
    "artist": "The Mindbenders",
    "song": "A Groovy Kind of Love",
    "year": "1966",
    "seconds": "50",
    "lyric": "The whole world could shatter",
    "number": "51",
  },
  {
    "artist": "The Beatles",
    "song": "A Hard Day’s Night",
    "year": "1964",
    "seconds": "40",
    "lyric": "So why on earth",
    "number": "52",
  },
  {
    "artist": "Bob Dylan",
    "song": "A Hard Rain’s a-Gonna Fall",
    "year": "1962",
    "seconds": "60",
    "lyric": "I’ve crawled on six crooked highways",
    "number": "53",
  },
  {
    "artist": "Simon & Garfunkel",
    "song": "A Hazy Shade of Winter",
    "year": "1966",
    "seconds": "60",
    "lyric": "Carry your cup in your hand",
    "number": "54",
  },
  {
    "artist": "Burl Ives",
    "song": "A Holly Jolly Christmas",
    "year": "1964",
    "seconds": "60",
    "lyric": "Say hello to friends you know",
    "number": "55",
  },
  {
    "artist": "America",
    "song": "A Horse with No Name",
    "year": "1972",
    "seconds": "50",
    "lyric": "And the ground was dry",
    "number": "56",
  },
  {
    "artist": "Gene McDaniels",
    "song": "A Hundred Pounds of Clay",
    "year": "1961",
    "seconds": "60",
    "lyric": "He rolled his big sleeves up",
    "number": "57",
  },
  {
    "artist": "Glen Campbell",
    "song": "A Lady Like You",
    "year": "1984",
    "seconds": "50",
    "lyric": "And I’m amazed every morning",
    "number": "58",
  },
  {
    "artist": "Dottie West",
    "song": "A Lesson in Leavin’",
    "year": "1980",
    "seconds": "50",
    "lyric": "Watch ’em knock you down",
    "number": "59",
  },
  {
    "artist": "Lee Roy Parnell",
    "song": "A Little Bit of You",
    "year": "1995",
    "seconds": "40",
    "lyric": "High wires, thin ice",
    "number": "60",
  },
  {
    "artist": "Anne Murray",
    "song": "A Little Good News",
    "year": "1983",
    "seconds": "60",
    "lyric": "Rolled up in a rubber band",
    "number": "61",
  },
  {
    "artist": "Elis Presley (Junkie XL)",
    "song": "A Little Less Conversation",
    "year": "2002",
    "seconds": "70",
    "lyric": "I’m tired of talking, grab your coat",
    "number": "62",
  },
  {
    "artist": "Toby Keith",
    "song": "A Little Less Talk and a Lot More Action",
    "year": "1993",
    "seconds": "50",
    "lyric": "She was peeling the label",
    "number": "63",
  },
  {
    "artist": "Toby Keith",
    "song": "A Little Too Late",
    "year": "2006",
    "seconds": "40",
    "lyric": "Water under the bridge",
    "number": "64",
  },
  {
    "artist": "The Counting Crows",
    "song": "A Long December",
    "year": "1996",
    "seconds": "60",
    "lyric": "The smell of hospitals in winter",
    "number": "65",
  },
  {
    "artist": "Anne Murray",
    "song": "A Love Song",
    "year": "1974",
    "seconds": "50",
    "lyric": "Rock you in my arms",
    "number": "66",
  },
  {
    "artist": "Brooks & Dunn",
    "song": "A Man This Lonely",
    "year": "1996",
    "seconds": "50",
    "lyric": "I was so far down",
    "number": "67",
  },
  {
    "artist": "Engelbert Humperdinck",
    "song": "A Man Without Love (Quando m’innamoro)",
    "year": "1968",
    "seconds": "50",
    "lyric": "I cry my heart out",
    "number": "68",
  },
  {
    "artist": "Kelly Clarkson",
    "song": "A Moment Like This",
    "year": "2002",
    "seconds": "70",
    "lyric": "You will catch me when I fall",
    "number": "69",
  },
  {
    "artist": "Dinah Shore and Tony Martin",
    "song": "A Penny a Kiss",
    "year": "1951",
    "seconds": "50",
    "lyric": "In a little round jug",
    "number": "70",
  },
  {
    "artist": "George Hamilton IV",
    "song": "A Rose and a Baby Ruth",
    "year": "1956",
    "seconds": "60",
    "lyric": "All I had in my jeans",
    "number": "71",
  },
  {
    "artist": "Porter Wagoner",
    "song": "A Satisfied Mind",
    "year": "1955",
    "seconds": "40",
    "lyric": "I lost every dime",
    "number": "72",
  },
  {
    "artist": "Coldplay",
    "song": "A Sky Full of Stars",
    "year": "2014",
    "seconds": "40",
    "lyric": "Die in your arms",
    "number": "73",
  },
  {
    "artist": "Anne Murray",
    "song": "A Stranger in My Place",
    "year": "1971",
    "seconds": "60",
    "lyric": "I see pain in your face",
    "number": "74",
  },
  {
    "artist": "Etta James",
    "song": "A Sunday Kind of Love",
    "year": "1960",
    "seconds": "50",
    "lyric": "To last past Saturday night",
    "number": "75",
  },
  {
    "artist": "Johnny Cash",
    "song": "A Thing Called Love",
    "year": "1971",
    "seconds": "50",
    "lyric": "Look you in the eye",
    "number": "76",
  },
  {
    "artist": "Dwight Yoakam",
    "song": "A Thousand Miles from Nowhere",
    "year": "1993",
    "seconds": "60",
    "lyric": "I got heartaches in my pocket",
    "number": "77",
  },
  {
    "artist": "Christina Perri",
    "song": "A Thousand Years",
    "year": "2011",
    "seconds": "60",
    "lyric": "Time stands still, beauty in all",
    "number": "78",
  },
  {
    "artist": "Charlie Rich",
    "song": "A Very Special Love Song",
    "year": "1974",
    "seconds": "60",
    "lyric": "Turn your radio way down low",
    "number": "79",
  },
  {
    "artist": "The Kinks",
    "song": "A Well Respected Man",
    "year": "1965",
    "seconds": "70",
    "lyric": "‘Cause he gets up in the morning",
    "number": "80",
  },
  {
    "artist": "Marty Robbins",
    "song": "A White Sport Coat",
    "year": "1957",
    "seconds": "70",
    "lyric": "I’m all dressed up for the dance",
    "number": "81",
  },
  {
    "artist": "Procol Harum",
    "song": "A Whiter Shade of Pale",
    "year": "1967",
    "seconds": "50",
    "lyric": "Turned cartwheels ‘cross the floor",
    "number": "82",
  },
  {
    "artist": "Peabo Bryson & Regina Belle",
    "song": "A Whole New World",
    "year": "1992",
    "seconds": "50",
    "lyric": "On a magic carpet ride",
    "number": "83",
  },
  {
    "artist": "David Houston",
    "song": "A Woman Always Knows",
    "year": "1971",
    "seconds": "50",
    "lyric": "No lipstick on his shirt",
    "number": "84",
  },
  {
    "artist": "Ronnie Milsap",
    "song": "A Woman in Love",
    "year": "1989",
    "seconds": "40",
    "lyric": "She’ll drive you wild",
    "number": "85",
  },
  {
    "artist": "Peter and Gordon",
    "song": "A World Without Love",
    "year": "1964",
    "seconds": "50",
    "lyric": "Birds sing out of tune",
    "number": "86",
  },
  {
    "artist": "The Kingston Trio",
    "song": "A Worried Man",
    "year": "1959",
    "seconds": "70",
    "lyric": "Got myself a Cadillac, thirty dollars down",
    "number": "87",
  },
  {
    "artist": "George Hamilton IV",
    "song": "Abilene",
    "year": "1963",
    "seconds": "50",
    "lyric": "Trains pull out of sight",
    "number": "88",
  },
  {
    "artist": "Buck Owens",
    "song": "Above and Beyond",
    "year": "1960",
    "seconds": "60",
    "lyric": "Carry you off and marry you",
    "number": "89",
  },
  {
    "artist": "The Counting Crows",
    "song": "Accidentally In Love",
    "year": "2004",
    "seconds": "50",
    "lyric": "Turn around and face me",
    "number": "90",
  },
  {
    "artist": "George Strait",
    "song": "Ace in the Hole",
    "year": "1989",
    "seconds": "60",
    "lyric": "Headed down a one way street",
    "number": "91",
  },
  {
    "artist": "Billy Ray Cyrus",
    "song": "Achy Breaky Heart",
    "year": "1992",
    "seconds": "70",
    "lyric": "Tell your dog to bite my leg",
    "number": "92",
  },
  {
    "artist": "Buck Owens",
    "song": "Act Naturally",
    "year": "1963",
    "seconds": "70",
    "lyric": "Make a big star out of me",
    "number": "93",
  },
  {
    "artist": "George Strait",
    "song": "Adalida",
    "year": "1995",
    "seconds": "50",
    "lyric": "I’d walk through a hurricane",
    "number": "94",
  },
  {
    "artist": "Dan Seals",
    "song": "Addicted",
    "year": "1988",
    "seconds": "50",
    "lyric": "Bury her head and cry",
    "number": "95",
  },
  {
    "artist": "Robert Palmer",
    "song": "Addicted to Love",
    "year": "1986",
    "seconds": "60",
    "lyric": "Your heart sweats, your body shakes",
    "number": "96",
  },
  {
    "artist": "Sarah McLachlan",
    "song": "Adia",
    "year": "1998",
    "seconds": "70",
    "lyric": "No one left to talk to, honey",
    "number": "97",
  },
  {
    "artist": "Jim Reeves",
    "song": "Adios Amigo",
    "year": "1962",
    "seconds": "60",
    "lyric": "It’s you who she longs for",
    "number": "98",
  },
  {
    "artist": "Harry Styles",
    "song": "Adore You",
    "year": "2019",
    "seconds": "50",
    "lyric": "Strawberry lipstick state of mind",
    "number": "99",
  },
  {
    "artist": "Coldplay",
    "song": "Adventure of a Lifetime",
    "year": "2015",
    "seconds": "60",
    "lyric": "Under this weight, we are diamonds",
    "number": "100",
  },
  {
    "artist": "Toto",
    "song": "Africa",
    "year": "1983",
    "seconds": "50",
    "lyric": "I stopped an old man",
    "number": "101",
  },
  {
    "artist": "Peter Cetera & Cher",
    "song": "After All",
    "year": "1989",
    "seconds": "60",
    "lyric": "Your kiss was so brand new",
    "number": "102",
  },
  {
    "artist": "Conway Twitty",
    "song": "After All the Good Is Gone",
    "year": "1976",
    "seconds": "50",
    "lyric": "I read between the lines",
    "number": "103",
  },
  {
    "artist": "Eric Clapton",
    "song": "After Midnight",
    "year": "1970",
    "seconds": "50",
    "lyric": "We’re gonna shake your tambourine",
    "number": "104",
  },
  {
    "artist": "Earth, Wind and Fire",
    "song": "After the Love Has Gone",
    "year": "1979",
    "seconds": "60",
    "lyric": "Love was strong for so long",
    "number": "105",
  },
  {
    "artist": "Engelbert Humperdinck",
    "song": "After the Lovin’",
    "year": "1976",
    "seconds": "60",
    "lyric": "So I sing you to sleep",
    "number": "106",
  },
  {
    "artist": "Bessie Smith",
    "song": "After You’ve Gone",
    "year": "1927",
    "seconds": "40",
    "lyric": "And left me cryin’",
    "number": "107",
  },
  {
    "artist": "Starland Vocal Band",
    "song": "Afternoon Delight",
    "year": "1976",
    "seconds": "70",
    "lyric": "The middle of a cold dark night",
    "number": "108",
  },
  {
    "artist": "Janet Jackson",
    "song": "Again",
    "year": "1993",
    "seconds": "70",
    "lyric": "I’ll never fall in love with you",
    "number": "109",
  },
  {
    "artist": "Phil Collins",
    "song": "Against All Odds",
    "year": "1984",
    "seconds": "60",
    "lyric": "Shared the laughter and the pain",
    "number": "110",
  },
  {
    "artist": "The Tragically Hip",
    "song": "Ahead by a Century",
    "year": "1996",
    "seconds": "60",
    "lyric": "And I had a feverish dream",
    "number": "111",
  },
  {
    "artist": "John Mellencamp",
    "song": "Ain’t Even Done With the Night",
    "year": "1981",
    "seconds": "60",
    "lyric": "Your hands in my back pockets",
    "number": "112",
  },
  {
    "artist": "Fats Waller",
    "song": "Ain’t Misbehavin’",
    "year": "1943",
    "seconds": "60",
    "lyric": "But I’m happy on the shelf",
    "number": "113",
  },
  {
    "artist": "Marvin Gaye, Tammi Terrell",
    "song": "Ain’t No Mountain High Enough",
    "year": "1967",
    "seconds": "70",
    "lyric": "No wind, no rain, nor winter’s cold",
    "number": "114",
  },
  {
    "artist": "Christina Aguilera",
    "song": "Ain’t No Other Man",
    "year": "2006",
    "seconds": "50",
    "lyric": "Stand up next to you",
    "number": "115",
  },
  {
    "artist": "Bill Withers",
    "song": "Ain’t No Sunshine",
    "year": "1971",
    "seconds": "60",
    "lyric": "It’s not warm when she’s away",
    "number": "116",
  },
  {
    "artist": "The Four Tops",
    "song": "Ain’t No Woman (Like the One I’ve Got)",
    "year": "1973",
    "seconds": "50",
    "lyric": "A sight for sore eyes",
    "number": "117",
  },
  {
    "artist": "Chaka Khan (with Rufus)",
    "song": "Ain’t Nobody",
    "year": "1983",
    "seconds": "50",
    "lyric": "We’re flyin’ through the stars",
    "number": "118",
  },
  {
    "artist": "Brooks & Dunn",
    "song": "Ain’t Nothing ‘Bout You",
    "year": "2001",
    "seconds": "50",
    "lyric": "Hammered by some heavy blows",
    "number": "119",
  },
  {
    "artist": "Marvin Gaye, Tammi Terrell",
    "song": "Ain’t Nothing Like the Real Thing",
    "year": "1968",
    "seconds": "60",
    "lyric": "Your picture hangin’ on the wall",
    "number": "120",
  },
  {
    "artist": "Conway Twitty",
    "song": "Ain’t She Somethin’ Else",
    "year": "1984",
    "seconds": "60",
    "lyric": "To see the sun go down",
    "number": "121",
  },
  {
    "artist": "Van Halen",
    "song": "Ain’t Talkin’ ‘Bout Love",
    "year": "1978",
    "seconds": "70",
    "lyric": "My love is rotten to the core",
    "number": "122",
  },
  {
    "artist": "Fats Domino",
    "song": "Ain’t That a Shame",
    "year": "1955",
    "seconds": "50",
    "lyric": "My tears fell like rain",
    "number": "123",
  },
  {
    "artist": "Dwight Yoakam",
    "song": "Ain’t That Lonely Yet",
    "year": "1993",
    "seconds": "50",
    "lyric": "This spider in my bed",
    "number": "124",
  },
  {
    "artist": "The Temptations",
    "song": "Ain’t Too Proud to Beg",
    "year": "1966",
    "seconds": "60",
    "lyric": "In the pit of my heart",
    "number": "125",
  },
  {
    "artist": "Lady Gaga",
    "song": "Alejandro",
    "year": "2010",
    "seconds": "50",
    "lyric": "Both hands in her pocket",
    "number": "126",
  },
  {
    "artist": "Arthur Collins",
    "song": "Alexander’s Ragtime Band",
    "year": "1911",
    "seconds": "50",
    "lyric": "Take you by the hand",
    "number": "127",
  },
  {
    "artist": "Cher",
    "song": "Alfie",
    "year": "1966",
    "seconds": "60",
    "lyric": "You find the love you’ve missed",
    "number": "128",
  },
  {
    "artist": "Arlo Guthrie",
    "song": "Alice’s Restaurant Massacree",
    "year": "1967",
    "seconds": "70",
    "lyric": "Half a mile from the railroad track",
    "number": "129",
  },
  {
    "artist": "Meghan Trainor",
    "song": "All About That Bass",
    "year": "2014",
    "seconds": "60",
    "lyric": "I won’t be no stick figure",
    "number": "130",
  },
  {
    "artist": "Blake Shelton",
    "song": "All About Tonight",
    "year": "2010",
    "seconds": "60",
    "lyric": "A red Gatorade by my bed",
    "number": "131",
  },
  {
    "artist": "Jimi Hendrix",
    "song": "All Along the Watchtower",
    "year": "1968",
    "seconds": "60",
    "lyric": "And the wind began to howl",
    "number": "132",
  },
  {
    "artist": "U2",
    "song": "All Because of You",
    "year": "2005",
    "seconds": "40",
    "lyric": "Squashed crossing the tracks",
    "number": "133",
  },
  {
    "artist": "Eric Carmen",
    "song": "All By Myself",
    "year": "1975",
    "seconds": "60",
    "lyric": "But when I dial the telephone",
    "number": "134",
  },
  {
    "artist": "The Kinks",
    "song": "All Day and All of the Night",
    "year": "1964",
    "seconds": "70",
    "lyric": "Girl, I want to be with you",
    "number": "135",
  },
  {
    "artist": "Bryan Adams, Rod Stewart, Sting",
    "song": "All For Love",
    "year": "1993",
    "seconds": "60",
    "lyric": "The rock you can build on",
    "number": "136",
  },
  {
    "artist": "Etta James",
    "song": "All I Could Do Was Cry",
    "year": "1960",
    "seconds": "50",
    "lyric": "I heard church bells ringing",
    "number": "137",
  },
  {
    "artist": "Sonny & Cher",
    "song": "All I Ever Need is You",
    "year": "1971",
    "seconds": "60",
    "lyric": "And we watch the melting snow",
    "number": "138",
  },
  {
    "artist": "The Everly Brothers",
    "song": "All I Have to Do is Dream",
    "year": "1958",
    "seconds": "50",
    "lyric": "Taste your lips of wine",
    "number": "139",
  },
  {
    "artist": "The Temptations",
    "song": "All I Need",
    "year": "1967",
    "seconds": "70",
    "lyric": "I’ll make it up to you, baby",
    "number": "140",
  },
  {
    "artist": "Bob Dylan",
    "song": "All I Really Want to Do",
    "year": "1964",
    "seconds": "70",
    "lyric": "Shock or knock or lock you up",
    "number": "141",
  },
  {
    "artist": "Sheryl Crow",
    "song": "All I Wanna Do",
    "year": "1994",
    "seconds": "60",
    "lyric": "And he’s plain ugly to me",
    "number": "142",
  },
  {
    "artist": "Heart",
    "song": "All I Wanna Do Is Make Love to You",
    "year": "1990",
    "seconds": "60",
    "lyric": "Standing by the road, no umbrella",
    "number": "143",
  },
  {
    "artist": "Spike Jones & his City Slickers",
    "song": "All I Want For Christmas is My Two Front Teeth",
    "year": "1947",
    "seconds": "60",
    "lyric": "Sister Susie sitting on a thistle",
    "number": "144",
  },
  {
    "artist": "Mariah Carey",
    "song": "All I Want For Christmas is You",
    "year": "1994",
    "seconds": "40",
    "lyric": "Waiting underneath the mistltoe",
    "number": "145",
  },
  {
    "artist": "Tim McGraw",
    "song": "All I Want Is a Life",
    "year": "1996",
    "seconds": "50",
    "lyric": "Knocks me off the ladder",
    "number": "146",
  },
  {
    "artist": "U2",
    "song": "All I Want is You",
    "year": "1989",
    "seconds": "60",
    "lyric": "From the cradle to the grave",
    "number": "147",
  },
  {
    "artist": "Kodaline",
    "song": "All I Want (Part 1)",
    "year": "2012",
    "seconds": "70",
    "lyric": "To hear you knocking at my door",
    "number": "148",
  },
  {
    "artist": "Sugarland",
    "song": "All I Want to Do",
    "year": "2008",
    "seconds": "40",
    "lyric": "Hide under the covers",
    "number": "149",
  },
  {
    "artist": "George Strait",
    "song": "All My Exes Live in Texas",
    "year": "1987",
    "seconds": "60",
    "lyric": "I hang my hat in Tennessee",
    "number": "150",
  },
  {
    "artist": "Luke Bryan",
    "song": "All My Friends Say",
    "year": "2007",
    "seconds": "60",
    "lyric": "Holding a beer in my hand",
    "number": "151",
  },
  {
    "artist": "Foo Fighters",
    "song": "All My Life",
    "year": "2002",
    "seconds": "70",
    "lyric": "Prize at the end of the rope",
    "number": "152",
  },
  {
    "artist": "Led Zeppelin",
    "song": "All My Love",
    "year": "1979",
    "seconds": "60",
    "lyric": "Chase a feather in the wind",
    "number": "153",
  },
  {
    "artist": "The Beatles",
    "song": "All My Loving",
    "year": "1963",
    "seconds": "50",
    "lyric": "The lips I am missing",
    "number": "154",
  },
  {
    "artist": "Hank Williams Jr.",
    "song": "All My Rowdy Friends",
    "year": "1981",
    "seconds": "50",
    "lyric": "Get drunk and get loud",
    "number": "155",
  },
  {
    "artist": "Lionel Richie",
    "song": "All Night Long (All Night)",
    "year": "1983",
    "seconds": "40",
    "lyric": "To raise the roof",
    "number": "156",
  },
  {
    "artist": "Frank Sinatra",
    "song": "All of Me",
    "year": "1948",
    "seconds": "50",
    "lyric": "A piece of these arms",
    "number": "157",
  },
  {
    "artist": "John Legend",
    "song": "All of Me",
    "year": "2013",
    "seconds": "50",
    "lyric": "And you kicking me out",
    "number": "158",
  },
  {
    "artist": "Air Supply",
    "song": "All Out of Love",
    "year": "1980",
    "seconds": "60",
    "lyric": "Carry your smile in my heart",
    "number": "159",
  },
  {
    "artist": "Free",
    "song": "All Right Now",
    "year": "1970",
    "seconds": "50",
    "lyric": "Her head to her feet",
    "number": "160",
  },
  {
    "artist": "Elvis Presley",
    "song": "All Shook Up",
    "year": "1957",
    "seconds": "60",
    "lyric": "A man on a fuzzy tree",
    "number": "161",
  },
  {
    "artist": "Smash Mouth",
    "song": "All Star",
    "year": "1999",
    "seconds": "50",
    "lyric": "But your head gets dumb",
    "number": "162",
  },
  {
    "artist": "Kid Rock",
    "song": "All Summer Long",
    "year": "2008",
    "seconds": "60",
    "lyric": "The moonlight shined upon her hair",
    "number": "163",
  },
  {
    "artist": "Dinah Shore",
    "song": "All That Glitters is Not Gold",
    "year": "1946",
    "seconds": "60",
    "lyric": "Lady love melted in his arms",
    "number": "164",
  },
  {
    "artist": "Billie Eilish",
    "song": "All the Good Girls Go to Hell",
    "year": "2019",
    "seconds": "50",
    "lyric": "The water starts to rise",
    "number": "165",
  },
  {
    "artist": "Kylie Minogue",
    "song": "All the Lovers",
    "year": "2010",
    "seconds": "50",
    "lyric": "Throws you to the fire",
    "number": "166",
  },
  {
    "artist": "Blink 182",
    "song": "All the Small Things",
    "year": "2000",
    "seconds": "70",
    "lyric": "She left me roses by the stairs",
    "number": "167",
  },
  {
    "artist": "Jack Greene",
    "song": "All the Time",
    "year": "1967",
    "seconds": "60",
    "lyric": "Through the years, smiles and tears",
    "number": "168",
  },
  {
    "artist": "Mott the Hoople",
    "song": "All the Young Dudes",
    "year": "1972",
    "seconds": "60",
    "lyric": "I’ve drunk a lot of wine",
    "number": "169",
  },
  {
    "artist": "The Killers",
    "song": "All These Things That I’ve Done",
    "year": "2004",
    "seconds": "70",
    "lyric": "You put me on the back burner",
    "number": "170",
  },
  {
    "artist": "Taylor Swift",
    "song": "All Too Well",
    "year": "2012",
    "seconds": "50",
    "lyric": "Photo album on the counter",
    "number": "171",
  },
  {
    "artist": "The Beatles",
    "song": "All You Need is Love",
    "year": "1967",
    "seconds": "60",
    "lyric": "To play the game, it’s easy",
    "number": "172",
  },
  {
    "artist": "Carrie Underwood",
    "song": "All-American Girl",
    "year": "2008",
    "seconds": "40",
    "lyric": "He was dropping passes",
    "number": "173",
  },
  {
    "artist": "The Hollywood Argyles",
    "song": "Alley Oop",
    "year": "1960",
    "seconds": "50",
    "lyric": "He rides through the jungle",
    "number": "174",
  },
  {
    "artist": "David Houston",
    "song": "Almost Persuaded",
    "year": "1966",
    "seconds": "60",
    "lyric": "Ruby red lips, coal black hair",
    "number": "175",
  },
  {
    "artist": "Heart",
    "song": "Alone",
    "year": "1987",
    "seconds": "70",
    "lyric": "I hear the ticking of the clock",
    "number": "176",
  },
  {
    "artist": "Marshmello",
    "song": "Alone",
    "year": "2016",
    "seconds": "60",
    "lyric": "My way back home to you",
    "number": "177",
  },
  {
    "artist": "The Bee Gees",
    "song": "Alone",
    "year": "1997",
    "seconds": "60",
    "lyric": "I’m on a wheel of fortune",
    "number": "178",
  },
  {
    "artist": "Gilbert O’Sullivan",
    "song": "Alone Again (Naturally)",
    "year": "1972",
    "seconds": "50",
    "lyric": "And climbing to the top",
    "number": "179",
  },
  {
    "artist": "The Coasters",
    "song": "Along Came Jones",
    "year": "1959",
    "seconds": "70",
    "lyric": "I plopped down in my easy chair",
    "number": "180",
  },
  {
    "artist": "The Association",
    "song": "Along Comes Mary",
    "year": "1966",
    "seconds": "50",
    "lyric": "And be my steady chick",
    "number": "181",
  },
  {
    "artist": "Dinah Shore",
    "song": "Along the Navajo Trail",
    "year": "1945",
    "seconds": "60",
    "lyric": "Time to climb into my saddle",
    "number": "182",
  },
  {
    "artist": "Prince",
    "song": "Alphabet St.",
    "year": "1988",
    "seconds": "40",
    "lyric": "Drive my daddy’s Thunderbird",
    "number": "183",
  },
  {
    "artist": "Eagles",
    "song": "Already Gone",
    "year": "1974",
    "seconds": "60",
    "lyric": "I will sing this victory song",
    "number": "184",
  },
  {
    "artist": "Kelly Clarkson",
    "song": "Already Gone",
    "year": "2009",
    "seconds": "50",
    "lyric": "Started with the perfect kiss",
    "number": "185",
  },
  {
    "artist": "Sugarland",
    "song": "Already Gone",
    "year": "2008",
    "seconds": "70",
    "lyric": "Our whole life down to one box",
    "number": "186",
  },
  {
    "artist": "David Houston",
    "song": "Already It’s Heaven",
    "year": "1968",
    "seconds": "50",
    "lyric": "The long search is through",
    "number": "187",
  },
  {
    "artist": "Heatwave",
    "song": "Always and Forever",
    "year": "1977",
    "seconds": "50",
    "lyric": "For a very long time",
    "number": "188",
  },
  {
    "artist": "Mariah Carey",
    "song": "Always Be My Baby",
    "year": "1996",
    "seconds": "60",
    "lyric": "Nights get a little bit colder",
    "number": "189",
  },
  {
    "artist": "Willie Nelson",
    "song": "Always On My Mind",
    "year": "1982",
    "seconds": "60",
    "lyric": "Girl, I’m sorry, I was blind",
    "number": "190",
  },
  {
    "artist": "George Strait",
    "song": "Am I Blue?",
    "year": "1987",
    "seconds": "40",
    "lyric": "This morning over coffee",
    "number": "191",
  },
  {
    "artist": "Jim Reeves",
    "song": "Am I Losing You?",
    "year": "1957",
    "seconds": "40",
    "lyric": "Too blind to see",
    "number": "192",
  },
  {
    "artist": "Engelbert Humperdinck",
    "song": "Am I That Easy to Forget",
    "year": "1968",
    "seconds": "70",
    "lyric": "Forget the love I had for you",
    "number": "193",
  },
  {
    "artist": "Waylon Jennings",
    "song": "Amanda",
    "year": "1979",
    "seconds": "40",
    "lyric": "Light of my life",
    "number": "194",
  },
  {
    "artist": "George Strait",
    "song": "Amarillo by Morning",
    "year": "1983",
    "seconds": "50",
    "lyric": "I’ll be looking for eight",
    "number": "195",
  },
  {
    "artist": "Lonestar",
    "song": "Amazed",
    "year": "1999",
    "seconds": "50",
    "lyric": "I can see your dreams",
    "number": "196",
  },
  {
    "artist": "Aerosmith",
    "song": "Amazing",
    "year": "1993",
    "seconds": "50",
    "lyric": "The blink of an eye",
    "number": "197",
  },
  {
    "artist": "Elvis Presley",
    "song": "Amazing Grace",
    "year": "1971",
    "seconds": "60",
    "lyric": "Was blind, but now I see",
    "number": "198",
  },
  {
    "artist": "Neil Diamond",
    "song": "America",
    "year": "1981",
    "seconds": "60",
    "lyric": "In the eye of the storm",
    "number": "199",
  },
  {
    "artist": "Simon & Garfunkel",
    "song": "America",
    "year": "1972",
    "seconds": "70",
    "lyric": "So we bought a pack of cigarettes",
    "number": "200",
  },
  {
    "artist": "Green Day",
    "song": "American Idiot",
    "year": "2004",
    "seconds": "40",
    "lyric": "Television dreams of tomorrow",
    "number": "201",
  },
  {
    "artist": "Kenny Chesney",
    "song": "American Kids",
    "year": "2014",
    "seconds": "60",
    "lyric": "Growing up in little pink houses",
    "number": "202",
  },
  {
    "artist": "The Oak Ridge Boys",
    "song": "American Made",
    "year": "1983",
    "seconds": "50",
    "lyric": "In her tight blue jeans",
    "number": "203",
  },
  {
    "artist": "Don McLean",
    "song": "American Pie",
    "year": "1972",
    "seconds": "60",
    "lyric": "You both kicked off your shoes",
    "number": "204",
  },
  {
    "artist": "Toby Keith",
    "song": "American Ride",
    "year": "2009",
    "seconds": "50",
    "lyric": "Spill a cup of coffee",
    "number": "205",
  },
  {
    "artist": "Brad Paisley",
    "song": "American Saturday Night",
    "year": "2009",
    "seconds": "40",
    "lyric": "Listen to the Beatles",
    "number": "206",
  },
  {
    "artist": "Toby Keith",
    "song": "American Soldier",
    "year": "2003",
    "seconds": "60",
    "lyric": "The wolf growls at the door",
    "number": "207",
  },
  {
    "artist": "The Guess Who",
    "song": "American Woman",
    "year": "1970",
    "seconds": "40",
    "lyric": "Colored lights can hypnotize",
    "number": "208",
  },
  {
    "artist": "Connie Francis",
    "song": "Among My Souvenirs",
    "year": "1959",
    "seconds": "50",
    "lyric": "I find a broken heart",
    "number": "209",
  },
  {
    "artist": "Jerry Reed",
    "song": "Amos Moses",
    "year": "1971",
    "seconds": "60",
    "lyric": "He hunted alligators for a livin’",
    "number": "210",
  },
  {
    "artist": "Andy Gibb",
    "song": "An Everlasting Love",
    "year": "1978",
    "seconds": "50",
    "lyric": "Above the rumble of thunder",
    "number": "211",
  },
  {
    "artist": "Three Dog Night",
    "song": "An Old Fashioned Love Song",
    "year": "1971",
    "seconds": "50",
    "lyric": "Comin’ down in three-part harmony",
    "number": "212",
  },
  {
    "artist": "The Beatles",
    "song": "And I Love Her",
    "year": "1964",
    "seconds": "50",
    "lyric": "The kiss my lover brings",
    "number": "213",
  },
  {
    "artist": "Eric Bogle",
    "song": "And the Band Played Waltzing Matilda",
    "year": "1971",
    "seconds": "70",
    "lyric": "So they gave me a tin hat",
    "number": "214",
  },
  {
    "artist": "Van Halen",
    "song": "And the Cradle Will Rock",
    "year": "1980",
    "seconds": "40",
    "lyric": "His folks are overjoyed",
    "number": "215",
  },
  {
    "artist": "Aerosmith",
    "song": "Angel",
    "year": "1988",
    "seconds": "50",
    "lyric": "A dog without a bone",
    "number": "216",
  },
  {
    "artist": "Sarah McLachlan",
    "song": "Angel",
    "year": "1998",
    "seconds": "50",
    "lyric": "I’ll find some peace tonight",
    "number": "217",
  },
  {
    "artist": "Shaggy (ft. Rayvon)",
    "song": "Angel",
    "year": "2000",
    "seconds": "40",
    "lyric": "Well girl, I surrender",
    "number": "218",
  },
  {
    "artist": "Willie Nelson",
    "song": "Angel Flying Too Close to the Ground",
    "year": "1981",
    "seconds": "60",
    "lyric": "I patched up your broken wing",
    "number": "219",
  },
  {
    "artist": "Hot",
    "song": "Angel in Your Arms",
    "year": "1977",
    "seconds": "70",
    "lyric": "The devil in someone else’s arms tonight",
    "number": "220",
  },
  {
    "artist": "U2",
    "song": "Angel of Harlem",
    "year": "1988",
    "seconds": "60",
    "lyric": "A star exploding in the night",
    "number": "221",
  },
  {
    "artist": "Merrilee Rush & the Turnabouts",
    "song": "Angel of the Morning",
    "year": "1968",
    "seconds": "60",
    "lyric": "No strings to bind your hands",
    "number": "222",
  },
  {
    "artist": "Rolling Stones",
    "song": "Angie",
    "year": "1973",
    "seconds": "60",
    "lyric": "And no money in our coats",
    "number": "223",
  },
  {
    "artist": "Tim McGraw",
    "song": "Angry All the Time",
    "year": "2001",
    "seconds": "50",
    "lyric": "The light in your eyes",
    "number": "224",
  },
  {
    "artist": "Jim Reeves",
    "song": "Anna Marie",
    "year": "1958",
    "seconds": "40",
    "lyric": "The snowflakes are falling",
    "number": "225",
  },
  {
    "artist": "John Denver",
    "song": "Annie’s Song",
    "year": "1974",
    "seconds": "40",
    "lyric": "A sleepy blue ocean",
    "number": "226",
  },
  {
    "artist": "Pink Floyd",
    "song": "Another Brick in the Wall",
    "year": "1979",
    "seconds": "60",
    "lyric": "Yes, you, behind the bike sheds",
    "number": "227",
  },
  {
    "artist": "Paul & Linda McCartney",
    "song": "Another Day",
    "year": "1971",
    "seconds": "60",
    "lyric": "Slippin’ into stockings, steppin’ into shoes",
    "number": "228",
  },
  {
    "artist": "Phil Collins",
    "song": "Another Day in Paradise",
    "year": "1989",
    "seconds": "70",
    "lyric": "Blisters on the soles of her feet",
    "number": "229",
  },
  {
    "artist": "Queen",
    "song": "Another One Bites the Dust",
    "year": "1980",
    "seconds": "70",
    "lyric": "Out of the doorway the bullets rip",
    "number": "230",
  },
  {
    "artist": "Sam Cooke",
    "song": "Another Saturday Night",
    "year": "1963",
    "seconds": "50",
    "lyric": "To a cat named Frankenstein",
    "number": "231",
  },
  {
    "artist": "Anne Murray",
    "song": "Another Sleepless Night",
    "year": "1982",
    "seconds": "70",
    "lyric": "The moon shines in through the window",
    "number": "232",
  },
  {
    "artist": "Carly Simon",
    "song": "Anticipation",
    "year": "1971",
    "seconds": "70",
    "lyric": "How right your arms feel around me",
    "number": "233",
  },
  {
    "artist": "Ronnie Milsap",
    "song": "Any Day Now",
    "year": "1982",
    "seconds": "50",
    "lyric": "Holding on for dear life",
    "number": "234",
  },
  {
    "artist": "Shania Twain",
    "song": "Any Man of Mine",
    "year": "1995",
    "seconds": "50",
    "lyric": "Just a little too tight",
    "number": "235",
  },
  {
    "artist": "Johnny Cash",
    "song": "Any Old Wind That Blows",
    "year": "1972",
    "seconds": "40",
    "lyric": "She’ll go sailin’ off",
    "number": "236",
  },
  {
    "artist": "Journey",
    "song": "Any Way You Want It",
    "year": "1980",
    "seconds": "50",
    "lyric": "The way you need it",
    "number": "237",
  },
  {
    "artist": "The Dave Clark Five",
    "song": "Any Way You Want It",
    "year": "1964",
    "seconds": "60",
    "lyric": "You can call me any day",
    "number": "238",
  },
  {
    "artist": "Travis Tritt",
    "song": "Anymore",
    "year": "1991",
    "seconds": "50",
    "lyric": "My tears no longer waiting",
    "number": "239",
  },
  {
    "artist": "Dottie West & Kenny Rogers",
    "song": "Anyone Who Isn’t Me Tonight",
    "year": "1978",
    "seconds": "60",
    "lyric": "And I’m higher than a kite",
    "number": "240",
  },
  {
    "artist": "Kenny Chesney",
    "song": "Anything But Mine",
    "year": "2005",
    "seconds": "60",
    "lyric": "Feel the sand beneath her feet",
    "number": "241",
  },
  {
    "artist": "Brad Paisley",
    "song": "Anything Like Me",
    "year": "2010",
    "seconds": "50",
    "lyric": "Climb a tree too tall",
    "number": "242",
  },
  {
    "artist": "George Hamilton IV",
    "song": "Anyway",
    "year": "1971",
    "seconds": "50",
    "lyric": "A ticket in your purse",
    "number": "243",
  },
  {
    "artist": "Lady Gaga",
    "song": "Applause",
    "year": "2013",
    "seconds": "70",
    "lyric": "Waiting for you to bang the gong",
    "number": "244",
  },
  {
    "artist": "Jethro Tull",
    "song": "Aqualung",
    "year": "1973",
    "seconds": "50",
    "lyric": "Greasy fingers smearing shabby clothes",
    "number": "245",
  },
  {
    "artist": "The 5th Dimension",
    "song": "Aquarius/Let the Sunshine In",
    "year": "1969",
    "seconds": "60",
    "lyric": "And love will steer the stars",
    "number": "246",
  },
  {
    "artist": "Dottie West",
    "song": "Are You Happy Baby?",
    "year": "1980",
    "seconds": "50",
    "lyric": "But you know I would",
    "number": "247",
  },
  {
    "artist": "Elvis Presley",
    "song": "Are You Lonesome Tonight?",
    "year": "1960",
    "seconds": "60",
    "lyric": "Is your heart filled with pain?",
    "number": "248",
  },
  {
    "artist": "Ronnie Milsap",
    "song": "Are You Lovin’ Me Like I’m Lovin’ You?",
    "year": "1990",
    "seconds": "50",
    "lyric": "You walk into a room",
    "number": "249",
  },
  {
    "artist": "Andy Williams",
    "song": "Are You Sincere?",
    "year": "1958",
    "seconds": "60",
    "lyric": "You say you love me too",
    "number": "250",
  },
  {
    "artist": "Waylon Jennings",
    "song": "Are You Sure Hank Done It This Way?",
    "year": "1975",
    "seconds": "50",
    "lyric": "Ten years on the road",
    "number": "251",
  },
  {
    "artist": "Toby Keith",
    "song": "As Good As I Once Was",
    "year": "2005",
    "seconds": "70",
    "lyric": "A big Texas smile on your face",
    "number": "252",
  },
  {
    "artist": "George Jones",
    "song": "As Long As I Live",
    "year": "1968",
    "seconds": "50",
    "lyric": "I know it sounds funny",
    "number": "253",
  },
  {
    "artist": "Zac Brown Band (ft. Alan Jackson)",
    "song": "As She’s Walking Away",
    "year": "2010",
    "seconds": "60",
    "lyric": "Might fall down on your face",
    "number": "254",
  },
  {
    "artist": "Conway Twitty & Loretta Lynn",
    "song": "As Soon As I Hang Up the Phone",
    "year": "1974",
    "seconds": "70",
    "lyric": "You tell me it’s over and done",
    "number": "255",
  },
  {
    "artist": "Rolling Stones",
    "song": "As Tears Go By",
    "year": "1965",
    "seconds": "70",
    "lyric": "I sit and watch the children play",
    "number": "256",
  },
  {
    "artist": "Etta James",
    "song": "At Last",
    "year": "1961",
    "seconds": "40",
    "lyric": "Wrapped up in clover",
    "number": "257",
  },
  {
    "artist": "Useless ID",
    "song": "At Least I Tried",
    "year": "2003",
    "seconds": "60",
    "lyric": "I remember sitting by the phone",
    "number": "258",
  },
  {
    "artist": "Gene Autry",
    "song": "At Mail Call Today",
    "year": "1945",
    "seconds": "40",
    "lyric": "But my castle tumbled",
    "number": "259",
  },
  {
    "artist": "Janis Ian",
    "song": "At Seventeen",
    "year": "1975",
    "seconds": "50",
    "lyric": "Inventing lovers on the phone",
    "number": "260",
  },
  {
    "artist": "Danny & the Juniors",
    "song": "At the Hop",
    "year": "1957",
    "seconds": "60",
    "lyric": "All the cats and the chicks",
    "number": "261",
  },
  {
    "artist": "Simon & Garfunkel",
    "song": "At the Zoo",
    "year": "1967",
    "seconds": "60",
    "lyric": "You can take the cross-town bus",
    "number": "262",
  },
  {
    "artist": "Billy Vera & the Beaters",
    "song": "At This Moment",
    "year": "1981",
    "seconds": "60",
    "lyric": "I’d fall down on my knees",
    "number": "263",
  },
  {
    "artist": "The Who",
    "song": "Athena",
    "year": "1982",
    "seconds": "60",
    "lyric": "Picked me up by my lapels",
    "number": "264",
  },
  {
    "artist": "Bruce Springsteen",
    "song": "Atlantic City",
    "year": "1982",
    "seconds": "50",
    "lyric": "Blew up the chicken man",
    "number": "265",
  },
  {
    "artist": "Guy Lombardo & his Royal Canadians",
    "song": "Auld Lang Syne",
    "year": "1939",
    "seconds": "50",
    "lyric": "Take a cup o’ kindness",
    "number": "266",
  },
  {
    "artist": "Blake Shelton",
    "song": "Austin",
    "year": "2001",
    "seconds": "60",
    "lyric": "She needed to clear her mind",
    "number": "267",
  },
  {
    "artist": "John Mellencamp",
    "song": "Authority Song",
    "year": "1984",
    "seconds": "60",
    "lyric": "So I call up my preacher",
    "number": "268",
  },
  {
    "artist": "Dallas Smith",
    "song": "Autograph",
    "year": "2016",
    "seconds": "70",
    "lyric": "Big old ring on your left hand",
    "number": "269",
  },
  {
    "artist": "Frank Sinatra",
    "song": "Autumn Leaves",
    "year": "1957",
    "seconds": "60",
    "lyric": "The summer kisses, the sunburned hands",
    "number": "270",
  },
  {
    "artist": "Smashing Pumpkins",
    "song": "Ava Adore",
    "year": "1998",
    "seconds": "60",
    "lyric": "And I’ll pull your crooked teeth",
    "number": "271",
  },
  {
    "artist": "Chris Young",
    "song": "Aw Naw",
    "year": "2013",
    "seconds": "60",
    "lyric": "I started walkin’ across the floor",
    "number": "272",
  },
  {
    "artist": "Godsmack",
    "song": "Awake",
    "year": "2000",
    "seconds": "50",
    "lyric": "Turn your back on me",
    "number": "273",
  },
  {
    "artist": "Darryl Worley",
    "song": "Awful, Beautiful Life",
    "year": "2004",
    "seconds": "50",
    "lyric": "Drag your butt to church",
    "number": "274",
  },
  {
    "artist": "The Who",
    "song": "Baba O’Riley",
    "year": "1971",
    "seconds": "60",
    "lyric": "Don’t cry, don’t raise your eye",
    "number": "275",
  },
  {
    "artist": "Styx",
    "song": "Babe",
    "year": "1979",
    "seconds": "60",
    "lyric": "My heart is in your hands",
    "number": "276",
  },
  {
    "artist": "Sugarland (ft. Taylor Swift)",
    "song": "Babe",
    "year": "2018",
    "seconds": "50",
    "lyric": "Her lips on your neck",
    "number": "277",
  },
  {
    "artist": "David Houston",
    "song": "Baby, Baby (I Know You’re a Lady)",
    "year": "1969",
    "seconds": "60",
    "lyric": "I will kiss the tears away",
    "number": "278",
  },
  {
    "artist": "Badfinger",
    "song": "Baby Blue",
    "year": "1971",
    "seconds": "60",
    "lyric": "I want you by my side",
    "number": "279",
  },
  {
    "artist": "George Strait",
    "song": "Baby Blue",
    "year": "1988",
    "seconds": "50",
    "lyric": "An angel when she smiled",
    "number": "280",
  },
  {
    "artist": "Beyoncé (ft. Sean Paul)",
    "song": "Baby Boy",
    "year": "2003",
    "seconds": "60",
    "lyric": "I see you in my dreams",
    "number": "281",
  },
  {
    "artist": "Player",
    "song": "Baby Come Back",
    "year": "1977",
    "seconds": "60",
    "lyric": "All the love in your heart",
    "number": "282",
  },
  {
    "artist": "Mac Davis",
    "song": "Baby, Don’t Get Hooked on Me",
    "year": "1972",
    "seconds": "40",
    "lyric": "By your trembling smile",
    "number": "283",
  },
  {
    "artist": "Sonny & Cher",
    "song": "Baby Don’t Go",
    "year": "1965",
    "seconds": "40",
    "lyric": "The second hand store",
    "number": "284",
  },
  {
    "artist": "Jan Garber and his Orchestra",
    "song": "Baby Face",
    "year": "1926",
    "seconds": "50",
    "lyric": "My poor heart is jumpin’",
    "number": "285",
  },
  {
    "artist": "Sugarland",
    "song": "Baby Girl",
    "year": "2004",
    "seconds": "50",
    "lyric": "Mom and Dad, please send money",
    "number": "286",
  },
  {
    "artist": "Andy Kim",
    "song": "Baby, I Love You",
    "year": "1969",
    "seconds": "70",
    "lyric": "How good it feels to hold you",
    "number": "287",
  },
  {
    "artist": "Peter Frampton",
    "song": "Baby, I Love Your Way",
    "year": "1975",
    "seconds": "60",
    "lyric": "See the sunset in your eyes",
    "number": "288",
  },
  {
    "artist": "The Four Tops",
    "song": "Baby I Need Your Loving",
    "year": "1964",
    "seconds": "60",
    "lyric": "I long to hold you tight",
    "number": "289",
  },
  {
    "artist": "Barbara Lewis",
    "song": "Baby I’m Yours",
    "year": "1965",
    "seconds": "60",
    "lyric": "The stars fall from the sky",
    "number": "290",
  },
  {
    "artist": "Ray Charles and Betty Carter",
    "song": "Baby, It’s Cold Outside",
    "year": "1961",
    "seconds": "70",
    "lyric": "And father will be pacing the floor",
    "number": "291",
  },
  {
    "artist": "The Supremes",
    "song": "Baby Love",
    "year": "1964",
    "seconds": "50",
    "lyric": "Don’t throw our love away",
    "number": "292",
  },
  {
    "artist": "The Foundations",
    "song": "Baby, Now That I’ve Found You",
    "year": "1968",
    "seconds": "50",
    "lyric": "Build my world around you",
    "number": "293",
  },
  {
    "artist": "Britney Spears",
    "song": "…Baby One More Time",
    "year": "1998",
    "seconds": "50",
    "lyric": "And give me a sign",
    "number": "294",
  },
  {
    "artist": "Glenn Yarbrough",
    "song": "Baby the Rain Must Fall",
    "year": "1965",
    "seconds": "60",
    "lyric": "I do not love for silver",
    "number": "295",
  },
  {
    "artist": "Chicago",
    "song": "Baby, What a Big Surprise",
    "year": "1977",
    "seconds": "50",
    "lyric": "Right before my very eyes",
    "number": "296",
  },
  {
    "artist": "Bessie Smith",
    "song": "Baby, Won’t You Please Come Home",
    "year": "1923",
    "seconds": "40",
    "lyric": "You broke my heart",
    "number": "297",
  },
  {
    "artist": "The Beatles",
    "song": "Baby, You’re a Rich Man",
    "year": "1967",
    "seconds": "60",
    "lyric": "Big brown bag inside a zoo",
    "number": "298",
  },
  {
    "artist": "David Gray",
    "song": "Babylon",
    "year": "1999",
    "seconds": "60",
    "lyric": "Sky is fading red to blue",
    "number": "299",
  },
  {
    "artist": "George Strait",
    "song": "Baby’s Gotten Good at Goodbye",
    "year": "1988",
    "seconds": "60",
    "lyric": "I’m sittin’ on the front steps",
    "number": "300",
  },
  {
    "artist": "Cliff Richard and the Shadows",
    "song": "Bachelor Boy",
    "year": "1962",
    "seconds": "70",
    "lyric": "Meet a girl and fall in love",
    "number": "301",
  },
  {
    "artist": "Jessica Pratt",
    "song": "Back, Baby",
    "year": "2014",
    "seconds": "50",
    "lyric": "I pray for the rain",
    "number": "302",
  },
  {
    "artist": "Take That",
    "song": "Back for Good",
    "year": "1995",
    "seconds": "50",
    "lyric": "Still on your coffee cup",
    "number": "303",
  },
  {
    "artist": "John Denver",
    "song": "Back Home Again",
    "year": "1974",
    "seconds": "60",
    "lyric": "Your fingers feather soft on me",
    "number": "304",
  },
  {
    "artist": "AC/DC",
    "song": "Back in Black",
    "year": "1981",
    "seconds": "50",
    "lyric": "Number one with a bullet",
    "number": "305",
  },
  {
    "artist": "The Supremes",
    "song": "Back in My Arms Again",
    "year": "1965",
    "seconds": "40",
    "lyric": "Right by my side",
    "number": "306",
  },
  {
    "artist": "Aerosmith",
    "song": "Back in the Saddle",
    "year": "1976",
    "seconds": "70",
    "lyric": "She turned to give me a wink",
    "number": "307",
  },
  {
    "artist": "Gene Autry",
    "song": "Back in the Saddle Again",
    "year": "1939",
    "seconds": "50",
    "lyric": "You sleep out every night",
    "number": "308",
  },
  {
    "artist": "The Beatles",
    "song": "Back in the U.S.S.R.",
    "year": "1968",
    "seconds": "70",
    "lyric": "The paper bag was on my knee",
    "number": "309",
  },
  {
    "artist": "Ronnie Milsap",
    "song": "Back on My Mind Again",
    "year": "1979",
    "seconds": "50",
    "lyric": "Building castles in the sand",
    "number": "310",
  },
  {
    "artist": "The Pretenders",
    "song": "Back On the Chain Gang",
    "year": "1982",
    "seconds": "60",
    "lyric": "Put us back on the train",
    "number": "311",
  },
  {
    "artist": "The O’Jays",
    "song": "Back Stabbers",
    "year": "1972",
    "seconds": "50",
    "lyric": "Out to get your lady",
    "number": "312",
  },
  {
    "artist": "Taylor Swift",
    "song": "Back to December",
    "year": "2010",
    "seconds": "60",
    "lyric": "Your tan skin, your sweet smile",
    "number": "313",
  },
  {
    "artist": "Tim McGraw",
    "song": "Back When",
    "year": "2004",
    "seconds": "40",
    "lyric": "Peanuts in a bottle",
    "number": "314",
  },
  {
    "artist": "Michael Jackson",
    "song": "Bad",
    "year": "1987",
    "seconds": "70",
    "lyric": "Watch your mouth, I know your game",
    "number": "315",
  },
  {
    "artist": "Jim Croce",
    "song": "Bad, Bad Leroy Brown",
    "year": "1973",
    "seconds": "60",
    "lyric": "He stand ‘bout six foot four",
    "number": "316",
  },
  {
    "artist": "Neil Sedaka",
    "song": "Bad Blood",
    "year": "1975",
    "seconds": "60",
    "lyric": "The woman was born to lie",
    "number": "317",
  },
  {
    "artist": "Taylor Swift (ft. Kendrick Lamar)",
    "song": "Bad Blood",
    "year": "2015",
    "seconds": "60",
    "lyric": "And rub it in so deep",
    "number": "318",
  },
  {
    "artist": "Robert Palmer",
    "song": "Bad Case of Loving You (Doctor, Doctor)",
    "year": "1979",
    "seconds": "60",
    "lyric": "Turn my blue heart to red",
    "number": "319",
  },
  {
    "artist": "Bad Company",
    "song": "Bad Company",
    "year": "1974",
    "seconds": "50",
    "lyric": "Destiny is the rising sun",
    "number": "320",
  },
  {
    "artist": "Donna Summer",
    "song": "Bad Girls",
    "year": "1979",
    "seconds": "70",
    "lyric": "Friday night and the strip is hot",
    "number": "321",
  },
  {
    "artist": "M.I.A.",
    "song": "Bad Girls",
    "year": "2012",
    "seconds": "50",
    "lyric": "I’m banging on the dashboard",
    "number": "322",
  },
  {
    "artist": "Billie Eilish",
    "song": "Bad Guy",
    "year": "2019",
    "seconds": "50",
    "lyric": "You’re on your tippy toes",
    "number": "323",
  },
  {
    "artist": "Creedence Clearwater Revival",
    "song": "Bad Moon Rising",
    "year": "1969",
    "seconds": "50",
    "lyric": "I see earthquakes and lightning",
    "number": "324",
  },
  {
    "artist": "Lady Gaga",
    "song": "Bad Romance",
    "year": "2009",
    "seconds": "60",
    "lyric": "Want you in my rear window",
    "number": "325",
  },
  {
    "artist": "George Thorogood and the Destroyers",
    "song": "Bad to the Bone",
    "year": "1982",
    "seconds": "50",
    "lyric": "The head nurse spoke up",
    "number": "326",
  },
  {
    "artist": "Bruce Springsteen",
    "song": "Badlands",
    "year": "1978",
    "seconds": "50",
    "lyric": "I’m caught in a crossfire",
    "number": "327",
  },
  {
    "artist": "Enrique Iglesias",
    "song": "Bailamos",
    "year": "1999",
    "seconds": "50",
    "lyric": "My life in your hands",
    "number": "328",
  },
  {
    "artist": "Gerry Rafferty",
    "song": "Baker Street",
    "year": "1978",
    "seconds": "50",
    "lyric": "And dead on your feet",
    "number": "329",
  },
  {
    "artist": "Perry Como",
    "song": "Bali Ha’i",
    "year": "1949",
    "seconds": "40",
    "lyric": "My head sticking out",
    "number": "330",
  },
  {
    "artist": "The Temptations",
    "song": "Ball of Confusion (That’s What the World is Today)",
    "year": "1970",
    "seconds": "50",
    "lyric": "Shooting rockets to the moon",
    "number": "331",
  },
  {
    "artist": "Johnny Cash",
    "song": "Ballad of a Teenage Queen",
    "year": "1958",
    "seconds": "60",
    "lyric": "Golden hair and eyes of blue",
    "number": "332",
  },
  {
    "artist": "Freda Payne",
    "song": "Band of Gold",
    "year": "1970",
    "seconds": "50",
    "lyric": "I’d wait in the darkness",
    "number": "333",
  },
  {
    "artist": "Paul McCartney & Wings",
    "song": "Band on the Run",
    "year": "1974",
    "seconds": "50",
    "lyric": "Stuck inside these four walls",
    "number": "334",
  },
  {
    "artist": "Jessie J, Ariana Grande, Nicki Minaj",
    "song": "Bang Bang",
    "year": "2014",
    "seconds": "60",
    "lyric": "Step on up, swing your bat",
    "number": "335",
  },
  {
    "artist": "Cher",
    "song": "Bang Bang (My Baby Shot Me Down)",
    "year": "1966",
    "seconds": "70",
    "lyric": "We rode on horses made of sticks",
    "number": "336",
  },
  {
    "artist": "Rascal Flatts",
    "song": "Banjo",
    "year": "2012",
    "seconds": "30",
    "lyric": "Crickets and frogs",
    "number": "337",
  },
  {
    "artist": "Beach Boys",
    "song": "Barbara Ann",
    "year": "1965",
    "seconds": "70",
    "lyric": "Went to a dance, looking for romance",
    "number": "338",
  },
  {
    "artist": "Aqua",
    "song": "Barbie Girl",
    "year": "1997",
    "seconds": "50",
    "lyric": "You can brush my hair",
    "number": "339",
  },
  {
    "artist": "The Who",
    "song": "Bargain",
    "year": "1971",
    "seconds": "50",
    "lyric": "My face in the mirror",
    "number": "340",
  },
  {
    "artist": "Heart",
    "song": "Barracuda",
    "year": "1977",
    "seconds": "50",
    "lyric": "So low in the weeds",
    "number": "341",
  },
  {
    "artist": "Green Day",
    "song": "Basket Case",
    "year": "1994",
    "seconds": "60",
    "lyric": "Time to listen to me whine",
    "number": "342",
  },
  {
    "artist": "Prince",
    "song": "Batdance",
    "year": "1989",
    "seconds": "50",
    "lyric": "In the pale moon light",
    "number": "343",
  },
  {
    "artist": "Journey",
    "song": "Be Good to Yourself",
    "year": "1986",
    "seconds": "60",
    "lyric": "I’m hanging up my boxing gloves",
    "number": "344",
  },
  {
    "artist": "The Ronettes",
    "song": "Be My Baby",
    "year": "1963",
    "seconds": "60",
    "lyric": "For every kiss you give me",
    "number": "345",
  },
  {
    "artist": "John Michael Montgomery",
    "song": "Be My Baby Tonight",
    "year": "1994",
    "seconds": "60",
    "lyric": "To knock me off my feet",
    "number": "346",
  },
  {
    "artist": "Fats Domino",
    "song": "Be My Guest",
    "year": "1959",
    "seconds": "50",
    "lyric": "You can wear my crown",
    "number": "347",
  },
  {
    "artist": "Dua Lipa",
    "song": "Be the One",
    "year": "2015",
    "seconds": "60",
    "lyric": "When you’re looking at the sun",
    "number": "348",
  },
  {
    "artist": "Beach Boys",
    "song": "Be True to Your School",
    "year": "1963",
    "seconds": "50",
    "lyric": "With a letter in front",
    "number": "349",
  },
  {
    "artist": "Enrique Iglesias",
    "song": "Be With You",
    "year": "2000",
    "seconds": "70",
    "lyric": "I know the touch of your hand",
    "number": "350",
  },
  {
    "artist": "The First Class",
    "song": "Beach Baby",
    "year": "1974",
    "seconds": "60",
    "lyric": "We can walk by the shore",
    "number": "351",
  },
  {
    "artist": "Rolling Stones",
    "song": "Beast of Burden",
    "year": "1978",
    "seconds": "60",
    "lyric": "I’m not too blind to see",
    "number": "352",
  },
  {
    "artist": "Michael Jackson",
    "song": "Beat It",
    "year": "1983",
    "seconds": "50",
    "lyric": "The fire’s in their eyes",
    "number": "353",
  },
  {
    "artist": "Brad Paisley",
    "song": "Beat This Summer",
    "year": "2013",
    "seconds": "70",
    "lyric": "The Ferris wheel goes around and around",
    "number": "354",
  },
  {
    "artist": "Christina Aguilera",
    "song": "Beautiful",
    "year": "2002",
    "seconds": "40",
    "lyric": "Left the puzzle undone",
    "number": "355",
  },
  {
    "artist": "Mariah Carey (ft. Miguel)",
    "song": "#Beautiful",
    "year": "2013",
    "seconds": "70",
    "lyric": "Hop on the back of my bike",
    "number": "356",
  },
  {
    "artist": "Luke Combs",
    "song": "Beautiful Crazy",
    "year": "2018",
    "seconds": "60",
    "lyric": "Wears her heart on her sleeve",
    "number": "357",
  },
  {
    "artist": "U2",
    "song": "Beautiful Day",
    "year": "2000",
    "seconds": "60",
    "lyric": "Shoots up through the stony ground",
    "number": "358",
  },
  {
    "artist": "Van Halen",
    "song": "Beautiful Girls",
    "year": "1979",
    "seconds": "70",
    "lyric": "She had her toes in the sand",
    "number": "359",
  },
  {
    "artist": "Beyoncé (with Shakira)",
    "song": "Beautiful Liar",
    "year": "2007",
    "seconds": "50",
    "lyric": "Let’s not start a fight",
    "number": "360",
  },
  {
    "artist": "Ed Sheeran (ft. Khalid)",
    "song": "Beautiful People",
    "year": "2019",
    "seconds": "50",
    "lyric": "Front row at fashion shows",
    "number": "361",
  },
  {
    "artist": "Daniel Boone",
    "song": "Beautiful Sunday",
    "year": "1972",
    "seconds": "70",
    "lyric": "Birds are singing, you by my side",
    "number": "362",
  },
  {
    "artist": "The Oak Ridge Boys",
    "song": "Beautiful You",
    "year": "1980",
    "seconds": "50",
    "lyric": "Please don’t cry, oh my",
    "number": "363",
  },
  {
    "artist": "Céline Dion and Peabo Bryson",
    "song": "Beauty and the Beast",
    "year": "1991",
    "seconds": "60",
    "lyric": "The sun rising in the east",
    "number": "364",
  },
  {
    "artist": "The Temptations",
    "song": "Beauty is Only Skin Deep",
    "year": "1966",
    "seconds": "60",
    "lyric": "Judge a book by its cover",
    "number": "365",
  },
  {
    "artist": "Perry Como",
    "song": "Because",
    "year": "1948",
    "seconds": "40",
    "lyric": "I find the roses",
    "number": "366",
  },
  {
    "artist": "The Beatles",
    "song": "Because",
    "year": "1969",
    "seconds": "40",
    "lyric": "The world is round",
    "number": "367",
  },
  {
    "artist": "The Dave Clark Five",
    "song": "Because",
    "year": "1964",
    "seconds": "60",
    "lyric": "One kiss and I’ll be happy",
    "number": "368",
  },
  {
    "artist": "Kelly Clarkson",
    "song": "Because of You",
    "year": "2005",
    "seconds": "60",
    "lyric": "Stray too far from the sidewalk",
    "number": "369",
  },
  {
    "artist": "Ne-Yo",
    "song": "Because of You",
    "year": "2007",
    "seconds": "60",
    "lyric": "I’m so strung out on you",
    "number": "370",
  },
  {
    "artist": "Tony Bennett",
    "song": "Because of You",
    "year": "1951",
    "seconds": "60",
    "lyric": "There’s a song in my heart",
    "number": "371",
  },
  {
    "artist": "Céline Dion",
    "song": "Because You Loved Me",
    "year": "1996",
    "seconds": "60",
    "lyric": "No star was out of reach",
    "number": "372",
  },
  {
    "artist": "Jhené Aiko (ft. Childish Gambino)",
    "song": "Bed Peace",
    "year": "2013",
    "seconds": "60",
    "lyric": "But your nose is still running",
    "number": "373",
  },
  {
    "artist": "Soundgarden",
    "song": "Been Away Too long",
    "year": "2012",
    "seconds": "60",
    "lyric": "From the holes to the cracks",
    "number": "374",
  },
  {
    "artist": "Toby Keith & Willie Nelson",
    "song": "Beer For My Horses",
    "year": "2003",
    "seconds": "50",
    "lyric": "We’ll raise up our glasses",
    "number": "375",
  },
  {
    "artist": "Kenny Chesney",
    "song": "Beer in Mexico",
    "year": "2007",
    "seconds": "70",
    "lyric": "Too young to be over the hill",
    "number": "376",
  },
  {
    "artist": "Luke Combs",
    "song": "Beer Never Broke My Heart",
    "year": "2019",
    "seconds": "40",
    "lyric": "Fire in her eyes",
    "number": "377",
  },
  {
    "artist": "Carrie Underwood",
    "song": "Before He Cheats",
    "year": "2006",
    "seconds": "70",
    "lyric": "Up behind her with a pool stick",
    "number": "378",
  },
  {
    "artist": "Brad Martin",
    "song": "Before I Knew Better",
    "year": "2002",
    "seconds": "50",
    "lyric": "And I broke my leg",
    "number": "379",
  },
  {
    "artist": "Freddy Fender",
    "song": "Before the Next Teardrop Falls",
    "year": "1975",
    "seconds": "60",
    "lyric": "If he ever leaves you blue",
    "number": "380",
  },
  {
    "artist": "Buck Owens",
    "song": "Before You Go",
    "year": "1965",
    "seconds": "50",
    "lyric": "I’m sorry for the quarrels",
    "number": "381",
  },
  {
    "artist": "Marty Robbins",
    "song": "Begging to You",
    "year": "1963",
    "seconds": "50",
    "lyric": "You dared me to walk",
    "number": "382",
  },
  {
    "artist": "Taylor Swift",
    "song": "Begin Again",
    "year": "2012",
    "seconds": "60",
    "lyric": "You throw your head back laughing",
    "number": "383",
  },
  {
    "artist": "Chicago",
    "song": "Beginnings",
    "year": "1969",
    "seconds": "50",
    "lyric": "Chills all over my body",
    "number": "384",
  },
  {
    "artist": "The Who",
    "song": "Behind Blue Eyes",
    "year": "1971",
    "seconds": "50",
    "lyric": "To be the bad man",
    "number": "385",
  },
  {
    "artist": "Charlie Rich",
    "song": "Behind Closed Doors",
    "year": "1973",
    "seconds": "50",
    "lyric": "Lets her hair hang down",
    "number": "386",
  },
  {
    "artist": "Kelly Clarkson",
    "song": "Behind These Hazel Eyes",
    "year": "2005",
    "seconds": "50",
    "lyric": "Your arms around me tight",
    "number": "387",
  },
  {
    "artist": "Van Morrison",
    "song": "Bein’ Green",
    "year": "1973",
    "seconds": "50",
    "lyric": "Red or yellow or gold",
    "number": "388",
  },
  {
    "artist": "Cher",
    "song": "Believe",
    "year": "1998",
    "seconds": "60",
    "lyric": "Sit around and wait for you?",
    "number": "389",
  },
  {
    "artist": "Elton John",
    "song": "Believe",
    "year": "1995",
    "seconds": "50",
    "lyric": "Curled up in my father",
    "number": "390",
  },
  {
    "artist": "Josh Groban",
    "song": "Believe",
    "year": "2004",
    "seconds": "50",
    "lyric": "Sailing far across the sea",
    "number": "391",
  },
  {
    "artist": "Billie Eilish",
    "song": "Bellyache",
    "year": "2017",
    "seconds": "40",
    "lyric": "I’m biting my nails",
    "number": "392",
  },
  {
    "artist": "Michael Jackson",
    "song": "Ben",
    "year": "1972",
    "seconds": "40",
    "lyric": "Running here and there",
    "number": "393",
  },
  {
    "artist": "John Anderson",
    "song": "Bend It Until It Breaks",
    "year": "1994",
    "seconds": "60",
    "lyric": "But baby, I’m calling your bluff",
    "number": "394",
  },
  {
    "artist": "The American Breed",
    "song": "Bend Me, Shape Me",
    "year": "1968",
    "seconds": "50",
    "lyric": "Long as you love me",
    "number": "395",
  },
  {
    "artist": "Elton John",
    "song": "Bennie and the Jets",
    "year": "1974",
    "seconds": "50",
    "lyric": "Electric boots, a mohair suit",
    "number": "396",
  },
  {
    "artist": "The Four Tops",
    "song": "Bernadette",
    "year": "1967",
    "seconds": "50",
    "lyric": "You’re the soul of me",
    "number": "397",
  },
  {
    "artist": "American Authors",
    "song": "Best Day of My Life",
    "year": "2013",
    "seconds": "60",
    "lyric": "A dream so big and loud",
    "number": "398",
  },
  {
    "artist": "Travis Tritt",
    "song": "Best of Intentions",
    "year": "2000",
    "seconds": "60",
    "lyric": "You’re the queen of my heart",
    "number": "399",
  },
  {
    "artist": "Eagles",
    "song": "Best of My Love",
    "year": "1974",
    "seconds": "60",
    "lyric": "Holdin’ you close in my dreams",
    "number": "400",
  },
  {
    "artist": "The Emotions",
    "song": "Best of My Love",
    "year": "1977",
    "seconds": "60",
    "lyric": "The whole wide world to see",
    "number": "401",
  },
  {
    "artist": "Foo Fighters",
    "song": "Best of You",
    "year": "2005",
    "seconds": "50",
    "lyric": "My heart is under arrest",
    "number": "402",
  },
  {
    "artist": "Christopher Cross",
    "song": "Best That You Can Do",
    "year": "1981",
    "seconds": "40",
    "lyric": "Turns your heart around",
    "number": "403",
  },
  {
    "artist": "Beyoncé",
    "song": "Best Thing I Never Had",
    "year": "2011",
    "seconds": "40",
    "lyric": "I dodged the bullet",
    "number": "404",
  },
  {
    "artist": "Gladys Knight & the Pips",
    "song": "Best Thing That Ever Happened To Me",
    "year": "1974",
    "seconds": "40",
    "lyric": "Write my life story",
    "number": "405",
  },
  {
    "artist": "The Stylistics",
    "song": "Betcha by Golly, Wow",
    "year": "1972",
    "seconds": "60",
    "lyric": "Write your name across the sky",
    "number": "406",
  },
  {
    "artist": "Kim Carnes",
    "song": "Bette Davis Eyes",
    "year": "1981",
    "seconds": "50",
    "lyric": "The crumbs she throws you",
    "number": "407",
  },
  {
    "artist": "Kenny Chesney",
    "song": "Better As a Memory",
    "year": "2008",
    "seconds": "60",
    "lyric": "Walls too high to climb out",
    "number": "408",
  },
  {
    "artist": "Tina Turner",
    "song": "Better Be Good To Me",
    "year": "1984",
    "seconds": "50",
    "lyric": "Hot whispers in the night",
    "number": "409",
  },
  {
    "artist": "Bruce Springsteen",
    "song": "Better Days",
    "year": "1992",
    "seconds": "60",
    "lyric": "And turning his heart to stone",
    "number": "410",
  },
  {
    "artist": "Leona Lewis",
    "song": "Better in Time",
    "year": "2008",
    "seconds": "50",
    "lyric": "Thought I heard a knock",
    "number": "411",
  },
  {
    "artist": "Keith Urban",
    "song": "Better Life",
    "year": "2005",
    "seconds": "50",
    "lyric": "And the moon is high",
    "number": "412",
  },
  {
    "artist": "Tim McGraw",
    "song": "Better Than I Used To Be",
    "year": "2011",
    "seconds": "50",
    "lyric": "I’m cleaning up my act",
    "number": "413",
  },
  {
    "artist": "Kylie Minogue",
    "song": "Better Than Today",
    "year": "2010",
    "seconds": "50",
    "lyric": "The world in a web",
    "number": "414",
  },
  {
    "artist": "Jack Johnson",
    "song": "Better Together",
    "year": "2005",
    "seconds": "50",
    "lyric": "Sit beneath the mango tree",
    "number": "415",
  },
  {
    "artist": "Luke Combs",
    "song": "Better Together",
    "year": "2020",
    "seconds": "60",
    "lyric": "Barbed wire and old fence posts",
    "number": "416",
  },
  {
    "artist": "Bobby Darin",
    "song": "Beyond the Sea",
    "year": "1960",
    "seconds": "60",
    "lyric": "My lover stands on golden sands",
    "number": "417",
  },
  {
    "artist": "The 4 Seasons",
    "song": "Big Girls Don’t Cry",
    "year": "1962",
    "seconds": "50",
    "lyric": "We had to break up",
    "number": "418",
  },
  {
    "artist": "Jason Aldean",
    "song": "Big Green Tractor",
    "year": "2009",
    "seconds": "50",
    "lyric": "And she curled her hair",
    "number": "419",
  },
  {
    "artist": "Burl Ives",
    "song": "Big Rock Candy Mountain",
    "year": "1945",
    "seconds": "60",
    "lyric": "The bulldogs all have rubber teeth",
    "number": "420",
  },
  {
    "artist": "Kenny Chesney",
    "song": "Big Star",
    "year": "2003",
    "seconds": "50",
    "lyric": "The camera fell in love",
    "number": "421",
  },
  {
    "artist": "Peter Gabriel",
    "song": "Big Time",
    "year": "1986",
    "seconds": "50",
    "lyric": "I’ll be stretching my mouth",
    "number": "422",
  },
  {
    "artist": "Dan Seals",
    "song": "Big Wheels in the Moonlight",
    "year": "1988",
    "seconds": "50",
    "lyric": "In a living room chair",
    "number": "423",
  },
  {
    "artist": "Joni Mitchell",
    "song": "Big Yellow Taxi",
    "year": "1970",
    "seconds": "50",
    "lyric": "Put up a parking lot",
    "number": "424",
  },
  {
    "artist": "Michael Jackson",
    "song": "Billie Jean",
    "year": "1983",
    "seconds": "70",
    "lyric": "Danced on the floor in the round",
    "number": "425",
  },
  {
    "artist": "Jim Reeves",
    "song": "Billy Bayou",
    "year": "1958",
    "seconds": "50",
    "lyric": "Freckles and big blue eyes",
    "number": "426",
  },
  {
    "artist": "Bo Donaldson and the Heywoods",
    "song": "Billy, Don’t Be a Hero",
    "year": "1974",
    "seconds": "50",
    "lyric": "She threw the letter away",
    "number": "427",
  },
  {
    "artist": "Jim Reeves",
    "song": "Bimbo",
    "year": "1954",
    "seconds": "50",
    "lyric": "A hole in his pants",
    "number": "428",
  },
  {
    "artist": "The Everly Brothers",
    "song": "Bird Dog",
    "year": "1958",
    "seconds": "60",
    "lyric": "He tiptoed up to reach her",
    "number": "429",
  },
  {
    "artist": "Red Foley",
    "song": "Birmingham Bounce",
    "year": "1950",
    "seconds": "50",
    "lyric": "Everybody’s dancin’ and a-jumpin’ too",
    "number": "430",
  },
  {
    "artist": "Meredith Brooks",
    "song": "Bitch",
    "year": "1997",
    "seconds": "60",
    "lyric": "I’m a child, I’m a mother",
    "number": "431",
  },
  {
    "artist": "Rolling Stones",
    "song": "Bitch",
    "year": "1971",
    "seconds": "50",
    "lyric": "My heart is beating louder",
    "number": "432",
  },
  {
    "artist": "The Dave Clark Five",
    "song": "Bits and Pieces",
    "year": "1964",
    "seconds": "50",
    "lyric": "Up there on your throne",
    "number": "433",
  },
  {
    "artist": "The Verve",
    "song": "Bittersweet Symphony",
    "year": "1997",
    "seconds": "60",
    "lyric": "But I’m a million different people",
    "number": "434",
  },
  {
    "artist": "Three Dog Night",
    "song": "Black and White",
    "year": "1972",
    "seconds": "50",
    "lyric": "Grow to see the light",
    "number": "435",
  },
  {
    "artist": "Ram Jam",
    "song": "Black Betty",
    "year": "1977",
    "seconds": "40",
    "lyric": "She’s so rock steady",
    "number": "436",
  },
  {
    "artist": "Janet Jackson",
    "song": "Black Cat",
    "year": "1990",
    "seconds": "40",
    "lyric": "Short days, long nights",
    "number": "437",
  },
  {
    "artist": "Led Zeppelin",
    "song": "Black Dog",
    "year": "1971",
    "seconds": "50",
    "lyric": "Eyes that shine, burnin’ red",
    "number": "438",
  },
  {
    "artist": "Soundgarden",
    "song": "Black Hole Sun",
    "year": "1994",
    "seconds": "60",
    "lyric": "In my shoes, a walking sleep",
    "number": "439",
  },
  {
    "artist": "Los Bravos",
    "song": "Black is Black",
    "year": "1966",
    "seconds": "50",
    "lyric": "I want my baby back",
    "number": "440",
  },
  {
    "artist": "Santana",
    "song": "Black Magic Woman",
    "year": "1970",
    "seconds": "50",
    "lyric": "Turning my heart into stone",
    "number": "441",
  },
  {
    "artist": "Michael Jackson",
    "song": "Black or White",
    "year": "1991",
    "seconds": "70",
    "lyric": "I saw you kicking dirt in my eye",
    "number": "442",
  },
  {
    "artist": "John Anderson",
    "song": "Black Sheep",
    "year": "1983",
    "seconds": "50",
    "lyric": "He sits in his Jacuzzi",
    "number": "443",
  },
  {
    "artist": "Alannah Myles",
    "song": "Black Velvet",
    "year": "1989",
    "seconds": "60",
    "lyric": "Dancin’ with baby on her shoulder",
    "number": "444",
  },
  {
    "artist": "Doobie Brothers",
    "song": "Black Water",
    "year": "1974",
    "seconds": "50",
    "lyric": "Take me by the hand",
    "number": "445",
  },
  {
    "artist": "The Beatles",
    "song": "Blackbird",
    "year": "1968",
    "seconds": "40",
    "lyric": "Take these broken wings",
    "number": "446",
  },
  {
    "artist": "Eydie Gormé",
    "song": "Blame It On the Bossa Nova",
    "year": "1963",
    "seconds": "40",
    "lyric": "The dance of love",
    "number": "447",
  },
  {
    "artist": "Taylor Swift",
    "song": "Blank Space",
    "year": "2014",
    "seconds": "40",
    "lyric": "Go down in flames",
    "number": "448",
  },
  {
    "artist": "Leona Lewis",
    "song": "Bleeding Love",
    "year": "2007",
    "seconds": "60",
    "lyric": "My heart melted to the ground",
    "number": "449",
  },
  {
    "artist": "Rascal Flatts",
    "song": "Bless the Broken Road",
    "year": "2004",
    "seconds": "60",
    "lyric": "Every sign pointed straight to you",
    "number": "450",
  },
  {
    "artist": "Anne Murray",
    "song": "Blessed Are the Believers",
    "year": "1981",
    "seconds": "60",
    "lyric": "Cold coffee cup, sun coming up",
    "number": "451",
  },
  {
    "artist": "Manfred Mann’s Earth Band",
    "song": "Blinded By the Light",
    "year": "1976",
    "seconds": "50",
    "lyric": "A boulder on my shoulder",
    "number": "452",
  },
  {
    "artist": "Violent Femmes",
    "song": "Blister in the Sun",
    "year": "1983",
    "seconds": "50",
    "lyric": "I’m high as a kite",
    "number": "453",
  },
  {
    "artist": "The Ramones",
    "song": "Blitzkrieg Bop",
    "year": "1976",
    "seconds": "50",
    "lyric": "Piling in the back seat",
    "number": "454",
  },
  {
    "artist": "Tanya Tucker",
    "song": "Blood Red and Goin’ Down",
    "year": "1973",
    "seconds": "40",
    "lyric": "Through his clenched teeth",
    "number": "455",
  },
  {
    "artist": "Soundgarden",
    "song": "Blow Up the Outside World",
    "year": "1996",
    "seconds": "50",
    "lyric": "The world bring you down",
    "number": "456",
  },
  {
    "artist": "Bob Dylan",
    "song": "Blowin’ in the Wind",
    "year": "1963",
    "seconds": "60",
    "lyric": "Before she sleeps in the sand?",
    "number": "457",
  },
  {
    "artist": "Carrie Underwood",
    "song": "Blown Away",
    "year": "2012",
    "seconds": "60",
    "lyric": "Dry lightning cracks across the skies",
    "number": "458",
  },
  {
    "artist": "Keith Urban",
    "song": "Blue Ain’t Your Color",
    "year": "2016",
    "seconds": "50",
    "lyric": "It’s so black and white",
    "number": "459",
  },
  {
    "artist": "Linda Ronstadt",
    "song": "Blue Bayou",
    "year": "1977",
    "seconds": "40",
    "lyric": "Saving nickels, saving dimes",
    "number": "460",
  },
  {
    "artist": "Jim Reeves",
    "song": "Blue Boy",
    "year": "1958",
    "seconds": "50",
    "lyric": "A twinkle in my eye",
    "number": "461",
  },
  {
    "artist": "Elvis Presley",
    "song": "Blue Christmas",
    "year": "1957",
    "seconds": "80",
    "lyric": "Decorations of red, on a green Xmas tree",
    "number": "462",
  },
  {
    "artist": "George Strait",
    "song": "Blue Clear Sky",
    "year": "1996",
    "seconds": "50",
    "lyric": "Fallin’ right into your hands",
    "number": "463",
  },
  {
    "artist": "Styx",
    "song": "Blue Collar Man (Long Nights)",
    "year": "1978",
    "seconds": "60",
    "lyric": "Keeping my eye to the keyhole",
    "number": "464",
  },
  {
    "artist": "Eiffel 65",
    "song": "Blue (Da Ba Dee)",
    "year": "1998",
    "seconds": "50",
    "lyric": "I have a blue house",
    "number": "465",
  },
  {
    "artist": "Elton John",
    "song": "Blue Eyes",
    "year": "1980",
    "seconds": "40",
    "lyric": "Holding back the tears",
    "number": "466",
  },
  {
    "artist": "Willie Nelson",
    "song": "Blue Eyes Crying the Rain",
    "year": "1975",
    "seconds": "60",
    "lyric": "When we kissed goodbye and parted",
    "number": "467",
  },
  {
    "artist": "Chris Isaak",
    "song": "Blue Hotel",
    "year": "1987",
    "seconds": "60",
    "lyric": "On a lonely highway",
    "number": "468",
  },
  {
    "artist": "David Bowie",
    "song": "Blue Jean",
    "year": "1984",
    "seconds": "40",
    "lyric": "A turned up nose",
    "number": "469",
  },
  {
    "artist": "Fats Domino",
    "song": "Blue Monday",
    "year": "1956",
    "seconds": "50",
    "lyric": "My money and my honey",
    "number": "470",
  },
  {
    "artist": "Van Morrison",
    "song": "Blue Money",
    "year": "1971",
    "seconds": "50",
    "lyric": "You search in your bag",
    "number": "471",
  },
  {
    "artist": "The Marcels",
    "song": "Blue Moon",
    "year": "1961",
    "seconds": "60",
    "lyric": "The moon had turned to gold",
    "number": "472",
  },
  {
    "artist": "Bill Monroe",
    "song": "Blue Moon of Kentucky",
    "year": "1955",
    "seconds": "50",
    "lyric": "Gone and left me blue",
    "number": "473",
  },
  {
    "artist": "Kenny Wayne Shepherd",
    "song": "Blue on Black",
    "year": "1997",
    "seconds": "70",
    "lyric": "Joker on Jack, match on a fire",
    "number": "474",
  },
  {
    "artist": "Bobby Vinton",
    "song": "Blue On Blue",
    "year": "1963",
    "seconds": "50",
    "lyric": "You wait with open arms",
    "number": "475",
  },
  {
    "artist": "Jim Reeves",
    "song": "Blue Side of Lonesome",
    "year": "1966",
    "seconds": "60",
    "lyric": "Right next to the Heartbreak Hotel",
    "number": "476",
  },
  {
    "artist": "Willie Nelson",
    "song": "Blue Skies",
    "year": "1978",
    "seconds": "50",
    "lyric": "The sun shinin’ so bright",
    "number": "477",
  },
  {
    "artist": "Carl Perkins",
    "song": "Blue Suede Shoes",
    "year": "1955",
    "seconds": "60",
    "lyric": "Burn my house, steal my car",
    "number": "478",
  },
  {
    "artist": "Burl Ives",
    "song": "Blue Tail Fly (Jimmy Crack Corn)",
    "year": "1944",
    "seconds": "70",
    "lyric": "He threw my master in the ditch",
    "number": "479",
  },
  {
    "artist": "Bobby Vinton",
    "song": "Blue Velvet",
    "year": "1963",
    "seconds": "50",
    "lyric": "The light from the stars",
    "number": "480",
  },
  {
    "artist": "Fats Domino",
    "song": "Blueberry Hill",
    "year": "1957",
    "seconds": "50",
    "lyric": "The wind in the willow",
    "number": "481",
  },
  {
    "artist": "Dinah Shore",
    "song": "Blues in the Night",
    "year": "1942",
    "seconds": "50",
    "lyric": "When I was in pigtails",
    "number": "482",
  },
  {
    "artist": "Fats Domino",
    "song": "Bo Weevil",
    "year": "1956",
    "seconds": "70",
    "lyric": "I’m sitting on this bale of hay",
    "number": "483",
  },
  {
    "artist": "The Oak Ridge Boys",
    "song": "Bobbie Sue",
    "year": "1982",
    "seconds": "40",
    "lyric": "And now she’s eighteen",
    "number": "484",
  },
  {
    "artist": "Sam Hunt",
    "song": "Body Like a Back Road",
    "year": "2017",
    "seconds": "50",
    "lyric": "Got braids in her hair",
    "number": "485",
  },
  {
    "artist": "Queen",
    "song": "Bohemian Rhapsody",
    "year": "1975",
    "seconds": "50",
    "lyric": "Sends shivers down my spine",
    "number": "486",
  },
  {
    "artist": "The Smothers Brothers",
    "song": "Boil Them Cabbage Down",
    "year": "1963",
    "seconds": "50",
    "lyric": "He chased the big hogs",
    "number": "487",
  },
  {
    "artist": "James Blunt",
    "song": "Bonfire Heart",
    "year": "2013",
    "seconds": "70",
    "lyric": "No one looks you in the eye",
    "number": "488",
  },
  {
    "artist": "Ella Mai",
    "song": "Boo’d Up",
    "year": "2018",
    "seconds": "60",
    "lyric": "Grab me by the waist, baby",
    "number": "489",
  },
  {
    "artist": "The Sylvers",
    "song": "Boogie Fever",
    "year": "1975",
    "seconds": "50",
    "lyric": "She turned the speaker down",
    "number": "490",
  },
  {
    "artist": "Heatwave",
    "song": "Boogie Nights",
    "year": "1976",
    "seconds": "60",
    "lyric": "You know how to do it",
    "number": "491",
  },
  {
    "artist": "A Taste of Honey",
    "song": "Boogie Oogie Oogie",
    "year": "1978",
    "seconds": "40",
    "lyric": "No time to waste",
    "number": "492",
  },
  {
    "artist": "Earth, Wind & Fire and The Emotions",
    "song": "Boogie Wonderland",
    "year": "1979",
    "seconds": "40",
    "lyric": "Midnight creeps so slowly",
    "number": "493",
  },
  {
    "artist": "Bette Midler",
    "song": "Boogie Woogie Bugle Boy",
    "year": "1973",
    "seconds": "70",
    "lyric": "He blows it eight to the bar",
    "number": "494",
  },
  {
    "artist": "X Ambassadors",
    "song": "Boom",
    "year": "2019",
    "seconds": "50",
    "lyric": "Sing the blues for you",
    "number": "495",
  },
  {
    "artist": "The Black Eyed Peas",
    "song": "Boom Boom Pow",
    "year": "2009",
    "seconds": "40",
    "lyric": "I’m steppin’ on leprechauns",
    "number": "496",
  },
  {
    "artist": "Shaggy",
    "song": "Boombastic",
    "year": "1995",
    "seconds": "70",
    "lyric": "A turtle crawling out of me shell",
    "number": "497",
  },
  {
    "artist": "Brooks & Dunn",
    "song": "Boot Scootin’ Boogie",
    "year": "1992",
    "seconds": "50",
    "lyric": "Whiskey, women, music and smoke",
    "number": "498",
  },
  {
    "artist": "Bob Dylan",
    "song": "Boots of Spanish Leather",
    "year": "1963",
    "seconds": "50",
    "lyric": "Diamonds from the deepest ocean",
    "number": "499",
  },
  {
    "artist": "Dan Seals",
    "song": "Bop",
    "year": "1985",
    "seconds": "60",
    "lyric": "Roll up your old blue jeans",
    "number": "500",
  },
  {
    "artist": "Billie Eilish",
    "song": "Bored",
    "year": "2017",
    "seconds": "50",
    "lyric": "You walk out the door",
    "number": "501",
  },
  {
    "artist": "Blink 182",
    "song": "Bored to Death",
    "year": "2016",
    "seconds": "60",
    "lyric": "Lost and cold and fading fast",
    "number": "502",
  },
  {
    "artist": "Roger Williams",
    "song": "Born Free",
    "year": "1966",
    "seconds": "40",
    "lyric": "No walls divide you",
    "number": "503",
  },
  {
    "artist": "Bruce Springsteen",
    "song": "Born in the U.S.A.",
    "year": "1984",
    "seconds": "60",
    "lyric": "Ten years burning down the road",
    "number": "504",
  },
  {
    "artist": "Creedence Clearwater Revival",
    "song": "Born on the Bayou",
    "year": "1969",
    "seconds": "50",
    "lyric": "My old hound dog barkin’",
    "number": "505",
  },
  {
    "artist": "Lady Gaga",
    "song": "Born This Way",
    "year": "2011",
    "seconds": "60",
    "lyric": "In the glass of my boudoir",
    "number": "506",
  },
  {
    "artist": "Steppenwolf",
    "song": "Born to be Wild",
    "year": "1968",
    "seconds": "50",
    "lyric": "I like smoke and lightning",
    "number": "507",
  },
  {
    "artist": "Hank Williams Jr.",
    "song": "Born to Boogie",
    "year": "1987",
    "seconds": "50",
    "lyric": "A guitar in my hand",
    "number": "508",
  },
  {
    "artist": "Bruce Springsteen",
    "song": "Born to Run",
    "year": "1975",
    "seconds": "60",
    "lyric": "Rips the bones from your back",
    "number": "509",
  },
  {
    "artist": "Joni Mitchell",
    "song": "Both Sides Now",
    "year": "1969",
    "seconds": "70",
    "lyric": "And ice cream castles in the air",
    "number": "510",
  },
  {
    "artist": "NOFX",
    "song": "Bottles to the Ground",
    "year": "2000",
    "seconds": "40",
    "lyric": "Smashing of the glass",
    "number": "511",
  },
  {
    "artist": "Green Day",
    "song": "Boulevard of Broken Dreams",
    "year": "2004",
    "seconds": "50",
    "lyric": "I walk a lonely road",
    "number": "512",
  },
  {
    "artist": "BTS (feat. Halsey)",
    "song": "Boy with Luv",
    "year": "2019",
    "seconds": "40",
    "lyric": "Stronger than a moment",
    "number": "513",
  },
  {
    "artist": "Ariana Grande and Social House",
    "song": "Boyfriend",
    "year": "2019",
    "seconds": "50",
    "lyric": "I’m a train wreck too",
    "number": "514",
  },
  {
    "artist": "Blake Shelton (ft. Pistol Annies & Friends)",
    "song": "Boys ‘Round Here",
    "year": "2013",
    "seconds": "50",
    "lyric": "Runnin’ them red dirt roads",
    "number": "515",
  },
  {
    "artist": "Pink Floyd",
    "song": "Brain Damage",
    "year": "1973",
    "seconds": "80",
    "lyric": "Lock the door and throw away the key",
    "number": "516",
  },
  {
    "artist": "Melanie",
    "song": "Brand New Key",
    "year": "1971",
    "seconds": "60",
    "lyric": "I roller skated to your door",
    "number": "517",
  },
  {
    "artist": "Brooks & Dunn",
    "song": "Brand New Man",
    "year": "1991",
    "seconds": "60",
    "lyric": "By the fire in your touch",
    "number": "518",
  },
  {
    "artist": "Looking Glass",
    "song": "Brandy (You’re a Fine Girl)",
    "year": "1972",
    "seconds": "60",
    "lyric": "Feel the ocean fall and rise",
    "number": "519",
  },
  {
    "artist": "The Pretenders",
    "song": "Brass in Pocket",
    "year": "1980",
    "seconds": "40",
    "lyric": "Gonna use my fingers",
    "number": "520",
  },
  {
    "artist": "Sara Bareilles",
    "song": "Brave",
    "year": "2013",
    "seconds": "40",
    "lyric": "Stop holding your tongue",
    "number": "521",
  },
  {
    "artist": "Ariana Grande (ft. Zedd)",
    "song": "Break Free",
    "year": "2014",
    "seconds": "70",
    "lyric": "By the hands of a broken heart",
    "number": "522",
  },
  {
    "artist": "Dua Lipa",
    "song": "Break My Heart",
    "year": "2020",
    "seconds": "50",
    "lyric": "Am I falling in love?",
    "number": "523",
  },
  {
    "artist": "George Hamilton IV",
    "song": "Break My Mind",
    "year": "1967",
    "seconds": "60",
    "lyric": "If you say goodbye to me",
    "number": "524",
  },
  {
    "artist": "Keith Urban",
    "song": "Break on Me",
    "year": "2015",
    "seconds": "60",
    "lyric": "Put your head on my chest",
    "number": "525",
  },
  {
    "artist": "The Doors",
    "song": "Break on Through",
    "year": "1967",
    "seconds": "50",
    "lyric": "The day destroys the night",
    "number": "526",
  },
  {
    "artist": "Sam Hunt",
    "song": "Break Up in a Small Town",
    "year": "2015",
    "seconds": "40",
    "lyric": "Hiding behind her sister",
    "number": "527",
  },
  {
    "artist": "The Stylistics",
    "song": "Break Up to Make Up",
    "year": "1973",
    "seconds": "40",
    "lyric": "You’re drivin’ me wild",
    "number": "528",
  },
  {
    "artist": "Ariana Grande",
    "song": "Break Up with Your Girlfriend, I’m Bored",
    "year": "2019",
    "seconds": "40",
    "lyric": "Look at your face",
    "number": "529",
  },
  {
    "artist": "Kelly Clarkson",
    "song": "Breakaway",
    "year": "2004",
    "seconds": "50",
    "lyric": "Sleep under a palm tree",
    "number": "530",
  },
  {
    "artist": "Tom Petty & the Heartbreakers",
    "song": "Breakdown",
    "year": "1977",
    "seconds": "60",
    "lyric": "I’m standing here, can’t you see",
    "number": "531",
  },
  {
    "artist": "Deep Blue Something",
    "song": "Breakfast at Tiffany’s",
    "year": "1995",
    "seconds": "60",
    "lyric": "I think I remember the film",
    "number": "532",
  },
  {
    "artist": "Ollie & Jerry",
    "song": "Breakin’… There’s No Stopping Us",
    "year": "1984",
    "seconds": "60",
    "lyric": "We have waited far too long",
    "number": "533",
  },
  {
    "artist": "Neil Sedaka",
    "song": "Breaking Up Is Hard To Do",
    "year": "1962",
    "seconds": "60",
    "lyric": "Kissed me all through the night",
    "number": "534",
  },
  {
    "artist": "Pink Floyd",
    "song": "Breathe",
    "year": "1973",
    "seconds": "60",
    "lyric": "Run, rabbit, run, dig that hole",
    "number": "535",
  },
  {
    "artist": "Faith Hill",
    "song": "Breathe",
    "year": "1999",
    "seconds": "50",
    "lyric": "Wrapped up in your arms",
    "number": "536",
  },
  {
    "artist": "Ariana Grande",
    "song": "Breathin",
    "year": "2018",
    "seconds": "50",
    "lyric": "Way up into the clouds",
    "number": "537",
  },
  {
    "artist": "The Commodores",
    "song": "Brick House",
    "year": "1977",
    "seconds": "70",
    "lyric": "Knock a strong man to his knees",
    "number": "538",
  },
  {
    "artist": "Simon & Garfunkel",
    "song": "Bridge Over Troubled Water",
    "year": "1970",
    "seconds": "50",
    "lyric": "I will lay me down",
    "number": "539",
  },
  {
    "artist": "Van Morrison",
    "song": "Bright Side of the Road",
    "year": "1979",
    "seconds": "60",
    "lyric": "In the twinkling of an eye",
    "number": "540",
  },
  {
    "artist": "Bruce Springsteen",
    "song": "Brilliant Disguise",
    "year": "1987",
    "seconds": "60",
    "lyric": "Tucked in shame, underneath your pillow",
    "number": "541",
  },
  {
    "artist": "Sam Cooke",
    "song": "Bring It On Home To Me",
    "year": "1962",
    "seconds": "60",
    "lyric": "Give you jewelry and money too",
    "number": "542",
  },
  {
    "artist": "Evanescence",
    "song": "Bring Me to Life",
    "year": "2003",
    "seconds": "60",
    "lyric": "Wake me up inside, save me",
    "number": "543",
  },
  {
    "artist": "Jo Dee Messina and Tim McGraw",
    "song": "Bring On the Rain",
    "year": "2001",
    "seconds": "50",
    "lyric": "But I’m not dead, no",
    "number": "544",
  },
  {
    "artist": "Anne Murray",
    "song": "Broken Hearted Me",
    "year": "1979",
    "seconds": "50",
    "lyric": "Put all the pieces together",
    "number": "545",
  },
  {
    "artist": "Stories",
    "song": "Brother Louie",
    "year": "1973",
    "seconds": "60",
    "lyric": "To meet his mama and papa",
    "number": "546",
  },
  {
    "artist": "Neil Diamond",
    "song": "Brother Love’s Travelling Salvation Show",
    "year": "1969",
    "seconds": "50",
    "lyric": "And grab the old ladies",
    "number": "547",
  },
  {
    "artist": "Van Morrison",
    "song": "Brown Eyed Girl",
    "year": "1967",
    "seconds": "50",
    "lyric": "Standing in the sunlight laughing",
    "number": "548",
  },
  {
    "artist": "Waylon Jennings",
    "song": "Brown Eyed Handsome Man",
    "year": "1969",
    "seconds": "60",
    "lyric": "Back in history, three thousand years",
    "number": "549",
  },
  {
    "artist": "Rolling Stones",
    "song": "Brown Sugar",
    "year": "1971",
    "seconds": "50",
    "lyric": "You taste so good, baby",
    "number": "550",
  },
  {
    "artist": "Weezer",
    "song": "Buddy Holly",
    "year": "1994",
    "seconds": "70",
    "lyric": "Bang, bang, a knock on the door",
    "number": "551",
  },
  {
    "artist": "Neneh Cherry",
    "song": "Buffalo Stance",
    "year": "1988",
    "seconds": "50",
    "lyric": "Get a gold tooth smile",
    "number": "552",
  },
  {
    "artist": "The Foundations",
    "song": "Build Me Up Buttercup",
    "year": "1968",
    "seconds": "50",
    "lyric": "To you I’m a toy",
    "number": "553",
  },
  {
    "artist": "Smashing Pumpkins",
    "song": "Bullet with Butterfly Wings",
    "year": "1995",
    "seconds": "50",
    "lyric": "A rat in a cage",
    "number": "554",
  },
  {
    "artist": "Jethro Tull",
    "song": "Bungle in the Jungle",
    "year": "1974",
    "seconds": "40",
    "lyric": "Full of crocodile nasties",
    "number": "555",
  },
  {
    "artist": "Soundgarden",
    "song": "Burden in My Hand",
    "year": "1996",
    "seconds": "70",
    "lyric": "Close your eyes and bow your head",
    "number": "556",
  },
  {
    "artist": "Ellie Goulding",
    "song": "Burn",
    "year": "2013",
    "seconds": "50",
    "lyric": "We’ll be raising our hands",
    "number": "557",
  },
  {
    "artist": "Tina Arena",
    "song": "Burn",
    "year": "1997",
    "seconds": "70",
    "lyric": "Lay down on your bed of coals",
    "number": "558",
  },
  {
    "artist": "Usher",
    "song": "Burn",
    "year": "2004",
    "seconds": "50",
    "lyric": "Hold on and hurt you",
    "number": "559",
  },
  {
    "artist": "Talking Heads",
    "song": "Burning Down the House",
    "year": "1983",
    "seconds": "70",
    "lyric": "Sure can sweep me off my feet",
    "number": "560",
  },
  {
    "artist": "Cam",
    "song": "Burning House",
    "year": "2015",
    "seconds": "70",
    "lyric": "Two of us went up in smoke",
    "number": "561",
  },
  {
    "artist": "Jason Aldean",
    "song": "Burnin’ It Down",
    "year": "2014",
    "seconds": "50",
    "lyric": "You feel like an angel",
    "number": "562",
  },
  {
    "artist": "Elvis Presley",
    "song": "Burning Love",
    "year": "1972",
    "seconds": "50",
    "lyric": "I feel my temperature rising",
    "number": "563",
  },
  {
    "artist": "Billie Eilish",
    "song": "Bury a Friend",
    "year": "2019",
    "seconds": "40",
    "lyric": "My limbs are frozen",
    "number": "564",
  },
  {
    "artist": "The Hollies",
    "song": "Bus Stop",
    "year": "1966",
    "seconds": "40",
    "lyric": "Please share my umbrella",
    "number": "565",
  },
  {
    "artist": "Young M.C",
    "song": "Bust a Move",
    "year": "1989",
    "seconds": "50",
    "lyric": "You nearly split your pants",
    "number": "566",
  },
  {
    "artist": "Ray Charles",
    "song": "Busted",
    "year": "1963",
    "seconds": "50",
    "lyric": "A big stack of bills",
    "number": "567",
  },
  {
    "artist": "Billy Ray Cyrus",
    "song": "Busy Man",
    "year": "1998",
    "seconds": "40",
    "lyric": "His basketball in hand",
    "number": "568",
  },
  {
    "artist": "Keith Urban",
    "song": "But For the Grace of God",
    "year": "2000",
    "seconds": "60",
    "lyric": "But the shoutin’ is so loud",
    "number": "569",
  },
  {
    "artist": "Andy Williams",
    "song": "Butterfly",
    "year": "1957",
    "seconds": "60",
    "lyric": "Honey drips from your sweet lips",
    "number": "570",
  },
  {
    "artist": "Crazy Town",
    "song": "Butterfly",
    "year": "2000",
    "seconds": "50",
    "lyric": "Trapped on a short leash",
    "number": "571",
  },
  {
    "artist": "Ronnie Milsap",
    "song": "Button Off My Shirt",
    "year": "1988",
    "seconds": "50",
    "lyric": "The clock’s been my friend",
    "number": "572",
  },
  {
    "artist": "Dinah Shore",
    "song": "Buttons and Bows",
    "year": "1948",
    "seconds": "60",
    "lyric": "And the cactus hurts my toes",
    "number": "573",
  },
  {
    "artist": "Chris Janson",
    "song": "Buy Me a Boat",
    "year": "2015",
    "seconds": "50",
    "lyric": "White trash and blue collar",
    "number": "574",
  },
  {
    "artist": "Runaway June",
    "song": "Buy My Own Drinks",
    "year": "2019",
    "seconds": "60",
    "lyric": "If you could fill my glass",
    "number": "575",
  },
  {
    "artist": "Soundgarden",
    "song": "By Crooked Steps",
    "year": "2013",
    "seconds": "60",
    "lyric": "It cuts a deep deep river",
    "number": "576",
  },
  {
    "artist": "Glen Campbell",
    "song": "By the Time I Get to Phoenix",
    "year": "1967",
    "seconds": "60",
    "lyric": "I left hanging on her door",
    "number": "577",
  },
  {
    "artist": "Red Hot Chili Peppers",
    "song": "By the Way",
    "year": "2002",
    "seconds": "70",
    "lyric": "Standing in line to see the show",
    "number": "578",
  },
  {
    "artist": "Jo Dee Messina",
    "song": "Bye Bye",
    "year": "1998",
    "seconds": "40",
    "lyric": "Standing in the doorway",
    "number": "579",
  },
  {
    "artist": "NSYNC",
    "song": "Bye Bye Bye",
    "year": "2000",
    "seconds": "60",
    "lyric": "I’m checkin’ out, I’m signin’ off",
    "number": "580",
  },
  {
    "artist": "The Everly Brothers",
    "song": "Bye Bye Love",
    "year": "1957",
    "seconds": "40",
    "lyric": "Counting the stars above",
    "number": "581",
  },
  {
    "artist": "Liza Minnelli",
    "song": "Cabaret",
    "year": "1972",
    "seconds": "40",
    "lyric": "Come taste the wine",
    "number": "582",
  },
  {
    "artist": "Bruce Springsteen",
    "song": "Cadillac Ranch",
    "year": "1981",
    "seconds": "60",
    "lyric": "Long and dark, shiny and black",
    "number": "583",
  },
  {
    "artist": "Neil Sedaka",
    "song": "Calendar Girl",
    "year": "1961",
    "seconds": "50",
    "lyric": "My heart’s in a whirl",
    "number": "584",
  },
  {
    "artist": "The Mamas & the Papas",
    "song": "California Dreamin’",
    "year": "1965",
    "seconds": "50",
    "lyric": "All the leaves are brown",
    "number": "585",
  },
  {
    "artist": "Beach Boys",
    "song": "California Girls",
    "year": "1965",
    "seconds": "50",
    "lyric": "East coast girls are hip",
    "number": "586",
  },
  {
    "artist": "Red Hot Chili Peppers",
    "song": "Californication",
    "year": "2000",
    "seconds": "60",
    "lyric": "Be my fairy to the world",
    "number": "587",
  },
  {
    "artist": "Blondie",
    "song": "Call Me",
    "year": "1980",
    "seconds": "50",
    "lyric": "Cover me with kisses, baby",
    "number": "588",
  },
  {
    "artist": "Carly Rae Jepsen",
    "song": "Call Me Maybe",
    "year": "2011",
    "seconds": "70",
    "lyric": "I threw a wish in the well",
    "number": "589",
  },
  {
    "artist": "Lynyrd Skynyrd",
    "song": "Call Me the Breeze",
    "year": "1974",
    "seconds": "60",
    "lyric": "I keep blowin’ down the road",
    "number": "590",
  },
  {
    "artist": "Evanescence",
    "song": "Call Me When You’re Sober",
    "year": "2006",
    "seconds": "60",
    "lyric": "And I’m sick of the lie",
    "number": "591",
  },
  {
    "artist": "Chicago",
    "song": "Call On Me",
    "year": "1974",
    "seconds": "50",
    "lyric": "You can count on me",
    "number": "592",
  },
  {
    "artist": "John Denver",
    "song": "Calypso",
    "year": "1975",
    "seconds": "50",
    "lyric": "The dolphin who guides you",
    "number": "593",
  },
  {
    "artist": "Blake Shelton",
    "song": "Came Here To Forget",
    "year": "2016",
    "seconds": "60",
    "lyric": "Go ahead and check your phone",
    "number": "594",
  },
  {
    "artist": "Travis Tritt",
    "song": "Can I Trust You With My Heart?",
    "year": "1992",
    "seconds": "60",
    "lyric": "I know right from the start",
    "number": "595",
  },
  {
    "artist": "Elton John",
    "song": "Can You Feel the Love Tonight?",
    "year": "1994",
    "seconds": "50",
    "lyric": "And it sees me through",
    "number": "596",
  },
  {
    "artist": "George Hamilton IV",
    "song": "Canadian Pacific",
    "year": "1969",
    "seconds": "50",
    "lyric": "Listening to the sea gulls",
    "number": "597",
  },
  {
    "artist": "Andy Williams",
    "song": "Canadian Sunset",
    "year": "1956",
    "seconds": "70",
    "lyric": "In your eyes I found the love",
    "number": "598",
  },
  {
    "artist": "Dawn",
    "song": "Candida",
    "year": "1970",
    "seconds": "70",
    "lyric": "Take my hand and I’ll lead ya",
    "number": "599",
  },
  {
    "artist": "Elton John",
    "song": "Candle in the Wind 1997",
    "year": "1997",
    "seconds": "50",
    "lyric": "Empty days without your smile",
    "number": "600",
  },
  {
    "artist": "Dinah Shore",
    "song": "Candy",
    "year": "1945",
    "seconds": "60",
    "lyric": "A sweet tooth for my sweetheart",
    "number": "601",
  },
  {
    "artist": "The 4 Seasons",
    "song": "Candy Girl",
    "year": "1963",
    "seconds": "50",
    "lyric": "And it’s plain to see",
    "number": "602",
  },
  {
    "artist": "George Morgan",
    "song": "Candy Kisses",
    "year": "1949",
    "seconds": "60",
    "lyric": "Build a castle out of dreams",
    "number": "603",
  },
  {
    "artist": "Christina Aguilera",
    "song": "Candyman",
    "year": "2007",
    "seconds": "60",
    "lyric": "Tattoos up and down his arm",
    "number": "604",
  },
  {
    "artist": "Tim McGraw",
    "song": "Can’t Be Really Gone",
    "year": "1995",
    "seconds": "40",
    "lyric": "The two of hearts",
    "number": "605",
  },
  {
    "artist": "The Beatles",
    "song": "Can’t Buy Me Love",
    "year": "1964",
    "seconds": "60",
    "lyric": "You don’t need no diamond rings",
    "number": "606",
  },
  {
    "artist": "Bad Company",
    "song": "Can’t Get Enough",
    "year": "1974",
    "seconds": "60",
    "lyric": "Hang me up in your doorway",
    "number": "607",
  },
  {
    "artist": "Smash Mouth",
    "song": "Can’t Get Enough of You Baby",
    "year": "1998",
    "seconds": "40",
    "lyric": "Wrap around me twice",
    "number": "608",
  },
  {
    "artist": "Andy Williams",
    "song": "Can’t Get Used to Losing You",
    "year": "1963",
    "seconds": "50",
    "lyric": "I heard her say “Hello”",
    "number": "609",
  },
  {
    "artist": "Kylie Minogue",
    "song": "Can’t Get You Out of My Head",
    "year": "2001",
    "seconds": "70",
    "lyric": "Locked in your heart, set me free",
    "number": "610",
  },
  {
    "artist": "Elvis Presley",
    "song": "Can’t Help Falling in Love",
    "year": "1961",
    "seconds": "70",
    "lyric": "A river flows surely to the sea",
    "number": "611",
  },
  {
    "artist": "Mariah Carey",
    "song": "Can’t Let Go",
    "year": "1991",
    "seconds": "50",
    "lyric": "Your eyes captured my soul",
    "number": "612",
  },
  {
    "artist": "Barry Manilow",
    "song": "Can’t Smile Without You",
    "year": "1978",
    "seconds": "60",
    "lyric": "I feel sad when you’re sad",
    "number": "613",
  },
  {
    "artist": "Van Halen",
    "song": "Can’t Stop Lovin’ You",
    "year": "1995",
    "seconds": "40",
    "lyric": "I’m twisted and tied",
    "number": "614",
  },
  {
    "artist": "Bryan Adams",
    "song": "Can’t Stop This Thing We Started",
    "year": "1991",
    "seconds": "40",
    "lyric": "A dozen other guys",
    "number": "615",
  },
  {
    "artist": "Frankie Valli",
    "song": "Can’t Take My Eyes Off You",
    "year": "1967",
    "seconds": "50",
    "lyric": "I thank God I’m alive",
    "number": "616",
  },
  {
    "artist": "Rolling Stones",
    "song": "Can’t You Hear Me Knocking",
    "year": "1971",
    "seconds": "60",
    "lyric": "I’ve been begging on my knees",
    "number": "617",
  },
  {
    "artist": "Herman’s Hermits",
    "song": "Can’t You Hear My Heartbeat",
    "year": "1965",
    "seconds": "60",
    "lyric": "To go and see the preacher",
    "number": "618",
  },
  {
    "artist": "The Marshall Tucker Band",
    "song": "Can’t You See",
    "year": "1973",
    "seconds": "70",
    "lyric": "Find me a hole in the wall",
    "number": "619",
  },
  {
    "artist": "The Dave Clark Five",
    "song": "Can’t You See That She’s Mine",
    "year": "1964",
    "seconds": "60",
    "lyric": "Together for a long, long time",
    "number": "620",
  },
  {
    "artist": "Rose Royce",
    "song": "Car Wash",
    "year": "1976",
    "seconds": "60",
    "lyric": "Work my fingers to the bone",
    "number": "621",
  },
  {
    "artist": "Taylor Swift",
    "song": "cardigan",
    "year": "2020",
    "seconds": "40",
    "lyric": "High heels on cobblestones",
    "number": "622",
  },
  {
    "artist": "Bessie Smith",
    "song": "Careless Love Blues",
    "year": "1925",
    "seconds": "70",
    "lyric": "Night and day, I weep and moan",
    "number": "623",
  },
  {
    "artist": "Joni Mitchell",
    "song": "Carey",
    "year": "1971",
    "seconds": "50",
    "lyric": "Last night I couldn’t sleep",
    "number": "624",
  },
  {
    "artist": "Billy Ocean",
    "song": "Caribbean Queen (No More Love on the Run)",
    "year": "1984",
    "seconds": "60",
    "lyric": "She said I was the tiger",
    "number": "625",
  },
  {
    "artist": "Natalie Merchant",
    "song": "Carnival",
    "year": "1995",
    "seconds": "40",
    "lyric": "They crowded around me",
    "number": "626",
  },
  {
    "artist": "The Hollies",
    "song": "Carrie Anne",
    "year": "1967",
    "seconds": "50",
    "lyric": "And I’ll be your teacher",
    "number": "627",
  },
  {
    "artist": "George Strait",
    "song": "Carried Away",
    "year": "1996",
    "seconds": "50",
    "lyric": "Planted firmly on the ground",
    "number": "628",
  },
  {
    "artist": "Crosby, Stills, Nash & Young",
    "song": "Carry On",
    "year": "1970",
    "seconds": "60",
    "lyric": "New eyes to see the dawn",
    "number": "629",
  },
  {
    "artist": "Kansas",
    "song": "Carry On Wayward Son",
    "year": "1976",
    "seconds": "50",
    "lyric": "But I flew too high",
    "number": "630",
  },
  {
    "artist": "The Beatles",
    "song": "Carry that Weight",
    "year": "1969",
    "seconds": "60",
    "lyric": "I never give you my pillow",
    "number": "631",
  },
  {
    "artist": "George Strait",
    "song": "Carrying Your Love With Me",
    "year": "1997",
    "seconds": "50",
    "lyric": "Stuck out in the rain",
    "number": "632",
  },
  {
    "artist": "Ed Sheeran",
    "song": "Castle on the Hill",
    "year": "2017",
    "seconds": "50",
    "lyric": "And tasted the sweet perfume",
    "number": "633",
  },
  {
    "artist": "David Bowie",
    "song": "Cat People (Putting Out Fire)",
    "year": "1982",
    "seconds": "70",
    "lyric": "I can stare for a thousand years",
    "number": "634",
  },
  {
    "artist": "Ted Nugent",
    "song": "Cat Scratch Fever",
    "year": "1977",
    "seconds": "60",
    "lyric": "I went to see the doctor",
    "number": "635",
  },
  {
    "artist": "Perry Como",
    "song": "Catch a Falling Star",
    "year": "1958",
    "seconds": "60",
    "lyric": "And put it in your pocket",
    "number": "636",
  },
  {
    "artist": "The Dave Clark Five",
    "song": "Catch Us If You Can",
    "year": "1965",
    "seconds": "60",
    "lyric": "Time to get a move on",
    "number": "637",
  },
  {
    "artist": "The Everly Brothers",
    "song": "Cathy’s Clown",
    "year": "1960",
    "seconds": "80",
    "lyric": "I die each time I hear this sound",
    "number": "638",
  },
  {
    "artist": "Harry Chapin",
    "song": "Cat’s in the Cradle",
    "year": "1974",
    "seconds": "60",
    "lyric": "And the man in the moon",
    "number": "639",
  },
  {
    "artist": ".38 Special",
    "song": "Caught Up in You",
    "year": "1982",
    "seconds": "60",
    "lyric": "Got me down on my knees",
    "number": "640",
  },
  {
    "artist": "Simon & Garfunkel",
    "song": "Cecilia",
    "year": "1970",
    "seconds": "70",
    "lyric": "I got up to wash my face",
    "number": "641",
  },
  {
    "artist": "Three Dog Night",
    "song": "Celebrate",
    "year": "1970",
    "seconds": "60",
    "lyric": "Slippin’ away, sittin’ on a pillow",
    "number": "642",
  },
  {
    "artist": "Kool & the Gang",
    "song": "Celebration",
    "year": "1980",
    "seconds": "60",
    "lyric": "A party goin’ on right here",
    "number": "643",
  },
  {
    "artist": "Brad Paisley",
    "song": "Celebrity",
    "year": "2003",
    "seconds": "60",
    "lyric": "I can throw a major fit",
    "number": "644",
  },
  {
    "artist": "Hole",
    "song": "Celebrity Skin",
    "year": "1998",
    "seconds": "60",
    "lyric": "I wake up in my makeup",
    "number": "645",
  },
  {
    "artist": "J. Geils Band",
    "song": "Centerfold",
    "year": "1981",
    "seconds": "60",
    "lyric": "Slipped me notes under the desk",
    "number": "646",
  },
  {
    "artist": "B*Witched",
    "song": "C’est La Vie",
    "year": "1998",
    "seconds": "40",
    "lyric": "Throw down your ladder",
    "number": "647",
  },
  {
    "artist": "Mr. C The Slide Man",
    "song": "Cha-Cha Slide",
    "year": "2000",
    "seconds": "40",
    "lyric": "Right foot, two stomps",
    "number": "648",
  },
  {
    "artist": "Sam Cooke",
    "song": "Chain Gang",
    "year": "1960",
    "seconds": "70",
    "lyric": "I’m thirsty, my work is so hard",
    "number": "649",
  },
  {
    "artist": "Bishop Briggs",
    "song": "Champion",
    "year": "2019",
    "seconds": "60",
    "lyric": "A million times in my head",
    "number": "650",
  },
  {
    "artist": "Johnny Mathis",
    "song": "Chances Are",
    "year": "1957",
    "seconds": "60",
    "lyric": "You think my heart’s your Valentine",
    "number": "651",
  },
  {
    "artist": "Sia",
    "song": "Chandelier",
    "year": "2014",
    "seconds": "60",
    "lyric": "Feel my tears as they dry",
    "number": "652",
  },
  {
    "artist": "Frank Ocean",
    "song": "Chanel",
    "year": "2017",
    "seconds": "50",
    "lyric": "Swimming laps through pool water",
    "number": "653",
  },
  {
    "artist": "Eric Clapton",
    "song": "Change the World",
    "year": "1996",
    "seconds": "60",
    "lyric": "Be king even for a day",
    "number": "654",
  },
  {
    "artist": "David Bowie",
    "song": "Changes",
    "year": "1972",
    "seconds": "60",
    "lyric": "And my time was running wild",
    "number": "655",
  },
  {
    "artist": "The Big Bopper",
    "song": "Chantilly Lace",
    "year": "1958",
    "seconds": "50",
    "lyric": "And giggle in the talk",
    "number": "656",
  },
  {
    "artist": "The Dixie Cups",
    "song": "Chapel of Love",
    "year": "1964",
    "seconds": "70",
    "lyric": "Bells will ring, the sun will shine",
    "number": "657",
  },
  {
    "artist": "The Coasters",
    "song": "Charlie Brown",
    "year": "1959",
    "seconds": "60",
    "lyric": "Who’s always writing on the wall?",
    "number": "658",
  },
  {
    "artist": "Snow Patrol",
    "song": "Chasing Cars",
    "year": "2006",
    "seconds": "50",
    "lyric": "Here in your perfect eyes",
    "number": "659",
  },
  {
    "artist": "Adele",
    "song": "Chasing Pavements",
    "year": "2008",
    "seconds": "50",
    "lyric": "And fly around in circles",
    "number": "660",
  },
  {
    "artist": "Alan Jackson",
    "song": "Chasin’ That Neon Rainbow",
    "year": "1990",
    "seconds": "60",
    "lyric": "I was rocking in the cradle",
    "number": "661",
  },
  {
    "artist": "Alan Jackson",
    "song": "Chattahoochee",
    "year": "1993",
    "seconds": "50",
    "lyric": "We fogged up the windows",
    "number": "662",
  },
  {
    "artist": "Red Foley",
    "song": "Chattanoogie Shoe Shine Boy",
    "year": "1950",
    "seconds": "50",
    "lyric": "He charges you a nickel",
    "number": "663",
  },
  {
    "artist": "ZZ Top",
    "song": "Cheap Sunglasses",
    "year": "1980",
    "seconds": "60",
    "lyric": "And I followed her all night",
    "number": "664",
  },
  {
    "artist": "Sia (ft. Sean Paul)",
    "song": "Cheap Thrills",
    "year": "2016",
    "seconds": "50",
    "lyric": "Put my high heels on",
    "number": "665",
  },
  {
    "artist": "John Mellencamp",
    "song": "Check It Out",
    "year": "1988",
    "seconds": "50",
    "lyric": "Playing football with the kids",
    "number": "666",
  },
  {
    "artist": "Beyoncé (ft. Bun B & Slim Thug)",
    "song": "Check On It",
    "year": "2005",
    "seconds": "80",
    "lyric": "Dip it, pop it, twerk it, stop it",
    "number": "667",
  },
  {
    "artist": "George Strait",
    "song": "Check Yes or No",
    "year": "1995",
    "seconds": "60",
    "lyric": "Kissed me on the school bus",
    "number": "668",
  },
  {
    "artist": "Joni Mitchell",
    "song": "Chelsea Morning",
    "year": "1969",
    "seconds": "60",
    "lyric": "And a bowl of oranges too",
    "number": "669",
  },
  {
    "artist": "Kool & the Gang",
    "song": "Cherish",
    "year": "1985",
    "seconds": "60",
    "lyric": "I lay down by your side",
    "number": "670",
  },
  {
    "artist": "The Association",
    "song": "Cherish",
    "year": "1966",
    "seconds": "80",
    "lyric": "You are drivin’ me out of my mind",
    "number": "671",
  },
  {
    "artist": "John Mellencamp",
    "song": "Cherry Bomb",
    "year": "1987",
    "seconds": "50",
    "lyric": "Our hearts were really thumpin’",
    "number": "672",
  },
  {
    "artist": "Neil Diamond",
    "song": "Cherry, Cherry",
    "year": "1966",
    "seconds": "50",
    "lyric": "Girl, I can’t stay long",
    "number": "673",
  },
  {
    "artist": "Perry Como",
    "song": "Chi-Baba, Chi-Baba (My Bambino Go to Sleep)",
    "year": "1947",
    "seconds": "50",
    "lyric": "Your doll is sleepy too",
    "number": "674",
  },
  {
    "artist": "Daddy Dewdrop",
    "song": "Chick-A-Boom (Don’t Ya Jes’ Love It)",
    "year": "1971",
    "seconds": "40",
    "lyric": "In a black bikini",
    "number": "675",
  },
  {
    "artist": "Zac Brown Band",
    "song": "Chicken Fried",
    "year": "2008",
    "seconds": "40",
    "lyric": "Sweet tea, pecan pie",
    "number": "676",
  },
  {
    "artist": "Bob Dylan",
    "song": "Chimes of Freedom",
    "year": "1964",
    "seconds": "40",
    "lyric": "A cloud’s white curtain",
    "number": "677",
  },
  {
    "artist": "David Bowie",
    "song": "China Girl",
    "year": "1983",
    "seconds": "60",
    "lyric": "In the white of my eyes",
    "number": "678",
  },
  {
    "artist": "Doobie Brothers",
    "song": "China Grove",
    "year": "1973",
    "seconds": "50",
    "lyric": "On a sleepy little town",
    "number": "679",
  },
  {
    "artist": "Darlene Love",
    "song": "Christmas (Baby, Please Come Home)",
    "year": "1963",
    "seconds": "50",
    "lyric": "Pretty lights on the tree",
    "number": "680",
  },
  {
    "artist": "Bryan Adams",
    "song": "Christmas Time",
    "year": "1985",
    "seconds": "70",
    "lyric": "See the joy in the children’s eyes",
    "number": "681",
  },
  {
    "artist": "Rickie Lee Jones",
    "song": "Chuck E.’s in Love",
    "year": "1979",
    "seconds": "70",
    "lyric": "His jacket and his old blue jeans",
    "number": "682",
  },
  {
    "artist": "Carrie Underwood",
    "song": "Church Bells",
    "year": "2016",
    "seconds": "70",
    "lyric": "Fold your hands and close your eyes",
    "number": "683",
  },
  {
    "artist": "Neil Young",
    "song": "Cinnamon Girl",
    "year": "1970",
    "seconds": "50",
    "lyric": "Your baby loves to dance",
    "number": "684",
  },
  {
    "artist": "Elton John",
    "song": "Circle of Life",
    "year": "1994",
    "seconds": "60",
    "lyric": "And, blinking, step into the sun",
    "number": "685",
  },
  {
    "artist": "Britney Spears",
    "song": "Circus",
    "year": "2008",
    "seconds": "60",
    "lyric": "In the center of the ring",
    "number": "686",
  },
  {
    "artist": "Siouxsie & the Banshees",
    "song": "Cities In Dust",
    "year": "1985",
    "seconds": "60",
    "lyric": "Under the mountain, a golden fountain",
    "number": "687",
  },
  {
    "artist": "U2",
    "song": "City of Blinding Lights",
    "year": "2005",
    "seconds": "50",
    "lyric": "Look ugly in a photograph",
    "number": "688",
  },
  {
    "artist": "Steve Goodman",
    "song": "City of New Orleans",
    "year": "1971",
    "seconds": "50",
    "lyric": "Graveyards of the rusted automobiles",
    "number": "689",
  },
  {
    "artist": "Gilbert O’Sullivan",
    "song": "Clair",
    "year": "1972",
    "seconds": "50",
    "lyric": "You look up and smile",
    "number": "690",
  },
  {
    "artist": "The Guess Who",
    "song": "Clap For the Wolfman",
    "year": "1974",
    "seconds": "70",
    "lyric": "Dig him ‘til the day you die",
    "number": "691",
  },
  {
    "artist": "Margery McKay",
    "song": "Climb Ev’ry Mountain",
    "year": "1965",
    "seconds": "50",
    "lyric": "‘Til you find your dream",
    "number": "692",
  },
  {
    "artist": "Coldplay",
    "song": "Clocks",
    "year": "2003",
    "seconds": "60",
    "lyric": "A tiger’s waiting to be tamed",
    "number": "693",
  },
  {
    "artist": "Ne-Yo",
    "song": "Closer",
    "year": "2008",
    "seconds": "60",
    "lyric": "I swear I know her face",
    "number": "694",
  },
  {
    "artist": "The Chainsmokers (feat. Halsey)",
    "song": "Closer",
    "year": "2016",
    "seconds": "60",
    "lyric": "Bite that tattoo on your shoulder",
    "number": "695",
  },
  {
    "artist": "Rush",
    "song": "Closer to the Heart",
    "year": "1977",
    "seconds": "50",
    "lyric": "The blacksmith and the artist",
    "number": "696",
  },
  {
    "artist": "Semisonic",
    "song": "Closing Time",
    "year": "1998",
    "seconds": "60",
    "lyric": "Turn all of the lights on",
    "number": "697",
  },
  {
    "artist": "The Temptations",
    "song": "Cloud Nine",
    "year": "1968",
    "seconds": "70",
    "lyric": "A one room shack we slept in",
    "number": "698",
  },
  {
    "artist": "KoЯn",
    "song": "Clown",
    "year": "1996",
    "seconds": "50",
    "lyric": "A tattooed body to hide",
    "number": "699",
  },
  {
    "artist": "Fergie",
    "song": "Clumsy",
    "year": "2006",
    "seconds": "70",
    "lyric": "The love bug crawls right back up",
    "number": "700",
  },
  {
    "artist": "The 4 Seasons",
    "song": "C’mon Marianne",
    "year": "1967",
    "seconds": "50",
    "lyric": "She was a passing fling",
    "number": "701",
  },
  {
    "artist": "Dolly Parton",
    "song": "Coat of Many Colors",
    "year": "1971",
    "seconds": "60",
    "lyric": "And holes in both my shoes",
    "number": "702",
  },
  {
    "artist": "Mel Tillis",
    "song": "Coca Cola Cowboy",
    "year": "1979",
    "seconds": "60",
    "lyric": "But you walked across my heart",
    "number": "703",
  },
  {
    "artist": "Eric Clapton",
    "song": "Cocaine",
    "year": "1977",
    "seconds": "50",
    "lyric": "And you wanna ride on",
    "number": "704",
  },
  {
    "artist": "Harry Nilsson",
    "song": "Coconut",
    "year": "1972",
    "seconds": "50",
    "lyric": "Call me in the morning",
    "number": "705",
  },
  {
    "artist": "Foreigner",
    "song": "Cold as Ice",
    "year": "1977",
    "seconds": "40",
    "lyric": "You’re digging for gold",
    "number": "706",
  },
  {
    "artist": "Hank Williams",
    "song": "Cold, Cold Heart",
    "year": "1951",
    "seconds": "60",
    "lyric": "To see you sit and cry",
    "number": "707",
  },
  {
    "artist": "Zac Brown Band",
    "song": "Colder Weather",
    "year": "2011",
    "seconds": "60",
    "lyric": "You’re a runner, I’m a lover",
    "number": "708",
  },
  {
    "artist": "Jay and the Americans",
    "song": "Come a Little Bit Closer",
    "year": "1964",
    "seconds": "50",
    "lyric": "So big and so strong",
    "number": "709",
  },
  {
    "artist": "Badfinger",
    "song": "Come and Get It",
    "year": "1969",
    "seconds": "50",
    "lyric": "A fool and his money",
    "number": "710",
  },
  {
    "artist": "Redbone",
    "song": "Come and Get Your Love",
    "year": "1974",
    "seconds": "50",
    "lyric": "Don’t you feel right baby?",
    "number": "711",
  },
  {
    "artist": "Norah Jones",
    "song": "Come Away With Me",
    "year": "2003",
    "seconds": "50",
    "lyric": "The yellow grass grows knee-high",
    "number": "712",
  },
  {
    "artist": "Walter Davis",
    "song": "Come Back Baby",
    "year": "1940",
    "seconds": "60",
    "lyric": "Talk it over one more time",
    "number": "713",
  },
  {
    "artist": "Darius Rucker",
    "song": "Come Back Song",
    "year": "2010",
    "seconds": "50",
    "lyric": "Burned a pot of coffee",
    "number": "714",
  },
  {
    "artist": "Lonestar",
    "song": "Come Cryin’ To Me",
    "year": "1997",
    "seconds": "50",
    "lyric": "Your back’s to the wall",
    "number": "715",
  },
  {
    "artist": "The Kinks",
    "song": "Come Dancing",
    "year": "1982",
    "seconds": "60",
    "lyric": "And a peck on the cheek",
    "number": "716",
  },
  {
    "artist": "Frank Sinatra",
    "song": "Come Fly with Me",
    "year": "1958",
    "seconds": "60",
    "lyric": "He’ll toot his flute for you",
    "number": "717",
  },
  {
    "artist": "Kathy Mattea",
    "song": "Come From the Heart",
    "year": "1989",
    "seconds": "50",
    "lyric": "I was a young girl",
    "number": "718",
  },
  {
    "artist": "Dexys Midnight Runners",
    "song": "Come On Eileen",
    "year": "1982",
    "seconds": "50",
    "lyric": "Sounded sad upon the radio",
    "number": "719",
  },
  {
    "artist": "The Oak Ridge Boys",
    "song": "Come On In",
    "year": "1978",
    "seconds": "50",
    "lyric": "Baby, take your coat off",
    "number": "720",
  },
  {
    "artist": "The Oak Ridge Boys",
    "song": "Come On In",
    "year": "1978",
    "seconds": "50",
    "lyric": "Baby, take your coat off",
    "number": "721",
  },
  {
    "artist": "Shania Twain",
    "song": "Come On Over",
    "year": "1999",
    "seconds": "40",
    "lyric": "Pull up a seat",
    "number": "722",
  },
  {
    "artist": "Christina Aguilera",
    "song": "Come On Over Baby (All I Want is You)",
    "year": "2000",
    "seconds": "50",
    "lyric": "I’m gonna love you right",
    "number": "723",
  },
  {
    "artist": "Billie Eilish",
    "song": "Come Out and Play",
    "year": "2018",
    "seconds": "60",
    "lyric": "Wake up and smell the coffee",
    "number": "724",
  },
  {
    "artist": "Kenny Chesney",
    "song": "Come Over",
    "year": "2012",
    "seconds": "50",
    "lyric": "But baby, climbing the walls",
    "number": "725",
  },
  {
    "artist": "Van Morrison",
    "song": "Come Running",
    "year": "1970",
    "seconds": "50",
    "lyric": "Hound dog by your side",
    "number": "726",
  },
  {
    "artist": "Styx",
    "song": "Come Sail Away",
    "year": "1977",
    "seconds": "50",
    "lyric": "Angels appeared above my head",
    "number": "727",
  },
  {
    "artist": "The Sandpipers",
    "song": "Come Saturday Morning",
    "year": "1969",
    "seconds": "50",
    "lyric": "Dressed up in our rings",
    "number": "728",
  },
  {
    "artist": "The Supremes",
    "song": "Come See About Me",
    "year": "1964",
    "seconds": "40",
    "lyric": "Crying baby for you",
    "number": "729",
  },
  {
    "artist": "The Beatles",
    "song": "Come Together",
    "year": "1969",
    "seconds": "50",
    "lyric": "He got joo joo eyeball",
    "number": "730",
  },
  {
    "artist": "Puff Daddy",
    "song": "Come With Me",
    "year": "1998",
    "seconds": "40",
    "lyric": "Itchy finger, trigger happy",
    "number": "731",
  },
  {
    "artist": "Ricky Martin",
    "song": "Come With Me",
    "year": "2013",
    "seconds": "50",
    "lyric": "Your finger’s on my trigger",
    "number": "732",
  },
  {
    "artist": "Sammie",
    "song": "Come With Me",
    "year": "2007",
    "seconds": "50",
    "lyric": "Left out in the rain",
    "number": "733",
  },
  {
    "artist": "Waylon Jennings",
    "song": "Come With Me",
    "year": "1979",
    "seconds": "50",
    "lyric": "My love’s a light, babe",
    "number": "734",
  },
  {
    "artist": "Pink Floyd",
    "song": "Comfortably Numb",
    "year": "1980",
    "seconds": "60",
    "lyric": "Nod if you can hear me",
    "number": "735",
  },
  {
    "artist": "Keith Urban (ft. Julia Michaels)",
    "song": "Comin Home",
    "year": "2018",
    "seconds": "70",
    "lyric": "My mind’s heavy and I can’t sleep",
    "number": "736",
  },
  {
    "artist": "Gloria Estefan",
    "song": "Coming Out of the Dark",
    "year": "1991",
    "seconds": "60",
    "lyric": "So much stronger holding your hand",
    "number": "737",
  },
  {
    "artist": "Wings",
    "song": "Coming Up",
    "year": "1980",
    "seconds": "60",
    "lyric": "I feel it in my bones",
    "number": "738",
  },
  {
    "artist": "Avril Lavigne",
    "song": "Complicated",
    "year": "2002",
    "seconds": "80",
    "lyric": "You fall and you crawl and you break",
    "number": "739",
  },
  {
    "artist": "Zapp",
    "song": "Computer Love",
    "year": "1985",
    "seconds": "60",
    "lyric": "And your eyes have that glow",
    "number": "740",
  },
  {
    "artist": "ZZ Top",
    "song": "Concrete and Steel",
    "year": "1990",
    "seconds": "60",
    "lyric": "Before you fall for her, boy",
    "number": "741",
  },
  {
    "artist": "Ray Charles and the Maxin Trio",
    "song": "Confession Blues",
    "year": "1949",
    "seconds": "50",
    "lyric": "The happiness I dreamed of",
    "number": "742",
  },
  {
    "artist": "Usher",
    "song": "Confessions Part II",
    "year": "2004",
    "seconds": "40",
    "lyric": "She’s three months pregnant",
    "number": "743",
  },
  {
    "artist": "Kylie Minogue",
    "song": "Confide in Me",
    "year": "1994",
    "seconds": "50",
    "lyric": "And throw away the key",
    "number": "744",
  },
  {
    "artist": "Gloria Estefan & Miami Sound Machine",
    "song": "Conga",
    "year": "1985",
    "seconds": "40",
    "lyric": "Shake your body, baby",
    "number": "745",
  },
  {
    "artist": "Cliff Richard and the Shadows",
    "song": "Congratulations",
    "year": "1968",
    "seconds": "70",
    "lyric": "I let you walk into my heart",
    "number": "746",
  },
  {
    "artist": "Post Malone",
    "song": "Congratulations",
    "year": "2017",
    "seconds": "60",
    "lyric": "Put your lighters to the sky",
    "number": "747",
  },
  {
    "artist": "The Traveling Wilburys",
    "song": "Congratulations",
    "year": "1988",
    "seconds": "50",
    "lyric": "You came out on top",
    "number": "748",
  },
  {
    "artist": "Procol Harum",
    "song": "Conquistador",
    "year": "1972",
    "seconds": "50",
    "lyric": "I see your armour-plated breast",
    "number": "749",
  },
  {
    "artist": "C. W. McCall",
    "song": "Convoy",
    "year": "1975",
    "seconds": "50",
    "lyric": "This here’s the rubber duck",
    "number": "750",
  },
  {
    "artist": "Jonas Brothers",
    "song": "Cool",
    "year": "2019",
    "seconds": "40",
    "lyric": "I combed my hair",
    "number": "751",
  },
  {
    "artist": "Barry Manilow",
    "song": "Copacabana (At the Copa)",
    "year": "1978",
    "seconds": "50",
    "lyric": "Yellow feathers in her hair",
    "number": "752",
  },
  {
    "artist": "Steve Earle",
    "song": "Copperhead Road",
    "year": "1988",
    "seconds": "40",
    "lyric": "The sheriff came around",
    "number": "753",
  },
  {
    "artist": "Billie Eilish",
    "song": "Copycat",
    "year": "2017",
    "seconds": "50",
    "lyric": "You’ve run out of time",
    "number": "754",
  },
  {
    "artist": "Anne Murray",
    "song": "Cotton Jenny",
    "year": "1972",
    "seconds": "50",
    "lyric": "And she wakes him up",
    "number": "755",
  },
  {
    "artist": "Anne Murray",
    "song": "Could I Have This Dance?",
    "year": "1980",
    "seconds": "50",
    "lyric": "Would you be my partner?",
    "number": "756",
  },
  {
    "artist": "The Spinners",
    "song": "Could It Be I’m Falling in Love",
    "year": "1973",
    "seconds": "50",
    "lyric": "My heart in my hands",
    "number": "757",
  },
  {
    "artist": "Bob Marley & the Wailers",
    "song": "Could You be Loved",
    "year": "1980",
    "seconds": "60",
    "lyric": "The road of life is rocky",
    "number": "758",
  },
  {
    "artist": "Billy Ray Cyrus",
    "song": "Could’ve Been Me",
    "year": "1992",
    "seconds": "60",
    "lyric": "Carry it around in my pocket",
    "number": "759",
  },
  {
    "artist": "Climax Blues Band",
    "song": "Couldn’t Get It Right",
    "year": "1976",
    "seconds": "40",
    "lyric": "Looking for a sign",
    "number": "760",
  },
  {
    "artist": "Collin Raye",
    "song": "Couldn’t Last a Moment",
    "year": "2000",
    "seconds": "50",
    "lyric": "Without you by my side",
    "number": "761",
  },
  {
    "artist": "Bruno Mars",
    "song": "Count on Me",
    "year": "2010",
    "seconds": "50",
    "lyric": "The middle of the sea",
    "number": "762",
  },
  {
    "artist": "OneRepublic",
    "song": "Counting Stars",
    "year": "2013",
    "seconds": "60",
    "lyric": "Swing my heart across the line",
    "number": "763",
  },
  {
    "artist": "Glen Campbell",
    "song": "Country Boy (You Got Your Feet in L.A.)",
    "year": "1975",
    "seconds": "50",
    "lyric": "But your mind’s on Tennessee",
    "number": "764",
  },
  {
    "artist": "Luke Bryan",
    "song": "Country Girl (Shake It For Me)",
    "year": "2011",
    "seconds": "70",
    "lyric": "A little boom in my big truck",
    "number": "765",
  },
  {
    "artist": "Hank Williams Jr.",
    "song": "Country State of Mind",
    "year": "1986",
    "seconds": "50",
    "lyric": "And kick up your heels",
    "number": "766",
  },
  {
    "artist": "Dottie West",
    "song": "Country Sunshine",
    "year": "1973",
    "seconds": "60",
    "lyric": "Running through the fields of daisies",
    "number": "767",
  },
  {
    "artist": "Toby Keith",
    "song": "Courtesy of the Red, White & Blue",
    "year": "2002",
    "seconds": "50",
    "lyric": "This big dog will fight",
    "number": "768",
  },
  {
    "artist": "Bruce Springsteen",
    "song": "Cover Me",
    "year": "1984",
    "seconds": "50",
    "lyric": "I’m looking for a lover",
    "number": "769",
  },
  {
    "artist": "Kenny Rogers",
    "song": "Coward of the County",
    "year": "1979",
    "seconds": "50",
    "lyric": "I walk away from trouble",
    "number": "770",
  },
  {
    "artist": "Carrie Underwood",
    "song": "Cowboy Casanova",
    "year": "2009",
    "seconds": "70",
    "lyric": "Looks like a cool drink of water",
    "number": "771",
  },
  {
    "artist": "Dustin Lynch",
    "song": "Cowboys and Angels",
    "year": "2012",
    "seconds": "50",
    "lyric": "Her kiss is my salvation",
    "number": "772",
  },
  {
    "artist": "Ronnie Milsap",
    "song": "Cowboys and Clowns",
    "year": "1980",
    "seconds": "50",
    "lyric": "No balloons, no sweet perfume",
    "number": "773",
  },
  {
    "artist": "George Strait",
    "song": "Cowboys Like Us",
    "year": "2003",
    "seconds": "40",
    "lyric": "Back to square one",
    "number": "774",
  },
  {
    "artist": "Reba McEntire, Brooks & Dunn",
    "song": "Cowgirls Don’t Cry",
    "year": "2008",
    "seconds": "60",
    "lyric": "She grew up, she got married",
    "number": "775",
  },
  {
    "artist": "Neil Diamond",
    "song": "Cracklin’ Rosie",
    "year": "1970",
    "seconds": "50",
    "lyric": "Sing like a guitar hummin’",
    "number": "776",
  },
  {
    "artist": "Solange",
    "song": "Cranes in the Sky",
    "year": "2016",
    "seconds": "70",
    "lyric": "I ran my credit card bill up",
    "number": "777",
  },
  {
    "artist": "Thomas Rhett",
    "song": "Crash and Burn",
    "year": "2015",
    "seconds": "40",
    "lyric": "The world keeps spinning",
    "number": "778",
  },
  {
    "artist": "Luke Bryan",
    "song": "Crash My Party",
    "year": "2013",
    "seconds": "50",
    "lyric": "Pulled up or falling down?",
    "number": "779",
  },
  {
    "artist": "Aerosmith",
    "song": "Crazy",
    "year": "1994",
    "seconds": "60",
    "lyric": "Leavin’ on a seven thirty train",
    "number": "780",
  },
  {
    "artist": "Gnarls Barkley",
    "song": "Crazy",
    "year": "2006",
    "seconds": "50",
    "lyric": "My heroes had the heart",
    "number": "781",
  },
  {
    "artist": "Patsy Cline",
    "song": "Crazy",
    "year": "1961",
    "seconds": "50",
    "lyric": "My love could hold you",
    "number": "782",
  },
  {
    "artist": "Madonna",
    "song": "Crazy For You",
    "year": "1985",
    "seconds": "60",
    "lyric": "You’ll feel it in my kiss",
    "number": "783",
  },
  {
    "artist": "Eli Young Band",
    "song": "Crazy Girl",
    "year": "2011",
    "seconds": "40",
    "lyric": "I’d lose my mind",
    "number": "784",
  },
  {
    "artist": "Beyoncé (feat. Jay-Z)",
    "song": "Crazy in Love",
    "year": "2003",
    "seconds": "60",
    "lyric": "Stare so deep in your eyes",
    "number": "785",
  },
  {
    "artist": "Queen",
    "song": "Crazy Little Thing Called Love",
    "year": "1979",
    "seconds": "50",
    "lyric": "Be cool, relax, get hip",
    "number": "786",
  },
  {
    "artist": "Van Morrison",
    "song": "Crazy Love",
    "year": "1970",
    "seconds": "60",
    "lyric": "I can hear her heart beat",
    "number": "787",
  },
  {
    "artist": "Heart",
    "song": "Crazy on You",
    "year": "1976",
    "seconds": "60",
    "lyric": "The evening breeze touching your skin",
    "number": "788",
  },
  {
    "artist": "Jason Aldean",
    "song": "Crazy Town",
    "year": "2010",
    "seconds": "60",
    "lyric": "The drunk girls scream and shout",
    "number": "789",
  },
  {
    "artist": "Ozzy Osbourne",
    "song": "Crazy Train",
    "year": "1980",
    "seconds": "50",
    "lyric": "I’m goin’ off the rails",
    "number": "790",
  },
  {
    "artist": "Prince and the New Power Generation",
    "song": "Cream",
    "year": "1991",
    "seconds": "50",
    "lyric": "Look up in the air",
    "number": "791",
  },
  {
    "artist": "The Mamas & the Papas",
    "song": "Creeque Alley",
    "year": "1967",
    "seconds": "40",
    "lyric": "Thumb out to hitchhike",
    "number": "792",
  },
  {
    "artist": "Radiohead",
    "song": "Creep",
    "year": "1992",
    "seconds": "50",
    "lyric": "You float like a feather",
    "number": "793",
  },
  {
    "artist": "Fiona",
    "song": "Criminal",
    "year": "1996",
    "seconds": "60",
    "lyric": "A girl will break a boy",
    "number": "794",
  },
  {
    "artist": "Tommy James & the Shondells",
    "song": "Crimson and Clover",
    "year": "1969",
    "seconds": "70",
    "lyric": "But I think I could love her",
    "number": "795",
  },
  {
    "artist": "Elton John",
    "song": "Crocodile Rock",
    "year": "1972",
    "seconds": "70",
    "lyric": "My Chevy and my old blue jeans",
    "number": "796",
  },
  {
    "artist": "Stevie Ray Vaughan",
    "song": "Crossfire",
    "year": "1989",
    "seconds": "50",
    "lyric": "Blinded by the neon light",
    "number": "797",
  },
  {
    "artist": "John Mellencamp",
    "song": "Crumblin’ Down",
    "year": "1983",
    "seconds": "60",
    "lyric": "Read the news around my face",
    "number": "798",
  },
  {
    "artist": "Jennifer Paige",
    "song": "Crush",
    "year": "1996",
    "seconds": "60",
    "lyric": "I faint every time we touch",
    "number": "799",
  },
  {
    "artist": "The Box Tops",
    "song": "Cry Like a Baby",
    "year": "1968",
    "seconds": "70",
    "lyric": "My heart just fell to my feet",
    "number": "800",
  },
  {
    "artist": "Gerard McMann",
    "song": "Cry Little Sister",
    "year": "1987",
    "seconds": "50",
    "lyric": "Love is with your brother",
    "number": "801",
  },
  {
    "artist": "Justin Timberlake",
    "song": "Cry Me a River",
    "year": "2003",
    "seconds": "40",
    "lyric": "The bridges were burned",
    "number": "802",
  },
  {
    "artist": "Aerosmith",
    "song": "Cryin’",
    "year": "1993",
    "seconds": "60",
    "lyric": "Our love goes up in flames",
    "number": "803",
  },
  {
    "artist": "Roy Orbison",
    "song": "Crying",
    "year": "1961",
    "seconds": "50",
    "lyric": "The touch of your hand",
    "number": "804",
  },
  {
    "artist": "The Oak Ridge Boys",
    "song": "Cryin’ Again",
    "year": "1978",
    "seconds": "60",
    "lyric": "But I’m waking up without you",
    "number": "805",
  },
  {
    "artist": "Elvis Presley",
    "song": "Crying in the Chapel",
    "year": "1965",
    "seconds": "50",
    "lyric": "Get down on your knees",
    "number": "806",
  },
  {
    "artist": "The Everly Brothers",
    "song": "Crying in the Rain",
    "year": "1962",
    "seconds": "50",
    "lyric": "I wait for cloudy skies",
    "number": "807",
  },
  {
    "artist": "Ray Charles",
    "song": "Crying Time",
    "year": "1966",
    "seconds": "60",
    "lyric": "Far away look in your eyes",
    "number": "808",
  },
  {
    "artist": "Tommy James and the Shondells",
    "song": "Crystal Blue Persuasion",
    "year": "1969",
    "seconds": "80",
    "lyric": "Look to your soul and open your mind",
    "number": "809",
  },
  {
    "artist": "Sam Cooke",
    "song": "Cupid",
    "year": "1961",
    "seconds": "70",
    "lyric": "Straight to my lover’s heart for me",
    "number": "810",
  },
  {
    "artist": "Bryan Adams",
    "song": "Cuts Like a Knife",
    "year": "1983",
    "seconds": "50",
    "lyric": "But it feels so right",
    "number": "811",
  },
  {
    "artist": "Tammy Wynette",
    "song": "D-I-V-O-R-C-E",
    "year": "1968",
    "seconds": "70",
    "lyric": "Watch him smile, he thinks it’s Christmas",
    "number": "812",
  },
  {
    "artist": "Foo Fighters",
    "song": "DOA",
    "year": "2005",
    "seconds": "50",
    "lyric": "The grains in an hourglass",
    "number": "813",
  },
  {
    "artist": "The Crystals",
    "song": "Da Doo Ron Ron",
    "year": "1963",
    "seconds": "50",
    "lyric": "And my heart stood still",
    "number": "814",
  },
  {
    "artist": "Rod Stewart",
    "song": "Da Ya Think I’m Sexy?",
    "year": "1979",
    "seconds": "60",
    "lyric": "Just reach out and touch me",
    "number": "815",
  },
  {
    "artist": "Wayne Newton",
    "song": "Daddy, Don’t You Walk So Fast",
    "year": "1972",
    "seconds": "60",
    "lyric": "My little daughter running after me",
    "number": "816",
  },
  {
    "artist": "Johnny Cash",
    "song": "Daddy Sang Bass",
    "year": "1968",
    "seconds": "40",
    "lyric": "Me and little brother",
    "number": "817",
  },
  {
    "artist": "Shep and the Limelites",
    "song": "Daddy’s Home",
    "year": "1961",
    "seconds": "60",
    "lyric": "You’re the girl of my dreams",
    "number": "818",
  },
  {
    "artist": "Jud Strunk",
    "song": "Daisy a Day",
    "year": "1973",
    "seconds": "40",
    "lyric": "The rivers run still",
    "number": "819",
  },
  {
    "artist": "Sophie B Hawkins",
    "song": "Damn, I Wish I Was Your Lover",
    "year": "1992",
    "seconds": "70",
    "lyric": "Give me an hour to kiss you",
    "number": "820",
  },
  {
    "artist": "Beach Boys",
    "song": "Dance, Dance, Dance",
    "year": "1964",
    "seconds": "50",
    "lyric": "My chick by my side",
    "number": "821",
  },
  {
    "artist": "Chic",
    "song": "Dance, Dance, Dance",
    "year": "1977",
    "seconds": "50",
    "lyric": "I’m dancing with my baby",
    "number": "822",
  },
  {
    "artist": "Van Halen",
    "song": "Dance the Night Away",
    "year": "1979",
    "seconds": "70",
    "lyric": "I feel her from across the room",
    "number": "823",
  },
  {
    "artist": "Sly & the Family Stone",
    "song": "Dance to the Music",
    "year": "1968",
    "seconds": "40",
    "lyric": "Add a little guitar",
    "number": "824",
  },
  {
    "artist": "Orleans",
    "song": "Dance With Me",
    "year": "1975",
    "seconds": "50",
    "lyric": "I want to be your partner",
    "number": "825",
  },
  {
    "artist": "Peter Brown",
    "song": "Dance With Me",
    "year": "1978",
    "seconds": "60",
    "lyric": "Ladies, get up off your seats",
    "number": "826",
  },
  {
    "artist": "Kylie Minogue",
    "song": "Dancing",
    "year": "2018",
    "seconds": "50",
    "lyric": "Your arms wrapped around me",
    "number": "827",
  },
  {
    "artist": "Bruce Springsteen",
    "song": "Dancing in the Dark",
    "year": "1984",
    "seconds": "50",
    "lyric": "You can’t start a fire",
    "number": "828",
  },
  {
    "artist": "King Harvest",
    "song": "Dancing in the Moonlight",
    "year": "1973",
    "seconds": "70",
    "lyric": "They don’t bark and they don’t bite",
    "number": "829",
  },
  {
    "artist": "Martha & the Vandellas",
    "song": "Dancing in the Street",
    "year": "1964",
    "seconds": "50",
    "lyric": "Every guy, grab a girl",
    "number": "830",
  },
  {
    "artist": "The Jackson 5",
    "song": "Dancing Machine",
    "year": "1974",
    "seconds": "60",
    "lyric": "At the drop of a coin",
    "number": "831",
  },
  {
    "artist": "Robyn",
    "song": "Dancing On My Own",
    "year": "2010",
    "seconds": "60",
    "lyric": "Big black sky over my town",
    "number": "832",
  },
  {
    "artist": "Lionel Richie",
    "song": "Dancing on the Ceiling",
    "year": "1986",
    "seconds": "40",
    "lyric": "Everybody, clap your hands",
    "number": "833",
  },
  {
    "artist": "ABBA",
    "song": "Dancing Queen",
    "year": "1976",
    "seconds": "70",
    "lyric": "You come to look for a king",
    "number": "834",
  },
  {
    "artist": "Roger Miller",
    "song": "Dang Me",
    "year": "1964",
    "seconds": "60",
    "lyric": "Take a rope and hang me",
    "number": "835",
  },
  {
    "artist": "Ariana Grande",
    "song": "Dangerous Woman",
    "year": "2016",
    "seconds": "40",
    "lyric": "I’m locked and loaded",
    "number": "836",
  },
  {
    "artist": "Red Hot Chili Peppers",
    "song": "Dani California",
    "year": "2006",
    "seconds": "50",
    "lyric": "She would swing a hammer",
    "number": "837",
  },
  {
    "artist": "Elton John",
    "song": "Daniel",
    "year": "1973",
    "seconds": "70",
    "lyric": "I can see the red tail lights",
    "number": "838",
  },
  {
    "artist": "Bing Crosby",
    "song": "Danny Boy",
    "year": "1943",
    "seconds": "50",
    "lyric": "When summer’s in the meadow",
    "number": "839",
  },
  {
    "artist": "Anne Murray",
    "song": "Danny’s Song",
    "year": "1972",
    "seconds": "70",
    "lyric": "I’m so in love with ya honey",
    "number": "840",
  },
  {
    "artist": "Katy Perry (feat. Juicy J)",
    "song": "Dark Horse",
    "year": "2013",
    "seconds": "50",
    "lyric": "A knight in shining armor",
    "number": "841",
  },
  {
    "artist": "Cher",
    "song": "Dark Lady",
    "year": "1974",
    "seconds": "60",
    "lyric": "Lit the candles one by one",
    "number": "842",
  },
  {
    "artist": "The 4 Seasons",
    "song": "Dawn (Go Away)",
    "year": "1964",
    "seconds": "50",
    "lyric": "He’ll be good to you",
    "number": "843",
  },
  {
    "artist": "Badfinger",
    "song": "Day After Day",
    "year": "1971",
    "seconds": "70",
    "lyric": "I feel the tears that you weep",
    "number": "844",
  },
  {
    "artist": "Original Cast of Godspell",
    "song": "Day By Day",
    "year": "1972",
    "seconds": "40",
    "lyric": "Three things I pray",
    "number": "845",
  },
  {
    "artist": "Aretha Franklin",
    "song": "Day Dreaming",
    "year": "1972",
    "seconds": "50",
    "lyric": "And I’m thinking of you",
    "number": "846",
  },
  {
    "artist": "The Beatles",
    "song": "Day Tripper",
    "year": "1966",
    "seconds": "50",
    "lyric": "So long to find out",
    "number": "847",
  },
  {
    "artist": "The Lovin’ Spoonful",
    "song": "Daydream",
    "year": "1966",
    "seconds": "50",
    "lyric": "A pie in your face",
    "number": "848",
  },
  {
    "artist": "The Monkees",
    "song": "Daydream Believer",
    "year": "1967",
    "seconds": "70",
    "lyric": "Wipe the sleep out of my eyes",
    "number": "849",
  },
  {
    "artist": "Ronnie Milsap",
    "song": "Daydreams About Night Things",
    "year": "1975",
    "seconds": "60",
    "lyric": "Time slows down to a crawl",
    "number": "850",
  },
  {
    "artist": "Keith Urban",
    "song": "Days Go By",
    "year": "2004",
    "seconds": "60",
    "lyric": "Like a hand out the window",
    "number": "851",
  },
  {
    "artist": "Van Morrison",
    "song": "Days Like This",
    "year": "1995",
    "seconds": "50",
    "lyric": "The parts of the puzzle",
    "number": "852",
  },
  {
    "artist": "Andy Williams",
    "song": "Days of Wine and Roses",
    "year": "1963",
    "seconds": "60",
    "lyric": "A passing breeze filled with memories",
    "number": "853",
  },
  {
    "artist": "Metric",
    "song": "Dead Disco",
    "year": "2003",
    "seconds": "40",
    "lyric": "Touch down, look around",
    "number": "854",
  },
  {
    "artist": "The White Stripes",
    "song": "Dead Leaves and the Dirty Ground",
    "year": "2001",
    "seconds": "60",
    "lyric": "You can hear a piano fall",
    "number": "855",
  },
  {
    "artist": "Jan and Dean",
    "song": "Dead Man’s Curve",
    "year": "1964",
    "seconds": "50",
    "lyric": "And rolled down the window",
    "number": "856",
  },
  {
    "artist": "Oingo Boingo",
    "song": "Dead Man’s Party",
    "year": "1985",
    "seconds": "50",
    "lyric": "I was struck by lightning",
    "number": "857",
  },
  {
    "artist": "Dinah Shore",
    "song": "Dear Hearts and Gentle People",
    "year": "1949",
    "seconds": "40",
    "lyric": "Laughin’ like a clown",
    "number": "858",
  },
  {
    "artist": "Loretta Lynn",
    "song": "Dear Uncle Sam",
    "year": "1966",
    "seconds": "60",
    "lyric": "I know you’re a busy man",
    "number": "859",
  },
  {
    "artist": "Dinah Shore",
    "song": "Dearly Beloved",
    "year": "1942",
    "seconds": "60",
    "lyric": "Angel voices led me to you",
    "number": "860",
  },
  {
    "artist": "The Four Seasons",
    "song": "December 1963 (Oh, What a Night)",
    "year": "1975",
    "seconds": "40",
    "lyric": "Spinning my head around",
    "number": "861",
  },
  {
    "artist": "Nat King Cole",
    "song": "Deck the Hall",
    "year": "1960",
    "seconds": "70",
    "lyric": "Strike the harp and join the chorus",
    "number": "862",
  },
  {
    "artist": "The Kinks",
    "song": "Dedicated Follower of Fashion",
    "year": "1966",
    "seconds": "50",
    "lyric": "One week he’s in polka-dots",
    "number": "863",
  },
  {
    "artist": "The Shirelles",
    "song": "Dedicated to the One I Love",
    "year": "1961",
    "seconds": "60",
    "lyric": "Whisper a little prayer for me",
    "number": "864",
  },
  {
    "artist": "Donny & Marie Osmond",
    "song": "Deep Purple",
    "year": "1976",
    "seconds": "40",
    "lyric": "Over sleepy garden walls",
    "number": "865",
  },
  {
    "artist": "Taylor Swift",
    "song": "Delicate",
    "year": "2018",
    "seconds": "50",
    "lyric": "Phone lights up my nightstand",
    "number": "866",
  },
  {
    "artist": "Tom Jones",
    "song": "Delilah",
    "year": "1968",
    "seconds": "70",
    "lyric": "I felt the knife in my hand",
    "number": "867",
  },
  {
    "artist": "Prince",
    "song": "Delirious",
    "year": "1983",
    "seconds": "50",
    "lyric": "Stupid look on my face",
    "number": "868",
  },
  {
    "artist": "Tanya Tucker",
    "song": "Delta Dawn",
    "year": "1972",
    "seconds": "60",
    "lyric": "Could it be a faded rose",
    "number": "869",
  },
  {
    "artist": "Randy & the Rainbows",
    "song": "Denise",
    "year": "1963",
    "seconds": "60",
    "lyric": "I’ve got a crush on you",
    "number": "870",
  },
  {
    "artist": "Andy Gibb",
    "song": "Desire",
    "year": "1980",
    "seconds": "60",
    "lyric": "It’s nice to light a fire",
    "number": "871",
  },
  {
    "artist": "U2",
    "song": "Desire",
    "year": "1988",
    "seconds": "40",
    "lyric": "A preacher stealing hearts",
    "number": "872",
  },
  {
    "artist": "Neil Diamond",
    "song": "Desirée",
    "year": "1977",
    "seconds": "60",
    "lyric": "At the hands of a girl",
    "number": "873",
  },
  {
    "artist": "Eagles",
    "song": "Desperado",
    "year": "1972",
    "seconds": "50",
    "lyric": "The queen of diamonds, boy",
    "number": "874",
  },
  {
    "artist": "Conway Twitty",
    "song": "Desperado Love",
    "year": "1986",
    "seconds": "60",
    "lyric": "Ride in and steal you away",
    "number": "875",
  },
  {
    "artist": "KISS",
    "song": "Detroit Rock City",
    "year": "1976",
    "seconds": "50",
    "lyric": "The radio’s the only light",
    "number": "876",
  },
  {
    "artist": "Bobby Vee",
    "song": "Devil or Angel",
    "year": "1960",
    "seconds": "50",
    "lyric": "Please say you’ll be mine",
    "number": "877",
  },
  {
    "artist": "Mitch Ryder & the Detroit Wheels",
    "song": "Devil with a Blue Dress On/Good Golly Miss Molly",
    "year": "1966",
    "seconds": "40",
    "lyric": "And an alligator hat",
    "number": "878",
  },
  {
    "artist": "Cliff Richard",
    "song": "Devil Woman",
    "year": "1976",
    "seconds": "50",
    "lyric": "Crystal ball on the table",
    "number": "879",
  },
  {
    "artist": "Marty Robbins",
    "song": "Devil Woman",
    "year": "1962",
    "seconds": "50",
    "lyric": "Mary is waiting and weeping",
    "number": "880",
  },
  {
    "artist": "The Everly Brothers",
    "song": "Devoted to You",
    "year": "1958",
    "seconds": "70",
    "lyric": "I’ll never hurt you, I’ll never lie",
    "number": "881",
  },
  {
    "artist": "Seals and Crofts",
    "song": "Diamond Girl",
    "year": "1973",
    "seconds": "40",
    "lyric": "Deep down inside you",
    "number": "882",
  },
  {
    "artist": "Rihanna",
    "song": "Diamonds",
    "year": "2012",
    "seconds": "70",
    "lyric": "I saw the life inside your eyes",
    "number": "883",
  },
  {
    "artist": "Prince and the New Power Generation",
    "song": "Diamonds and Pearls",
    "year": "1991",
    "seconds": "60",
    "lyric": "A happy boy or a girl",
    "number": "884",
  },
  {
    "artist": "Joan Baez",
    "song": "Diamonds and Rust",
    "year": "1975",
    "seconds": "50",
    "lyric": "As I remember your eyes",
    "number": "885",
  },
  {
    "artist": "Marilyn Monroe",
    "song": "Diamonds Are a Girl’s Best Friend",
    "year": "1953",
    "seconds": "50",
    "lyric": "A kiss on the hand",
    "number": "886",
  },
  {
    "artist": "Ben Harper",
    "song": "Diamonds on the Inside",
    "year": "2003",
    "seconds": "60",
    "lyric": "A soldier standing long under fire",
    "number": "887",
  },
  {
    "artist": "Paul Simon",
    "song": "Diamonds on the Soles of Her Shoes",
    "year": "1987",
    "seconds": "50",
    "lyric": "She slipped into my pocket",
    "number": "888",
  },
  {
    "artist": "Paul Anka",
    "song": "Diana",
    "year": "1957",
    "seconds": "70",
    "lyric": "I’m so young and you’re so old",
    "number": "889",
  },
  {
    "artist": "Van Morrison",
    "song": "Did Ye Get Healed?",
    "year": "1987",
    "seconds": "50",
    "lyric": "Fills you through and through",
    "number": "890",
  },
  {
    "artist": "The Lovin’ Spoonful",
    "song": "Did You Ever Have to Make Up Your Mind?",
    "year": "1966",
    "seconds": "40",
    "lyric": "Cute as a bunny",
    "number": "891",
  },
  {
    "artist": "Thomas Rhett",
    "song": "Die a Happy Man",
    "year": "2015",
    "seconds": "60",
    "lyric": "And the look in your eyes",
    "number": "892",
  },
  {
    "artist": "Dierks Bentley",
    "song": "Different For Girls",
    "year": "2016",
    "seconds": "40",
    "lyric": "Looking for a Band-Aid",
    "number": "893",
  },
  {
    "artist": "Peter Gabriel",
    "song": "Digging in the Dirt",
    "year": "1992",
    "seconds": "60",
    "lyric": "I feel it in my toes",
    "number": "894",
  },
  {
    "artist": "Randy Travis",
    "song": "Diggin’ Up Bones",
    "year": "1986",
    "seconds": "70",
    "lyric": "Right up ’til the break of dawn",
    "number": "895",
  },
  {
    "artist": "Donna Summer",
    "song": "Dim All the Lights",
    "year": "1979",
    "seconds": "60",
    "lyric": "I want to be your woman",
    "number": "896",
  },
  {
    "artist": "Christina Aguilera (ft. Redman)",
    "song": "Dirrty",
    "year": "2002",
    "seconds": "50",
    "lyric": "Table dancing, glasses are crashing",
    "number": "897",
  },
  {
    "artist": "Florida Georgia Line",
    "song": "Dirt",
    "year": "2014",
    "seconds": "50",
    "lyric": "Spin your tires on it",
    "number": "898",
  },
  {
    "artist": "Jason Aldean",
    "song": "Dirt Road Anthem",
    "year": "2011",
    "seconds": "40",
    "lyric": "Jump the barbed wire",
    "number": "899",
  },
  {
    "artist": "AC/DC",
    "song": "Dirty Deeds Done Dirt Cheap",
    "year": "1976",
    "seconds": "50",
    "lyric": "He’s givin’ you the blues",
    "number": "900",
  },
  {
    "artist": "Michael Jackson",
    "song": "Dirty Diana",
    "year": "1988",
    "seconds": "60",
    "lyric": "I was too blind to see",
    "number": "901",
  },
  {
    "artist": "Carrie Underwood",
    "song": "Dirty Laundry",
    "year": "2016",
    "seconds": "60",
    "lyric": "Found it over in the corner",
    "number": "902",
  },
  {
    "artist": "Don Henley",
    "song": "Dirty Laundry",
    "year": "1982",
    "seconds": "60",
    "lyric": "Come and whisper in my ear",
    "number": "903",
  },
  {
    "artist": "Smashing Pumpkins",
    "song": "Disarm",
    "year": "1994",
    "seconds": "50",
    "lyric": "So old in my shoes",
    "number": "904",
  },
  {
    "artist": "Rick Dees & his Cast of Idiots",
    "song": "Disco Duck",
    "year": "1976",
    "seconds": "60",
    "lyric": "Oh mama, shake your tail feather",
    "number": "905",
  },
  {
    "artist": "Johnnie Taylor",
    "song": "Disco Lady",
    "year": "1976",
    "seconds": "50",
    "lyric": "Girl, you drive me crazy",
    "number": "906",
  },
  {
    "artist": "U2",
    "song": "Discothèque",
    "year": "1997",
    "seconds": "50",
    "lyric": "You hear in your head",
    "number": "907",
  },
  {
    "artist": "Jim Reeves",
    "song": "Distant Drums",
    "year": "1966",
    "seconds": "70",
    "lyric": "I hear the sound of bugles blowing",
    "number": "908",
  },
  {
    "artist": "Hank Williams Jr.",
    "song": "Dixie on My Mind",
    "year": "1981",
    "seconds": "60",
    "lyric": "A few squirrels and a porcupine",
    "number": "909",
  },
  {
    "artist": "Alabama",
    "song": "Dixieland Delight",
    "year": "1983",
    "seconds": "40",
    "lyric": "‘Neath the mountain moonlight",
    "number": "910",
  },
  {
    "artist": "Tommy Roe",
    "song": "Dizzy",
    "year": "1969",
    "seconds": "60",
    "lyric": "Around in circles all the time",
    "number": "911",
  },
  {
    "artist": "Usher (ft. Pitbull)",
    "song": "DJ Got Us Fallin’ in Love",
    "year": "2010",
    "seconds": "60",
    "lyric": "A zombie comin’ back to life",
    "number": "912",
  },
  {
    "artist": "Luke Bryan",
    "song": "Do I?",
    "year": "2009",
    "seconds": "50",
    "lyric": "Lean in to hold you",
    "number": "913",
  },
  {
    "artist": "Bryan Adams",
    "song": "Do I Have to Say the Words?",
    "year": "1992",
    "seconds": "60",
    "lyric": "So I’m standin’ in your way",
    "number": "914",
  },
  {
    "artist": "Arctic Monkeys",
    "song": "Do I Wanna Know?",
    "year": "2013",
    "seconds": "50",
    "lyric": "Some aces up your sleeve",
    "number": "915",
  },
  {
    "artist": "Steely Dan",
    "song": "Do It Again",
    "year": "1972",
    "seconds": "50",
    "lyric": "Wheel turnin’ ’round and ‘round",
    "number": "916",
  },
  {
    "artist": "Julie Andrews",
    "song": "Do-Re-Mi",
    "year": "1965",
    "seconds": "40",
    "lyric": "A needle pulling thread",
    "number": "917",
  },
  {
    "artist": "Band Aid",
    "song": "Do They Know It’s Christmas?",
    "year": "1984",
    "seconds": "50",
    "lyric": "Raise a glass for everyone",
    "number": "918",
  },
  {
    "artist": "Manfred Mann",
    "song": "Do Wah Diddy Diddy",
    "year": "1964",
    "seconds": "70",
    "lyric": "Snappin’ her fingers and shufflin’ her feet",
    "number": "919",
  },
  {
    "artist": "K.T. Oslin",
    "song": "Do Ya’",
    "year": "1987",
    "seconds": "40",
    "lyric": "By snappin’ your fingers",
    "number": "920",
  },
  {
    "artist": "The Lovin’ Spoonful",
    "song": "Do You Believe in Magic?",
    "year": "1965",
    "seconds": "40",
    "lyric": "Your feet start tapping",
    "number": "921",
  },
  {
    "artist": "Peter Frampton",
    "song": "Do You Feel Like We Do?",
    "year": "1976",
    "seconds": "60",
    "lyric": "A wine glass in my hand",
    "number": "922",
  },
  {
    "artist": "Bing Crosby",
    "song": "Do You Hear What I Hear?",
    "year": "1963",
    "seconds": "60",
    "lyric": "A star, dancing in the night",
    "number": "923",
  },
  {
    "artist": "Dionne Warwick",
    "song": "Do You Know the Way to San Jose?",
    "year": "1968",
    "seconds": "50",
    "lyric": "Parking cars and pumping gas",
    "number": "924",
  },
  {
    "artist": "Lee Michaels",
    "song": "Do You Know What I Mean?",
    "year": "1971",
    "seconds": "50",
    "lyric": "I held back my tears",
    "number": "925",
  },
  {
    "artist": "Diana Ross",
    "song": "Do You Know Where You’re Going To?",
    "year": "1975",
    "seconds": "50",
    "lyric": "Just slip through our hands",
    "number": "926",
  },
  {
    "artist": "The Statler Brothers",
    "song": "Do You Know You Are My Sunshine?",
    "year": "1978",
    "seconds": "60",
    "lyric": "Searching every crowd for her face",
    "number": "927",
  },
  {
    "artist": "The Contours",
    "song": "Do You Love Me?",
    "year": "1962",
    "seconds": "50",
    "lyric": "Watch me now, work, work",
    "number": "928",
  },
  {
    "artist": "The Flaming Lips",
    "song": "Do You Realize?",
    "year": "2003",
    "seconds": "50",
    "lyric": "The sun doesn’t go down",
    "number": "929",
  },
  {
    "artist": "Peter McCann",
    "song": "Do You Wanna Make Love?",
    "year": "1977",
    "seconds": "50",
    "lyric": "I look in your eyes",
    "number": "930",
  },
  {
    "artist": "Tim McGraw",
    "song": "Do You Want Fries With That?",
    "year": "2005",
    "seconds": "50",
    "lyric": "Your Ketchup’s in the bag",
    "number": "931",
  },
  {
    "artist": "Bobby Freeman",
    "song": "Do You Want to Dance?",
    "year": "1958",
    "seconds": "40",
    "lyric": "And hold my hand",
    "number": "932",
  },
  {
    "artist": "The Beatles",
    "song": "Do You Want to Know a Secret?",
    "year": "1963",
    "seconds": "60",
    "lyric": "Let me whisper in your ear",
    "number": "933",
  },
  {
    "artist": "Jackson Browne",
    "song": "Doctor My Eyes",
    "year": "1972",
    "seconds": "50",
    "lyric": "They cannot see the sky",
    "number": "934",
  },
  {
    "artist": "Chicago",
    "song": "Does Anybody Really Know What Time It Is?",
    "year": "1970",
    "seconds": "50",
    "lyric": "Her diamond watch had stopped",
    "number": "935",
  },
  {
    "artist": "George Strait",
    "song": "Does Fort Worth Ever Cross Your Mind?",
    "year": "1984",
    "seconds": "70",
    "lyric": "You’re in someone else’s arms in Dallas",
    "number": "936",
  },
  {
    "artist": "Reba McEntire & Linda Davis",
    "song": "Does He Love You?",
    "year": "1993",
    "seconds": "60",
    "lyric": "And when I’m in his arms",
    "number": "937",
  },
  {
    "artist": "Toby Keith",
    "song": "Does That Blue Moon Ever Shine on You?",
    "year": "1996",
    "seconds": "50",
    "lyric": "Hold you close to me",
    "number": "938",
  },
  {
    "artist": "Luke Combs (ft. Eric Church)",
    "song": "Does to Me",
    "year": "2020",
    "seconds": "60",
    "lyric": "But I was hell on wheels",
    "number": "939",
  },
  {
    "artist": "Dinah Shore",
    "song": "Doin’ What Comes Natur’lly",
    "year": "1946",
    "seconds": "50",
    "lyric": "Still they raised a family",
    "number": "940",
  },
  {
    "artist": "Tony Martin",
    "song": "Domino",
    "year": "1951",
    "seconds": "50",
    "lyric": "And I burst into fire",
    "number": "941",
  },
  {
    "artist": "Van Morrison",
    "song": "Domino",
    "year": "1970",
    "seconds": "50",
    "lyric": "Blues music, on the radio",
    "number": "942",
  },
  {
    "artist": "Chris Janson",
    "song": "Done",
    "year": "2019",
    "seconds": "50",
    "lyric": "I spend my nights praying",
    "number": "943",
  },
  {
    "artist": "The Band Perry",
    "song": "DONE.",
    "year": "2013",
    "seconds": "40",
    "lyric": "Bottle up the ashes",
    "number": "944",
  },
  {
    "artist": "Ritchie Valens",
    "song": "Donna",
    "year": "1959",
    "seconds": "50",
    "lyric": "‘Cause I love my girl",
    "number": "945",
  },
  {
    "artist": "Ed Sheeran",
    "song": "Don’t",
    "year": "2014",
    "seconds": "50",
    "lyric": "And sit on a couch",
    "number": "946",
  },
  {
    "artist": "Elvis Presley",
    "song": "Don’t",
    "year": "1958",
    "seconds": "60",
    "lyric": "And I want to kiss you",
    "number": "947",
  },
  {
    "artist": "Elvis Presley",
    "song": "Don’t Be Cruel",
    "year": "1956",
    "seconds": "70",
    "lyric": "Baby it’s just you I’m thinking of",
    "number": "948",
  },
  {
    "artist": "Shania Twain",
    "song": "Don’t Be Stupid (You Know I Love You)",
    "year": "1997",
    "seconds": "50",
    "lyric": "You hang over my shoulder",
    "number": "949",
  },
  {
    "artist": "Kenny Chesney",
    "song": "Don’t Blink",
    "year": "2007",
    "seconds": "50",
    "lyric": "And you take a nap",
    "number": "950",
  },
  {
    "artist": "Connie Francis",
    "song": "Don’t Break the Heart That Loves You",
    "year": "1962",
    "seconds": "60",
    "lyric": "You know I’m jealous of you",
    "number": "951",
  },
  {
    "artist": "Electric Light Orchestra",
    "song": "Don’t Bring Me Down",
    "year": "1979",
    "seconds": "60",
    "lyric": "Before I get off the floor",
    "number": "952",
  },
  {
    "artist": "Conway Twitty",
    "song": "Don’t Call Him a Cowboy",
    "year": "1985",
    "seconds": "40",
    "lyric": "Leaning on the bar",
    "number": "953",
  },
  {
    "artist": "Tom Petty & the Heartbreakers",
    "song": "Don’t Come Around Here No More",
    "year": "1985",
    "seconds": "50",
    "lyric": "Stop walking down my street",
    "number": "954",
  },
  {
    "artist": "Loretta Lynn",
    "song": "Don’t Come Home A Drinkin’",
    "year": "1966",
    "seconds": "60",
    "lyric": "Leave a bottle or me behind",
    "number": "955",
  },
  {
    "artist": "Tom Petty & the Heartbreakers",
    "song": "Don’t Do Me Like That",
    "year": "1979",
    "seconds": "50",
    "lyric": "Cut you down to size",
    "number": "956",
  },
  {
    "artist": "Lobo",
    "song": "Don’t Expect Me to Be Your Friend",
    "year": "1973",
    "seconds": "70",
    "lyric": "I feel the walls start crashing in",
    "number": "957",
  },
  {
    "artist": "Kenny Rogers and Kim Carnes",
    "song": "Don’t Fall in Love With a Dreamer",
    "year": "1980",
    "seconds": "40",
    "lyric": "Before we say goodbye",
    "number": "958",
  },
  {
    "artist": "Blue Oyster Cult",
    "song": "Don’t Fear the Reaper",
    "year": "1976",
    "seconds": "70",
    "lyric": "The wind, the sun or the rain",
    "number": "959",
  },
  {
    "artist": "Roy Rogers",
    "song": "Don’t Fence Me In",
    "year": "1944",
    "seconds": "40",
    "lyric": "Straddle my old saddle",
    "number": "960",
  },
  {
    "artist": "Kenny Loggins & Steve Perry",
    "song": "Don’t Fight It",
    "year": "1982",
    "seconds": "50",
    "lyric": "I turn up the music",
    "number": "961",
  },
  {
    "artist": "Wilson Pickett",
    "song": "Don’t Fight It",
    "year": "1965",
    "seconds": "40",
    "lyric": "And after the dance",
    "number": "962",
  },
  {
    "artist": "Charley Pride",
    "song": "Don’t Fight the Feelings of Love",
    "year": "1973",
    "seconds": "50",
    "lyric": "So reach out your hand",
    "number": "963",
  },
  {
    "artist": "Mariah Carey",
    "song": "Don’t Forget About Us",
    "year": "2005",
    "seconds": "70",
    "lyric": "I can see it in your eyes",
    "number": "964",
  },
  {
    "artist": "Glass Tiger",
    "song": "Don’t Forget Me (When I’m Gone)",
    "year": "1986",
    "seconds": "70",
    "lyric": "I have loved you for so long",
    "number": "965",
  },
  {
    "artist": "Carrie Underwood",
    "song": "Don’t Forget to Remember Me",
    "year": "2006",
    "seconds": "60",
    "lyric": "There’s a fifty in the ashtray",
    "number": "966",
  },
  {
    "artist": "The Pretenders",
    "song": "Don’t Get Me Wrong",
    "year": "1986",
    "seconds": "40",
    "lyric": "I see neon lights",
    "number": "967",
  },
  {
    "artist": "Chicane (ft. Bryan Adams)",
    "song": "Don’t Give Up",
    "year": "2000",
    "seconds": "50",
    "lyric": "Every day’s an uphill climb",
    "number": "968",
  },
  {
    "artist": "Peter Gabriel and Kate Bush",
    "song": "Don’t Give Up",
    "year": "1986",
    "seconds": "50",
    "lyric": "Burned down to the ground",
    "number": "969",
  },
  {
    "artist": "David Soul",
    "song": "Don’t Give Up On Us",
    "year": "1976",
    "seconds": "50",
    "lyric": "And painted on the stars",
    "number": "970",
  },
  {
    "artist": "Elton John and Kiki Dee",
    "song": "Don’t Go Breaking My Heart",
    "year": "1976",
    "seconds": "50",
    "lyric": "I gave you my key",
    "number": "971",
  },
  {
    "artist": "Kenny Chesney",
    "song": "Don’t Happen Twice",
    "year": "2001",
    "seconds": "60",
    "lyric": "I still taste that first kiss",
    "number": "972",
  },
  {
    "artist": "Crystal Gayle",
    "song": "Don’t It Make Your Brown Eyes Blue",
    "year": "1977",
    "seconds": "50",
    "lyric": "Tell me you love me",
    "number": "973",
  },
  {
    "artist": "Wilson Pickett",
    "song": "Don’t Knock My Love – Pt. I",
    "year": "1971",
    "seconds": "50",
    "lyric": "All the way around town",
    "number": "974",
  },
  {
    "artist": "Linda Ronstadt and Aaron Neville",
    "song": "Don’t Know Much",
    "year": "1989",
    "seconds": "50",
    "lyric": "So beaten and so battered",
    "number": "975",
  },
  {
    "artist": "Mark Wills",
    "song": "Don’t Laugh At Me",
    "year": "1998",
    "seconds": "60",
    "lyric": "I’m a little boy with glasses",
    "number": "976",
  },
  {
    "artist": "Harold Melvin & the Blue Notes",
    "song": "Don’t Leave Me This Way",
    "year": "1975",
    "seconds": "70",
    "lyric": "I will surely miss your tender kiss",
    "number": "977",
  },
  {
    "artist": "Styx",
    "song": "Don’t Let It End",
    "year": "1983",
    "seconds": "70",
    "lyric": "Honey, please, don’t walk out that door",
    "number": "978",
  },
  {
    "artist": "The Chainsmokers (ft. Daya)",
    "song": "Don’t Let Me Down",
    "year": "2016",
    "seconds": "60",
    "lyric": "I think I’m losing my mind",
    "number": "979",
  },
  {
    "artist": "Perry Como (ft. the Ramblers)",
    "song": "Don’t Let the Stars Get in Your Eyes",
    "year": "1952",
    "seconds": "50",
    "lyric": "The moon break your heart",
    "number": "980",
  },
  {
    "artist": "Gerry and the Pacemakers",
    "song": "Don’t Let the Sun Catch You Crying",
    "year": "1964",
    "seconds": "60",
    "lyric": "Your heart may be broken tonight",
    "number": "981",
  },
  {
    "artist": "Elton John",
    "song": "Don’t Let the Sun Go Down on Me",
    "year": "1974",
    "seconds": "50",
    "lyric": "Frozen here on the ladder",
    "number": "982",
  },
  {
    "artist": "The Black Eyed Peas",
    "song": "Don’t Lie",
    "year": "2005",
    "seconds": "40",
    "lyric": "Blinded by lights, camera",
    "number": "983",
  },
  {
    "artist": "Boston",
    "song": "Don’t Look Back",
    "year": "1978",
    "seconds": "80",
    "lyric": "The sun is shinin’, the clouds are breakin’",
    "number": "984",
  },
  {
    "artist": "Oasis",
    "song": "Don’t Look Back in Anger",
    "year": "1995",
    "seconds": "40",
    "lyric": "My soul slides away",
    "number": "985",
  },
  {
    "artist": "Drake (ft. Michael Jackson)",
    "song": "Don’t Matter to Me",
    "year": "2018",
    "seconds": "60",
    "lyric": "You love to see me down",
    "number": "986",
  },
  {
    "artist": "The Black Eyed Peas",
    "song": "Don’t Phunk with My Heart",
    "year": "2005",
    "seconds": "40",
    "lyric": "Your pistol shot me",
    "number": "987",
  },
  {
    "artist": "Hamilton, Joe Frank & Reynolds",
    "song": "Don’t Pull Your Love",
    "year": "1971",
    "seconds": "60",
    "lyric": "And cry for a hundred years",
    "number": "988",
  },
  {
    "artist": "The Hues Corporation",
    "song": "Don’t Rock the Boat",
    "year": "1974",
    "seconds": "50",
    "lyric": "Safe from a rolling sea",
    "number": "989",
  },
  {
    "artist": "Alan Jackson",
    "song": "Don’t Rock the Jukebox",
    "year": "1991",
    "seconds": "50",
    "lyric": "You spend your money, baby",
    "number": "990",
  },
  {
    "artist": "Beverly Bremers",
    "song": "Don’t Say You Don’t Remember",
    "year": "1972",
    "seconds": "50",
    "lyric": "You always call me baby",
    "number": "991",
  },
  {
    "artist": "The Andrews Sisters",
    "song": "Don’t Sit Under the Apple Tree",
    "year": "1942",
    "seconds": "60",
    "lyric": "The stars get in your eyes",
    "number": "992",
  },
  {
    "artist": "Petula Clark",
    "song": "Don’t Sleep in the Subway",
    "year": "1967",
    "seconds": "50",
    "lyric": "Stand in the pouring rain",
    "number": "993",
  },
  {
    "artist": "No Doubt",
    "song": "Don’t Speak",
    "year": "1995",
    "seconds": "60",
    "lyric": "With my head in my hands",
    "number": "994",
  },
  {
    "artist": "The Police",
    "song": "Don’t Stand So Close to Me",
    "year": "1980",
    "seconds": "50",
    "lyric": "This girl’s an open page",
    "number": "995",
  },
  {
    "artist": "Dua Lipa",
    "song": "Don’t Start Now",
    "year": "2019",
    "seconds": "50",
    "lyric": "Did a full 180, crazy",
    "number": "996",
  },
  {
    "artist": "Fleetwood Mac",
    "song": "Don’t Stop",
    "year": "1977",
    "seconds": "40",
    "lyric": "To see you smile",
    "number": "997",
  },
  {
    "artist": "Journey",
    "song": "Don’t Stop Believin’",
    "year": "1981",
    "seconds": "50",
    "lyric": "Born to sing the blues",
    "number": "998",
  },
  {
    "artist": "Queen",
    "song": "Don’t Stop Me Now",
    "year": "1979",
    "seconds": "40",
    "lyric": "I’m a rocket ship",
    "number": "999",
  },
  {
    "artist": "Pitbull",
    "song": "Don’t Stop the Party",
    "year": "2012",
    "seconds": "50",
    "lyric": "I’m running through the world",
    "number": "1000",
  },
  {
    "artist": "The Black Eyed Peas",
    "song": "Don’t Stop the Party",
    "year": "2011",
    "seconds": "40",
    "lyric": "Music is my medicine",
    "number": "1001",
  },
  {
    "artist": "Michael Jackson",
    "song": "Don’t Stop ’Til You Get Enough",
    "year": "1979",
    "seconds": "70",
    "lyric": "Touch me and I feel on fire",
    "number": "1002",
  },
  {
    "artist": "Conway Twitty",
    "song": "Don’t Take It Away",
    "year": "1979",
    "seconds": "50",
    "lyric": "I stepped over the line",
    "number": "1003",
  },
  {
    "artist": "Tim McGraw",
    "song": "Don’t Take the Girl",
    "year": "1994",
    "seconds": "40",
    "lyric": "Holdin’ a fishin’ pole",
    "number": "1004",
  },
  {
    "artist": "Johnny Cash",
    "song": "Don’t Take Your Guns to Town",
    "year": "1958",
    "seconds": "60",
    "lyric": "And combed his dark hair down",
    "number": "1005",
  },
  {
    "artist": "Mickey Gilley",
    "song": "Don’t the Girls All Get Prettier At Closing Time",
    "year": "1976",
    "seconds": "50",
    "lyric": "Angels of the backstreet bars",
    "number": "1006",
  },
  {
    "artist": "Bob Dylan",
    "song": "Don’t Think Twice, It’s All Right",
    "year": "1963",
    "seconds": "50",
    "lyric": "I gave her my heart",
    "number": "1007",
  },
  {
    "artist": "Beach Boys",
    "song": "Don’t Worry Baby",
    "year": "1964",
    "seconds": "50",
    "lyric": "Building up inside of me",
    "number": "1008",
  },
  {
    "artist": "Bobby McFerrin",
    "song": "Don’t Worry, Be Happy",
    "year": "1988",
    "seconds": "50",
    "lyric": "A little song I wrote",
    "number": "1009",
  },
  {
    "artist": "Marty Robbins",
    "song": "Don’t Worry ‘Bout Me",
    "year": "1961",
    "seconds": "80",
    "lyric": "One heart is free, one heart will cry",
    "number": "1010",
  },
  {
    "artist": "Brett Eldredge",
    "song": "Don’t Ya",
    "year": "2013",
    "seconds": "50",
    "lyric": "When you walked by me",
    "number": "1011",
  },
  {
    "artist": "Ray Price",
    "song": "Don’t You Ever Get Tired (Of Hurting Me)?",
    "year": "1965",
    "seconds": "50",
    "lyric": "Still I keep running back",
    "number": "1012",
  },
  {
    "artist": "Simple Minds",
    "song": "Don’t You (Forget About Me)",
    "year": "1985",
    "seconds": "60",
    "lyric": "Light gets into your heart, baby",
    "number": "1013",
  },
  {
    "artist": "Della Reese",
    "song": "Don’t You Know?",
    "year": "1959",
    "seconds": "40",
    "lyric": "I’m under your spell",
    "number": "1014",
  },
  {
    "artist": "Ronnie Milsap",
    "song": "Don’t You Know How Much I Love You?",
    "year": "1983",
    "seconds": "50",
    "lyric": "Watch me hit the ground",
    "number": "1015",
  },
  {
    "artist": "Jason Aldean (ft. Kelly Clarkson)",
    "song": "Don’t You Wanna Stay?",
    "year": "2010",
    "seconds": "50",
    "lyric": "And your hair falling slow",
    "number": "1016",
  },
  {
    "artist": "The Human League",
    "song": "Don’t You Want Me",
    "year": "1981",
    "seconds": "60",
    "lyric": "A waitress in a cocktail bar",
    "number": "1017",
  },
  {
    "artist": "Rolling Stones",
    "song": "Doo Doo Doo Doo Doo (Heartbreaker)",
    "year": "1973",
    "seconds": "50",
    "lyric": "A bullet through his heart",
    "number": "1018",
  },
  {
    "artist": "Foreigner",
    "song": "Double Vision",
    "year": "1978",
    "seconds": "60",
    "lyric": "But my body’s in the lead",
    "number": "1019",
  },
  {
    "artist": "ZZ Top",
    "song": "Doubleback",
    "year": "1990",
    "seconds": "50",
    "lyric": "Rollin’ on through the night",
    "number": "1020",
  },
  {
    "artist": "Neil Young",
    "song": "Down By the River",
    "year": "1969",
    "seconds": "50",
    "lyric": "Drag me over the rainbow",
    "number": "1021",
  },
  {
    "artist": "Billy Joe Royal",
    "song": "Down in the Boondocks",
    "year": "1965",
    "seconds": "50",
    "lyric": "Steal away to see me",
    "number": "1022",
  },
  {
    "artist": "Creedence Clearwater Revival",
    "song": "Down on the Corner",
    "year": "1969",
    "seconds": "60",
    "lyric": "A dance and doubles on kazoo",
    "number": "1023",
  },
  {
    "artist": "Tim McGraw",
    "song": "Down on the Farm",
    "year": "1994",
    "seconds": "50",
    "lyric": "Heading down to the creek",
    "number": "1024",
  },
  {
    "artist": "Kenny Chesney (ft. Mac McAnally)",
    "song": "Down the Road",
    "year": "2008",
    "seconds": "50",
    "lyric": "Four houses down from me",
    "number": "1025",
  },
  {
    "artist": "Tanya Tucker",
    "song": "Down to My Last Teardrop",
    "year": "1991",
    "seconds": "50",
    "lyric": "In a magazine I read",
    "number": "1026",
  },
  {
    "artist": "Jake Owen",
    "song": "Down to the Honkytonk",
    "year": "2018",
    "seconds": "60",
    "lyric": "A flag on the front porch",
    "number": "1027",
  },
  {
    "artist": "Men at Work",
    "song": "Down Under",
    "year": "1981",
    "seconds": "50",
    "lyric": "Can’t you hear the thunder?",
    "number": "1028",
  },
  {
    "artist": "Bessie Smith",
    "song": "Downhearted Blues",
    "year": "1923",
    "seconds": "50",
    "lyric": "Follow me to my grave",
    "number": "1029",
  },
  {
    "artist": "Neil Young (ft. Pearl Jam)",
    "song": "Downtown",
    "year": "1995",
    "seconds": "50",
    "lyric": "It’s right around the corner",
    "number": "1030",
  },
  {
    "artist": "Petula Clark",
    "song": "Downtown",
    "year": "1965",
    "seconds": "50",
    "lyric": "Before the night is over",
    "number": "1031",
  },
  {
    "artist": "One Direction",
    "song": "Drag Me Down",
    "year": "2015",
    "seconds": "50",
    "lyric": "And baby, you’re a boat",
    "number": "1032",
  },
  {
    "artist": "Tommy James",
    "song": "Draggin’ the Line",
    "year": "1971",
    "seconds": "50",
    "lyric": "We dig snow and rain",
    "number": "1033",
  },
  {
    "artist": "Ozzie Nelson and his Orchestra",
    "song": "Dream a Little Dream of Me",
    "year": "1931",
    "seconds": "40",
    "lyric": "Still craving your kiss",
    "number": "1034",
  },
  {
    "artist": "Roy Orbison",
    "song": "Dream Baby (How Long Must I Dream)",
    "year": "1962",
    "seconds": "40",
    "lyric": "The whole day through",
    "number": "1035",
  },
  {
    "artist": "Bobby Darin",
    "song": "Dream Lover",
    "year": "1959",
    "seconds": "70",
    "lyric": "A girl to hold in my arms",
    "number": "1036",
  },
  {
    "artist": "Aerosmith",
    "song": "Dream On",
    "year": "1973",
    "seconds": "80",
    "lyric": "Sing for the laughter, sing for the tear",
    "number": "1037",
  },
  {
    "artist": "Gary Wright",
    "song": "Dream Weaver",
    "year": "1976",
    "seconds": "70",
    "lyric": "Fly me high through the starry skies",
    "number": "1038",
  },
  {
    "artist": "Heart",
    "song": "Dreamboat Annie",
    "year": "1976",
    "seconds": "50",
    "lyric": "Riding on the diamond waves",
    "number": "1039",
  },
  {
    "artist": "Blondie",
    "song": "Dreaming",
    "year": "1979",
    "seconds": "40",
    "lyric": "Your hair is beautiful",
    "number": "1040",
  },
  {
    "artist": "Rush",
    "song": "Dreamline",
    "year": "1991",
    "seconds": "60",
    "lyric": "A radar fix on the stars",
    "number": "1041",
  },
  {
    "artist": "Mariah Carey",
    "song": "Dreamlover",
    "year": "1993",
    "seconds": "60",
    "lyric": "Take me up, take me down",
    "number": "1042",
  },
  {
    "artist": "Fleetwood Mac",
    "song": "Dreams",
    "year": "1977",
    "seconds": "60",
    "lyric": "Thunder only happens when it’s raining",
    "number": "1043",
  },
  {
    "artist": "Van Halen",
    "song": "Dreams",
    "year": "1986",
    "seconds": "50",
    "lyric": "And falls between the cracks",
    "number": "1044",
  },
  {
    "artist": "Glen Campbell",
    "song": "Dreams of the Everyday Housewife",
    "year": "1968",
    "seconds": "50",
    "lyric": "And stares at the wrinkles",
    "number": "1045",
  },
  {
    "artist": "Narvel Felts",
    "song": "Drift Away",
    "year": "1973",
    "seconds": "50",
    "lyric": "Give me the beat, boys",
    "number": "1046",
  },
  {
    "artist": "Travis Tritt",
    "song": "Drift Off to Dream",
    "year": "1991",
    "seconds": "60",
    "lyric": "Hold hands on the porch swing",
    "number": "1047",
  },
  {
    "artist": "Luke Bryan",
    "song": "Drink a Beer",
    "year": "2013",
    "seconds": "50",
    "lyric": "And watch the sunset disappear",
    "number": "1048",
  },
  {
    "artist": "Blake Shelton",
    "song": "Drink On It",
    "year": "2012",
    "seconds": "50",
    "lyric": "And your Cosmo’s getting’ low",
    "number": "1049",
  },
  {
    "artist": "Waylon Jennings",
    "song": "Drinkin’ and Dreamin’",
    "year": "1985",
    "seconds": "50",
    "lyric": "Born to be tied down",
    "number": "1050",
  },
  {
    "artist": "George Strait",
    "song": "Drinking Champagne",
    "year": "1990",
    "seconds": "50",
    "lyric": "I’ll wake up with you",
    "number": "1051",
  },
  {
    "artist": "Midland",
    "song": "Drinkin’ Problem",
    "year": "2017",
    "seconds": "60",
    "lyric": "First one in, last one out",
    "number": "1052",
  },
  {
    "artist": "Incubus",
    "song": "Drive",
    "year": "2000",
    "seconds": "50",
    "lyric": "The one behind the wheel",
    "number": "1053",
  },
  {
    "artist": "The Cars",
    "song": "Drive",
    "year": "1984",
    "seconds": "60",
    "lyric": "Pick you up when you fall",
    "number": "1054",
  },
  {
    "artist": "Alan Jackson",
    "song": "Drive (For Daddy Gene)",
    "year": "2002",
    "seconds": "50",
    "lyric": "Two hands on the wheel",
    "number": "1055",
  },
  {
    "artist": "The Beatles",
    "song": "Drive My Car",
    "year": "1965",
    "seconds": "50",
    "lyric": "A star of the screen",
    "number": "1056",
  },
  {
    "artist": "Eddie Rabbitt",
    "song": "Drivin’ My Life Away",
    "year": "1980",
    "seconds": "50",
    "lyric": "Lookin’ for a sunny day",
    "number": "1057",
  },
  {
    "artist": "Dallas Smith",
    "song": "Drop",
    "year": "2019",
    "seconds": "50",
    "lyric": "Follow me to the candlelight",
    "number": "1058",
  },
  {
    "artist": "Snoop Dogg (feat. Pharrell)",
    "song": "Drop It Like It’s Hot",
    "year": "2007",
    "seconds": "40",
    "lyric": "See these ice cubes?",
    "number": "1059",
  },
  {
    "artist": "Train",
    "song": "Drops of Jupiter",
    "year": "2001",
    "seconds": "60",
    "lyric": "Did you sail across the sun?",
    "number": "1060",
  },
  {
    "artist": "Ray Charles",
    "song": "Drown in My Own Tears",
    "year": "1956",
    "seconds": "60",
    "lyric": "I’m so blue here without you",
    "number": "1061",
  },
  {
    "artist": "Chris Janson",
    "song": "Drunk Girl",
    "year": "2017",
    "seconds": "40",
    "lyric": "Bouncing like a pinball",
    "number": "1062",
  },
  {
    "artist": "Beyoncé (ft. Jay-Z)",
    "song": "Drunk In Love",
    "year": "2013",
    "seconds": "60",
    "lyric": "I fill the tub up halfway",
    "number": "1063",
  },
  {
    "artist": "Dierks Bentley",
    "song": "Drunk on a Plane",
    "year": "2014",
    "seconds": "60",
    "lyric": "Mardi Gras up in the clouds",
    "number": "1064",
  },
  {
    "artist": "Luke Bryan",
    "song": "Drunk on You",
    "year": "2012",
    "seconds": "70",
    "lyric": "Throwin’ little cherry bombs into my fire",
    "number": "1065",
  },
  {
    "artist": "Aerosmith",
    "song": "Dude (Looks Like a Lady)",
    "year": "1987",
    "seconds": "50",
    "lyric": "She whipped out her gun",
    "number": "1066",
  },
  {
    "artist": "Gene Chandler",
    "song": "Duke of Earl",
    "year": "1962",
    "seconds": "60",
    "lyric": "And no one can hurt you",
    "number": "1067",
  },
  {
    "artist": "Zayn (ft. Sia)",
    "song": "Dusk Till Dawn",
    "year": "2017",
    "seconds": "60",
    "lyric": "Light it up, on the run",
    "number": "1068",
  },
  {
    "artist": "Kansas",
    "song": "Dust In the Wind",
    "year": "1978",
    "seconds": "50",
    "lyric": "But the earth and sky",
    "number": "1069",
  },
  {
    "artist": "Led Zeppelin",
    "song": "D’yer Mak’er",
    "year": "1973",
    "seconds": "70",
    "lyric": "I read the letter you wrote me",
    "number": "1070",
  },
  {
    "artist": "BTS",
    "song": "Dynamite",
    "year": "2020",
    "seconds": "60",
    "lyric": "And a game of ping pong",
    "number": "1071",
  },
  {
    "artist": "Katy Perry (feat. Kanye West)",
    "song": "E.T.",
    "year": "2010",
    "seconds": "70",
    "lyric": "You open my eyes and I’m ready",
    "number": "1072",
  },
  {
    "artist": "Gordon Lightfoot",
    "song": "Early Morning Rain",
    "year": "1966",
    "seconds": "60",
    "lyric": "And my pockets full of sand",
    "number": "1073",
  },
  {
    "artist": "The Penguins",
    "song": "Earth Angel",
    "year": "1955",
    "seconds": "70",
    "lyric": "I fell for you and I knew",
    "number": "1074",
  },
  {
    "artist": "Jerry Reed",
    "song": "East Bound and Down",
    "year": "1977",
    "seconds": "50",
    "lyric": "He’s hot on your trail",
    "number": "1075",
  },
  {
    "artist": "Rascal Flatts and Natasha Bedingfield",
    "song": "Easy",
    "year": "2011",
    "seconds": "60",
    "lyric": "I can smile, live it up",
    "number": "1076",
  },
  {
    "artist": "The Commodores",
    "song": "Easy",
    "year": "1977",
    "seconds": "50",
    "lyric": "You see I begged, stole",
    "number": "1077",
  },
  {
    "artist": "Troye Sivan and Kacey Musgraves",
    "song": "Easy",
    "year": "2020",
    "seconds": "50",
    "lyric": "A hair on my coat",
    "number": "1078",
  },
  {
    "artist": "Bobby Sherman",
    "song": "Easy Come, Easy Go",
    "year": "1970",
    "seconds": "70",
    "lyric": "Taking the shade out of the sun",
    "number": "1079",
  },
  {
    "artist": "George Strait",
    "song": "Easy Come, Easy Go",
    "year": "1993",
    "seconds": "60",
    "lyric": "No tears and no broken hearts",
    "number": "1080",
  },
  {
    "artist": "Phil Collins",
    "song": "Easy Lover",
    "year": "1985",
    "seconds": "50",
    "lyric": "You’ll be on your knees",
    "number": "1081",
  },
  {
    "artist": "Freddie Hart",
    "song": "Easy Loving",
    "year": "1971",
    "seconds": "50",
    "lyric": "A pair of angel wings",
    "number": "1082",
  },
  {
    "artist": "Three Dog Night",
    "song": "Easy To Be Hard",
    "year": "1969",
    "seconds": "70",
    "lyric": "You know I’m hung up on you",
    "number": "1083",
  },
  {
    "artist": "The Everly Brothers",
    "song": "Ebony Eyes",
    "year": "1961",
    "seconds": "70",
    "lyric": "I felt a burning break deep inside",
    "number": "1084",
  },
  {
    "artist": "Paul McCartney & Stevie Wonder",
    "song": "Ebony and Ivory",
    "year": "1982",
    "seconds": "60",
    "lyric": "Side by side on my piano",
    "number": "1085",
  },
  {
    "artist": "Richard Rodgers",
    "song": "Edelweiss",
    "year": "1965",
    "seconds": "60",
    "lyric": "Small and white, clean and bright",
    "number": "1086",
  },
  {
    "artist": "Stevie Nicks",
    "song": "Edge of Seventeen",
    "year": "1981",
    "seconds": "50",
    "lyric": "Like the white winged dove",
    "number": "1087",
  },
  {
    "artist": "The Beatles",
    "song": "Eight Days a Week",
    "year": "1964",
    "seconds": "50",
    "lyric": "Love you every day, girl",
    "number": "1088",
  },
  {
    "artist": "Marty Robbins",
    "song": "El Condor Pasa (If I Could)",
    "year": "1970",
    "seconds": "60",
    "lyric": "Feel the earth beneath my feet",
    "number": "1089",
  },
  {
    "artist": "Marty Robbins",
    "song": "El Paso",
    "year": "1959",
    "seconds": "50",
    "lyric": "I see five mounted cowboys",
    "number": "1090",
  },
  {
    "artist": "Marty Robbins",
    "song": "El Paso City",
    "year": "1976",
    "seconds": "50",
    "lyric": "From thirty thousand feet above",
    "number": "1091",
  },
  {
    "artist": "Sia",
    "song": "Elastic Heart",
    "year": "2015",
    "seconds": "50",
    "lyric": "I’m still fighting for peace",
    "number": "1092",
  },
  {
    "artist": "The Beatles",
    "song": "Eleanor Rigby",
    "year": "1966",
    "seconds": "70",
    "lyric": "Keeps in a jar by the door",
    "number": "1093",
  },
  {
    "artist": "Eddy Grant",
    "song": "Electric Avenue",
    "year": "1983",
    "seconds": "70",
    "lyric": "Deep in my heart I abhor ya",
    "number": "1094",
  },
  {
    "artist": "MGMT",
    "song": "Electric Feel",
    "year": "2007",
    "seconds": "40",
    "lyric": "Running through her skin",
    "number": "1095",
  },
  {
    "artist": "U2",
    "song": "Electrical Storm",
    "year": "2002",
    "seconds": "40",
    "lyric": "The air is heavy",
    "number": "1096",
  },
  {
    "artist": "The Turtles",
    "song": "Elenore",
    "year": "1968",
    "seconds": "40",
    "lyric": "I think you’re swell",
    "number": "1097",
  },
  {
    "artist": "Beirut",
    "song": "Elephant Gun",
    "year": "2006",
    "seconds": "50",
    "lyric": "Take the big king down",
    "number": "1098",
  },
  {
    "artist": "U2",
    "song": "Elevation",
    "year": "2001",
    "seconds": "50",
    "lyric": "The orbit of your hips",
    "number": "1099",
  },
  {
    "artist": "Hank Williams Jr.",
    "song": "Eleven Roses",
    "year": "1972",
    "seconds": "50",
    "lyric": "Our garden where they grow",
    "number": "1100",
  },
  {
    "artist": "Three Dog Night",
    "song": "Eli’s Coming",
    "year": "1969",
    "seconds": "40",
    "lyric": "Hide your heart, girl",
    "number": "1101",
  },
  {
    "artist": "Bob Lind",
    "song": "Elusive Butterfly",
    "year": "1966",
    "seconds": "50",
    "lyric": "A distant pair of wings",
    "number": "1102",
  },
  {
    "artist": "The Oak Ridge Boys",
    "song": "Elvira",
    "year": "1981",
    "seconds": "40",
    "lyric": "Lips like cherry wine",
    "number": "1103",
  },
  {
    "artist": "The Who",
    "song": "Eminence Front",
    "year": "1982",
    "seconds": "60",
    "lyric": "Big wheel spins, the hair thins",
    "number": "1104",
  },
  {
    "artist": "First Aid Kit",
    "song": "Emmylou",
    "year": "2012",
    "seconds": "50",
    "lyric": "A ghost in my mind",
    "number": "1105",
  },
  {
    "artist": "Samantha Sang",
    "song": "Emotion",
    "year": "1977",
    "seconds": "40",
    "lyric": "Tears on my pillow",
    "number": "1106",
  },
  {
    "artist": "Mariah Carey",
    "song": "Emotions",
    "year": "1991",
    "seconds": "60",
    "lyric": "When you’re looking into my eyes",
    "number": "1107",
  },
  {
    "artist": "Rolling Stones",
    "song": "Emotional Rescue",
    "year": "1980",
    "seconds": "50",
    "lyric": "Your knight in shining armour",
    "number": "1108",
  },
  {
    "artist": "Jay-Z and Alicia Keys",
    "song": "Empire State of Mind",
    "year": "2005",
    "seconds": "40",
    "lyric": "New York, concrete jungle",
    "number": "1109",
  },
  {
    "artist": "Elton John",
    "song": "Empty Garden (Hey Hey Johnny)",
    "year": "1982",
    "seconds": "50",
    "lyric": "Now we pray for rain",
    "number": "1110",
  },
  {
    "artist": "Taylor Swift (ft. Ed Sheeran & Future)",
    "song": "End Game",
    "year": "2017",
    "seconds": "60",
    "lyric": "Chips on both of my shoulders",
    "number": "1111",
  },
  {
    "artist": "The Traveling Wilburys",
    "song": "End of the Line",
    "year": "1988",
    "seconds": "60",
    "lyric": "Ashamed of the car I drive",
    "number": "1112",
  },
  {
    "artist": "Lionel Richie & Diana Ross",
    "song": "Endless Love",
    "year": "1981",
    "seconds": "70",
    "lyric": "I’ll hold you close in my arms",
    "number": "1113",
  },
  {
    "artist": "Roger Miller",
    "song": "England Swings",
    "year": "1965",
    "seconds": "60",
    "lyric": "Rosy-red cheeks of the little children",
    "number": "1114",
  },
  {
    "artist": "Depeche Mode",
    "song": "Enjoy the Silence",
    "year": "1990",
    "seconds": "40",
    "lyric": "Here in my arms",
    "number": "1115",
  },
  {
    "artist": "Guy Lombardo & his Royal Canadians",
    "song": "Enjoy Yourself",
    "year": "1949",
    "seconds": "50",
    "lyric": "It’s later than you think",
    "number": "1116",
  },
  {
    "artist": "The Jacksons",
    "song": "Enjoy Yourself",
    "year": "1976",
    "seconds": "70",
    "lyric": "We can tear the whole house down",
    "number": "1117",
  },
  {
    "artist": "Metallica",
    "song": "Enter Sandman",
    "year": "1991",
    "seconds": "40",
    "lyric": "Gripping your pillow tight",
    "number": "1118",
  },
  {
    "artist": "Janet Jackson",
    "song": "Escapade",
    "year": "1990",
    "seconds": "50",
    "lyric": "Worked so hard all week",
    "number": "1119",
  },
  {
    "artist": "Cascada",
    "song": "Evacuate the Dancefloor",
    "year": "2009",
    "seconds": "60",
    "lyric": "Stop, this beat is killing me",
    "number": "1120",
  },
  {
    "artist": "Barry McGuire",
    "song": "Eve of Destruction",
    "year": "1965",
    "seconds": "60",
    "lyric": "Over and over again, my friend",
    "number": "1121",
  },
  {
    "artist": "U2",
    "song": "Even Better Than the Real Thing",
    "year": "1992",
    "seconds": "40",
    "lyric": "A swarm of bees",
    "number": "1122",
  },
  {
    "artist": "Eli Young Band",
    "song": "Even If It Breaks Your Heart",
    "year": "2012",
    "seconds": "70",
    "lyric": "All the way to rock and roll",
    "number": "1123",
  },
  {
    "artist": "Air Supply",
    "song": "Even the Nights Are Better",
    "year": "1982",
    "seconds": "60",
    "lyric": "I was tied to a heartache",
    "number": "1124",
  },
  {
    "artist": "Luke Combs",
    "song": "Even Though I’m Leaving",
    "year": "2019",
    "seconds": "60",
    "lyric": "I can walk this road alone",
    "number": "1125",
  },
  {
    "artist": "Barbra Streisand",
    "song": "Evergreen",
    "year": "1976",
    "seconds": "60",
    "lyric": "A rose under the April snow",
    "number": "1126",
  },
  {
    "artist": "Gladys Knight & the Pips",
    "song": "Every Beat of My Heart",
    "year": "1961",
    "seconds": "60",
    "lyric": "In the pouring rain say farewell",
    "number": "1127",
  },
  {
    "artist": "The Police",
    "song": "Every Breath You Take",
    "year": "1983",
    "seconds": "60",
    "lyric": "I can only see your face",
    "number": "1128",
  },
  {
    "artist": "Rascal Flatts",
    "song": "Every Day",
    "year": "2008",
    "seconds": "60",
    "lyric": "I’m hangin’ on to your words",
    "number": "1129",
  },
  {
    "artist": "Sheryl Crow",
    "song": "Every Day is a Winding Road",
    "year": "1996",
    "seconds": "40",
    "lyric": "I hitched a ride",
    "number": "1130",
  },
  {
    "artist": "Robert Palmer",
    "song": "Every Kinda People",
    "year": "1978",
    "seconds": "60",
    "lyric": "Bring a peace to any soul",
    "number": "1131",
  },
  {
    "artist": "Carly Pearce",
    "song": "Every Little Thing",
    "year": "2017",
    "seconds": "50",
    "lyric": "Your eyes like a window",
    "number": "1132",
  },
  {
    "artist": "The Police",
    "song": "Every Little Thing She Does is Magic",
    "year": "1981",
    "seconds": "60",
    "lyric": "I resolve to call her up",
    "number": "1133",
  },
  {
    "artist": "Sugar Ray",
    "song": "Every Morning",
    "year": "1999",
    "seconds": "60",
    "lyric": "A halo hangin’ from the corner",
    "number": "1134",
  },
  {
    "artist": "Coldplay",
    "song": "Every Teardrop is a Waterfall",
    "year": "2011",
    "seconds": "60",
    "lyric": "I feel my heart start beating",
    "number": "1135",
  },
  {
    "artist": "David Lee Murphy",
    "song": "Every Time I Get Around You",
    "year": "1996",
    "seconds": "60",
    "lyric": "Stare into those big brown eyes",
    "number": "1136",
  },
  {
    "artist": "Dottie West & Kenny Rogers",
    "song": "Every Time Two Fools Collide",
    "year": "1978",
    "seconds": "40",
    "lyric": "So cut and dried",
    "number": "1137",
  },
  {
    "artist": "Cascada",
    "song": "Every Time We Touch",
    "year": "2006",
    "seconds": "50",
    "lyric": "Feel my heart beat fast",
    "number": "1138",
  },
  {
    "artist": "Charlie Rich",
    "song": "Every Time You Touch Me (I Get High)",
    "year": "1975",
    "seconds": "50",
    "lyric": "Lay right down and die",
    "number": "1139",
  },
  {
    "artist": "Air Supply",
    "song": "Every Woman in the World",
    "year": "1980",
    "seconds": "60",
    "lyric": "So put your hand in mine",
    "number": "1140",
  },
  {
    "artist": "Backstreet Boys",
    "song": "Everybody (Backstreet’s Back)",
    "year": "1997",
    "seconds": "70",
    "lyric": "Throw your hands up in the air",
    "number": "1141",
  },
  {
    "artist": "Dean Martin",
    "song": "Everybody Loves Somebody",
    "year": "1964",
    "seconds": "60",
    "lyric": "Every minute, every hour, every boy",
    "number": "1142",
  },
  {
    "artist": "Wilson Pickett",
    "song": "Everybody Needs Somebody to Love",
    "year": "1967",
    "seconds": "60",
    "lyric": "And I need you, you, you",
    "number": "1143",
  },
  {
    "artist": "The Main Ingredient",
    "song": "Everybody Plays the Fool",
    "year": "1972",
    "seconds": "60",
    "lyric": "Love runs deeper than any ocean",
    "number": "1144",
  },
  {
    "artist": "Kenny Chesney (ft. The Wailers)",
    "song": "Everybody Wants to Go to Heaven",
    "year": "2008",
    "seconds": "50",
    "lyric": "Quit the women and whiskey",
    "number": "1145",
  },
  {
    "artist": "Tears for Fears",
    "song": "Everybody Wants to Rule the World",
    "year": "1985",
    "seconds": "60",
    "lyric": "Turn your back on Mother Nature",
    "number": "1146",
  },
  {
    "artist": "Billy Squier",
    "song": "Everybody Wants You",
    "year": "1982",
    "seconds": "60",
    "lyric": "Fall for you at first sight",
    "number": "1147",
  },
  {
    "artist": "Santana",
    "song": "Everybody’s Everything",
    "year": "1971",
    "seconds": "50",
    "lyric": "Let your head be free",
    "number": "1148",
  },
  {
    "artist": "Harry Nilsson",
    "song": "Everybody’s Talkin’",
    "year": "1969",
    "seconds": "50",
    "lyric": "The shadows of their eyes",
    "number": "1149",
  },
  {
    "artist": "Buddy Holly",
    "song": "Everyday",
    "year": "1957",
    "seconds": "50",
    "lyric": "Faster than a roller coaster",
    "number": "1150",
  },
  {
    "artist": "The Oak Ridge Boys",
    "song": "Everyday",
    "year": "1984",
    "seconds": "60",
    "lyric": "On the tongue of the old",
    "number": "1151",
  },
  {
    "artist": "Sly and the Family Stone",
    "song": "Everyday People",
    "year": "1969",
    "seconds": "60",
    "lyric": "The butcher, the banker, the drummer",
    "number": "1152",
  },
  {
    "artist": "Chris Brown",
    "song": "Everything",
    "year": "2008",
    "seconds": "70",
    "lyric": "An angel to bring me your heart",
    "number": "1153",
  },
  {
    "artist": "Michael Bublé",
    "song": "Everything",
    "year": "2007",
    "seconds": "60",
    "lyric": "Swimming pool on an August day",
    "number": "1154",
  },
  {
    "artist": "Glen Campbell",
    "song": "Everything a Man Could Ever Need",
    "year": "1970",
    "seconds": "60",
    "lyric": "And my shoulder to the wheel",
    "number": "1155",
  },
  {
    "artist": "Taylor Swift & Ed Sheeran",
    "song": "Everything Has Changed",
    "year": "2013",
    "seconds": "40",
    "lyric": "Green eyes and freckles",
    "number": "1156",
  },
  {
    "artist": "Bryan Adams",
    "song": "(Everything I Do) I Do It For You",
    "year": "1991",
    "seconds": "60",
    "lyric": "Walk the wire for you, yeah",
    "number": "1157",
  },
  {
    "artist": "Bread",
    "song": "Everything I Own",
    "year": "1972",
    "seconds": "60",
    "lyric": "My life, my heart, my home",
    "number": "1158",
  },
  {
    "artist": "Billie Eilish",
    "song": "Everything I Wanted",
    "year": "2019",
    "seconds": "50",
    "lyric": "Go straight to my head",
    "number": "1159",
  },
  {
    "artist": "Corey Hart",
    "song": "Everything in My Heart",
    "year": "1985",
    "seconds": "70",
    "lyric": "I want to break through to you",
    "number": "1160",
  },
  {
    "artist": "Ray Stevens",
    "song": "Everything is Beautiful",
    "year": "1970",
    "seconds": "50",
    "lyric": "On a snow-covered winter’s day",
    "number": "1161",
  },
  {
    "artist": "Sky Ferreira",
    "song": "Everything is Embarrassing",
    "year": "2012",
    "seconds": "60",
    "lyric": "One step closer to the door",
    "number": "1162",
  },
  {
    "artist": "Dan Seals",
    "song": "Everything That Glitters (Is Not Gold)",
    "year": "1986",
    "seconds": "50",
    "lyric": "We never cross your mind",
    "number": "1163",
  },
  {
    "artist": "Lonestar",
    "song": "Everything’s Changed",
    "year": "1998",
    "seconds": "50",
    "lyric": "The café is closed",
    "number": "1164",
  },
  {
    "artist": "Britney Spears",
    "song": "Everytime",
    "year": "2004",
    "seconds": "70",
    "lyric": "I see your face, it’s haunting me",
    "number": "1165",
  },
  {
    "artist": "Fleetwood Mac",
    "song": "Everywhere",
    "year": "1987",
    "seconds": "50",
    "lyric": "I’ll speak a little louder",
    "number": "1166",
  },
  {
    "artist": "Michelle Branch",
    "song": "Everywhere",
    "year": "2001",
    "seconds": "40",
    "lyric": "The water’s getting deep",
    "number": "1167",
  },
  {
    "artist": "Tim McGraw",
    "song": "Everywhere",
    "year": "1997",
    "seconds": "40",
    "lyric": "Waitin’ out a blizzard",
    "number": "1168",
  },
  {
    "artist": "Santana",
    "song": "Evil Ways",
    "year": "1969",
    "seconds": "50",
    "lyric": "And my pots are cold",
    "number": "1169",
  },
  {
    "artist": "Electric Light Orchestra",
    "song": "Evil Woman",
    "year": "1975",
    "seconds": "50",
    "lyric": "A hole in my head",
    "number": "1170",
  },
  {
    "artist": "Ani DiFranco",
    "song": "Evolve",
    "year": "2003",
    "seconds": "50",
    "lyric": "Moth outside my kitchen door",
    "number": "1171",
  },
  {
    "artist": "Taylor Swift (ft. Bon Iver)",
    "song": "exile",
    "year": "2020",
    "seconds": "60",
    "lyric": "I can see you standin’, honey",
    "number": "1172",
  },
  {
    "artist": "Buck Owens",
    "song": "Excuse Me (I Think I’ve Got a Heartache)",
    "year": "1960",
    "seconds": "50",
    "lyric": "Say goodbye before I cry",
    "number": "1173",
  },
  {
    "artist": "The Soul Survivors",
    "song": "Expressway to Your Heart",
    "year": "1967",
    "seconds": "50",
    "lyric": "Shower you with love and affection",
    "number": "1174",
  },
  {
    "artist": "Survivor",
    "song": "Eye of the Tiger",
    "year": "1982",
    "seconds": "60",
    "lyric": "Rising up, back on the street",
    "number": "1175",
  },
  {
    "artist": "Taylor Swift",
    "song": "Eyes Open",
    "year": "2012",
    "seconds": "70",
    "lyric": "So here you are, two steps ahead",
    "number": "1176",
  },
  {
    "artist": "Bruce Springsteen",
    "song": "Fade Away",
    "year": "1981",
    "seconds": "60",
    "lyric": "You and I walked as two",
    "number": "1177",
  },
  {
    "artist": "Patsy Cline",
    "song": "Faded Love",
    "year": "1964",
    "seconds": "50",
    "lyric": "I look at the letters",
    "number": "1178",
  },
  {
    "artist": "George Michael",
    "song": "Faith",
    "year": "1987",
    "seconds": "60",
    "lyric": "Before I give my heart away",
    "number": "1179",
  },
  {
    "artist": "Journey",
    "song": "Faithfully",
    "year": "1983",
    "seconds": "50",
    "lyric": "Restless hearts sleep alone tonight",
    "number": "1180",
  },
  {
    "artist": "The National",
    "song": "Fake Empire",
    "year": "2007",
    "seconds": "40",
    "lyric": "Picking apples, making pies",
    "number": "1181",
  },
  {
    "artist": "BTS",
    "song": "Fake Love",
    "year": "2018",
    "seconds": "40",
    "lyric": "Love you so bad",
    "number": "1182",
  },
  {
    "artist": "Davido",
    "song": "Fall",
    "year": "2017",
    "seconds": "70",
    "lyric": "Banana fall on you, paparazzi follow you",
    "number": "1183",
  },
  {
    "artist": "Sarah McLachlan",
    "song": "Fallen",
    "year": "2003",
    "seconds": "60",
    "lyric": "Heaven bend to take my hand",
    "number": "1184",
  },
  {
    "artist": "Harry Styles",
    "song": "Falling",
    "year": "2020",
    "seconds": "40",
    "lyric": "I’m in my bed",
    "number": "1185",
  },
  {
    "artist": "Conway Twitty",
    "song": "Fallin’ For You For Years",
    "year": "1986",
    "seconds": "40",
    "lyric": "Runnin’ deep, runnin’ strong",
    "number": "1186",
  },
  {
    "artist": "Aerosmith",
    "song": "Falling I Love (Is Hard on the Knees)",
    "year": "1997",
    "seconds": "50",
    "lyric": "You’re bad to the bone",
    "number": "1187",
  },
  {
    "artist": "David Bowie",
    "song": "Fame",
    "year": "1975",
    "seconds": "60",
    "lyric": "You are too cool to fool",
    "number": "1188",
  },
  {
    "artist": "Mary J. Blige",
    "song": "Family Affair",
    "year": "2002",
    "seconds": "40",
    "lyric": "A great track pumpin’",
    "number": "1189",
  },
  {
    "artist": "Sly & the Family Stone",
    "song": "Family Affair",
    "year": "1972",
    "seconds": "60",
    "lyric": "Both kids are good to Mom",
    "number": "1190",
  },
  {
    "artist": "Hank Williams Jr.",
    "song": "Family Tradition",
    "year": "1979",
    "seconds": "50",
    "lyric": "Stop and think it over",
    "number": "1191",
  },
  {
    "artist": "George Strait",
    "song": "Famous Last Words of a Fool",
    "year": "1988",
    "seconds": "40",
    "lyric": "She drove away crying",
    "number": "1192",
  },
  {
    "artist": "Bobbie Gentry",
    "song": "Fancy",
    "year": "1969",
    "seconds": "50",
    "lyric": "Combed and curled my hair",
    "number": "1193",
  },
  {
    "artist": "The Bee Gees",
    "song": "Fanny (Be Tender With My Love)",
    "year": "1976",
    "seconds": "50",
    "lyric": "You’re out of your brain",
    "number": "1194",
  },
  {
    "artist": "Mariah Carey",
    "song": "Fantasy",
    "year": "1995",
    "seconds": "50",
    "lyric": "Talking sweet and looking fine",
    "number": "1195",
  },
  {
    "artist": "Candlebox",
    "song": "Far Behind",
    "year": "1994",
    "seconds": "40",
    "lyric": "Brush the leaves aside",
    "number": "1196",
  },
  {
    "artist": "Bing Crosby & The Ken Darby Choir",
    "song": "Far Away Places",
    "year": "1948",
    "seconds": "70",
    "lyric": "I hear the whistle of a train",
    "number": "1197",
  },
  {
    "artist": "Dwight Yoakam",
    "song": "Fast as You",
    "year": "1993",
    "seconds": "60",
    "lyric": "Your turn to hurt comes around",
    "number": "1198",
  },
  {
    "artist": "Tracy Chapman",
    "song": "Fast Car",
    "year": "1988",
    "seconds": "50",
    "lyric": "So we can fly away",
    "number": "1199",
  },
  {
    "artist": "Rascal Flatts",
    "song": "Fast Cars and Freedom",
    "year": "2005",
    "seconds": "50",
    "lyric": "Your head on my shoulder",
    "number": "1200",
  },
  {
    "artist": "Queen",
    "song": "Fat Bottomed Girls",
    "year": "1978",
    "seconds": "50",
    "lyric": "The rocking world go round",
    "number": "1201",
  },
  {
    "artist": "Cat Stevens",
    "song": "Father and Son",
    "year": "1970",
    "seconds": "60",
    "lyric": "Look at me, I am old",
    "number": "1202",
  },
  {
    "artist": "Taylor Swift",
    "song": "Fearless",
    "year": "2010",
    "seconds": "40",
    "lyric": "Drag me head first",
    "number": "1203",
  },
  {
    "artist": "Janet Jackson",
    "song": "Feedback",
    "year": "2007",
    "seconds": "50",
    "lyric": "Strum me like a guitar",
    "number": "1204",
  },
  {
    "artist": "Robbie Williams",
    "song": "Feel",
    "year": "2002",
    "seconds": "50",
    "lyric": "A hole in my soul",
    "number": "1205",
  },
  {
    "artist": "Gorillaz",
    "song": "Feel Good Inc",
    "year": "2005",
    "seconds": "60",
    "lyric": "Breaking down on a camel’s back",
    "number": "1206",
  },
  {
    "artist": "Bad Company",
    "song": "Feel Like Making Love",
    "year": "1975",
    "seconds": "50",
    "lyric": "Wrap you in the Heaven",
    "number": "1207",
  },
  {
    "artist": "Pitbull (ft. Christina Aguilera)",
    "song": "Feel This Moment",
    "year": "2013",
    "seconds": "60",
    "lyric": "But until the gates are open",
    "number": "1208",
  },
  {
    "artist": "Joe Cocker",
    "song": "Feelin’ Alright",
    "year": "1969",
    "seconds": "70",
    "lyric": "Locked the door and took the key",
    "number": "1209",
  },
  {
    "artist": "Chicago",
    "song": "Feelin’ Stronger Every Day",
    "year": "1973",
    "seconds": "50",
    "lyric": "To live on the run",
    "number": "1210",
  },
  {
    "artist": "Foreigner",
    "song": "Feels Like the First Time",
    "year": "1977",
    "seconds": "50",
    "lyric": "Sail across a stormy sea",
    "number": "1211",
  },
  {
    "artist": "Tame Impala",
    "song": "Feels Like We Only Go Backwards",
    "year": "2012",
    "seconds": "60",
    "lyric": "I hear it inside my head",
    "number": "1212",
  },
  {
    "artist": "Van Halen",
    "song": "Feels So Good",
    "year": "1989",
    "seconds": "60",
    "lyric": "I watch the tide roll in",
    "number": "1213",
  },
  {
    "artist": "Alabama",
    "song": "Feels So Right",
    "year": "1981",
    "seconds": "50",
    "lyric": "Press your lips to mine",
    "number": "1214",
  },
  {
    "artist": "José Feliciano",
    "song": "Feliz Navidad",
    "year": "1970",
    "seconds": "60",
    "lyric": "From the bottom of my heart",
    "number": "1215",
  },
  {
    "artist": "The White Stripes",
    "song": "Fell in Love With a Girl",
    "year": "2003",
    "seconds": "50",
    "lyric": "Kiss me by the riverside",
    "number": "1216",
  },
  {
    "artist": "Tim McGraw",
    "song": "Felt Good on My Lips",
    "year": "2010",
    "seconds": "60",
    "lyric": "She grabbed me by the sleeve",
    "number": "1217",
  },
  {
    "artist": "ABBA",
    "song": "Fernando",
    "year": "1975",
    "seconds": "50",
    "lyric": "Can you hear the drums",
    "number": "1218",
  },
  {
    "artist": "Gerry and the Pacemakers",
    "song": "Ferry Cross the Mersey",
    "year": "1965",
    "seconds": "50",
    "lyric": "We’ll never turn you away",
    "number": "1219",
  },
  {
    "artist": "Taylor Swift",
    "song": "Fifteen",
    "year": "2009",
    "seconds": "60",
    "lyric": "The boy on the football team",
    "number": "1220",
  },
  {
    "artist": "Conway Twitty",
    "song": "Fifteen Years Ago",
    "year": "1970",
    "seconds": "60",
    "lyric": "My mind raced back in time",
    "number": "1221",
  },
  {
    "artist": "Beastie Boys",
    "song": "Fight for Your Right",
    "year": "1987",
    "seconds": "70",
    "lyric": "I’ll kick you out of my home",
    "number": "1222",
  },
  {
    "artist": "Tracy Lawrence",
    "song": "Find Out Who Your Friends Are",
    "year": "2007",
    "seconds": "60",
    "lyric": "Lose the shirt off your back",
    "number": "1223",
  },
  {
    "artist": "Crosby, Stills, Nash & Young",
    "song": "Find the Cost of Freedom",
    "year": "1970",
    "seconds": "50",
    "lyric": "Mother Earth will swallow you",
    "number": "1224",
  },
  {
    "artist": "Van Halen",
    "song": "Finish What Ya Started",
    "year": "1988",
    "seconds": "50",
    "lyric": "Look at the long run",
    "number": "1225",
  },
  {
    "artist": "Jimi Hendrix",
    "song": "Fire",
    "year": "1967",
    "seconds": "50",
    "lyric": "Stand next to your fire",
    "number": "1226",
  },
  {
    "artist": "The Pointer Sisters",
    "song": "Fire",
    "year": "1979",
    "seconds": "50",
    "lyric": "When we kiss, ooooh fire",
    "number": "1227",
  },
  {
    "artist": "James Taylor",
    "song": "Fire and Rain",
    "year": "1970",
    "seconds": "60",
    "lyric": "My back turned towards the sun",
    "number": "1228",
  },
  {
    "artist": "Sean Kingston",
    "song": "Fire Burning",
    "year": "2009",
    "seconds": "50",
    "lyric": "Birthday cake got a candle",
    "number": "1229",
  },
  {
    "artist": "Owl City",
    "song": "Fireflies",
    "year": "2009",
    "seconds": "40",
    "lyric": "Ten thousand lightning bugs",
    "number": "1230",
  },
  {
    "artist": "Katy Perry",
    "song": "Firework",
    "year": "2016",
    "seconds": "50",
    "lyric": "You shoot across the sky",
    "number": "1231",
  },
  {
    "artist": "Nitty Gritty Dirt Band",
    "song": "Fishin’ in the Dark",
    "year": "1987",
    "seconds": "40",
    "lyric": "Lying on our backs",
    "number": "1232",
  },
  {
    "artist": "Loretta Lynn",
    "song": "Fist City",
    "year": "1968",
    "seconds": "60",
    "lyric": "I’ll grab you by the hair",
    "number": "1233",
  },
  {
    "artist": "Chris Janson",
    "song": "Fix a Drink",
    "year": "2017",
    "seconds": "60",
    "lyric": "The boss is on your back",
    "number": "1234",
  },
  {
    "artist": "Coldplay",
    "song": "Fix You",
    "year": "2005",
    "seconds": "50",
    "lyric": "Tears stream down your face",
    "number": "1235",
  },
  {
    "artist": "Johnny Cash",
    "song": "Flesh and Blood",
    "year": "1970",
    "seconds": "60",
    "lyric": "Against a sky of baby blue",
    "number": "1236",
  },
  {
    "artist": "Molly Hatchet",
    "song": "Flirtin’ with Disaster",
    "year": "1979",
    "seconds": "40",
    "lyric": "Gamble with our time",
    "number": "1237",
  },
  {
    "artist": "The Statler Brothers",
    "song": "Flowers on the Wall",
    "year": "1965",
    "seconds": "40",
    "lyric": "Playing solitaire ’til dawn",
    "number": "1238",
  },
  {
    "artist": "Rush",
    "song": "Fly by Night",
    "year": "1975",
    "seconds": "40",
    "lyric": "Moon rise, thoughtful eyes",
    "number": "1239",
  },
  {
    "artist": "The Steve Miller Band",
    "song": "Fly Like an Eagle",
    "year": "1976",
    "seconds": "40",
    "lyric": "Time keeps on slippin’",
    "number": "1240",
  },
  {
    "artist": "Frank Sinatra",
    "song": "Fly Me to the Moon",
    "year": "1961",
    "seconds": "50",
    "lyric": "Fill my heart with song",
    "number": "1241",
  },
  {
    "artist": "Jason Aldean",
    "song": "Fly Over States",
    "year": "2012",
    "seconds": "50",
    "lyric": "Flirting with the flight attendants",
    "number": "1242",
  },
  {
    "artist": "Silver Convention",
    "song": "Fly, Robin, Fly",
    "year": "1975",
    "seconds": "50",
    "lyric": "Up, up to the sky",
    "number": "1243",
  },
  {
    "artist": "Ozzy Osbourne",
    "song": "Flying High Again",
    "year": "1981",
    "seconds": "50",
    "lyric": "I can see through mountains",
    "number": "1244",
  },
  {
    "artist": "Ariana Grande",
    "song": "Focus",
    "year": "2015",
    "seconds": "40",
    "lyric": "Written on your lips",
    "number": "1245",
  },
  {
    "artist": "Kacey Musgraves",
    "song": "Follow Your Arrow",
    "year": "2014",
    "seconds": "50",
    "lyric": "You can’t lose the weight",
    "number": "1246",
  },
  {
    "artist": "Johnny Cash",
    "song": "Folsom Prison Blues",
    "year": "1968",
    "seconds": "60",
    "lyric": "Drinkin’ coffee and smoking big cigars",
    "number": "1247",
  },
  {
    "artist": "Foghat",
    "song": "Fool for the City",
    "year": "1975",
    "seconds": "60",
    "lyric": "I’ll be walkin’ on a cloud",
    "number": "1248",
  },
  {
    "artist": "George Strait",
    "song": "Fool Hearted Memory",
    "year": "1982",
    "seconds": "60",
    "lyric": "Nickels and dimes, memories and wines",
    "number": "1249",
  },
  {
    "artist": "Led Zeppelin",
    "song": "Fool in the Rain",
    "year": "1979",
    "seconds": "60",
    "lyric": "There’s a light in your eye",
    "number": "1250",
  },
  {
    "artist": "Elvin Bishop",
    "song": "Fooled Around and Fell in Love",
    "year": "1975",
    "seconds": "50",
    "lyric": "Through about a million girls",
    "number": "1251",
  },
  {
    "artist": "Buck Owens",
    "song": "Foolin’ Around",
    "year": "1961",
    "seconds": "60",
    "lyric": "So I’ll take back my ring",
    "number": "1252",
  },
  {
    "artist": "Styx",
    "song": "Fooling Yourself (The Angry Young Man)",
    "year": "1978",
    "seconds": "50",
    "lyric": "Your hand’s wet with sweat",
    "number": "1253",
  },
  {
    "artist": "Steve Perry",
    "song": "Foolish Heart",
    "year": "1984",
    "seconds": "50",
    "lyric": "Love’s knockin’ on the door",
    "number": "1254",
  },
  {
    "artist": "Travis Tritt",
    "song": "Foolish Pride",
    "year": "1994",
    "seconds": "50",
    "lyric": "And cried into her pillow",
    "number": "1255",
  },
  {
    "artist": "Ricky Nelson",
    "song": "Fools Rush In",
    "year": "1963",
    "seconds": "50",
    "lyric": "My heart above my head",
    "number": "1256",
  },
  {
    "artist": "Kenny Loggins",
    "song": "Footloose",
    "year": "1984",
    "seconds": "50",
    "lyric": "Kick off your Sunday shoes",
    "number": "1257",
  },
  {
    "artist": "Bill Munroe",
    "song": "Footprints in the Snow",
    "year": "1952",
    "seconds": "50",
    "lyric": "She’s with the angel band",
    "number": "1258",
  },
  {
    "artist": "Tim McGraw",
    "song": "For a Little While",
    "year": "1998",
    "seconds": "70",
    "lyric": "Long on love but short on time",
    "number": "1259",
  },
  {
    "artist": "The Bellamy Brothers",
    "song": "For All the Wrong Reasons",
    "year": "1982",
    "seconds": "70",
    "lyric": "So we throw it to the wind",
    "number": "1260",
  },
  {
    "artist": "The Carpenters",
    "song": "For All We Know",
    "year": "1971",
    "seconds": "70",
    "lyric": "Love, look at the two of us",
    "number": "1261",
  },
  {
    "artist": "Amy Holland & Donny Gerrard",
    "song": "For Just a Moment",
    "year": "1985",
    "seconds": "60",
    "lyric": "I can see the banners fly",
    "number": "1262",
  },
  {
    "artist": "Stevie Wonder",
    "song": "For Once in My Life",
    "year": "1968",
    "seconds": "40",
    "lyric": "Long before I knew",
    "number": "1263",
  },
  {
    "artist": "Ray Price",
    "song": "For the Good Times",
    "year": "1970",
    "seconds": "60",
    "lyric": "Lay your head upon my pillow",
    "number": "1264",
  },
  {
    "artist": "The O’Jays",
    "song": "For the Love of Money",
    "year": "1974",
    "seconds": "50",
    "lyric": "A small piece of paper",
    "number": "1265",
  },
  {
    "artist": "Sheena Easton",
    "song": "For Your Eyes Only",
    "year": "1981",
    "seconds": "50",
    "lyric": "Maybe I’m an open book",
    "number": "1266",
  },
  {
    "artist": "Luke Combs",
    "song": "Forever After All",
    "year": "2021",
    "seconds": "50",
    "lyric": "The strings on this guitar",
    "number": "1267",
  },
  {
    "artist": "Russ Morgan, His Orchestra with The Skylarks",
    "song": "Forever and Ever",
    "year": "1949",
    "seconds": "50",
    "lyric": "Seal it with a kiss",
    "number": "1268",
  },
  {
    "artist": "Randy Travis",
    "song": "Forever and Ever, Amen",
    "year": "1987",
    "seconds": "60",
    "lyric": "As long as old men sit",
    "number": "1269",
  },
  {
    "artist": "Neil Diamond",
    "song": "Forever in Blue Jeans",
    "year": "1979",
    "seconds": "80",
    "lyric": "Money talks, but it can’t sing and dance",
    "number": "1270",
  },
  {
    "artist": "Eric Clapton",
    "song": "Forever Man",
    "year": "1985",
    "seconds": "50",
    "lyric": "Bridges I’ve got to cross",
    "number": "1271",
  },
  {
    "artist": "Bob Dylan",
    "song": "Forever Young",
    "year": "1966",
    "seconds": "60",
    "lyric": "Build a ladder to the stars",
    "number": "1272",
  },
  {
    "artist": "Willie Nelson",
    "song": "Forgiving You Was Easy",
    "year": "1985",
    "seconds": "50",
    "lyric": "The bitter fruit of anger",
    "number": "1273",
  },
  {
    "artist": "Beyoncé",
    "song": "Formation",
    "year": "2016",
    "seconds": "50",
    "lyric": "Hot sauce in my bag",
    "number": "1274",
  },
  {
    "artist": "Creedence Clearwater Revival",
    "song": "Fortunate Son",
    "year": "1969",
    "seconds": "50",
    "lyric": "Born, silver spoon in hand",
    "number": "1275",
  },
  {
    "artist": "Bobby Curtola",
    "song": "Fortuneteller",
    "year": "1962",
    "seconds": "70",
    "lyric": "See it all in your crystal ball",
    "number": "1276",
  },
  {
    "artist": "Ian Tyson",
    "song": "Four Strong Winds",
    "year": "1963",
    "seconds": "50",
    "lyric": "Seven seas that run high",
    "number": "1277",
  },
  {
    "artist": "Jim Reeves",
    "song": "Four Walls",
    "year": "1957",
    "seconds": "60",
    "lyric": "I sit and whisper your name",
    "number": "1278",
  },
  {
    "artist": "Rihanna (ft. Kanye West & Paul McCartney)",
    "song": "FourFiveSeconds",
    "year": "2015",
    "seconds": "50",
    "lyric": "Three more days ’til Friday",
    "number": "1279",
  },
  {
    "artist": "Sweet",
    "song": "Fox on the Run",
    "year": "1975",
    "seconds": "70",
    "lyric": "You think you got a pretty face",
    "number": "1280",
  },
  {
    "artist": "Jimi Hendrix",
    "song": "Foxy Lady",
    "year": "1967",
    "seconds": "50",
    "lyric": "A cute little heart breaker",
    "number": "1281",
  },
  {
    "artist": "Travis Scott (ft. Young Thug and M.I.A.)",
    "song": "Franchise",
    "year": "2020",
    "seconds": "50",
    "lyric": "I’m higher than the plane",
    "number": "1282",
  },
  {
    "artist": "Jimmie Rodgers",
    "song": "Frankie & Johnny",
    "year": "1929",
    "seconds": "70",
    "lyric": "And he’s doin’ you wrong",
    "number": "1283",
  },
  {
    "artist": "Zac Brown Band",
    "song": "Free",
    "year": "2010",
    "seconds": "50",
    "lyric": "Lay underneath the harvest moon",
    "number": "1284",
  },
  {
    "artist": "Tom Petty & the Heartbreakers",
    "song": "Free Fallin’",
    "year": "1989",
    "seconds": "60",
    "lyric": "Loves horses and her boyfriend too",
    "number": "1285",
  },
  {
    "artist": "Tom Petty & the Heartbreakers",
    "song": "Free Girl Now",
    "year": "1999",
    "seconds": "50",
    "lyric": "Honey, put your sugar down",
    "number": "1286",
  },
  {
    "artist": "Joni Mitchell",
    "song": "Free Man in Paris",
    "year": "1974",
    "seconds": "70",
    "lyric": "I deal in dreamers and telephone screamers",
    "number": "1287",
  },
  {
    "artist": "The Edgar Winter Group",
    "song": "Free Ride",
    "year": "1972",
    "seconds": "80",
    "lyric": "The mountain is high, the valley is low",
    "number": "1288",
  },
  {
    "artist": "Lynyrd Skynyrd",
    "song": "Freebird",
    "year": "1974",
    "seconds": "60",
    "lyric": "I stayed here with you girl",
    "number": "1289",
  },
  {
    "artist": "Ted Nugent",
    "song": "Free-For-All",
    "year": "1977",
    "seconds": "80",
    "lyric": "The stakes are high and so am I",
    "number": "1290",
  },
  {
    "artist": "Rush",
    "song": "Freewill",
    "year": "1981",
    "seconds": "50",
    "lyric": "We dance on the strings",
    "number": "1291",
  },
  {
    "artist": "J. Geils Band",
    "song": "Freeze-Frame",
    "year": "1982",
    "seconds": "60",
    "lyric": "I’m lookin’ at a flashback Sunday",
    "number": "1292",
  },
  {
    "artist": "Kool & the Gang",
    "song": "Fresh",
    "year": "1984",
    "seconds": "50",
    "lyric": "She’s so exciting to me",
    "number": "1293",
  },
  {
    "artist": "John Michael Montgomery",
    "song": "Friends",
    "year": "1996",
    "seconds": "50",
    "lyric": "A dagger to the heart",
    "number": "1294",
  },
  {
    "artist": "Marshmello & Anne-Marie",
    "song": "Friends",
    "year": "2018",
    "seconds": "50",
    "lyric": "Turning up at my door",
    "number": "1295",
  },
  {
    "artist": "Garth Brooks",
    "song": "Friends in Low Places",
    "year": "1990",
    "seconds": "50",
    "lyric": "I showed up in boots",
    "number": "1296",
  },
  {
    "artist": "Bette Midler",
    "song": "From a Distance",
    "year": "1990",
    "seconds": "40",
    "lyric": "God is watching us",
    "number": "1297",
  },
  {
    "artist": "Ned Miller",
    "song": "From a Jack to a King",
    "year": "1962",
    "seconds": "70",
    "lyric": "I saw the twinkle in your eye",
    "number": "1298",
  },
  {
    "artist": "The Beatles",
    "song": "From Me to You",
    "year": "1964",
    "seconds": "60",
    "lyric": "Arms that long to hold you",
    "number": "1299",
  },
  {
    "artist": "Dan + Shay",
    "song": "From the Ground Up",
    "year": "2016",
    "seconds": "40",
    "lyric": "Grandpa painted a picture",
    "number": "1300",
  },
  {
    "artist": "Shania Twain",
    "song": "From This Moment On",
    "year": "1998",
    "seconds": "50",
    "lyric": "I’d give my last breath",
    "number": "1301",
  },
  {
    "artist": "Pharrell (feat. Jay-Z)",
    "song": "Frontin’",
    "year": "2003",
    "seconds": "60",
    "lyric": "We locked eyes and I knew",
    "number": "1302",
  },
  {
    "artist": "Jimmy Durante",
    "song": "Frosty the Snowman",
    "year": "1950",
    "seconds": "70",
    "lyric": "A corncob pipe and a button nose",
    "number": "1303",
  },
  {
    "artist": "Van Morrison",
    "song": "Full Force Gale",
    "year": "1979",
    "seconds": "70",
    "lyric": "I saw the writing on the wall",
    "number": "1304",
  },
  {
    "artist": "Beach Boys",
    "song": "Fun, Fun, Fun",
    "year": "1964",
    "seconds": "60",
    "lyric": "She cruised through the hamburger stand",
    "number": "1305",
  },
  {
    "artist": "James Gang",
    "song": "Funk #49",
    "year": "1970",
    "seconds": "60",
    "lyric": "Out all night, sleep all day",
    "number": "1306",
  },
  {
    "artist": "Wilson Pickett",
    "song": "Funky Broadway",
    "year": "1967",
    "seconds": "50",
    "lyric": "Wiggle your legs now, baby",
    "number": "1307",
  },
  {
    "artist": "Ohio Players",
    "song": "Funky Worm",
    "year": "1973",
    "seconds": "50",
    "lyric": "Nine cans of shaving powder",
    "number": "1308",
  },
  {
    "artist": "Donna Fargo",
    "song": "Funny Face",
    "year": "1972",
    "seconds": "50",
    "lyric": "You kiss the tears away",
    "number": "1309",
  },
  {
    "artist": "Glen Campbell",
    "song": "Galveston",
    "year": "1969",
    "seconds": "70",
    "lyric": "I see her standing by the water",
    "number": "1310",
  },
  {
    "artist": "Wayne Fontana & the Mindbenders",
    "song": "Game of Love",
    "year": "1965",
    "seconds": "50",
    "lyric": "‘Cause the time is right",
    "number": "1311",
  },
  {
    "artist": "Joe South",
    "song": "Games People Play",
    "year": "1969",
    "seconds": "40",
    "lyric": "Covered up with flowers",
    "number": "1312",
  },
  {
    "artist": "Peter Gabriel",
    "song": "Games Without Frontiers",
    "year": "1980",
    "seconds": "50",
    "lyric": "Kissing baboons in the jungle",
    "number": "1313",
  },
  {
    "artist": "Coolio (feat. L.V.)",
    "song": "Gangsta’s Paradise",
    "year": "1995",
    "seconds": "60",
    "lyric": "Valley of the shadow of death",
    "number": "1314",
  },
  {
    "artist": "Ricky Nelson",
    "song": "Garden Party",
    "year": "1972",
    "seconds": "40",
    "lyric": "Yoko brought her walrus",
    "number": "1315",
  },
  {
    "artist": "David Mallett",
    "song": "Garden Song (Inch by Inch)",
    "year": "1975",
    "seconds": "50",
    "lyric": "A rake and a hoe",
    "number": "1316",
  },
  {
    "artist": "Christina Aguilera",
    "song": "Genie in a Bottle",
    "year": "1999",
    "seconds": "50",
    "lyric": "Rub me the right way",
    "number": "1317",
  },
  {
    "artist": "Glen Campbell",
    "song": "Gentle on My Mind",
    "year": "1967",
    "seconds": "50",
    "lyric": "A dirty hat pulled low",
    "number": "1318",
  },
  {
    "artist": "Ray Charles",
    "song": "Georgia on My Mind",
    "year": "1960",
    "seconds": "40",
    "lyric": "Moonlight through the pines",
    "number": "1319",
  },
  {
    "artist": "The Seekers",
    "song": "Georgy Girl",
    "year": "1966",
    "seconds": "50",
    "lyric": "Jumping down from the shelf",
    "number": "1320",
  },
  {
    "artist": "George Thorogood and the Destroyers",
    "song": "Get a Haircut",
    "year": "1992",
    "seconds": "40",
    "lyric": "Grew my hair long",
    "number": "1321",
  },
  {
    "artist": "Kenny Chesney",
    "song": "Get Along",
    "year": "2018",
    "seconds": "60",
    "lyric": "Call your mom, buy a boat",
    "number": "1322",
  },
  {
    "artist": "The Beatles",
    "song": "Get Back",
    "year": "1970",
    "seconds": "40",
    "lyric": "Jojo was a man",
    "number": "1323",
  },
  {
    "artist": "Seals and Crofts",
    "song": "Get Closer",
    "year": "1976",
    "seconds": "50",
    "lyric": "It’s locked in my mind",
    "number": "1324",
  },
  {
    "artist": "Gilbert O’Sullivan",
    "song": "Get Down",
    "year": "1973",
    "seconds": "70",
    "lyric": "A cat on a hot tin roof",
    "number": "1325",
  },
  {
    "artist": "Kool & the Gang",
    "song": "Get Down On It",
    "year": "1981",
    "seconds": "50",
    "lyric": "By standing on the wall?",
    "number": "1326",
  },
  {
    "artist": "KC & the Sunshine Band",
    "song": "Get Down Tonight",
    "year": "1975",
    "seconds": "50",
    "lyric": "Honey, honey, me and you",
    "number": "1327",
  },
  {
    "artist": "Toby Keith",
    "song": "Get Drunk and Be Somebody",
    "year": "2005",
    "seconds": "60",
    "lyric": "The bottom of his totem pole",
    "number": "1328",
  },
  {
    "artist": "T. Rex",
    "song": "Get It On",
    "year": "1971",
    "seconds": "50",
    "lyric": "A hubcap diamond star halo",
    "number": "1329",
  },
  {
    "artist": "Daft Punk",
    "song": "Get Lucky",
    "year": "2013",
    "seconds": "60",
    "lyric": "Up all night to the sun",
    "number": "1330",
  },
  {
    "artist": "Rolling Stones",
    "song": "Get Off of My Cloud",
    "year": "1965",
    "seconds": "60",
    "lyric": "Drive me out of my head",
    "number": "1331",
  },
  {
    "artist": "U2",
    "song": "Get On Your Boots",
    "year": "2009",
    "seconds": "40",
    "lyric": "Candy bars, ice cream",
    "number": "1332",
  },
  {
    "artist": "Billy Ocean",
    "song": "Get Outta My Dreams, Get into My Car",
    "year": "1988",
    "seconds": "60",
    "lyric": "Hey Cinderella, step in your shoe",
    "number": "1333",
  },
  {
    "artist": "Kylie Minogue",
    "song": "Get Outta My Way",
    "year": "2010",
    "seconds": "50",
    "lyric": "The zombie you’ve turned into",
    "number": "1334",
  },
  {
    "artist": "Eagles",
    "song": "Get Over It",
    "year": "1994",
    "seconds": "50",
    "lyric": "I turn on the tube",
    "number": "1335",
  },
  {
    "artist": "The Temptations",
    "song": "Get Ready",
    "year": "1966",
    "seconds": "50",
    "lyric": "I’m bringing you a love",
    "number": "1336",
  },
  {
    "artist": "Pink",
    "song": "Get the Party Started",
    "year": "2001",
    "seconds": "40",
    "lyric": "My gold diamond rings",
    "number": "1337",
  },
  {
    "artist": "The Youngbloods",
    "song": "Get Together",
    "year": "1969",
    "seconds": "60",
    "lyric": "The bird is on the wing",
    "number": "1338",
  },
  {
    "artist": "James Brown",
    "song": "Get Up (I Feel Like Being a) Sex Machine",
    "year": "1970",
    "seconds": "40",
    "lyric": "Shake your money maker",
    "number": "1339",
  },
  {
    "artist": "James Brown",
    "song": "Get Up Offa That Thing",
    "year": "1970",
    "seconds": "60",
    "lyric": "And dance ‘til you feel better",
    "number": "1340",
  },
  {
    "artist": "Earth, Wind and Fire",
    "song": "Getaway",
    "year": "1976",
    "seconds": "70",
    "lyric": "So come, take me by the hand",
    "number": "1341",
  },
  {
    "artist": "Will Smith",
    "song": "Gettin’ Jiggy Wit It",
    "year": "1997",
    "seconds": "60",
    "lyric": "Watch your step, you might fall",
    "number": "1342",
  },
  {
    "artist": "Chris Young",
    "song": "Gettin’ You Home (The Black Dress Song)",
    "year": "2009",
    "seconds": "40",
    "lyric": "Tuxedo waiters, black ties",
    "number": "1343",
  },
  {
    "artist": "Johnny Cash",
    "song": "(Ghost) Riders in the Sky",
    "year": "1979",
    "seconds": "50",
    "lyric": "Shirts all soaked with sweat",
    "number": "1344",
  },
  {
    "artist": "ZZ Top",
    "song": "Gimme All Your Lovin’",
    "year": "1983",
    "seconds": "60",
    "lyric": "All your hugs and kisses too",
    "number": "1345",
  },
  {
    "artist": "The Pipkins",
    "song": "Gimme Dat Ding",
    "year": "1970",
    "seconds": "50",
    "lyric": "A metronome without a bell",
    "number": "1346",
  },
  {
    "artist": "Crazy Elephant",
    "song": "Gimme Gimme Good Lovin’",
    "year": "1969",
    "seconds": "50",
    "lyric": "To the girls in Frisco",
    "number": "1347",
  },
  {
    "artist": "Britney Spears",
    "song": "Gimme More",
    "year": "2007",
    "seconds": "40",
    "lyric": "Turn the lights down",
    "number": "1348",
  },
  {
    "artist": "Rolling Stones",
    "song": "Gimme Shelter",
    "year": "1969",
    "seconds": "50",
    "lyric": "Mad bull lost your way",
    "number": "1349",
  },
  {
    "artist": "The Spencer Davis Group",
    "song": "Gimme Some Lovin’",
    "year": "1966",
    "seconds": "60",
    "lyric": "My feet are on the floor",
    "number": "1350",
  },
  {
    "artist": "Metric",
    "song": "Gimme Sympathy",
    "year": "2009",
    "seconds": "70",
    "lyric": "Got no time to take a picture",
    "number": "1351",
  },
  {
    "artist": "Lynyrd Skynyrd",
    "song": "Gimme Three Steps",
    "year": "1973",
    "seconds": "70",
    "lyric": "Shakin’ like a leaf on a tree",
    "number": "1352",
  },
  {
    "artist": "Maren Morris",
    "song": "Girl",
    "year": "2019",
    "seconds": "40",
    "lyric": "Don’t lose your halo",
    "number": "1353",
  },
  {
    "artist": "Little Big Town",
    "song": "Girl Crush",
    "year": "2015",
    "seconds": "60",
    "lyric": "I want her long blonde hair",
    "number": "1354",
  },
  {
    "artist": "Jason Aldean",
    "song": "Girl Like You",
    "year": "2018",
    "seconds": "40",
    "lyric": "Double-barrel bourbon on rocks",
    "number": "1355",
  },
  {
    "artist": "Alicia Keys",
    "song": "Girl on Fire",
    "year": "2012",
    "seconds": "50",
    "lyric": "She can burn your eyes",
    "number": "1356",
  },
  {
    "artist": "The O’Kaysions",
    "song": "Girl Watcher",
    "year": "1968",
    "seconds": "40",
    "lyric": "Here comes one now",
    "number": "1357",
  },
  {
    "artist": "Neil Diamond",
    "song": "Girl, You’ll Be a Woman Soon",
    "year": "1967",
    "seconds": "50",
    "lyric": "Please, come take my hand",
    "number": "1358",
  },
  {
    "artist": "NSYNC (ft. Nelly)",
    "song": "Girlfriend",
    "year": "2002",
    "seconds": "50",
    "lyric": "Or will he run and hide?",
    "number": "1359",
  },
  {
    "artist": "Motley Crue",
    "song": "Girls, Girls, Girls",
    "year": "1987",
    "seconds": "60",
    "lyric": "Handful of grease in my hair",
    "number": "1360",
  },
  {
    "artist": "AC/DC",
    "song": "Girls Got Rhythm",
    "year": "1979",
    "seconds": "50",
    "lyric": "Knocks me off my feet",
    "number": "1361",
  },
  {
    "artist": "Cyndi Lauper",
    "song": "Girls Just Want To Have Fun",
    "year": "1984",
    "seconds": "70",
    "lyric": "The one to walk in the sun",
    "number": "1362",
  },
  {
    "artist": "Maroon 5 (feat. Cardi B)",
    "song": "Girls Like You",
    "year": "2017",
    "seconds": "50",
    "lyric": "I know that I’m drunk",
    "number": "1363",
  },
  {
    "artist": "Supertramp",
    "song": "Give a Little Bit",
    "year": "1977",
    "seconds": "70",
    "lyric": "See the man with the lonely eyes",
    "number": "1364",
  },
  {
    "artist": "George Strait",
    "song": "Give It All We Got Tonight",
    "year": "2012",
    "seconds": "50",
    "lyric": "Baby, fall into my kiss",
    "number": "1365",
  },
  {
    "artist": "George Strait",
    "song": "Give It Away",
    "year": "2006",
    "seconds": "50",
    "lyric": "Front door swung wide open",
    "number": "1366",
  },
  {
    "artist": "Red Hot Chili Peppers",
    "song": "Give It Away",
    "year": "1991",
    "seconds": "50",
    "lyric": "You drink a little water",
    "number": "1367",
  },
  {
    "artist": "ZZ Top",
    "song": "Give It Up",
    "year": "1991",
    "seconds": "60",
    "lyric": "And I won by a nose",
    "number": "1368",
  },
  {
    "artist": "Madonna (ft. Nicki Minaj and M.I.A.)",
    "song": "Give Me All Your Luvin’",
    "year": "2012",
    "seconds": "40",
    "lyric": "Step into my world",
    "number": "1369",
  },
  {
    "artist": "Eric Church",
    "song": "Give Me Back My Hometown",
    "year": "2014",
    "seconds": "50",
    "lyric": "Every picture, every broken dream",
    "number": "1370",
  },
  {
    "artist": "Pitbull (ft. Ne-Yo, Afrojack and Nayer)",
    "song": "Give Me Everything",
    "year": "2011",
    "seconds": "60",
    "lyric": "I can make you my queen",
    "number": "1371",
  },
  {
    "artist": "Chairmen of the Board",
    "song": "Give Me Just a Little More Time",
    "year": "1970",
    "seconds": "50",
    "lyric": "Our love will surely grow",
    "number": "1372",
  },
  {
    "artist": "George Harrison",
    "song": "Give Me Love (Give Me Peace on Earth)",
    "year": "1973",
    "seconds": "60",
    "lyric": "Please take hold of my hand",
    "number": "1373",
  },
  {
    "artist": "Exile",
    "song": "Give Me One More Chance",
    "year": "1984",
    "seconds": "50",
    "lyric": "Slip right through my hands",
    "number": "1374",
  },
  {
    "artist": "Tracy Chapman",
    "song": "Give Me One Reason",
    "year": "1999",
    "seconds": "60",
    "lyric": "And I’ll turn right back around",
    "number": "1375",
  },
  {
    "artist": "The Dave Clark Five",
    "song": "Glad All Over",
    "year": "1963",
    "seconds": "70",
    "lyric": "It’s by your side I will stay",
    "number": "1376",
  },
  {
    "artist": "Laura Branigan",
    "song": "Gloria",
    "year": "1982",
    "seconds": "60",
    "lyric": "The voices in your head calling",
    "number": "1377",
  },
  {
    "artist": "Van Morrison",
    "song": "Gloria",
    "year": "1974",
    "seconds": "50",
    "lyric": "You know she comes around",
    "number": "1378",
  },
  {
    "artist": "Bruce Springsteen",
    "song": "Glory Days",
    "year": "1985",
    "seconds": "80",
    "lyric": "In the wink of a young girl’s eye",
    "number": "1379",
  },
  {
    "artist": "Peter Cetera",
    "song": "Glory of Love",
    "year": "1986",
    "seconds": "50",
    "lyric": "The hero you’re dreaming of",
    "number": "1380",
  },
  {
    "artist": "The Raspberries",
    "song": "Go All the Way",
    "year": "1972",
    "seconds": "60",
    "lyric": "She kissed me and said, baby",
    "number": "1381",
  },
  {
    "artist": "Steve Lawrence",
    "song": "Go Away Little Girl",
    "year": "1963",
    "seconds": "60",
    "lyric": "But our lips must never meet",
    "number": "1382",
  },
  {
    "artist": "American Authors",
    "song": "Go Big or Go Home",
    "year": "2015",
    "seconds": "60",
    "lyric": "I’m passed out on the floor",
    "number": "1383",
  },
  {
    "artist": "George Strait",
    "song": "Go On",
    "year": "2000",
    "seconds": "60",
    "lyric": "We can talk a little more",
    "number": "1384",
  },
  {
    "artist": "The 5th Dimension",
    "song": "Go Where You Wanna Go",
    "year": "1966",
    "seconds": "50",
    "lyric": "The girl you left behind",
    "number": "1385",
  },
  {
    "artist": "Fleetwood Mac",
    "song": "Go Your Own Way",
    "year": "1977",
    "seconds": "60",
    "lyric": "Baby, I’d give you my world",
    "number": "1386",
  },
  {
    "artist": "Billie Holiday",
    "song": "God Bless the Child",
    "year": "1972",
    "seconds": "40",
    "lyric": "Crowding around your door",
    "number": "1387",
  },
  {
    "artist": "Blake Shelton",
    "song": "God Gave Me You",
    "year": "2011",
    "seconds": "50",
    "lyric": "I’ve been a walking heartache",
    "number": "1388",
  },
  {
    "artist": "Ariana Grande",
    "song": "God is a Woman",
    "year": "2018",
    "seconds": "50",
    "lyric": "So baby, take my hand",
    "number": "1389",
  },
  {
    "artist": "Toby Keith",
    "song": "God Love Her",
    "year": "2008",
    "seconds": "60",
    "lyric": "The back seat of my motorcycle",
    "number": "1390",
  },
  {
    "artist": "Blake Shelton",
    "song": "God’s Country",
    "year": "2019",
    "seconds": "40",
    "lyric": "We pray for rain",
    "number": "1391",
  },
  {
    "artist": "The Miracles",
    "song": "Going to a Go-Go",
    "year": "1965",
    "seconds": "60",
    "lyric": "And if you drop in there",
    "number": "1392",
  },
  {
    "artist": "Led Zeppelin",
    "song": "Going to California",
    "year": "1971",
    "seconds": "50",
    "lyric": "And flowers in her hair",
    "number": "1393",
  },
  {
    "artist": "Kanye West (feat. Jamie Foxx)",
    "song": "Gold Digger",
    "year": "2005",
    "seconds": "40",
    "lyric": "At a beauty salon",
    "number": "1394",
  },
  {
    "artist": "Fleetwood Mac",
    "song": "Gold Dust Woman",
    "year": "1977",
    "seconds": "50",
    "lyric": "Shatter your illusions of love",
    "number": "1395",
  },
  {
    "artist": "Harry Styles",
    "song": "Golden",
    "year": "2020",
    "seconds": "60",
    "lyric": "And I know that you’re scared",
    "number": "1396",
  },
  {
    "artist": "Tammy Wynette",
    "song": "Golden Ring",
    "year": "1976",
    "seconds": "50",
    "lyric": "Tears roll down her cheeks",
    "number": "1397",
  },
  {
    "artist": "David Bowie",
    "song": "Golden Years",
    "year": "1975",
    "seconds": "70",
    "lyric": "In the back of a dream car",
    "number": "1398",
  },
  {
    "artist": "Shirley Bassey",
    "song": "Goldfinger",
    "year": "1964",
    "seconds": "60",
    "lyric": "The man with the Midas touch",
    "number": "1399",
  },
  {
    "artist": "Ferlin Husky",
    "song": "Gone",
    "year": "1957",
    "seconds": "60",
    "lyric": "I acted smart, broke your heart",
    "number": "1400",
  },
  {
    "artist": "George Strait",
    "song": "Gone As a Girl Can Get",
    "year": "1992",
    "seconds": "50",
    "lyric": "Show up in my dreams",
    "number": "1401",
  },
  {
    "artist": "Alan Jackson",
    "song": "Gone Country",
    "year": "1994",
    "seconds": "50",
    "lyric": "She looks in the mirror",
    "number": "1402",
  },
  {
    "artist": "Robert Plant & Alison Krauss",
    "song": "Gone, Gone, Gone",
    "year": "2007",
    "seconds": "60",
    "lyric": "You walk down the street, baby",
    "number": "1403",
  },
  {
    "artist": "Gene Autry",
    "song": "Gonna Build a Big Fence Around Texas",
    "year": "1945",
    "seconds": "50",
    "lyric": "Perky sunshine in her smile",
    "number": "1404",
  },
  {
    "artist": "DeEtta West & Nelson Pigford",
    "song": "Gonna Fly Now",
    "year": "1977",
    "seconds": "40",
    "lyric": "Won’t be long now",
    "number": "1405",
  },
  {
    "artist": "The Oak Ridge Boys",
    "song": "Gonna Take a Lot of River",
    "year": "1988",
    "seconds": "60",
    "lyric": "A tug boat pushing and pulling",
    "number": "1406",
  },
  {
    "artist": "Lizzo",
    "song": "Good as Hell",
    "year": "2016",
    "seconds": "50",
    "lyric": "I do my hair toss",
    "number": "1407",
  },
  {
    "artist": "The Beatles",
    "song": "Good Day Sunshine",
    "year": "1966",
    "seconds": "50",
    "lyric": "Lie beneath the shady tree",
    "number": "1408",
  },
  {
    "artist": "Billy Currington",
    "song": "Good Directions",
    "year": "2007",
    "seconds": "40",
    "lyric": "A little country store",
    "number": "1409",
  },
  {
    "artist": "Carrie Underwood",
    "song": "Good Girl",
    "year": "2012",
    "seconds": "50",
    "lyric": "His lips are dripping honey",
    "number": "1410",
  },
  {
    "artist": "Little Richard",
    "song": "Good Golly Miss Molly",
    "year": "1958",
    "seconds": "50",
    "lyric": "From the early early mornin’",
    "number": "1411",
  },
  {
    "artist": "Waylon Jennings",
    "song": "Good Hearted Woman",
    "year": "1971",
    "seconds": "50",
    "lyric": "She loves him in spite",
    "number": "1412",
  },
  {
    "artist": "The Young Rascals",
    "song": "Good Lovin’",
    "year": "1966",
    "seconds": "50",
    "lyric": "I asked my family doctor",
    "number": "1413",
  },
  {
    "artist": "Elvis Presley",
    "song": "Good Luck Charm",
    "year": "1962",
    "seconds": "50",
    "lyric": "Rabbit’s foot on a string",
    "number": "1414",
  },
  {
    "artist": "Oliver",
    "song": "Good Morning Starshine",
    "year": "1969",
    "seconds": "70",
    "lyric": "My love and me as we sing",
    "number": "1415",
  },
  {
    "artist": "Sam Cooke",
    "song": "Good News",
    "year": "1964",
    "seconds": "50",
    "lyric": "Meet her at the station",
    "number": "1416",
  },
  {
    "artist": "The Weavers",
    "song": "Good Night Irene",
    "year": "1955",
    "seconds": "50",
    "lyric": "Stop ramblin’, stop your gamblin’",
    "number": "1417",
  },
  {
    "artist": "Waylon Jennings",
    "song": "Good Ol Boys - Theme from The Dukes of Hazzard",
    "year": "1980",
    "seconds": "60",
    "lyric": "Straightenin’ the curves, flattenin’ the hills",
    "number": "1418",
  },
  {
    "artist": "Green Day",
    "song": "Good Riddance (Time of Your Life)",
    "year": "1997",
    "seconds": "60",
    "lyric": "A fork stuck in the road",
    "number": "1419",
  },
  {
    "artist": "Etta James",
    "song": "Good Rockin’ Daddy",
    "year": "1955",
    "seconds": "50",
    "lyric": "And I’ll hold you tight",
    "number": "1420",
  },
  {
    "artist": "David Houston",
    "song": "Good Things",
    "year": "1972",
    "seconds": "70",
    "lyric": "A honey bee kissin’ on the flowers",
    "number": "1421",
  },
  {
    "artist": "Carly Rae Jepsen (with Owl City)",
    "song": "Good Time",
    "year": "2012",
    "seconds": "60",
    "lyric": "Dropped my phone in the pool",
    "number": "1422",
  },
  {
    "artist": "Sam Cooke",
    "song": "Good Times",
    "year": "1964",
    "seconds": "50",
    "lyric": "The clock on the wall",
    "number": "1423",
  },
  {
    "artist": "The Cars",
    "song": "Good Times Roll",
    "year": "1979",
    "seconds": "60",
    "lyric": "Brush your rock and roll hair",
    "number": "1424",
  },
  {
    "artist": "Chris Janson",
    "song": "Good Vibes",
    "year": "2017",
    "seconds": "50",
    "lyric": "I kiss her good morning",
    "number": "1425",
  },
  {
    "artist": "Beach Boys",
    "song": "Good Vibrations",
    "year": "1966",
    "seconds": "60",
    "lyric": "The sunlight plays upon her hair",
    "number": "1426",
  },
  {
    "artist": "Mel Tillis",
    "song": "Good Woman Blues",
    "year": "1976",
    "seconds": "60",
    "lyric": "On the tail of my shirt",
    "number": "1427",
  },
  {
    "artist": "Dixie Chicks",
    "song": "Goodbye Earl",
    "year": "1999",
    "seconds": "50",
    "lyric": "She put on dark glasses",
    "number": "1428",
  },
  {
    "artist": "David Gates",
    "song": "Goodbye Girl",
    "year": "1978",
    "seconds": "60",
    "lyric": "For love to come and stay",
    "number": "1429",
  },
  {
    "artist": "James Blunt",
    "song": "Goodbye My Lover",
    "year": "2005",
    "seconds": "60",
    "lyric": "And I still hold your hand",
    "number": "1430",
  },
  {
    "artist": "Supertramp",
    "song": "Goodbye Stranger",
    "year": "1979",
    "seconds": "50",
    "lyric": "A queen without a throne",
    "number": "1431",
  },
  {
    "artist": "Elton John",
    "song": "Goodbye Yellow Brick Road",
    "year": "1973",
    "seconds": "60",
    "lyric": "Howling old owl in the woods",
    "number": "1432",
  },
  {
    "artist": "The Spaniels",
    "song": "Goodnite, Sweetheart, Goodnite",
    "year": "1954",
    "seconds": "50",
    "lyric": "Well, it’s time to go",
    "number": "1433",
  },
  {
    "artist": "John Conlee",
    "song": "Got My Heart Set on You",
    "year": "1986",
    "seconds": "40",
    "lyric": "I’m walking on air",
    "number": "1434",
  },
  {
    "artist": "Michael Jackson",
    "song": "Got to Be There",
    "year": "1971",
    "seconds": "50",
    "lyric": "I look in her eyes",
    "number": "1435",
  },
  {
    "artist": "The Beatles",
    "song": "Got to Get You Into My Life",
    "year": "1966",
    "seconds": "70",
    "lyric": "You were meant to be near me",
    "number": "1436",
  },
  {
    "artist": "Marvin Gaye",
    "song": "Got to Give It Up",
    "year": "1977",
    "seconds": "70",
    "lyric": "All the young ladies are so fine",
    "number": "1437",
  },
  {
    "artist": "Jason Aldean",
    "song": "Got What I Got",
    "year": "2020",
    "seconds": "60",
    "lyric": "In the back of your mind",
    "number": "1438",
  },
  {
    "artist": "Billy Grammer Monroe",
    "song": "Gotta Travel On",
    "year": "1958",
    "seconds": "60",
    "lyric": "I’ve laid around and played around",
    "number": "1439",
  },
  {
    "artist": "Mika",
    "song": "Grace Kelly",
    "year": "2007",
    "seconds": "70",
    "lyric": "I guess I’m a little bit shy",
    "number": "1440",
  },
  {
    "artist": "Paul Simon",
    "song": "Graceland",
    "year": "1986",
    "seconds": "50",
    "lyric": "A window in your heart",
    "number": "1441",
  },
  {
    "artist": "Vitamin C",
    "song": "Graduation (Friends Forever)",
    "year": "2000",
    "seconds": "60",
    "lyric": "Thinking it’s a time to fly",
    "number": "1442",
  },
  {
    "artist": "Styx",
    "song": "Grand Illusion",
    "year": "1977",
    "seconds": "50",
    "lyric": "Suddenly your heart is pounding",
    "number": "1443",
  },
  {
    "artist": "Elmo & Patsy",
    "song": "Grandma Got Run Over By a Reindeer",
    "year": "1979",
    "seconds": "40",
    "lyric": "Drinking too much eggnog",
    "number": "1444",
  },
  {
    "artist": "John Mayer",
    "song": "Gravity",
    "year": "2005",
    "seconds": "50",
    "lyric": "Send me to my knees",
    "number": "1445",
  },
  {
    "artist": "Hugh Masekela",
    "song": "Grazing in the Grass",
    "year": "1968",
    "seconds": "50",
    "lyric": "Can you dig it, baby?",
    "number": "1446",
  },
  {
    "artist": "Frankie Valli",
    "song": "Grease",
    "year": "1978",
    "seconds": "50",
    "lyric": "And I see the light",
    "number": "1447",
  },
  {
    "artist": "Jerry Lee Lewis",
    "song": "Great Balls of Fire",
    "year": "1957",
    "seconds": "70",
    "lyric": "Kiss me baby, woo, it feels good",
    "number": "1448",
  },
  {
    "artist": "Whitney Houston",
    "song": "Greatest Love of All",
    "year": "1985",
    "seconds": "50",
    "lyric": "To walk in anyone’s shadow",
    "number": "1449",
  },
  {
    "artist": "Barry McGuire & Randy Sparks",
    "song": "Green, Green",
    "year": "1963",
    "seconds": "60",
    "lyric": "Green, green valley or rocky road",
    "number": "1450",
  },
  {
    "artist": "Tom Jones",
    "song": "Green, Green Grass of Home",
    "year": "1967",
    "seconds": "60",
    "lyric": "The paint is cracked and dry",
    "number": "1451",
  },
  {
    "artist": "Lorde",
    "song": "Green Light",
    "year": "2017",
    "seconds": "60",
    "lyric": "She thinks you love the beach",
    "number": "1452",
  },
  {
    "artist": "Creedence Clearwater Revival",
    "song": "Green River",
    "year": "1969",
    "seconds": "50",
    "lyric": "I can hear the bullfrog",
    "number": "1453",
  },
  {
    "artist": "The Lemon Pipers",
    "song": "Green Tambourine",
    "year": "1968",
    "seconds": "50",
    "lyric": "When you toss a coin",
    "number": "1454",
  },
  {
    "artist": "The Kingston Trio",
    "song": "Greenback Dollar",
    "year": "1963",
    "seconds": "40",
    "lyric": "A bottle of brandy",
    "number": "1455",
  },
  {
    "artist": "Sugarloaf",
    "song": "Green-Eyed Lady",
    "year": "1970",
    "seconds": "50",
    "lyric": "Strolling slowly towards the sun",
    "number": "1456",
  },
  {
    "artist": "Bruno Mars",
    "song": "Grenade",
    "year": "2010",
    "seconds": "60",
    "lyric": "Jump in front of a train",
    "number": "1457",
  },
  {
    "artist": "The Young Rascals",
    "song": "Groovin’",
    "year": "1967",
    "seconds": "40",
    "lyric": "On a Sunday afternoon",
    "number": "1458",
  },
  {
    "artist": "Tim McGraw",
    "song": "Grown Men Don’t Cry",
    "year": "2001",
    "seconds": "70",
    "lyric": "Up the stairs and off to bed",
    "number": "1459",
  },
  {
    "artist": "Johnny Cash",
    "song": "Guess Things Happen That Way",
    "year": "1958",
    "seconds": "50",
    "lyric": "The strength to stand alone",
    "number": "1460",
  },
  {
    "artist": "Dwight Yoakam",
    "song": "Guitars, Cadillacs",
    "year": "1986",
    "seconds": "60",
    "lyric": "The pie don’t taste so sweet",
    "number": "1461",
  },
  {
    "artist": "Bessie Smith",
    "song": "Gulf Coast Blues",
    "year": "1923",
    "seconds": "50",
    "lyric": "My teeth are pearly white",
    "number": "1462",
  },
  {
    "artist": "Mindy McCready",
    "song": "Guys Do It All the Time",
    "year": "1996",
    "seconds": "60",
    "lyric": "The shoe’s on the other foot",
    "number": "1463",
  },
  {
    "artist": "Van Morrison",
    "song": "Gypsy",
    "year": "1973",
    "seconds": "50",
    "lyric": "The moon above your head",
    "number": "1464",
  },
  {
    "artist": "Cher",
    "song": "Gypsys, Tramps & Thieves",
    "year": "1971",
    "seconds": "60",
    "lyric": "Dance for the money they’d throw",
    "number": "1465",
  },
  {
    "artist": "Florida Georgia Line",
    "song": "H.O.L.Y.",
    "year": "2016",
    "seconds": "40",
    "lyric": "I sat in darkness",
    "number": "1466",
  },
  {
    "artist": "The Cowsills",
    "song": "Hair",
    "year": "1969",
    "seconds": "40",
    "lyric": "Shoulder length or longer",
    "number": "1467",
  },
  {
    "artist": "Leonard Cohen",
    "song": "Hallelujah",
    "year": "1984",
    "seconds": "50",
    "lyric": "Her beauty and the moonlight",
    "number": "1468",
  },
  {
    "artist": "Beyoncé",
    "song": "Halo",
    "year": "2009",
    "seconds": "60",
    "lyric": "It’s written all over your face",
    "number": "1469",
  },
  {
    "artist": "The Guess Who",
    "song": "Hand Me Down World",
    "year": "1970",
    "seconds": "50",
    "lyric": "See the sky weeping tears",
    "number": "1470",
  },
  {
    "artist": "James Taylor",
    "song": "Handy Man",
    "year": "1977",
    "seconds": "40",
    "lyric": "I fix broken hearts",
    "number": "1471",
  },
  {
    "artist": "The McCoys",
    "song": "Hang On Sloopy",
    "year": "1965",
    "seconds": "50",
    "lyric": "Let your hair down, girl",
    "number": "1472",
  },
  {
    "artist": "The Guess Who",
    "song": "Hang On To Your Life",
    "year": "1971",
    "seconds": "50",
    "lyric": "You can ride the wind",
    "number": "1473",
  },
  {
    "artist": "The Counting Crows",
    "song": "Hanginaround",
    "year": "1999",
    "seconds": "70",
    "lyric": "She sat right down on the sofa",
    "number": "1474",
  },
  {
    "artist": "Moe Bandy",
    "song": "Hank Williams, You Wrote My Life",
    "year": "1976",
    "seconds": "60",
    "lyric": "I hope you’ll shake my hand",
    "number": "1475",
  },
  {
    "artist": "Tommy James & the Shondells",
    "song": "Hanky Panky",
    "year": "1966",
    "seconds": "50",
    "lyric": "Walking on down the line",
    "number": "1476",
  },
  {
    "artist": "Marshmello (feat. Bastille)",
    "song": "Happier",
    "year": "2018",
    "seconds": "60",
    "lyric": "In the cold light of day",
    "number": "1477",
  },
  {
    "artist": "Leona Lewis",
    "song": "Happy",
    "year": "2009",
    "seconds": "50",
    "lyric": "Throws me off the edge",
    "number": "1478",
  },
  {
    "artist": "Mudvayne",
    "song": "Happy?",
    "year": "2005",
    "seconds": "50",
    "lyric": "Tear me from the rind",
    "number": "1479",
  },
  {
    "artist": "Pharrell Williams",
    "song": "Happy",
    "year": "2013",
    "seconds": "50",
    "lyric": "I’m a hot air balloon",
    "number": "1480",
  },
  {
    "artist": "Rolling Stones",
    "song": "Happy",
    "year": "1972",
    "seconds": "60",
    "lyric": "Burned a hole in my pants",
    "number": "1481",
  },
  {
    "artist": "Loretta Lynn",
    "song": "Happy Birthday",
    "year": "1964",
    "seconds": "50",
    "lyric": "But I’ll be busy packing",
    "number": "1482",
  },
  {
    "artist": "Conway Twitty",
    "song": "Happy Birthday Darlin’",
    "year": "1979",
    "seconds": "50",
    "lyric": "No presents, no fancy cake",
    "number": "1483",
  },
  {
    "artist": "Neil Sedaka",
    "song": "Happy Birthday Sweet Sixteen",
    "year": "1961",
    "seconds": "40",
    "lyric": "Smile with sweet surprise",
    "number": "1484",
  },
  {
    "artist": "The Tune Weavers",
    "song": "Happy, Happy Birthday Baby",
    "year": "1957",
    "seconds": "50",
    "lyric": "Thought I’d drop a line",
    "number": "1485",
  },
  {
    "artist": "Andy Williams",
    "song": "Happy Heart",
    "year": "1969",
    "seconds": "50",
    "lyric": "Singing loud and singing clear",
    "number": "1486",
  },
  {
    "artist": "The Who",
    "song": "Happy Jack",
    "year": "1966",
    "seconds": "60",
    "lyric": "So they rode on his head",
    "number": "1487",
  },
  {
    "artist": "The Turtles",
    "song": "Happy Together",
    "year": "1967",
    "seconds": "50",
    "lyric": "The skies will be blue",
    "number": "1488",
  },
  {
    "artist": "John & Yoko & the Plastic Ono Band",
    "song": "Happy Xmas (War is Over)",
    "year": "1971",
    "seconds": "60",
    "lyric": "The near and the dear one",
    "number": "1489",
  },
  {
    "artist": "Chicago",
    "song": "Hard Habit to Break",
    "year": "1984",
    "seconds": "50",
    "lyric": "It’s all a big mistake",
    "number": "1490",
  },
  {
    "artist": "Elvis Presley",
    "song": "Hard Headed Woman",
    "year": "1958",
    "seconds": "50",
    "lyric": "I’d cry around the clock",
    "number": "1491",
  },
  {
    "artist": "Sam Hunt",
    "song": "Hard to Forget",
    "year": "2020",
    "seconds": "70",
    "lyric": "I see your face in the clouds",
    "number": "1492",
  },
  {
    "artist": "The Black Crowes",
    "song": "Hard to Handle",
    "year": "1990",
    "seconds": "50",
    "lyric": "Let me light your candle",
    "number": "1493",
  },
  {
    "artist": "Chicago",
    "song": "Hard to Say I’m Sorry",
    "year": "1982",
    "seconds": "60",
    "lyric": "Everybody needs a little time away",
    "number": "1494",
  },
  {
    "artist": "Brooks & Dunn",
    "song": "Hard Workin’ Man",
    "year": "1993",
    "seconds": "70",
    "lyric": "I can ride, rope, hammer and paint",
    "number": "1495",
  },
  {
    "artist": "Rolling Stones",
    "song": "Harlem Shuffle",
    "year": "1986",
    "seconds": "50",
    "lyric": "Hitchhike baby, across the floor",
    "number": "1496",
  },
  {
    "artist": "Jeannie C. Riley",
    "song": "Harper Valley P.T.A.",
    "year": "1968",
    "seconds": "60",
    "lyric": "Wearing your dresses way too high",
    "number": "1497",
  },
  {
    "artist": "Neil Young",
    "song": "Harvest Moon",
    "year": "1993",
    "seconds": "60",
    "lyric": "And the moon is climbin’ high",
    "number": "1498",
  },
  {
    "artist": "Del Shannon",
    "song": "Hats Off to Larry",
    "year": "1961",
    "seconds": "50",
    "lyric": "But you laughed at me",
    "number": "1499",
  },
  {
    "artist": "David Houston",
    "song": "Have a Little Faith",
    "year": "1968",
    "seconds": "50",
    "lyric": "With a grain of salt",
    "number": "1500",
  },
  {
    "artist": "Van Morrison",
    "song": "Have I Told You Lately?",
    "year": "1989",
    "seconds": "50",
    "lyric": "Fill my heart with gladness",
    "number": "1501",
  },
  {
    "artist": "Gene Autry",
    "song": "Have I Told You Lately That I Love You?",
    "year": "1946",
    "seconds": "50",
    "lyric": "All my heart and soul",
    "number": "1502",
  },
  {
    "artist": "Jim Reeves and Patsy Cline",
    "song": "Have You Ever Been Lonely?",
    "year": "1981",
    "seconds": "60",
    "lyric": "Take me back in your heart",
    "number": "1503",
  },
  {
    "artist": "Bryan Adams",
    "song": "Have You Ever Really Loved a Woman?",
    "year": "1995",
    "seconds": "50",
    "lyric": "Layin’ helpless in her arms",
    "number": "1504",
  },
  {
    "artist": "Creedence Clearwater Revival",
    "song": "Have You Ever Seen the Rain?",
    "year": "1971",
    "seconds": "70",
    "lyric": "Sun is cold and rain is hard",
    "number": "1505",
  },
  {
    "artist": "Darryl Worley",
    "song": "Have You Forgotten?",
    "year": "2003",
    "seconds": "50",
    "lyric": "Going through a living hell",
    "number": "1506",
  },
  {
    "artist": "The Chi-Lites",
    "song": "Have You Seen Her",
    "year": "1971",
    "seconds": "70",
    "lyric": "In the sweet music on my radio",
    "number": "1507",
  },
  {
    "artist": "Judy Garland",
    "song": "Have Yourself a Merry Little Christmas",
    "year": "1944",
    "seconds": "50",
    "lyric": "Let your heart be light",
    "number": "1508",
  },
  {
    "artist": "Carly Simon",
    "song": "Haven’t Got Time For the Pain",
    "year": "1974",
    "seconds": "50",
    "lyric": "Fill my heart with love",
    "number": "1509",
  },
  {
    "artist": "Michael Bublé",
    "song": "Haven’t Met You Yet",
    "year": "2009",
    "seconds": "50",
    "lyric": "We can be so amazing",
    "number": "1510",
  },
  {
    "artist": "Sam Cooke",
    "song": "Having a Party",
    "year": "1962",
    "seconds": "40",
    "lyric": "Popcorn’s on the table",
    "number": "1511",
  },
  {
    "artist": "The Hollies",
    "song": "He Ain’t Heavy, He’s My Brother",
    "year": "1969",
    "seconds": "50",
    "lyric": "With many a winding turn",
    "number": "1512",
  },
  {
    "artist": "Lynn Anderson",
    "song": "He Ain’t You",
    "year": "1977",
    "seconds": "60",
    "lyric": "The sweet touch of his hand",
    "number": "1513",
  },
  {
    "artist": "Brad Paisley",
    "song": "He Didn’t Have to Be",
    "year": "1999",
    "seconds": "60",
    "lyric": "I’m at least half the dad",
    "number": "1514",
  },
  {
    "artist": "Tony Orlando and Dawn",
    "song": "He Don’t Love You (Like I Love You)",
    "year": "1975",
    "seconds": "60",
    "lyric": "He’s tryin’ to tear us apart",
    "number": "1515",
  },
  {
    "artist": "Ronnie Milsap",
    "song": "He Got You",
    "year": "1982",
    "seconds": "70",
    "lyric": "Leads me right back to your feet",
    "number": "1516",
  },
  {
    "artist": "Tammy Wynette",
    "song": "He Loves Me All the Way",
    "year": "1970",
    "seconds": "60",
    "lyric": "He comes home, I’m cookin’ breakfast",
    "number": "1517",
  },
  {
    "artist": "George Jones",
    "song": "He Stopped Loving Her Today",
    "year": "1980",
    "seconds": "50",
    "lyric": "A wreath upon his door",
    "number": "1518",
  },
  {
    "artist": "Avril Lavigne",
    "song": "Head Above Water",
    "year": "2018",
    "seconds": "60",
    "lyric": "Bar the windows and the doors",
    "number": "1519",
  },
  {
    "artist": "Jon Pardi",
    "song": "Head Over Boots",
    "year": "2016",
    "seconds": "50",
    "lyric": "Sweep you off your feet",
    "number": "1520",
  },
  {
    "artist": "Jo Dee Messina",
    "song": "Heads Carolina, Tails California",
    "year": "1996",
    "seconds": "50",
    "lyric": "Tonight, let’s flip a coin",
    "number": "1521",
  },
  {
    "artist": "The Marshall Tucker Band",
    "song": "Heard It in a Love Song",
    "year": "1977",
    "seconds": "70",
    "lyric": "I saw a teardrop in your eye",
    "number": "1522",
  },
  {
    "artist": "Blondie",
    "song": "Heart of Glass",
    "year": "1979",
    "seconds": "50",
    "lyric": "There’s no peace of mind",
    "number": "1523",
  },
  {
    "artist": "Neil Young",
    "song": "Heart of Gold",
    "year": "1971",
    "seconds": "50",
    "lyric": "It’s such a fine line",
    "number": "1524",
  },
  {
    "artist": "Peter Salett",
    "song": "Heart of Mine",
    "year": "2000",
    "seconds": "60",
    "lyric": "I’m through with waiting and hesitating",
    "number": "1525",
  },
  {
    "artist": "The Oak Ridge Boys",
    "song": "Heart of Mine",
    "year": "1980",
    "seconds": "50",
    "lyric": "Oh my heart beat slow",
    "number": "1526",
  },
  {
    "artist": "Rolling Stones",
    "song": "Heart of Stone",
    "year": "1964",
    "seconds": "50",
    "lyric": "Knock her off her feet",
    "number": "1527",
  },
  {
    "artist": "Eagles",
    "song": "Heartache Tonight",
    "year": "1979",
    "seconds": "60",
    "lyric": "We can beat around the bushes",
    "number": "1528",
  },
  {
    "artist": "Harry James",
    "song": "Heartaches",
    "year": "1947",
    "seconds": "40",
    "lyric": "Just a burning memory",
    "number": "1529",
  },
  {
    "artist": "Guy Mitchell",
    "song": "Heartaches By the Number",
    "year": "1959",
    "seconds": "70",
    "lyric": "But the day that I stop counting",
    "number": "1530",
  },
  {
    "artist": "Annie",
    "song": "Heartbeat",
    "year": "2004",
    "seconds": "60",
    "lyric": "And I was dancing with you",
    "number": "1531",
  },
  {
    "artist": "BTS",
    "song": "Heartbeat",
    "year": "2019",
    "seconds": "60",
    "lyric": "But my heart’s still on fire",
    "number": "1532",
  },
  {
    "artist": "Buddy Holly",
    "song": "Heartbeat",
    "year": "1958",
    "seconds": "50",
    "lyric": "When my baby kisses me",
    "number": "1533",
  },
  {
    "artist": "Carrie Underwood",
    "song": "Heartbeat",
    "year": "2015",
    "seconds": "60",
    "lyric": "A cricket choir in the background",
    "number": "1534",
  },
  {
    "artist": "Don Johnson",
    "song": "Heartbeat",
    "year": "1986",
    "seconds": "40",
    "lyric": "Standing by the fire",
    "number": "1535",
  },
  {
    "artist": "Don Williams",
    "song": "Heartbeat in the Darkness",
    "year": "1986",
    "seconds": "50",
    "lyric": "You see money and clothes",
    "number": "1536",
  },
  {
    "artist": "Elvis Presley",
    "song": "Heartbreak Hotel",
    "year": "1956",
    "seconds": "70",
    "lyric": "Down at the end of Lonely Street",
    "number": "1537",
  },
  {
    "artist": "Dionne Warwick",
    "song": "Heartbreaker",
    "year": "1982",
    "seconds": "50",
    "lyric": "I’m crying in the rain",
    "number": "1538",
  },
  {
    "artist": "Dolly Parton",
    "song": "Heartbreaker",
    "year": "1978",
    "seconds": "40",
    "lyric": "Sweet little love maker",
    "number": "1539",
  },
  {
    "artist": "Justin Bieber",
    "song": "Heartbreaker",
    "year": "2013",
    "seconds": "50",
    "lyric": "You’ll pick up your phone",
    "number": "1540",
  },
  {
    "artist": "Led Zeppelin",
    "song": "Heartbreaker",
    "year": "1969",
    "seconds": "60",
    "lyric": "I first set eyes on you",
    "number": "1541",
  },
  {
    "artist": "Mariah Carey (ft. Jay-Z)",
    "song": "Heartbreaker",
    "year": "1999",
    "seconds": "40",
    "lyric": "Right from the start",
    "number": "1542",
  },
  {
    "artist": "Pat Benatar",
    "song": "Heartbreaker",
    "year": "1979",
    "seconds": "50",
    "lyric": "Wave spinning over my head",
    "number": "1543",
  },
  {
    "artist": "will.i.am",
    "song": "Heartbreaker",
    "year": "2008",
    "seconds": "70",
    "lyric": "Throw the love down in the dirt",
    "number": "1544",
  },
  {
    "artist": "George Strait",
    "song": "Heartland",
    "year": "1993",
    "seconds": "60",
    "lyric": "Twin fiddles and a steel guitar",
    "number": "1545",
  },
  {
    "artist": "Neil Diamond",
    "song": "Heartlight",
    "year": "1982",
    "seconds": "50",
    "lyric": "A ride across the moon",
    "number": "1546",
  },
  {
    "artist": "Lee Roy Parnell",
    "song": "Heart’s Desire",
    "year": "1996",
    "seconds": "60",
    "lyric": "You’re givin’ me a cardiac arrest",
    "number": "1547",
  },
  {
    "artist": "Bryan Adams",
    "song": "Heat of the Night",
    "year": "1987",
    "seconds": "50",
    "lyric": "Pull the covers up high",
    "number": "1548",
  },
  {
    "artist": "Bryan Adams",
    "song": "Heaven",
    "year": "1985",
    "seconds": "50",
    "lyric": "Lying here in my arms",
    "number": "1549",
  },
  {
    "artist": "Donna Summer (ft. Brooklyn Dreams)",
    "song": "Heaven Knows",
    "year": "1978",
    "seconds": "40",
    "lyric": "So come on down",
    "number": "1550",
  },
  {
    "artist": "Tavares",
    "song": "Heaven Must Be Missing an Angel",
    "year": "1976",
    "seconds": "50",
    "lyric": "A rainbow over my shoulder",
    "number": "1551",
  },
  {
    "artist": "The Kendalls",
    "song": "Heaven’s Just a Sin Away",
    "year": "1977",
    "seconds": "60",
    "lyric": "I’d love to hold you tight",
    "number": "1552",
  },
  {
    "artist": "Paul McCartney & Wings",
    "song": "Helen Wheels",
    "year": "1973",
    "seconds": "70",
    "lyric": "No time for a rum and lime",
    "number": "1553",
  },
  {
    "artist": "Jim Reeves",
    "song": "He’ll Have to Go",
    "year": "1960",
    "seconds": "60",
    "lyric": "A little closer to the phone",
    "number": "1554",
  },
  {
    "artist": "No Doubt",
    "song": "Hella Good",
    "year": "2002",
    "seconds": "70",
    "lyric": "So don’t wake me if I’m dreaming",
    "number": "1555",
  },
  {
    "artist": "Adele",
    "song": "Hello",
    "year": "2015",
    "seconds": "60",
    "lyric": "I’m sorry for breaking your heart",
    "number": "1556",
  },
  {
    "artist": "Lionel Richie",
    "song": "Hello",
    "year": "1984",
    "seconds": "60",
    "lyric": "Is it me you’re looking for?",
    "number": "1557",
  },
  {
    "artist": "Neil Diamond",
    "song": "Hello Again",
    "year": "1981",
    "seconds": "50",
    "lyric": "My heart above my head",
    "number": "1558",
  },
  {
    "artist": "Conway Twitty",
    "song": "Hello Darlin’",
    "year": "1970",
    "seconds": "50",
    "lyric": "It’s been a long time",
    "number": "1559",
  },
  {
    "artist": "Louis Armstrong",
    "song": "Hello Dolly!",
    "year": "1964",
    "seconds": "50",
    "lyric": "Find her an empty lap",
    "number": "1560",
  },
  {
    "artist": "The Beatles",
    "song": "Hello, Goodbye",
    "year": "1967",
    "seconds": "90",
    "lyric": "You say stop and I say go, go, go",
    "number": "1561",
  },
  {
    "artist": "The Doors",
    "song": "Hello, I Love You",
    "year": "1968",
    "seconds": "60",
    "lyric": "Blind to every eye she meets",
    "number": "1562",
  },
  {
    "artist": "John Prine",
    "song": "Hello in There",
    "year": "1971",
    "seconds": "50",
    "lyric": "Old people just grow lonesome",
    "number": "1563",
  },
  {
    "artist": "Todd Rundgren",
    "song": "Hello It’s Me",
    "year": "1974",
    "seconds": "60",
    "lyric": "I’ll come around to see you",
    "number": "1564",
  },
  {
    "artist": "Ricky Nelson",
    "song": "Hello Mary Lou",
    "year": "1961",
    "seconds": "50",
    "lyric": "Flashed those big brown eyes",
    "number": "1565",
  },
  {
    "artist": "Allan Sherman",
    "song": "Hello Muddah, Hello Fadduh",
    "year": "1963",
    "seconds": "50",
    "lyric": "And the lake has alligators",
    "number": "1566",
  },
  {
    "artist": "Barbara Lewis",
    "song": "Hello Stranger",
    "year": "1963",
    "seconds": "50",
    "lyric": "I still love you so",
    "number": "1567",
  },
  {
    "artist": "Faron Young",
    "song": "Hello Walls",
    "year": "1961",
    "seconds": "70",
    "lyric": "Teardrop in the corner of your pane",
    "number": "1568",
  },
  {
    "artist": "AC/DC",
    "song": "Hells Bells",
    "year": "1980",
    "seconds": "50",
    "lyric": "I’m rolling thunder, pouring rain",
    "number": "1569",
  },
  {
    "artist": "The Beatles",
    "song": "Help!",
    "year": "1965",
    "seconds": "50",
    "lyric": "And opened up the doors",
    "number": "1570",
  },
  {
    "artist": "Joni Mitchell",
    "song": "Help Me",
    "year": "1974",
    "seconds": "80",
    "lyric": "The lady with the hole in her stocking",
    "number": "1571",
  },
  {
    "artist": "Travis Tritt",
    "song": "Help Me Hold On",
    "year": "1990",
    "seconds": "60",
    "lyric": "Sit down and talk to me",
    "number": "1572",
  },
  {
    "artist": "Sammi Smith",
    "song": "Help Me Make It Through the Night",
    "year": "1971",
    "seconds": "60",
    "lyric": "Like the shadows on the wall",
    "number": "1573",
  },
  {
    "artist": "Beach Boys",
    "song": "Help Me, Rhonda",
    "year": "1965",
    "seconds": "60",
    "lyric": "Get her out of my heart",
    "number": "1574",
  },
  {
    "artist": "George Jones",
    "song": "Her Name Is",
    "year": "1976",
    "seconds": "50",
    "lyric": "We’ll walk in the sunshine",
    "number": "1575",
  },
  {
    "artist": "Bob Seger",
    "song": "Her Strut",
    "year": "1980",
    "seconds": "50",
    "lyric": "Give up and leave her",
    "number": "1576",
  },
  {
    "artist": "Alessia Cara",
    "song": "Here",
    "year": "2018",
    "seconds": "60",
    "lyric": "And we’ll discuss our big dreams",
    "number": "1577",
  },
  {
    "artist": "Rascal Flatts",
    "song": "Here",
    "year": "2008",
    "seconds": "50",
    "lyric": "Behind windows, walls and doors",
    "number": "1578",
  },
  {
    "artist": "Rascal Flatts",
    "song": "Here Comes Goodbye",
    "year": "2009",
    "seconds": "60",
    "lyric": "I can hear the truck tires",
    "number": "1579",
  },
  {
    "artist": "Cat Stevens",
    "song": "Here Comes My Baby",
    "year": "1967",
    "seconds": "60",
    "lyric": "I’m still waiting for your heart",
    "number": "1580",
  },
  {
    "artist": "Gene Autry",
    "song": "Here Comes Santa Claus",
    "year": "1947",
    "seconds": "70",
    "lyric": "Jump in bed and cover your head",
    "number": "1581",
  },
  {
    "artist": "The Fortunes",
    "song": "Here Comes That Rainy Day Feeling Again",
    "year": "1971",
    "seconds": "70",
    "lyric": "And I’ll be dreamin’ of you, baby",
    "number": "1582",
  },
  {
    "artist": "Them",
    "song": "Here Comes the Night",
    "year": "1965",
    "seconds": "40",
    "lyric": "His arm around her",
    "number": "1583",
  },
  {
    "artist": "The Beatles",
    "song": "Here Comes the Sun",
    "year": "1969",
    "seconds": "50",
    "lyric": "A long cold lonely winter",
    "number": "1584",
  },
  {
    "artist": "Streetheart",
    "song": "Here Comes the Night",
    "year": "1979",
    "seconds": "70",
    "lyric": "I can see her closing her eyes",
    "number": "1585",
  },
  {
    "artist": "George Strait",
    "song": "Here For a Good Time",
    "year": "2011",
    "seconds": "40",
    "lyric": "He left nothing behind",
    "number": "1586",
  },
  {
    "artist": "Whitesnake",
    "song": "Here I Go Again",
    "year": "1982",
    "seconds": "40",
    "lyric": "Lonely street of dreams",
    "number": "1587",
  },
  {
    "artist": "Tony Martin",
    "song": "Here (In This Enchanted Place)",
    "year": "1954",
    "seconds": "50",
    "lyric": "Beside the warmth of you",
    "number": "1588",
  },
  {
    "artist": "Demi Lovato",
    "song": "Here We Go Again",
    "year": "2009",
    "seconds": "60",
    "lyric": "Cleared you out of my head",
    "number": "1589",
  },
  {
    "artist": "Ray Charles",
    "song": "Here We Go Again",
    "year": "1967",
    "seconds": "40",
    "lyric": "She’ll break my heart",
    "number": "1590",
  },
  {
    "artist": "Dolly Parton",
    "song": "Here You Come Again",
    "year": "1977",
    "seconds": "60",
    "lyric": "You waltz right in the door",
    "number": "1591",
  },
  {
    "artist": "Travis Tritt",
    "song": "Here’s a Quarter (Call Someone Who Cares)",
    "year": "1991",
    "seconds": "40",
    "lyric": "You’re lonesome and scared",
    "number": "1592",
  },
  {
    "artist": "Tanya Tucker",
    "song": "Here’s Some Love",
    "year": "1976",
    "seconds": "50",
    "lyric": "Put your lips on mine",
    "number": "1593",
  },
  {
    "artist": "Enrique Iglesias",
    "song": "Hero",
    "year": "2001",
    "seconds": "60",
    "lyric": "I can kiss away the pain",
    "number": "1594",
  },
  {
    "artist": "Mariah Carey",
    "song": "Hero",
    "year": "1993",
    "seconds": "60",
    "lyric": "Look inside you and be strong",
    "number": "1595",
  },
  {
    "artist": "The Crystals",
    "song": "He’s a Rebel",
    "year": "1962",
    "seconds": "50",
    "lyric": "Standing right by his side",
    "number": "1596",
  },
  {
    "artist": "The Chiffons",
    "song": "He’s So Fine",
    "year": "1963",
    "seconds": "40",
    "lyric": "He’s a soft-spoken guy",
    "number": "1597",
  },
  {
    "artist": "Bruce Channel",
    "song": "Hey! Baby",
    "year": "1961",
    "seconds": "50",
    "lyric": "If you’ll be my girl",
    "number": "1598",
  },
  {
    "artist": "Neil Young",
    "song": "Hey Hey, My My (Into the Black)",
    "year": "1979",
    "seconds": "60",
    "lyric": "Rock and roll can never die",
    "number": "1599",
  },
  {
    "artist": "Jimi Hendrix",
    "song": "Hey Joe",
    "year": "1966",
    "seconds": "50",
    "lyric": "I caught her messin’ ‘round",
    "number": "1600",
  },
  {
    "artist": "The Beatles",
    "song": "Hey Jude",
    "year": "1968",
    "seconds": "60",
    "lyric": "Carry the world upon your shoulders",
    "number": "1601",
  },
  {
    "artist": "Panic! At the Disco",
    "song": "Hey Look Ma, I Made It",
    "year": "2018",
    "seconds": "50",
    "lyric": "In the garden of evil",
    "number": "1602",
  },
  {
    "artist": "David Guetta",
    "song": "Hey Mama",
    "year": "2015",
    "seconds": "50",
    "lyric": "Yes, I do the cooking",
    "number": "1603",
  },
  {
    "artist": "The Black Eyed Peas",
    "song": "Hey Mama",
    "year": "2004",
    "seconds": "50",
    "lyric": "Rice and peas and chicken",
    "number": "1604",
  },
  {
    "artist": "Paul & Paula",
    "song": "Hey Paula",
    "year": "1963",
    "seconds": "60",
    "lyric": "I want to marry you too",
    "number": "1605",
  },
  {
    "artist": "Shaggy (ft. Brian and Tony Gold)",
    "song": "Hey Sexy Lady",
    "year": "2002",
    "seconds": "40",
    "lyric": "Crawlin’ up the wall",
    "number": "1606",
  },
  {
    "artist": "Plain White T’s",
    "song": "Hey There Delilah",
    "year": "2007",
    "seconds": "50",
    "lyric": "I’m a thousand miles away",
    "number": "1607",
  },
  {
    "artist": "Eddie Holman",
    "song": "Hey There Lonely Girl",
    "year": "1970",
    "seconds": "50",
    "lyric": "I’ll kiss your tears away",
    "number": "1608",
  },
  {
    "artist": "Creedence Clearwater Revival",
    "song": "Hey Tonight",
    "year": "1971",
    "seconds": "50",
    "lyric": "Don’t you know I’m flyin’",
    "number": "1609",
  },
  {
    "artist": "OutKast",
    "song": "Hey Ya!",
    "year": "2003",
    "seconds": "40",
    "lyric": "Lend me some sugar",
    "number": "1610",
  },
  {
    "artist": "Pink Floyd",
    "song": "Hey You",
    "year": "1979",
    "seconds": "50",
    "lyric": "Itchy feet and fading smiles",
    "number": "1611",
  },
  {
    "artist": "Frank Sinatra",
    "song": "High Hopes",
    "year": "1959",
    "seconds": "50",
    "lyric": "Your chin on the ground",
    "number": "1612",
  },
  {
    "artist": "Kodaline",
    "song": "High Hopes",
    "year": "2013",
    "seconds": "70",
    "lyric": "Staring at the barrel of a gun",
    "number": "1613",
  },
  {
    "artist": "Panic! At the Disco",
    "song": "High Hopes",
    "year": "2018",
    "seconds": "50",
    "lyric": "Light up your wildest dreams",
    "number": "1614",
  },
  {
    "artist": "Toby Keith",
    "song": "High Maintenance Woman",
    "year": "2007",
    "seconds": "60",
    "lyric": "The back of a long limousine",
    "number": "1615",
  },
  {
    "artist": "Bowling for Soup",
    "song": "High School Never Ends",
    "year": "2006",
    "seconds": "50",
    "lyric": "Still care about your hair",
    "number": "1616",
  },
  {
    "artist": "AC/DC",
    "song": "High Voltage",
    "year": "1975",
    "seconds": "50",
    "lyric": "Spotlight, put the lights out",
    "number": "1617",
  },
  {
    "artist": "Jackie Wilson",
    "song": "Higher and Higher",
    "year": "1967",
    "seconds": "70",
    "lyric": "Yes, that one in a million girl",
    "number": "1618",
  },
  {
    "artist": "Stevie Wonder",
    "song": "Higher Ground",
    "year": "1973",
    "seconds": "50",
    "lyric": "It won’t be too long",
    "number": "1619",
  },
  {
    "artist": "Steve Winwood (ft.Chaka Khan)",
    "song": "Higher Love",
    "year": "1986",
    "seconds": "50",
    "lyric": "Hidden in the stars above",
    "number": "1620",
  },
  {
    "artist": "Zac Brown Band",
    "song": "Highway 20 Ride",
    "year": "2009",
    "seconds": "40",
    "lyric": "I slowly die inside",
    "number": "1621",
  },
  {
    "artist": "Tim McGraw (ft. Keith Urban & Taylor Swift)",
    "song": "Highway Don’t Care",
    "year": "2013",
    "seconds": "50",
    "lyric": "Bet your window’s rolled down",
    "number": "1622",
  },
  {
    "artist": "Tanya Tucker",
    "song": "Highway Robbery",
    "year": "1988",
    "seconds": "60",
    "lyric": "But before you write me up",
    "number": "1623",
  },
  {
    "artist": "AC/DC",
    "song": "Highway to Hell",
    "year": "1979",
    "seconds": "50",
    "lyric": "No stop sign, speed limit",
    "number": "1624",
  },
  {
    "artist": "Blake Shelton (ft. Trace Adkins)",
    "song": "Hillbilly Bone",
    "year": "2009",
    "seconds": "50",
    "lyric": "Born out in the sticks",
    "number": "1625",
  },
  {
    "artist": "Shakira (feat. Wyclef Jean)",
    "song": "Hips Don’t Lie",
    "year": "2006",
    "seconds": "60",
    "lyric": "I can see your body moving",
    "number": "1626",
  },
  {
    "artist": "Pat Benatar",
    "song": "Hit Me With Your Best Shot",
    "year": "1979",
    "seconds": "50",
    "lyric": "You’re the real tough cookie",
    "number": "1627",
  },
  {
    "artist": "Ray Charles",
    "song": "Hit the Road Jack",
    "year": "1961",
    "seconds": "60",
    "lyric": "I’ll be back on my feet",
    "number": "1628",
  },
  {
    "artist": "Vanity Fair",
    "song": "Hitchin’ A Ride",
    "year": "1970",
    "seconds": "80",
    "lyric": "A thumb goes up, a car goes by",
    "number": "1629",
  },
  {
    "artist": "Britney Spears",
    "song": "Hold It Against Me",
    "year": "2011",
    "seconds": "50",
    "lyric": "My heart was beating loud",
    "number": "1630",
  },
  {
    "artist": "Mel Carter",
    "song": "Hold Me, Thrill Me, Kiss Me",
    "year": "1965",
    "seconds": "80",
    "lyric": "And drive me slowly out of my mind",
    "number": "1631",
  },
  {
    "artist": "U2",
    "song": "Hold Me, Thrill Me, Kiss Me, Kill Me",
    "year": "1995",
    "seconds": "60",
    "lyric": "You’re a headache in a suitcase",
    "number": "1632",
  },
  {
    "artist": "En Vogue",
    "song": "Hold On",
    "year": "1990",
    "seconds": "40",
    "lyric": "Don’t waste your time",
    "number": "1633",
  },
  {
    "artist": "Santana",
    "song": "Hold On",
    "year": "1982",
    "seconds": "40",
    "lyric": "Close my eyes, memorize",
    "number": "1634",
  },
  {
    "artist": "Wilson Phillips",
    "song": "Hold On",
    "year": "1990",
    "seconds": "50",
    "lyric": "Turn around and say goodbye",
    "number": "1635",
  },
  {
    "artist": "LaMarca",
    "song": "Hold On Blue Eyes",
    "year": "1986",
    "seconds": "50",
    "lyric": "Twist and turn the knife",
    "number": "1636",
  },
  {
    "artist": ".38 Special",
    "song": "Hold On Loosely",
    "year": "1981",
    "seconds": "70",
    "lyric": "My mind goes back to a girl",
    "number": "1637",
  },
  {
    "artist": "Toto",
    "song": "Hold the Line",
    "year": "1978",
    "seconds": "50",
    "lyric": "Love isn’t always on time",
    "number": "1638",
  },
  {
    "artist": "Beyoncé",
    "song": "Hold Up",
    "year": "2016",
    "seconds": "50",
    "lyric": "I look in the mirror",
    "number": "1639",
  },
  {
    "artist": "Argent",
    "song": "Hold Your Head Up",
    "year": "1972",
    "seconds": "60",
    "lyric": "Don’t let them see you cry",
    "number": "1640",
  },
  {
    "artist": "Tracy Byrd",
    "song": "Holdin’ Heaven",
    "year": "1993",
    "seconds": "60",
    "lyric": "Face to face with an angel",
    "number": "1641",
  },
  {
    "artist": "Gwen Stefani",
    "song": "Hollaback Girl",
    "year": "2005",
    "seconds": "40",
    "lyric": "Put your pom-poms down",
    "number": "1642",
  },
  {
    "artist": "Neil Diamond",
    "song": "Holly Holy",
    "year": "1969",
    "seconds": "40",
    "lyric": "Dream of only me",
    "number": "1643",
  },
  {
    "artist": "Bob Seger",
    "song": "Hollywood Nights",
    "year": "1978",
    "seconds": "70",
    "lyric": "So right in her diamonds and frills",
    "number": "1644",
  },
  {
    "artist": "Kool & the Gang",
    "song": "Hollywood Swinging",
    "year": "1974",
    "seconds": "50",
    "lyric": "A bad piano playing man",
    "number": "1645",
  },
  {
    "artist": "Machine Gun Kelly, Bebe Rexha, X Ambassadors",
    "song": "Home",
    "year": "2017",
    "seconds": "50",
    "lyric": "Take this off my shoulders",
    "number": "1646",
  },
  {
    "artist": "Michael Bublé",
    "song": "Home",
    "year": "2005",
    "seconds": "50",
    "lyric": "Surrounded by a million people",
    "number": "1647",
  },
  {
    "artist": "Luke Bryan (ft. Karen Fairchild)",
    "song": "Home Alone Tonight",
    "year": "2015",
    "seconds": "40",
    "lyric": "Throw your camera up",
    "number": "1648",
  },
  {
    "artist": "John Michael Montgomery",
    "song": "Home to You",
    "year": "1999",
    "seconds": "50",
    "lyric": "I get up and battle",
    "number": "1649",
  },
  {
    "artist": "Zac Brown Band",
    "song": "Homegrown",
    "year": "2015",
    "seconds": "50",
    "lyric": "Sip whiskey out the bottle",
    "number": "1650",
  },
  {
    "artist": "Josh Turner",
    "song": "Hometown Girl",
    "year": "2017",
    "seconds": "60",
    "lyric": "She’s the salt of the earth",
    "number": "1651",
  },
  {
    "artist": "Simon & Garfunkel",
    "song": "Homeward Bound",
    "year": "1966",
    "seconds": "60",
    "lyric": "I’m sitting in the railway station",
    "number": "1652",
  },
  {
    "artist": "Logic (ft. Eminem)",
    "song": "Homicide",
    "year": "2019",
    "seconds": "50",
    "lyric": "I’m foamin’ at the mouth",
    "number": "1653",
  },
  {
    "artist": "Bobby Goldsboro",
    "song": "Honey",
    "year": "1968",
    "seconds": "50",
    "lyric": "To brush the snow away",
    "number": "1654",
  },
  {
    "artist": "Mandy Moore",
    "song": "Honey",
    "year": "1999",
    "seconds": "50",
    "lyric": "Like sugar to my heart",
    "number": "1655",
  },
  {
    "artist": "Mariah Carey",
    "song": "Honey",
    "year": "1997",
    "seconds": "50",
    "lyric": "Your love comes over me",
    "number": "1656",
  },
  {
    "artist": "Blake Shelton",
    "song": "Honey Bee",
    "year": "2011",
    "seconds": "40",
    "lyric": "Your shot of whiskey",
    "number": "1657",
  },
  {
    "artist": "Glen Campbell",
    "song": "Honey Come Back",
    "year": "1970",
    "seconds": "60",
    "lyric": "The last time I held you",
    "number": "1658",
  },
  {
    "artist": "Jimmie Rodgers",
    "song": "Honeycomb",
    "year": "1957",
    "seconds": "50",
    "lyric": "And a piece of bone",
    "number": "1659",
  },
  {
    "artist": "Elton John",
    "song": "Honky Cat",
    "year": "1972",
    "seconds": "70",
    "lyric": "Drink whiskey from a bottle of wine",
    "number": "1660",
  },
  {
    "artist": "Dwight Yoakam",
    "song": "Honky Tonk Man",
    "year": "1986",
    "seconds": "60",
    "lyric": "To give the girls a whirl",
    "number": "1661",
  },
  {
    "artist": "Randy Travis",
    "song": "Honky Tonk Moon",
    "year": "1988",
    "seconds": "50",
    "lyric": "Woods so cool and dark",
    "number": "1662",
  },
  {
    "artist": "Rolling Stones",
    "song": "Honky Tonk Women",
    "year": "1969",
    "seconds": "60",
    "lyric": "Take me upstairs for a ride",
    "number": "1663",
  },
  {
    "artist": "Hank Williams",
    "song": "Honky Tonkin’",
    "year": "1948",
    "seconds": "50",
    "lyric": "You are sad and lonely",
    "number": "1664",
  },
  {
    "artist": "Olivia Newton-John",
    "song": "Hopelessly Devoted to You",
    "year": "1978",
    "seconds": "50",
    "lyric": "You pushed my love aside",
    "number": "1665",
  },
  {
    "artist": "B. J. Thomas",
    "song": "Hooked on a Feeling",
    "year": "1969",
    "seconds": "60",
    "lyric": "Thirsty for another cup of wine",
    "number": "1666",
  },
  {
    "artist": "The Fontane Sisters and Perry Como",
    "song": "Hoop-Dee-Doo",
    "year": "1950",
    "seconds": "50",
    "lyric": "A fiddle in the middle",
    "number": "1667",
  },
  {
    "artist": "Foreigner",
    "song": "Hot Blooded",
    "year": "1978",
    "seconds": "70",
    "lyric": "A fever of a hundred and three",
    "number": "1668",
  },
  {
    "artist": "Nick Gilder",
    "song": "Hot Child in the City",
    "year": "1978",
    "seconds": "50",
    "lyric": "Runnin’ wild and lookin’ pretty",
    "number": "1669",
  },
  {
    "artist": "Perry Como",
    "song": "Hot Diggity",
    "year": "1956",
    "seconds": "60",
    "lyric": "Happy the rest of my life",
    "number": "1670",
  },
  {
    "artist": "Van Halen",
    "song": "Hot For Teacher",
    "year": "1984",
    "seconds": "60",
    "lyric": "I think that clock is slow",
    "number": "1671",
  },
  {
    "artist": "Sly and the Family Stone",
    "song": "Hot Fun in the Summertime",
    "year": "1969",
    "seconds": "50",
    "lyric": "And here she comes back",
    "number": "1672",
  },
  {
    "artist": "Nelly",
    "song": "Hot in Herre",
    "year": "2002",
    "seconds": "50",
    "lyric": "Penthouse, rooftop, birds I’m feeding",
    "number": "1673",
  },
  {
    "artist": "Rod Stewart",
    "song": "Hot Legs",
    "year": "1977",
    "seconds": "50",
    "lyric": "You can scream and shout",
    "number": "1674",
  },
  {
    "artist": "The Sylvers",
    "song": "Hot Line",
    "year": "1976",
    "seconds": "50",
    "lyric": "My desire is climbin’ higher",
    "number": "1675",
  },
  {
    "artist": "Katy Perry",
    "song": "Hot N Cold",
    "year": "2008",
    "seconds": "50",
    "lyric": "We fight, we break up",
    "number": "1676",
  },
  {
    "artist": "Commander Cody & his Lost Planet Airmen",
    "song": "Hot Rod Lincoln",
    "year": "1972",
    "seconds": "50",
    "lyric": "I looked in my mirror",
    "number": "1677",
  },
  {
    "artist": "Donna Summer",
    "song": "Hot Stuff",
    "year": "1979",
    "seconds": "60",
    "lyric": "Sittin’ here eatin’ my heart out",
    "number": "1678",
  },
  {
    "artist": "Eagles",
    "song": "Hotel California",
    "year": "1977",
    "seconds": "50",
    "lyric": "The pink champagne on ice",
    "number": "1679",
  },
  {
    "artist": "Elvis Presley",
    "song": "Hound Dog",
    "year": "1956",
    "seconds": "60",
    "lyric": "You ain’t no friend of mine",
    "number": "1680",
  },
  {
    "artist": "Sam Hunt",
    "song": "House Party",
    "year": "2015",
    "seconds": "60",
    "lyric": "At your door in ten minutes",
    "number": "1681",
  },
  {
    "artist": "Led Zeppelin",
    "song": "Houses of the Holy",
    "year": "1975",
    "seconds": "50",
    "lyric": "An angel on my shoulder",
    "number": "1682",
  },
  {
    "artist": "Ronnie Milsap",
    "song": "Houston Solution",
    "year": "1989",
    "seconds": "70",
    "lyric": "I can hang out or hide out",
    "number": "1683",
  },
  {
    "artist": "Tracy Lawrence",
    "song": "How a Cowgirl Says Goodbye",
    "year": "1997",
    "seconds": "50",
    "lyric": "Through a worn out roadmap",
    "number": "1684",
  },
  {
    "artist": "George Strait",
    "song": "How ‘Bout Them Cowgirls",
    "year": "2007",
    "seconds": "50",
    "lyric": "On her own two feet",
    "number": "1685",
  },
  {
    "artist": "The Young Rascals",
    "song": "How Can I Be Sure?",
    "year": "1967",
    "seconds": "70",
    "lyric": "Touch me but don’t take me down",
    "number": "1686",
  },
  {
    "artist": "The Bee Gees",
    "song": "How Can You Mend a Broken Heart?",
    "year": "1971",
    "seconds": "60",
    "lyric": "I can still feel the breeze",
    "number": "1687",
  },
  {
    "artist": "The Bee Gees",
    "song": "How Deep is Your Love?",
    "year": "1977",
    "seconds": "60",
    "lyric": "Touch me in the pouring rain",
    "number": "1688",
  },
  {
    "artist": "Trisha Yearwood",
    "song": "How Do I Live?",
    "year": "1997",
    "seconds": "50",
    "lyric": "No sun in my sky",
    "number": "1689",
  },
  {
    "artist": "Ronnie Milsap",
    "song": "How Do I Turn You On?",
    "year": "1986",
    "seconds": "50",
    "lyric": "A fly on your wall",
    "number": "1690",
  },
  {
    "artist": "Mouth & MacNeal",
    "song": "How Do You Do",
    "year": "1972",
    "seconds": "60",
    "lyric": "You still can make me cry",
    "number": "1691",
  },
  {
    "artist": "Toby Keith",
    "song": "How Do You Like Me Now?",
    "year": "1999",
    "seconds": "60",
    "lyric": "And your kids hear you cry",
    "number": "1692",
  },
  {
    "artist": "Kenny Chesney",
    "song": "How Forever Feels",
    "year": "1998",
    "seconds": "40",
    "lyric": "Down on one knee",
    "number": "1693",
  },
  {
    "artist": "Britney Spears",
    "song": "How I Roll",
    "year": "2011",
    "seconds": "50",
    "lyric": "Drink tequila on the rocks",
    "number": "1694",
  },
  {
    "artist": "Brooks & Dunn",
    "song": "How Long Gone",
    "year": "1998",
    "seconds": "40",
    "lyric": "Hearin’ your footsteps fallin’",
    "number": "1695",
  },
  {
    "artist": "Leroy Carr and Scrapper Blackwell",
    "song": "How Long, How Long Blues",
    "year": "1928",
    "seconds": "60",
    "lyric": "I can hear the whistle blow’n",
    "number": "1696",
  },
  {
    "artist": "Buck Owens",
    "song": "How Long Will My Baby Be Gone?",
    "year": "1968",
    "seconds": "40",
    "lyric": "Turn to another man",
    "number": "1697",
  },
  {
    "artist": "Patti Page",
    "song": "(How Much is) That Doggie in the Window",
    "year": "1953",
    "seconds": "50",
    "lyric": "A bowl of little fishies",
    "number": "1698",
  },
  {
    "artist": "Conway Twitty",
    "song": "How Much More Can She Stand?",
    "year": "1971",
    "seconds": "60",
    "lyric": "I can hardly dial the phone",
    "number": "1699",
  },
  {
    "artist": "Dinah Shore",
    "song": "How Soon (Will I Be Seeing You)",
    "year": "1947",
    "seconds": "60",
    "lyric": "I see your star eyes gleaming",
    "number": "1700",
  },
  {
    "artist": "Marvin Gaye",
    "song": "How Sweet It Is",
    "year": "1964",
    "seconds": "50",
    "lyric": "Stop and thank you baby",
    "number": "1701",
  },
  {
    "artist": "The Fray",
    "song": "How to Save a Life",
    "year": "2006",
    "seconds": "60",
    "lyric": "You stare politely right on through",
    "number": "1702",
  },
  {
    "artist": "John Michael Montgomery",
    "song": "How Was I to Know?",
    "year": "1997",
    "seconds": "70",
    "lyric": "Shine your light on my sleepin’ heart",
    "number": "1703",
  },
  {
    "artist": "Reba McEntire",
    "song": "How Was I to Know?",
    "year": "1996",
    "seconds": "50",
    "lyric": "Swept up inside a whirlwind",
    "number": "1704",
  },
  {
    "artist": "Whitney Houston",
    "song": "How Will I Know",
    "year": "1985",
    "seconds": "60",
    "lyric": "Takes me to the clouds above",
    "number": "1705",
  },
  {
    "artist": "Nickelback",
    "song": "How You Remind Me",
    "year": "2001",
    "seconds": "60",
    "lyric": "Handing you a heart worth breaking",
    "number": "1706",
  },
  {
    "artist": "Diamond Rio",
    "song": "How Your Love Makes Me Feel",
    "year": "1997",
    "seconds": "60",
    "lyric": "There’s a cow in the road",
    "number": "1707",
  },
  {
    "artist": "Christina Perri",
    "song": "Human",
    "year": "2013",
    "seconds": "50",
    "lyric": "I can bite my tongue",
    "number": "1708",
  },
  {
    "artist": "The Killers",
    "song": "Human",
    "year": "2008",
    "seconds": "50",
    "lyric": "Wave goodbye, wish me well",
    "number": "1709",
  },
  {
    "artist": "Michael Jackson",
    "song": "Human Nature",
    "year": "1983",
    "seconds": "60",
    "lyric": "Reaching out to touch a stranger",
    "number": "1710",
  },
  {
    "artist": "Bruce Springsteen",
    "song": "Human Touch",
    "year": "1992",
    "seconds": "40",
    "lyric": "And a little paint",
    "number": "1711",
  },
  {
    "artist": "Tim McGraw",
    "song": "Humble and Kind",
    "year": "2016",
    "seconds": "50",
    "lyric": "The keys under the mat",
    "number": "1712",
  },
  {
    "artist": "Eric Carmen",
    "song": "Hungry Eyes",
    "year": "1987",
    "seconds": "60",
    "lyric": "The magic between you and I",
    "number": "1713",
  },
  {
    "artist": "Bruce Springsteen",
    "song": "Hungry Heart",
    "year": "1980",
    "seconds": "40",
    "lyric": "Lay down your money",
    "number": "1714",
  },
  {
    "artist": "Duran Duran",
    "song": "Hungry Like the Wolf",
    "year": "1982",
    "seconds": "50",
    "lyric": "I howl and I whine",
    "number": "1715",
  },
  {
    "artist": "Luke Bryan (ft. Karen Fairchild)",
    "song": "Huntin’, Fishin’ and Lovin’ Every Day",
    "year": "2016",
    "seconds": "60",
    "lyric": "Tuckin’ her hair in my hat",
    "number": "1716",
  },
  {
    "artist": "Donovan",
    "song": "Hurdy Gurdy Man",
    "year": "1968",
    "seconds": "50",
    "lyric": "He’s singing songs of love",
    "number": "1717",
  },
  {
    "artist": "Bob Dylan",
    "song": "Hurricane",
    "year": "1975",
    "seconds": "70",
    "lyric": "The bartender in a pool of blood",
    "number": "1718",
  },
  {
    "artist": "Luke Combs",
    "song": "Hurricane",
    "year": "2016",
    "seconds": "50",
    "lyric": "Rain was driving, thunder, lightning",
    "number": "1719",
  },
  {
    "artist": "Christina Aguilera",
    "song": "Hurt",
    "year": "2006",
    "seconds": "50",
    "lyric": "And see you looking back",
    "number": "1720",
  },
  {
    "artist": "Johnny Cash",
    "song": "Hurt",
    "year": "2002",
    "seconds": "50",
    "lyric": "The needle tears a hole",
    "number": "1721",
  },
  {
    "artist": "The Carpenters",
    "song": "Hurting Each Other",
    "year": "1972",
    "seconds": "50",
    "lyric": "You give gladly to me",
    "number": "1722",
  },
  {
    "artist": "Little Anthony & the Imperials",
    "song": "Hurts So Bad",
    "year": "1964",
    "seconds": "50",
    "lyric": "Standing here, looking at you",
    "number": "1723",
  },
  {
    "artist": "John Mellencamp",
    "song": "Hurts So Good",
    "year": "1982",
    "seconds": "70",
    "lyric": "I long for those young boy days",
    "number": "1724",
  },
  {
    "artist": "Roger Miller",
    "song": "Husbands and Wives",
    "year": "1966",
    "seconds": "40",
    "lyric": "Two broken hearts lonely",
    "number": "1725",
  },
  {
    "artist": "Deep Purple",
    "song": "Hush",
    "year": "1968",
    "seconds": "80",
    "lyric": "She broke my heart but I love her",
    "number": "1726",
  },
  {
    "artist": "Coldplay",
    "song": "Hymn for the Weekend",
    "year": "2016",
    "seconds": "50",
    "lyric": "We’ll shoot across the sky",
    "number": "1727",
  },
  {
    "artist": "Color Me Badd",
    "song": "I Adore Mi Amor",
    "year": "1991",
    "seconds": "60",
    "lyric": "My heart in her hands, baby",
    "number": "1728",
  },
  {
    "artist": "Waylon Jennings",
    "song": "I Ain’t Living Long Like This",
    "year": "1979",
    "seconds": "50",
    "lyric": "He slipped the handcuffs on",
    "number": "1729",
  },
  {
    "artist": "George Jones",
    "song": "I Always Get Lucky With You",
    "year": "1983",
    "seconds": "60",
    "lyric": "When the bars close at two",
    "number": "1730",
  },
  {
    "artist": "Simon & Garfunkel",
    "song": "I Am a Rock",
    "year": "1966",
    "seconds": "60",
    "lyric": "I am shielded in my armor",
    "number": "1731",
  },
  {
    "artist": "Neil Diamond",
    "song": "I Am…I Said",
    "year": "1971",
    "seconds": "50",
    "lyric": "I’m lost between two shores",
    "number": "1732",
  },
  {
    "artist": "Brooks & Dunn",
    "song": "I Am That Man",
    "year": "1996",
    "seconds": "50",
    "lyric": "Hard to be by yourself",
    "number": "1733",
  },
  {
    "artist": "The Beatles",
    "song": "I Am the Walrus",
    "year": "1967",
    "seconds": "50",
    "lyric": "Climbing up the Eiffel Tower",
    "number": "1734",
  },
  {
    "artist": "Helen Reddy",
    "song": "I Am Woman",
    "year": "1971",
    "seconds": "60",
    "lyric": "See me standing toe to toe",
    "number": "1735",
  },
  {
    "artist": "Imagine Dragons",
    "song": "I Bet My Life",
    "year": "2015",
    "seconds": "60",
    "lyric": "I know I took the path",
    "number": "1736",
  },
  {
    "artist": "Billy Swan",
    "song": "I Can Help",
    "year": "1974",
    "seconds": "50",
    "lyric": "I got two strong arms",
    "number": "1737",
  },
  {
    "artist": "All-4-One",
    "song": "I Can Love You Like That",
    "year": "1995",
    "seconds": "60",
    "lyric": "I will give you my heart",
    "number": "1738",
  },
  {
    "artist": "Johnny Nash",
    "song": "I Can See Clearly Now",
    "year": "1972",
    "seconds": "40",
    "lyric": "Nothing but blue sky",
    "number": "1739",
  },
  {
    "artist": "The Who",
    "song": "I Can See for Miles",
    "year": "1967",
    "seconds": "60",
    "lyric": "You’re gonna choke on it too",
    "number": "1740",
  },
  {
    "artist": "Collin Raye",
    "song": "I Can Still Feel You",
    "year": "1998",
    "seconds": "50",
    "lyric": "Someone is standing behind me",
    "number": "1741",
  },
  {
    "artist": "George Strait",
    "song": "I Can Still Make Cheyenne",
    "year": "1996",
    "seconds": "60",
    "lyric": "A chance to write or call",
    "number": "1742",
  },
  {
    "artist": "Vern Gosdin",
    "song": "I Can Tell by the Way You Dance",
    "year": "1984",
    "seconds": "50",
    "lyric": "The rhythm of your body",
    "number": "1743",
  },
  {
    "artist": "Conway Twitty",
    "song": "(I Can’t Believe) She Gives it All to Me",
    "year": "1976",
    "seconds": "60",
    "lyric": "Warm my face with morning kisses",
    "number": "1744",
  },
  {
    "artist": "Sammy Hagar",
    "song": "I Can’t Drive 55",
    "year": "1984",
    "seconds": "60",
    "lyric": "My face wanted dead or alive",
    "number": "1745",
  },
  {
    "artist": "The Temptations",
    "song": "I Can’t Get Next to You",
    "year": "1969",
    "seconds": "60",
    "lyric": "You’re the key to my happiness",
    "number": "1746",
  },
  {
    "artist": "Hank Williams",
    "song": "I Can’t Help It (If I’m Still in Love With You)",
    "year": "1951",
    "seconds": "70",
    "lyric": "And my heart fell at your feet",
    "number": "1747",
  },
  {
    "artist": "The Four Tops",
    "song": "I Can’t Help Myself",
    "year": "1965",
    "seconds": "50",
    "lyric": "Ooh, sugar pie, honey bunch",
    "number": "1748",
  },
  {
    "artist": "Ray Charles",
    "song": "I Can’t Stop Loving You",
    "year": "1962",
    "seconds": "50",
    "lyric": "Time heals a broken heart",
    "number": "1749",
  },
  {
    "artist": "Eagles",
    "song": "I Can’t Tell You Why",
    "year": "1980",
    "seconds": "40",
    "lyric": "Tearing our love apart",
    "number": "1750",
  },
  {
    "artist": "The Bellamy Brothers",
    "song": "I Could Be Persuaded",
    "year": "1990",
    "seconds": "40",
    "lyric": "Looking for a rendezvous",
    "number": "1751",
  },
  {
    "artist": "Prince",
    "song": "I Could Never Take the Place of Your Man",
    "year": "1987",
    "seconds": "60",
    "lyric": "She was standin’ by the bar",
    "number": "1752",
  },
  {
    "artist": "Rodney Crowell",
    "song": "I Couldn’t Leave You If I Tried",
    "year": "1988",
    "seconds": "50",
    "lyric": "And every bridge I cross",
    "number": "1753",
  },
  {
    "artist": "George Strait",
    "song": "I Cross My Heart",
    "year": "1992",
    "seconds": "50",
    "lyric": "All the love and laughter",
    "number": "1754",
  },
  {
    "artist": "Robert Palmer",
    "song": "I Didn’t Mean to Turn You On",
    "year": "1986",
    "seconds": "40",
    "lyric": "You read me wrong",
    "number": "1755",
  },
  {
    "artist": "Peter, Paul & Mary",
    "song": "I Dig Rock and Roll Music",
    "year": "1967",
    "seconds": "60",
    "lyric": "I lay it between the lines",
    "number": "1756",
  },
  {
    "artist": "David Houston",
    "song": "I Do My Swinging at Home",
    "year": "1970",
    "seconds": "50",
    "lyric": "Stumble and hit the floor",
    "number": "1757",
  },
  {
    "artist": "Ed Sheeran and Justin Bieber",
    "song": "I Don’t Care",
    "year": "2019",
    "seconds": "50",
    "lyric": "Wear a suit and tie",
    "number": "1758",
  },
  {
    "artist": "Webb Pierce",
    "song": "I Don’t Care",
    "year": "1955",
    "seconds": "60",
    "lyric": "Not the first one you’ve kissed",
    "number": "1759",
  },
  {
    "artist": "Buck Owens",
    "song": "I Don’t Care (Just as Long as You Love Me)",
    "year": "1964",
    "seconds": "70",
    "lyric": "You know I will stand the test",
    "number": "1760",
  },
  {
    "artist": "Hank Snow",
    "song": "I Don’t Hurt Anymore",
    "year": "1954",
    "seconds": "50",
    "lyric": "All my teardrops are dried",
    "number": "1761",
  },
  {
    "artist": "Conway Twitty",
    "song": "I Don’t Know a Thing About Love",
    "year": "1984",
    "seconds": "50",
    "lyric": "The man in the moon",
    "number": "1762",
  },
  {
    "artist": "Helen Reddy",
    "song": "I Don’t Know How to Love Him",
    "year": "1971",
    "seconds": "70",
    "lyric": "I’d turn my head, I’d back away",
    "number": "1763",
  },
  {
    "artist": "Clarence “Frogman” Henry",
    "song": "(I Don’t Know Why) But I Do",
    "year": "1961",
    "seconds": "60",
    "lyric": "I cry both night and day",
    "number": "1764",
  },
  {
    "artist": "Rosanne Cash",
    "song": "I Don’t Know Why You Don’t Want Me",
    "year": "1985",
    "seconds": "50",
    "lyric": "I’ve got my new shoes",
    "number": "1765",
  },
  {
    "artist": "Charlie Rich",
    "song": "I Don’t See Me in Your Eyes Anymore",
    "year": "1974",
    "seconds": "60",
    "lyric": "Or your heart in your kiss",
    "number": "1766",
  },
  {
    "artist": "Mariah Carey",
    "song": "I Don’t Wanna Cry",
    "year": "1991",
    "seconds": "60",
    "lyric": "Given you my heart and soul",
    "number": "1767",
  },
  {
    "artist": "Tina Turner",
    "song": "I Don’t Wanna Fight",
    "year": "1993",
    "seconds": "70",
    "lyric": "I hear a whisper in the air",
    "number": "1768",
  },
  {
    "artist": "Elton John",
    "song": "I Don’t Wanna Go On With You Like That",
    "year": "1988",
    "seconds": "60",
    "lyric": "Be a feather in your cap",
    "number": "1769",
  },
  {
    "artist": "Taylor Swift & Zayn",
    "song": "I Don’t Wanna Live Forever",
    "year": "2016",
    "seconds": "40",
    "lyric": "I dodged a bullet",
    "number": "1770",
  },
  {
    "artist": "Luke Bryan",
    "song": "I Don’t Want This Night to End",
    "year": "2011",
    "seconds": "50",
    "lyric": "And let your hair down",
    "number": "1771",
  },
  {
    "artist": "Gladys Knight & the Pips",
    "song": "I Don’t Want to Do Wrong",
    "year": "1971",
    "seconds": "50",
    "lyric": "It’s out of my hands",
    "number": "1772",
  },
  {
    "artist": "Aerosmith",
    "song": "I Don’t Want to Miss a Thing",
    "year": "1998",
    "seconds": "60",
    "lyric": "Don’t want to close my eyes",
    "number": "1773",
  },
  {
    "artist": "Rod Stewart",
    "song": "I Don’t Want to Talk About It",
    "year": "1975",
    "seconds": "60",
    "lyric": "Won’t you listen to my heart",
    "number": "1774",
  },
  {
    "artist": "Lee Brice",
    "song": "I Drive Your Truck",
    "year": "2013",
    "seconds": "50",
    "lyric": "Half empty bottle of Gatorade",
    "number": "1775",
  },
  {
    "artist": "Patsy Cline",
    "song": "I Fall to Pieces",
    "year": "1961",
    "seconds": "60",
    "lyric": "Time only adds to the flame",
    "number": "1776",
  },
  {
    "artist": "The Beatles",
    "song": "I Feel Fine",
    "year": "1964",
    "seconds": "70",
    "lyric": "He buys her diamond rings, you know",
    "number": "1777",
  },
  {
    "artist": "Chaka Khan",
    "song": "I Feel For You",
    "year": "1984",
    "seconds": "50",
    "lyric": "Take you in my arms",
    "number": "1778",
  },
  {
    "artist": "Carole King",
    "song": "I Feel the Earth Move",
    "year": "1971",
    "seconds": "60",
    "lyric": "I feel the sky tumbling down",
    "number": "1779",
  },
  {
    "artist": "Bryan Adams (ft. Barbra Streisand)",
    "song": "I Finally Found Someone",
    "year": "1996",
    "seconds": "50",
    "lyric": "Knocks me off my feet",
    "number": "1780",
  },
  {
    "artist": "Bobby Fuller Four",
    "song": "I Fought the Law",
    "year": "1965",
    "seconds": "70",
    "lyric": "I’m breakin’ rocks in the hot sun",
    "number": "1781",
  },
  {
    "artist": "Wilson Pickett",
    "song": "I Found a Love – Part I",
    "year": "1967",
    "seconds": "60",
    "lyric": "She set my soul on fire",
    "number": "1782",
  },
  {
    "artist": "Cher",
    "song": "I Found Someone",
    "year": "1987",
    "seconds": "50",
    "lyric": "And I remember the fire",
    "number": "1783",
  },
  {
    "artist": "Beach Boys",
    "song": "I Get Around",
    "year": "1964",
    "seconds": "50",
    "lyric": "I’m makin’ real good bread",
    "number": "1784",
  },
  {
    "artist": "Tony Martin",
    "song": "I Get Ideas",
    "year": "1951",
    "seconds": "60",
    "lyric": "I’m falling in love with you",
    "number": "1785",
  },
  {
    "artist": "Bill Anderson",
    "song": "I Get the Fever",
    "year": "1966",
    "seconds": "60",
    "lyric": "I hear the midnight trains rollin’",
    "number": "1786",
  },
  {
    "artist": "Kenny Chesney",
    "song": "I Go Back",
    "year": "2004",
    "seconds": "50",
    "lyric": "The smell of Sunday chicken",
    "number": "1787",
  },
  {
    "artist": "Paul Davis",
    "song": "I Go Crazy",
    "year": "1977",
    "seconds": "50",
    "lyric": "Getting over you was slow",
    "number": "1788",
  },
  {
    "artist": "Peter and Gordon",
    "song": "I Go To Pieces",
    "year": "1964",
    "seconds": "60",
    "lyric": "Every time my baby passes by",
    "number": "1789",
  },
  {
    "artist": "Jim Croce",
    "song": "I Got a Name",
    "year": "1973",
    "seconds": "70",
    "lyric": "The singing bird and the croaking toad",
    "number": "1790",
  },
  {
    "artist": "Ray Charles",
    "song": "I Got a Woman",
    "year": "1954",
    "seconds": "50",
    "lyric": "She loves me so tenderly",
    "number": "1791",
  },
  {
    "artist": "Eddie Raven",
    "song": "I Got Mexico",
    "year": "1984",
    "seconds": "40",
    "lyric": "Drinkin’ coke and rum",
    "number": "1792",
  },
  {
    "artist": "Jana Kramer",
    "song": "I Got the Boy",
    "year": "2015",
    "seconds": "60",
    "lyric": "Grown up in your black tux",
    "number": "1793",
  },
  {
    "artist": "Sonny & Cher",
    "song": "I Got You Babe",
    "year": "1965",
    "seconds": "70",
    "lyric": "I got you to hold my hand",
    "number": "1794",
  },
  {
    "artist": "James Brown",
    "song": "I Got You (I Feel Good)",
    "year": "1965",
    "seconds": "60",
    "lyric": "I hold you in my arms",
    "number": "1795",
  },
  {
    "artist": "The Black Eyed Peas",
    "song": "I Gotta Feeling",
    "year": "2009",
    "seconds": "60",
    "lyric": "Tonight’s gonna be a good night",
    "number": "1796",
  },
  {
    "artist": "George Strait",
    "song": "I Gotta Get to You",
    "year": "2010",
    "seconds": "60",
    "lyric": "To sweep you off your feet",
    "number": "1797",
  },
  {
    "artist": "The Oak Ridge Boys",
    "song": "I Guess It Never Hurts to Hurt Sometimes",
    "year": "1984",
    "seconds": "50",
    "lyric": "The years go rolling by",
    "number": "1798",
  },
  {
    "artist": "Elton John",
    "song": "I Guess That’s Why They Call It the Blues",
    "year": "1983",
    "seconds": "60",
    "lyric": "Rolling like thunder under the covers",
    "number": "1799",
  },
  {
    "artist": "Ella Fitzgerald",
    "song": "I Hadn’t Anyone Till You",
    "year": "1949",
    "seconds": "60",
    "lyric": "Cupid took a hand in it",
    "number": "1800",
  },
  {
    "artist": "Gene Autry",
    "song": "I Hang My Head and Cry",
    "year": "1944",
    "seconds": "50",
    "lyric": "I see her passing by",
    "number": "1801",
  },
  {
    "artist": "George Strait",
    "song": "I Hate Everything",
    "year": "2004",
    "seconds": "60",
    "lyric": "So I pulled out my phone",
    "number": "1802",
  },
  {
    "artist": "Dinah Shore",
    "song": "I Hear a Rhapsody",
    "year": "1941",
    "seconds": "70",
    "lyric": "Hold me tight and whisper to me",
    "number": "1803",
  },
  {
    "artist": "The Supremes",
    "song": "I Hear a Symphony",
    "year": "1965",
    "seconds": "60",
    "lyric": "A thousand violins fill the air",
    "number": "1804",
  },
  {
    "artist": "Dave Edmunds",
    "song": "I Hear You Knocking",
    "year": "1971",
    "seconds": "50",
    "lyric": "But you can’t come in",
    "number": "1805",
  },
  {
    "artist": "Marvin Gaye",
    "song": "I Heard it Through the Grapevine",
    "year": "1968",
    "seconds": "50",
    "lyric": "Tears I can’t hold inside",
    "number": "1806",
  },
  {
    "artist": "Olivia Newton-John",
    "song": "I Honestly Love You",
    "year": "1974",
    "seconds": "50",
    "lyric": "It’s coming from my heart",
    "number": "1807",
  },
  {
    "artist": "Gabby Barrett",
    "song": "I Hope",
    "year": "2020",
    "seconds": "60",
    "lyric": "In the middle of a highway",
    "number": "1808",
  },
  {
    "artist": "Lee Ann Womack",
    "song": "I Hope You Dance",
    "year": "2001",
    "seconds": "50",
    "lyric": "You stand beside the ocean",
    "number": "1809",
  },
  {
    "artist": "B.J. Thomas",
    "song": "I Just Can’t Help Believing",
    "year": "1970",
    "seconds": "70",
    "lyric": "This time the girl is gonna stay",
    "number": "1810",
  },
  {
    "artist": "Michael Jackson & Siedah Garrett",
    "song": "I Just Can’t Stop Loving You",
    "year": "1987",
    "seconds": "60",
    "lyric": "I hear harps and angels sing",
    "number": "1811",
  },
  {
    "artist": "Anne Murray",
    "song": "I Just Fall in Love Again",
    "year": "1979",
    "seconds": "70",
    "lyric": "Baby, you take me in your arms",
    "number": "1812",
  },
  {
    "artist": "Terri Clark",
    "song": "I Just Wanna Be Mad",
    "year": "2003",
    "seconds": "60",
    "lyric": "Run your fingers through my hair",
    "number": "1813",
  },
  {
    "artist": "Andy Gibb",
    "song": "I Just Want to Be Your Everything",
    "year": "1977",
    "seconds": "60",
    "lyric": "Your love will turn the key",
    "number": "1814",
  },
  {
    "artist": "Rare Earth",
    "song": "I Just Want to Celebrate",
    "year": "1971",
    "seconds": "50",
    "lyric": "But the sun is shining",
    "number": "1815",
  },
  {
    "artist": "George Strait",
    "song": "I Just Want to Dance With You",
    "year": "1998",
    "seconds": "60",
    "lyric": "Twirl you all around the floor",
    "number": "1816",
  },
  {
    "artist": "Foghat",
    "song": "I Just Want to Make Love to You",
    "year": "1972",
    "seconds": "50",
    "lyric": "To be sad and blue",
    "number": "1817",
  },
  {
    "artist": "Michael McDonald",
    "song": "I Keep Forgettin’ (Every Time You’re Near)",
    "year": "1982",
    "seconds": "50",
    "lyric": "We’re not in love anymore",
    "number": "1818",
  },
  {
    "artist": "Katy Perry",
    "song": "I Kissed a Girl",
    "year": "2008",
    "seconds": "50",
    "lyric": "Taste of her cherry chapstick",
    "number": "1819",
  },
  {
    "artist": "Savage Garden",
    "song": "I Knew I Loved You",
    "year": "1999",
    "seconds": "60",
    "lyric": "A thousand angels dance around you",
    "number": "1820",
  },
  {
    "artist": "Taylor Swift",
    "song": "I Knew You Were Trouble",
    "year": "2012",
    "seconds": "50",
    "lyric": "You took a step back",
    "number": "1821",
  },
  {
    "artist": "Petula Clark",
    "song": "I Know a Place",
    "year": "1965",
    "seconds": "50",
    "lyric": "You’ll be tapping your feet",
    "number": "1822",
  },
  {
    "artist": "The Temptations",
    "song": "(I Know) I’m Losing You",
    "year": "1966",
    "seconds": "50",
    "lyric": "Your touch has gone cold",
    "number": "1823",
  },
  {
    "artist": "Charley Pride",
    "song": "I Know One",
    "year": "1967",
    "seconds": "40",
    "lyric": "After the party’s over",
    "number": "1824",
  },
  {
    "artist": "Busta Rhymes (ft. Flipmode Squad, Mariah Carey)",
    "song": "I Know What You Want",
    "year": "2003",
    "seconds": "40",
    "lyric": "Walkin’ shoulder to shoulder",
    "number": "1825",
  },
  {
    "artist": "Kenny Nolan",
    "song": "I Like Dreamin’",
    "year": "1976",
    "seconds": "50",
    "lyric": "I’m holding you so tight",
    "number": "1826",
  },
  {
    "artist": "Enrique Iglesias (ft. Pitbull)",
    "song": "I Like It",
    "year": "2010",
    "seconds": "50",
    "lyric": "I see you watching me",
    "number": "1827",
  },
  {
    "artist": "Tim McGraw",
    "song": "I Like It, I Love It",
    "year": "1995",
    "seconds": "60",
    "lyric": "I won her that teddy bear",
    "number": "1828",
  },
  {
    "artist": "Chris Kenner",
    "song": "I Like It Like That",
    "year": "1961",
    "seconds": "40",
    "lyric": "They lost my shoes",
    "number": "1829",
  },
  {
    "artist": "Lauv",
    "song": "I Like Me Better",
    "year": "2017",
    "seconds": "50",
    "lyric": "Burning through the hours talking",
    "number": "1830",
  },
  {
    "artist": "Kenny Chesney",
    "song": "I Lost It",
    "year": "2000",
    "seconds": "70",
    "lyric": "I picked myself up off the floor",
    "number": "1831",
  },
  {
    "artist": "Tom T. Hall",
    "song": "I Love",
    "year": "1973",
    "seconds": "70",
    "lyric": "Coffee in a cup, little fuzzy pups",
    "number": "1832",
  },
  {
    "artist": "Eddie Rabbitt",
    "song": "I Love a Rainy Night",
    "year": "1981",
    "seconds": "50",
    "lyric": "It lights up the sky",
    "number": "1833",
  },
  {
    "artist": "Icona Pop (feat. Charli XCX)",
    "song": "I Love It",
    "year": "2012",
    "seconds": "60",
    "lyric": "Crashed my car into the bridge",
    "number": "1834",
  },
  {
    "artist": "The O’Jays",
    "song": "I Love Music (Part 1)",
    "year": "1975",
    "seconds": "40",
    "lyric": "Long as it’s swinging",
    "number": "1835",
  },
  {
    "artist": "Charlie Rich",
    "song": "I Love My Friend",
    "year": "1974",
    "seconds": "50",
    "lyric": "And I’ll call her baby",
    "number": "1836",
  },
  {
    "artist": "Joan Jett and the Blackhearts",
    "song": "I Love Rock & Roll",
    "year": "1981",
    "seconds": "60",
    "lyric": "Another dime in the jukebox, baby",
    "number": "1837",
  },
  {
    "artist": "John Michael Montgomery",
    "song": "I Love the Way You Love Me",
    "year": "1993",
    "seconds": "60",
    "lyric": "Your fingers run through my hair",
    "number": "1838",
  },
  {
    "artist": "Toby Keith",
    "song": "I Love This Bar",
    "year": "2003",
    "seconds": "70",
    "lyric": "Puts a big smile on my face",
    "number": "1839",
  },
  {
    "artist": "Cliff Richard and the Shadows",
    "song": "I Love You",
    "year": "1960",
    "seconds": "60",
    "lyric": "The apples hanging on a tree",
    "number": "1840",
  },
  {
    "artist": "Jim Reeves and Ginny Wright",
    "song": "I Love You",
    "year": "1954",
    "seconds": "50",
    "lyric": "The wedding bells are ringing",
    "number": "1841",
  },
  {
    "artist": "Leon Payne",
    "song": "I Love You Because",
    "year": "1949",
    "seconds": "40",
    "lyric": "My heart is lighter",
    "number": "1842",
  },
  {
    "artist": "Dinah Shore",
    "song": "(I Love You) For Sentimental Reasons",
    "year": "1947",
    "seconds": "50",
    "lyric": "I’ll give you my heart",
    "number": "1843",
  },
  {
    "artist": "Conway Twitty",
    "song": "I Love You More Today",
    "year": "1969",
    "seconds": "40",
    "lyric": "Kissed your tears away",
    "number": "1844",
  },
  {
    "artist": "Conway Twitty",
    "song": "I May Never Get to Heaven",
    "year": "1979",
    "seconds": "60",
    "lyric": "But I once came mighty close",
    "number": "1845",
  },
  {
    "artist": "Rascal Flatts",
    "song": "I Melt",
    "year": "2003",
    "seconds": "50",
    "lyric": "One little stare from you",
    "number": "1846",
  },
  {
    "artist": "Modern English",
    "song": "I Melt With You",
    "year": "1982",
    "seconds": "40",
    "lyric": "The future’s open wide",
    "number": "1847",
  },
  {
    "artist": "Darryl Worley",
    "song": "I Miss My Friend",
    "year": "2002",
    "seconds": "50",
    "lyric": "The power of your kiss",
    "number": "1848",
  },
  {
    "artist": "Blink 182",
    "song": "I Miss You",
    "year": "2004",
    "seconds": "50",
    "lyric": "The angel from my nightmare",
    "number": "1849",
  },
  {
    "artist": "P. Diddy (ft. Usher and Loon)",
    "song": "I Need a Girl",
    "year": "2002",
    "seconds": "50",
    "lyric": "Ride right here with me",
    "number": "1850",
  },
  {
    "artist": "John Mellencamp",
    "song": "I Need a Lover",
    "year": "1978",
    "seconds": "40",
    "lyric": "Won’t drive me crazy",
    "number": "1851",
  },
  {
    "artist": "America",
    "song": "I Need You",
    "year": "1972",
    "seconds": "50",
    "lyric": "The flower needs the rain",
    "number": "1852",
  },
  {
    "artist": "Calvin Harris (ft. Ellie Goulding)",
    "song": "I Need Your Love",
    "year": "2013",
    "seconds": "60",
    "lyric": "Hold me in your arms again",
    "number": "1853",
  },
  {
    "artist": "The Flamingos",
    "song": "I Only Have Eyes for You",
    "year": "1959",
    "seconds": "80",
    "lyric": "I can’t see a thing in the sky",
    "number": "1854",
  },
  {
    "artist": "Nina Simone",
    "song": "I Put a Spell on You",
    "year": "1965",
    "seconds": "60",
    "lyric": "You know I can’t stand it",
    "number": "1855",
  },
  {
    "artist": "Dolly Parton",
    "song": "I Really Got the Feeling",
    "year": "1978",
    "seconds": "70",
    "lyric": "I’ll love you ’til my dying day",
    "number": "1856",
  },
  {
    "artist": "Carly Rae Jepsen",
    "song": "I Really Like You",
    "year": "2015",
    "seconds": "40",
    "lyric": "Late night watching television",
    "number": "1857",
  },
  {
    "artist": "Tony Martin and Fran Warren",
    "song": "I Said My Pajamas (and Put on My Pray’rs)",
    "year": "1950",
    "seconds": "40",
    "lyric": "I buttered my tie",
    "number": "1858",
  },
  {
    "artist": "Dwight Yoakam",
    "song": "I Sang Dixie",
    "year": "1988",
    "seconds": "60",
    "lyric": "Laid his head against my chest",
    "number": "1859",
  },
  {
    "artist": "George Strait",
    "song": "I Saw God Today",
    "year": "2008",
    "seconds": "50",
    "lyric": "Pushing up through the concrete",
    "number": "1860",
  },
  {
    "artist": "The Mamas & the Papas",
    "song": "I Saw Her Again",
    "year": "1966",
    "seconds": "60",
    "lyric": "I’m in way over my head",
    "number": "1861",
  },
  {
    "artist": "The Beatles",
    "song": "I Saw Her Standing There",
    "year": "1963",
    "seconds": "50",
    "lyric": "Well, my heart went boom",
    "number": "1862",
  },
  {
    "artist": "John Mellencamp",
    "song": "I Saw Mommy Kissing Santa Claus",
    "year": "1987",
    "seconds": "50",
    "lyric": "In my bedroom fast asleep",
    "number": "1863",
  },
  {
    "artist": "Todd Rundgren",
    "song": "I Saw the Light",
    "year": "1972",
    "seconds": "50",
    "lyric": "And a feeling hit me",
    "number": "1864",
  },
  {
    "artist": "Aretha Franklin",
    "song": "I Say a Little Prayer",
    "year": "1968",
    "seconds": "60",
    "lyric": "Before I put on my makeup",
    "number": "1865",
  },
  {
    "artist": "Conway Twitty",
    "song": "I See the Want To in Your Eyes",
    "year": "1974",
    "seconds": "60",
    "lyric": "Sparkling little diamond on your hand",
    "number": "1866",
  },
  {
    "artist": "Leona Lewis",
    "song": "I See You",
    "year": "2009",
    "seconds": "60",
    "lyric": "I see me through your eyes",
    "number": "1867",
  },
  {
    "artist": "Luke Bryan",
    "song": "I See You",
    "year": "2014",
    "seconds": "60",
    "lyric": "No, I can’t close my eyes",
    "number": "1868",
  },
  {
    "artist": "Eric Clapton",
    "song": "I Shot the Sheriff",
    "year": "1974",
    "seconds": "50",
    "lyric": "Trying to track me down",
    "number": "1869",
  },
  {
    "artist": "Emerson Drive",
    "song": "I Should Be Sleeping",
    "year": "2002",
    "seconds": "60",
    "lyric": "A girl to call me honey",
    "number": "1870",
  },
  {
    "artist": "The Beatles",
    "song": "I Should Have Known Better",
    "year": "1964",
    "seconds": "50",
    "lyric": "Say you love me too",
    "number": "1871",
  },
  {
    "artist": "Godsmack",
    "song": "I Stand Alone",
    "year": "2002",
    "seconds": "60",
    "lyric": "Feeling your sting down inside me",
    "number": "1872",
  },
  {
    "artist": "Brenda K. Starr",
    "song": "I Still Believe",
    "year": "1988",
    "seconds": "50",
    "lyric": "But time melts into nothing",
    "number": "1873",
  },
  {
    "artist": "Loretta Lynn & Conway Twitty",
    "song": "I Still Believe in Waltzes",
    "year": "1981",
    "seconds": "50",
    "lyric": "I turned over and said",
    "number": "1874",
  },
  {
    "artist": "U2",
    "song": "I Still Haven’t Found What I’m Looking For",
    "year": "1987",
    "seconds": "50",
    "lyric": "I have kissed honey lips",
    "number": "1875",
  },
  {
    "artist": "All-4-One",
    "song": "I Swear",
    "year": "1994",
    "seconds": "80",
    "lyric": "The moon and the stars in the sky",
    "number": "1876",
  },
  {
    "artist": "ZZ Top",
    "song": "I Thank You",
    "year": "1980",
    "seconds": "60",
    "lyric": "I’ve never felt kisses so good",
    "number": "1877",
  },
  {
    "artist": "Randy Newman",
    "song": "I Think It’s Going To Rain Today",
    "year": "1968",
    "seconds": "50",
    "lyric": "Broken windows and empty hallways",
    "number": "1878",
  },
  {
    "artist": "Tommy James & the Shondells",
    "song": "I Think We’re Alone Now",
    "year": "1967",
    "seconds": "50",
    "lyric": "The beating of our hearts",
    "number": "1879",
  },
  {
    "artist": "Keith Urban",
    "song": "I Told You So",
    "year": "2007",
    "seconds": "50",
    "lyric": "To see you here tonight",
    "number": "1880",
  },
  {
    "artist": "Patty Loveless",
    "song": "I Try to Think About Elvis",
    "year": "1994",
    "seconds": "50",
    "lyric": "Slowly rolling out of sight",
    "number": "1881",
  },
  {
    "artist": "Marty Robbins",
    "song": "I Walk Alone",
    "year": "1968",
    "seconds": "70",
    "lyric": "The flame of love is brightly burnin’",
    "number": "1882",
  },
  {
    "artist": "Johnny Cash",
    "song": "I Walk the Line",
    "year": "1956",
    "seconds": "60",
    "lyric": "Eyes wide open all the time",
    "number": "1883",
  },
  {
    "artist": "The Ramones",
    "song": "I Wanna Be Sedated",
    "year": "1980",
    "seconds": "50",
    "lyric": "Put me on a plane",
    "number": "1884",
  },
  {
    "artist": "Prince",
    "song": "I Wanna Be Your Lover",
    "year": "1979",
    "seconds": "60",
    "lyric": "Turn you on, turn you out",
    "number": "1885",
  },
  {
    "artist": "Whitney Houston",
    "song": "I Wanna Dance With Somebody",
    "year": "1987",
    "seconds": "60",
    "lyric": "On a love that burns hot",
    "number": "1886",
  },
  {
    "artist": "Rose Royce",
    "song": "I Wanna Get Next to You",
    "year": "1977",
    "seconds": "50",
    "lyric": "Girl, I’m spendin’ my dimes",
    "number": "1887",
  },
  {
    "artist": "Glen Campbell",
    "song": "I Wanna Live",
    "year": "1968",
    "seconds": "40",
    "lyric": "The ocean has waves",
    "number": "1888",
  },
  {
    "artist": "Eddy Arnold",
    "song": "I Wanna Play House With You",
    "year": "1951",
    "seconds": "60",
    "lyric": "Come here to me, my little chickadee",
    "number": "1889",
  },
  {
    "artist": "Toby Keith",
    "song": "I Wanna Talk About Me",
    "year": "2001",
    "seconds": "60",
    "lyric": "And the dimples on your chin",
    "number": "1890",
  },
  {
    "artist": "Queen",
    "song": "I Want It All",
    "year": "1989",
    "seconds": "40",
    "lyric": "A one track mind",
    "number": "1891",
  },
  {
    "artist": "Backstreet Boys",
    "song": "I Want It That Way",
    "year": "1999",
    "seconds": "50",
    "lyric": "Ain’t nothin’ but a heartache",
    "number": "1892",
  },
  {
    "artist": "Queen",
    "song": "I Want to Break Free",
    "year": "1984",
    "seconds": "50",
    "lyric": "So baby, can’t you see?",
    "number": "1893",
  },
  {
    "artist": "The Early November",
    "song": "I Want to Hear You Sad",
    "year": "2002",
    "seconds": "50",
    "lyric": "We’re swerving off the road",
    "number": "1894",
  },
  {
    "artist": "The Beatles",
    "song": "I Want to Hold Your Hand",
    "year": "1964",
    "seconds": "40",
    "lyric": "I feel happy inside",
    "number": "1895",
  },
  {
    "artist": "Fats Domino",
    "song": "I Want to Walk You Home",
    "year": "1959",
    "seconds": "60",
    "lyric": "I love to hear you talk",
    "number": "1896",
  },
  {
    "artist": "Bob Dylan",
    "song": "I Want You",
    "year": "1966",
    "seconds": "50",
    "lyric": "The lonesome organ grinder cries",
    "number": "1897",
  },
  {
    "artist": "NSYNC",
    "song": "I Want You Back",
    "year": "1996",
    "seconds": "50",
    "lyric": "I’m going crazy without you",
    "number": "1898",
  },
  {
    "artist": "The Jackson 5",
    "song": "I Want You Back",
    "year": "1970",
    "seconds": "70",
    "lyric": "I was blind to let you go",
    "number": "1899",
  },
  {
    "artist": "Elvis Presley",
    "song": "I Want You, I Need You, I Love You",
    "year": "1956",
    "seconds": "60",
    "lyric": "Hold me close, hold me tight",
    "number": "1900",
  },
  {
    "artist": "Cheap Trick",
    "song": "I Want You to Want Me",
    "year": "1979",
    "seconds": "90",
    "lyric": "Didn’t I, didn’t I, didn’t I see you crying?",
    "number": "1901",
  },
  {
    "artist": "Stevie Wonder",
    "song": "I Was Made to Love Her",
    "year": "1967",
    "seconds": "70",
    "lyric": "I was knee high to a chicken",
    "number": "1902",
  },
  {
    "artist": "Dolly Parton",
    "song": "I Will Always Love You",
    "year": "1974",
    "seconds": "40",
    "lyric": "Goodbye, please don’t cry",
    "number": "1903",
  },
  {
    "artist": "Dan Seals",
    "song": "I Will Be There",
    "year": "1987",
    "seconds": "40",
    "lyric": "You’ll stop and stare",
    "number": "1904",
  },
  {
    "artist": "Little Peggy March",
    "song": "I Will Follow Him",
    "year": "1963",
    "seconds": "40",
    "lyric": "An ocean too deep",
    "number": "1905",
  },
  {
    "artist": "Amy Grant",
    "song": "I Will Remember You",
    "year": "1992",
    "seconds": "60",
    "lyric": "True love is frozen in time",
    "number": "1906",
  },
  {
    "artist": "Sarah McLachlan",
    "song": "I Will Remember You",
    "year": "1995",
    "seconds": "70",
    "lyric": "I’m so tired but I can’t sleep",
    "number": "1907",
  },
  {
    "artist": "Gloria Gaynor",
    "song": "I Will Survive",
    "year": "1979",
    "seconds": "40",
    "lyric": "And I grew strong",
    "number": "1908",
  },
  {
    "artist": "Mumford & Sons",
    "song": "I Will Wait",
    "year": "2012",
    "seconds": "50",
    "lyric": "My head alongside my heart",
    "number": "1909",
  },
  {
    "artist": "Stevie Wonder",
    "song": "I Wish",
    "year": "1976",
    "seconds": "60",
    "lyric": "You playin’ doctor with that girl",
    "number": "1910",
  },
  {
    "artist": "Dinah Shore",
    "song": "I Wish I Didn’t Love You So",
    "year": "1947",
    "seconds": "50",
    "lyric": "My heart on the mend",
    "number": "1911",
  },
  {
    "artist": "Gene Autry",
    "song": "I Wish I Had Never Met Sunshine",
    "year": "1946",
    "seconds": "50",
    "lyric": "I bought her a diamond",
    "number": "1912",
  },
  {
    "artist": "The Temptations",
    "song": "I Wish It Would Rain",
    "year": "1968",
    "seconds": "50",
    "lyric": "Pressed against the window pane",
    "number": "1913",
  },
  {
    "artist": "The Oak Ridge Boys",
    "song": "I Wish You Could Have Turned My Head",
    "year": "1982",
    "seconds": "50",
    "lyric": "And left my heart alone",
    "number": "1914",
  },
  {
    "artist": "Tommy Boyce & Bobby Hart",
    "song": "I Wonder What She’s Doing Tonight?",
    "year": "1968",
    "seconds": "60",
    "lyric": "I want to be your friend",
    "number": "1915",
  },
  {
    "artist": "Frank Adams and Will Hough",
    "song": "I Wonder Who’s Kissing Her Now?",
    "year": "1909",
    "seconds": "40",
    "lyric": "Breathing sighs, telling lies",
    "number": "1916",
  },
  {
    "artist": "Tom Petty & the Heartbreakers",
    "song": "I Won’t Back Down",
    "year": "1989",
    "seconds": "50",
    "lyric": "No, I’ll stand my ground",
    "number": "1917",
  },
  {
    "artist": "Jim Reeves",
    "song": "I Won’t Come In While He’s There",
    "year": "1967",
    "seconds": "50",
    "lyric": "But his car’s parked there",
    "number": "1918",
  },
  {
    "artist": "Rascal Flatts",
    "song": "I Won’t Let Go",
    "year": "2011",
    "seconds": "50",
    "lyric": "I will hold you tight",
    "number": "1919",
  },
  {
    "artist": "Tanya Tucker (ft. Paul Davis & Paul Overstreet)",
    "song": "I Won’t Take Less Than Your Love",
    "year": "1987",
    "seconds": "40",
    "lyric": "Rest your weary head",
    "number": "1920",
  },
  {
    "artist": "Prince",
    "song": "I Would Die 4 U",
    "year": "1984",
    "seconds": "40",
    "lyric": "I’m not your lover",
    "number": "1921",
  },
  {
    "artist": "Ronnie Milsap",
    "song": "I Wouldn’t Have Missed It For the World",
    "year": "1981",
    "seconds": "60",
    "lyric": "Even though I lost you girl",
    "number": "1922",
  },
  {
    "artist": "Barry Manilow",
    "song": "I Write the Songs",
    "year": "1975",
    "seconds": "50",
    "lyric": "Make the young girls cry",
    "number": "1923",
  },
  {
    "artist": "Van Halen",
    "song": "Ice Cream Man",
    "year": "1978",
    "seconds": "60",
    "lyric": "Stop me when I’m passin’ by",
    "number": "1924",
  },
  {
    "artist": "Vanilla Ice",
    "song": "Ice Ice Baby",
    "year": "1989",
    "seconds": "50",
    "lyric": "You better hit bull’s eye",
    "number": "1925",
  },
  {
    "artist": "Ronnie Milsap",
    "song": "(I’d Be) A Legend in My Time",
    "year": "1974",
    "seconds": "40",
    "lyric": "In love’s crazy game",
    "number": "1926",
  },
  {
    "artist": "Meat Loaf",
    "song": "I’d Do Anything For Love",
    "year": "1993",
    "seconds": "70",
    "lyric": "As long as the fires are burning",
    "number": "1927",
  },
  {
    "artist": "Dua Lipa",
    "song": "IDGAF",
    "year": "2018",
    "seconds": "50",
    "lyric": "So I cut you off",
    "number": "1928",
  },
  {
    "artist": "George Strait",
    "song": "I’d Like to Have That One Back",
    "year": "1993",
    "seconds": "50",
    "lyric": "Stopped right in her tracks",
    "number": "1929",
  },
  {
    "artist": "The New Seekers",
    "song": "I’d Like to Teach the World to Sing",
    "year": "1972",
    "seconds": "60",
    "lyric": "Grow apple trees and honey bees",
    "number": "1930",
  },
  {
    "artist": "Conway Twitty",
    "song": "I’d Love to Lay You Down",
    "year": "1980",
    "seconds": "60",
    "lyric": "Your hair all up in curlers",
    "number": "1931",
  },
  {
    "artist": "Etta James",
    "song": "I’d Rather Go Blind",
    "year": "1968",
    "seconds": "60",
    "lyric": "Sitting here thinkin’ of your kiss",
    "number": "1932",
  },
  {
    "artist": "Charley Pride",
    "song": "I’d Rather Love You",
    "year": "1971",
    "seconds": "60",
    "lyric": "The thrill of your sweet lips",
    "number": "1933",
  },
  {
    "artist": "England Dan & John Ford Coley",
    "song": "I’d Really Love to See You Tonight",
    "year": "1976",
    "seconds": "80",
    "lyric": "A warm wind blowing, the stars are out",
    "number": "1934",
  },
  {
    "artist": "The Grass Roots",
    "song": "I’d Wait a Million Years",
    "year": "1969",
    "seconds": "40",
    "lyric": "Swim the deepest sea",
    "number": "1935",
  },
  {
    "artist": "BTS (ft. Nicki Minaj)",
    "song": "Idol",
    "year": "2018",
    "seconds": "60",
    "lyric": "You can’t stop me lovin’ myself",
    "number": "1936",
  },
  {
    "artist": "Billie Eilish",
    "song": "Idontwannabeyouanymore",
    "year": "2017",
    "seconds": "50",
    "lyric": "Swimming pools filled by models",
    "number": "1937",
  },
  {
    "artist": "Bread",
    "song": "If",
    "year": "1971",
    "seconds": "50",
    "lyric": "Spinning slowly down to die",
    "number": "1938",
  },
  {
    "artist": "John Waite",
    "song": "If Anybody Had a Heart",
    "year": "1986",
    "seconds": "60",
    "lyric": "You may stumble, you may fall",
    "number": "1939",
  },
  {
    "artist": "Etta James & Harvey Fuqua",
    "song": "If I Can’t Have You",
    "year": "1960",
    "seconds": "50",
    "lyric": "The way you squeeze me",
    "number": "1940",
  },
  {
    "artist": "Yvonne Elliman",
    "song": "If I Can’t Have You",
    "year": "1977",
    "seconds": "50",
    "lyric": "I don’t want nobody, baby",
    "number": "1941",
  },
  {
    "artist": "Cher",
    "song": "If I Could Turn Back Time",
    "year": "1989",
    "seconds": "60",
    "lyric": "I know I made you cry",
    "number": "1942",
  },
  {
    "artist": "The Band Perry",
    "song": "If I Die Young",
    "year": "2010",
    "seconds": "50",
    "lyric": "In the river at dawn",
    "number": "1943",
  },
  {
    "artist": "The Beatles",
    "song": "If I Fell",
    "year": "1964",
    "seconds": "50",
    "lyric": "And I would be sad",
    "number": "1944",
  },
  {
    "artist": "Pete Seeger & Lee Hayes",
    "song": "If I Had a Hammer",
    "year": "1950",
    "seconds": "60",
    "lyric": "I’d ring it in the morning",
    "number": "1945",
  },
  {
    "artist": "Barenaked Ladies",
    "song": "If I Had a Million Dollars",
    "year": "1992",
    "seconds": "60",
    "lyric": "Buy you furniture for your house",
    "number": "1946",
  },
  {
    "artist": "Jack Johnson",
    "song": "If I Had Eyes",
    "year": "2006",
    "seconds": "60",
    "lyric": "In the back of my head",
    "number": "1947",
  },
  {
    "artist": "George Strait",
    "song": "If I Know Me",
    "year": "1991",
    "seconds": "50",
    "lyric": "When I slammed the door",
    "number": "1948",
  },
  {
    "artist": "Cat Stevens",
    "song": "If I Laugh",
    "year": "1971",
    "seconds": "60",
    "lyric": "And sleep at night – and dream",
    "number": "1949",
  },
  {
    "artist": "Perry Como",
    "song": "If I Loved You",
    "year": "1945",
    "seconds": "40",
    "lyric": "But afraid and shy",
    "number": "1950",
  },
  {
    "artist": "Darius Rucker",
    "song": "If I Told You",
    "year": "2017",
    "seconds": "40",
    "lyric": "A million miles away",
    "number": "1951",
  },
  {
    "artist": "Beyoncé",
    "song": "If I Were a Boy",
    "year": "2008",
    "seconds": "60",
    "lyric": "I would turn off my phone",
    "number": "1952",
  },
  {
    "artist": "Johnny Cash & June Carter",
    "song": "If I Were a Carpenter",
    "year": "1970",
    "seconds": "50",
    "lyric": "At a mill wheel grinding",
    "number": "1953",
  },
  {
    "artist": "Gladys Knight & the Pips",
    "song": "If I Were Your Woman",
    "year": "1970",
    "seconds": "50",
    "lyric": "She tears you down, darling",
    "number": "1954",
  },
  {
    "artist": "Tanya Tucker",
    "song": "If It Don’t Come Easy",
    "year": "1988",
    "seconds": "60",
    "lyric": "She’s got a heart of gold",
    "number": "1955",
  },
  {
    "artist": "Sheryl Crow",
    "song": "If It Makes You Happy",
    "year": "1996",
    "seconds": "60",
    "lyric": "Scrape the mold off the bread",
    "number": "1956",
  },
  {
    "artist": "Luther Ingram",
    "song": "(If Loving You is Wrong) I Don’t Want to Be Right",
    "year": "1972",
    "seconds": "60",
    "lyric": "A wife and two little children",
    "number": "1957",
  },
  {
    "artist": "Faith Hill",
    "song": "If My Heart Had Wings",
    "year": "2001",
    "seconds": "50",
    "lyric": "I stare through this windshield",
    "number": "1958",
  },
  {
    "artist": "Bob Dylan",
    "song": "If Not For You",
    "year": "1971",
    "seconds": "40",
    "lyric": "My sky would fall",
    "number": "1959",
  },
  {
    "artist": "Perry Como",
    "song": "If (They Made Me a King)",
    "year": "1950",
    "seconds": "70",
    "lyric": "I’d be but a slave to you",
    "number": "1960",
  },
  {
    "artist": "Garth Brooks",
    "song": "If Tomorrow Never Comes",
    "year": "1989",
    "seconds": "60",
    "lyric": "Lie awake and watch her sleeping",
    "number": "1961",
  },
  {
    "artist": "Merle Haggard",
    "song": "If We Make it Through December",
    "year": "1973",
    "seconds": "50",
    "lyric": "The coldest time of winter",
    "number": "1962",
  },
  {
    "artist": "Faron Young",
    "song": "If You Ain’t Lovin’ (You Ain’t Livin’)",
    "year": "1954",
    "seconds": "50",
    "lyric": "A bucket full of money",
    "number": "1963",
  },
  {
    "artist": "Céline Dion",
    "song": "If You Asked Me To",
    "year": "1992",
    "seconds": "50",
    "lyric": "Near my heart again, darlin’",
    "number": "1964",
  },
  {
    "artist": "Rosanne Cash",
    "song": "If You Change Your Mind",
    "year": "1988",
    "seconds": "50",
    "lyric": "And tears your world apart",
    "number": "1965",
  },
  {
    "artist": "Gordon Lightfoot",
    "song": "If You Could Read My Mind",
    "year": "1971",
    "seconds": "60",
    "lyric": "Walk away like a movie star",
    "number": "1966",
  },
  {
    "artist": "Harold Melvin & the Blue Notes",
    "song": "If You Don’t Know Me By Now",
    "year": "1972",
    "seconds": "60",
    "lyric": "We can’t see eye to eye",
    "number": "1967",
  },
  {
    "artist": "Orchestral Manoeuvres in the Dark (OMD)",
    "song": "If You Leave",
    "year": "1986",
    "seconds": "80",
    "lyric": "I touch you once, I touch you twice",
    "number": "1968",
  },
  {
    "artist": "Chicago",
    "song": "If You Leave Me Now",
    "year": "1976",
    "seconds": "80",
    "lyric": "You’ll take away the biggest part of me",
    "number": "1969",
  },
  {
    "artist": "Olivia Newton-John",
    "song": "If You Love Me (Let Me Know)",
    "year": "1974",
    "seconds": "60",
    "lyric": "Open wide to hold me closer",
    "number": "1970",
  },
  {
    "artist": "Wilson Pickett",
    "song": "If You Need Me",
    "year": "1963",
    "seconds": "50",
    "lyric": "Deep down in my heart",
    "number": "1971",
  },
  {
    "artist": "Stevie Wonder",
    "song": "If You Really Love Me",
    "year": "1971",
    "seconds": "70",
    "lyric": "I see the light of your smile",
    "number": "1972",
  },
  {
    "artist": "Reba McEntire, Brooks & Dunn",
    "song": "If You See Him/If You See Her",
    "year": "1998",
    "seconds": "60",
    "lyric": "Deep down the fire still burns",
    "number": "1973",
  },
  {
    "artist": "Alabama",
    "song": "If You’re Gonna Play in Texas",
    "year": "1984",
    "seconds": "50",
    "lyric": "So rosin up that bow",
    "number": "1974",
  },
  {
    "artist": "Shania Twain",
    "song": "(If You’re Not In It For Love) I’m Outta Here",
    "year": "1995",
    "seconds": "60",
    "lyric": "The back seat of my car",
    "number": "1975",
  },
  {
    "artist": "Tim McGraw",
    "song": "If You’re Reading This",
    "year": "2007",
    "seconds": "50",
    "lyric": "I’m laying down my gun",
    "number": "1976",
  },
  {
    "artist": "George Strait",
    "song": "If You’re Thinking You Want a Stranger",
    "year": "1982",
    "seconds": "60",
    "lyric": "Picked up some roses and champagne",
    "number": "1977",
  },
  {
    "artist": "John Michael Montgomery",
    "song": "If You’ve Got Love",
    "year": "1994",
    "seconds": "50",
    "lyric": "The devil’s at your door",
    "number": "1978",
  },
  {
    "artist": "Lefty Frizzell",
    "song": "If You’ve Got the Money, I’ve Got the Time",
    "year": "1950",
    "seconds": "50",
    "lyric": "Dance, drink beer and wine",
    "number": "1979",
  },
  {
    "artist": "Journey",
    "song": "I’ll Be Alright Without You",
    "year": "1987",
    "seconds": "60",
    "lyric": "I can still see your face",
    "number": "1980",
  },
  {
    "artist": "The Spinners",
    "song": "I’ll Be Around",
    "year": "1973",
    "seconds": "50",
    "lyric": "Our fork in the road",
    "number": "1981",
  },
  {
    "artist": "Marvin Gaye",
    "song": "I’ll Be Doggone",
    "year": "1965",
    "seconds": "60",
    "lyric": "Warm as a breath of spring",
    "number": "1982",
  },
  {
    "artist": "The Brothers Johnson",
    "song": "I’ll Be Good To You",
    "year": "1976",
    "seconds": "50",
    "lyric": "Break up our happy home",
    "number": "1983",
  },
  {
    "artist": "Bing Crosby",
    "song": "I’ll Be Home For Christmas",
    "year": "1943",
    "seconds": "50",
    "lyric": "And presents on the tree",
    "number": "1984",
  },
  {
    "artist": "The Jackson 5",
    "song": "I’ll Be There",
    "year": "1970",
    "seconds": "70",
    "lyric": "I’ll reach out my hand to you",
    "number": "1985",
  },
  {
    "artist": "The Rembrandts",
    "song": "I’ll Be There For You",
    "year": "1995",
    "seconds": "60",
    "lyric": "When the rain starts to pour",
    "number": "1986",
  },
  {
    "artist": "The Oak Ridge Boys",
    "song": "I’ll Be True to You",
    "year": "1978",
    "seconds": "60",
    "lyric": "He longed to hold her close",
    "number": "1987",
  },
  {
    "artist": "Bob Dylan",
    "song": "I’ll Be Your Baby Tonight",
    "year": "1967",
    "seconds": "60",
    "lyric": "Close your eyes, close the door",
    "number": "1988",
  },
  {
    "artist": "Tanya Tucker",
    "song": "I’ll Come Back as Another Woman",
    "year": "1986",
    "seconds": "50",
    "lyric": "The secrets to your heart",
    "number": "1989",
  },
  {
    "artist": "The Beatles",
    "song": "I’ll Follow the Sun",
    "year": "1964",
    "seconds": "60",
    "lyric": "In the end you will know",
    "number": "1990",
  },
  {
    "artist": "Andy Griggs",
    "song": "I’ll Go Crazy",
    "year": "1999",
    "seconds": "50",
    "lyric": "I’ll go down in flames",
    "number": "1991",
  },
  {
    "artist": "Marty Robbins",
    "song": "I’ll Go On Alone",
    "year": "1952",
    "seconds": "50",
    "lyric": "Traveling down two different roads",
    "number": "1992",
  },
  {
    "artist": "Jim Croce",
    "song": "I’ll Have to Say I Love You in a Song",
    "year": "1974",
    "seconds": "60",
    "lyric": "Every time the time was right",
    "number": "1993",
  },
  {
    "artist": "Ricky Van Shelton",
    "song": "I’ll Leave This World Loving You",
    "year": "1988",
    "seconds": "50",
    "lyric": "Before my life is over",
    "number": "1994",
  },
  {
    "artist": "Dionne Warwick",
    "song": "I’ll Never Fall in Love Again",
    "year": "1970",
    "seconds": "60",
    "lyric": "A pin to burst your bubble",
    "number": "1995",
  },
  {
    "artist": "The Seekers",
    "song": "I’ll Never Find Another You",
    "year": "1965",
    "seconds": "60",
    "lyric": "If you will hold my hand",
    "number": "1996",
  },
  {
    "artist": "George Jones",
    "song": "I’ll Share My World With You",
    "year": "1969",
    "seconds": "50",
    "lyric": "Give you my two arms",
    "number": "1997",
  },
  {
    "artist": "The Pretenders",
    "song": "I’ll Stand By You",
    "year": "1994",
    "seconds": "50",
    "lyric": "Tears are in your eyes",
    "number": "1998",
  },
  {
    "artist": "Jean Shepard & Ray Pillow",
    "song": "I’ll Take the Dog",
    "year": "1966",
    "seconds": "50",
    "lyric": "Dirty dishes in the sink",
    "number": "1999",
  },
  {
    "artist": "The Staple Singers",
    "song": "I’ll Take You There",
    "year": "1972",
    "seconds": "40",
    "lyric": "Play your piano now",
    "number": "2000",
  },
  {
    "artist": "Mark Chesnutt",
    "song": "I’ll Think of Something",
    "year": "1992",
    "seconds": "50",
    "lyric": "She’s still on my mind",
    "number": "2001",
  },
  {
    "artist": "Van Halen",
    "song": "I’ll Wait",
    "year": "1984",
    "seconds": "50",
    "lyric": "Her eyes don’t follow me",
    "number": "2002",
  },
  {
    "artist": "Dinah Shore",
    "song": "I’ll Walk Alone",
    "year": "1944",
    "seconds": "50",
    "lyric": "Your love and your kisses",
    "number": "2003",
  },
  {
    "artist": "Billie Eilish",
    "song": "Ilomilo",
    "year": "2020",
    "seconds": "60",
    "lyric": "To get too close to stars",
    "number": "2004",
  },
  {
    "artist": "The Monkees",
    "song": "I’m a Believer",
    "year": "1966",
    "seconds": "60",
    "lyric": "I needed sunshine, I got rain",
    "number": "2005",
  },
  {
    "artist": "The Beatles",
    "song": "I’m a Loser",
    "year": "1964",
    "seconds": "50",
    "lyric": "I am wearing a frown",
    "number": "2006",
  },
  {
    "artist": "The Spencer Davis Group",
    "song": "I’m a Man",
    "year": "1967",
    "seconds": "40",
    "lyric": "Whiskers on my chin",
    "number": "2007",
  },
  {
    "artist": "Waylon Jennings",
    "song": "I’m a Ramblin’ Man",
    "year": "1974",
    "seconds": "60",
    "lyric": "Standing too close to the flame",
    "number": "2008",
  },
  {
    "artist": "Britney Spears",
    "song": "I’m a Slave 4 U",
    "year": "2001",
    "seconds": "60",
    "lyric": "I know I may be young",
    "number": "2009",
  },
  {
    "artist": "Ronnie Milsap",
    "song": "(I’m a) Stand By My Woman Man",
    "year": "1976",
    "seconds": "50",
    "lyric": "At five o’clock she knows",
    "number": "2010",
  },
  {
    "artist": "The Hollies",
    "song": "I’m Alive",
    "year": "1965",
    "seconds": "60",
    "lyric": "I can taste all the sugar",
    "number": "2011",
  },
  {
    "artist": "Lonestar",
    "song": "I’m Already There",
    "year": "2001",
    "seconds": "50",
    "lyric": "Touch you with my fingertips",
    "number": "2012",
  },
  {
    "artist": "Jo Dee Messina",
    "song": "I’m Alright",
    "year": "1998",
    "seconds": "60",
    "lyric": "Looking good in your three-piece suit",
    "number": "2013",
  },
  {
    "artist": "Kenny Loggins",
    "song": "I’m Alright",
    "year": "1980",
    "seconds": "40",
    "lyric": "Nobody worry ’bout me",
    "number": "2014",
  },
  {
    "artist": "Perry Como",
    "song": "I’m Always Chasing Rainbows",
    "year": "1946",
    "seconds": "40",
    "lyric": "A wild goose chase",
    "number": "2015",
  },
  {
    "artist": "Chris Young",
    "song": "I’m Comin’ Over",
    "year": "2015",
    "seconds": "60",
    "lyric": "We break up, we make up",
    "number": "2016",
  },
  {
    "artist": "David Houston",
    "song": "I’m Down To My Last “I Love You”",
    "year": "1969",
    "seconds": "60",
    "lyric": "One last chance to hurt me",
    "number": "2017",
  },
  {
    "artist": "Keith Carradine",
    "song": "I’m Easy",
    "year": "1976",
    "seconds": "60",
    "lyric": "Please stop pulling at my sleeve",
    "number": "2018",
  },
  {
    "artist": "Chaka Khan",
    "song": "I’m Every Woman",
    "year": "1978",
    "seconds": "50",
    "lyric": "I can cast a spell",
    "number": "2019",
  },
  {
    "artist": "Hank Williams Jr.",
    "song": "I’m For Love",
    "year": "1985",
    "seconds": "60",
    "lyric": "I’m against cats in the house",
    "number": "2020",
  },
  {
    "artist": "Tracy Byrd",
    "song": "I’m From The Country",
    "year": "1998",
    "seconds": "70",
    "lyric": "Kick off your shoes, come on in",
    "number": "2021",
  },
  {
    "artist": "Bruce Springsteen",
    "song": "I’m Goin’ Down",
    "year": "1985",
    "seconds": "50",
    "lyric": "One of your bored sighs",
    "number": "2022",
  },
  {
    "artist": "The Proclaimers",
    "song": "I’m Gonna Be (500 Miles)",
    "year": "1987",
    "seconds": "60",
    "lyric": "To fall down at your door",
    "number": "2023",
  },
  {
    "artist": "Travis Tritt",
    "song": "I’m Gonna Be Somebody",
    "year": "1990",
    "seconds": "60",
    "lyric": "He’s number one on the stage",
    "number": "2024",
  },
  {
    "artist": "Jim Reeves",
    "song": "I’m Gonna Change Everything",
    "year": "1962",
    "seconds": "60",
    "lyric": "Take the carpet off the floor",
    "number": "2025",
  },
  {
    "artist": "Perry Como",
    "song": "I’m Gonna Love That Gal",
    "year": "1945",
    "seconds": "50",
    "lyric": "She’s in my arms again",
    "number": "2026",
  },
  {
    "artist": "Barry White",
    "song": "I’m Gonna Love You Just a Little More Baby",
    "year": "1973",
    "seconds": "60",
    "lyric": "Run my fingers through your hair",
    "number": "2027",
  },
  {
    "artist": "The Supremes & the Temptations",
    "song": "I’m Gonna Make You Love Me",
    "year": "1969",
    "seconds": "50",
    "lyric": "Every trick in the book",
    "number": "2028",
  },
  {
    "artist": "Lou Christie",
    "song": "I’m Gonna Make You Mine",
    "year": "1969",
    "seconds": "50",
    "lyric": "I’ll be waiting in line",
    "number": "2029",
  },
  {
    "artist": "Brad Paisley",
    "song": "I’m Gonna Miss Her (The Fishin’ Song)",
    "year": "2002",
    "seconds": "60",
    "lyric": "And I’m sittin’ in the sun",
    "number": "2030",
  },
  {
    "artist": "Mary artin",
    "song": "I’m Gonna Wash That Man Right Outa My Hair",
    "year": "1949",
    "seconds": "70",
    "lyric": "And drum him out of your dreams",
    "number": "2031",
  },
  {
    "artist": "The Beatles",
    "song": "I’m Happy Just to Dance With You",
    "year": "1964",
    "seconds": "60",
    "lyric": "I think I’ll love you too",
    "number": "2032",
  },
  {
    "artist": "Herman’s Hermits",
    "song": "I’m Henry the Eighth, I Am",
    "year": "1965",
    "seconds": "60",
    "lyric": "Married to the widow next door",
    "number": "2033",
  },
  {
    "artist": "Lee Roy Parnell",
    "song": "I’m Holding My Own",
    "year": "1994",
    "seconds": "50",
    "lyric": "Two sides to every door",
    "number": "2034",
  },
  {
    "artist": "Keith Urban",
    "song": "I’m In",
    "year": "2010",
    "seconds": "60",
    "lyric": "Come on in, the water’s fine",
    "number": "2035",
  },
  {
    "artist": "Wilson Pickett",
    "song": "I’m in Love",
    "year": "1967",
    "seconds": "50",
    "lyric": "I can knock on wood",
    "number": "2036",
  },
  {
    "artist": "Fats Domino",
    "song": "I’m in Love Again",
    "year": "1956",
    "seconds": "60",
    "lyric": "You let your dog bite me",
    "number": "2037",
  },
  {
    "artist": "Peter Frampton",
    "song": "I’m In You",
    "year": "1977",
    "seconds": "50",
    "lyric": "You gave me the love",
    "number": "2038",
  },
  {
    "artist": "Herman’s Hermits",
    "song": "I’m Into Something Good",
    "year": "1964",
    "seconds": "50",
    "lyric": "She danced close to me",
    "number": "2039",
  },
  {
    "artist": "Don Williams",
    "song": "I’m Just a Country Boy",
    "year": "1977",
    "seconds": "50",
    "lyric": "Who wears a diamond ring",
    "number": "2040",
  },
  {
    "artist": "Toby Keith",
    "song": "I’m Just Talkin’ About Tonight",
    "year": "2001",
    "seconds": "50",
    "lyric": "Sitting on your bar stool",
    "number": "2041",
  },
  {
    "artist": "Dale & Grace",
    "song": "I’m Leaving It Up to You",
    "year": "1963",
    "seconds": "40",
    "lyric": "Or are we through?",
    "number": "2042",
  },
  {
    "artist": "Hank Snow",
    "song": "I’m Movin’ On",
    "year": "1950",
    "seconds": "50",
    "lyric": "You were flyin’ too high",
    "number": "2043",
  },
  {
    "artist": "Gregg Allman",
    "song": "I’m No Angel",
    "year": "1987",
    "seconds": "60",
    "lyric": "Let me show you my tattoo",
    "number": "2044",
  },
  {
    "artist": "10cc",
    "song": "I’m Not in Love",
    "year": "1975",
    "seconds": "60",
    "lyric": "A silly phase I’m going through",
    "number": "2045",
  },
  {
    "artist": "Jessi Colter",
    "song": "I’m Not Lisa",
    "year": "1975",
    "seconds": "50",
    "lyric": "My eyes are not blue",
    "number": "2046",
  },
  {
    "artist": "George Jones",
    "song": "I’m Not Ready Yet",
    "year": "1980",
    "seconds": "50",
    "lyric": "All the land is wet",
    "number": "2047",
  },
  {
    "artist": "Sam Smith",
    "song": "I’m Not the Only One",
    "year": "2014",
    "seconds": "60",
    "lyric": "By lying and tearing us up",
    "number": "2048",
  },
  {
    "artist": "Bruce Springsteen",
    "song": "I’m On Fire",
    "year": "1985",
    "seconds": "40",
    "lyric": "The sheets soaking wet",
    "number": "2049",
  },
  {
    "artist": "The Proclaimers",
    "song": "I’m On My Way",
    "year": "1988",
    "seconds": "50",
    "lyric": "Jumping through my mouth, dear",
    "number": "2050",
  },
  {
    "artist": "The Oak Ridge Boys",
    "song": "(I’m Settin’) Fancy Free",
    "year": "1981",
    "seconds": "60",
    "lyric": "Even though I love her still",
    "number": "2051",
  },
  {
    "artist": "Toby Keith (ft. Sting)",
    "song": "I’m So Happy I Can’t Stop Crying",
    "year": "1997",
    "seconds": "60",
    "lyric": "Sunday fathers and melted ice cream",
    "number": "2052",
  },
  {
    "artist": "Hank Williams",
    "song": "I’m So Lonesome I Could Cry",
    "year": "1949",
    "seconds": "60",
    "lyric": "He sounds too blue to fly",
    "number": "2053",
  },
  {
    "artist": "Brenda Lee",
    "song": "I’m Sorry",
    "year": "1960",
    "seconds": "40",
    "lyric": "But love was blind",
    "number": "2054",
  },
  {
    "artist": "John Denver",
    "song": "I’m Sorry",
    "year": "1975",
    "seconds": "60",
    "lyric": "But they all know I’m crying",
    "number": "2055",
  },
  {
    "artist": "Brad Paisley",
    "song": "I’m Still a Guy",
    "year": "2008",
    "seconds": "70",
    "lyric": "I see antlers up on the wall",
    "number": "2056",
  },
  {
    "artist": "Vern Gosdin",
    "song": "I’m Still Crazy",
    "year": "1989",
    "seconds": "60",
    "lyric": "It hurt me to the bone",
    "number": "2057",
  },
  {
    "artist": "Wade Hayes",
    "song": "I’m Still Dancin’ With You",
    "year": "1995",
    "seconds": "50",
    "lyric": "The music’s good and loud",
    "number": "2058",
  },
  {
    "artist": "Al Green",
    "song": "I’m Still in Love With You",
    "year": "1972",
    "seconds": "60",
    "lyric": "I’m wrapped up in your love",
    "number": "2059",
  },
  {
    "artist": "Elton John",
    "song": "I’m Still Standing",
    "year": "1983",
    "seconds": "40",
    "lyric": "After all this time",
    "number": "2060",
  },
  {
    "artist": "The Stylistics",
    "song": "I’m Stone in Love With You",
    "year": "1972",
    "seconds": "70",
    "lyric": "Drive a big expensive car",
    "number": "2061",
  },
  {
    "artist": "Freddie and the Dreamers",
    "song": "I’m Telling You Now",
    "year": "1965",
    "seconds": "60",
    "lyric": "I’m in love with you now",
    "number": "2062",
  },
  {
    "artist": "Carter Family",
    "song": "I’m Thinking Tonight of My Blue Eyes",
    "year": "1935",
    "seconds": "50",
    "lyric": "Sailing far over the sea",
    "number": "2063",
  },
  {
    "artist": "Right Said Fred",
    "song": "I’m Too Sexy",
    "year": "1991",
    "seconds": "60",
    "lyric": "My little turn on the catwalk",
    "number": "2064",
  },
  {
    "artist": "Trace Adkins",
    "song": "I’m Tryin’",
    "year": "2001",
    "seconds": "60",
    "lyric": "A mountain in front of me",
    "number": "2065",
  },
  {
    "artist": "Fats Domino",
    "song": "I’m Walkin’",
    "year": "1957",
    "seconds": "50",
    "lyric": "Yes indeed, and I’m talkin’",
    "number": "2066",
  },
  {
    "artist": "Céline Dion and R. Kelly",
    "song": "I’m Your Angel",
    "year": "1998",
    "seconds": "60",
    "lyric": "I’ll be right by your side",
    "number": "2067",
  },
  {
    "artist": "KC & the Sunshine Band",
    "song": "I’m Your Boogie Man",
    "year": "1976",
    "seconds": "40",
    "lyric": "Be your rubber ball",
    "number": "2068",
  },
  {
    "artist": "Grand Funk Railroad",
    "song": "I’m Your Captain",
    "year": "1970",
    "seconds": "60",
    "lyric": "I will kiss you, Mother Earth",
    "number": "2069",
  },
  {
    "artist": "James & Bobby Purify",
    "song": "I’m Your Puppet",
    "year": "1966",
    "seconds": "40",
    "lyric": "Wiggle your little hand",
    "number": "2070",
  },
  {
    "artist": "Jason Mraz",
    "song": "I’m Yours",
    "year": "2008",
    "seconds": "60",
    "lyric": "And I will nibble your ear",
    "number": "2071",
  },
  {
    "artist": "John Lennon",
    "song": "Imagine",
    "year": "1971",
    "seconds": "60",
    "lyric": "Nothing to kill or die for",
    "number": "2072",
  },
  {
    "artist": "The Black Eyed Peas",
    "song": "Imma Be",
    "year": "2009",
    "seconds": "60",
    "lyric": "And I’m kicking down a door",
    "number": "2073",
  },
  {
    "artist": "Led Zeppelin",
    "song": "Immigrant Song",
    "year": "1970",
    "seconds": "70",
    "lyric": "The land of the ice and snow",
    "number": "2074",
  },
  {
    "artist": "Brett Young",
    "song": "In Case You Didn’t Know",
    "year": "2017",
    "seconds": "50",
    "lyric": "That second glass of wine",
    "number": "2075",
  },
  {
    "artist": "Jamey Johnson",
    "song": "In Color",
    "year": "2008",
    "seconds": "50",
    "lyric": "And her eyes were blue",
    "number": "2076",
  },
  {
    "artist": "Roy Orbison",
    "song": "In Dreams",
    "year": "1963",
    "seconds": "60",
    "lyric": "Tiptoes to my room every night",
    "number": "2077",
  },
  {
    "artist": "Ronnie Milsap",
    "song": "In Love",
    "year": "1986",
    "seconds": "50",
    "lyric": "Love is on shaky ground",
    "number": "2078",
  },
  {
    "artist": "Shawn Mendes",
    "song": "In My Blood",
    "year": "2018",
    "seconds": "50",
    "lyric": "Looking through my phone again",
    "number": "2079",
  },
  {
    "artist": "The Beatles",
    "song": "In My Life",
    "year": "1965",
    "seconds": "60",
    "lyric": "Some have gone and some remain",
    "number": "2080",
  },
  {
    "artist": "Coldplay",
    "song": "In My Place",
    "year": "2002",
    "seconds": "60",
    "lyric": "Come back and sing to me",
    "number": "2081",
  },
  {
    "artist": "Phil Collins",
    "song": "In the Air Tonight",
    "year": "1981",
    "seconds": "60",
    "lyric": "You can wipe off that grin",
    "number": "2082",
  },
  {
    "artist": "Eagles",
    "song": "In the City",
    "year": "1979",
    "seconds": "60",
    "lyric": "With my back against the wall",
    "number": "2083",
  },
  {
    "artist": "Michael Jackson",
    "song": "In the Closet",
    "year": "1992",
    "seconds": "50",
    "lyric": "Passion burns inside of me",
    "number": "2084",
  },
  {
    "artist": "Linkin Park",
    "song": "In the End",
    "year": "2002",
    "seconds": "50",
    "lyric": "Go right out the window",
    "number": "2085",
  },
  {
    "artist": "Elvis Presley",
    "song": "In the Ghetto",
    "year": "1969",
    "seconds": "70",
    "lyric": "He buys a gun, steals a car",
    "number": "2086",
  },
  {
    "artist": "Billy Ray Cyrus",
    "song": "In the Heart of a Woman",
    "year": "1993",
    "seconds": "60",
    "lyric": "One day I found a note",
    "number": "2087",
  },
  {
    "artist": "Webb Pierce",
    "song": "In the Jailhouse Now",
    "year": "1955",
    "seconds": "50",
    "lyric": "Playin’ cards and shootin’ dice",
    "number": "2088",
  },
  {
    "artist": "Wilson Pickett",
    "song": "In the Midnight Hour",
    "year": "1965",
    "seconds": "50",
    "lyric": "My love comes tumbling down",
    "number": "2089",
  },
  {
    "artist": "Robert Plant",
    "song": "In the Mood",
    "year": "1983",
    "seconds": "70",
    "lyric": "I can write it on the door",
    "number": "2090",
  },
  {
    "artist": "Rush",
    "song": "In the Mood",
    "year": "1974",
    "seconds": "70",
    "lyric": "Hey baby, it’s a quarter to eight",
    "number": "2091",
  },
  {
    "artist": "The Five Satins",
    "song": "In the Still of the Night",
    "year": "1956",
    "seconds": "50",
    "lyric": "The stars were bright above",
    "number": "2092",
  },
  {
    "artist": "Mungo Jerry",
    "song": "In the Summertime",
    "year": "1970",
    "seconds": "60",
    "lyric": "You got women on your mind",
    "number": "2093",
  },
  {
    "artist": "Zager & Evans",
    "song": "In the Year 2525",
    "year": "1969",
    "seconds": "50",
    "lyric": "Hanging limp at your sides",
    "number": "2094",
  },
  {
    "artist": "Robin Zander",
    "song": "In This Country",
    "year": "1987",
    "seconds": "50",
    "lyric": "Like water under the bridge",
    "number": "2095",
  },
  {
    "artist": "Sum 41",
    "song": "In Too Deep",
    "year": "2001",
    "seconds": "50",
    "lyric": "Up above in my head",
    "number": "2096",
  },
  {
    "artist": "Peter Gabriel",
    "song": "In Your Eyes",
    "year": "1986",
    "seconds": "60",
    "lyric": "The doorway to a thousand churches",
    "number": "2097",
  },
  {
    "artist": "Strawberry Alarm Clock",
    "song": "Incense and Peppermints",
    "year": "1967",
    "seconds": "70",
    "lyric": "Little to win but nothin’ to lose",
    "number": "2098",
  },
  {
    "artist": "Martina McBride",
    "song": "Independence Day",
    "year": "1994",
    "seconds": "50",
    "lyric": "Let the weak be strong",
    "number": "2099",
  },
  {
    "artist": "R. Dean Taylor",
    "song": "Indiana Wants Me",
    "year": "1970",
    "seconds": "60",
    "lyric": "I’ll never see your smiling face",
    "number": "2100",
  },
  {
    "artist": "Jann Arden",
    "song": "Insensitive",
    "year": "1994",
    "seconds": "60",
    "lyric": "By the chill in your embrace",
    "number": "2101",
  },
  {
    "artist": "Ronnie Milsap",
    "song": "Inside",
    "year": "1982",
    "seconds": "50",
    "lyric": "I feel her slipping away",
    "number": "2102",
  },
  {
    "artist": "Spoon",
    "song": "Inside Out",
    "year": "2014",
    "seconds": "50",
    "lyric": "They may wash my feet",
    "number": "2103",
  },
  {
    "artist": "Disturbed",
    "song": "Inside the Fire",
    "year": "2008",
    "seconds": "50",
    "lyric": "She begins to turn cold",
    "number": "2104",
  },
  {
    "artist": "Carrie Underwood",
    "song": "Inside Your Heaven",
    "year": "2005",
    "seconds": "50",
    "lyric": "The sun and the moonlight",
    "number": "2105",
  },
  {
    "artist": "John Lennon",
    "song": "Instant Karma",
    "year": "1970",
    "seconds": "60",
    "lyric": "Laughing in the face of love",
    "number": "2106",
  },
  {
    "artist": "Justin Bieber",
    "song": "Intentions",
    "year": "2020",
    "seconds": "60",
    "lyric": "Stay in the kitchen, cooking up",
    "number": "2107",
  },
  {
    "artist": "Stone Temple Pilots",
    "song": "Interstate Love Song",
    "year": "1994",
    "seconds": "50",
    "lyric": "A hand in rusted chains",
    "number": "2108",
  },
  {
    "artist": "Kylie Minogue",
    "song": "Into the Blue",
    "year": "2014",
    "seconds": "60",
    "lyric": "My back up against the wall",
    "number": "2109",
  },
  {
    "artist": "Madonna",
    "song": "Into the Groove",
    "year": "1985",
    "seconds": "50",
    "lyric": "Get up on your feet",
    "number": "2110",
  },
  {
    "artist": "Van Morrison",
    "song": "Into the Mystic",
    "year": "1970",
    "seconds": "70",
    "lyric": "Smell the sea and feel the sky",
    "number": "2111",
  },
  {
    "artist": "Alanis Morissette",
    "song": "Ironic",
    "year": "1996",
    "seconds": "50",
    "lyric": "An old man turned ninety-eight",
    "number": "2112",
  },
  {
    "artist": "Beyoncé",
    "song": "Irreplaceable",
    "year": "2006",
    "seconds": "60",
    "lyric": "Hurry up before your taxi leaves",
    "number": "2113",
  },
  {
    "artist": "Jim Reeves",
    "song": "Is It Really Over?",
    "year": "1965",
    "seconds": "40",
    "lyric": "Stepping into my shoes",
    "number": "2114",
  },
  {
    "artist": "Great Big Sea",
    "song": "I’se the B’y",
    "year": "1993",
    "seconds": "40",
    "lyric": "Fried in maggoty butter",
    "number": "2115",
  },
  {
    "artist": "Elton John",
    "song": "Island Girl",
    "year": "1975",
    "seconds": "50",
    "lyric": "Her nail scratch your back",
    "number": "2116",
  },
  {
    "artist": "Blondie",
    "song": "Island of Lost Souls",
    "year": "1982",
    "seconds": "60",
    "lyric": "The sea is warm and clear",
    "number": "2117",
  },
  {
    "artist": "Kenny Rogers (feat. Dolly Parton)",
    "song": "Islands in the Stream",
    "year": "1983",
    "seconds": "40",
    "lyric": "Sail away with me",
    "number": "2118",
  },
  {
    "artist": "George Strait",
    "song": "It Ain’t Cool to Be Crazy About You",
    "year": "1986",
    "seconds": "40",
    "lyric": "Right from the start",
    "number": "2119",
  },
  {
    "artist": "Corey Hart",
    "song": "It Ain’t Enough",
    "year": "1984",
    "seconds": "60",
    "lyric": "I could pour you a drink",
    "number": "2120",
  },
  {
    "artist": "Bob Dylan",
    "song": "It Ain’t Me Babe",
    "year": "1964",
    "seconds": "50",
    "lyric": "Go lightly on the ground",
    "number": "2121",
  },
  {
    "artist": "Brothers Osborne",
    "song": "It Ain’t My Fault",
    "year": "2017",
    "seconds": "50",
    "lyric": "I got my hands up",
    "number": "2122",
  },
  {
    "artist": "Ringo Starr",
    "song": "It Don’t Come Easy",
    "year": "1971",
    "seconds": "60",
    "lyric": "If you wanna sing the blues",
    "number": "2123",
  },
  {
    "artist": "Sugarland",
    "song": "It Happens",
    "year": "2009",
    "seconds": "60",
    "lyric": "No cup of coffee, no shower",
    "number": "2124",
  },
  {
    "artist": "Gene Pitney",
    "song": "It Hurts To Be In Love",
    "year": "1964",
    "seconds": "70",
    "lyric": "And so you cry a little bit",
    "number": "2125",
  },
  {
    "artist": "George Strait",
    "song": "It Just Comes Natural",
    "year": "2006",
    "seconds": "40",
    "lyric": "Fire burns, waves crash",
    "number": "2126",
  },
  {
    "artist": "Faith Hill",
    "song": "It Matters to Me",
    "year": "1995",
    "seconds": "50",
    "lyric": "Waltz back into my life",
    "number": "2127",
  },
  {
    "artist": "Stephen Bishop",
    "song": "It Might Be You (Tootsie Soundtrack)",
    "year": "1983",
    "seconds": "40",
    "lyric": "Watching trains go by",
    "number": "2128",
  },
  {
    "artist": "Vikki Carr",
    "song": "It Must Be Him",
    "year": "1967",
    "seconds": "50",
    "lyric": "A puppet on a string",
    "number": "2129",
  },
  {
    "artist": "Albert Hammnd",
    "song": "It Never Rains in Southern California",
    "year": "1973",
    "seconds": "50",
    "lyric": "It pours, man it pours",
    "number": "2130",
  },
  {
    "artist": "Gladys Knight & the Pips",
    "song": "It Should Have Been Me",
    "year": "1968",
    "seconds": "50",
    "lyric": "Or forever hold your peace",
    "number": "2131",
  },
  {
    "artist": "Ray Charles",
    "song": "It Should’ve Been Me",
    "year": "1954",
    "seconds": "60",
    "lyric": "I ate a bowl of chili",
    "number": "2132",
  },
  {
    "artist": "Mark Chesnutt",
    "song": "It Sure Is Monday",
    "year": "1993",
    "seconds": "50",
    "lyric": "I woke up dog tired",
    "number": "2133",
  },
  {
    "artist": "The Oak Ridge Boys",
    "song": "It Takes a Little Rain (To Make Love Grow)",
    "year": "1987",
    "seconds": "40",
    "lyric": "Warm feelings run deep",
    "number": "2134",
  },
  {
    "artist": "Buck Owens",
    "song": "It Takes People Like You",
    "year": "1967",
    "seconds": "50",
    "lyric": "From the snows of Alaska",
    "number": "2135",
  },
  {
    "artist": "Rob Base & DJ E-Z Rock",
    "song": "It Takes Two",
    "year": "1988",
    "seconds": "60",
    "lyric": "Ladies love me, girls adore me",
    "number": "2136",
  },
  {
    "artist": "Ronnie Milsap",
    "song": "It Was Almost Like a Song",
    "year": "1977",
    "seconds": "50",
    "lyric": "And you came to me",
    "number": "2137",
  },
  {
    "artist": "Shaggy (ft. Rikrok)",
    "song": "It Wasn’t Me",
    "year": "2000",
    "seconds": "50",
    "lyric": "And she caught me red-handed",
    "number": "2138",
  },
  {
    "artist": "Michael Bublé",
    "song": "It’s a Beautiful Day",
    "year": "2013",
    "seconds": "60",
    "lyric": "Tear the seam of someone’s dream",
    "number": "2139",
  },
  {
    "artist": "The Four Freshmen",
    "song": "It’s a Blue World",
    "year": "1950",
    "seconds": "80",
    "lyric": "The sea, the sky, my heart and I",
    "number": "2140",
  },
  {
    "artist": "Travis Tritt",
    "song": "It’s a Great Day to Be Alive",
    "year": "2000",
    "seconds": "50",
    "lyric": "Rice cookin’ in the microwave",
    "number": "2141",
  },
  {
    "artist": "Bonnie Tyler",
    "song": "It’s a Heartache",
    "year": "1977",
    "seconds": "50",
    "lyric": "Hits you when you’re down",
    "number": "2142",
  },
  {
    "artist": "Mark Chesnutt",
    "song": "It’s a Little Too Late",
    "year": "1996",
    "seconds": "40",
    "lyric": "Running through my head",
    "number": "2143",
  },
  {
    "artist": "Tanya Tucker",
    "song": "It’s a Little Too Late",
    "year": "1993",
    "seconds": "40",
    "lyric": "Turn the jukebox up",
    "number": "2144",
  },
  {
    "artist": "James Brown",
    "song": "It’s a Man’s Man’s Man’s World",
    "year": "1966",
    "seconds": "70",
    "lyric": "Nothing without a woman or a girl",
    "number": "2145",
  },
  {
    "artist": "Céline Dion",
    "song": "It’s All Coming Back to Me Now",
    "year": "1992",
    "seconds": "50",
    "lyric": "My body froze in bed",
    "number": "2146",
  },
  {
    "artist": "Tommy Edwards",
    "song": "It’s All in the Game",
    "year": "1958",
    "seconds": "60",
    "lyric": "And your hearts will fly away",
    "number": "2147",
  },
  {
    "artist": "Anne Murray",
    "song": "It’s All I Can Do",
    "year": "1981",
    "seconds": "50",
    "lyric": "One look in your eyes",
    "number": "2148",
  },
  {
    "artist": "The Cars",
    "song": "It’s All I Can Do",
    "year": "1980",
    "seconds": "40",
    "lyric": "One too many times",
    "number": "2149",
  },
  {
    "artist": "Rolling Stones",
    "song": "It’s All Over Now",
    "year": "1964",
    "seconds": "60",
    "lyric": "I used to love her, but",
    "number": "2150",
  },
  {
    "artist": "Bob Dylan",
    "song": "It’s All Over Now, Baby Blue",
    "year": "1965",
    "seconds": "60",
    "lyric": "The empty-handed painter from your streets",
    "number": "2151",
  },
  {
    "artist": "Merle Haggard",
    "song": "It’s Been a Great Afternoon",
    "year": "1978",
    "seconds": "80",
    "lyric": "The poundin’ in the top of my head",
    "number": "2152",
  },
  {
    "artist": "Staind",
    "song": "It’s Been Awhile",
    "year": "2001",
    "seconds": "60",
    "lyric": "Stand on my own two feet",
    "number": "2153",
  },
  {
    "artist": "Perry Como & the Fontane Sisters",
    "song": "It’s Beginning to Look a Lot Like Christmas",
    "year": "1951",
    "seconds": "60",
    "lyric": "Look at the five and ten",
    "number": "2154",
  },
  {
    "artist": "Jimmy Buffett (feat. Alan Jackson)",
    "song": "It’s Five O’Clock Somewhere",
    "year": "2003",
    "seconds": "50",
    "lyric": "It’s only half past twelve",
    "number": "2155",
  },
  {
    "artist": "Brooks & Dunn",
    "song": "It’s Getting Better All the Time",
    "year": "2004",
    "seconds": "40",
    "lyric": "Whiskey on my breath",
    "number": "2156",
  },
  {
    "artist": "NSYNC",
    "song": "It’s Gonna Be Me",
    "year": "2000",
    "seconds": "60",
    "lyric": "I’ll be the one, you’ll see",
    "number": "2157",
  },
  {
    "artist": "Charley Pride",
    "song": "It’s Gonna Take A Little Bit Longer",
    "year": "1972",
    "seconds": "60",
    "lyric": "Get you off of my mind",
    "number": "2158",
  },
  {
    "artist": "Bruce Springsteen",
    "song": "It’s Hard to Be a Saint in the City",
    "year": "1973",
    "seconds": "50",
    "lyric": "I had skin like leather",
    "number": "2159",
  },
  {
    "artist": "Mac Davis",
    "song": "It’s Hard to Be Humble",
    "year": "1980",
    "seconds": "50",
    "lyric": "To look in the mirror",
    "number": "2160",
  },
  {
    "artist": "Perry Como",
    "song": "It’s Impossible",
    "year": "1970",
    "seconds": "60",
    "lyric": "Ask a baby not to cry",
    "number": "2161",
  },
  {
    "artist": "Brook Benton",
    "song": "It’s Just a Matter of Time",
    "year": "1959",
    "seconds": "60",
    "lyric": "And you called me a clown",
    "number": "2162",
  },
  {
    "artist": "Lesley Gore",
    "song": "It’s My Party",
    "year": "1963",
    "seconds": "50",
    "lyric": "A queen with her king",
    "number": "2163",
  },
  {
    "artist": "Tom Jones",
    "song": "It’s Not Unusual",
    "year": "1965",
    "seconds": "50",
    "lyric": "I see you hanging about",
    "number": "2164",
  },
  {
    "artist": "Elvis Presley",
    "song": "It’s Now or Never",
    "year": "1960",
    "seconds": "40",
    "lyric": "Kiss me my darling",
    "number": "2165",
  },
  {
    "artist": "Björk",
    "song": "It’s Oh So Quiet",
    "year": "1995",
    "seconds": "60",
    "lyric": "You fall in love, zing boom",
    "number": "2166",
  },
  {
    "artist": "Bryan Adams, Tina Turner",
    "song": "It’s Only Love",
    "year": "1985",
    "seconds": "40",
    "lyric": "Lookin’ back in desperation",
    "number": "2167",
  },
  {
    "artist": "Conway Twitty",
    "song": "It’s Only Make Believe",
    "year": "1958",
    "seconds": "50",
    "lyric": "My heart, a wedding ring",
    "number": "2168",
  },
  {
    "artist": "Rolling Stones",
    "song": "It’s Only Rock & Roll",
    "year": "1974",
    "seconds": "60",
    "lyric": "Would it slide on by ya?",
    "number": "2169",
  },
  {
    "artist": "Roy Orbison",
    "song": "It’s Over",
    "year": "1964",
    "seconds": "60",
    "lyric": "All the rainbows in the sky",
    "number": "2170",
  },
  {
    "artist": "Sting (ft. Eric Clapton)",
    "song": "It’s Probably Me",
    "year": "1992",
    "seconds": "50",
    "lyric": "And too dumb to steal",
    "number": "2171",
  },
  {
    "artist": "The Weather Girls",
    "song": "It’s Raining Men",
    "year": "1982",
    "seconds": "50",
    "lyric": "She’s a single woman too",
    "number": "2172",
  },
  {
    "artist": "Buddy Holly",
    "song": "It’s So Easy to Fall in Love",
    "year": "1957",
    "seconds": "60",
    "lyric": "Look into your heart and see",
    "number": "2173",
  },
  {
    "artist": "Boyz II Men",
    "song": "It’s So Hard to Say Goodbye to Yesterday",
    "year": "1991",
    "seconds": "50",
    "lyric": "My sunshine after the rain",
    "number": "2174",
  },
  {
    "artist": "Andy Williams",
    "song": "It’s the Most Wonderful Time of the Year",
    "year": "1963",
    "seconds": "60",
    "lyric": "And caroling out in the snow",
    "number": "2175",
  },
  {
    "artist": "Four Tops",
    "song": "It’s the Same Old Song",
    "year": "1965",
    "seconds": "60",
    "lyric": "Oh, it hurts to hear it",
    "number": "2176",
  },
  {
    "artist": "Carole King",
    "song": "It’s Too Late",
    "year": "1971",
    "seconds": "50",
    "lyric": "You were light and breezy",
    "number": "2177",
  },
  {
    "artist": "Wilson Pickett",
    "song": "It’s Too Late",
    "year": "1963",
    "seconds": "60",
    "lyric": "Hang my little head and cry",
    "number": "2178",
  },
  {
    "artist": "The Young Rascals",
    "song": "It’s Wonderful",
    "year": "1967",
    "seconds": "60",
    "lyric": "But it’s all in your head",
    "number": "2179",
  },
  {
    "artist": "Tim McGraw (ft. Faith Hill)",
    "song": "It’s Your Love",
    "year": "1997",
    "seconds": "50",
    "lyric": "A shock right through me",
    "number": "2180",
  },
  {
    "artist": "Marty Robbins",
    "song": "It’s Your World",
    "year": "1961",
    "seconds": "50",
    "lyric": "And I’m just passin’ through",
    "number": "2181",
  },
  {
    "artist": "Brian Hyland",
    "song": "Itsy Bitsy Teenie Weenie Yellow Polka Dot Bikini",
    "year": "1960",
    "seconds": "70",
    "lyric": "Afraid to come out of the locker",
    "number": "2182",
  },
  {
    "artist": "Conway Twitty",
    "song": "I’ve Already Loved You in My Mind",
    "year": "1977",
    "seconds": "50",
    "lyric": "She whispered in my ear",
    "number": "2183",
  },
  {
    "artist": "Waylon Jennings",
    "song": "I’ve Always Been Crazy",
    "year": "1978",
    "seconds": "50",
    "lyric": "One foot over the line",
    "number": "2184",
  },
  {
    "artist": "The Young Rascals",
    "song": "(I’ve Been) Lonely Too Long",
    "year": "1967",
    "seconds": "40",
    "lyric": "Gliding through this world",
    "number": "2185",
  },
  {
    "artist": "Chicago",
    "song": "(I’ve Been) Searchin’ So Long",
    "year": "1974",
    "seconds": "50",
    "lyric": "New light in my eyes",
    "number": "2186",
  },
  {
    "artist": "George Strait",
    "song": "I’ve Come to Expect It From You",
    "year": "1990",
    "seconds": "60",
    "lyric": "Sit and smoke, cry and joke",
    "number": "2187",
  },
  {
    "artist": "The Free Movement",
    "song": "I’ve Found Someone of My Own",
    "year": "1971",
    "seconds": "60",
    "lyric": "Reached down and wiped the tears",
    "number": "2188",
  },
  {
    "artist": "Buck Owens",
    "song": "I’ve Got a Tiger by the Tail",
    "year": "1964",
    "seconds": "50",
    "lyric": "The wind from my sails",
    "number": "2189",
  },
  {
    "artist": "John Anderson",
    "song": "I’ve Got It Made",
    "year": "1993",
    "seconds": "60",
    "lyric": "Woman in a low cut dress",
    "number": "2190",
  },
  {
    "artist": "Natalie Cole",
    "song": "I’ve Got Love On My Mind",
    "year": "1977",
    "seconds": "40",
    "lyric": "From sunrise to sunset",
    "number": "2191",
  },
  {
    "artist": "Sammi Smith",
    "song": "I’ve Got To Have You",
    "year": "1972",
    "seconds": "50",
    "lyric": "You trembled at a touch",
    "number": "2192",
  },
  {
    "artist": "Gladys Knight & the Pips",
    "song": "I’ve Got to Use My Imagination",
    "year": "1973",
    "seconds": "40",
    "lyric": "Blockin’ out the sun",
    "number": "2193",
  },
  {
    "artist": "Frank Sinatra",
    "song": "I’ve Got You Under my Skin",
    "year": "1953",
    "seconds": "60",
    "lyric": "Deep in the heart of me",
    "number": "2194",
  },
  {
    "artist": "Sammy Davis Jr.",
    "song": "I’ve Gotta Be Me",
    "year": "1969",
    "seconds": "40",
    "lyric": "I’ll go it alone",
    "number": "2195",
  },
  {
    "artist": "The Bee Gees",
    "song": "I’ve Gotta Get a Message to You",
    "year": "1968",
    "seconds": "60",
    "lyric": "And my life will be through",
    "number": "2196",
  },
  {
    "artist": "Bill Medley, Jennifer Warnes",
    "song": "(I've Had) The Time Of My Life",
    "year": "1987",
    "seconds": "70",
    "lyric": "We saw the writing on the wall",
    "number": "2197",
  },
  {
    "artist": "The Beatles",
    "song": "I’ve Just Seen a Face",
    "year": "1965",
    "seconds": "50",
    "lyric": "Falling, yes I am falling",
    "number": "2198",
  },
  {
    "artist": "Yes",
    "song": "I’ve Seen All Good People",
    "year": "1971",
    "seconds": "60",
    "lyric": "The white queen run so fast",
    "number": "2199",
  },
  {
    "artist": "John Cougar",
    "song": "Jack and Diane",
    "year": "1982",
    "seconds": "40",
    "lyric": "Suckin’ on chili dogs",
    "number": "2200",
  },
  {
    "artist": "Raydio",
    "song": "Jack and Jill",
    "year": "1977",
    "seconds": "50",
    "lyric": "Sat up on the hill",
    "number": "2201",
  },
  {
    "artist": "Frank Crumit",
    "song": "Jack Was Every Inch a Sailor",
    "year": "1928",
    "seconds": "60",
    "lyric": "Born upon the bright blue sea",
    "number": "2202",
  },
  {
    "artist": "Van Morrison",
    "song": "Jackie Wilson Said",
    "year": "1972",
    "seconds": "70",
    "lyric": "Yes, it knocks me off my feet",
    "number": "2203",
  },
  {
    "artist": "Johnny Cash & June Carter",
    "song": "Jackson",
    "year": "1967",
    "seconds": "50",
    "lyric": "Hotter than a pepper sprout",
    "number": "2204",
  },
  {
    "artist": "Aerosmith",
    "song": "Jaded",
    "year": "2001",
    "seconds": "50",
    "lyric": "Slip into the velvet glove",
    "number": "2205",
  },
  {
    "artist": "AC/DC",
    "song": "Jailbreak",
    "year": "1976",
    "seconds": "50",
    "lyric": "A bullet in his back",
    "number": "2206",
  },
  {
    "artist": "Elvis Presley",
    "song": "Jailhouse Rock",
    "year": "1957",
    "seconds": "50",
    "lyric": "The joint began to swing",
    "number": "2207",
  },
  {
    "artist": "Harry Belafonte",
    "song": "Jamaica Farewell",
    "year": "1958",
    "seconds": "50",
    "lyric": "My head is turning around",
    "number": "2208",
  },
  {
    "artist": "Hank Williams",
    "song": "Jambalaya (On the Bayou)",
    "year": "1952",
    "seconds": "50",
    "lyric": "Pick guitar, fill fruit jar",
    "number": "2209",
  },
  {
    "artist": "Van Halen",
    "song": "Jamie’s Cryin’",
    "year": "1978",
    "seconds": "70",
    "lyric": "She saw the look in his eyes",
    "number": "2210",
  },
  {
    "artist": "Tom Petty & the Heartbreakers",
    "song": "Jammin’ Me",
    "year": "1987",
    "seconds": "50",
    "lyric": "Check your front wheel drive",
    "number": "2211",
  },
  {
    "artist": "Aerosmith",
    "song": "Janie’s Got a Gun",
    "year": "1989",
    "seconds": "50",
    "lyric": "Lookin’ straight at the sun",
    "number": "2212",
  },
  {
    "artist": "Christina Perri",
    "song": "Jar of Hearts",
    "year": "2010",
    "seconds": "50",
    "lyric": "The ice inside your soul",
    "number": "2213",
  },
  {
    "artist": "Nick Jonas",
    "song": "Jealous",
    "year": "2014",
    "seconds": "50",
    "lyric": "And I’m puffing my chest",
    "number": "2214",
  },
  {
    "artist": "Louis Armstrong",
    "song": "Jeepers Creepers",
    "year": "1938",
    "seconds": "50",
    "lyric": "Your eyes look into mine",
    "number": "2215",
  },
  {
    "artist": "The Hollies",
    "song": "Jennifer Eccles",
    "year": "1968",
    "seconds": "60",
    "lyric": "White chalk written on red brick",
    "number": "2216",
  },
  {
    "artist": "Pearl Jam",
    "song": "Jeremy",
    "year": "1991",
    "seconds": "80",
    "lyric": "Lemon yellow sun, arms raised in a V",
    "number": "2217",
  },
  {
    "artist": "Carly Simon",
    "song": "Jesse",
    "year": "1980",
    "seconds": "50",
    "lyric": "Cut fresh flowers for you",
    "number": "2218",
  },
  {
    "artist": "The Allman Brothers Band",
    "song": "Jessica",
    "year": "1973",
    "seconds": "50",
    "lyric": "A dagger in my back",
    "number": "2219",
  },
  {
    "artist": "Rick Springfield",
    "song": "Jessie's Girl",
    "year": "1981",
    "seconds": "60",
    "lyric": "He’s holding her in his arms",
    "number": "2220",
  },
  {
    "artist": "Doobie Brothers",
    "song": "Jesus is Just Alright",
    "year": "1972",
    "seconds": "60",
    "lyric": "He took me by the hand",
    "number": "2221",
  },
  {
    "artist": "Carrie Underwood",
    "song": "Jesus, Take the Wheel",
    "year": "2005",
    "seconds": "40",
    "lyric": "Sleeping like a rock",
    "number": "2222",
  },
  {
    "artist": "Paul McCartney & Wings",
    "song": "Jet",
    "year": "1973",
    "seconds": "80",
    "lyric": "We’ll go for a ride in the sky",
    "number": "2223",
  },
  {
    "artist": "The Steve Miller Band",
    "song": "Jet Airliner",
    "year": "1977",
    "seconds": "50",
    "lyric": "Carry me too far away",
    "number": "2224",
  },
  {
    "artist": "Dinah Shore",
    "song": "Jim",
    "year": "1941",
    "seconds": "50",
    "lyric": "Breaking my heart in bits",
    "number": "2225",
  },
  {
    "artist": "Martha and the Vandellas",
    "song": "Jimmy Mack",
    "year": "1967",
    "seconds": "70",
    "lyric": "He talks just as sweet as you",
    "number": "2226",
  },
  {
    "artist": "Bobby Helms",
    "song": "Jingle Bell Rock",
    "year": "1957",
    "seconds": "40",
    "lyric": "Pick up your feet",
    "number": "2227",
  },
  {
    "artist": "Bobby Helms",
    "song": "Jingle Bells",
    "year": "1957",
    "seconds": "40",
    "lyric": "Pick up your feet",
    "number": "2228",
  },
  {
    "artist": "The Bee Gees",
    "song": "Jive Talkin’",
    "year": "1975",
    "seconds": "40",
    "lyric": "You wear a disguise",
    "number": "2229",
  },
  {
    "artist": "Kool & the Gang",
    "song": "Joanna",
    "year": "1983",
    "seconds": "40",
    "lyric": "She cheers me up",
    "number": "2230",
  },
  {
    "artist": "Keith Urban",
    "song": "John Cougar, John Deere, John 3:16",
    "year": "2015",
    "seconds": "60",
    "lyric": "A TV dinner on a tray",
    "number": "2231",
  },
  {
    "artist": "DeFord Bailey",
    "song": "John Henry",
    "year": "1932",
    "seconds": "50",
    "lyric": "He picked up a hammer",
    "number": "2232",
  },
  {
    "artist": "Shelley Fabares",
    "song": "Johnny Angel",
    "year": "1962",
    "seconds": "60",
    "lyric": "I tingle when he passes by",
    "number": "2233",
  },
  {
    "artist": "Chuck Berry",
    "song": "Johnny B. Goode",
    "year": "1958",
    "seconds": "70",
    "lyric": "Carry his guitar in a gunny sack",
    "number": "2234",
  },
  {
    "artist": "Joanie Sommers",
    "song": "Johnny Get Angry",
    "year": "1962",
    "seconds": "50",
    "lyric": "I want a cave man",
    "number": "2235",
  },
  {
    "artist": "Dolly Parton",
    "song": "Jolene",
    "year": "1974",
    "seconds": "50",
    "lyric": "Flaming locks of auburn hair",
    "number": "2236",
  },
  {
    "artist": "Dolly Parton",
    "song": "Joshua",
    "year": "1970",
    "seconds": "60",
    "lyric": "And pull you up a chair",
    "number": "2237",
  },
  {
    "artist": "Three Dog Night",
    "song": "Joy to the World",
    "year": "1971",
    "seconds": "70",
    "lyric": "The fishes in the deep blue sea",
    "number": "2238",
  },
  {
    "artist": "Lady Gaga",
    "song": "Judas",
    "year": "2011",
    "seconds": "40",
    "lyric": "I’ll wash his feet",
    "number": "2239",
  },
  {
    "artist": "John Fred & His Playboy Band",
    "song": "Judy in Disguise (With Glasses)",
    "year": "1968",
    "seconds": "60",
    "lyric": "Unzipper the strings of my kite",
    "number": "2240",
  },
  {
    "artist": "Lesley Gore",
    "song": "Judy’s Turn to Cry",
    "year": "1963",
    "seconds": "50",
    "lyric": "Johnny’s come back to me",
    "number": "2241",
  },
  {
    "artist": "Foreigner",
    "song": "Jukebox Hero",
    "year": "1981",
    "seconds": "40",
    "lyric": "Slung way down low",
    "number": "2242",
  },
  {
    "artist": "Conway Twitty",
    "song": "Julia",
    "year": "1987",
    "seconds": "60",
    "lyric": "So afraid to fall in love",
    "number": "2243",
  },
  {
    "artist": "Bobby Sherman",
    "song": "Julie, Do Ya Love Me?",
    "year": "1970",
    "seconds": "50",
    "lyric": "I’ll write you every day",
    "number": "2244",
  },
  {
    "artist": "Van Halen",
    "song": "Jump",
    "year": "1983",
    "seconds": "50",
    "lyric": "To roll with the punches",
    "number": "2245",
  },
  {
    "artist": "Rolling Stones",
    "song": "Jumpin’ Jack Flash",
    "year": "1968",
    "seconds": "60",
    "lyric": "A strap right across my back",
    "number": "2246",
  },
  {
    "artist": "X Ambassadors, Jamie N Commons",
    "song": "Jungle",
    "year": "2013",
    "seconds": "50",
    "lyric": "Box me into the corner",
    "number": "2247",
  },
  {
    "artist": "Bruce Springsteen",
    "song": "Jungleland",
    "year": "1975",
    "seconds": "50",
    "lyric": "Outside the street’s on fire",
    "number": "2248",
  },
  {
    "artist": "Paul McCartney & Wings",
    "song": "Junior’s Farm",
    "year": "1974",
    "seconds": "50",
    "lyric": "Up popped a sea lion",
    "number": "2249",
  },
  {
    "artist": "Carrie Underwood",
    "song": "Just a Dream",
    "year": "2008",
    "seconds": "60",
    "lyric": "The church doors opened up wide",
    "number": "2250",
  },
  {
    "artist": "Nelly",
    "song": "Just a Dream",
    "year": "2010",
    "seconds": "40",
    "lyric": "I’m in the basement",
    "number": "2251",
  },
  {
    "artist": "Crosby, Stills & Nash",
    "song": "Just a Song Before I Go",
    "year": "1977",
    "seconds": "50",
    "lyric": "Driving me to the airport",
    "number": "2252",
  },
  {
    "artist": "Tanya Tucker",
    "song": "Just Another Love",
    "year": "1986",
    "seconds": "50",
    "lyric": "On your list of hearts",
    "number": "2253",
  },
  {
    "artist": "Anne Murray",
    "song": "Just Another Woman in Love",
    "year": "1984",
    "seconds": "50",
    "lyric": "Still, I fumble and fall",
    "number": "2254",
  },
  {
    "artist": "Depeche Mode",
    "song": "Just Can’t Get Enough",
    "year": "1981",
    "seconds": "40",
    "lyric": "We slip and slide",
    "number": "2255",
  },
  {
    "artist": "The Black Eyed Peas",
    "song": "Just Can’t Get Enough",
    "year": "2011",
    "seconds": "40",
    "lyric": "Drown inside your love",
    "number": "2256",
  },
  {
    "artist": "Lady Gaga (ft. Colby O’Donis)",
    "song": "Just Dance",
    "year": "2008",
    "seconds": "60",
    "lyric": "I turn my shirt inside out",
    "number": "2257",
  },
  {
    "artist": "Trace Adkins",
    "song": "Just Fishin’",
    "year": "2011",
    "seconds": "50",
    "lyric": "Talkin’ ‘bout her ballet shoes",
    "number": "2258",
  },
  {
    "artist": "Jason Aldean",
    "song": "Just Getting’ Started",
    "year": "2014",
    "seconds": "50",
    "lyric": "Two miles down the road",
    "number": "2259",
  },
  {
    "artist": "James Otto",
    "song": "Just Got Started Lovin’ You",
    "year": "2008",
    "seconds": "50",
    "lyric": "But two days in heaven",
    "number": "2260",
  },
  {
    "artist": "Bob Dylan",
    "song": "Just Like a Woman",
    "year": "1966",
    "seconds": "70",
    "lyric": "I see her ribbons and her bows",
    "number": "2261",
  },
  {
    "artist": "The Cure",
    "song": "Just Like Heaven",
    "year": "1987",
    "seconds": "50",
    "lyric": "Dancing in the deepest oceans",
    "number": "2262",
  },
  {
    "artist": "Cher",
    "song": "Just Like Jesse James",
    "year": "1989",
    "seconds": "60",
    "lyric": "The flash of your pearly smile",
    "number": "2263",
  },
  {
    "artist": "Marty Robbins",
    "song": "Just Married",
    "year": "1958",
    "seconds": "50",
    "lyric": "White lace in her hair",
    "number": "2264",
  },
  {
    "artist": "The Temptations",
    "song": "Just My Imagination",
    "year": "1971",
    "seconds": "50",
    "lyric": "Married and raise a family",
    "number": "2265",
  },
  {
    "artist": "Doris Troy",
    "song": "Just One Look",
    "year": "1963",
    "seconds": "50",
    "lyric": "And I fell so hard",
    "number": "2266",
  },
  {
    "artist": "George Jones",
    "song": "Just One More",
    "year": "1956",
    "seconds": "60",
    "lyric": "Put the bottle on the table",
    "number": "2267",
  },
  {
    "artist": "Bill Withers & Grover Washington Jr.",
    "song": "Just the Two of Us",
    "year": "1981",
    "seconds": "60",
    "lyric": "I see the crystal raindrops fall",
    "number": "2268",
  },
  {
    "artist": "Billy Joel",
    "song": "Just the Way You Are",
    "year": "1978",
    "seconds": "70",
    "lyric": "Change the color of your hair",
    "number": "2269",
  },
  {
    "artist": "Bruno Mars",
    "song": "Just the Way You Are",
    "year": "2010",
    "seconds": "60",
    "lyric": "The whole world stops and stares",
    "number": "2270",
  },
  {
    "artist": "The Commodores",
    "song": "Just to Be Close to You",
    "year": "1976",
    "seconds": "60",
    "lyric": "And opening my arms to you",
    "number": "2271",
  },
  {
    "artist": "Tim McGraw and Faith Hill",
    "song": "Just to Hear You Say That You Love Me",
    "year": "1998",
    "seconds": "60",
    "lyric": "Climb right up to the sky",
    "number": "2272",
  },
  {
    "artist": "Tim McGraw",
    "song": "Just to See You Smile",
    "year": "1997",
    "seconds": "40",
    "lyric": "Rollin’ down your face",
    "number": "2273",
  },
  {
    "artist": "Waylon & Willie",
    "song": "Just to Satisfy You",
    "year": "1982",
    "seconds": "40",
    "lyric": "Hurt before you’re through",
    "number": "2274",
  },
  {
    "artist": "DJ Khaled (feat. SZA)",
    "song": "Just Us",
    "year": "2019",
    "seconds": "50",
    "lyric": "Straight shooter from the hip",
    "number": "2275",
  },
  {
    "artist": "The Cars",
    "song": "Just What I Needed",
    "year": "1978",
    "seconds": "50",
    "lyric": "The ribbons in your hair",
    "number": "2276",
  },
  {
    "artist": "Chicago",
    "song": "Just You ‘n’ Me",
    "year": "1973",
    "seconds": "70",
    "lyric": "Open your arms, let my love in",
    "number": "2277",
  },
  {
    "artist": "Wilbert Harrison",
    "song": "Kansas City",
    "year": "1959",
    "seconds": "50",
    "lyric": "Some crazy lil’ women there",
    "number": "2278",
  },
  {
    "artist": "Culture Club",
    "song": "Karma Chameleon",
    "year": "1983",
    "seconds": "60",
    "lyric": "My dreams red, gold and green",
    "number": "2279",
  },
  {
    "artist": "Led Zeppelin",
    "song": "Kashmir",
    "year": "1975",
    "seconds": "50",
    "lyric": "The sun burns the ground",
    "number": "2280",
  },
  {
    "artist": "Johnny Cash",
    "song": "Kate",
    "year": "1972",
    "seconds": "50",
    "lyric": "Just thirteen steps to go",
    "number": "2281",
  },
  {
    "artist": "Ricky Van Shelton",
    "song": "Keep It Between the Lines",
    "year": "1991",
    "seconds": "50",
    "lyric": "I looked through the windshield",
    "number": "2282",
  },
  {
    "artist": "KC & the Sunshine Band",
    "song": "Keep It Comin’ Love",
    "year": "1977",
    "seconds": "60",
    "lyric": "Don’t let your well run dry",
    "number": "2283",
  },
  {
    "artist": "Zac Brown Band",
    "song": "Keep Me in Mind",
    "year": "2011",
    "seconds": "40",
    "lyric": "I’ll hold you darling",
    "number": "2284",
  },
  {
    "artist": "Pink Floyd",
    "song": "Keep Talking",
    "year": "1994",
    "seconds": "50",
    "lyric": "I sit in the corner",
    "number": "2285",
  },
  {
    "artist": "Ram Jam",
    "song": "Keep Your Hands on the Whhel",
    "year": "1977",
    "seconds": "60",
    "lyric": "She said “Don’t look at me”",
    "number": "2286",
  },
  {
    "artist": "Georgia Satellites",
    "song": "Keep Your Hands to Yourself",
    "year": "1986",
    "seconds": "70",
    "lyric": "Got a little change in my pocket",
    "number": "2287",
  },
  {
    "artist": "Christina Aguilera",
    "song": "Keeps Gettin’ Better",
    "year": "2008",
    "seconds": "60",
    "lyric": "You see the vixen in me",
    "number": "2288",
  },
  {
    "artist": "Bill Monroe",
    "song": "Kentucky Waltz",
    "year": "1951",
    "seconds": "60",
    "lyric": "I can see your smiling face",
    "number": "2289",
  },
  {
    "artist": "Perry Como",
    "song": "Kewpie Doll",
    "year": "1958",
    "seconds": "50",
    "lyric": "Five shots for a quarter",
    "number": "2290",
  },
  {
    "artist": "Luke Bryan",
    "song": "Kick the Dust Up",
    "year": "2015",
    "seconds": "50",
    "lyric": "Baby, watch your step now",
    "number": "2291",
  },
  {
    "artist": "MGMT",
    "song": "Kids",
    "year": "2007",
    "seconds": "40",
    "lyric": "Crawling on your knees",
    "number": "2292",
  },
  {
    "artist": "The Bellamy Brothers",
    "song": "Kids of the Baby Boom",
    "year": "1987",
    "seconds": "50",
    "lyric": "Mickey Mouse and hula hoops",
    "number": "2293",
  },
  {
    "artist": "Queen",
    "song": "Killer Queen",
    "year": "1974",
    "seconds": "40",
    "lyric": "Drop of a hat",
    "number": "2294",
  },
  {
    "artist": "Roberta Flack",
    "song": "Killing Me Softly With His Song",
    "year": "1973",
    "seconds": "60",
    "lyric": "Strumming my pain with his fingers",
    "number": "2295",
  },
  {
    "artist": "Clint Black",
    "song": "Killin’ Time",
    "year": "1989",
    "seconds": "60",
    "lyric": "A tight grip on the bottle",
    "number": "2296",
  },
  {
    "artist": "Tina Cousins",
    "song": "Killin’ Time",
    "year": "2000",
    "seconds": "60",
    "lyric": "I feel every breath you take",
    "number": "2297",
  },
  {
    "artist": "The Buckinghams",
    "song": "Kind of a Drag",
    "year": "1967",
    "seconds": "50",
    "lyric": "When your baby says goodbye",
    "number": "2298",
  },
  {
    "artist": "Sam Hunt",
    "song": "Kinfolks",
    "year": "2019",
    "seconds": "50",
    "lyric": "The house in the pines",
    "number": "2299",
  },
  {
    "artist": "Roger Miller",
    "song": "King of the Road",
    "year": "1965",
    "seconds": "50",
    "lyric": "Rooms to let, fifty cents",
    "number": "2300",
  },
  {
    "artist": "Ava Max",
    "song": "Kings and Queens",
    "year": "2020",
    "seconds": "60",
    "lyric": "Pop champagne and raise a toast",
    "number": "2301",
  },
  {
    "artist": "Lil Peep",
    "song": "Kiss",
    "year": "2016",
    "seconds": "60",
    "lyric": "Our first kiss by the tree",
    "number": "2302",
  },
  {
    "artist": "Prince",
    "song": "Kiss",
    "year": "1986",
    "seconds": "60",
    "lyric": "My love will be your food",
    "number": "2303",
  },
  {
    "artist": "Keith Urban",
    "song": "Kiss a Girl",
    "year": "2009",
    "seconds": "50",
    "lyric": "Broke it right in two",
    "number": "2304",
  },
  {
    "artist": "Charley Pride",
    "song": "Kiss an Angel Good Mornin’",
    "year": "1971",
    "seconds": "70",
    "lyric": "A woman and a man in love",
    "number": "2305",
  },
  {
    "artist": "The Manhattans",
    "song": "Kiss and Say Goodbye",
    "year": "1976",
    "seconds": "60",
    "lyric": "Hold you just one more time",
    "number": "2306",
  },
  {
    "artist": "Seal",
    "song": "Kiss from a Rose",
    "year": "1994",
    "seconds": "40",
    "lyric": "My eyes become large",
    "number": "2307",
  },
  {
    "artist": "Sixpence None the Richer",
    "song": "Kiss Me",
    "year": "1997",
    "seconds": "50",
    "lyric": "Down by the broken treehouse",
    "number": "2308",
  },
  {
    "artist": "Tony Martin",
    "song": "Kiss of Fire",
    "year": "1952",
    "seconds": "40",
    "lyric": "Give me your lips",
    "number": "2309",
  },
  {
    "artist": "Hall & Oates",
    "song": "Kiss on my List",
    "year": "1980",
    "seconds": "60",
    "lyric": "When I turn out the light",
    "number": "2310",
  },
  {
    "artist": "Siouxsie & the Banshees",
    "song": "Kiss Them For Me",
    "year": "1991",
    "seconds": "50",
    "lyric": "In the fountains, pink champagne",
    "number": "2311",
  },
  {
    "artist": "Luke Bryan",
    "song": "Kiss Tomorrow Goodbye",
    "year": "2012",
    "seconds": "60",
    "lyric": "Rest your head one more time",
    "number": "2312",
  },
  {
    "artist": "Exile",
    "song": "Kiss You All Over",
    "year": "1978",
    "seconds": "50",
    "lyric": "Wrap my arms around you",
    "number": "2313",
  },
  {
    "artist": "Neneh Cherry",
    "song": "Kisses on the Wind",
    "year": "1989",
    "seconds": "50",
    "lyric": "Hanging washing on the line",
    "number": "2314",
  },
  {
    "artist": "Joel Newman",
    "song": "Kisses Sweeter Than Wine",
    "year": "1950",
    "seconds": "50",
    "lyric": "And be my sweet wife",
    "number": "2315",
  },
  {
    "artist": "Zac Brown Band (ft. Jimmy Buffett)",
    "song": "Knee Deep",
    "year": "2011",
    "seconds": "50",
    "lyric": "Grab a backpack of lies",
    "number": "2316",
  },
  {
    "artist": "Marty Robbins",
    "song": "Knee Deep in the Blues",
    "year": "1957",
    "seconds": "60",
    "lyric": "Walk so soft in my shoes",
    "number": "2317",
  },
  {
    "artist": "Dawn",
    "song": "Knock Three Times",
    "year": "1970",
    "seconds": "60",
    "lyric": "I can feel your body swaying",
    "number": "2318",
  },
  {
    "artist": "Keri Hilson (ft. Kanye West and Ne-Yo)",
    "song": "Knock You Down",
    "year": "2009",
    "seconds": "60",
    "lyric": "I look at you and smile",
    "number": "2319",
  },
  {
    "artist": "Luke Bryan",
    "song": "Knockin’ Boots",
    "year": "2019",
    "seconds": "60",
    "lyric": "Shades need drawin’, hearts need fallin’",
    "number": "2320",
  },
  {
    "artist": "Bob Dylan",
    "song": "Knockin’ On Heaven’s Door",
    "year": "1973",
    "seconds": "60",
    "lyric": "Long black cloud is comin’ down",
    "number": "2321",
  },
  {
    "artist": "Green Day",
    "song": "Know Your Eneny",
    "year": "2009",
    "seconds": "70",
    "lyric": "Blinded by the lies in your eyes",
    "number": "2322",
  },
  {
    "artist": "Rage Against the Machine",
    "song": "Know Your Eneny",
    "year": "1992",
    "seconds": "50",
    "lyric": "A badge and a gun",
    "number": "2323",
  },
  {
    "artist": "ABBA",
    "song": "Knowing Me, Knowing You",
    "year": "1977",
    "seconds": "40",
    "lyric": "This time we’re through",
    "number": "2324",
  },
  {
    "artist": "Perry Como",
    "song": "Ko Ko Mo (I Love You So)",
    "year": "1955",
    "seconds": "40",
    "lyric": "Dimples on her elbows",
    "number": "2325",
  },
  {
    "artist": "Paul Simon",
    "song": "Kodachrome",
    "year": "1973",
    "seconds": "60",
    "lyric": "I love to take a photograph",
    "number": "2326",
  },
  {
    "artist": "Beach Boys",
    "song": "Kokomo",
    "year": "1988",
    "seconds": "50",
    "lyric": "Dreamy look in your eye",
    "number": "2327",
  },
  {
    "artist": "Jerry Reed",
    "song": "Ko-Ko Joe",
    "year": "1971",
    "seconds": "60",
    "lyric": "The water washed away the bridge",
    "number": "2328",
  },
  {
    "artist": "3 Doors Down",
    "song": "Kryptonite",
    "year": "2000",
    "seconds": "70",
    "lyric": "You stumbled in and bumped your head",
    "number": "2329",
  },
  {
    "artist": "Carl Douglas",
    "song": "Kung Fu Fighting",
    "year": "1974",
    "seconds": "50",
    "lyric": "Kids were fast as lightning",
    "number": "2330",
  },
  {
    "artist": "The Doors",
    "song": "L.A. Woman",
    "year": "1971",
    "seconds": "50",
    "lyric": "Hills are filled with fire",
    "number": "2331",
  },
  {
    "artist": "ZZ Top",
    "song": "La Grange",
    "year": "1973",
    "seconds": "50",
    "lyric": "If you got the time",
    "number": "2332",
  },
  {
    "artist": "Kool & the Gang",
    "song": "Ladies’ Night",
    "year": "1979",
    "seconds": "60",
    "lyric": "You smile, the guys go wild",
    "number": "2333",
  },
  {
    "artist": "Kenny Rogers",
    "song": "Lady",
    "year": "1980",
    "seconds": "50",
    "lyric": "Your knight in shining armor",
    "number": "2334",
  },
  {
    "artist": "Styx",
    "song": "Lady",
    "year": "1975",
    "seconds": "50",
    "lyric": "Your hands build me up",
    "number": "2335",
  },
  {
    "artist": "Peter and Gordon",
    "song": "Lady Godiva",
    "year": "1966",
    "seconds": "50",
    "lyric": "Lyin’ on the barber’s floor",
    "number": "2336",
  },
  {
    "artist": "The Beatles",
    "song": "Lady Madonna",
    "year": "1968",
    "seconds": "50",
    "lyric": "Learned to tie his bootlace",
    "number": "2337",
  },
  {
    "artist": "Patti LaBelle",
    "song": "Lady Marmalade",
    "year": "1974",
    "seconds": "60",
    "lyric": "Touching her skin feelin’ silky smooth",
    "number": "2338",
  },
  {
    "artist": "Gary Puckett & the Union Gap",
    "song": "Lady Willpower",
    "year": "1968",
    "seconds": "40",
    "lyric": "I’ll shower your heart",
    "number": "2339",
  },
  {
    "artist": "The Commodores",
    "song": "Lady (You Bring Me Up)",
    "year": "1981",
    "seconds": "40",
    "lyric": "I’m ten feet tall",
    "number": "2340",
  },
  {
    "artist": "Wilson Pickett",
    "song": "Land of a Thousand Dances",
    "year": "1966",
    "seconds": "60",
    "lyric": "Put your hands on your hips",
    "number": "2341",
  },
  {
    "artist": "Fleetwood Mac",
    "song": "Landslide",
    "year": "1975",
    "seconds": "60",
    "lyric": "Climb a mountain and turn around",
    "number": "2342",
  },
  {
    "artist": "Aerosmith",
    "song": "Last Child",
    "year": "1976",
    "seconds": "50",
    "lyric": "The mule in the stable",
    "number": "2343",
  },
  {
    "artist": "Wham!",
    "song": "Last Christmas",
    "year": "1984",
    "seconds": "50",
    "lyric": "I gave you my heart",
    "number": "2344",
  },
  {
    "artist": "Donna Summer",
    "song": "Last Dance",
    "year": "1978",
    "seconds": "60",
    "lyric": "To hold me, to scold me",
    "number": "2345",
  },
  {
    "artist": "Skeeter Davis",
    "song": "Last Date",
    "year": "1961",
    "seconds": "60",
    "lyric": "I know we had a quarrel",
    "number": "2346",
  },
  {
    "artist": "Tim McGraw",
    "song": "Last Dollar (Fly Away)",
    "year": "2007",
    "seconds": "60",
    "lyric": "Keep my feet on the ground",
    "number": "2347",
  },
  {
    "artist": "Jeff Buckley",
    "song": "Last Goodbye",
    "year": "1994",
    "seconds": "60",
    "lyric": "So hard on her soft eyes",
    "number": "2348",
  },
  {
    "artist": "Carrie Underwood",
    "song": "Last Name",
    "year": "2008",
    "seconds": "60",
    "lyric": "Sitting there in the parking lot",
    "number": "2349",
  },
  {
    "artist": "The 5th Dimension",
    "song": "(Last Night) I Didn’t Get to Sleep At All",
    "year": "1972",
    "seconds": "50",
    "lyric": "The dreams I left behind",
    "number": "2350",
  },
  {
    "artist": "Ed McCurdy",
    "song": "Last Night I Had the Strangest Dream",
    "year": "1950",
    "seconds": "60",
    "lyric": "And the paper they were signing",
    "number": "2351",
  },
  {
    "artist": "The Strokes",
    "song": "Last Nite",
    "year": "2001",
    "seconds": "50",
    "lyric": "I’m walking out that door",
    "number": "2352",
  },
  {
    "artist": "Edward Bear",
    "song": "Last Song",
    "year": "1973",
    "seconds": "60",
    "lyric": "You’ll come looking for the light",
    "number": "2353",
  },
  {
    "artist": "Willie Nelson",
    "song": "Last Thing I Needed First Thing This Morning",
    "year": "1982",
    "seconds": "50",
    "lyric": "I spilled all the coffee",
    "number": "2354",
  },
  {
    "artist": "The Monkees",
    "song": "Last Train to Clarksville",
    "year": "1966",
    "seconds": "60",
    "lyric": "I must hang up the phone",
    "number": "2355",
  },
  {
    "artist": "Paul Simon",
    "song": "Late in the Evening",
    "year": "1980",
    "seconds": "60",
    "lyric": "And all the music seeping through",
    "number": "2356",
  },
  {
    "artist": "Jodeci",
    "song": "Lately",
    "year": "1993",
    "seconds": "60",
    "lyric": "I’ve been staring in the mirror",
    "number": "2357",
  },
  {
    "artist": "Sonny & Cher",
    "song": "Laugh at Me",
    "year": "1966",
    "seconds": "50",
    "lyric": "Walk by and say “Hi”",
    "number": "2358",
  },
  {
    "artist": "The Beau Brummels",
    "song": "Laugh, Laugh",
    "year": "1965",
    "seconds": "70",
    "lyric": "I’m glad he packed up to go",
    "number": "2359",
  },
  {
    "artist": "The Guess Who",
    "song": "Laughing",
    "year": "1969",
    "seconds": "60",
    "lyric": "You put the hurt on me",
    "number": "2360",
  },
  {
    "artist": "Dinah Shore",
    "song": "Laughing on the Outside (Crying on the Inside)",
    "year": "1946",
    "seconds": "60",
    "lyric": "I’m still in love with you",
    "number": "2361",
  },
  {
    "artist": "Neil Sedaka",
    "song": "Laughter in the Rain",
    "year": "1974",
    "seconds": "40",
    "lyric": "Walking hand in hand",
    "number": "2362",
  },
  {
    "artist": "Dinah Shore",
    "song": "Lavender Blue (Dilly Dilly)",
    "year": "1948",
    "seconds": "50",
    "lyric": "In a pretty little church",
    "number": "2363",
  },
  {
    "artist": "Melanie",
    "song": "Lay Down (Candles in the Rain)",
    "year": "1970",
    "seconds": "50",
    "lyric": "Your white birds smile up",
    "number": "2364",
  },
  {
    "artist": "Eric Clapton",
    "song": "Lay Down Sally",
    "year": "1977",
    "seconds": "50",
    "lyric": "Just to talk to you",
    "number": "2365",
  },
  {
    "artist": "Bob Dylan",
    "song": "Lay Lady Lay",
    "year": "1969",
    "seconds": "50",
    "lyric": "Across my big, brass bed",
    "number": "2366",
  },
  {
    "artist": "Derek & the Dominos",
    "song": "Layla",
    "year": "1970",
    "seconds": "60",
    "lyric": "You’ve got me on my knees",
    "number": "2367",
  },
  {
    "artist": "Chic",
    "song": "Le Freak",
    "year": "1978",
    "seconds": "60",
    "lyric": "And you too will be sold",
    "number": "2368",
  },
  {
    "artist": "The Shangri-Las",
    "song": "Leader of the Pack",
    "year": "1964",
    "seconds": "50",
    "lyric": "Smiled and kissed me goodbye",
    "number": "2369",
  },
  {
    "artist": "Bill Withers",
    "song": "Lean on Me",
    "year": "1972",
    "seconds": "40",
    "lyric": "Please swallow your pride",
    "number": "2370",
  },
  {
    "artist": "Foo Fighters",
    "song": "Learn to Fly",
    "year": "1999",
    "seconds": "70",
    "lyric": "Run and tell all of the angels",
    "number": "2371",
  },
  {
    "artist": "Pink Floyd",
    "song": "Learning to Fly",
    "year": "1987",
    "seconds": "60",
    "lyric": "The corner of my watering eye",
    "number": "2372",
  },
  {
    "artist": "Tom Petty & the Heartbreakers",
    "song": "Learning To Fly",
    "year": "1991",
    "seconds": "60",
    "lyric": "Break your heart, steal your crown",
    "number": "2373",
  },
  {
    "artist": "Sam Hunt",
    "song": "Leave the Night On",
    "year": "2014",
    "seconds": "60",
    "lyric": "All the stars are turnin’ blue",
    "number": "2374",
  },
  {
    "artist": "The Wreckers",
    "song": "Leave the Pieces",
    "year": "2006",
    "seconds": "70",
    "lyric": "Take your love and hit the road",
    "number": "2375",
  },
  {
    "artist": "Faron Young",
    "song": "Leavin’ and Sayin’ Goodbye",
    "year": "1971",
    "seconds": "50",
    "lyric": "Afraid to cross the street",
    "number": "2376",
  },
  {
    "artist": "The Oak Ridge Boys",
    "song": "Leaving Lousiana in the Broad Daylight",
    "year": "1979",
    "seconds": "40",
    "lyric": "It looked so right",
    "number": "2377",
  },
  {
    "artist": "John Denver",
    "song": "Leaving on a Jet Plane",
    "year": "1966",
    "seconds": "50",
    "lyric": "I’ll bring your wedding ring",
    "number": "2378",
  },
  {
    "artist": "Carly Simon",
    "song": "Legend in Your Own Time",
    "year": "1972",
    "seconds": "50",
    "lyric": "A hero in the footlights",
    "number": "2379",
  },
  {
    "artist": "ZZ Top",
    "song": "Legs",
    "year": "1984",
    "seconds": "60",
    "lyric": "She’s so fine, she’s all mine",
    "number": "2380",
  },
  {
    "artist": "Engelbert Humperdinck",
    "song": "Les Bicyclettes de Belsize",
    "year": "1968",
    "seconds": "70",
    "lyric": "And hand in hand we will ride",
    "number": "2381",
  },
  {
    "artist": "Wings",
    "song": "Let ‘Em In",
    "year": "1976",
    "seconds": "50",
    "lyric": "Someone knocking at the door",
    "number": "2382",
  },
  {
    "artist": "Hootie & the Blowfish",
    "song": "Let Her Cry",
    "year": "1994",
    "seconds": "60",
    "lyric": "The tears fall down like rain",
    "number": "2383",
  },
  {
    "artist": "The Beatles",
    "song": "Let It Be",
    "year": "1970",
    "seconds": "60",
    "lyric": "And in my hour of darkness",
    "number": "2384",
  },
  {
    "artist": "The Everly Brothers",
    "song": "Let It Be Me",
    "year": "1959",
    "seconds": "60",
    "lyric": "Tell me you love me only",
    "number": "2385",
  },
  {
    "artist": "Idina Menzel",
    "song": "Let It Go",
    "year": "2014",
    "seconds": "60",
    "lyric": "No right, no wrong, no rules",
    "number": "2386",
  },
  {
    "artist": "Tim McGraw",
    "song": "Let It Go",
    "year": "2008",
    "seconds": "50",
    "lyric": "Hold my head up high",
    "number": "2387",
  },
  {
    "artist": "Bachman Turner Overdrive",
    "song": "Let It Ride",
    "year": "1973",
    "seconds": "50",
    "lyric": "Goodbye, I lied, don’t cry",
    "number": "2388",
  },
  {
    "artist": "Dean Martin",
    "song": "Let It Snow! Let It Snow! Let It Snow!",
    "year": "1959",
    "seconds": "70",
    "lyric": "All the way home, I’ll be warm",
    "number": "2389",
  },
  {
    "artist": "Olivia Newton-John",
    "song": "Let Me Be There",
    "year": "1974",
    "seconds": "70",
    "lyric": "You need a friend to lean on",
    "number": "2390",
  },
  {
    "artist": "Elvis Presley",
    "song": "(Let Me Be Your) Teddy Bear",
    "year": "1957",
    "seconds": "60",
    "lyric": "Put a chain around my neck",
    "number": "2391",
  },
  {
    "artist": "Faith Hill",
    "song": "Let Me Let Go",
    "year": "1998",
    "seconds": "50",
    "lyric": "Find the bright side baby",
    "number": "2392",
  },
  {
    "artist": "Ne-Yo",
    "song": "Let Me Love You",
    "year": "2012",
    "seconds": "50",
    "lyric": "The pain behind your eyes",
    "number": "2393",
  },
  {
    "artist": "Boston",
    "song": "Let Me Take You Home Tonight",
    "year": "1976",
    "seconds": "60",
    "lyric": "I’ve watched you for so long",
    "number": "2394",
  },
  {
    "artist": "Ronnie Milsap",
    "song": "Let My Love Be Your Pillow",
    "year": "1976",
    "seconds": "50",
    "lyric": "Rest your head on me",
    "number": "2395",
  },
  {
    "artist": "The Bellamy Brothers",
    "song": "Let Your Love Flow",
    "year": "1976",
    "seconds": "70",
    "lyric": "Fly like a bird on a wing",
    "number": "2396",
  },
  {
    "artist": "Lonestar",
    "song": "Let’s Be Us Again",
    "year": "2004",
    "seconds": "50",
    "lyric": "I’m reaching out for you",
    "number": "2397",
  },
  {
    "artist": "Chris Montez",
    "song": "Let’s Dance",
    "year": "1962",
    "seconds": "60",
    "lyric": "The stomp, the mashed potato too",
    "number": "2398",
  },
  {
    "artist": "David Bowie",
    "song": "Let’s Dance",
    "year": "1983",
    "seconds": "50",
    "lyric": "Put on your red shoes",
    "number": "2399",
  },
  {
    "artist": "Ella Fitzgerald",
    "song": "Let’s Do It (Let’s Fall in Love)",
    "year": "1956",
    "seconds": "60",
    "lyric": "Birds do it, bees do it",
    "number": "2400",
  },
  {
    "artist": "George Strait",
    "song": "Let’s Fall to Pieces Together",
    "year": "1984",
    "seconds": "70",
    "lyric": "You left your tears on the jukebox",
    "number": "2401",
  },
  {
    "artist": "Marvin Gaye",
    "song": "Let’s Get It On",
    "year": "1973",
    "seconds": "50",
    "lyric": "Stop beatin’ ‘round the bush",
    "number": "2402",
  },
  {
    "artist": "The Black Eyed Peas",
    "song": "Let’s Get It Started",
    "year": "2004",
    "seconds": "40",
    "lyric": "Lose your mind, what?",
    "number": "2403",
  },
  {
    "artist": "The Cars",
    "song": "Let’s Go",
    "year": "1979",
    "seconds": "50",
    "lyric": "She doesn’t wear her shoes",
    "number": "2404",
  },
  {
    "artist": "Prince & the Revolution",
    "song": "Let’s Go Crazy",
    "year": "1984",
    "seconds": "50",
    "lyric": "She picked up the phone",
    "number": "2405",
  },
  {
    "artist": "Faith Hill",
    "song": "Let’s Go To Vegas",
    "year": "1995",
    "seconds": "50",
    "lyric": "Kiss the single life goodbye",
    "number": "2406",
  },
  {
    "artist": "Earth, Wind and Fire",
    "song": "Let’s Groove",
    "year": "1981",
    "seconds": "60",
    "lyric": "You will find peace of mind",
    "number": "2407",
  },
  {
    "artist": "The 4 Seasons",
    "song": "Let’s Hang On",
    "year": "1965",
    "seconds": "60",
    "lyric": "And break our love to bits",
    "number": "2408",
  },
  {
    "artist": "Deniece Williams",
    "song": "Let’s Hear It For the Boy",
    "year": "1984",
    "seconds": "40",
    "lyric": "My lovin’ one-man show",
    "number": "2409",
  },
  {
    "artist": "Bryan Adams",
    "song": "Let’s Make A Night To Remember",
    "year": "1996",
    "seconds": "60",
    "lyric": "Hair hangin’ down on your shoulders",
    "number": "2410",
  },
  {
    "artist": "Rolling Stones",
    "song": "Let’s Spend the Night Together",
    "year": "1967",
    "seconds": "50",
    "lyric": "And my tongue’s getting tied",
    "number": "2411",
  },
  {
    "artist": "Al Green",
    "song": "Let’s Stay Together",
    "year": "1972",
    "seconds": "60",
    "lyric": "I’m so in love with you",
    "number": "2412",
  },
  {
    "artist": "Ronnie Milsap",
    "song": "Let’s Take the Long Way Around the World",
    "year": "1978",
    "seconds": "70",
    "lyric": "Take your hand and take a walk",
    "number": "2413",
  },
  {
    "artist": "Chubby Checker",
    "song": "Let’s Twist Again",
    "year": "1961",
    "seconds": "50",
    "lyric": "Like we did last summer",
    "number": "2414",
  },
  {
    "artist": "Janet Jackson",
    "song": "Let’s Wait Awhile",
    "year": "1987",
    "seconds": "50",
    "lyric": "Try to talk it over",
    "number": "2415",
  },
  {
    "artist": "Brad Paisley",
    "song": "Letter to Me",
    "year": "2007",
    "seconds": "70",
    "lyric": "A knife sticking out of your back",
    "number": "2416",
  },
  {
    "artist": "John Michael Montgomery",
    "song": "Letters from Home",
    "year": "2004",
    "seconds": "60",
    "lyric": "And I showed her the ring",
    "number": "2417",
  },
  {
    "artist": "Dua Lipa",
    "song": "Levitating",
    "year": "2020",
    "seconds": "60",
    "lyric": "You can fly away with me",
    "number": "2418",
  },
  {
    "artist": "Elton John",
    "song": "Levon",
    "year": "1971",
    "seconds": "70",
    "lyric": "And he shall be a good man",
    "number": "2419",
  },
  {
    "artist": "Three Dog Night",
    "song": "Liar",
    "year": "1971",
    "seconds": "40",
    "lyric": "So take my soul",
    "number": "2420",
  },
  {
    "artist": "Boz Scaggs",
    "song": "Lido Shuffle",
    "year": "1977",
    "seconds": "50",
    "lyric": "One more for the road",
    "number": "2421",
  },
  {
    "artist": "BTS",
    "song": "Life Goes On",
    "year": "2020",
    "seconds": "60",
    "lyric": "On my pillow, on my table",
    "number": "2422",
  },
  {
    "artist": "The Dream Academy",
    "song": "Life in a Northern Town",
    "year": "1985",
    "seconds": "50",
    "lyric": "And the children drank lemonade",
    "number": "2423",
  },
  {
    "artist": "Eagles",
    "song": "Life in the Fast Lane",
    "year": "1977",
    "seconds": "50",
    "lyric": "The lights are turnin’ red",
    "number": "2424",
  },
  {
    "artist": "Joe Walsh",
    "song": "Life’s Been Good",
    "year": "1978",
    "seconds": "50",
    "lyric": "Gold records on the wall",
    "number": "2425",
  },
  {
    "artist": "The Doors",
    "song": "Light my Fire",
    "year": "1967",
    "seconds": "60",
    "lyric": "The time to hesitate is through",
    "number": "2426",
  },
  {
    "artist": "Maggie Rogers",
    "song": "Light On",
    "year": "2018",
    "seconds": "50",
    "lyric": "Caught up in a wave",
    "number": "2427",
  },
  {
    "artist": "Lou Christie",
    "song": "Lightnin’ Strikes",
    "year": "1966",
    "seconds": "60",
    "lyric": "And she’s readin’ my mind",
    "number": "2428",
  },
  {
    "artist": "Ellie Goulding",
    "song": "Lights",
    "year": "2011",
    "seconds": "60",
    "lyric": "My brother and my sister slept",
    "number": "2429",
  },
  {
    "artist": "Journey",
    "song": "Lights",
    "year": "1978",
    "seconds": "60",
    "lyric": "The sun shines on the bay",
    "number": "2430",
  },
  {
    "artist": "Harry Styles",
    "song": "Lights Up",
    "year": "2019",
    "seconds": "40",
    "lyric": "Running through my heart",
    "number": "2431",
  },
  {
    "artist": "Dallas Smith",
    "song": "Like a Man",
    "year": "2020",
    "seconds": "40",
    "lyric": "Drive your heart wild",
    "number": "2432",
  },
  {
    "artist": "Madonna",
    "song": "Like a Prayer",
    "year": "1989",
    "seconds": "60",
    "lyric": "I hear you call my name",
    "number": "2433",
  },
  {
    "artist": "Bob Dylan",
    "song": "Like a Rolling Stone",
    "year": "1965",
    "seconds": "70",
    "lyric": "Stare into the vacuum of his eyes",
    "number": "2434",
  },
  {
    "artist": "Audioslave",
    "song": "Like a Stone",
    "year": "2002",
    "seconds": "70",
    "lyric": "In your house I long to be",
    "number": "2435",
  },
  {
    "artist": "Madonna",
    "song": "Like a Virgin",
    "year": "1984",
    "seconds": "60",
    "lyric": "You’re so fine and you’re mine",
    "number": "2436",
  },
  {
    "artist": "Meghan Trainor (ft. John Legend)",
    "song": "Like I’m Gonna Lose You",
    "year": "2015",
    "seconds": "60",
    "lyric": "In the blink of an eye",
    "number": "2437",
  },
  {
    "artist": "Clint Black",
    "song": "Like the Rain",
    "year": "1996",
    "seconds": "50",
    "lyric": "It’s as bright as lightning",
    "number": "2438",
  },
  {
    "artist": "Sam the Sham and the Pharaohs",
    "song": "Li’l Red Riding Hood",
    "year": "1966",
    "seconds": "60",
    "lyric": "To walk close by your side",
    "number": "2439",
  },
  {
    "artist": "Bob Dylan",
    "song": "Lily, Rosemary and the Jack of Hearts",
    "year": "1975",
    "seconds": "60",
    "lyric": "But his bodyguards and silver cane",
    "number": "2440",
  },
  {
    "artist": "Rush",
    "song": "Limelight",
    "year": "1981",
    "seconds": "50",
    "lyric": "Caught in the camera eye",
    "number": "2441",
  },
  {
    "artist": "Conway Twitty",
    "song": "Linda on My Mind",
    "year": "1975",
    "seconds": "50",
    "lyric": "I’m lying here beside her",
    "number": "2442",
  },
  {
    "artist": "Meghan Trainor",
    "song": "Lips Are Movin’",
    "year": "2014",
    "seconds": "70",
    "lyric": "But I smell her on your collar",
    "number": "2443",
  },
  {
    "artist": "Connie Francis",
    "song": "Lipstick on Your Collar",
    "year": "1959",
    "seconds": "50",
    "lyric": "You and I are through",
    "number": "2444",
  },
  {
    "artist": "Herman’s Hermits",
    "song": "Listen People",
    "year": "1966",
    "seconds": "50",
    "lyric": "I hope you never part",
    "number": "2445",
  },
  {
    "artist": "Doobie Brothers",
    "song": "Listen to the Music",
    "year": "1972",
    "seconds": "60",
    "lyric": "We’ll be happy and we’ll dance",
    "number": "2446",
  },
  {
    "artist": "Wings",
    "song": "Listen to What the Man Said",
    "year": "1975",
    "seconds": "70",
    "lyric": "Love is fine for all we know",
    "number": "2447",
  },
  {
    "artist": "Keith Urban",
    "song": "Little Bit of Everthing",
    "year": "2013",
    "seconds": "60",
    "lyric": "A whole box of Cuban cigars",
    "number": "2448",
  },
  {
    "artist": "Malvina Reynolds",
    "song": "Little Boxes",
    "year": "1962",
    "seconds": "50",
    "lyric": "And drink their martinis dry",
    "number": "2449",
  },
  {
    "artist": "The Diamonds",
    "song": "Little Darlin’",
    "year": "1957",
    "seconds": "70",
    "lyric": "To hold in mine, your little hand",
    "number": "2450",
  },
  {
    "artist": "Beach Boys",
    "song": "Little Deuce Coupe",
    "year": "1963",
    "seconds": "60",
    "lyric": "She’ll do a hundred and forty",
    "number": "2451",
  },
  {
    "artist": "SHeDAISY",
    "song": "Little Good-Byes",
    "year": "1999",
    "seconds": "50",
    "lyric": "Up and made a sandwich",
    "number": "2452",
  },
  {
    "artist": "O. C. Smith",
    "song": "Little Green Apples",
    "year": "1968",
    "seconds": "40",
    "lyric": "Puppy dogs, autumn leaves",
    "number": "2453",
  },
  {
    "artist": "Elton John",
    "song": "Little Jeannie",
    "year": "1980",
    "seconds": "70",
    "lyric": "I want you to be my acrobat",
    "number": "2454",
  },
  {
    "artist": "Brooks & Dunn",
    "song": "Little Miss Honky Tonk",
    "year": "1995",
    "seconds": "50",
    "lyric": "She’ll be my queen bee",
    "number": "2455",
  },
  {
    "artist": "Brad Paisley",
    "song": "Little Moments",
    "year": "2003",
    "seconds": "50",
    "lyric": "But she covered her mouth",
    "number": "2456",
  },
  {
    "artist": "Ani DiFranco",
    "song": "Little Plastic Castle",
    "year": "1998",
    "seconds": "50",
    "lyric": "Sneer at the two girls",
    "number": "2457",
  },
  {
    "artist": "Chuck Berry",
    "song": "Little Queenie",
    "year": "1959",
    "seconds": "80",
    "lyric": "A model on the cover of a magazine",
    "number": "2458",
  },
  {
    "artist": "Prince",
    "song": "Little Red Corvette",
    "year": "1983",
    "seconds": "50",
    "lyric": "The ride is so smooth",
    "number": "2459",
  },
  {
    "artist": "Howlin’ Wolf",
    "song": "Little Red Rooster",
    "year": "1961",
    "seconds": "60",
    "lyric": "Too lazy to crow ‘fore day",
    "number": "2460",
  },
  {
    "artist": "Bobby Goldsboro",
    "song": "Little Things",
    "year": "1964",
    "seconds": "60",
    "lyric": "You like to hold my hand",
    "number": "2461",
  },
  {
    "artist": "Bush",
    "song": "Little Things",
    "year": "1995",
    "seconds": "50",
    "lyric": "Spinning around two howling moons",
    "number": "2462",
  },
  {
    "artist": "Good Charlotte",
    "song": "Little Things",
    "year": "2001",
    "seconds": "50",
    "lyric": "Picked last in gym class",
    "number": "2463",
  },
  {
    "artist": "Marty Stuart",
    "song": "Little Things",
    "year": "1991",
    "seconds": "40",
    "lyric": "You squeeze me tight",
    "number": "2464",
  },
  {
    "artist": "One Direction",
    "song": "Little Things",
    "year": "2012",
    "seconds": "50",
    "lyric": "Your hand fits in mine",
    "number": "2465",
  },
  {
    "artist": "Tanya Tucker",
    "song": "Little Things",
    "year": "1997",
    "seconds": "60",
    "lyric": "Rub my back, make me laugh",
    "number": "2466",
  },
  {
    "artist": "The Oak Ridge Boys",
    "song": "Little Things",
    "year": "1985",
    "seconds": "40",
    "lyric": "Kissin’ in the dark",
    "number": "2467",
  },
  {
    "artist": "Carrie Underwood",
    "song": "Little Toy Guns",
    "year": "2015",
    "seconds": "70",
    "lyric": "In between the coats in the closet",
    "number": "2468",
  },
  {
    "artist": "Little Big Town",
    "song": "Little White Church",
    "year": "2010",
    "seconds": "50",
    "lyric": "Buy me a shiny ring",
    "number": "2469",
  },
  {
    "artist": "Sweet",
    "song": "Little Willy",
    "year": "1973",
    "seconds": "50",
    "lyric": "He’s the king around town",
    "number": "2470",
  },
  {
    "artist": "Bobby Sherman",
    "song": "Little Woman",
    "year": "1969",
    "seconds": "40",
    "lyric": "Leave your world behind",
    "number": "2471",
  },
  {
    "artist": "Kenny Chesney",
    "song": "Live a Little",
    "year": "2011",
    "seconds": "50",
    "lyric": "Step out in the sunshine",
    "number": "2472",
  },
  {
    "artist": "Paul McCartney & Wings",
    "song": "Live and Let Die",
    "year": "1973",
    "seconds": "60",
    "lyric": "Your heart was an open book",
    "number": "2473",
  },
  {
    "artist": "Tim McGraw",
    "song": "Live Like You Were Dying",
    "year": "2004",
    "seconds": "40",
    "lyric": "I went sky divin’",
    "number": "2474",
  },
  {
    "artist": "Soundgarden",
    "song": "Live To Rise",
    "year": "2012",
    "seconds": "60",
    "lyric": "Warm my face, warm your face",
    "number": "2475",
  },
  {
    "artist": "Madonna",
    "song": "Live To Tell",
    "year": "1986",
    "seconds": "70",
    "lyric": "A man can tell a thousand lies",
    "number": "2476",
  },
  {
    "artist": "George Strait",
    "song": "Living and Living Well",
    "year": "2002",
    "seconds": "60",
    "lyric": "Caught a fish, caught a breeze",
    "number": "2477",
  },
  {
    "artist": "Cliff Richard and the Drifters",
    "song": "Living Doll",
    "year": "1959",
    "seconds": "60",
    "lyric": "Lock her up in a trunk",
    "number": "2478",
  },
  {
    "artist": "Stevie Wonder",
    "song": "Living For the City",
    "year": "1974",
    "seconds": "60",
    "lyric": "His feet are hard and gritty",
    "number": "2479",
  },
  {
    "artist": "George Strait",
    "song": "Living For the Night",
    "year": "2009",
    "seconds": "50",
    "lyric": "To keep the sun out",
    "number": "2480",
  },
  {
    "artist": "James Brown",
    "song": "Living in America",
    "year": "1985",
    "seconds": "60",
    "lyric": "Black coffee and a hard roll",
    "number": "2481",
  },
  {
    "artist": "Kenny Chesney",
    "song": "Living in Fast Forward",
    "year": "2006",
    "seconds": "50",
    "lyric": "Greasy cheeseburgers and cheap cigarettes",
    "number": "2482",
  },
  {
    "artist": "Jethro Tull",
    "song": "Living in the Past",
    "year": "1969",
    "seconds": "70",
    "lyric": "Walk a mile to drink your water",
    "number": "2483",
  },
  {
    "artist": "Willie Nelson",
    "song": "Living In the Promiseland",
    "year": "1986",
    "seconds": "40",
    "lyric": "Across the shifting sand",
    "number": "2484",
  },
  {
    "artist": "Freddy Fender",
    "song": "Living It Down",
    "year": "1976",
    "seconds": "40",
    "lyric": "Out painting the town",
    "number": "2485",
  },
  {
    "artist": "Ricky Martin",
    "song": "Livin’ La Vida Loca",
    "year": "1990",
    "seconds": "50",
    "lyric": "Go dancing in the rain",
    "number": "2486",
  },
  {
    "artist": "Led Zeppelin",
    "song": "Livin’ Lovin’ Maid",
    "year": "1969",
    "seconds": "80",
    "lyric": "A purple umbrella and a fifty cent hat",
    "number": "2487",
  },
  {
    "artist": "Bon Jovi",
    "song": "Livin’ on a Prayer",
    "year": "1986",
    "seconds": "50",
    "lyric": "He’s down on his luck",
    "number": "2488",
  },
  {
    "artist": "Aerosmith",
    "song": "Livin’ On the Edge",
    "year": "1993",
    "seconds": "40",
    "lyric": "The lightbulb’s getting dim",
    "number": "2489",
  },
  {
    "artist": "Electric Light Orchestra",
    "song": "Livin’ Thing",
    "year": "1976",
    "seconds": "50",
    "lyric": "You look back in time",
    "number": "2490",
  },
  {
    "artist": "Tanya Tucker",
    "song": "Lizzie and the Rainman",
    "year": "1975",
    "seconds": "50",
    "lyric": "Ridin’ in on the sunrise",
    "number": "2491",
  },
  {
    "artist": "Jethro Tull",
    "song": "Locomotive Breath",
    "year": "1971",
    "seconds": "50",
    "lyric": "He’s crawling down the corridor",
    "number": "2492",
  },
  {
    "artist": "The Kinks",
    "song": "Lola",
    "year": "1970",
    "seconds": "50",
    "lyric": "The world’s most physical guy",
    "number": "2493",
  },
  {
    "artist": "The Chordettes",
    "song": "Lollipop",
    "year": "1958",
    "seconds": "60",
    "lyric": "Sweeter than candy on a stick",
    "number": "2494",
  },
  {
    "artist": "Conway Twitty",
    "song": "Lonely Blue Boy",
    "year": "1959",
    "seconds": "50",
    "lyric": "I’m so afraid of tomorrow",
    "number": "2495",
  },
  {
    "artist": "Paul Anka",
    "song": "Lonely Boy",
    "year": "1959",
    "seconds": "40",
    "lyric": "I prayed so hard",
    "number": "2496",
  },
  {
    "artist": "The Bee Gees",
    "song": "Lonely Days",
    "year": "1971",
    "seconds": "40",
    "lyric": "Come sit beside me",
    "number": "2497",
  },
  {
    "artist": "John Mellencamp",
    "song": "Lonely Ol’ Night",
    "year": "1985",
    "seconds": "60",
    "lyric": "Calls me up and says baby",
    "number": "2498",
  },
  {
    "artist": "America",
    "song": "Lonely People",
    "year": "1974",
    "seconds": "60",
    "lyric": "You drink from the silver cup",
    "number": "2499",
  },
  {
    "artist": "Andy Williams",
    "song": "Lonely Street",
    "year": "1959",
    "seconds": "60",
    "lyric": "And watch an old love die",
    "number": "2500",
  },
  {
    "artist": "Jackie Wilson",
    "song": "Lonely Teardrops",
    "year": "1959",
    "seconds": "40",
    "lyric": "My pillows never dry",
    "number": "2501",
  },
  {
    "artist": "Patty Loveless",
    "song": "Lonely Too Long",
    "year": "1996",
    "seconds": "50",
    "lyric": "Warm yourself by the fire",
    "number": "2502",
  },
  {
    "artist": "Perry Como",
    "song": "Long Ago (and Far Away)",
    "year": "1944",
    "seconds": "70",
    "lyric": "Chills run up and down my spine",
    "number": "2503",
  },
  {
    "artist": "The Hollies",
    "song": "Long Cool Woman in a Black Dress",
    "year": "1972",
    "seconds": "70",
    "lyric": "Sittin’ in a nest of bad men",
    "number": "2504",
  },
  {
    "artist": "Keith Urban",
    "song": "Long Hot Summer",
    "year": "2011",
    "seconds": "60",
    "lyric": "The beginning of my heart spinnin’",
    "number": "2505",
  },
  {
    "artist": "Neil Young",
    "song": "Long May You Run",
    "year": "1976",
    "seconds": "70",
    "lyric": "Your chrome heart shining in the sun",
    "number": "2506",
  },
  {
    "artist": "Little Richard",
    "song": "Long Tall Sally",
    "year": "1956",
    "seconds": "40",
    "lyric": "She’s built for speed",
    "number": "2507",
  },
  {
    "artist": "Doobie Brothers",
    "song": "Long Train Runnin’",
    "year": "1973",
    "seconds": "50",
    "lyric": "Her home and her family",
    "number": "2508",
  },
  {
    "artist": "Neil Diamond",
    "song": "Longfellow Serenade",
    "year": "1974",
    "seconds": "50",
    "lyric": "Warmed by a stolen kiss",
    "number": "2509",
  },
  {
    "artist": "Chicago",
    "song": "Look Away",
    "year": "1988",
    "seconds": "60",
    "lyric": "I said “I’m happy for you”",
    "number": "2510",
  },
  {
    "artist": "The Hollies",
    "song": "Look Through Any Window",
    "year": "1965",
    "seconds": "40",
    "lyric": "Smiling faces all around",
    "number": "2511",
  },
  {
    "artist": "The New Seekers",
    "song": "Look What They’ve Done to My Song Ma",
    "year": "1970",
    "seconds": "70",
    "lyric": "Tied it up in a plastic bag",
    "number": "2512",
  },
  {
    "artist": "Taylor Swift",
    "song": "Look What You Made Me Do",
    "year": "2017",
    "seconds": "70",
    "lyric": "Locked me out and threw a feast",
    "number": "2513",
  },
  {
    "artist": "Johnny Lee",
    "song": "Lookin’ for Love",
    "year": "1980",
    "seconds": "50",
    "lyric": "Love in too many faces",
    "number": "2514",
  },
  {
    "artist": "Creedence Clearwater Revival",
    "song": "Lookin’ Out My Back Door",
    "year": "1970",
    "seconds": "50",
    "lyric": "Tambourines and elephants are playing",
    "number": "2515",
  },
  {
    "artist": "Barry Manilow",
    "song": "Looks Like We Made It",
    "year": "1977",
    "seconds": "60",
    "lyric": "Playing hide and seek with hearts",
    "number": "2516",
  },
  {
    "artist": "Jerry Reed",
    "song": "Lord, Mr. Ford",
    "year": "1973",
    "seconds": "60",
    "lyric": "Changin’ a flat in a hurricane",
    "number": "2517",
  },
  {
    "artist": "Styx",
    "song": "Lorelei",
    "year": "1976",
    "seconds": "40",
    "lyric": "Gentle as a butterfly",
    "number": "2518",
  },
  {
    "artist": "Kane Brown",
    "song": "Lose It",
    "year": "2018",
    "seconds": "40",
    "lyric": "Flippin’ your hair, baby",
    "number": "2519",
  },
  {
    "artist": "Brett Eldredge",
    "song": "Lose My Mind",
    "year": "2015",
    "seconds": "60",
    "lyric": "Put me on a roller coaster",
    "number": "2520",
  },
  {
    "artist": "Eminem",
    "song": "Lose Yourself",
    "year": "2002",
    "seconds": "50",
    "lyric": "Knees weak, arms are heavy",
    "number": "2521",
  },
  {
    "artist": "R.E.M.",
    "song": "Losing My Religion",
    "year": "1991",
    "seconds": "50",
    "lyric": "That’s me in the spotlight",
    "number": "2522",
  },
  {
    "artist": "Conway Twitty",
    "song": "(Lost Her Love) On Our Last Date",
    "year": "1972",
    "seconds": "50",
    "lyric": "So blind I couldn’t see",
    "number": "2523",
  },
  {
    "artist": "Shawn Mendes",
    "song": "Lost in Japan",
    "year": "2018",
    "seconds": "40",
    "lyric": "Seeing all the rainbows",
    "number": "2524",
  },
  {
    "artist": "Air Supply",
    "song": "Lost in Love",
    "year": "1979",
    "seconds": "60",
    "lyric": "But I’m back on my feet",
    "number": "2525",
  },
  {
    "artist": "Ronnie Milsap",
    "song": "Lost in the Fifties Tonight",
    "year": "1985",
    "seconds": "70",
    "lyric": "Close your eyes baby, follow my heart",
    "number": "2526",
  },
  {
    "artist": "Bread",
    "song": "Lost Without Your Love",
    "year": "1977",
    "seconds": "50",
    "lyric": "A ship without a wind",
    "number": "2527",
  },
  {
    "artist": "Bessie Smith",
    "song": "Lost Your Head Blues",
    "year": "1926",
    "seconds": "60",
    "lyric": "But I’ll write you a letter",
    "number": "2528",
  },
  {
    "artist": "Jamie xx (feat. Romy)",
    "song": "Loud Places",
    "year": "2015",
    "seconds": "60",
    "lyric": "I feel music in your eyes",
    "number": "2529",
  },
  {
    "artist": "The Kingsmen",
    "song": "Louie Louie",
    "year": "1963",
    "seconds": "70",
    "lyric": "I smell the rose in her hair",
    "number": "2530",
  },
  {
    "artist": "Kylie Minogue",
    "song": "Love at First Sight",
    "year": "2002",
    "seconds": "50",
    "lyric": "And filled up the sky",
    "number": "2531",
  },
  {
    "artist": "Céline Dion",
    "song": "Love Can Move Mountains",
    "year": "1992",
    "seconds": "50",
    "lyric": "Oceans deep and mountains high",
    "number": "2532",
  },
  {
    "artist": "Diana Ross & the Supremes",
    "song": "Love Child",
    "year": "1968",
    "seconds": "50",
    "lyric": "In a worn, torn dress",
    "number": "2533",
  },
  {
    "artist": "Shania Twain",
    "song": "Love Gets Me Every Time",
    "year": "1997",
    "seconds": "50",
    "lyric": "My heart changed my mind",
    "number": "2534",
  },
  {
    "artist": "Edison Lighthouse",
    "song": "Love Grows (Where My Rosemary Goes)",
    "year": "1970",
    "seconds": "70",
    "lyric": "Her hair is kinda wild and free",
    "number": "2535",
  },
  {
    "artist": "Diana Ross",
    "song": "Love Hangover",
    "year": "1976",
    "seconds": "40",
    "lyric": "Don’t call a doctor",
    "number": "2536",
  },
  {
    "artist": "The Doors",
    "song": "Love Her Madly",
    "year": "1971",
    "seconds": "50",
    "lyric": "So sing a lonely song",
    "number": "2537",
  },
  {
    "artist": "Nazareth",
    "song": "Love Hurts",
    "year": "1974",
    "seconds": "60",
    "lyric": "To take a lot of pain",
    "number": "2538",
  },
  {
    "artist": "Aerosmith",
    "song": "Love in an Elevator",
    "year": "1989",
    "seconds": "70",
    "lyric": "Livin’ it up when I’m goin’ down",
    "number": "2539",
  },
  {
    "artist": "Alabama",
    "song": "Love in the First Degree",
    "year": "1981",
    "seconds": "50",
    "lyric": "And throw away the key",
    "number": "2540",
  },
  {
    "artist": "Usher (ft. Young Jeezy)",
    "song": "Love in This Club",
    "year": "2008",
    "seconds": "50",
    "lyric": "Baby, I’m comin’ for you",
    "number": "2541",
  },
  {
    "artist": "Alannah Myles",
    "song": "Love is",
    "year": "1989",
    "seconds": "60",
    "lyric": "Cry for help, wake up cold",
    "number": "2542",
  },
  {
    "artist": "Vanessa Williams and Brian McKnight",
    "song": "Love is",
    "year": "1993",
    "seconds": "60",
    "lyric": "In the blink of an eye",
    "number": "2543",
  },
  {
    "artist": "Pat Benatar",
    "song": "Love is a Battlefield",
    "year": "1983",
    "seconds": "60",
    "lyric": "Searching our hearts for so long",
    "number": "2544",
  },
  {
    "artist": "Phil Vassar",
    "song": "Love is a Beautiful Thing",
    "year": "2008",
    "seconds": "50",
    "lyric": "Folding chairs in the vestibule",
    "number": "2545",
  },
  {
    "artist": "Linda Ronstadt",
    "song": "Love is a Rose",
    "year": "1975",
    "seconds": "60",
    "lyric": "But you better not pick it",
    "number": "2546",
  },
  {
    "artist": "Gary Wright",
    "song": "Love is Alive",
    "year": "1976",
    "seconds": "50",
    "lyric": "My heart is on fire",
    "number": "2547",
  },
  {
    "artist": "The Judds",
    "song": "Love is Alive",
    "year": "1985",
    "seconds": "50",
    "lyric": "And at our breakfast table",
    "number": "2548",
  },
  {
    "artist": "The Troggs",
    "song": "Love is All Around",
    "year": "1967",
    "seconds": "60",
    "lyric": "I feel it in my fingers",
    "number": "2549",
  },
  {
    "artist": "Paul Mauriat",
    "song": "Love is Blue",
    "year": "1968",
    "seconds": "70",
    "lyric": "Crying for you alone in my bed",
    "number": "2550",
  },
  {
    "artist": "The Supremes",
    "song": "Love is Here and Now You’re Gone",
    "year": "1967",
    "seconds": "70",
    "lyric": "You closed the door to your heart",
    "number": "2551",
  },
  {
    "artist": "John Paul Young",
    "song": "Love Is In the Air",
    "year": "1977",
    "seconds": "60",
    "lyric": "In the whisper of the tree",
    "number": "2552",
  },
  {
    "artist": "Dolly Parton",
    "song": "Love is Like a Butterfly",
    "year": "1974",
    "seconds": "40",
    "lyric": "Soft wings in flight",
    "number": "2553",
  },
  {
    "artist": "Martha & the Vandellas",
    "song": "(Love is Like a) Heat Wave",
    "year": "1963",
    "seconds": "50",
    "lyric": "It’s burning in my heart",
    "number": "2554",
  },
  {
    "artist": "Don Williams",
    "song": "Love is on a Roll",
    "year": "1983",
    "seconds": "50",
    "lyric": "My woman, soft an’ curvy",
    "number": "2555",
  },
  {
    "artist": "Bryan White",
    "song": "Love is the Right Place",
    "year": "1997",
    "seconds": "50",
    "lyric": "A mist on the river",
    "number": "2556",
  },
  {
    "artist": "Andy Gibb",
    "song": "(Love Is) Thicker Than Water",
    "year": "1977",
    "seconds": "40",
    "lyric": "Heaven’s angel, devil’s daughter",
    "number": "2557",
  },
  {
    "artist": "The 1975",
    "song": "Love It If We Made It",
    "year": "2018",
    "seconds": "70",
    "lyric": "Write it on a piece of stone",
    "number": "2558",
  },
  {
    "artist": "Pat Boone",
    "song": "Love Letters in the Sand",
    "year": "1957",
    "seconds": "40",
    "lyric": "My broken heart aches",
    "number": "2559",
  },
  {
    "artist": "Kanye West",
    "song": "Love Lockdown",
    "year": "2008",
    "seconds": "60",
    "lyric": "Down this road too many times",
    "number": "2560",
  },
  {
    "artist": "The Miracles",
    "song": "Love Machine",
    "year": "1975",
    "seconds": "50",
    "lyric": "My mind blows a fuse",
    "number": "2561",
  },
  {
    "artist": "The Beatles",
    "song": "Love Me Do",
    "year": "1963",
    "seconds": "50",
    "lyric": "You know I love you",
    "number": "2562",
  },
  {
    "artist": "Ariana Grande, The Weeknd",
    "song": "Love Me Harder",
    "year": "2014",
    "seconds": "40",
    "lyric": "You bite your lip",
    "number": "2563",
  },
  {
    "artist": "Toby Keith",
    "song": "Love Me If You Can",
    "year": "2007",
    "seconds": "60",
    "lyric": "But I bring my better angels",
    "number": "2564",
  },
  {
    "artist": "Ellie Goulding",
    "song": "Love Me Like You Do",
    "year": "2015",
    "seconds": "40",
    "lyric": "I’m not thinking straight",
    "number": "2565",
  },
  {
    "artist": "Kelsea Ballerini",
    "song": "Love Me Like You Mean It",
    "year": "2015",
    "seconds": "70",
    "lyric": "The girls crying out in the rain",
    "number": "2566",
  },
  {
    "artist": "Tanya Tucker",
    "song": "Love Me Like You Used To",
    "year": "1987",
    "seconds": "50",
    "lyric": "Play around under the covers",
    "number": "2567",
  },
  {
    "artist": "John Legend",
    "song": "Love Me Now",
    "year": "2016",
    "seconds": "50",
    "lyric": "To turn darkness into light",
    "number": "2568",
  },
  {
    "artist": "Elvis Presley",
    "song": "Love Me Tender",
    "year": "1956",
    "seconds": "70",
    "lyric": "Love me sweet, never let me go",
    "number": "2569",
  },
  {
    "artist": "Chicago",
    "song": "Love Me Tomorrow",
    "year": "1982",
    "seconds": "50",
    "lyric": "She’s part of my life",
    "number": "2570",
  },
  {
    "artist": "Tom Jones",
    "song": "Love Me Tonight",
    "year": "1969",
    "seconds": "70",
    "lyric": "Hold me now, my heart is aching",
    "number": "2571",
  },
  {
    "artist": "The Doors",
    "song": "Love Me Two Times",
    "year": "1967",
    "seconds": "70",
    "lyric": "One for tomorrow, one just for today",
    "number": "2572",
  },
  {
    "artist": "Travis Tritt",
    "song": "Love of a Woman",
    "year": "2001",
    "seconds": "40",
    "lyric": "My baby knows me",
    "number": "2573",
  },
  {
    "artist": "Dan Seals",
    "song": "Love on Arrival",
    "year": "1990",
    "seconds": "50",
    "lyric": "Three letters by your name",
    "number": "2574",
  },
  {
    "artist": "Rihanna",
    "song": "Love on the Brain",
    "year": "2016",
    "seconds": "60",
    "lyric": "And throw me against the wall",
    "number": "2575",
  },
  {
    "artist": "Neil Diamond",
    "song": "Love on the Rocks",
    "year": "1980",
    "seconds": "50",
    "lyric": "Just pour me a drink",
    "number": "2576",
  },
  {
    "artist": "Beyoncé",
    "song": "Love on Top",
    "year": "2011",
    "seconds": "40",
    "lyric": "Fighting through my tears",
    "number": "2577",
  },
  {
    "artist": "The Friends of Distinction",
    "song": "Love or Let Me Be Lonely",
    "year": "1970",
    "seconds": "50",
    "lyric": "I can live without love",
    "number": "2578",
  },
  {
    "artist": "The Searchers",
    "song": "Love Potion No. 9",
    "year": "1965",
    "seconds": "80",
    "lyric": "I held my nose, I closed my eyes",
    "number": "2579",
  },
  {
    "artist": "The Who",
    "song": "Love Reign O’er Me",
    "year": "1973",
    "seconds": "50",
    "lyric": "Hot and black as ink",
    "number": "2580",
  },
  {
    "artist": "B-52’s",
    "song": "Love Shack",
    "year": "1989",
    "seconds": "60",
    "lyric": "It’s as big as a whale",
    "number": "2581",
  },
  {
    "artist": "The Bee Gees",
    "song": "Love So Right",
    "year": "1976",
    "seconds": "70",
    "lyric": "I’d found my heaven in her arms",
    "number": "2582",
  },
  {
    "artist": "Sara Bareilles",
    "song": "Love Song",
    "year": "2007",
    "seconds": "50",
    "lyric": "I’m happy in your hands",
    "number": "2583",
  },
  {
    "artist": "The Oak Ridge Boys",
    "song": "Love Song",
    "year": "1983",
    "seconds": "60",
    "lyric": "And toe the line for you",
    "number": "2584",
  },
  {
    "artist": "Taylor Swift",
    "song": "Love Story",
    "year": "2008",
    "seconds": "40",
    "lyric": "You were throwing pebbles",
    "number": "2585",
  },
  {
    "artist": "Mariah Carey",
    "song": "Love Takes Time",
    "year": "1990",
    "seconds": "40",
    "lyric": "Hollow in my heart",
    "number": "2586",
  },
  {
    "artist": "Eminem (feat. Rihanna)",
    "song": "Love the Way You Lie",
    "year": "2010",
    "seconds": "60",
    "lyric": "A steel knife in my windpipe",
    "number": "2587",
  },
  {
    "artist": "Donna Summer",
    "song": "Love To Love You Baby",
    "year": "1975",
    "seconds": "40",
    "lyric": "Lay your head down",
    "number": "2588",
  },
  {
    "artist": "The O’Jays",
    "song": "Love Train",
    "year": "1973",
    "seconds": "60",
    "lyric": "It’s time to get on board",
    "number": "2589",
  },
  {
    "artist": "Van Halen",
    "song": "Love Walks In",
    "year": "1986",
    "seconds": "60",
    "lyric": "She stands in a silken gown",
    "number": "2590",
  },
  {
    "artist": "Pablo Cruise",
    "song": "Love Will Find a Way",
    "year": "1978",
    "seconds": "50",
    "lyric": "So keep your heart open",
    "number": "2591",
  },
  {
    "artist": "Eagles",
    "song": "Love Will Keep Us Alive",
    "year": "1994",
    "seconds": "40",
    "lyric": "No more emptiness inside",
    "number": "2592",
  },
  {
    "artist": "Captain & Tennille",
    "song": "Love Will Keep Us Together",
    "year": "1974",
    "seconds": "50",
    "lyric": "Some sweet-talking girl comes along",
    "number": "2593",
  },
  {
    "artist": "Janet Jackson",
    "song": "Love Will Never Do (Without You)",
    "year": "1990",
    "seconds": "60",
    "lyric": "Falling in and out of love",
    "number": "2594",
  },
  {
    "artist": "George Strait",
    "song": "Love Without End, Amen",
    "year": "1990",
    "seconds": "60",
    "lyric": "Let me tell you a secret",
    "number": "2595",
  },
  {
    "artist": "Rascal Flatts",
    "song": "Love You Out Loud",
    "year": "2003",
    "seconds": "40",
    "lyric": "Stand on a rooftop",
    "number": "2596",
  },
  {
    "artist": "Eric Church",
    "song": "Love Your Love the Most",
    "year": "2009",
    "seconds": "50",
    "lyric": "And mustard on my fries",
    "number": "2597",
  },
  {
    "artist": "Justin Bieber",
    "song": "Love Yourself",
    "year": "2015",
    "seconds": "50",
    "lyric": "You rained on my parade",
    "number": "2598",
  },
  {
    "artist": "Lady Gaga",
    "song": "LoveGame",
    "year": "2009",
    "seconds": "60",
    "lyric": "I can see you staring there",
    "number": "2599",
  },
  {
    "artist": "Billie Eilish and Khalid",
    "song": "Lovely",
    "year": "2017",
    "seconds": "40",
    "lyric": "Tear me to pieces",
    "number": "2600",
  },
  {
    "artist": "Bill Withers",
    "song": "Lovely Day",
    "year": "1977",
    "seconds": "60",
    "lyric": "And the sunlight hurts my eyes",
    "number": "2601",
  },
  {
    "artist": "The Jacksons",
    "song": "Lovely One",
    "year": "1980",
    "seconds": "60",
    "lyric": "Walkin down the street so pretty",
    "number": "2602",
  },
  {
    "artist": "Taylor Swift",
    "song": "Lover",
    "year": "2019",
    "seconds": "50",
    "lyric": "Leave the Christmas lights up",
    "number": "2603",
  },
  {
    "artist": "Jerrod Niemann",
    "song": "Lover, Lover",
    "year": "2010",
    "seconds": "50",
    "lyric": "When the love starts burning",
    "number": "2604",
  },
  {
    "artist": "Alannah Myles",
    "song": "Lover of Mine",
    "year": "1990",
    "seconds": "50",
    "lyric": "I’m walking a fine line",
    "number": "2605",
  },
  {
    "artist": "Billy Ocean",
    "song": "Loverboy",
    "year": "1985",
    "seconds": "50",
    "lyric": "My finger’s on the trigger",
    "number": "2606",
  },
  {
    "artist": "Mariah Carey (ft. Cameo)",
    "song": "Loverboy",
    "year": "2001",
    "seconds": "70",
    "lyric": "I toss and turn in my bed",
    "number": "2607",
  },
  {
    "artist": "Bruce Cockburn",
    "song": "Lovers in a Dangerous Time",
    "year": "1984",
    "seconds": "70",
    "lyric": "You’re waiting for the sky to fall",
    "number": "2608",
  },
  {
    "artist": "Donna Summer",
    "song": "Love’s About to Change My Heart",
    "year": "1989",
    "seconds": "50",
    "lyric": "I’m waiting for the doorbell",
    "number": "2609",
  },
  {
    "artist": "Buck Owens",
    "song": "Love’s Gonna Live Here",
    "year": "1963",
    "seconds": "40",
    "lyric": "I hear bees a-hummin’",
    "number": "2610",
  },
  {
    "artist": "George Strait",
    "song": "Love’s Gonna Make It Alright",
    "year": "2011",
    "seconds": "60",
    "lyric": "I’ll chase you down the hallway",
    "number": "2611",
  },
  {
    "artist": "Paul Simon",
    "song": "Loves Me Like a Rock",
    "year": "1973",
    "seconds": "70",
    "lyric": "Down on her knees and hug me",
    "number": "2612",
  },
  {
    "artist": "Hank Williams",
    "song": "Lovesick Blues",
    "year": "1949",
    "seconds": "60",
    "lyric": "Lord, I love to hear her",
    "number": "2613",
  },
  {
    "artist": "Clint Black",
    "song": "Loving Blind",
    "year": "1991",
    "seconds": "50",
    "lyric": "And I know those tears",
    "number": "2614",
  },
  {
    "artist": "Luke Combs (ft. Eric Church)",
    "song": "Lovin’ on You",
    "year": "2020",
    "seconds": "50",
    "lyric": "Birdie on a par five",
    "number": "2615",
  },
  {
    "artist": "Ricky Skaggs",
    "song": "Lovin’ Only Me",
    "year": "1989",
    "seconds": "50",
    "lyric": "I’m offering you my love",
    "number": "2616",
  },
  {
    "artist": "Journey",
    "song": "Lovin’ Touchin’ Squeezin’",
    "year": "1979",
    "seconds": "60",
    "lyric": "It’s your turn girl to cry",
    "number": "2617",
  },
  {
    "artist": "Minnie Riperton",
    "song": "Lovin’ You",
    "year": "1974",
    "seconds": "60",
    "lyric": "I’m more in love with you",
    "number": "2618",
  },
  {
    "artist": "George Jones",
    "song": "Loving You Could Never Be Better",
    "year": "1972",
    "seconds": "50",
    "lyric": "We’ve built us a fire",
    "number": "2619",
  },
  {
    "artist": "Zac Brown Band",
    "song": "Loving You Easy",
    "year": "2015",
    "seconds": "50",
    "lyric": "Dancing around to the radio",
    "number": "2620",
  },
  {
    "artist": "War",
    "song": "Low Rider",
    "year": "1975",
    "seconds": "40",
    "lyric": "Don’t drive too fast",
    "number": "2621",
  },
  {
    "artist": "Boz Scaggs",
    "song": "Lowdown",
    "year": "1976",
    "seconds": "60",
    "lyric": "Put your money on the table",
    "number": "2622",
  },
  {
    "artist": "Kenny Rogers",
    "song": "Lucille",
    "year": "1977",
    "seconds": "50",
    "lyric": "The drinks finally hit her",
    "number": "2623",
  },
  {
    "artist": "Frank Sinatra",
    "song": "Luck Be a Lady",
    "year": "1963",
    "seconds": "50",
    "lyric": "Wander all over the room",
    "number": "2624",
  },
  {
    "artist": "Waylon Jennings (ft. Willie Nelson)",
    "song": "Luckenbach, Texas (Back to the Basics of Love)",
    "year": "1977",
    "seconds": "40",
    "lyric": "Boots and faded jeans",
    "number": "2625",
  },
  {
    "artist": "Britney Spears",
    "song": "Lucky",
    "year": "2000",
    "seconds": "60",
    "lyric": "It’s time for makeup, perfect smile",
    "number": "2626",
  },
  {
    "artist": "Jason Mraz (feat. Colbie Caillat)",
    "song": "Lucky",
    "year": "2008",
    "seconds": "70",
    "lyric": "I’ll put a flower in your hair",
    "number": "2627",
  },
  {
    "artist": "Emerson, Lake & Palmer",
    "song": "Lucky Man",
    "year": "1970",
    "seconds": "60",
    "lyric": "His blood ran as he cried",
    "number": "2628",
  },
  {
    "artist": "The Oak Ridge Boys",
    "song": "Lucky Moon",
    "year": "1990",
    "seconds": "60",
    "lyric": "The first star I see tonight",
    "number": "2629",
  },
  {
    "artist": "Bruce Springsteen",
    "song": "Lucky Town",
    "year": "1992",
    "seconds": "50",
    "lyric": "A coat of fine leather",
    "number": "2630",
  },
  {
    "artist": "The Beatles",
    "song": "Lucy in the Sky With Diamonds",
    "year": "1967",
    "seconds": "50",
    "lyric": "The girl with kaleidoscope eyes",
    "number": "2631",
  },
  {
    "artist": "Red Rider",
    "song": "Lunatic Fringe",
    "year": "1981",
    "seconds": "50",
    "lyric": "Can you feel the thunder?",
    "number": "2632",
  },
  {
    "artist": "Eagles",
    "song": "Lyin’ Eyes",
    "year": "1975",
    "seconds": "50",
    "lyric": "Hands as cold as ice",
    "number": "2633",
  },
  {
    "artist": "The Kingston Trio",
    "song": "M.T.A.",
    "year": "1959",
    "seconds": "70",
    "lyric": "He put ten cents in his pocket",
    "number": "2634",
  },
  {
    "artist": "Tee-Set",
    "song": "Ma Belle Amie",
    "year": "1970",
    "seconds": "50",
    "lyric": "A child of the sun",
    "number": "2635",
  },
  {
    "artist": "Richard Harris",
    "song": "MacArthur Park",
    "year": "1968",
    "seconds": "70",
    "lyric": "It took so long to bake it",
    "number": "2636",
  },
  {
    "artist": "Bobby Darin",
    "song": "Mack the Knife",
    "year": "1959",
    "seconds": "60",
    "lyric": "A tugboat down by the river",
    "number": "2637",
  },
  {
    "artist": "Toby Keith",
    "song": "Made in America",
    "year": "2011",
    "seconds": "50",
    "lyric": "Flyin’ high on the farm",
    "number": "2638",
  },
  {
    "artist": "Buck Owens",
    "song": "Made in Japan",
    "year": "1972",
    "seconds": "60",
    "lyric": "All the love in my heart",
    "number": "2639",
  },
  {
    "artist": "Styx",
    "song": "Mademoiselle",
    "year": "1976",
    "seconds": "50",
    "lyric": "You’re searching for a dream",
    "number": "2640",
  },
  {
    "artist": "Rod Stewart",
    "song": "Maggie May",
    "year": "1971",
    "seconds": "60",
    "lyric": "And get on back to school",
    "number": "2641",
  },
  {
    "artist": "Bob Dylan",
    "song": "Maggie’s Farm",
    "year": "1965",
    "seconds": "50",
    "lyric": "He hands you a nickel",
    "number": "2642",
  },
  {
    "artist": "Coldplay",
    "song": "Magic",
    "year": "2014",
    "seconds": "40",
    "lyric": "Cut me into two",
    "number": "2643",
  },
  {
    "artist": "The Who",
    "song": "Magic Bus",
    "year": "1968",
    "seconds": "50",
    "lyric": "I get in the queue",
    "number": "2644",
  },
  {
    "artist": "Steppenwolf",
    "song": "Magic Carpet Ride",
    "year": "1968",
    "seconds": "70",
    "lyric": "Close your eyes girl, look inside girl",
    "number": "2645",
  },
  {
    "artist": "Heart",
    "song": "Magic Man",
    "year": "1975",
    "seconds": "70",
    "lyric": "I was not so strong you know",
    "number": "2646",
  },
  {
    "artist": "Perry Como",
    "song": "Magic Moments",
    "year": "1958",
    "seconds": "60",
    "lyric": "The night of the hay ride",
    "number": "2647",
  },
  {
    "artist": "The Beatles",
    "song": "Magical Mystery Tour",
    "year": "1967",
    "seconds": "50",
    "lyric": "Dying to take you away",
    "number": "2648",
  },
  {
    "artist": "Walter Egan",
    "song": "Magnet and Steel",
    "year": "1978",
    "seconds": "60",
    "lyric": "To show the way I feel",
    "number": "2649",
  },
  {
    "artist": "Dallas Smith",
    "song": "Make ‘Em Like You",
    "year": "2018",
    "seconds": "60",
    "lyric": "And your foot on the gas",
    "number": "2650",
  },
  {
    "artist": "Master P (ft. Fiend, Silkk The Shocker, Mia X, Mystical)",
    "song": "Make ‘Em Say Uhh!",
    "year": "1998",
    "seconds": "50",
    "lyric": "I’m knocking out some teeth",
    "number": "2651",
  },
  {
    "artist": "Mariah Carey",
    "song": "Make It Happen",
    "year": "1992",
    "seconds": "50",
    "lyric": "I struggled and I prayed",
    "number": "2652",
  },
  {
    "artist": "Bread",
    "song": "Make It With You",
    "year": "1970",
    "seconds": "60",
    "lyric": "Reaching out for the other side",
    "number": "2653",
  },
  {
    "artist": "Fabolous (ft. Ne-Yo)",
    "song": "Make Me Better",
    "year": "2007",
    "seconds": "40",
    "lyric": "Pep in my step",
    "number": "2654",
  },
  {
    "artist": "Eric Carmen",
    "song": "Make Me Lose Control",
    "year": "1988",
    "seconds": "70",
    "lyric": "I run a comb through my hair",
    "number": "2655",
  },
  {
    "artist": "Chicago",
    "song": "Make Me Smile",
    "year": "1970",
    "seconds": "70",
    "lyric": "I’m so happy that you love me",
    "number": "2656",
  },
  {
    "artist": "Barbara Lewis",
    "song": "Make Me Your Baby",
    "year": "1965",
    "seconds": "60",
    "lyric": "Kiss me, kiss me and maybe",
    "number": "2657",
  },
  {
    "artist": "The Oak Ridge Boys",
    "song": "Make My Life With You",
    "year": "1984",
    "seconds": "40",
    "lyric": "Loving arms open wide",
    "number": "2658",
  },
  {
    "artist": "Kim Carnes & Barbra Streisand",
    "song": "Make No Mistake, He’s Mine",
    "year": "1984",
    "seconds": "60",
    "lyric": "See your face answer his door",
    "number": "2659",
  },
  {
    "artist": "Eddy Arnold",
    "song": "Make the World Go Away",
    "year": "1965",
    "seconds": "50",
    "lyric": "Get it off my shoulder",
    "number": "2660",
  },
  {
    "artist": "Sam Hunt",
    "song": "Make You Miss Me",
    "year": "2016",
    "seconds": "60",
    "lyric": "You were sleeping in my shirt",
    "number": "2661",
  },
  {
    "artist": "Taking Back Sunday",
    "song": "MakeDamnSure",
    "year": "2006",
    "seconds": "60",
    "lyric": "You start shaking at the thought",
    "number": "2662",
  },
  {
    "artist": "Doug and the Slugs",
    "song": "Making It Work",
    "year": "1983",
    "seconds": "50",
    "lyric": "You pack a pretty punch",
    "number": "2663",
  },
  {
    "artist": "Air Supply",
    "song": "Making Love Out of Nothing At All",
    "year": "1983",
    "seconds": "60",
    "lyric": "Through the waves in your hair",
    "number": "2664",
  },
  {
    "artist": "Keith Urban",
    "song": "Making Memories of Us",
    "year": "2005",
    "seconds": "40",
    "lyric": "The wild bees swarm",
    "number": "2665",
  },
  {
    "artist": "Eddie Cantor",
    "song": "Makin’ Whoopee!",
    "year": "1929",
    "seconds": "40",
    "lyric": "And bakes him pies",
    "number": "2666",
  },
  {
    "artist": "Hole",
    "song": "Malibu",
    "year": "1998",
    "seconds": "50",
    "lyric": "I walk into the waves",
    "number": "2667",
  },
  {
    "artist": "Miley Cyrus",
    "song": "Malibu",
    "year": "2017",
    "seconds": "50",
    "lyric": "My feet in the sand",
    "number": "2668",
  },
  {
    "artist": "Waylon & Willie",
    "song": "Mama, Don’t Let Your Babies",
    "year": "1978",
    "seconds": "40",
    "lyric": "Let ‘em pick guitars",
    "number": "2669",
  },
  {
    "artist": "The Judds",
    "song": "Mama He’s Crazy",
    "year": "1984",
    "seconds": "60",
    "lyric": "I hung the moon and stars",
    "number": "2670",
  },
  {
    "artist": "Ozzy Osbourne",
    "song": "Mama, I’m Coming Home",
    "year": "1991",
    "seconds": "60",
    "lyric": "I can’t stand to say goodbye",
    "number": "2671",
  },
  {
    "artist": "Liz Anderson",
    "song": "Mama Spank",
    "year": "1967",
    "seconds": "60",
    "lyric": "The big hand is on twelve",
    "number": "2672",
  },
  {
    "artist": "Three Dog Night",
    "song": "Mama Told Me (Not to Come)",
    "year": "1970",
    "seconds": "50",
    "lyric": "Let me catch my breath",
    "number": "2673",
  },
  {
    "artist": "Merle Haggard",
    "song": "Mama Tried",
    "year": "1968",
    "seconds": "60",
    "lyric": "And I turned twenty-one in prison",
    "number": "2674",
  },
  {
    "artist": "The Black Eyed Peas, Ozuna, J Rey Soul",
    "song": "Mamacita",
    "year": "2019",
    "seconds": "50",
    "lyric": "You wind up your hips",
    "number": "2675",
  },
  {
    "artist": "Jackson 5",
    "song": "Mama’s Pearl",
    "year": "1970",
    "seconds": "50",
    "lyric": "Give your heart a twirl",
    "number": "2676",
  },
  {
    "artist": "Carrie Underwood",
    "song": "Mama’s Song",
    "year": "2010",
    "seconds": "60",
    "lyric": "And he is good, so good",
    "number": "2677",
  },
  {
    "artist": "Lou Bega",
    "song": "Mambo No. 5",
    "year": "1999",
    "seconds": "60",
    "lyric": "Shake your head to the sound",
    "number": "2678",
  },
  {
    "artist": "ABBA",
    "song": "Mamma Mia",
    "year": "1975",
    "seconds": "50",
    "lyric": "When you slam the door",
    "number": "2679",
  },
  {
    "artist": "Shania Twain",
    "song": "Man! I Feel Like a Woman",
    "year": "1999",
    "seconds": "40",
    "lyric": "Men’s shirts, short skirts",
    "number": "2680",
  },
  {
    "artist": "Johnny Cash",
    "song": "Man in Black",
    "year": "1971",
    "seconds": "60",
    "lyric": "For the sick and lonely old",
    "number": "2681",
  },
  {
    "artist": "Michael Jackson",
    "song": "Man in the Mirror",
    "year": "1987",
    "seconds": "70",
    "lyric": "I see the kids in the street",
    "number": "2682",
  },
  {
    "artist": "Soggy Bottom Boys",
    "song": "Man of Constant Sorrow",
    "year": "2000",
    "seconds": "40",
    "lyric": "For six long years",
    "number": "2683",
  },
  {
    "artist": "Hank Williams Jr.",
    "song": "Man of Steel",
    "year": "1984",
    "seconds": "50",
    "lyric": "Bounce me on her knee",
    "number": "2684",
  },
  {
    "artist": "Barry Manilow",
    "song": "Mandy",
    "year": "1974",
    "seconds": "50",
    "lyric": "And stopped me from shaking",
    "number": "2685",
  },
  {
    "artist": "Hall & Oates",
    "song": "Maneater",
    "year": "1982",
    "seconds": "70",
    "lyric": "Watch out boy, she’ll chew you up",
    "number": "2686",
  },
  {
    "artist": "Michael Sembello",
    "song": "Maniac",
    "year": "1983",
    "seconds": "70",
    "lyric": "Lookin’ for the fight of her life",
    "number": "2687",
  },
  {
    "artist": "The Bangles",
    "song": "Manic Monday",
    "year": "1986",
    "seconds": "60",
    "lyric": "A dream, I was kissin’ Valentino",
    "number": "2688",
  },
  {
    "artist": "Jean Shepard",
    "song": "Many Happy Hangovers to You",
    "year": "1966",
    "seconds": "50",
    "lyric": "You’re quiet as a mouse",
    "number": "2689",
  },
  {
    "artist": "Jimmy Buffett",
    "song": "Margaritaville",
    "year": "1977",
    "seconds": "40",
    "lyric": "Nibblin’ on sponge cake",
    "number": "2690",
  },
  {
    "artist": "Blondie",
    "song": "Maria",
    "year": "1999",
    "seconds": "60",
    "lyric": "A million and one candle lights",
    "number": "2691",
  },
  {
    "artist": "Nuns Chorus",
    "song": "Maria",
    "year": "1965",
    "seconds": "40",
    "lyric": "Drive a hornet from its nest",
    "number": "2692",
  },
  {
    "artist": "Santana (ft. The Product G&B and Wyclef Jean)",
    "song": "Maria Maria",
    "year": "1999",
    "seconds": "60",
    "lyric": "Stop the shooting, stop the looting",
    "number": "2693",
  },
  {
    "artist": "Crosby, Stills and Nash",
    "song": "Marrakesh Express",
    "year": "1969",
    "seconds": "70",
    "lyric": "I smell the garden in your hair",
    "number": "2694",
  },
  {
    "artist": "Thomas Rhett",
    "song": "Marry Me",
    "year": "2018",
    "seconds": "50",
    "lyric": "I’ll wear my black suit",
    "number": "2695",
  },
  {
    "artist": "Bruno Mars",
    "song": "Marry You",
    "year": "2011",
    "seconds": "50",
    "lyric": "A pocket full of cash",
    "number": "2696",
  },
  {
    "artist": "Ray Charles",
    "song": "Mary Ann",
    "year": "1956",
    "seconds": "60",
    "lyric": "Can I take you home tonight?",
    "number": "2697",
  },
  {
    "artist": "Tom Petty & the Heartbreakers",
    "song": "Mary Jane’s Last Dance",
    "year": "1993",
    "seconds": "70",
    "lyric": "One more time to kill the pain",
    "number": "2698",
  },
  {
    "artist": "The Temptations",
    "song": "Masterpiece",
    "year": "1973",
    "seconds": "40",
    "lyric": "Stealin’ cars, robbin’ bars",
    "number": "2699",
  },
  {
    "artist": "The Beatles",
    "song": "Maxwell’s Silver Hammer",
    "year": "1969",
    "seconds": "50",
    "lyric": "He creeps up from behind",
    "number": "2700",
  },
  {
    "artist": "Wings",
    "song": "Maybe I’m Amazed",
    "year": "1977",
    "seconds": "60",
    "lyric": "You hung me on the line",
    "number": "2701",
  },
  {
    "artist": "Tim McGraw",
    "song": "Maybe We Should Just Sleep On It",
    "year": "1996",
    "seconds": "50",
    "lyric": "They carried out your clothes",
    "number": "2702",
  },
  {
    "artist": "The Judds",
    "song": "Maybe Your Baby’s Got the Blues",
    "year": "1987",
    "seconds": "50",
    "lyric": "Keep her apron strings untied",
    "number": "2703",
  },
  {
    "artist": "Chuck Berry",
    "song": "Maybellene",
    "year": "1955",
    "seconds": "40",
    "lyric": "The motor cooled down",
    "number": "2704",
  },
  {
    "artist": "Rascal Flatts",
    "song": "Mayberry",
    "year": "2003",
    "seconds": "50",
    "lyric": "Sitting on the porch drinking",
    "number": "2705",
  },
  {
    "artist": "Taylor Swift (feat. Brendon Urie)",
    "song": "Me!",
    "year": "2019",
    "seconds": "40",
    "lyric": "I’m a handful, baby",
    "number": "2706",
  },
  {
    "artist": "Britney Spears (ft. Madonna)",
    "song": "Me Against the Music",
    "year": "2003",
    "seconds": "60",
    "lyric": "From the tip of my toes",
    "number": "2707",
  },
  {
    "artist": "Janis Joplin",
    "song": "Me and Bobby McGee",
    "year": "1971",
    "seconds": "40",
    "lyric": "Windshield wipers slapping time",
    "number": "2708",
  },
  {
    "artist": "Paul Simon",
    "song": "Me and Julio Down By the Schoolyard",
    "year": "1972",
    "seconds": "50",
    "lyric": "And spit on the ground",
    "number": "2709",
  },
  {
    "artist": "Billy Paul",
    "song": "Me and Mrs. Jones",
    "year": "1973",
    "seconds": "60",
    "lyric": "The jukebox plays our favorite song",
    "number": "2710",
  },
  {
    "artist": "Kenny Chesney",
    "song": "Me and You",
    "year": "1996",
    "seconds": "50",
    "lyric": "I thank the stars above",
    "number": "2711",
  },
  {
    "artist": "Lobo",
    "song": "Me & You and a Dog Named Boo",
    "year": "1971",
    "seconds": "60",
    "lyric": "And back on the road again",
    "number": "2712",
  },
  {
    "artist": "Meghan Trainor",
    "song": "Me Too",
    "year": "2016",
    "seconds": "60",
    "lyric": "I never pay for my drinks",
    "number": "2713",
  },
  {
    "artist": "Toby Keith",
    "song": "Me Too",
    "year": "1996",
    "seconds": "50",
    "lyric": "Pull the covers back up",
    "number": "2714",
  },
  {
    "artist": "Taylor Swift",
    "song": "Mean",
    "year": "2010",
    "seconds": "50",
    "lyric": "Talking over a football game",
    "number": "2715",
  },
  {
    "artist": "George Strait",
    "song": "Meanwhile",
    "year": "1999",
    "seconds": "60",
    "lyric": "Slowly we climb up the stairs",
    "number": "2716",
  },
  {
    "artist": "Tim McGraw",
    "song": "Meanwhile Back at Mama’s",
    "year": "2014",
    "seconds": "50",
    "lyric": "And beer’s in the fridge",
    "number": "2717",
  },
  {
    "artist": "Diamond Rio",
    "song": "Meet in the Middle",
    "year": "1991",
    "seconds": "60",
    "lyric": "Old enough to drive a car",
    "number": "2718",
  },
  {
    "artist": "The Black Eyed Peas",
    "song": "Meet Me Halfway",
    "year": "2009",
    "seconds": "50",
    "lyric": "Even sail the seven seas",
    "number": "2719",
  },
  {
    "artist": "Dan Seals & Marie Osmond",
    "song": "Meet Me in Montana",
    "year": "1985",
    "seconds": "60",
    "lyric": "See the mountains in your eyes",
    "number": "2720",
  },
  {
    "artist": "Donovan",
    "song": "Mellow Yellow",
    "year": "1966",
    "seconds": "50",
    "lyric": "She’s just mad about me",
    "number": "2721",
  },
  {
    "artist": "Dean Martin",
    "song": "Memories Are Made of This",
    "year": "1956",
    "seconds": "40",
    "lyric": "Two sips of wine",
    "number": "2722",
  },
  {
    "artist": "Johnny Rivers",
    "song": "Memphis",
    "year": "1964",
    "seconds": "70",
    "lyric": "And he wrote it on the wall",
    "number": "2723",
  },
  {
    "artist": "Marvin Gaye",
    "song": "Mercy, Mercy Me (The Ecology)",
    "year": "1971",
    "seconds": "40",
    "lyric": "Fish full of mercury",
    "number": "2724",
  },
  {
    "artist": "Elvis Presley",
    "song": "Merry Christmas, Baby",
    "year": "1971",
    "seconds": "40",
    "lyric": "Standing ‘neath the mistletoe",
    "number": "2725",
  },
  {
    "artist": "Ray Charles",
    "song": "Mess Around",
    "year": "1953",
    "seconds": "50",
    "lyric": "Talk about the pit, barbecue",
    "number": "2726",
  },
  {
    "artist": "The Police",
    "song": "Message in a Bottle",
    "year": "1979",
    "seconds": "50",
    "lyric": "Washed up on the shore",
    "number": "2727",
  },
  {
    "artist": "Dionne Warwick",
    "song": "Message to Michael",
    "year": "1966",
    "seconds": "60",
    "lyric": "Spread your wings for New Orleans",
    "number": "2728",
  },
  {
    "artist": "Bing Crosby",
    "song": "Mexicali Rose",
    "year": "1938",
    "seconds": "50",
    "lyric": "Dry those big brown eyes",
    "number": "2729",
  },
  {
    "artist": "The Beatles",
    "song": "Michelle",
    "year": "1965",
    "seconds": "60",
    "lyric": "Say the only words I know",
    "number": "2730",
  },
  {
    "artist": "Tony Basil",
    "song": "Mickey",
    "year": "1981",
    "seconds": "60",
    "lyric": "You take me by the heart",
    "number": "2731",
  },
  {
    "artist": "Cole Swindell",
    "song": "Middle of a Memory",
    "year": "2016",
    "seconds": "60",
    "lyric": "My one drink order to two",
    "number": "2732",
  },
  {
    "artist": "The Pretenders",
    "song": "Middle of the Road",
    "year": "1983",
    "seconds": "60",
    "lyric": "Fat guys driving ’round in jeeps",
    "number": "2733",
  },
  {
    "artist": "Red Foley",
    "song": "Midnight",
    "year": "1952",
    "seconds": "50",
    "lyric": "I can read the room",
    "number": "2734",
  },
  {
    "artist": "Maria Muldaur",
    "song": "Midnight at the Oasis",
    "year": "1974",
    "seconds": "60",
    "lyric": "I know your daddy’s a sultan",
    "number": "2735",
  },
  {
    "artist": "M83",
    "song": "Midnight City",
    "year": "2011",
    "seconds": "50",
    "lyric": "Looking at the milky skyline",
    "number": "2736",
  },
  {
    "artist": "The Grass Roots",
    "song": "Midnight Confessions",
    "year": "1968",
    "seconds": "60",
    "lyric": "Passing so close beside you, baby",
    "number": "2737",
  },
  {
    "artist": "The Allman Brothers Band",
    "song": "Midnight Rider",
    "year": "1970",
    "seconds": "40",
    "lyric": "One more silver dollar",
    "number": "2738",
  },
  {
    "artist": "Miley Cyrus",
    "song": "Midnight Sky",
    "year": "2020",
    "seconds": "70",
    "lyric": "My hands tied up in your ropes",
    "number": "2739",
  },
  {
    "artist": "Gladys Knight & the Pips",
    "song": "Midnight Train to Georgia",
    "year": "1973",
    "seconds": "60",
    "lyric": "Bought a one way ticket back",
    "number": "2740",
  },
  {
    "artist": "Cat Stevens",
    "song": "Miles From Nowhere",
    "year": "1970",
    "seconds": "50",
    "lyric": "I creep through the valleys",
    "number": "2741",
  },
  {
    "artist": "Kelis",
    "song": "Milkshake",
    "year": "2004",
    "seconds": "60",
    "lyric": "All the boys to the yard",
    "number": "2742",
  },
  {
    "artist": "Lady Gaga",
    "song": "Million Reasons",
    "year": "2016",
    "seconds": "50",
    "lyric": "I bow down to pray",
    "number": "2743",
  },
  {
    "artist": "Hank Williams",
    "song": "Mind Your Own Business",
    "year": "1949",
    "seconds": "40",
    "lyric": "The boys all whistle",
    "number": "2744",
  },
  {
    "artist": "Taylor Swift",
    "song": "Mine",
    "year": "2010",
    "seconds": "50",
    "lyric": "Braced myself for the goodbye",
    "number": "2745",
  },
  {
    "artist": "Blake Shelton",
    "song": "Mine Would Be You",
    "year": "2013",
    "seconds": "50",
    "lyric": "Tail lights fading, daylight breaking",
    "number": "2746",
  },
  {
    "artist": "Doobie Brothers",
    "song": "Minute by Minute",
    "year": "1979",
    "seconds": "50",
    "lyric": "I know where I stand",
    "number": "2747",
  },
  {
    "artist": "Justin Timberlake",
    "song": "Mirrors",
    "year": "2013",
    "seconds": "60",
    "lyric": "Put your hand on the glass",
    "number": "2748",
  },
  {
    "artist": "Porter Wagoner",
    "song": "Misery Loves Company",
    "year": "1962",
    "seconds": "50",
    "lyric": "So break out the bottle",
    "number": "2749",
  },
  {
    "artist": "March Violets",
    "song": "Miss Amanda Jones",
    "year": "1987",
    "seconds": "50",
    "lyric": "Hey girl, your suspender shows",
    "number": "2750",
  },
  {
    "artist": "Kelly Clarkson",
    "song": "Miss Independent",
    "year": "2003",
    "seconds": "50",
    "lyric": "Help her off her throne",
    "number": "2751",
  },
  {
    "artist": "Ne-Yo",
    "song": "Miss Independent",
    "year": "2008",
    "seconds": "70",
    "lyric": "And her bills are paid on time",
    "number": "2752",
  },
  {
    "artist": "AFI",
    "song": "Miss Murder",
    "year": "2006",
    "seconds": "40",
    "lyric": "The ghost you love",
    "number": "2753",
  },
  {
    "artist": "Dinah Shore",
    "song": "Miss You",
    "year": "1942",
    "seconds": "60",
    "lyric": "In my dreams I kiss you",
    "number": "2754",
  },
  {
    "artist": "Rolling Stones",
    "song": "Miss You",
    "year": "1978",
    "seconds": "40",
    "lyric": "Shuffling through the street",
    "number": "2755",
  },
  {
    "artist": "Natalie Cole",
    "song": "Miss You Like Crazy",
    "year": "1989",
    "seconds": "50",
    "lyric": "A kiss in the rain",
    "number": "2756",
  },
  {
    "artist": "Janet Jackson",
    "song": "Miss You Much",
    "year": "1989",
    "seconds": "60",
    "lyric": "An arrow going through my heart",
    "number": "2757",
  },
  {
    "artist": "John Waite",
    "song": "Missing You",
    "year": "1984",
    "seconds": "50",
    "lyric": "I always catch my breath",
    "number": "2758",
  },
  {
    "artist": "Faith Hill",
    "song": "Mississippi Girl",
    "year": "2005",
    "seconds": "50",
    "lyric": "The butterflies still get me",
    "number": "2759",
  },
  {
    "artist": "Nina Simone",
    "song": "Mississippi Goddam",
    "year": "1964",
    "seconds": "50",
    "lyric": "Black cat cross my path",
    "number": "2760",
  },
  {
    "artist": "Mountain",
    "song": "Mississippi Queen",
    "year": "1970",
    "seconds": "60",
    "lyric": "You know she was a dancer",
    "number": "2761",
  },
  {
    "artist": "Dorothy Moore",
    "song": "Misty Blue",
    "year": "1976",
    "seconds": "60",
    "lyric": "Turns the flicker to a flame",
    "number": "2762",
  },
  {
    "artist": "Hanson",
    "song": "MMMbop",
    "year": "1997",
    "seconds": "50",
    "lyric": "And start losing your hair",
    "number": "2763",
  },
  {
    "artist": "Inez & Charlie Foxx",
    "song": "Mockingbird",
    "year": "1963",
    "seconds": "70",
    "lyric": "He’s gonna buy me a diamond ring",
    "number": "2764",
  },
  {
    "artist": "David Bowie",
    "song": "Modern Love",
    "year": "1983",
    "seconds": "50",
    "lyric": "But I never wave bye-bye",
    "number": "2765",
  },
  {
    "artist": "Nat King Cole",
    "song": "Mona Lisa",
    "year": "1950",
    "seconds": "50",
    "lyric": "To hide a broken heart",
    "number": "2766",
  },
  {
    "artist": "The Mamas & the Papas",
    "song": "Monday, Monday",
    "year": "1966",
    "seconds": "60",
    "lyric": "Every other day of the week",
    "number": "2767",
  },
  {
    "artist": "Pink Floyd",
    "song": "Money",
    "year": "1973",
    "seconds": "60",
    "lyric": "Take a slice of my pie",
    "number": "2768",
  },
  {
    "artist": "Dire Straits",
    "song": "Money for Nothing",
    "year": "1985",
    "seconds": "60",
    "lyric": "A blister on your little finger",
    "number": "2769",
  },
  {
    "artist": "John Anderson",
    "song": "Money in the Bank",
    "year": "1993",
    "seconds": "70",
    "lyric": "Baby, you’re the apple of my eye",
    "number": "2770",
  },
  {
    "artist": "Lil Scrappy",
    "song": "Money in the Bank",
    "year": "2006",
    "seconds": "50",
    "lyric": "Wallet full of credit cards",
    "number": "2771",
  },
  {
    "artist": "Swizz Beatz",
    "song": "Money in the Bank",
    "year": "2007",
    "seconds": "50",
    "lyric": "And she’s lookin’ so sad",
    "number": "2772",
  },
  {
    "artist": "Barrett Strong",
    "song": "Money (That’s What I Want)",
    "year": "1960",
    "seconds": "50",
    "lyric": "To the birds and bees",
    "number": "2773",
  },
  {
    "artist": "Bobby ‘Boris’ Pickett",
    "song": "Monster Mash",
    "year": "1973",
    "seconds": "70",
    "lyric": "Opened the lid and shook his fist",
    "number": "2774",
  },
  {
    "artist": "Tommy James & the Shondells",
    "song": "Mony Mony",
    "year": "1968",
    "seconds": "50",
    "lyric": "Shoot ‘em down, turn around",
    "number": "2775",
  },
  {
    "artist": "Pat Boone",
    "song": "Moody River",
    "year": "1961",
    "seconds": "50",
    "lyric": "It stands beside the river",
    "number": "2776",
  },
  {
    "artist": "Andy Williams",
    "song": "Moon River",
    "year": "1962",
    "seconds": "50",
    "lyric": "Off to see the world",
    "number": "2777",
  },
  {
    "artist": "Van Morrison",
    "song": "Moondance",
    "year": "1970",
    "seconds": "70",
    "lyric": "Straight into my arms you will run",
    "number": "2778",
  },
  {
    "artist": "Starbuck",
    "song": "Moonlight Feels Right",
    "year": "1975",
    "seconds": "70",
    "lyric": "I caught it in my hands today",
    "number": "2779",
  },
  {
    "artist": "Cat Stevens",
    "song": "Moonshadow",
    "year": "1970",
    "seconds": "60",
    "lyric": "All my teeth, north and south",
    "number": "2780",
  },
  {
    "artist": "Usher",
    "song": "More",
    "year": "2010",
    "seconds": "50",
    "lyric": "I dance under the spotlight",
    "number": "2781",
  },
  {
    "artist": "Kim Carnes",
    "song": "More Love",
    "year": "1980",
    "seconds": "60",
    "lyric": "Wear it down, tear it down",
    "number": "2782",
  },
  {
    "artist": "Andrea True Connection",
    "song": "More, More, More",
    "year": "1976",
    "seconds": "40",
    "lyric": "Get the cameras rollin’",
    "number": "2783",
  },
  {
    "artist": "Boston",
    "song": "More Than a Feeling",
    "year": "1976",
    "seconds": "60",
    "lyric": "I see my Marianne walkin’ away",
    "number": "2784",
  },
  {
    "artist": "Garth Brooks",
    "song": "More Than a Memory",
    "year": "2007",
    "seconds": "50",
    "lyric": "Falling down upon your knees",
    "number": "2785",
  },
  {
    "artist": "Morgan Wallen",
    "song": "More Than My Hometown",
    "year": "2020",
    "seconds": "50",
    "lyric": "The bass hits a hook",
    "number": "2786",
  },
  {
    "artist": "Travis Tritt",
    "song": "More Than You’ll Ever Know",
    "year": "1996",
    "seconds": "70",
    "lyric": "I pour my heart out to you",
    "number": "2787",
  },
  {
    "artist": "Spiral Starecase",
    "song": "More Today Than Yesterday",
    "year": "1969",
    "seconds": "50",
    "lyric": "All my dreams come true",
    "number": "2788",
  },
  {
    "artist": "Cat Stevens",
    "song": "Morning Has Broken",
    "year": "1972",
    "seconds": "60",
    "lyric": "The sweetness of the wet garden",
    "number": "2789",
  },
  {
    "artist": "Sheena Easton",
    "song": "Morning Train (Nine to Five)",
    "year": "1981",
    "seconds": "70",
    "lyric": "Stumble out of bed, stretchin’ and yawnin’",
    "number": "2790",
  },
  {
    "artist": "Bob Dylan",
    "song": "Most Likely You Go Your Way (And I’ll Go Mine)",
    "year": "1974",
    "seconds": "50",
    "lyric": "And he walks on stilts",
    "number": "2791",
  },
  {
    "artist": "Paul Simon",
    "song": "Mother and Child Reunion",
    "year": "1972",
    "seconds": "40",
    "lyric": "Remember a sadder day",
    "number": "2792",
  },
  {
    "artist": "Ernie K-Doe",
    "song": "Mother-in-Law",
    "year": "1961",
    "seconds": "60",
    "lyric": "Every time I open my mouth",
    "number": "2793",
  },
  {
    "artist": "Rolling Stones",
    "song": "Mother’s Little Helper",
    "year": "1966",
    "seconds": "60",
    "lyric": "So she buys an instant cake",
    "number": "2794",
  },
  {
    "artist": "Boyz II Men",
    "song": "Motownphilly",
    "year": "1991",
    "seconds": "50",
    "lyric": "We are ready to roll",
    "number": "2795",
  },
  {
    "artist": "Charley Pride",
    "song": "Mountain of Love",
    "year": "1982",
    "seconds": "70",
    "lyric": "A church and a big tall steeple",
    "number": "2796",
  },
  {
    "artist": "Alabama",
    "song": "Mountain Music",
    "year": "1982",
    "seconds": "60",
    "lyric": "Grandma and Grandpa used to play",
    "number": "2797",
  },
  {
    "artist": "Hank Williams",
    "song": "Move it on Over",
    "year": "1947",
    "seconds": "60",
    "lyric": "The lock on our front door",
    "number": "2798",
  },
  {
    "artist": "Curtis Mayfield",
    "song": "Move On Up",
    "year": "1974",
    "seconds": "40",
    "lyric": "So hush now child",
    "number": "2799",
  },
  {
    "artist": "Maroon 5 (ft. Christina Aguilera)",
    "song": "Moves Like Jagger",
    "year": "2011",
    "seconds": "70",
    "lyric": "I’m a kid, my ego is big",
    "number": "2800",
  },
  {
    "artist": "Jerry Jeff Walker",
    "song": "Mr. Bojangles",
    "year": "1970",
    "seconds": "50",
    "lyric": "Ragged shirt and baggy pants",
    "number": "2801",
  },
  {
    "artist": "The Killers",
    "song": "Mr. Brightside",
    "year": "2003",
    "seconds": "50",
    "lyric": "But she’s touching his chest",
    "number": "2802",
  },
  {
    "artist": "Burl Ives",
    "song": "Mr. Froggie Went a’Courtin’",
    "year": "1950",
    "seconds": "70",
    "lyric": "Yes kind sir, I sit and spin",
    "number": "2803",
  },
  {
    "artist": "The Counting Crows",
    "song": "Mr. Jones",
    "year": "1993",
    "seconds": "70",
    "lyric": "She dances while his father plays guitar",
    "number": "2804",
  },
  {
    "artist": "Kelly Clarkson",
    "song": "Mr. Know It All",
    "year": "2011",
    "seconds": "50",
    "lyric": "The world at your feet",
    "number": "2805",
  },
  {
    "artist": "Bobby Vinton",
    "song": "Mr. Lonely",
    "year": "1964",
    "seconds": "70",
    "lyric": "I get no letters in the mail",
    "number": "2806",
  },
  {
    "artist": "Johnny Paycheck",
    "song": "Mr. Lovemaker",
    "year": "1973",
    "seconds": "50",
    "lyric": "Guide her to my arms",
    "number": "2807",
  },
  {
    "artist": "Lonestar",
    "song": "Mr. Mom",
    "year": "2004",
    "seconds": "50",
    "lyric": "Bubblegum in the baby’s hair",
    "number": "2808",
  },
  {
    "artist": "Styx",
    "song": "Mr. Roboto",
    "year": "1983",
    "seconds": "50",
    "lyric": "To throw away this mask",
    "number": "2809",
  },
  {
    "artist": "The Chordettes",
    "song": "Mr. Sandman",
    "year": "1955",
    "seconds": "60",
    "lyric": "Please turn on your magic beam",
    "number": "2810",
  },
  {
    "artist": "Bob Dylan",
    "song": "Mr. Tambourine Man",
    "year": "1965",
    "seconds": "60",
    "lyric": "My toes too numb to step",
    "number": "2811",
  },
  {
    "artist": "Herman’s Hermits",
    "song": "Mrs. Brown, You’ve Got a Lovely Daughter",
    "year": "1965",
    "seconds": "60",
    "lyric": "Don’t say she’s broke my heart",
    "number": "2812",
  },
  {
    "artist": "Simon & Garfunkel",
    "song": "Mrs. Robinson",
    "year": "1968",
    "seconds": "40",
    "lyric": "Stroll around the grounds",
    "number": "2813",
  },
  {
    "artist": "OutKast",
    "song": "Ms. Jackson",
    "year": "2000",
    "seconds": "60",
    "lyric": "A piece of the American pie",
    "number": "2814",
  },
  {
    "artist": "Brad Paisley",
    "song": "Mud on the Tires",
    "year": "2004",
    "seconds": "60",
    "lyric": "We can throw a blanket down",
    "number": "2815",
  },
  {
    "artist": "Jimmy Rodgers",
    "song": "Mule Skinner Blues",
    "year": "1930",
    "seconds": "60",
    "lyric": "Dollar and a half a day",
    "number": "2816",
  },
  {
    "artist": "Wings",
    "song": "Mull of Kintyre",
    "year": "1977",
    "seconds": "60",
    "lyric": "Mist rolling in from the sea",
    "number": "2817",
  },
  {
    "artist": "Dinah Shore",
    "song": "Murder He Says",
    "year": "1943",
    "seconds": "60",
    "lyric": "Over and over and over again",
    "number": "2818",
  },
  {
    "artist": "Feist",
    "song": "Mushaboom",
    "year": "2004",
    "seconds": "60",
    "lyric": "Old dirt road, knee deep snow",
    "number": "2819",
  },
  {
    "artist": "Madonna",
    "song": "Music",
    "year": "2000",
    "seconds": "60",
    "lyric": "I don’t look at the clock",
    "number": "2820",
  },
  {
    "artist": "Gloria Estefan and NSYNC",
    "song": "Music of My Heart",
    "year": "1999",
    "seconds": "40",
    "lyric": "You opened my eyes",
    "number": "2821",
  },
  {
    "artist": "Captain & Tennille",
    "song": "Muskrat Love",
    "year": "1976",
    "seconds": "60",
    "lyric": "Nibbling on bacon, chewing on cheese",
    "number": "2822",
  },
  {
    "artist": "Wilson Pickett",
    "song": "Mustang Sally",
    "year": "1966",
    "seconds": "60",
    "lyric": "Ride around Sally, ride, Sally, ride",
    "number": "2823",
  },
  {
    "artist": "Mariah Carey",
    "song": "My All",
    "year": "1998",
    "seconds": "50",
    "lyric": "I can see you clearly",
    "number": "2824",
  },
  {
    "artist": "Tanya Tucker",
    "song": "My Arms Stay Open All Night",
    "year": "1989",
    "seconds": "50",
    "lyric": "My heart is never closed",
    "number": "2825",
  },
  {
    "artist": "Dan Seals",
    "song": "My Baby’s Got Good Timing",
    "year": "1984",
    "seconds": "50",
    "lyric": "But she reads my mind",
    "number": "2826",
  },
  {
    "artist": "White Plains",
    "song": "My Baby Loves Lovin’",
    "year": "1970",
    "seconds": "40",
    "lyric": "She smiled at me",
    "number": "2827",
  },
  {
    "artist": "Rosanne Cash",
    "song": "My Baby Thinks He’s a Train",
    "year": "1981",
    "seconds": "50",
    "lyric": "He makes his whistle stop",
    "number": "2828",
  },
  {
    "artist": "Tim McGraw",
    "song": "My Best Friend",
    "year": "1999",
    "seconds": "40",
    "lyric": "And I still tremble",
    "number": "2829",
  },
  {
    "artist": "The Cars",
    "song": "My Best Friend’s Girl",
    "year": "1978",
    "seconds": "60",
    "lyric": "She’s dancing ‘neath the starry sky",
    "number": "2830",
  },
  {
    "artist": "Gene Austin",
    "song": "My Blue Heaven",
    "year": "1928",
    "seconds": "50",
    "lyric": "A smiling face, a fireplace",
    "number": "2831",
  },
  {
    "artist": "Usher and Alicia Keys",
    "song": "My Boo",
    "year": "2004",
    "seconds": "50",
    "lyric": "You’re blinded from the start",
    "number": "2832",
  },
  {
    "artist": "Billie Eilish",
    "song": "My Boy",
    "year": "2017",
    "seconds": "50",
    "lyric": "I love my split ends",
    "number": "2833",
  },
  {
    "artist": "The Angels",
    "song": "My Boyfriend’s Back",
    "year": "1963",
    "seconds": "60",
    "lyric": "He’ll cut you down to size",
    "number": "2834",
  },
  {
    "artist": "Stevie Wonder",
    "song": "My Cherie Amour",
    "year": "1969",
    "seconds": "70",
    "lyric": "The only girl my heart beats for",
    "number": "2835",
  },
  {
    "artist": "Maren Morris",
    "song": "My Church",
    "year": "2016",
    "seconds": "40",
    "lyric": "Roll the windows down",
    "number": "2836",
  },
  {
    "artist": "Chuck Berry",
    "song": "My Ding-a-Ling",
    "year": "1972",
    "seconds": "50",
    "lyric": "Silver bells hanging on a string",
    "number": "2837",
  },
  {
    "artist": "David Houston and Tammy Wynette",
    "song": "My Elusive Dreams",
    "year": "1967",
    "seconds": "50",
    "lyric": "I followed you to Texas",
    "number": "2838",
  },
  {
    "artist": "Frankie Valli",
    "song": "My Eyes Adored You",
    "year": "1974",
    "seconds": "50",
    "lyric": "Carried your books from school",
    "number": "2839",
  },
  {
    "artist": "Julie Andrews",
    "song": "My Favorite Things",
    "year": "1965",
    "seconds": "70",
    "lyric": "Raindrops on roses and whiskers on kittens",
    "number": "2840",
  },
  {
    "artist": "Red Hot Chili Peppers",
    "song": "My Friends",
    "year": "1995",
    "seconds": "40",
    "lyric": "Hurt by the cold",
    "number": "2841",
  },
  {
    "artist": "Lonestar",
    "song": "My Front Porch Looking In",
    "year": "2003",
    "seconds": "60",
    "lyric": "With a sippy cup of milk",
    "number": "2842",
  },
  {
    "artist": "Billie Eilish",
    "song": "My Future",
    "year": "2020",
    "seconds": "70",
    "lyric": "I’ll see you in a couple years",
    "number": "2843",
  },
  {
    "artist": "The Who",
    "song": "My Generation",
    "year": "1965",
    "seconds": "50",
    "lyric": "Dig what we all say",
    "number": "2844",
  },
  {
    "artist": "The Temptations",
    "song": "My Girl",
    "year": "1964",
    "seconds": "50",
    "lyric": "The birds in the trees",
    "number": "2845",
  },
  {
    "artist": "Jim Stafford",
    "song": "My Girl Bill",
    "year": "1974",
    "seconds": "50",
    "lyric": "Sit here on the couch",
    "number": "2846",
  },
  {
    "artist": "Jo Dee Messina",
    "song": "My Give a Damn’s Busted",
    "year": "2005",
    "seconds": "50",
    "lyric": "You can crawl back home",
    "number": "2847",
  },
  {
    "artist": "Mary Wells",
    "song": "My Guy",
    "year": "1964",
    "seconds": "60",
    "lyric": "Like a stamp to a letter",
    "number": "2848",
  },
  {
    "artist": "Connie Francis",
    "song": "My Happiness",
    "year": "1958",
    "seconds": "50",
    "lyric": "Dreaming of your tender kiss",
    "number": "2849",
  },
  {
    "artist": "ZZ Top",
    "song": "My Head’s in Mississippi",
    "year": "1990",
    "seconds": "40",
    "lyric": "Floatin’ across the ceiling",
    "number": "2850",
  },
  {
    "artist": "Ronnie Milsap",
    "song": "My Heart",
    "year": "1980",
    "seconds": "50",
    "lyric": "And I’ll throw a party",
    "number": "2851",
  },
  {
    "artist": "Barbra Streisand",
    "song": "My Heart Belongs To Me",
    "year": "1977",
    "seconds": "40",
    "lyric": "Come lie beside me",
    "number": "2852",
  },
  {
    "artist": "Dinah Shore",
    "song": "My Hearts Cries for You",
    "year": "1950",
    "seconds": "60",
    "lyric": "And my arms long for you",
    "number": "2853",
  },
  {
    "artist": "Paul Brandt",
    "song": "My Heart Has a History",
    "year": "1996",
    "seconds": "50",
    "lyric": "One kiss at a time",
    "number": "2854",
  },
  {
    "artist": "Connie Francis",
    "song": "My Heart Has a Mind of Its Own",
    "year": "1960",
    "seconds": "60",
    "lyric": "You’re not in love with me",
    "number": "2855",
  },
  {
    "artist": "Buck Owens",
    "song": "My Heart Skips a Beat",
    "year": "1964",
    "seconds": "70",
    "lyric": "I feel a trembling in my knees",
    "number": "2856",
  },
  {
    "artist": "Céline Dion",
    "song": "My Heart Will Go On",
    "year": "1997",
    "seconds": "60",
    "lyric": "Once more, you open the door",
    "number": "2857",
  },
  {
    "artist": "Willie Nelson",
    "song": "My Heroes Have Always Been Cowboys",
    "year": "1980",
    "seconds": "60",
    "lyric": "I burned up my childhood days",
    "number": "2858",
  },
  {
    "artist": "Bruce Springsteen",
    "song": "My Hometown",
    "year": "1985",
    "seconds": "50",
    "lyric": "Between the black and white",
    "number": "2859",
  },
  {
    "artist": "The Black Eyed Peas",
    "song": "My Humps",
    "year": "2005",
    "seconds": "50",
    "lyric": "I drive these brothers crazy",
    "number": "2860",
  },
  {
    "artist": "Evanescence",
    "song": "My Immortal",
    "year": "2003",
    "seconds": "60",
    "lyric": "Wipe away all of your tears",
    "number": "2861",
  },
  {
    "artist": "Jason Aldean",
    "song": "My Kinda Party",
    "year": "2010",
    "seconds": "50",
    "lyric": "Sneak away from the bonfire",
    "number": "2862",
  },
  {
    "artist": "Kelly Clarkson",
    "song": "My Life Would Suck Without You",
    "year": "2009",
    "seconds": "60",
    "lyric": "For tryin’ to pick a fight",
    "number": "2863",
  },
  {
    "artist": "Toby Keith",
    "song": "My List",
    "year": "2002",
    "seconds": "70",
    "lyric": "Wade the shore and cast a line",
    "number": "2864",
  },
  {
    "artist": "Tim McGraw",
    "song": "My Little Girl",
    "year": "2006",
    "seconds": "50",
    "lyric": "Melt my heart of stone",
    "number": "2865",
  },
  {
    "artist": "Justin Timberlake (feat. T.I.)",
    "song": "My Love",
    "year": "2006",
    "seconds": "50",
    "lyric": "I wrote you a symphony",
    "number": "2866",
  },
  {
    "artist": "Lionel Richie",
    "song": "My Love",
    "year": "1983",
    "seconds": "60",
    "lyric": "Blows my mind all the time",
    "number": "2867",
  },
  {
    "artist": "Paul McCartney & Wings",
    "song": "My Love",
    "year": "1973",
    "seconds": "50",
    "lyric": "And when the cupboard’s bare",
    "number": "2868",
  },
  {
    "artist": "Petula Clark",
    "song": "My Love",
    "year": "1966",
    "seconds": "50",
    "lyric": "Deeper than the deepest ocean",
    "number": "2869",
  },
  {
    "artist": "B. W. Stevenson",
    "song": "My Maria",
    "year": "1973",
    "seconds": "60",
    "lyric": "A ship sailing on the sea",
    "number": "2870",
  },
  {
    "artist": "Bobby Vinton",
    "song": "My Melody of Love",
    "year": "1974",
    "seconds": "60",
    "lyric": "Love you with all my heart",
    "number": "2871",
  },
  {
    "artist": "Feist",
    "song": "My Moon My Man",
    "year": "2007",
    "seconds": "60",
    "lyric": "Shed some light on me please",
    "number": "2872",
  },
  {
    "artist": "Troye Sivan",
    "song": "My My My!",
    "year": "2018",
    "seconds": "50",
    "lyric": "My tongue between your teeth",
    "number": "2873",
  },
  {
    "artist": "Brooks & Dunn",
    "song": "My Next Broken Heart",
    "year": "1991",
    "seconds": "50",
    "lyric": "Be the death of me",
    "number": "2874",
  },
  {
    "artist": "Tim McGraw",
    "song": "My Next Thirty Years",
    "year": "2000",
    "seconds": "50",
    "lyric": "I’m gonna watch my weight",
    "number": "2875",
  },
  {
    "artist": "Zac Brown Band",
    "song": "My Old Man",
    "year": "2017",
    "seconds": "50",
    "lyric": "Trying to fill the boots",
    "number": "2876",
  },
  {
    "artist": "George Harrison",
    "song": "My Sweet Lord",
    "year": "1971",
    "seconds": "60",
    "lyric": "I really want to know you",
    "number": "2877",
  },
  {
    "artist": "Rascal Flatts",
    "song": "My Wish",
    "year": "2006",
    "seconds": "40",
    "lyric": "Your worries stay small",
    "number": "2878",
  },
  {
    "artist": "Marty Robbins",
    "song": "My Woman, My Woman, My Wife",
    "year": "1970",
    "seconds": "50",
    "lyric": "The foundation I lean on",
    "number": "2879",
  },
  {
    "artist": "David Houston",
    "song": "My Woman’s Good To Me",
    "year": "1969",
    "seconds": "60",
    "lyric": "Your lips are warm and close",
    "number": "2880",
  },
  {
    "artist": "The Supremes",
    "song": "My World is Empty Without You",
    "year": "1965",
    "seconds": "50",
    "lyric": "I need your tender touch",
    "number": "2881",
  },
  {
    "artist": "U2",
    "song": "Mysterious Ways",
    "year": "1991",
    "seconds": "50",
    "lyric": "Your sister in the rain",
    "number": "2882",
  },
  {
    "artist": "Janet Jackson",
    "song": "Nasty",
    "year": "1986",
    "seconds": "40",
    "lyric": "So close the door",
    "number": "2883",
  },
  {
    "artist": "Beyoncé",
    "song": "Naughty Girl",
    "year": "2004",
    "seconds": "60",
    "lyric": "You can feel my burning flame",
    "number": "2884",
  },
  {
    "artist": "Lady Antebellum",
    "song": "Need You Now",
    "year": "2009",
    "seconds": "50",
    "lyric": "It’s a quarter after one",
    "number": "2885",
  },
  {
    "artist": "The Searchers",
    "song": "Needles and Pins",
    "year": "1964",
    "seconds": "60",
    "lyric": "Down on my knees and pray",
    "number": "2886",
  },
  {
    "artist": "Arcade Fire",
    "song": "Neighborhood #1 (Tunnels)",
    "year": "2004",
    "seconds": "50",
    "lyric": "You climb out the chimney",
    "number": "2887",
  },
  {
    "artist": "Gladys Knight & the Pips",
    "song": "Neither One of Us",
    "year": "1972",
    "seconds": "50",
    "lyric": "The first to say goodbye",
    "number": "2888",
  },
  {
    "artist": "Arcade Fire",
    "song": "Neon Bible",
    "year": "2007",
    "seconds": "40",
    "lyric": "Don’t lick your fingers",
    "number": "2889",
  },
  {
    "artist": "Brooks & Dunn",
    "song": "Neon Moon",
    "year": "1992",
    "seconds": "50",
    "lyric": "To watch your broken dreams",
    "number": "2890",
  },
  {
    "artist": "Heart",
    "song": "Never",
    "year": "1985",
    "seconds": "50",
    "lyric": "Stand up and turn around",
    "number": "2891",
  },
  {
    "artist": "Kelly Clarkson",
    "song": "Never Again",
    "year": "2007",
    "seconds": "40",
    "lyric": "Turns her finger green",
    "number": "2892",
  },
  {
    "artist": "Head East",
    "song": "Never Been Any Reason",
    "year": "1975",
    "seconds": "60",
    "lyric": "Save my life, I’m goin’ down",
    "number": "2893",
  },
  {
    "artist": "Three Dog Night",
    "song": "Never Been to Spain",
    "year": "1971",
    "seconds": "50",
    "lyric": "The ladies are insane there",
    "number": "2894",
  },
  {
    "artist": "The Jackson 5",
    "song": "Never Can Say Goodbye",
    "year": "1971",
    "seconds": "60",
    "lyric": "I start heading for the door",
    "number": "2895",
  },
  {
    "artist": "The Pretenders",
    "song": "Never Do That",
    "year": "1990",
    "seconds": "50",
    "lyric": "A shock right through me",
    "number": "2896",
  },
  {
    "artist": "Delaney & Bonnie & Friends",
    "song": "Never Ending Song of Love",
    "year": "1971",
    "seconds": "50",
    "lyric": "We can love one another",
    "number": "2897",
  },
  {
    "artist": "All Saints",
    "song": "Never Ever",
    "year": "1997",
    "seconds": "50",
    "lyric": "Boy, I’m in a daze",
    "number": "2898",
  },
  {
    "artist": "Take That",
    "song": "Never Forget",
    "year": "1995",
    "seconds": "60",
    "lyric": "Feel I’ve walked a thousand miles",
    "number": "2899",
  },
  {
    "artist": "Sia (from “Lion” soundtrack)",
    "song": "Never Give Up",
    "year": "2017",
    "seconds": "50",
    "lyric": "When I hit the ground",
    "number": "2900",
  },
  {
    "artist": "Eric Carmen",
    "song": "Never Gonna Fall in Love Again",
    "year": "1976",
    "seconds": "60",
    "lyric": "Close the door and walk away",
    "number": "2901",
  },
  {
    "artist": "Rick Astley",
    "song": "Never Gonna Give You Up",
    "year": "1987",
    "seconds": "50",
    "lyric": "Run around and desert you",
    "number": "2902",
  },
  {
    "artist": "The Association",
    "song": "Never My Love",
    "year": "1967",
    "seconds": "60",
    "lyric": "When I grow tired of you",
    "number": "2903",
  },
  {
    "artist": "Barry White",
    "song": "Never, Never Gonna Give You Up",
    "year": "1974",
    "seconds": "60",
    "lyric": "I know you need it, girl",
    "number": "2904",
  },
  {
    "artist": "Corey Hart",
    "song": "Never Surrender",
    "year": "1985",
    "seconds": "40",
    "lyric": "You can see light",
    "number": "2905",
  },
  {
    "artist": "Three Days Grace",
    "song": "Never Too Late",
    "year": "2007",
    "seconds": "50",
    "lyric": "We’ll turn it all around",
    "number": "2906",
  },
  {
    "artist": "Kenny Chesney",
    "song": "Never Wanted Nothing More",
    "year": "2007",
    "seconds": "50",
    "lyric": "And hands on the wheel",
    "number": "2907",
  },
  {
    "artist": "Red Foley and The Cumberland Valley Boys",
    "song": "New Jolie Blonde",
    "year": "1947",
    "seconds": "50",
    "lyric": "Come up on the range",
    "number": "2908",
  },
  {
    "artist": "Eagles",
    "song": "New Kid in Town",
    "year": "1976",
    "seconds": "40",
    "lyric": "Tears on your shoulder",
    "number": "2909",
  },
  {
    "artist": "B.J. Thomas",
    "song": "New Looks From an Old Lover",
    "year": "1983",
    "seconds": "60",
    "lyric": "And caught you watching me sleep",
    "number": "2910",
  },
  {
    "artist": "Taylor Swift",
    "song": "New Romantics",
    "year": "2016",
    "seconds": "50",
    "lyric": "We cry tears of mascara",
    "number": "2911",
  },
  {
    "artist": "Dua Lipa",
    "song": "New Rules",
    "year": "2017",
    "seconds": "50",
    "lyric": "Eat, sleep and breathe it",
    "number": "2912",
  },
  {
    "artist": "Rush",
    "song": "New World Man",
    "year": "1982",
    "seconds": "50",
    "lyric": "A young boy bearing arms",
    "number": "2913",
  },
  {
    "artist": "U2",
    "song": "New Year’s Day",
    "year": "1983",
    "seconds": "50",
    "lyric": "Under a blood red sky",
    "number": "2914",
  },
  {
    "artist": "St. Vincent",
    "song": "New York",
    "year": "2017",
    "seconds": "60",
    "lyric": "I call you from First Avenue",
    "number": "2915",
  },
  {
    "artist": "Frank Sinatra",
    "song": "New York, New York",
    "year": "1962",
    "seconds": "50",
    "lyric": "Wake up in a city",
    "number": "2916",
  },
  {
    "artist": "Neil Sedaka",
    "song": "Next Door to an Angel",
    "year": "1962",
    "seconds": "50",
    "lyric": "I’m on cloud number nine",
    "number": "2917",
  },
  {
    "artist": "Conway Twitty",
    "song": "Next in Line",
    "year": "1968",
    "seconds": "60",
    "lyric": "Watch her tear at the label",
    "number": "2918",
  },
  {
    "artist": "Shenandoah",
    "song": "Next to You, Next to Me",
    "year": "1990",
    "seconds": "50",
    "lyric": "Barbecue chicken in aluminum foil",
    "number": "2919",
  },
  {
    "artist": "Gallery",
    "song": "Nice to Be with You",
    "year": "1972",
    "seconds": "60",
    "lyric": "And help me to carry on",
    "number": "2920",
  },
  {
    "artist": "The Bee Gees",
    "song": "Night Fever",
    "year": "1978",
    "seconds": "60",
    "lyric": "I get higher in my walkin’",
    "number": "2921",
  },
  {
    "artist": "The Pretenders",
    "song": "Night in My Veins",
    "year": "1994",
    "seconds": "60",
    "lyric": "The back of a pickup truck",
    "number": "2922",
  },
  {
    "artist": "Bob Seger",
    "song": "Night Moves",
    "year": "1976",
    "seconds": "40",
    "lyric": "Front page drive-in news",
    "number": "2923",
  },
  {
    "artist": "Jason Aldean",
    "song": "Night Train",
    "year": "2013",
    "seconds": "70",
    "lyric": "Hurry up girl, I hear it coming",
    "number": "2924",
  },
  {
    "artist": "Avenged Sevenfold",
    "song": "Nightmare",
    "year": "2010",
    "seconds": "50",
    "lyric": "Wake up in a sweat",
    "number": "2925",
  },
  {
    "artist": "Frank Ocean",
    "song": "Nights",
    "year": "2016",
    "seconds": "50",
    "lyric": "Know you need the money",
    "number": "2926",
  },
  {
    "artist": "The Moody Blues",
    "song": "Nights in White Satin",
    "year": "1972",
    "seconds": "40",
    "lyric": "Never reaching the end",
    "number": "2927",
  },
  {
    "artist": "The Commodores",
    "song": "Nightshift",
    "year": "1985",
    "seconds": "50",
    "lyric": "He opened up our minds",
    "number": "2928",
  },
  {
    "artist": "R.E.M.",
    "song": "Nightswimming",
    "year": "1992",
    "seconds": "40",
    "lyric": "I forgot my shirt",
    "number": "2929",
  },
  {
    "artist": "Elton John",
    "song": "Nikita",
    "year": "1985",
    "seconds": "60",
    "lyric": "Gates no longer hold you in",
    "number": "2930",
  },
  {
    "artist": "Wilson Pickett",
    "song": "Ninety Nine and a Half (Won’t Do)",
    "year": "1966",
    "seconds": "60",
    "lyric": "A man needs a little love",
    "number": "2931",
  },
  {
    "artist": "Meghan Trainor",
    "song": "No",
    "year": "2016",
    "seconds": "70",
    "lyric": "Lick your lips and swing your hips",
    "number": "2932",
  },
  {
    "artist": "Robert Tepper",
    "song": "No Easy Way Out",
    "year": "1986",
    "seconds": "70",
    "lyric": "Baby, baby, we can shed this skin",
    "number": "2933",
  },
  {
    "artist": "Zac Brown Band",
    "song": "No Hurry",
    "year": "2012",
    "seconds": "60",
    "lyric": "The front yard needs a trim",
    "number": "2934",
  },
  {
    "artist": "The Oak Ridge Boys",
    "song": "No Matter How High",
    "year": "1989",
    "seconds": "50",
    "lyric": "I’ll settle for number two",
    "number": "2935",
  },
  {
    "artist": "Badfinger",
    "song": "No Matter What",
    "year": "1970",
    "seconds": "60",
    "lyric": "Knock down the old grey wall",
    "number": "2936",
  },
  {
    "artist": "Barbra Streisand (ft. Donna Summer)",
    "song": "No More Tears (Enough Is Enough)",
    "year": "1979",
    "seconds": "60",
    "lyric": "Get the check, pay the bill",
    "number": "2937",
  },
  {
    "artist": "Lonestar",
    "song": "No News",
    "year": "1996",
    "seconds": "50",
    "lyric": "Locked in a bathroom stall",
    "number": "2938",
  },
  {
    "artist": "Beirut",
    "song": "No No No",
    "year": "2015",
    "seconds": "40",
    "lyric": "My heart is waiting",
    "number": "2939",
  },
  {
    "artist": "Wynonna Judd",
    "song": "No One Else on Earth",
    "year": "1992",
    "seconds": "50",
    "lyric": "And I’ve got my fences",
    "number": "2940",
  },
  {
    "artist": "Scorpions",
    "song": "No One Like You",
    "year": "1982",
    "seconds": "60",
    "lyric": "Wait for the nights with you",
    "number": "2941",
  },
  {
    "artist": "Shania Twain",
    "song": "No One Needs to Know",
    "year": "1996",
    "seconds": "60",
    "lyric": "My heart set, my feet wet",
    "number": "2942",
  },
  {
    "artist": "Perry Como",
    "song": "No Other Love",
    "year": "1953",
    "seconds": "50",
    "lyric": "Into your arms I’ll fly",
    "number": "2943",
  },
  {
    "artist": "TLC",
    "song": "No Scrubs",
    "year": "1992",
    "seconds": "50",
    "lyric": "Hanging out the passenger side",
    "number": "2944",
  },
  {
    "artist": "Kenny Chesney",
    "song": "No Shoes, No Shirt, No Problems",
    "year": "2003",
    "seconds": "60",
    "lyric": "And a drink in my hand",
    "number": "2945",
  },
  {
    "artist": "Old Dominion",
    "song": "No Such Thing as a Broken Heart",
    "year": "2017",
    "seconds": "50",
    "lyric": "Jumping off a rope swing",
    "number": "2946",
  },
  {
    "artist": "The Guess Who",
    "song": "No Sugar Tonight",
    "year": "1970",
    "seconds": "60",
    "lyric": "Sudden darkness but I can see",
    "number": "2947",
  },
  {
    "artist": "Ariana Grande",
    "song": "No Tears Left to Cry",
    "year": "2018",
    "seconds": "40",
    "lyric": "I’m pickin’ it up",
    "number": "2948",
  },
  {
    "artist": "Chicago",
    "song": "No Tell Lover",
    "year": "1978",
    "seconds": "40",
    "lyric": "You see me coming",
    "number": "2949",
  },
  {
    "artist": "The Guess Who",
    "song": "No Time",
    "year": "1969",
    "seconds": "50",
    "lyric": "For my watch and chain",
    "number": "2950",
  },
  {
    "artist": "Billie Eilish",
    "song": "No Time to Die",
    "year": "2020",
    "seconds": "50",
    "lyric": "But I saw you there",
    "number": "2951",
  },
  {
    "artist": "Bob Marley & the Wailers",
    "song": "No Woman No Cry",
    "year": "1979",
    "seconds": "60",
    "lyric": "So dry your tears, I say",
    "number": "2952",
  },
  {
    "artist": "Sylvia",
    "song": "Nobody",
    "year": "1982",
    "seconds": "40",
    "lyric": "Who’s on your mind?",
    "number": "2953",
  },
  {
    "artist": "The Human Beinz",
    "song": "Nobody But Me",
    "year": "1967",
    "seconds": "50",
    "lyric": "The Skate, like I do",
    "number": "2954",
  },
  {
    "artist": "Blake Shelton & Gwen Stefani",
    "song": "Nobody But You",
    "year": "2020",
    "seconds": "50",
    "lyric": "Looking in your eyes now",
    "number": "2955",
  },
  {
    "artist": "Carly Simon",
    "song": "Nobody Does It Better",
    "year": "1977",
    "seconds": "70",
    "lyric": "Makes me feel sad for the rest",
    "number": "2956",
  },
  {
    "artist": "George Strait",
    "song": "Nobody in His Right Mind Would’ve Left Her",
    "year": "1986",
    "seconds": "50",
    "lyric": "I still carry her picture",
    "number": "2957",
  },
  {
    "artist": "The Tony Rich Project",
    "song": "Nobody Knows",
    "year": "1996",
    "seconds": "40",
    "lyric": "And I’m dying inside",
    "number": "2958",
  },
  {
    "artist": "Ronnie Milsap",
    "song": "Nobody Likes Sad Songs",
    "year": "1979",
    "seconds": "40",
    "lyric": "Tears in your eyes",
    "number": "2959",
  },
  {
    "artist": "Anne Murray & Dave Loggins",
    "song": "Nobody Loves Me Like You Do",
    "year": "1984",
    "seconds": "50",
    "lyric": "A dancer out of time",
    "number": "2960",
  },
  {
    "artist": "Chris Stapleton",
    "song": "Nobody to Blame",
    "year": "2016",
    "seconds": "60",
    "lyric": "My clothes out in the yard",
    "number": "2961",
  },
  {
    "artist": "Clint Black",
    "song": "Nobody’s Home",
    "year": "1989",
    "seconds": "70",
    "lyric": "And I think I’m losing my hair",
    "number": "2962",
  },
  {
    "artist": "Johnny Horton",
    "song": "North to Alaska",
    "year": "1961",
    "seconds": "40",
    "lyric": "Big nuggets they’re finding",
    "number": "2963",
  },
  {
    "artist": "Stan Rogers",
    "song": "Northwest Passage",
    "year": "1981",
    "seconds": "60",
    "lyric": "To find the hand of Franklin",
    "number": "2964",
  },
  {
    "artist": "The Beatles",
    "song": "Norwegian Wood",
    "year": "1965",
    "seconds": "50",
    "lyric": "I once had a girl",
    "number": "2965",
  },
  {
    "artist": "Lonestar",
    "song": "Not a Day Goes By",
    "year": "2002",
    "seconds": "50",
    "lyric": "Your head against my heart",
    "number": "2966",
  },
  {
    "artist": "Tim McGraw",
    "song": "Not a Moment Too Soon",
    "year": "1994",
    "seconds": "50",
    "lyric": "Before I crossed the line",
    "number": "2967",
  },
  {
    "artist": "Buddy Holly",
    "song": "Not Fade Away",
    "year": "1957",
    "seconds": "70",
    "lyric": "My love is bigger than a Cadillac",
    "number": "2968",
  },
  {
    "artist": "Jeff Carson",
    "song": "Not On Your Love",
    "year": "1995",
    "seconds": "60",
    "lyric": "We don’t see eye to eye",
    "number": "2969",
  },
  {
    "artist": "Heart",
    "song": "Nothin’ At All",
    "year": "1986",
    "seconds": "50",
    "lyric": "Through the pyramids of light",
    "number": "2970",
  },
  {
    "artist": "Céline Dion",
    "song": "Nothing Broken But My Heart",
    "year": "1992",
    "seconds": "60",
    "lyric": "I’ll say I’m happy I’m free",
    "number": "2971",
  },
  {
    "artist": "Poison",
    "song": "Nothin’ But a Good Time",
    "year": "1988",
    "seconds": "70",
    "lyric": "Spend my money on women and wine",
    "number": "2972",
  },
  {
    "artist": "Clint Black",
    "song": "Nothin’ But the Taillights",
    "year": "1998",
    "seconds": "40",
    "lyric": "I was ridin’ shotgun",
    "number": "2973",
  },
  {
    "artist": "Sam Cooke",
    "song": "Nothing Can Change This Love",
    "year": "1962",
    "seconds": "50",
    "lyric": "You’re cake and ice cream",
    "number": "2974",
  },
  {
    "artist": "Sinéad O’Connor",
    "song": "Nothing Compares to U",
    "year": "1990",
    "seconds": "50",
    "lyric": "Dinner in a fancy restaurant",
    "number": "2975",
  },
  {
    "artist": "Billy Preston",
    "song": "Nothing From Nothing",
    "year": "1974",
    "seconds": "60",
    "lyric": "Zero is too cold for me",
    "number": "2976",
  },
  {
    "artist": "Willie Nelson",
    "song": "Nothing I Can Do About It Now",
    "year": "1989",
    "seconds": "40",
    "lyric": "Written on my brow",
    "number": "2977",
  },
  {
    "artist": "Tim McGraw",
    "song": "Nothin’ To Die For",
    "year": "2009",
    "seconds": "40",
    "lyric": "Stop and turn around",
    "number": "2978",
  },
  {
    "artist": "Josh Gracin",
    "song": "Nothin’ to Lose",
    "year": "2005",
    "seconds": "70",
    "lyric": "Rolling in dirt in a white t-shirt",
    "number": "2979",
  },
  {
    "artist": "Guns N’ Roses",
    "song": "November Rain",
    "year": "1991",
    "seconds": "70",
    "lyric": "And it’s hard to hold a candle",
    "number": "2980",
  },
  {
    "artist": "Anne Murray",
    "song": "Now and Forever (You and Me)",
    "year": "1986",
    "seconds": "50",
    "lyric": "And you’re melting the ice",
    "number": "2981",
  },
  {
    "artist": "Hank Snow",
    "song": "(Now and Then There’s) A Fool Such As I",
    "year": "1952",
    "seconds": "60",
    "lyric": "You say that we are through",
    "number": "2982",
  },
  {
    "artist": "The Beatles",
    "song": "Nowhere Man",
    "year": "1965",
    "seconds": "50",
    "lyric": "Blind as he can be",
    "number": "2983",
  },
  {
    "artist": "Linkin Park",
    "song": "Numb",
    "year": "2003",
    "seconds": "70",
    "lyric": "The pressure of walking in your shoes",
    "number": "2984",
  },
  {
    "artist": "Mitch Miller and the Gang",
    "song": "O My Darling, Clementine",
    "year": "1959",
    "seconds": "60",
    "lyric": "Drove she ducklings to the water",
    "number": "2985",
  },
  {
    "artist": "The Beatles",
    "song": "Ob-La-Di, Ob-La-Da",
    "year": "1968",
    "seconds": "50",
    "lyric": "Built a home sweet home",
    "number": "2986",
  },
  {
    "artist": "Mariah Carey",
    "song": "Obsessed",
    "year": "2009",
    "seconds": "50",
    "lyric": "Boy, you’re losing your mind",
    "number": "2987",
  },
  {
    "artist": "Billie Eilish",
    "song": "Ocean Eyes",
    "year": "2016",
    "seconds": "50",
    "lyric": "Thinking of your diamond mine",
    "number": "2988",
  },
  {
    "artist": "George Strait",
    "song": "Ocean Front Property",
    "year": "1986",
    "seconds": "50",
    "lyric": "You can see the sea",
    "number": "2989",
  },
  {
    "artist": "The Beatles",
    "song": "Octopus’s Garden",
    "year": "1969",
    "seconds": "60",
    "lyric": "Our little hideaway beneath the waves",
    "number": "2990",
  },
  {
    "artist": "Bobbie Gentry",
    "song": "Ode to Billie Joe",
    "year": "1967",
    "seconds": "70",
    "lyric": "He put a frog down my back",
    "number": "2991",
  },
  {
    "artist": "Michael Jackson",
    "song": "Off the Wall",
    "year": "1980",
    "seconds": "50",
    "lyric": "Before you get too old",
    "number": "2992",
  },
  {
    "artist": "Hurricane Smith",
    "song": "Oh, Babe, What Would You Say?",
    "year": "1973",
    "seconds": "60",
    "lyric": "On a cold and rainy day",
    "number": "2993",
  },
  {
    "artist": "Shaggy",
    "song": "Oh Carolina",
    "year": "1993",
    "seconds": "50",
    "lyric": "Moce just like a squirrel",
    "number": "2994",
  },
  {
    "artist": "The Chi-Lites",
    "song": "Oh Girl",
    "year": "1972",
    "seconds": "60",
    "lyric": "So I try to be hip",
    "number": "2995",
  },
  {
    "artist": "Don Gibson",
    "song": "Oh Lonesome Me",
    "year": "1958",
    "seconds": "50",
    "lyric": "She’s out and fancy free",
    "number": "2996",
  },
  {
    "artist": "Ringo Starr",
    "song": "Oh My My",
    "year": "1974",
    "seconds": "40",
    "lyric": "My knees started shakin’",
    "number": "2997",
  },
  {
    "artist": "The Commodores",
    "song": "Oh No",
    "year": "1981",
    "seconds": "80",
    "lyric": "I close my eyes, I see your face",
    "number": "2998",
  },
  {
    "artist": "Roy Orbison",
    "song": "Oh, Pretty Woman",
    "year": "1964",
    "seconds": "60",
    "lyric": "Is she walking back to me?",
    "number": "2999",
  },
  {
    "artist": "Steve Perry",
    "song": "Oh Sherrie",
    "year": "1984",
    "seconds": "50",
    "lyric": "You’ll go on hurtin’ me",
    "number": "3000",
  },
  {
    "artist": "Cat Stevens",
    "song": "Oh Very Young",
    "year": "1974",
    "seconds": "50",
    "lyric": "Ride the great white bird",
    "number": "3001",
  },
  {
    "artist": "Crosby, Stills, Nash & Young",
    "song": "Ohio",
    "year": "1970",
    "seconds": "50",
    "lyric": "Soldiers are cutting us down",
    "number": "3002",
  },
  {
    "artist": "Merle Haggard",
    "song": "Okie from Muskogee",
    "year": "1969",
    "seconds": "60",
    "lyric": "Still the biggest thrill of all",
    "number": "3003",
  },
  {
    "artist": "Brad Paisley (ft. Alabama)",
    "song": "Old Alabama",
    "year": "2011",
    "seconds": "50",
    "lyric": "A pair of cut-off jeans",
    "number": "3004",
  },
  {
    "artist": "Chicago",
    "song": "Old Days",
    "year": "1975",
    "seconds": "50",
    "lyric": "Comic books and blue jeans",
    "number": "3005",
  },
  {
    "artist": "Tom T. Hall",
    "song": "Old Dogs, Children & Watermelon Wine",
    "year": "1972",
    "seconds": "50",
    "lyric": "I had to catch a plane",
    "number": "3006",
  },
  {
    "artist": "Ronnie Milsap & Mike Reid",
    "song": "Old Folks",
    "year": "1988",
    "seconds": "60",
    "lyric": "Her hands are bent and sore",
    "number": "3007",
  },
  {
    "artist": "Neil Young",
    "song": "Old Man",
    "year": "1972",
    "seconds": "40",
    "lyric": "Rolling home to you",
    "number": "3008",
  },
  {
    "artist": "Paul Robeson",
    "song": "Ol’ Man River",
    "year": "1936",
    "seconds": "50",
    "lyric": "He keeps on rollin’ along",
    "number": "3009",
  },
  {
    "artist": "Lil Nas X (feat. Billy Ray Cyrus)",
    "song": "Old Town Road",
    "year": "2019",
    "seconds": "50",
    "lyric": "In my Maserati sports car",
    "number": "3010",
  },
  {
    "artist": "Usher (ft. will.i.am)",
    "song": "OMG",
    "year": "2010",
    "seconds": "50",
    "lyric": "Make a grown man cry",
    "number": "3011",
  },
  {
    "artist": "The Hollies",
    "song": "On a Carousel",
    "year": "1967",
    "seconds": "60",
    "lyric": "Pulling ducks out of the water",
    "number": "3012",
  },
  {
    "artist": "Bob Dylan",
    "song": "On a Night Like This",
    "year": "1974",
    "seconds": "60",
    "lyric": "Run your fingers down my spine",
    "number": "3013",
  },
  {
    "artist": "Gladys Knight & the Pips",
    "song": "On and On",
    "year": "1974",
    "seconds": "40",
    "lyric": "But I’m so afraid",
    "number": "3014",
  },
  {
    "artist": "Stephen Bishop",
    "song": "On and On",
    "year": "1977",
    "seconds": "60",
    "lyric": "And my toes in the sand",
    "number": "3015",
  },
  {
    "artist": "Charlie Rich and Janie Fricke",
    "song": "On My Knees",
    "year": "1978",
    "seconds": "50",
    "lyric": "We meet on the street",
    "number": "3016",
  },
  {
    "artist": "Randy Travis",
    "song": "On the Other Hand",
    "year": "1986",
    "seconds": "60",
    "lyric": "When I first kissed your lips",
    "number": "3017",
  },
  {
    "artist": "Donna Summer",
    "song": "On the Radio",
    "year": "1979",
    "seconds": "50",
    "lyric": "A letter you wrote me",
    "number": "3018",
  },
  {
    "artist": "Willie Nelson",
    "song": "On the Road Again",
    "year": "1980",
    "seconds": "50",
    "lyric": "We go down the highway",
    "number": "3019",
  },
  {
    "artist": "Vic Damone",
    "song": "On the Street Where You Live",
    "year": "1956",
    "seconds": "50",
    "lyric": "Can you hear a lark?",
    "number": "3020",
  },
  {
    "artist": "Pink Floyd",
    "song": "On the Turning Away",
    "year": "1987",
    "seconds": "50",
    "lyric": "Light is changing to shadow",
    "number": "3021",
  },
  {
    "artist": "The Weavers",
    "song": "On Top of Old Smokey",
    "year": "1951",
    "seconds": "50",
    "lyric": "I lost my true lover",
    "number": "3022",
  },
  {
    "artist": "Great White",
    "song": "Once Bitten, Twice Shy",
    "year": "1987",
    "seconds": "50",
    "lyric": "Knocks you off your feet",
    "number": "3023",
  },
  {
    "artist": "Talking Heads",
    "song": "Once in a Lifetime",
    "year": "1981",
    "seconds": "60",
    "lyric": "Let the water hold me down",
    "number": "3024",
  },
  {
    "artist": "George Jones",
    "song": "Once You’ve Had the Best",
    "year": "1973",
    "seconds": "60",
    "lyric": "More love in your little finger",
    "number": "3025",
  },
  {
    "artist": "Three Dog Night",
    "song": "One",
    "year": "1969",
    "seconds": "70",
    "lyric": "Two can be as bad as one",
    "number": "3026",
  },
  {
    "artist": "U2",
    "song": "One",
    "year": "1992",
    "seconds": "60",
    "lyric": "A bad taste in your mouth",
    "number": "3027",
  },
  {
    "artist": "The Osmonds",
    "song": "One Bad Apple",
    "year": "1971",
    "seconds": "70",
    "lyric": "By that look on your face, girl",
    "number": "3028",
  },
  {
    "artist": "George Thorogood and the Destroyers",
    "song": "One Bourbon, One Scotch, One Beer",
    "year": "1978",
    "seconds": "50",
    "lyric": "Leaning up against a post",
    "number": "3029",
  },
  {
    "artist": "Collin Raye",
    "song": "One Boy, One Girl",
    "year": "1995",
    "seconds": "40",
    "lyric": "Two hearts beating wildly",
    "number": "3030",
  },
  {
    "artist": "Kitty Wells and Red Foley",
    "song": "One By One",
    "year": "1954",
    "seconds": "40",
    "lyric": "You left me cold",
    "number": "3031",
  },
  {
    "artist": "Drake (feat. Kyla, Wizkid)",
    "song": "One Dance",
    "year": "2016",
    "seconds": "60",
    "lyric": "I pray to make it back",
    "number": "3032",
  },
  {
    "artist": "Dinah Shore",
    "song": "One Dozen Roses",
    "year": "1942",
    "seconds": "50",
    "lyric": "I’m busy as a bee",
    "number": "3033",
  },
  {
    "artist": "Clint Black",
    "song": "One Emotion",
    "year": "1995",
    "seconds": "40",
    "lyric": "Shivers down my spine",
    "number": "3034",
  },
  {
    "artist": "The Chiffons",
    "song": "One Fine Day",
    "year": "1963",
    "seconds": "50",
    "lyric": "The arms I long for",
    "number": "3035",
  },
  {
    "artist": "Dan Seals",
    "song": "One Friend",
    "year": "1987",
    "seconds": "50",
    "lyric": "I could move a mountain",
    "number": "3036",
  },
  {
    "artist": "Peter Cetera",
    "song": "One Good Woman",
    "year": "1988",
    "seconds": "50",
    "lyric": "We’re heading for a fall",
    "number": "3037",
  },
  {
    "artist": "Alanis Morissette",
    "song": "One Hand in my Pocket",
    "year": "1995",
    "seconds": "60",
    "lyric": "I feel drunk but I’m sober",
    "number": "3038",
  },
  {
    "artist": "Mac Davis",
    "song": "One Hell of a Woman",
    "year": "1974",
    "seconds": "60",
    "lyric": "A gentle kitten in my hand",
    "number": "3039",
  },
  {
    "artist": "Dua Lipa and Calvin Harris",
    "song": "One Kiss",
    "year": "2018",
    "seconds": "50",
    "lyric": "Lit up heaven in me",
    "number": "3040",
  },
  {
    "artist": "The 5th Dimension",
    "song": "One Less Bell to Answer",
    "year": "1970",
    "seconds": "60",
    "lyric": "No more laughter, no more love",
    "number": "3041",
  },
  {
    "artist": "Jim Croce",
    "song": "One Less Set of Footsteps",
    "year": "1973",
    "seconds": "60",
    "lyric": "Pair of jeans upon your door",
    "number": "3042",
  },
  {
    "artist": "Tanya Tucker",
    "song": "One Love at a Time",
    "year": "1986",
    "seconds": "60",
    "lyric": "Burning both ends of the candle",
    "number": "3043",
  },
  {
    "artist": "Old Dominion",
    "song": "One Man Band",
    "year": "2019",
    "seconds": "60",
    "lyric": "No bass, no guitar, no tambourine",
    "number": "3044",
  },
  {
    "artist": "Three Dog Night",
    "song": "One Man Band",
    "year": "1970",
    "seconds": "60",
    "lyric": "Take my hand, I’m your man",
    "number": "3045",
  },
  {
    "artist": "Luke Bryan",
    "song": "One Margarita",
    "year": "2020",
    "seconds": "50",
    "lyric": "Sippin’ on a frozen drink",
    "number": "3046",
  },
  {
    "artist": "Ray Charles",
    "song": "One Mint Julep",
    "year": "1961",
    "seconds": "50",
    "lyric": "The lights were burning low",
    "number": "3047",
  },
  {
    "artist": "Diamond Rio",
    "song": "One More Day",
    "year": "2001",
    "seconds": "50",
    "lyric": "Pray for time to crawl",
    "number": "3048",
  },
  {
    "artist": "Maroon 5",
    "song": "One More Night",
    "year": "2012",
    "seconds": "50",
    "lyric": "So I cross my heart",
    "number": "3049",
  },
  {
    "artist": "Phil Collins",
    "song": "One More Night",
    "year": "1985",
    "seconds": "60",
    "lyric": "I’ve been sitting here so long",
    "number": "3050",
  },
  {
    "artist": "Elvis Presley",
    "song": "One Night",
    "year": "1958",
    "seconds": "60",
    "lyric": "I’ll be right by your side",
    "number": "3051",
  },
  {
    "artist": "George Strait",
    "song": "One Night At a Time",
    "year": "1997",
    "seconds": "50",
    "lyric": "I got my fingers crossed",
    "number": "3052",
  },
  {
    "artist": "Bryan Adams",
    "song": "One Night Love Affair",
    "year": "1985",
    "seconds": "50",
    "lyric": "And you caught me eye",
    "number": "3053",
  },
  {
    "artist": "Luke Combs",
    "song": "One Number Away",
    "year": "2018",
    "seconds": "50",
    "lyric": "Stuck at a red light",
    "number": "3054",
  },
  {
    "artist": "The Spinners",
    "song": "One of a Kind (Love Affair)",
    "year": "1973",
    "seconds": "70",
    "lyric": "You read about in a fairy tale",
    "number": "3055",
  },
  {
    "artist": "Lee Brice",
    "song": "One of Them Girls",
    "year": "2020",
    "seconds": "60",
    "lyric": "Peels off the Bud Light label",
    "number": "3056",
  },
  {
    "artist": "Emmylou Harris",
    "song": "One of These Days",
    "year": "1976",
    "seconds": "40",
    "lyric": "All bottled up inside",
    "number": "3057",
  },
  {
    "artist": "Tim McGraw",
    "song": "One of These Days",
    "year": "1998",
    "seconds": "60",
    "lyric": "Innocent tears in the pourin’ rain",
    "number": "3058",
  },
  {
    "artist": "Eagles",
    "song": "One of These Nights",
    "year": "1975",
    "seconds": "60",
    "lyric": "Searching for an angel in white",
    "number": "3059",
  },
  {
    "artist": "Chris Brown",
    "song": "One of Those Nights",
    "year": "2012",
    "seconds": "50",
    "lyric": "I brought my swagger too",
    "number": "3060",
  },
  {
    "artist": "Tim McGraw",
    "song": "One of Those Nights",
    "year": "2012",
    "seconds": "60",
    "lyric": "You turn it up to eleven",
    "number": "3061",
  },
  {
    "artist": "Joan Osborne",
    "song": "One of Us",
    "year": "1995",
    "seconds": "50",
    "lyric": "A stranger on the bus",
    "number": "3062",
  },
  {
    "artist": "Johnny Cash",
    "song": "One Piece at a Time",
    "year": "1976",
    "seconds": "60",
    "lyric": "Honey, take me for a spin",
    "number": "3063",
  },
  {
    "artist": "Bruce Springsteen",
    "song": "One Step Up",
    "year": "1988",
    "seconds": "40",
    "lyric": "And two steps back",
    "number": "3064",
  },
  {
    "artist": "Boyz II Men (ft. Mariah Carey)",
    "song": "One Sweet Day",
    "year": "1995",
    "seconds": "60",
    "lyric": "Shining down on me from heaven",
    "number": "3065",
  },
  {
    "artist": "The Original Caste",
    "song": "One Tin Soldier",
    "year": "1969",
    "seconds": "60",
    "lyric": "Mount your horses, draw your sword!",
    "number": "3066",
  },
  {
    "artist": "Brewer & Shipley",
    "song": "One Toke Over the Line",
    "year": "1971",
    "seconds": "50",
    "lyric": "The train is on time",
    "number": "3067",
  },
  {
    "artist": "Blondie",
    "song": "One Way or Another",
    "year": "1979",
    "seconds": "50",
    "lyric": "I’ll walk down the mall",
    "number": "3068",
  },
  {
    "artist": "Barenaked Ladies",
    "song": "One Week",
    "year": "1998",
    "seconds": "70",
    "lyric": "The rug burns on both my knees",
    "number": "3069",
  },
  {
    "artist": "Johnny Cash",
    "song": "Oney",
    "year": "1972",
    "seconds": "50",
    "lyric": "His folded hands behind him",
    "number": "3070",
  },
  {
    "artist": "Brad Paisley",
    "song": "Online",
    "year": "2007",
    "seconds": "60",
    "lyric": "I’m a black belt in Karate",
    "number": "3071",
  },
  {
    "artist": "Waylon Jennings",
    "song": "Only Daddy That’ll Walk the Line",
    "year": "1968",
    "seconds": "50",
    "lyric": "A little bitty teeny girl",
    "number": "3072",
  },
  {
    "artist": "Brooks & Dunn",
    "song": "Only in America",
    "year": "2001",
    "seconds": "50",
    "lyric": "Faces in her rearview mirror",
    "number": "3073",
  },
  {
    "artist": "Neil Young",
    "song": "Only Love Can Break Your Heart",
    "year": "1970",
    "seconds": "60",
    "lyric": "Be sure right from the start",
    "number": "3074",
  },
  {
    "artist": "Ronnie Milsap",
    "song": "Only One Love in My Life",
    "year": "1978",
    "seconds": "60",
    "lyric": "A mountain too high to climb",
    "number": "3075",
  },
  {
    "artist": "Sam Cooke",
    "song": "Only Sixteen",
    "year": "1959",
    "seconds": "60",
    "lyric": "Too young to fall in love",
    "number": "3076",
  },
  {
    "artist": "Billy Joel",
    "song": "Only the Good Die Young",
    "year": "1978",
    "seconds": "60",
    "lyric": "The stained-glass curtain you’re hiding behind",
    "number": "3077",
  },
  {
    "artist": "Roy Orbison",
    "song": "Only the Lonely",
    "year": "1960",
    "seconds": "40",
    "lyric": "Know why I cry",
    "number": "3078",
  },
  {
    "artist": "The Platters",
    "song": "Only You (And You Alone)",
    "year": "1955",
    "seconds": "60",
    "lyric": "Can thrill me like you do",
    "number": "3079",
  },
  {
    "artist": "Buck Owens",
    "song": "Only You (Can Break My Heart)",
    "year": "1965",
    "seconds": "60",
    "lyric": "Can turn my skies to blue",
    "number": "3080",
  },
  {
    "artist": "Keith Urban",
    "song": "Only You Can Love Me This Way",
    "year": "2009",
    "seconds": "60",
    "lyric": "We can roll with the punches",
    "number": "3081",
  },
  {
    "artist": "Five Stairsteps",
    "song": "Ooh Child",
    "year": "1970",
    "seconds": "50",
    "lyric": "We’ll walk in the rays",
    "number": "3082",
  },
  {
    "artist": "Britney Spears",
    "song": "Oops!... I Did It Again",
    "year": "2000",
    "seconds": "40",
    "lyric": "I’m not that innocent",
    "number": "3083",
  },
  {
    "artist": "Bruce Springsteen",
    "song": "Open All Night",
    "year": "1982",
    "seconds": "60",
    "lyric": "Fried chicken on the front seat",
    "number": "3084",
  },
  {
    "artist": "Journey",
    "song": "Open Arms",
    "year": "1982",
    "seconds": "60",
    "lyric": "Feeling your heart beat with mine",
    "number": "3085",
  },
  {
    "artist": "Buck Owens",
    "song": "Open Up Your Heart",
    "year": "1966",
    "seconds": "60",
    "lyric": "Hurt by an old love affair",
    "number": "3086",
  },
  {
    "artist": "Jim Croce",
    "song": "Operator (That’s Not the Way It Feels)",
    "year": "1972",
    "seconds": "60",
    "lyric": "The matchbook is old and faded",
    "number": "3087",
  },
  {
    "artist": "Johnny Cash",
    "song": "Orange Blossom Special",
    "year": "1965",
    "seconds": "60",
    "lyric": "Get some sand in my shoes",
    "number": "3088",
  },
  {
    "artist": "Red Hot Chili Peppers",
    "song": "Otherside",
    "year": "2000",
    "seconds": "40",
    "lyric": "And pull the thread",
    "number": "3089",
  },
  {
    "artist": "Ruby & the Romantics",
    "song": "Our Day Will Come",
    "year": "1963",
    "seconds": "60",
    "lyric": "Think love and wear a smile",
    "number": "3090",
  },
  {
    "artist": "Crosby, Stills, Nash & Young",
    "song": "Our House",
    "year": "1970",
    "seconds": "60",
    "lyric": "With two cats in the yard",
    "number": "3091",
  },
  {
    "artist": "Natalie Cole",
    "song": "Our Love",
    "year": "1977",
    "seconds": "50",
    "lyric": "Spread wide as the seas",
    "number": "3092",
  },
  {
    "artist": "Frank Sinatra",
    "song": "Our Love is Here to Stay",
    "year": "1955",
    "seconds": "50",
    "lyric": "The radio and the telephone",
    "number": "3093",
  },
  {
    "artist": "Taylor Swift",
    "song": "Our Song",
    "year": "2007",
    "seconds": "40",
    "lyric": "Tapping on your window",
    "number": "3094",
  },
  {
    "artist": "Taylor Swift",
    "song": "Ours",
    "year": "2011",
    "seconds": "50",
    "lyric": "Elevator buttons and morning air",
    "number": "3095",
  },
  {
    "artist": "Three Dog Night",
    "song": "Out in the Country",
    "year": "1970",
    "seconds": "60",
    "lyric": "Before the breathin’ air is gone",
    "number": "3096",
  },
  {
    "artist": "Taylor Swift",
    "song": "Out of the Woods",
    "year": "2016",
    "seconds": "60",
    "lyric": "Your necklace hanging from my neck",
    "number": "3097",
  },
  {
    "artist": "Kenny Chesney",
    "song": "Out Last Night",
    "year": "2009",
    "seconds": "40",
    "lyric": "Drank too much beer",
    "number": "3098",
  },
  {
    "artist": "Blake Shelton",
    "song": "Over",
    "year": "2012",
    "seconds": "70",
    "lyric": "Feed your dreams and starve your fears",
    "number": "3099",
  },
  {
    "artist": "Nelly (ft. Tim McGraw)",
    "song": "Over and Over",
    "year": "2004",
    "seconds": "50",
    "lyric": "Right in front of me",
    "number": "3100",
  },
  {
    "artist": "The Dave Clark Five",
    "song": "Over and Over",
    "year": "1965",
    "seconds": "40",
    "lyric": "And be my girl",
    "number": "3101",
  },
  {
    "artist": "Led Zeppelin",
    "song": "Over the Hills and Far Away",
    "year": "1973",
    "seconds": "50",
    "lyric": "And a pocketful of gold",
    "number": "3102",
  },
  {
    "artist": "Israel Kamakawiwo’ole “Iz”",
    "song": "Over the Rainbow",
    "year": "2003",
    "seconds": "50",
    "lyric": "High above the chimney tops",
    "number": "3103",
  },
  {
    "artist": "Britney Spears",
    "song": "Overprotected",
    "year": "2002",
    "seconds": "50",
    "lyric": "Say hello to the girl",
    "number": "3104",
  },
  {
    "artist": "Yes",
    "song": "Owner of a Lonely Heart",
    "year": "1983",
    "seconds": "40",
    "lyric": "Look before you leap",
    "number": "3105",
  },
  {
    "artist": "The Beatles",
    "song": "P.S. I Love You",
    "year": "1963",
    "seconds": "50",
    "lyric": "Send my love to you",
    "number": "3106",
  },
  {
    "artist": "Michael Jackson",
    "song": "P.Y.T. (Pretty Young Thing)",
    "year": "1983",
    "seconds": "40",
    "lyric": "Sugar, fly with me",
    "number": "3107",
  },
  {
    "artist": "Rolling Stones",
    "song": "Paint It Black",
    "year": "1966",
    "seconds": "60",
    "lyric": "I see the girls walk by",
    "number": "3108",
  },
  {
    "artist": "Van Halen",
    "song": "Panama",
    "year": "1984",
    "seconds": "60",
    "lyric": "Hot shoe, burnin’ down the avenue",
    "number": "3109",
  },
  {
    "artist": "Merle Haggard & Willie Nelson",
    "song": "Pancho and Lefty",
    "year": "1983",
    "seconds": "50",
    "lyric": "And sank into your dreams",
    "number": "3110",
  },
  {
    "artist": "Mitchell Ayres’ Orchestra (ft. Perry Como)",
    "song": "Papa Loves Mambo",
    "year": "1954",
    "seconds": "60",
    "lyric": "He goes fast, she goes slow",
    "number": "3111",
  },
  {
    "artist": "The Temptations",
    "song": "Papa Was a Rollin’ Stone",
    "year": "1972",
    "seconds": "50",
    "lyric": "Mama just hung her head",
    "number": "3112",
  },
  {
    "artist": "Lady Gaga",
    "song": "Paparazzi",
    "year": "2009",
    "seconds": "50",
    "lyric": "Loving you is cherry pie",
    "number": "3113",
  },
  {
    "artist": "James Brown",
    "song": "Papa’s Got a Brand New Bag",
    "year": "1965",
    "seconds": "40",
    "lyric": "See you later alligator",
    "number": "3114",
  },
  {
    "artist": "John Mellencamp",
    "song": "Paper in Fire",
    "year": "1987",
    "seconds": "40",
    "lyric": "Stinking up the ashtrays",
    "number": "3115",
  },
  {
    "artist": "M.I.A.",
    "song": "Paper Planes",
    "year": "2008",
    "seconds": "70",
    "lyric": "Sticks and stones and weed and bombs",
    "number": "3116",
  },
  {
    "artist": "Marie Osmond",
    "song": "Paper Roses",
    "year": "1973",
    "seconds": "50",
    "lyric": "Your imitation love for me",
    "number": "3117",
  },
  {
    "artist": "The Beatles",
    "song": "Paperback Writer",
    "year": "1966",
    "seconds": "60",
    "lyric": "More in a week or two",
    "number": "3118",
  },
  {
    "artist": "Coldplay",
    "song": "Paradise",
    "year": "2011",
    "seconds": "50",
    "lyric": "The wheel breaks the butterfly",
    "number": "3119",
  },
  {
    "artist": "John Prine",
    "song": "Paradise",
    "year": "1971",
    "seconds": "50",
    "lyric": "The air smelled like snakes",
    "number": "3120",
  },
  {
    "artist": "Meat Loaf",
    "song": "Paradise By the Dashboard Light",
    "year": "1978",
    "seconds": "80",
    "lyric": "Cold and lonely in the deep dark night",
    "number": "3121",
  },
  {
    "artist": "Guns N’ Roses",
    "song": "Paradise City",
    "year": "1989",
    "seconds": "60",
    "lyric": "So buy me something to eat",
    "number": "3122",
  },
  {
    "artist": "Finger Eleven",
    "song": "Paralyzer",
    "year": "2007",
    "seconds": "60",
    "lyric": "Hold out for one more drink",
    "number": "3123",
  },
  {
    "artist": "Black Sabbath",
    "song": "Paranoid",
    "year": "1970",
    "seconds": "80",
    "lyric": "And you will laugh and I will cry",
    "number": "3124",
  },
  {
    "artist": "The Chainsmokers",
    "song": "Paris",
    "year": "2017",
    "seconds": "50",
    "lyric": "A frown and a cigarette",
    "number": "3125",
  },
  {
    "artist": "Beyoncé",
    "song": "Partition",
    "year": "2013",
    "seconds": "50",
    "lyric": "Diamond fangs in my grill",
    "number": "3126",
  },
  {
    "artist": "Buddy Knox",
    "song": "Party Doll",
    "year": "1957",
    "seconds": "60",
    "lyric": "Blonde hair and eyes of blue",
    "number": "3127",
  },
  {
    "artist": "Shania Twain & Mark McGrath",
    "song": "Party For Two",
    "year": "2004",
    "seconds": "40",
    "lyric": "Lock all the doors",
    "number": "3128",
  },
  {
    "artist": "Miley Cyrus",
    "song": "Party in the USA",
    "year": "2009",
    "seconds": "50",
    "lyric": "I see the Hollywood sign",
    "number": "3129",
  },
  {
    "artist": "Clarence Carter",
    "song": "Patches",
    "year": "1970",
    "seconds": "50",
    "lyric": "A shovel in his hand",
    "number": "3130",
  },
  {
    "artist": "Perry Como",
    "song": "Patricia",
    "year": "1950",
    "seconds": "50",
    "lyric": "My heart is just droolin’",
    "number": "3131",
  },
  {
    "artist": "Boston",
    "song": "Peace of Mind",
    "year": "1976",
    "seconds": "60",
    "lyric": "The top of the company ladder",
    "number": "3132",
  },
  {
    "artist": "Cat Stevens",
    "song": "Peace Train",
    "year": "1971",
    "seconds": "60",
    "lyric": "Dreaming about the world as one",
    "number": "3133",
  },
  {
    "artist": "Eagles",
    "song": "Peaceful Easy Feeling",
    "year": "1972",
    "seconds": "50",
    "lyric": "Whispering in my other ear",
    "number": "3134",
  },
  {
    "artist": "The Presidents of the US of America",
    "song": "Peaches",
    "year": "1995",
    "seconds": "60",
    "lyric": "Room for an ant to hide",
    "number": "3135",
  },
  {
    "artist": "Siouxsie & the Banshees",
    "song": "Peek-a-Boo",
    "year": "1988",
    "seconds": "50",
    "lyric": "Sneaking out the back door",
    "number": "3136",
  },
  {
    "artist": "Steely Dan",
    "song": "Peg",
    "year": "1977",
    "seconds": "70",
    "lyric": "So won’t you smile for the camera?",
    "number": "3137",
  },
  {
    "artist": "Buddy Holly",
    "song": "Peggy Sue",
    "year": "1957",
    "seconds": "60",
    "lyric": "A love so rare and true",
    "number": "3138",
  },
  {
    "artist": "Jim Reeves",
    "song": "Penny Candy",
    "year": "1955",
    "seconds": "60",
    "lyric": "She’s got freckles on her nose",
    "number": "3139",
  },
  {
    "artist": "The Beatles",
    "song": "Penny Lane",
    "year": "1967",
    "seconds": "60",
    "lyric": "A banker with a motor car",
    "number": "3140",
  },
  {
    "artist": "Lionel Richie",
    "song": "Penny Lover",
    "year": "1984",
    "seconds": "50",
    "lyric": "Don’t you walk on by",
    "number": "3141",
  },
  {
    "artist": "Barbra Streisand",
    "song": "People",
    "year": "1964",
    "seconds": "60",
    "lyric": "A feeling deep in your soul",
    "number": "3142",
  },
  {
    "artist": "The Doors",
    "song": "People are Strange",
    "year": "1967",
    "seconds": "60",
    "lyric": "Faces come out of the rain",
    "number": "3143",
  },
  {
    "artist": "The Rascals",
    "song": "People Got to be Free",
    "year": "1968",
    "seconds": "60",
    "lyric": "And needs a helpin’ hand",
    "number": "3144",
  },
  {
    "artist": "The Dixie Cups",
    "song": "People Say",
    "year": "1964",
    "seconds": "70",
    "lyric": "But I know deep in my heart",
    "number": "3145",
  },
  {
    "artist": "Joey Dee and the Starliters",
    "song": "Peppermint Twist",
    "year": "1962",
    "seconds": "60",
    "lyric": "Round and round, up and down",
    "number": "3146",
  },
  {
    "artist": "Ed Sheeran",
    "song": "Perfect",
    "year": "2017",
    "seconds": "60",
    "lyric": "I found a love for me",
    "number": "3147",
  },
  {
    "artist": "Smashing Pumpkins",
    "song": "Perfect",
    "year": "1998",
    "seconds": "40",
    "lyric": "Strangers down the line",
    "number": "3148",
  },
  {
    "artist": "Brad Paisley",
    "song": "Perfect Storm",
    "year": "2014",
    "seconds": "60",
    "lyric": "Sunshine mixed with a little hurricane",
    "number": "3149",
  },
  {
    "artist": "Dinah Shore",
    "song": "Personality",
    "year": "1946",
    "seconds": "50",
    "lyric": "Sable coats and wedding rings",
    "number": "3150",
  },
  {
    "artist": "Lloyd Price",
    "song": "Personality",
    "year": "1959",
    "seconds": "60",
    "lyric": "You’ve got a great big heart",
    "number": "3151",
  },
  {
    "artist": "Valdy",
    "song": "Peter and Lou",
    "year": "1976",
    "seconds": "60",
    "lyric": "Hanging their skates on the wall",
    "number": "3152",
  },
  {
    "artist": "Gene Autry",
    "song": "Peter Cottontail",
    "year": "1950",
    "seconds": "50",
    "lyric": "Hoppin’ down the bunny trail",
    "number": "3153",
  },
  {
    "artist": "Elton John",
    "song": "Philadelphia Freedom",
    "year": "1975",
    "seconds": "40",
    "lyric": "Right between the eyes",
    "number": "3154",
  },
  {
    "artist": "Def Leppard",
    "song": "Photograph",
    "year": "1983",
    "seconds": "40",
    "lyric": "Wrapped up in mystery",
    "number": "3155",
  },
  {
    "artist": "Ed Sheeran",
    "song": "Photograph",
    "year": "2015",
    "seconds": "70",
    "lyric": "Inside the pocket of your ripped jeans",
    "number": "3156",
  },
  {
    "artist": "Olivia Newton-John",
    "song": "Physical",
    "year": "1981",
    "seconds": "60",
    "lyric": "Let me hear your body talk",
    "number": "3157",
  },
  {
    "artist": "Billy Joel",
    "song": "Piano Man",
    "year": "1973",
    "seconds": "50",
    "lyric": "To light up your smoke",
    "number": "3158",
  },
  {
    "artist": "Kid Rock (ft. Sheryl Crow)",
    "song": "Picture",
    "year": "2002",
    "seconds": "50",
    "lyric": "Sat down and cried today",
    "number": "3159",
  },
  {
    "artist": "Taylor Swift",
    "song": "Picture to Burn",
    "year": "2008",
    "seconds": "40",
    "lyric": "Stupid old pickup truck",
    "number": "3160",
  },
  {
    "artist": "The Cure",
    "song": "Pictures of You",
    "year": "1989",
    "seconds": "50",
    "lyric": "Standing quiet in the rain",
    "number": "3161",
  },
  {
    "artist": "Kelly Clarkson",
    "song": "Piece By Piece",
    "year": "2015",
    "seconds": "40",
    "lyric": "Your words fall flat",
    "number": "3162",
  },
  {
    "artist": "Britney Spears",
    "song": "Piece of Me",
    "year": "2007",
    "seconds": "40",
    "lyric": "She’s too big now",
    "number": "3163",
  },
  {
    "artist": "Janis Joplin",
    "song": "Piece of My Heart",
    "year": "1969",
    "seconds": "70",
    "lyric": "And baby, deep down in your heart",
    "number": "3164",
  },
  {
    "artist": "The Who",
    "song": "Pinball Wizard",
    "year": "1969",
    "seconds": "50",
    "lyric": "Deaf, dumb and blind kid",
    "number": "3165",
  },
  {
    "artist": "ZZ Top",
    "song": "Pincushion",
    "year": "1994",
    "seconds": "40",
    "lyric": "She gave me acupuncture",
    "number": "3166",
  },
  {
    "artist": "Aerosmith",
    "song": "Pink",
    "year": "1997",
    "seconds": "50",
    "lyric": "You could be my flamingo",
    "number": "3167",
  },
  {
    "artist": "John Mellencamp",
    "song": "Pink Houses",
    "year": "1983",
    "seconds": "50",
    "lyric": "Runnin’ through his front yard",
    "number": "3168",
  },
  {
    "artist": "Conway Twitty",
    "song": "Play Guitar Play",
    "year": "1977",
    "seconds": "60",
    "lyric": "Can they read between the lines",
    "number": "3169",
  },
  {
    "artist": "Luke Bryan",
    "song": "Play It Again",
    "year": "2014",
    "seconds": "50",
    "lyric": "Sittin’ out under the stars",
    "number": "3170",
  },
  {
    "artist": "Neil Diamond",
    "song": "Play Me",
    "year": "1972",
    "seconds": "80",
    "lyric": "You are the sun, I am the moon",
    "number": "3171",
  },
  {
    "artist": "Brooks & Dunn",
    "song": "Play Something Country",
    "year": "2005",
    "seconds": "60",
    "lyric": "Put her hand on her hip",
    "number": "3172",
  },
  {
    "artist": "Wild Cherry",
    "song": "Play That Funky Music",
    "year": "1976",
    "seconds": "50",
    "lyric": "Burnin’ down the one-night stands",
    "number": "3173",
  },
  {
    "artist": "Clint Holmes",
    "song": "Playground in My Mind",
    "year": "1972",
    "seconds": "60",
    "lyric": "Close your eyes and follow me",
    "number": "3174",
  },
  {
    "artist": "The Monkees",
    "song": "Pleasant Valley Sunday",
    "year": "1967",
    "seconds": "60",
    "lyric": "Came out to mow his lawn",
    "number": "3175",
  },
  {
    "artist": "Charles Brown",
    "song": "Please Come Home For Christmas",
    "year": "1960",
    "seconds": "40",
    "lyric": "Bells will be ringing",
    "number": "3176",
  },
  {
    "artist": "Dave Loggins",
    "song": "Please Come to Boston",
    "year": "1974",
    "seconds": "50",
    "lyric": "Looks out over the ocean",
    "number": "3177",
  },
  {
    "artist": "Porter Wagoner & Dolly Parton",
    "song": "Please Don’t Stop Loving Me",
    "year": "1974",
    "seconds": "40",
    "lyric": "You opened my eyes",
    "number": "3178",
  },
  {
    "artist": "Rihanna",
    "song": "Please Don’t Stop the Music",
    "year": "2007",
    "seconds": "50",
    "lyric": "Your hands around my waist",
    "number": "3179",
  },
  {
    "artist": "Cliff Richard and the Shadows",
    "song": "Please Don’t Tease",
    "year": "1960",
    "seconds": "60",
    "lyric": "You love me like a hurricane",
    "number": "3180",
  },
  {
    "artist": "Bobby Bare",
    "song": "Please Don’t Tell Me How the Story Ends",
    "year": "1971",
    "seconds": "50",
    "lyric": "Your fingers on my skin",
    "number": "3181",
  },
  {
    "artist": "Bryan Adams",
    "song": "Please Forgive Me",
    "year": "1993",
    "seconds": "50",
    "lyric": "The smell of your skin",
    "number": "3182",
  },
  {
    "artist": "Hank Locklin",
    "song": "Please Help Me, I’m Falling",
    "year": "1960",
    "seconds": "50",
    "lyric": "Turn away from me darling",
    "number": "3183",
  },
  {
    "artist": "Olivia Newton-John",
    "song": "Please Mister Please",
    "year": "1975",
    "seconds": "50",
    "lyric": "Five selections for a quarter",
    "number": "3184",
  },
  {
    "artist": "The Beatles",
    "song": "Please, Please Me",
    "year": "1963",
    "seconds": "50",
    "lyric": "These words to my girl",
    "number": "3185",
  },
  {
    "artist": "Tim McGraw",
    "song": "Please Remember Me",
    "year": "1999",
    "seconds": "60",
    "lyric": "Snow falls high outside your door",
    "number": "3186",
  },
  {
    "artist": "Stone Temple Pilots",
    "song": "Plush",
    "year": "1992",
    "seconds": "70",
    "lyric": "So, is it raining in your bedroom?",
    "number": "3187",
  },
  {
    "artist": "Justin Moore",
    "song": "Point At You",
    "year": "2013",
    "seconds": "60",
    "lyric": "Melted down this heart of stone",
    "number": "3188",
  },
  {
    "artist": "Bruce Springsteen",
    "song": "Point Blank",
    "year": "1981",
    "seconds": "50",
    "lyric": "Right between the eyes, baby",
    "number": "3189",
  },
  {
    "artist": "The Coasters",
    "song": "Poison Ivy",
    "year": "1959",
    "seconds": "50",
    "lyric": "You start to mess around",
    "number": "3190",
  },
  {
    "artist": "Lady Gaga",
    "song": "Poker Face",
    "year": "2008",
    "seconds": "50",
    "lyric": "A chick in the casino",
    "number": "3191",
  },
  {
    "artist": "Ricky Nelson",
    "song": "Poor Little Fool",
    "year": "1958",
    "seconds": "60",
    "lyric": "Hold me close and kiss me",
    "number": "3192",
  },
  {
    "artist": "Linda Ronstadt",
    "song": "Poor Poor Pitiful Me",
    "year": "1977",
    "seconds": "50",
    "lyric": "And he threw me down",
    "number": "3193",
  },
  {
    "artist": "Johnny Rivers",
    "song": "Poor Side of Town",
    "year": "1966",
    "seconds": "50",
    "lyric": "Rich guy you’ve been seein’",
    "number": "3194",
  },
  {
    "artist": "NSYNC",
    "song": "Pop",
    "year": "2001",
    "seconds": "50",
    "lyric": "The ice around my neck",
    "number": "3195",
  },
  {
    "artist": "Prince",
    "song": "Pop Life",
    "year": "1985",
    "seconds": "40",
    "lyric": "Your million dollar check",
    "number": "3196",
  },
  {
    "artist": "Ariana Grande",
    "song": "Positions",
    "year": "2020",
    "seconds": "40",
    "lyric": "I’m jumping through hoops",
    "number": "3197",
  },
  {
    "artist": "Bob Dylan",
    "song": "Positively 4th Street",
    "year": "1965",
    "seconds": "50",
    "lyric": "You talked behind my back",
    "number": "3198",
  },
  {
    "artist": "Beirut",
    "song": "Postcards from Italy",
    "year": "2006",
    "seconds": "50",
    "lyric": "Outside with the willow trees",
    "number": "3199",
  },
  {
    "artist": "Def Leppard",
    "song": "Pour Some Sugar On Me",
    "year": "1987",
    "seconds": "70",
    "lyric": "Red light, yellow light, green light, go",
    "number": "3200",
  },
  {
    "artist": "Rascal Flatts",
    "song": "Prayin’ for Daylight",
    "year": "2000",
    "seconds": "60",
    "lyric": "Deep in my heart I know",
    "number": "3201",
  },
  {
    "artist": "Climax",
    "song": "Precious and Few",
    "year": "1972",
    "seconds": "60",
    "lyric": "Quiet and blue like the sky",
    "number": "3202",
  },
  {
    "artist": "Manfred Mann",
    "song": "Pretty Flamingo",
    "year": "1966",
    "seconds": "60",
    "lyric": "Her eyes can light the skies",
    "number": "3203",
  },
  {
    "artist": "Billy Currington",
    "song": "Pretty Good at Drinkin’ Beer",
    "year": "2010",
    "seconds": "40",
    "lyric": "For mowin’ thick grass",
    "number": "3204",
  },
  {
    "artist": "Curtis Lee",
    "song": "Pretty Little Angel Eyes",
    "year": "1961",
    "seconds": "60",
    "lyric": "You are so good to me",
    "number": "3205",
  },
  {
    "artist": "Soundgarden",
    "song": "Pretty Noose",
    "year": "1996",
    "seconds": "50",
    "lyric": "And kiss the snake goodnight",
    "number": "3206",
  },
  {
    "artist": "Marvin Gaye",
    "song": "Pride and Joy",
    "year": "1963",
    "seconds": "60",
    "lyric": "You shake up the whole town",
    "number": "3207",
  },
  {
    "artist": "Stevie Ray Vaughan",
    "song": "Pride and Joy",
    "year": "1983",
    "seconds": "50",
    "lyric": "I’m her little lover boy",
    "number": "3208",
  },
  {
    "artist": "U2",
    "song": "Pride (In the Name of Love)",
    "year": "1984",
    "seconds": "60",
    "lyric": "Caught on a barbed wire fence",
    "number": "3209",
  },
  {
    "artist": "Coldplay and Rihanna",
    "song": "Princess of China",
    "year": "2012",
    "seconds": "60",
    "lyric": "The two halves of my heart",
    "number": "3210",
  },
  {
    "artist": "Perry Como",
    "song": "Prisoner of Love",
    "year": "1946",
    "seconds": "60",
    "lyric": "Too weak to break the chains",
    "number": "3211",
  },
  {
    "artist": "Tina Turner",
    "song": "Private Dancer",
    "year": "1984",
    "seconds": "60",
    "lyric": "Keeping your eyes on the wall",
    "number": "3212",
  },
  {
    "artist": "Hall & Oates",
    "song": "Private Eyes",
    "year": "1981",
    "seconds": "60",
    "lyric": "You can twist it around, baby",
    "number": "3213",
  },
  {
    "artist": "Ariana Grande (ft. Iggy Azalea)",
    "song": "Problem",
    "year": "2014",
    "seconds": "60",
    "lyric": "Got no weight on my shoulders",
    "number": "3214",
  },
  {
    "artist": "The Everly Brothers",
    "song": "Problems",
    "year": "1958",
    "seconds": "60",
    "lyric": "Worries pile up on my head",
    "number": "3215",
  },
  {
    "artist": "Joe Diffie",
    "song": "Prop Me Up Beside the Jukebox",
    "year": "1993",
    "seconds": "60",
    "lyric": "A stiff drink in my hand",
    "number": "3216",
  },
  {
    "artist": "Creedence Clearwater Revival",
    "song": "Proud Mary",
    "year": "1969",
    "seconds": "70",
    "lyric": "A ride on a river boat queen",
    "number": "3217",
  },
  {
    "artist": "Bruce Springsteen",
    "song": "Prove It All Night",
    "year": "1978",
    "seconds": "60",
    "lyric": "To buy you a gold ring",
    "number": "3218",
  },
  {
    "artist": "The Temptations",
    "song": "Psychedelic Shack",
    "year": "1970",
    "seconds": "40",
    "lyric": "Right around the corner",
    "number": "3219",
  },
  {
    "artist": "Peter, Paul & Mary",
    "song": "Puff the Magic Dragon",
    "year": "1963",
    "seconds": "60",
    "lyric": "Pirate ships would lower their flag",
    "number": "3220",
  },
  {
    "artist": "Elvis Costello",
    "song": "Pump It Up",
    "year": "1978",
    "seconds": "50",
    "lyric": "Out in the fashion show",
    "number": "3221",
  },
  {
    "artist": "Technotronic",
    "song": "Pump Up the Jam",
    "year": "1989",
    "seconds": "60",
    "lyric": "Get your booty on the floor",
    "number": "3222",
  },
  {
    "artist": "Paul Anka",
    "song": "Puppy Love",
    "year": "1960",
    "seconds": "60",
    "lyric": "Someone help me, help me please",
    "number": "3223",
  },
  {
    "artist": "Ronnie Milsap",
    "song": "Pure Love",
    "year": "1974",
    "seconds": "60",
    "lyric": "Milk and honey and Captain Krunch",
    "number": "3224",
  },
  {
    "artist": "Jimi Hendrix",
    "song": "Purple Haze",
    "year": "1967",
    "seconds": "60",
    "lyric": "Girl put a spell on me",
    "number": "3225",
  },
  {
    "artist": "Prince",
    "song": "Purple Rain",
    "year": "1984",
    "seconds": "60",
    "lyric": "One time to see you laughing",
    "number": "3226",
  },
  {
    "artist": "Salt-N-Pepa",
    "song": "Push It",
    "year": "1987",
    "seconds": "60",
    "lyric": "Get on out there and dance",
    "number": "3227",
  },
  {
    "artist": "Brooks & Dunn",
    "song": "Put a Girl In It",
    "year": "2008",
    "seconds": "50",
    "lyric": "Float around in the breeze",
    "number": "3228",
  },
  {
    "artist": "Jackie DeShannon",
    "song": "Put a Little Love in Your Heart",
    "year": "1969",
    "seconds": "50",
    "lyric": "Lend him a helping hand",
    "number": "3229",
  },
  {
    "artist": "Keith Urban",
    "song": "Put You in a Song",
    "year": "2010",
    "seconds": "60",
    "lyric": "Your hair shimmers in the sunlight",
    "number": "3230",
  },
  {
    "artist": "Ocean",
    "song": "Put Your Hand in the Hand",
    "year": "1971",
    "seconds": "60",
    "lyric": "I reached the age of seven",
    "number": "3231",
  },
  {
    "artist": "The O’Jays",
    "song": "Put Your Hands Together",
    "year": "1973",
    "seconds": "50",
    "lyric": "Pray for all the sisters",
    "number": "3232",
  },
  {
    "artist": "Kylie Minogue",
    "song": "Put Your Hands Up (If You Feel Love)",
    "year": "2011",
    "seconds": "70",
    "lyric": "But no one’s lookin’ in my eyes",
    "number": "3233",
  },
  {
    "artist": "Paul Anka",
    "song": "Put Your Head on My Shoulder",
    "year": "1959",
    "seconds": "60",
    "lyric": "Hold me in your arms, baby",
    "number": "3234",
  },
  {
    "artist": "Corinne Bailey Rae",
    "song": "Put Your Records On",
    "year": "2006",
    "seconds": "70",
    "lyric": "Three little birds sat on my window",
    "number": "3235",
  },
  {
    "artist": "Engelbert Humperdinck",
    "song": "Quando Quando Quando",
    "year": "1968",
    "seconds": "40",
    "lyric": "Say yes to me",
    "number": "3236",
  },
  {
    "artist": "Doris Day",
    "song": "Que Sera, Sera",
    "year": "1956",
    "seconds": "60",
    "lyric": "I tell them “Wait and see”",
    "number": "3237",
  },
  {
    "artist": "Juice Newton",
    "song": "Queen of Hearts",
    "year": "1981",
    "seconds": "60",
    "lyric": "You know it makes you mad",
    "number": "3238",
  },
  {
    "artist": "Bobby Darin",
    "song": "Queen of the Hop",
    "year": "1958",
    "seconds": "50",
    "lyric": "My baby drives me crazy",
    "number": "3239",
  },
  {
    "artist": "Jody Miller",
    "song": "Queen of the House",
    "year": "1965",
    "seconds": "50",
    "lyric": "Floors to wax and scrub",
    "number": "3240",
  },
  {
    "artist": "The Moody Blues",
    "song": "Question",
    "year": "1970",
    "seconds": "50",
    "lyric": "We’re knocking at the door",
    "number": "3241",
  },
  {
    "artist": "Chicago",
    "song": "Questions 67 and 68",
    "year": "1969",
    "seconds": "60",
    "lyric": "The thought of us so happy",
    "number": "3242",
  },
  {
    "artist": "Backstreet Boys",
    "song": "Quit Playing Games (With My Heart)",
    "year": "1997",
    "seconds": "60",
    "lyric": "Even in my heart I see",
    "number": "3243",
  },
  {
    "artist": "Golden Earring",
    "song": "Radar Love",
    "year": "1973",
    "seconds": "50",
    "lyric": "And it’s half past four",
    "number": "3244",
  },
  {
    "artist": "Queen",
    "song": "Radio Ga Ga",
    "year": "1984",
    "seconds": "40",
    "lyric": "We watch the stars",
    "number": "3245",
  },
  {
    "artist": "Charly McClain",
    "song": "Radio Heart",
    "year": "1985",
    "seconds": "70",
    "lyric": "Married at 16, two kids by 21",
    "number": "3246",
  },
  {
    "artist": "Imagine Dragons",
    "song": "Radioactive",
    "year": "2012",
    "seconds": "60",
    "lyric": "I feel it in my bones",
    "number": "3247",
  },
  {
    "artist": "Aerosmith",
    "song": "Rag Doll",
    "year": "1987",
    "seconds": "60",
    "lyric": "Come on up and see me",
    "number": "3248",
  },
  {
    "artist": "The 4 Seasons",
    "song": "Rag Doll",
    "year": "1964",
    "seconds": "50",
    "lyric": "Should be dressed in lace",
    "number": "3249",
  },
  {
    "artist": "Tony Bennett",
    "song": "Rags to Riches",
    "year": "1953",
    "seconds": "50",
    "lyric": "Hold me and kiss me",
    "number": "3250",
  },
  {
    "artist": "The Guess Who",
    "song": "Rain Dance",
    "year": "1971",
    "seconds": "50",
    "lyric": "Can your telescope tell me",
    "number": "3251",
  },
  {
    "artist": "Luke Bryan",
    "song": "Rain is a Good Thing",
    "year": "2010",
    "seconds": "40",
    "lyric": "Roll up your pants",
    "number": "3252",
  },
  {
    "artist": "Lady Gaga and Ariana Grande",
    "song": "Rain On Me",
    "year": "2020",
    "seconds": "70",
    "lyric": "I can feel it on my skin",
    "number": "3253",
  },
  {
    "artist": "John Mellencamp",
    "song": "Rain on the Scarecrow",
    "year": "1986",
    "seconds": "40",
    "lyric": "Blood on the plow",
    "number": "3254",
  },
  {
    "artist": "Dee Clark",
    "song": "Raindrops",
    "year": "1961",
    "seconds": "50",
    "lyric": "A cloud in my head",
    "number": "3255",
  },
  {
    "artist": "B. J. Thomas",
    "song": "Raindrops Keep Falling on my Head",
    "year": "1969",
    "seconds": "50",
    "lyric": "Too big for his bed",
    "number": "3256",
  },
  {
    "artist": "Keith Urban",
    "song": "Raining on Sunday",
    "year": "2003",
    "seconds": "60",
    "lyric": "Hide under the covers all afternoon",
    "number": "3257",
  },
  {
    "artist": "Waylon Jennings",
    "song": "Rainy Day Woman",
    "year": "1975",
    "seconds": "40",
    "lyric": "Woke up this mornin’",
    "number": "3258",
  },
  {
    "artist": "Bob Dylan",
    "song": "Rainy Day Women #12 & 35",
    "year": "1966",
    "seconds": "40",
    "lyric": "Everybody must get stoned",
    "number": "3259",
  },
  {
    "artist": "The Carpenters",
    "song": "Rainy Days and Mondays",
    "year": "1971",
    "seconds": "50",
    "lyric": "Nothing to do but frown",
    "number": "3260",
  },
  {
    "artist": "Brook Benton",
    "song": "Rainy Night in Georgia",
    "year": "1970",
    "seconds": "70",
    "lyric": "I hold your picture to my chest",
    "number": "3261",
  },
  {
    "artist": "Keith Urban (ft. Eric Church)",
    "song": "Raise ’Em Up",
    "year": "2015",
    "seconds": "60",
    "lyric": "Black umbrellas in the pourin’ rain",
    "number": "3262",
  },
  {
    "artist": "Led Zeppelin",
    "song": "Ramble On",
    "year": "1969",
    "seconds": "50",
    "lyric": "I’m goin’ ’round the world",
    "number": "3263",
  },
  {
    "artist": "The Allman Brothers Band",
    "song": "Ramblin’ Man",
    "year": "1973",
    "seconds": "50",
    "lyric": "When it’s time for leaving",
    "number": "3264",
  },
  {
    "artist": "Nat King Cole",
    "song": "Ramblin’ Rose",
    "year": "1962",
    "seconds": "60",
    "lyric": "Why I want you, heaven knows",
    "number": "3265",
  },
  {
    "artist": "Blondie",
    "song": "Rapture",
    "year": "1981",
    "seconds": "60",
    "lyric": "Toe to toe, dancing very close",
    "number": "3266",
  },
  {
    "artist": "Prince",
    "song": "Raspberry Beret",
    "year": "1985",
    "seconds": "60",
    "lyric": "On the back of my bike",
    "number": "3267",
  },
  {
    "artist": "Four Tops",
    "song": "Reach Out I’ll Be There",
    "year": "1966",
    "seconds": "70",
    "lyric": "A love that will see you through",
    "number": "3268",
  },
  {
    "artist": "The Killers",
    "song": "Read My Mind",
    "year": "2007",
    "seconds": "70",
    "lyric": "A broken wrist and a big trapeze",
    "number": "3269",
  },
  {
    "artist": "Taylor Swift",
    "song": "…Ready For It?",
    "year": "2017",
    "seconds": "60",
    "lyric": "Island breeze and lights down low",
    "number": "3270",
  },
  {
    "artist": "Billy Ray & Miley Cyrus",
    "song": "Ready, Set, Don’t Go",
    "year": "2007",
    "seconds": "60",
    "lyric": "Before she hits that open road",
    "number": "3271",
  },
  {
    "artist": "Chase Rice",
    "song": "Ready Set Roll",
    "year": "2014",
    "seconds": "50",
    "lyric": "I can rock your world",
    "number": "3272",
  },
  {
    "artist": "Tim McGraw",
    "song": "Real Good Man",
    "year": "2003",
    "seconds": "50",
    "lyric": "I may drink too much",
    "number": "3273",
  },
  {
    "artist": "Doobie Brothers",
    "song": "Real Love",
    "year": "1980",
    "seconds": "40",
    "lyric": "Head on your pillow",
    "number": "3274",
  },
  {
    "artist": "Van Morrison",
    "song": "Real Real Gone",
    "year": "1990",
    "seconds": "60",
    "lyric": "Hit by a bow and arrow",
    "number": "3275",
  },
  {
    "artist": "Kenny Chesney",
    "song": "Reality",
    "year": "2011",
    "seconds": "60",
    "lyric": "On a boat underneath the stars",
    "number": "3276",
  },
  {
    "artist": "Rod Stewart",
    "song": "Reason to Believe",
    "year": "1971",
    "seconds": "50",
    "lyric": "Time to change my mind",
    "number": "3277",
  },
  {
    "artist": "David Bowie",
    "song": "Rebel Rebel",
    "year": "1974",
    "seconds": "60",
    "lyric": "Hot tramp I love you so",
    "number": "3278",
  },
  {
    "artist": "Billy Idol",
    "song": "Rebel Yell",
    "year": "1983",
    "seconds": "60",
    "lyric": "It don’t mess up his hair",
    "number": "3279",
  },
  {
    "artist": "Arcade Fire",
    "song": "Rebellion (Lies)",
    "year": "2004",
    "seconds": "50",
    "lyric": "So lift those heavy eyelids",
    "number": "3280",
  },
  {
    "artist": "Taylor Swift",
    "song": "Red",
    "year": "2013",
    "seconds": "50",
    "lyric": "Down a dead end street",
    "number": "3281",
  },
  {
    "artist": "Brooks & Dunn",
    "song": "Red Dirt Road",
    "year": "2003",
    "seconds": "50",
    "lyric": "I wrecked my first car",
    "number": "3282",
  },
  {
    "artist": "Hey Rosetta!",
    "song": "Red Heart",
    "year": "2008",
    "seconds": "50",
    "lyric": "But you look so good",
    "number": "3283",
  },
  {
    "artist": "Conway Twitty",
    "song": "Red Neckin’ Love Makin’ Night",
    "year": "1981",
    "seconds": "50",
    "lyric": "In the trunk, on ice",
    "number": "3284",
  },
  {
    "artist": "Tim McGraw",
    "song": "Red Rag Top",
    "year": "2002",
    "seconds": "40",
    "lyric": "Hiding from the cops",
    "number": "3285",
  },
  {
    "artist": "UB40",
    "song": "Red Red Wine",
    "year": "1988",
    "seconds": "60",
    "lyric": "It’s tearing apart my blue heart",
    "number": "3286",
  },
  {
    "artist": "Woody Guthrie",
    "song": "Red River Valley",
    "year": "1944",
    "seconds": "80",
    "lyric": "Sit by my side if you love me",
    "number": "3287",
  },
  {
    "artist": "The Cyrkle",
    "song": "Red Rubber Ball",
    "year": "1966",
    "seconds": "50",
    "lyric": "The morning sun is shining",
    "number": "3288",
  },
  {
    "artist": "Nat King Cole",
    "song": "Red Sails in the Sunset",
    "year": "1951",
    "seconds": "50",
    "lyric": "Make straight for the shore",
    "number": "3289",
  },
  {
    "artist": "Toby Keith",
    "song": "Red Solo Cup",
    "year": "2011",
    "seconds": "40",
    "lyric": "You’re easy to crack",
    "number": "3290",
  },
  {
    "artist": "Childish Gambino",
    "song": "Redbone",
    "year": "2016",
    "seconds": "50",
    "lyric": "My peanut butter chocolate cake",
    "number": "3291",
  },
  {
    "artist": "Gretchen Wilson",
    "song": "Redneck Woman",
    "year": "2004",
    "seconds": "60",
    "lyric": "With a baby on my hip",
    "number": "3292",
  },
  {
    "artist": "Chuck Berry",
    "song": "Reelin’ and Rockin’",
    "year": "1957",
    "seconds": "60",
    "lyric": "Well I looked at my watch",
    "number": "3293",
  },
  {
    "artist": "Steely Dan",
    "song": "Reelin’ in the Years",
    "year": "1972",
    "seconds": "60",
    "lyric": "You held it in your hand",
    "number": "3294",
  },
  {
    "artist": "Diana Ross & the Supremes",
    "song": "Reflections",
    "year": "1967",
    "seconds": "60",
    "lyric": "Through the mirror of my mind",
    "number": "3295",
  },
  {
    "artist": "Tim McGraw",
    "song": "Refried Dreams",
    "year": "1995",
    "seconds": "50",
    "lyric": "Comin’ apart at the seams",
    "number": "3296",
  },
  {
    "artist": "Tom Petty & the Heartbreakers",
    "song": "Refugee",
    "year": "1980",
    "seconds": "50",
    "lyric": "You were kidnapped, tied up",
    "number": "3297",
  },
  {
    "artist": "Engelbert Humperdinck",
    "song": "Release Me",
    "year": "1967",
    "seconds": "80",
    "lyric": "Her lips are warm while yours are cold",
    "number": "3298",
  },
  {
    "artist": "Michael Jackson",
    "song": "Remember the Time",
    "year": "1992",
    "seconds": "60",
    "lyric": "In the park, on the beach",
    "number": "3299",
  },
  {
    "artist": "Alan Jackson",
    "song": "Remember When",
    "year": "2003",
    "seconds": "50",
    "lyric": "The sound of little feet",
    "number": "3300",
  },
  {
    "artist": "Brad Paisley (ft. Carrie Underwood)",
    "song": "Remind Me",
    "year": "2011",
    "seconds": "50",
    "lyric": "I was late for work",
    "number": "3301",
  },
  {
    "artist": "Styx",
    "song": "Renegade",
    "year": "1979",
    "seconds": "80",
    "lyric": "The jig is up, the news is out",
    "number": "3302",
  },
  {
    "artist": "X Ambassadors",
    "song": "Renegades",
    "year": "2015",
    "seconds": "50",
    "lyric": "Two kids, you and me",
    "number": "3303",
  },
  {
    "artist": "OneRepublic",
    "song": "Rescue Me",
    "year": "2019",
    "seconds": "50",
    "lyric": "Broken bottles and slammin’ doors",
    "number": "3304",
  },
  {
    "artist": "Aretha Franklin",
    "song": "Respect",
    "year": "1967",
    "seconds": "50",
    "lyric": "Give you all my money",
    "number": "3305",
  },
  {
    "artist": "Conway Twitty",
    "song": "Rest Your Love On Me",
    "year": "1981",
    "seconds": "60",
    "lyric": "Put your worries in my pocket",
    "number": "3306",
  },
  {
    "artist": "Elvis Presley",
    "song": "Return to Sender",
    "year": "1962",
    "seconds": "60",
    "lyric": "But my letter keeps coming back",
    "number": "3307",
  },
  {
    "artist": "Kenny Rogers",
    "song": "Reuben James",
    "year": "1969",
    "seconds": "50",
    "lyric": "Calloused hands upon the plough",
    "number": "3308",
  },
  {
    "artist": "The Kingston Trio",
    "song": "Reverend Mr. Black",
    "year": "1963",
    "seconds": "60",
    "lyric": "He rode easy in the saddle",
    "number": "3309",
  },
  {
    "artist": "The Beatles",
    "song": "Revolution",
    "year": "1968",
    "seconds": "50",
    "lyric": "You can count me out",
    "number": "3310",
  },
  {
    "artist": "Fleetwood Mac",
    "song": "Rhiannon",
    "year": "1975",
    "seconds": "50",
    "lyric": "If she promised you heaven",
    "number": "3311",
  },
  {
    "artist": "Glen Campbell",
    "song": "Rhinestone Cowboy",
    "year": "1975",
    "seconds": "50",
    "lyric": "Riding out on a horse",
    "number": "3312",
  },
  {
    "artist": "Dallas Smith",
    "song": "Rhinestone World",
    "year": "2018",
    "seconds": "60",
    "lyric": "Felt the rain on the faces",
    "number": "3313",
  },
  {
    "artist": "The Cascades",
    "song": "Rhythm of the Rain",
    "year": "1963",
    "seconds": "70",
    "lyric": "Along with her, she took my heart",
    "number": "3314",
  },
  {
    "artist": "Marty Robbins",
    "song": "Ribbon of Darkness",
    "year": "1965",
    "seconds": "60",
    "lyric": "Rain is falling on the meadow",
    "number": "3315",
  },
  {
    "artist": "Hall & Oates",
    "song": "Rich Girl",
    "year": "1977",
    "seconds": "70",
    "lyric": "High and dry, out of the rain",
    "number": "3316",
  },
  {
    "artist": "Blues Image",
    "song": "Ride Captain Ride",
    "year": "1970",
    "seconds": "50",
    "lyric": "Out on the peaceful sea",
    "number": "3317",
  },
  {
    "artist": "Christopher Cross",
    "song": "Ride Like the Wind",
    "year": "1980",
    "seconds": "50",
    "lyric": "To the border of Mexico",
    "number": "3318",
  },
  {
    "artist": "The Doors",
    "song": "Riders on the Storm",
    "year": "1971",
    "seconds": "60",
    "lyric": "Like a dog without a bone",
    "number": "3319",
  },
  {
    "artist": "REO Speedwagon",
    "song": "Ridin’ the Storm Out",
    "year": "1973",
    "seconds": "40",
    "lyric": "My wine bottle’s low",
    "number": "3320",
  },
  {
    "artist": "Maxine Nightingale",
    "song": "Right Back Where We Started From",
    "year": "1976",
    "seconds": "60",
    "lyric": "That smile back on your face",
    "number": "3321",
  },
  {
    "artist": "Gerry Rafferty",
    "song": "Right Down the Lne",
    "year": "1978",
    "seconds": "50",
    "lyric": "And it’s been you, woman",
    "number": "3322",
  },
  {
    "artist": "George Strait",
    "song": "Right or Wrong",
    "year": "1984",
    "seconds": "60",
    "lyric": "I’m still in love with you",
    "number": "3323",
  },
  {
    "artist": "Dr. John",
    "song": "Right Place, Wrong Time",
    "year": "1973",
    "seconds": "50",
    "lyric": "A little brain salad surgery",
    "number": "3324",
  },
  {
    "artist": "Flo Rida",
    "song": "Right Round",
    "year": "2009",
    "seconds": "60",
    "lyric": "I know the storm is coming",
    "number": "3325",
  },
  {
    "artist": "Jennifer Warnes",
    "song": "Right Time of the Night",
    "year": "1977",
    "seconds": "50",
    "lyric": "The stars are winking above",
    "number": "3326",
  },
  {
    "artist": "Steely Dan",
    "song": "Rikki Don’t Lose That Number",
    "year": "1974",
    "seconds": "60",
    "lyric": "Send it off in a letter",
    "number": "3327",
  },
  {
    "artist": "Johnny Cash",
    "song": "Ring of Fire",
    "year": "1963",
    "seconds": "50",
    "lyric": "And the flames went higher",
    "number": "3328",
  },
  {
    "artist": "Lorne Green",
    "song": "Ringo",
    "year": "1964",
    "seconds": "60",
    "lyric": "Face down in the desert sand",
    "number": "3329",
  },
  {
    "artist": "Dottie West & Don Gibson",
    "song": "Rings of Gold",
    "year": "1969",
    "seconds": "40",
    "lyric": "They’ve crumbled into dust",
    "number": "3330",
  },
  {
    "artist": "The Black Eyed Peas, J Balvin",
    "song": "Ritmo",
    "year": "2019",
    "seconds": "50",
    "lyric": "The rhythm of the night",
    "number": "3331",
  },
  {
    "artist": "Bishop Briggs",
    "song": "River",
    "year": "2016",
    "seconds": "50",
    "lyric": "The veins start to shiver",
    "number": "3332",
  },
  {
    "artist": "Eminem (ft. Ed Sheeran)",
    "song": "River",
    "year": "2017",
    "seconds": "50",
    "lyric": "Feel it washing over me",
    "number": "3333",
  },
  {
    "artist": "Joni Mitchell",
    "song": "River",
    "year": "1971",
    "seconds": "40",
    "lyric": "They’re cutting down trees",
    "number": "3334",
  },
  {
    "artist": "George Strait",
    "song": "River of Love",
    "year": "2008",
    "seconds": "40",
    "lyric": "A stream of kisses",
    "number": "3335",
  },
  {
    "artist": "The Doors",
    "song": "Roadhouse Blues",
    "year": "1972",
    "seconds": "50",
    "lyric": "Your hand upon the wheel",
    "number": "3336",
  },
  {
    "artist": "Led Zeppelin",
    "song": "Rock and Roll",
    "year": "1972",
    "seconds": "60",
    "lyric": "Let my love come running in",
    "number": "3337",
  },
  {
    "artist": "KISS",
    "song": "Rock & Roll All Night",
    "year": "1975",
    "seconds": "50",
    "lyric": "And the room gets hot",
    "number": "3338",
  },
  {
    "artist": "Boston",
    "song": "Rock & Roll Band",
    "year": "1976",
    "seconds": "50",
    "lyric": "He smoked a big cigar",
    "number": "3339",
  },
  {
    "artist": "Bad Company",
    "song": "Rock & Roll Fantasy",
    "year": "1979",
    "seconds": "60",
    "lyric": "Dancing on a number nine cloud",
    "number": "3340",
  },
  {
    "artist": "The Righteous Brothers",
    "song": "Rock & Roll Heaven",
    "year": "1974",
    "seconds": "50",
    "lyric": "A piece of our hearts",
    "number": "3341",
  },
  {
    "artist": "The Ramones",
    "song": "Rock N Roll High School",
    "year": "1979",
    "seconds": "70",
    "lyric": "The girls out there knock me out",
    "number": "3342",
  },
  {
    "artist": "Danny & the Juniors",
    "song": "Rock and Roll Is Here to Stay",
    "year": "1958",
    "seconds": "40",
    "lyric": "You watch, my friend",
    "number": "3343",
  },
  {
    "artist": "Chuck Berry",
    "song": "Rock & Roll Music",
    "year": "1958",
    "seconds": "70",
    "lyric": "A back beat, you can’t lose it",
    "number": "3344",
  },
  {
    "artist": "Bob Seger",
    "song": "Rock & Roll Never Forgets",
    "year": "1976",
    "seconds": "60",
    "lyric": "So you’re a little bit older",
    "number": "3345",
  },
  {
    "artist": "Valdy",
    "song": "Rock and Roll Song",
    "year": "1972",
    "seconds": "50",
    "lyric": "A big case of beer",
    "number": "3346",
  },
  {
    "artist": "Bill Haley & His Comets",
    "song": "Rock Around the Clock",
    "year": "1956",
    "seconds": "70",
    "lyric": "The chimes ring five, six and seven",
    "number": "3347",
  },
  {
    "artist": "John Mellencamp",
    "song": "R.O.C.K. in the U.S.A.",
    "year": "1986",
    "seconds": "50",
    "lyric": "Pipe dreams in their heads",
    "number": "3348",
  },
  {
    "artist": "Steppenwolf",
    "song": "Rock Me",
    "year": "1969",
    "seconds": "50",
    "lyric": "An answer to her confusion",
    "number": "3349",
  },
  {
    "artist": "Andy Kim",
    "song": "Rock Me Gently",
    "year": "1974",
    "seconds": "50",
    "lyric": "Our bodies swaying in time",
    "number": "3350",
  },
  {
    "artist": "Shenandoah",
    "song": "Rock My Baby",
    "year": "1992",
    "seconds": "50",
    "lyric": "And play some air guitar",
    "number": "3351",
  },
  {
    "artist": "Brooks & Dunn",
    "song": "Rock My World (Little Country Girl)",
    "year": "1993",
    "seconds": "40",
    "lyric": "She wears snakeskin boots",
    "number": "3352",
  },
  {
    "artist": "David Essex",
    "song": "Rock On",
    "year": "1974",
    "seconds": "40",
    "lyric": "Blue jean baby queen",
    "number": "3353",
  },
  {
    "artist": "The Black Eyed Peas",
    "song": "Rock That Body",
    "year": "2010",
    "seconds": "40",
    "lyric": "Running around the clock",
    "number": "3354",
  },
  {
    "artist": "The Clash",
    "song": "Rock the Casbah",
    "year": "1982",
    "seconds": "60",
    "lyric": "Brought out the electric camel drum",
    "number": "3355",
  },
  {
    "artist": "Michael Jackson",
    "song": "Rock With You",
    "year": "1979",
    "seconds": "60",
    "lyric": "And we can ride the boogie",
    "number": "3356",
  },
  {
    "artist": "Scorpions",
    "song": "Rock You Like a Hurricane",
    "year": "1984",
    "seconds": "50",
    "lyric": "For love at first sting",
    "number": "3357",
  },
  {
    "artist": "George McCrae",
    "song": "Rock Your Baby",
    "year": "1974",
    "seconds": "60",
    "lyric": "Woman, take me in your arms",
    "number": "3358",
  },
  {
    "artist": "Justin Timberlake",
    "song": "Rock Your Body",
    "year": "2003",
    "seconds": "50",
    "lyric": "So quick to walk away",
    "number": "3359",
  },
  {
    "artist": "Elton John",
    "song": "Rocket Man",
    "year": "1972",
    "seconds": "60",
    "lyric": "My job five days a week",
    "number": "3360",
  },
  {
    "artist": "Brenda Lee",
    "song": "Rocking Around the Christmas Tree",
    "year": "1958",
    "seconds": "50",
    "lyric": "We’ll have some pumpkin pie",
    "number": "3361",
  },
  {
    "artist": "Doobie Brothers",
    "song": "Rockin’ Down the Highway",
    "year": "1972",
    "seconds": "50",
    "lyric": "I smell my motor burnin’",
    "number": "3362",
  },
  {
    "artist": "Neil Young",
    "song": "Rockin’ in the Free World",
    "year": "1989",
    "seconds": "50",
    "lyric": "A baby in her hand",
    "number": "3363",
  },
  {
    "artist": "The Steve Miller Band",
    "song": "Rockin’ Me",
    "year": "1976",
    "seconds": "60",
    "lyric": "Woman is a friend of mine",
    "number": "3364",
  },
  {
    "artist": "Johnny Rivers",
    "song": "Rockin’ Pneumonia and the Boogie Woogie Flu",
    "year": "1973",
    "seconds": "50",
    "lyric": "But she’s way too tall",
    "number": "3365",
  },
  {
    "artist": "Bobby Day",
    "song": "Rockin’ Robin",
    "year": "1957",
    "seconds": "80",
    "lyric": "The wise old owl, the big black crow",
    "number": "3366",
  },
  {
    "artist": "Dolly Parton",
    "song": "Rockin’ Years",
    "year": "1991",
    "seconds": "60",
    "lyric": "Side by side, we’ll be together",
    "number": "3367",
  },
  {
    "artist": "John Denver",
    "song": "Rocky Mountain High",
    "year": "1972",
    "seconds": "50",
    "lyric": "Raining fire in the sky",
    "number": "3368",
  },
  {
    "artist": "Joe Walsh",
    "song": "Rocky Mountain Way",
    "year": "1973",
    "seconds": "50",
    "lyric": "Time to change the batter",
    "number": "3369",
  },
  {
    "artist": "The Beatles",
    "song": "Rocky Raccoon",
    "year": "1968",
    "seconds": "70",
    "lyric": "By stealing the girl of his fancy",
    "number": "3370",
  },
  {
    "artist": "Montgomery Gentry",
    "song": "Roll With Me",
    "year": "2008",
    "seconds": "50",
    "lyric": "Tears roll down her face",
    "number": "3371",
  },
  {
    "artist": "REO Speedwagon",
    "song": "Roll with the Changes",
    "year": "1978",
    "seconds": "60",
    "lyric": "My cup is on the table",
    "number": "3372",
  },
  {
    "artist": "Buck Owens",
    "song": "Rollin’ in My Sweet Baby’s Arms",
    "year": "1971",
    "seconds": "50",
    "lyric": "The mail train comes back",
    "number": "3373",
  },
  {
    "artist": "Adele",
    "song": "Rolling in the Deep",
    "year": "2010",
    "seconds": "60",
    "lyric": "I can see you crystal clear",
    "number": "3374",
  },
  {
    "artist": "Charlie Rich",
    "song": "Rollin’ With the Flow",
    "year": "1977",
    "seconds": "60",
    "lyric": "Guys my age are raising kids",
    "number": "3375",
  },
  {
    "artist": "Foo Fighters",
    "song": "Rope",
    "year": "2011",
    "seconds": "40",
    "lyric": "I’m counting on you",
    "number": "3376",
  },
  {
    "artist": "Bruce Springsteen",
    "song": "RosaIita (Come Out Tonight)",
    "year": "1979",
    "seconds": "60",
    "lyric": "Senorita, come sit by my fire",
    "number": "3377",
  },
  {
    "artist": "Toto",
    "song": "Rosanna",
    "year": "1982",
    "seconds": "50",
    "lyric": "Meet you all the way",
    "number": "3378",
  },
  {
    "artist": "Johnny Cash",
    "song": "Rosanna’s Going Wild",
    "year": "1967",
    "seconds": "40",
    "lyric": "Her hair is long",
    "number": "3379",
  },
  {
    "artist": "Lynn Anderson",
    "song": "Rose Garden",
    "year": "1970",
    "seconds": "40",
    "lyric": "Like big diamond rings",
    "number": "3380",
  },
  {
    "artist": "Waylon Jennings",
    "song": "Rose in Paradise",
    "year": "1987",
    "seconds": "50",
    "lyric": "A mansion on the mountain",
    "number": "3381",
  },
  {
    "artist": "The Chainsmokers (ft. Rozes)",
    "song": "Roses",
    "year": "2015",
    "seconds": "40",
    "lyric": "Deep in my bones",
    "number": "3382",
  },
  {
    "artist": "Bobby Vinton",
    "song": "Roses are Red (My Love)",
    "year": "1962",
    "seconds": "50",
    "lyric": "Write in her book too",
    "number": "3383",
  },
  {
    "artist": "George Strait",
    "song": "Round About Way",
    "year": "1998",
    "seconds": "50",
    "lyric": "My tears start falling down",
    "number": "3384",
  },
  {
    "artist": "Perry Como",
    "song": "Round and Round",
    "year": "1957",
    "seconds": "50",
    "lyric": "And the ball of sun",
    "number": "3385",
  },
  {
    "artist": "Yes",
    "song": "Roundabout",
    "year": "1972",
    "seconds": "50",
    "lyric": "In and around the lake",
    "number": "3386",
  },
  {
    "artist": "The Police",
    "song": "Roxanne",
    "year": "1978",
    "seconds": "50",
    "lyric": "So put away your make-up",
    "number": "3387",
  },
  {
    "artist": "Nick Gilder",
    "song": "Roxy Roller",
    "year": "1976",
    "seconds": "50",
    "lyric": "Bubble gum on her knees",
    "number": "3388",
  },
  {
    "artist": "Lorde",
    "song": "Royals",
    "year": "2013",
    "seconds": "60",
    "lyric": "You can call me Queen Bee",
    "number": "3389",
  },
  {
    "artist": "Marty Robbins",
    "song": "Ruby Ann",
    "year": "1962",
    "seconds": "60",
    "lyric": "You’re at a table for two",
    "number": "3390",
  },
  {
    "artist": "Cousin Emmy & Her Kinfolk",
    "song": "Ruby, Are You Mad?",
    "year": "1946",
    "seconds": "50",
    "lyric": "I’m sittin’ in the shade",
    "number": "3391",
  },
  {
    "artist": "Dion",
    "song": "Ruby Baby",
    "year": "1963",
    "seconds": "60",
    "lyric": "I love this girl, I said",
    "number": "3392",
  },
  {
    "artist": "Kenny Rogers and the First Edition",
    "song": "Ruby, Don’t Take Your Love to Town",
    "year": "1969",
    "seconds": "50",
    "lyric": "You’ve painted up your lips",
    "number": "3393",
  },
  {
    "artist": "Rolling Stones",
    "song": "Ruby Tuesday",
    "year": "1967",
    "seconds": "70",
    "lyric": "No one knows, she comes and goes",
    "number": "3394",
  },
  {
    "artist": "Gene Autry",
    "song": "Rudolph the Red-Nosed Reindeer",
    "year": "1949",
    "seconds": "40",
    "lyric": "One foggy Christmas Eve",
    "number": "3395",
  },
  {
    "artist": "Frankie Carle",
    "song": "Rumors Are Flying",
    "year": "1946",
    "seconds": "40",
    "lyric": "I hear people talking",
    "number": "3396",
  },
  {
    "artist": "Adele",
    "song": "Rumour Has It",
    "year": "2011",
    "seconds": "60",
    "lyric": "But you’re cold to the core",
    "number": "3397",
  },
  {
    "artist": "George Strait",
    "song": "Run",
    "year": "2001",
    "seconds": "40",
    "lyric": "Lean on the gas",
    "number": "3398",
  },
  {
    "artist": "Snow Patrol",
    "song": "Run",
    "year": "2004",
    "seconds": "60",
    "lyric": "I’ll be right beside you dear",
    "number": "3399",
  },
  {
    "artist": "Carly Rae Jepsen",
    "song": "Run Away With Me",
    "year": "2015",
    "seconds": "50",
    "lyric": "You’re stuck in my head",
    "number": "3400",
  },
  {
    "artist": "Pink Floyd",
    "song": "Run Like Hell",
    "year": "1979",
    "seconds": "50",
    "lyric": "And your roller blind eyes",
    "number": "3401",
  },
  {
    "artist": "Beyoncé",
    "song": "Run the World (Girls)",
    "year": "2011",
    "seconds": "50",
    "lyric": "I work my 9 to 5",
    "number": "3402",
  },
  {
    "artist": "Creedence Clearwater Revival",
    "song": "Run Through the Jungle",
    "year": "1970",
    "seconds": "60",
    "lyric": "Over on the mountain, thunder magic",
    "number": "3403",
  },
  {
    "artist": "Bryan Adams",
    "song": "Run to You",
    "year": "1984",
    "seconds": "60",
    "lyric": "I need to feel your touch",
    "number": "3404",
  },
  {
    "artist": "Dion",
    "song": "Runaround Sue",
    "year": "1961",
    "seconds": "80",
    "lyric": "Her lips and the smile on her face",
    "number": "3405",
  },
  {
    "artist": "Del Shannon",
    "song": "Runaway",
    "year": "1961",
    "seconds": "80",
    "lyric": "Tears are fallin’ and I feel the pain",
    "number": "3406",
  },
  {
    "artist": "Janet Jackson",
    "song": "Runaway",
    "year": "1995",
    "seconds": "50",
    "lyric": "We won’t need a compass",
    "number": "3407",
  },
  {
    "artist": "The Temptations",
    "song": "Runaway Child, Running Wild",
    "year": "1969",
    "seconds": "50",
    "lyric": "You better go back home",
    "number": "3408",
  },
  {
    "artist": "Tom Petty & the Heartbreakers",
    "song": "Runnin’ Down a Dream",
    "year": "1989",
    "seconds": "80",
    "lyric": "I had the radio on, I was drivin’",
    "number": "3409",
  },
  {
    "artist": "Jackson Browne",
    "song": "Running on Empty",
    "year": "1978",
    "seconds": "70",
    "lyric": "In sixty nine I was twenty one",
    "number": "3410",
  },
  {
    "artist": "Randy Houser",
    "song": "Runnin’ Outta Moonlight",
    "year": "2013",
    "seconds": "60",
    "lyric": "Tailgate for two underneath the stars",
    "number": "3411",
  },
  {
    "artist": "Roy Orbison",
    "song": "Running Scared",
    "year": "1961",
    "seconds": "50",
    "lyric": "And walked away with me",
    "number": "3412",
  },
  {
    "artist": "Van Halen",
    "song": "Running with the Devil",
    "year": "1978",
    "seconds": "60",
    "lyric": "I jumped out on that road",
    "number": "3413",
  },
  {
    "artist": "Stringbean",
    "song": "Rye Whiskey",
    "year": "1947",
    "seconds": "70",
    "lyric": "One gallon for a ten dollar bill",
    "number": "3414",
  },
  {
    "artist": "S Club 7",
    "song": "S Club Party",
    "year": "1999",
    "seconds": "70",
    "lyric": "Shake your body from side to side",
    "number": "3415",
  },
  {
    "artist": "Elton John",
    "song": "Sad Songs (Say So Much)",
    "year": "1984",
    "seconds": "50",
    "lyric": "They reach into your room",
    "number": "3416",
  },
  {
    "artist": "Taylor Swift (ft. The Civil Wars)",
    "song": "Safe & Sound",
    "year": "2011",
    "seconds": "50",
    "lyric": "Tears streaming down your face",
    "number": "3417",
  },
  {
    "artist": "David Gray",
    "song": "Sail Away",
    "year": "2001",
    "seconds": "50",
    "lyric": "Winter howling at my face",
    "number": "3418",
  },
  {
    "artist": "Randy Newman",
    "song": "Sail Away",
    "year": "1972",
    "seconds": "40",
    "lyric": "Run through the jungle",
    "number": "3419",
  },
  {
    "artist": "The Oak Ridge Boys",
    "song": "Sail Away",
    "year": "1979",
    "seconds": "50",
    "lyric": "I skip across the waves",
    "number": "3420",
  },
  {
    "artist": "The Rasmus",
    "song": "Sail Away",
    "year": "2005",
    "seconds": "40",
    "lyric": "Sparks in the air",
    "number": "3421",
  },
  {
    "artist": "The Commodores",
    "song": "Sail On",
    "year": "1979",
    "seconds": "40",
    "lyric": "Your cup of tea",
    "number": "3422",
  },
  {
    "artist": "Christopher Cross",
    "song": "Sailing",
    "year": "1980",
    "seconds": "60",
    "lyric": "And the wind to carry me",
    "number": "3423",
  },
  {
    "artist": "John Prine",
    "song": "Sam Stone",
    "year": "1971",
    "seconds": "50",
    "lyric": "A hole in daddy’s arm",
    "number": "3424",
  },
  {
    "artist": "Aerosmith",
    "song": "Same Old Song and Dance",
    "year": "1974",
    "seconds": "60",
    "lyric": "When you’re low down and dirty",
    "number": "3425",
  },
  {
    "artist": "Buck Owens",
    "song": "Sam’s Place",
    "year": "1967",
    "seconds": "60",
    "lyric": "A big smile on her face",
    "number": "3426",
  },
  {
    "artist": "Tanya Tucker",
    "song": "San Antonio Stroll",
    "year": "1975",
    "seconds": "50",
    "lyric": "Big square dance in town",
    "number": "3427",
  },
  {
    "artist": "Scott McKenzie",
    "song": "San Francisco (Be Sure to Wear Flowers in Your Hair)",
    "year": "1967",
    "seconds": "40",
    "lyric": "All across the nation",
    "number": "3428",
  },
  {
    "artist": "Eartha Kitt",
    "song": "Santa Baby",
    "year": "1953",
    "seconds": "50",
    "lyric": "Hurry down the chimney tonight",
    "number": "3429",
  },
  {
    "artist": "Bruce Springsteen",
    "song": "Santa Claus is Comin’ to Town",
    "year": "1981",
    "seconds": "60",
    "lyric": "He sees you when you’re sleeping",
    "number": "3430",
  },
  {
    "artist": "Hall & Oates",
    "song": "Sara Smile",
    "year": "1975",
    "seconds": "50",
    "lyric": "I’ll come and hold you",
    "number": "3431",
  },
  {
    "artist": "Rolling Stones",
    "song": "Satisfaction",
    "year": "1965",
    "seconds": "70",
    "lyric": "And a man comes on the radio",
    "number": "3432",
  },
  {
    "artist": "Chicago",
    "song": "Saturday in the Park",
    "year": "1972",
    "seconds": "50",
    "lyric": "A man selling ice cream",
    "number": "3433",
  },
  {
    "artist": "Bay City Rollers",
    "song": "Saturday Night",
    "year": "1973",
    "seconds": "60",
    "lyric": "Dancin’ to the rock and roll",
    "number": "3434",
  },
  {
    "artist": "Whigfield",
    "song": "Saturday Night",
    "year": "1994",
    "seconds": "70",
    "lyric": "I feel the air is getting hot",
    "number": "3435",
  },
  {
    "artist": "Lynyrd Skynyrd",
    "song": "Saturday Night Special",
    "year": "1975",
    "seconds": "40",
    "lyric": "Right between the eyes",
    "number": "3436",
  },
  {
    "artist": "Elton John",
    "song": "Saturday Night’s Alright for Fighting",
    "year": "1973",
    "seconds": "50",
    "lyric": "A barrel full of monkeys",
    "number": "3437",
  },
  {
    "artist": "Jason Derulo & Jawsh 685",
    "song": "Savage Love (Laxed – Siren Beat)",
    "year": "2020",
    "seconds": "60",
    "lyric": "I feel it in the air",
    "number": "3438",
  },
  {
    "artist": "Big & Rich",
    "song": "Save a Horse (Ride a Cowboy)",
    "year": "2004",
    "seconds": "60",
    "lyric": "And I ride into the city",
    "number": "3439",
  },
  {
    "artist": "The Drifters",
    "song": "Save the Last Dance For Me",
    "year": "1960",
    "seconds": "60",
    "lyric": "Don’t give your heart to anyone",
    "number": "3440",
  },
  {
    "artist": "Whitney Houston",
    "song": "Saving All My Love for You",
    "year": "1985",
    "seconds": "60",
    "lyric": "I just break down and cry",
    "number": "3441",
  },
  {
    "artist": "Porter Wagoner & Dolly Parton",
    "song": "Say Forever You’ll Be Mine",
    "year": "1975",
    "seconds": "50",
    "lyric": "Stand the test of time",
    "number": "3442",
  },
  {
    "artist": "Dawn (ft. Tony Orlando)",
    "song": "Say, Has Anybody Seen My Sweet Gypsy Rose?",
    "year": "1973",
    "seconds": "50",
    "lyric": "And bells on her toes",
    "number": "3443",
  },
  {
    "artist": "Michael Franti & Spearhead",
    "song": "Say Hey (I Love You)",
    "year": "2008",
    "seconds": "50",
    "lyric": "Rocking in the dance hall",
    "number": "3444",
  },
  {
    "artist": "Don Williams",
    "song": "Say It Again",
    "year": "1976",
    "seconds": "60",
    "lyric": "Hold me closer, make me warm",
    "number": "3445",
  },
  {
    "artist": "Nelly Furtado",
    "song": "Say It Right",
    "year": "2006",
    "seconds": "60",
    "lyric": "You either stand or you fall",
    "number": "3446",
  },
  {
    "artist": "Tove Styrke",
    "song": "Say My Name",
    "year": "2017",
    "seconds": "60",
    "lyric": "Spill it out like a drink",
    "number": "3447",
  },
  {
    "artist": "Paul McCartney (ft. Michael Jackson)",
    "song": "Say Say Say",
    "year": "1983",
    "seconds": "60",
    "lyric": "By the phone, waiting for you",
    "number": "3448",
  },
  {
    "artist": "A Great Big World & Christina Aguilera",
    "song": "Say Something",
    "year": "2013",
    "seconds": "60",
    "lyric": "And I will stumble and fall",
    "number": "3449",
  },
  {
    "artist": "Fleetwood Mac",
    "song": "Say You Love Me",
    "year": "1975",
    "seconds": "50",
    "lyric": "And the lights go down",
    "number": "3450",
  },
  {
    "artist": "Lionel Richie",
    "song": "Say You, Say Me",
    "year": "1986",
    "seconds": "60",
    "lyric": "The whole world’s got you dancing",
    "number": "3451",
  },
  {
    "artist": "Spice Girls",
    "song": "Say You’ll Be There",
    "year": "1996",
    "seconds": "50",
    "lyric": "Put two and two together",
    "number": "3452",
  },
  {
    "artist": "Perry Como (ft. the Ramblers)",
    "song": "Say You’re Mine Again",
    "year": "1953",
    "seconds": "50",
    "lyric": "I have you beside me",
    "number": "3453",
  },
  {
    "artist": "Red Hot Chili Peppers",
    "song": "Scar Tissue",
    "year": "1999",
    "seconds": "70",
    "lyric": "Close your eyes and I’ll kiss you",
    "number": "3454",
  },
  {
    "artist": "Simon & Garfunkel",
    "song": "Scarborough Fair/Canticle",
    "year": "1966",
    "seconds": "50",
    "lyric": "Parsley, sage, rosemary and thyme",
    "number": "3455",
  },
  {
    "artist": "Dua Lipa and Martin Garrix",
    "song": "Scared to Be Lonely",
    "year": "2017",
    "seconds": "50",
    "lyric": "Picking fights and slamming doors",
    "number": "3456",
  },
  {
    "artist": "Harry Belafonte",
    "song": "Scarlet Ribbons",
    "year": "1956",
    "seconds": "70",
    "lyric": "I peeked in and on her bed",
    "number": "3457",
  },
  {
    "artist": "Tool",
    "song": "Schism",
    "year": "2001",
    "seconds": "50",
    "lyric": "Watch the temple topple over",
    "number": "3458",
  },
  {
    "artist": "Alice Cooper",
    "song": "School’s Out",
    "year": "1972",
    "seconds": "50",
    "lyric": "No more teacher’s dirty looks",
    "number": "3459",
  },
  {
    "artist": "Michael Jackson, Janet Jackson",
    "song": "Scream",
    "year": "1995",
    "seconds": "50",
    "lyric": "I turned on the TV",
    "number": "3460",
  },
  {
    "artist": "Usher",
    "song": "Scream",
    "year": "2012",
    "seconds": "40",
    "lyric": "But you’re so magnetic",
    "number": "3461",
  },
  {
    "artist": "will.i.am and Britney Spears",
    "song": "Scream and Shout",
    "year": "2012",
    "seconds": "50",
    "lyric": "And burn down the house",
    "number": "3462",
  },
  {
    "artist": "Frankie Ford",
    "song": "Sea Cruise",
    "year": "1959",
    "seconds": "70",
    "lyric": "Old man rhythm is in my shoes",
    "number": "3463",
  },
  {
    "artist": "Phil Phillips",
    "song": "Sea of Love",
    "year": "1959",
    "seconds": "60",
    "lyric": "I knew you were my pet",
    "number": "3464",
  },
  {
    "artist": "Brian Hyland",
    "song": "Sealed With a Kiss",
    "year": "1962",
    "seconds": "50",
    "lyric": "Say goodbye for the summer",
    "number": "3465",
  },
  {
    "artist": "The Coasters",
    "song": "Searchin’",
    "year": "1957",
    "seconds": "60",
    "lyric": "Hiding up on a blueberry hill",
    "number": "3466",
  },
  {
    "artist": "Terry Jacks",
    "song": "Seasons in the Sun",
    "year": "1974",
    "seconds": "50",
    "lyric": "We’ve climbed hills and trees",
    "number": "3467",
  },
  {
    "artist": ".38 Special",
    "song": "Second Chance",
    "year": "1989",
    "seconds": "50",
    "lyric": "Standing in the pouring rain",
    "number": "3468",
  },
  {
    "artist": "Johnny Rivers",
    "song": "Secret Agent Man",
    "year": "1966",
    "seconds": "50",
    "lyric": "Can hide an evil mind",
    "number": "3469",
  },
  {
    "artist": "Bruce Springsteen",
    "song": "Secret Garden",
    "year": "1995",
    "seconds": "50",
    "lyric": "A hammer and a vise",
    "number": "3470",
  },
  {
    "artist": "Doris Day",
    "song": "Secret Love",
    "year": "1953",
    "seconds": "50",
    "lyric": "My heart’s an open door",
    "number": "3471",
  },
  {
    "artist": "The Who",
    "song": "See Me, Feel Me",
    "year": "1970",
    "seconds": "40",
    "lyric": "Touch me, heal me",
    "number": "3472",
  },
  {
    "artist": "Ma Rainey",
    "song": "See See Rider Blues",
    "year": "1924",
    "seconds": "70",
    "lyric": "I’m so unhappy, I feel so blue",
    "number": "3473",
  },
  {
    "artist": "Carrie Underwood",
    "song": "See You Again",
    "year": "2013",
    "seconds": "40",
    "lyric": "Said goodbye, turned around",
    "number": "3474",
  },
  {
    "artist": "Wiz Khalifa (ft. Charlie Puth)",
    "song": "See You Again",
    "year": "2015",
    "seconds": "60",
    "lyric": "We loved to hit the road",
    "number": "3475",
  },
  {
    "artist": "The Happenings",
    "song": "See You in September",
    "year": "1966",
    "seconds": "60",
    "lyric": "Lose you to a summer love",
    "number": "3476",
  },
  {
    "artist": "Bill Haley & His Comets",
    "song": "See You Later Alligator",
    "year": "1956",
    "seconds": "50",
    "lyric": "I saw my baby walkin’",
    "number": "3477",
  },
  {
    "artist": "Laura Branigan",
    "song": "Self Control",
    "year": "1984",
    "seconds": "50",
    "lyric": "The creatures of the night",
    "number": "3478",
  },
  {
    "artist": "The Offspring",
    "song": "Self Esteem",
    "year": "1994",
    "seconds": "50",
    "lyric": "She knocks on my door",
    "number": "3479",
  },
  {
    "artist": "The Chainsmokers",
    "song": "#Selfie",
    "year": "2014",
    "seconds": "60",
    "lyric": "The girl with no shoes on",
    "number": "3480",
  },
  {
    "artist": "Third Eye Blind",
    "song": "Semi-Charmed Life",
    "year": "1997",
    "seconds": "70",
    "lyric": "It won’t stop, I won’t come down",
    "number": "3481",
  },
  {
    "artist": "John Anderson",
    "song": "Seminole Wind",
    "year": "1992",
    "seconds": "70",
    "lyric": "The eagles fly and the otters play",
    "number": "3482",
  },
  {
    "artist": "Real Life",
    "song": "Send Me An Angel",
    "year": "1983",
    "seconds": "50",
    "lyric": "A kiss or a frown",
    "number": "3483",
  },
  {
    "artist": "Little Richard",
    "song": "Send Me Some Lovin’",
    "year": "1958",
    "seconds": "50",
    "lyric": "My nights are so blue",
    "number": "3484",
  },
  {
    "artist": "Adele",
    "song": "Send My Love (To Your New Lover)",
    "year": "2016",
    "seconds": "60",
    "lyric": "I was running, you were walking",
    "number": "3485",
  },
  {
    "artist": "Shawn Mendes, Camila Cabello",
    "song": "Señorita",
    "year": "2019",
    "seconds": "40",
    "lyric": "Hot from summer rain",
    "number": "3486",
  },
  {
    "artist": "Phil Collins (ft. Marilyn Martin)",
    "song": "Separate Lives",
    "year": "1985",
    "seconds": "50",
    "lyric": "So you build that wall",
    "number": "3487",
  },
  {
    "artist": "Journey",
    "song": "Separate Ways (Worlds Apart)",
    "year": "1983",
    "seconds": "50",
    "lyric": "If he ever hurts you",
    "number": "3488",
  },
  {
    "artist": "Earth, Wind and Fire",
    "song": "September",
    "year": "1978",
    "seconds": "50",
    "lyric": "We danced in the night",
    "number": "3489",
  },
  {
    "artist": "Vern Gosdin",
    "song": "Set ‘em Up Joe",
    "year": "1988",
    "seconds": "50",
    "lyric": "And play ‘Walking the Floor’",
    "number": "3490",
  },
  {
    "artist": "Adele",
    "song": "Set Fire to the Rain",
    "year": "2011",
    "seconds": "70",
    "lyric": "Kissed my lips and you saved me",
    "number": "3491",
  },
  {
    "artist": "Kenny Chesney (ft. Pink)",
    "song": "Setting the World on Fire",
    "year": "2007",
    "seconds": "50",
    "lyric": "In lipstick on the mirror",
    "number": "3492",
  },
  {
    "artist": "Sugarland",
    "song": "Settlin’",
    "year": "2007",
    "seconds": "50",
    "lyric": "So raise the bar high",
    "number": "3493",
  },
  {
    "artist": "The White Stripes",
    "song": "Seven Nation Army",
    "year": "2003",
    "seconds": "60",
    "lyric": "Back and forth through my mind",
    "number": "3494",
  },
  {
    "artist": "Ray Charles (ft. Willie Nelson)",
    "song": "Seven Spanish Angels",
    "year": "1985",
    "seconds": "60",
    "lyric": "She threw her arms around him",
    "number": "3495",
  },
  {
    "artist": "Sharon Van Etten",
    "song": "Seventeen",
    "year": "2019",
    "seconds": "60",
    "lyric": "Follow my shadow around your corner",
    "number": "3496",
  },
  {
    "artist": "Kings of Leon",
    "song": "Sex on Fire",
    "year": "2008",
    "seconds": "50",
    "lyric": "The dark of the alley",
    "number": "3497",
  },
  {
    "artist": "Marvin Gaye",
    "song": "Sexual Healing",
    "year": "1982",
    "seconds": "50",
    "lyric": "And call you up baby",
    "number": "3498",
  },
  {
    "artist": "The Beatles",
    "song": "Sgt.Pepper’s Lonely Heart Club’s Band",
    "year": "1967",
    "seconds": "50",
    "lyric": "It’s wonderful to be here",
    "number": "3499",
  },
  {
    "artist": "The Crew Cuts",
    "song": "Sh Boom",
    "year": "1954",
    "seconds": "60",
    "lyric": "Life could be a dream, sweetheart",
    "number": "3500",
  },
  {
    "artist": "Manfred Mann",
    "song": "Sha La La",
    "year": "1964",
    "seconds": "40",
    "lyric": "I felt so weak",
    "number": "3501",
  },
  {
    "artist": "Andy Gibb",
    "song": "Shadow Dancing",
    "year": "1978",
    "seconds": "50",
    "lyric": "Drag me across the floor",
    "number": "3502",
  },
  {
    "artist": "Anne Murray",
    "song": "Shadows in the Moonlight",
    "year": "1979",
    "seconds": "50",
    "lyric": "Dancin’ through the Milky Way",
    "number": "3503",
  },
  {
    "artist": "Mariah Carey",
    "song": "Shake It Off",
    "year": "2005",
    "seconds": "70",
    "lyric": "I grabbed all my diamonds and clothes",
    "number": "3504",
  },
  {
    "artist": "Taylor Swift",
    "song": "Shake It Off",
    "year": "2014",
    "seconds": "50",
    "lyric": "I’m lightning on my feet",
    "number": "3505",
  },
  {
    "artist": "Florence and the Machine",
    "song": "Shake It Out",
    "year": "2011",
    "seconds": "40",
    "lyric": "Dragging that horse around",
    "number": "3506",
  },
  {
    "artist": "The Jacksons",
    "song": "Shake Your Body (Down to the Ground)",
    "year": "1979",
    "seconds": "70",
    "lyric": "Your head all up in the sky",
    "number": "3507",
  },
  {
    "artist": "KC & the Sunshine Band",
    "song": "Shake Your Booty",
    "year": "1976",
    "seconds": "60",
    "lyric": "Aah, everybody, get on the floor",
    "number": "3508",
  },
  {
    "artist": "The Guess Who",
    "song": "Shakin’ All Over",
    "year": "1965",
    "seconds": "40",
    "lyric": "Quivers down my backbone",
    "number": "3509",
  },
  {
    "artist": "Deborah Kerr, Yul Brynner",
    "song": "Shall We Dance",
    "year": "1956",
    "seconds": "60",
    "lyric": "On a bright cloud of music",
    "number": "3510",
  },
  {
    "artist": "Lady Gaga and Bradley Cooper",
    "song": "Shallow",
    "year": "2018",
    "seconds": "50",
    "lyric": "Watch as I dive in",
    "number": "3511",
  },
  {
    "artist": "Three Dog Night",
    "song": "Shambala",
    "year": "1973",
    "seconds": "60",
    "lyric": "By the flowers in her eyes",
    "number": "3512",
  },
  {
    "artist": "Spade Cooley & His Western Band",
    "song": "Shame on You",
    "year": "1945",
    "seconds": "50",
    "lyric": "Hold your head up high",
    "number": "3513",
  },
  {
    "artist": "Ed Sheeran",
    "song": "Shape of You",
    "year": "2017",
    "seconds": "50",
    "lyric": "At the table doing shots",
    "number": "3514",
  },
  {
    "artist": "The Guess Who",
    "song": "Share the Land",
    "year": "1970",
    "seconds": "70",
    "lyric": "I’ll be there to shake your hand",
    "number": "3515",
  },
  {
    "artist": "Dr. Hook & the Medicine Show",
    "song": "Sharing the Night Together",
    "year": "1978",
    "seconds": "60",
    "lyric": "Dance with me and hold me",
    "number": "3516",
  },
  {
    "artist": "ZZ Top",
    "song": "Sharp Dressed Man",
    "year": "1983",
    "seconds": "50",
    "lyric": "And cuff links, stick pin",
    "number": "3517",
  },
  {
    "artist": "Rolling Stones",
    "song": "Shattered",
    "year": "1978",
    "seconds": "70",
    "lyric": "Love and hope and sex and dreams",
    "number": "3518",
  },
  {
    "artist": "Kenny Rogers",
    "song": "She Believes In Me",
    "year": "1979",
    "seconds": "50",
    "lyric": "I stumble to the kitchen",
    "number": "3519",
  },
  {
    "artist": "Charlie Rich",
    "song": "She Called Me Baby",
    "year": "1974",
    "seconds": "50",
    "lyric": "Held me up so high",
    "number": "3520",
  },
  {
    "artist": "Luke Combs",
    "song": "She Got the Best of Me",
    "year": "2018",
    "seconds": "50",
    "lyric": "So you jump right in",
    "number": "3521",
  },
  {
    "artist": "Jerry Reed",
    "song": "She Got the Goldmine (I Got the Shaft)",
    "year": "1982",
    "seconds": "60",
    "lyric": "Splittin’ it all down the middle",
    "number": "3522",
  },
  {
    "artist": "Wynonna Judd",
    "song": "She Is His Only Need",
    "year": "1992",
    "seconds": "60",
    "lyric": "Sitting on the front porch rocking",
    "number": "3523",
  },
  {
    "artist": "Ronnie Milsap",
    "song": "She Keeps the Home Fires Burning",
    "year": "1985",
    "seconds": "70",
    "lyric": "Crack of dawn, I hit the road",
    "number": "3524",
  },
  {
    "artist": "George Strait",
    "song": "She Let Herself Go",
    "year": "2005",
    "seconds": "50",
    "lyric": "Drove down to the beach",
    "number": "3525",
  },
  {
    "artist": "The Beatles",
    "song": "She Loves You",
    "year": "1964",
    "seconds": "60",
    "lyric": "You know you should be glad",
    "number": "3526",
  },
  {
    "artist": "Toby Keith",
    "song": "She Never Cried in Front of Me",
    "year": "2008",
    "seconds": "60",
    "lyric": "A tear rolled down her face",
    "number": "3527",
  },
  {
    "artist": "Tim McGraw",
    "song": "She Never Lets It Go to Her Heart",
    "year": "1996",
    "seconds": "50",
    "lyric": "Right back to my arms",
    "number": "3528",
  },
  {
    "artist": "John Anderson",
    "song": "She Sure Got Away With My Heart",
    "year": "1984",
    "seconds": "50",
    "lyric": "And shakin’ in my shoes",
    "number": "3529",
  },
  {
    "artist": "The Black Crowes",
    "song": "She Talks to Angels",
    "year": "1990",
    "seconds": "70",
    "lyric": "A lock of hair in her pocket",
    "number": "3530",
  },
  {
    "artist": "George Jones",
    "song": "She Thinks I Still Care",
    "year": "1962",
    "seconds": "60",
    "lyric": "I rang her number by mistake",
    "number": "3531",
  },
  {
    "artist": "Kenny Chesney",
    "song": "She Thinks My Tractor’s Sexy",
    "year": "1999",
    "seconds": "50",
    "lyric": "Crazy ‘bout my farmer’s tan",
    "number": "3532",
  },
  {
    "artist": "Brooks & Dunn",
    "song": "She Used To Be Mine",
    "year": "1993",
    "seconds": "40",
    "lyric": "Holdin’ hands and laughin’",
    "number": "3533",
  },
  {
    "artist": "Donna Summer",
    "song": "She Works Hard for the Money",
    "year": "1983",
    "seconds": "60",
    "lyric": "So you better treat her right",
    "number": "3534",
  },
  {
    "artist": "Blake Shelton",
    "song": "She Wouldn’t Be Gone",
    "year": "2008",
    "seconds": "60",
    "lyric": "Beg her for one more try",
    "number": "3535",
  },
  {
    "artist": "George Strait",
    "song": "She’ll Leave You With a Smile",
    "year": "2002",
    "seconds": "60",
    "lyric": "It’s written all over your face",
    "number": "3536",
  },
  {
    "artist": "Bob Dylan",
    "song": "Shelter From the Storm",
    "year": "1975",
    "seconds": "50",
    "lyric": "Silver bracelets on her wrists",
    "number": "3537",
  },
  {
    "artist": "The 4 Seasons",
    "song": "Sherry",
    "year": "1962",
    "seconds": "50",
    "lyric": "Can you come out tonight?",
    "number": "3538",
  },
  {
    "artist": "Bruce Springsteen",
    "song": "Sherry Darling",
    "year": "1981",
    "seconds": "50",
    "lyric": "Girls melting on the beach",
    "number": "3539",
  },
  {
    "artist": "Tom Jones",
    "song": "She’s a Lady",
    "year": "1971",
    "seconds": "50",
    "lyric": "Help me build a mountain",
    "number": "3540",
  },
  {
    "artist": "George Hamilton IV",
    "song": "She’s a Little Bit Country",
    "year": "1970",
    "seconds": "50",
    "lyric": "Apple pie and a puppy",
    "number": "3541",
  },
  {
    "artist": "Gary Stewart",
    "song": "She’s Actin’ Single (I’m Drinking Doubles)",
    "year": "1975",
    "seconds": "50",
    "lyric": "I know she’ll be looking back",
    "number": "3542",
  },
  {
    "artist": "David Houston",
    "song": "She’s All Woman",
    "year": "1973",
    "seconds": "50",
    "lyric": "Suits me to a tee",
    "number": "3543",
  },
  {
    "artist": "Jason Aldean",
    "song": "She’s Country",
    "year": "2008",
    "seconds": "40",
    "lyric": "From her cowboy boots",
    "number": "3544",
  },
  {
    "artist": "Brad Paisley",
    "song": "She’s Everything",
    "year": "2006",
    "seconds": "50",
    "lyric": "A bubble bath and candles",
    "number": "3545",
  },
  {
    "artist": "Hall & Oates",
    "song": "She’s Gone",
    "year": "1973",
    "seconds": "50",
    "lyric": "I’m worn as a toothbrush",
    "number": "3546",
  },
  {
    "artist": "Kenny Chesney",
    "song": "She’s Got It All",
    "year": "1997",
    "seconds": "80",
    "lyric": "All of my love, my hugs, my kisses",
    "number": "3547",
  },
  {
    "artist": "Patsy Cline",
    "song": "She’s Got You",
    "year": "1962",
    "seconds": "50",
    "lyric": "I’ve got your class ring",
    "number": "3548",
  },
  {
    "artist": "Trisha Yearwood",
    "song": "She’s in Love With the Boy",
    "year": "1991",
    "seconds": "60",
    "lyric": "The short end of the stick",
    "number": "3549",
  },
  {
    "artist": "The Beatles",
    "song": "She’s Leaving Home",
    "year": "1967",
    "seconds": "50",
    "lyric": "Friday morning at nine o’clock",
    "number": "3550",
  },
  {
    "artist": "Wilson Pickett",
    "song": "She’s Lookin’ Good",
    "year": "1968",
    "seconds": "50",
    "lyric": "You wear your dresses tight",
    "number": "3551",
  },
  {
    "artist": "Tim McGraw",
    "song": "She’s My Kind of Rain",
    "year": "2003",
    "seconds": "50",
    "lyric": "Confetti falling down all night",
    "number": "3552",
  },
  {
    "artist": "George Jones",
    "song": "She’s My Rock",
    "year": "1984",
    "seconds": "50",
    "lyric": "Pack up and leave her",
    "number": "3553",
  },
  {
    "artist": "Billy Ray Cyrus",
    "song": "She’s Not Cryin’ Anymore",
    "year": "1993",
    "seconds": "40",
    "lyric": "Too little too late",
    "number": "3554",
  },
  {
    "artist": "Brooks & Dunn",
    "song": "She’s Not the Cheatin’ Kind",
    "year": "1994",
    "seconds": "40",
    "lyric": "She’s dressed to kill",
    "number": "3555",
  },
  {
    "artist": "The Zombies",
    "song": "She’s Not There",
    "year": "1964",
    "seconds": "60",
    "lyric": "Her voice was soft and cool",
    "number": "3556",
  },
  {
    "artist": "Michael Jackson",
    "song": "She’s Out of My Life",
    "year": "1980",
    "seconds": "60",
    "lyric": "And it cuts like a knife",
    "number": "3557",
  },
  {
    "artist": "Eddy Raven",
    "song": "She’s Playing Hard to Forget",
    "year": "1982",
    "seconds": "50",
    "lyric": "I’m still not over you",
    "number": "3558",
  },
  {
    "artist": "Rolling Stones",
    "song": "She’s So Cold",
    "year": "1980",
    "seconds": "40",
    "lyric": "I’m the bleeding volcano",
    "number": "3559",
  },
  {
    "artist": "Kenny Chesney (ft. George Strait)",
    "song": "Shiftwork",
    "year": "2007",
    "seconds": "70",
    "lyric": "Blue collar shirt and a baseball cap",
    "number": "3560",
  },
  {
    "artist": "George Jones",
    "song": "Shine On (Shine All Your Sweet Love On Me)",
    "year": "198",
    "seconds": "40",
    "lyric": "She’s the sexy dream",
    "number": "3561",
  },
  {
    "artist": "Earth, Wind & Fire",
    "song": "Shining Star",
    "year": "1975",
    "seconds": "60",
    "lyric": "Make your body big and strong",
    "number": "3562",
  },
  {
    "artist": "Peter Gabriel",
    "song": "Shock the Monkey",
    "year": "1982",
    "seconds": "50",
    "lyric": "Cover me through the fire",
    "number": "3563",
  },
  {
    "artist": "Dinah Shore",
    "song": "Shoo-Fly Pie and Apple Pan Dowdy",
    "year": "1946",
    "seconds": "40",
    "lyric": "Your eyes light up",
    "number": "3564",
  },
  {
    "artist": "The Miracles",
    "song": "Shop Around",
    "year": "1961",
    "seconds": "70",
    "lyric": "Pretty girls come a dime a dozen",
    "number": "3565",
  },
  {
    "artist": "Randy Newman",
    "song": "Short People",
    "year": "1977",
    "seconds": "40",
    "lyric": "And they walk around",
    "number": "3566",
  },
  {
    "artist": "The Royal Teens",
    "song": "Short Shorts",
    "year": "1958",
    "seconds": "50",
    "lyric": "Man, dig that crazy chick",
    "number": "3567",
  },
  {
    "artist": "Junior Walker & the All Stars",
    "song": "Shotgun",
    "year": "1965",
    "seconds": "40",
    "lyric": "Do the jerk baby",
    "number": "3568",
  },
  {
    "artist": "Tim McGraw",
    "song": "Shotgun Rider",
    "year": "2014",
    "seconds": "50",
    "lyric": "Girl putting’ on her makeup",
    "number": "3569",
  },
  {
    "artist": "The Clash",
    "song": "Should I Stay or Should I Go?",
    "year": "1982",
    "seconds": "60",
    "lyric": "Happy when I’m on my knees",
    "number": "3570",
  },
  {
    "artist": "Toby Keith",
    "song": "Should’ve Been a Cowboy",
    "year": "1993",
    "seconds": "70",
    "lyric": "Riding my pony on a cattle drive",
    "number": "3571",
  },
  {
    "artist": "Taylor Swift",
    "song": "Should’ve Said No",
    "year": "2008",
    "seconds": "60",
    "lyric": "Beggin’ for forgiveness at my feet",
    "number": "3572",
  },
  {
    "artist": "The Isley Brothers",
    "song": "Shout",
    "year": "1978",
    "seconds": "60",
    "lyric": "Kick my heels up and shout",
    "number": "3573",
  },
  {
    "artist": "Al Wilson",
    "song": "Show and Tell",
    "year": "1974",
    "seconds": "50",
    "lyric": "And these are the lips",
    "number": "3574",
  },
  {
    "artist": "Ronnie Milsap",
    "song": "Show Her",
    "year": "1983",
    "seconds": "50",
    "lyric": "Her laughter turns to tears",
    "number": "3575",
  },
  {
    "artist": "Peter Frampton",
    "song": "Show Me the Way",
    "year": "1975",
    "seconds": "50",
    "lyric": "I’m swimming in a circle",
    "number": "3576",
  },
  {
    "artist": "Styx",
    "song": "Show Me the Way",
    "year": "1990",
    "seconds": "50",
    "lyric": "I slowly drift to sleep",
    "number": "3577",
  },
  {
    "artist": "Mindless Self Indulgence",
    "song": "Shut Me Up",
    "year": "2006",
    "seconds": "50",
    "lyric": "I like my coffee black",
    "number": "3578",
  },
  {
    "artist": "The Black Eyed Peas",
    "song": "Shut Up",
    "year": "2003",
    "seconds": "50",
    "lyric": "The neighbors across the street",
    "number": "3579",
  },
  {
    "artist": "Dallas Smith",
    "song": "Side Effects",
    "year": "2017",
    "seconds": "60",
    "lyric": "Pulls you back in the flame",
    "number": "3580",
  },
  {
    "artist": "Ariana Grande (ft. Nicki Minaj)",
    "song": "Side to Side",
    "year": "2016",
    "seconds": "40",
    "lyric": "I got a tricycle",
    "number": "3581",
  },
  {
    "artist": "Blue Magic",
    "song": "Sideshow",
    "year": "1974",
    "seconds": "60",
    "lyric": "Hurry, hurry, step right on in",
    "number": "3582",
  },
  {
    "artist": "Prince",
    "song": "Sign O’ the Times",
    "year": "1987",
    "seconds": "60",
    "lyric": "Ripped the ceiling of a church",
    "number": "3583",
  },
  {
    "artist": "Harry Styles",
    "song": "Sign of the Times",
    "year": "2017",
    "seconds": "50",
    "lyric": "And running from the bullets",
    "number": "3584",
  },
  {
    "artist": "Stevie Wonder",
    "song": "Signed, Sealed, Delivered I’m Yours",
    "year": "1970",
    "seconds": "50",
    "lyric": "I went and said goodbye",
    "number": "3585",
  },
  {
    "artist": "Five Man Electrical Band",
    "song": "Signs",
    "year": "1971",
    "seconds": "60",
    "lyric": "My hair up under my hat",
    "number": "3586",
  },
  {
    "artist": "Delerium (ft. Sarah McLachlan)",
    "song": "Silence",
    "year": "1999",
    "seconds": "40",
    "lyric": "Hungry still for more",
    "number": "3587",
  },
  {
    "artist": "The Tremeloes",
    "song": "Silence is Golden",
    "year": "1967",
    "seconds": "50",
    "lyric": "But my eyes still see",
    "number": "3588",
  },
  {
    "artist": "Herman’s Hermits",
    "song": "Silhouettes",
    "year": "1965",
    "seconds": "60",
    "lyric": "Put his arms around your waist",
    "number": "3589",
  },
  {
    "artist": "Wings",
    "song": "Silly Love Songs",
    "year": "1976",
    "seconds": "60",
    "lyric": "Love doesn’t come in a minute",
    "number": "3590",
  },
  {
    "artist": "Bing Crosby & Carole Richards",
    "song": "Silver Bells",
    "year": "1950",
    "seconds": "40",
    "lyric": "Hear the snow crunch",
    "number": "3591",
  },
  {
    "artist": "Beach House",
    "song": "Silver Soul",
    "year": "2010",
    "seconds": "60",
    "lyric": "The bodies lying in the sand",
    "number": "3592",
  },
  {
    "artist": "Elsie Hansen",
    "song": "Silver Threads and Golden Needles",
    "year": "1956",
    "seconds": "70",
    "lyric": "You can’t buy my love with money",
    "number": "3593",
  },
  {
    "artist": "Florida Georgia Line",
    "song": "Simple",
    "year": "2018",
    "seconds": "50",
    "lyric": "Your fingers fit in mine",
    "number": "3594",
  },
  {
    "artist": "Elton John",
    "song": "Simple Life",
    "year": "1993",
    "seconds": "50",
    "lyric": "Break out of this blindfold",
    "number": "3595",
  },
  {
    "artist": "Bob Dylan",
    "song": "Simple Twist of Fate",
    "year": "1975",
    "seconds": "70",
    "lyric": "She dropped a coin into the cup",
    "number": "3596",
  },
  {
    "artist": "Robert Palmer",
    "song": "Simply Irresistible",
    "year": "1988",
    "seconds": "70",
    "lyric": "She used to look good to me",
    "number": "3597",
  },
  {
    "artist": "Tina Turner",
    "song": "Simply the Best",
    "year": "1991",
    "seconds": "50",
    "lyric": "When my heart’s on fire",
    "number": "3598",
  },
  {
    "artist": "The Skyliners",
    "song": "Since I Don’t Have You",
    "year": "1959",
    "seconds": "60",
    "lyric": "When you walked out on me",
    "number": "3599",
  },
  {
    "artist": "Kelly Clarkson",
    "song": "Since U Been Gone",
    "year": "2004",
    "seconds": "70",
    "lyric": "I can breathe for the first time",
    "number": "3600",
  },
  {
    "artist": "Ed Sheeran",
    "song": "Sing",
    "year": "2014",
    "seconds": "70",
    "lyric": "A bottle of water filled with tequila",
    "number": "3601",
  },
  {
    "artist": "My Chemical Romance",
    "song": "Sing",
    "year": "2010",
    "seconds": "50",
    "lyric": "The music drowns you out",
    "number": "3602",
  },
  {
    "artist": "The Carpenters",
    "song": "Sing",
    "year": "1973",
    "seconds": "60",
    "lyric": "To last your whole life long",
    "number": "3603",
  },
  {
    "artist": "Earth, Wind & Fire",
    "song": "Sing a Song",
    "year": "1975",
    "seconds": "60",
    "lyric": "A smile so hard to bear",
    "number": "3604",
  },
  {
    "artist": "Styx",
    "song": "Sing for the Day",
    "year": "1979",
    "seconds": "60",
    "lyric": "At my back, on my heels",
    "number": "3605",
  },
  {
    "artist": "Anne Murray",
    "song": "Sing High, Sing low",
    "year": "1971",
    "seconds": "40",
    "lyric": "Straight, clean and simple",
    "number": "3606",
  },
  {
    "artist": "Gene Kelly",
    "song": "Singin’ In the Rain",
    "year": "1952",
    "seconds": "40",
    "lyric": "I’m laughing at clouds",
    "number": "3607",
  },
  {
    "artist": "Marty Robbins",
    "song": "Singing the Blues",
    "year": "1956",
    "seconds": "70",
    "lyric": "The moon and stars no longer shine",
    "number": "3608",
  },
  {
    "artist": "Beyoncé",
    "song": "Single Ladies (Put a Ring On It)",
    "year": "2008",
    "seconds": "80",
    "lyric": "I cried my tears for three good years",
    "number": "3609",
  },
  {
    "artist": "Jordan Davis",
    "song": "Singles You Up",
    "year": "2018",
    "seconds": "60",
    "lyric": "The first one calling you baby",
    "number": "3610",
  },
  {
    "artist": "Nina Simone",
    "song": "Sinnerman",
    "year": "1962",
    "seconds": "60",
    "lyric": "So I run to the river",
    "number": "3611",
  },
  {
    "artist": "Stevie Wonder",
    "song": "Sir Duke",
    "year": "1977",
    "seconds": "60",
    "lyric": "They can feel it all over",
    "number": "3612",
  },
  {
    "artist": "Night Ranger",
    "song": "Sister Christian",
    "year": "1983",
    "seconds": "40",
    "lyric": "In finding Mister Right",
    "number": "3613",
  },
  {
    "artist": "America",
    "song": "Sister Golden Hair",
    "year": "1975",
    "seconds": "50",
    "lyric": "Too, too hard to find",
    "number": "3614",
  },
  {
    "artist": "Otis Redding",
    "song": "(Sittin’ On) The Dock of the Bay",
    "year": "1968",
    "seconds": "50",
    "lyric": "Sittin’ here resting my bones",
    "number": "3615",
  },
  {
    "artist": "Mississippi Sheiks",
    "song": "Sitting on Top of the World",
    "year": "1930",
    "seconds": "40",
    "lyric": "Holdin’ up your hand",
    "number": "3616",
  },
  {
    "artist": "Billie Eilish",
    "song": "Six Feet Under",
    "year": "2016",
    "seconds": "70",
    "lyric": "Our grave was watered by the rain",
    "number": "3617",
  },
  {
    "artist": "Dick Curless",
    "song": "Six Times a Day",
    "year": "1965",
    "seconds": "50",
    "lyric": "She always fixed her hair",
    "number": "3618",
  },
  {
    "artist": "The Crests",
    "song": "Sixteen Candles",
    "year": "1959",
    "seconds": "40",
    "lyric": "Make a lovely light",
    "number": "3619",
  },
  {
    "artist": "Charmian Carr & Daniel Truhitte",
    "song": "Sixteen Going On Seventeen",
    "year": "1965",
    "seconds": "60",
    "lyric": "To face a world of men",
    "number": "3620",
  },
  {
    "artist": "Tennessee Ernie Ford",
    "song": "Sixteen Tons",
    "year": "1956",
    "seconds": "50",
    "lyric": "I picked up my shovel",
    "number": "3621",
  },
  {
    "artist": "Rascal Flatts",
    "song": "Skin (Sarabeth)",
    "year": "2005",
    "seconds": "60",
    "lyric": "And her daddy ushers him in",
    "number": "3622",
  },
  {
    "artist": "Bon Iver",
    "song": "Skinny Love",
    "year": "2007",
    "seconds": "60",
    "lyric": "Staring at the sink of blood",
    "number": "3623",
  },
  {
    "artist": "Henson Cargill",
    "song": "Skip a Rope",
    "year": "1967",
    "seconds": "50",
    "lyric": "Stab ‘em in the back",
    "number": "3624",
  },
  {
    "artist": "Dallas Smith",
    "song": "Sky Stays This Blue",
    "year": "2017",
    "seconds": "40",
    "lyric": "You’re posin’ for pictures",
    "number": "3625",
  },
  {
    "artist": "Adele",
    "song": "Skyfall",
    "year": "2012",
    "seconds": "70",
    "lyric": "Hold your breath and count to ten",
    "number": "3626",
  },
  {
    "artist": "Dinah Shore",
    "song": "Skylark",
    "year": "1942",
    "seconds": "50",
    "lyric": "The end of my man",
    "number": "3627",
  },
  {
    "artist": "Peter Gabriel",
    "song": "Sledgehammer",
    "year": "1986",
    "seconds": "50",
    "lyric": "I’ve been feeding the rhythm",
    "number": "3628",
  },
  {
    "artist": "Dallas Smith",
    "song": "Sleepin’ Around",
    "year": "2017",
    "seconds": "70",
    "lyric": "I can’t kiss no one but you",
    "number": "3629",
  },
  {
    "artist": "ZZ Top",
    "song": "Sleeping Bag",
    "year": "1985",
    "seconds": "50",
    "lyric": "We’ll whip out our mattress",
    "number": "3630",
  },
  {
    "artist": "Barbara Mandrell",
    "song": "Sleeping Single in a Double Bed",
    "year": "1978",
    "seconds": "50",
    "lyric": "I’d pour me a drink",
    "number": "3631",
  },
  {
    "artist": "The Ronnettes",
    "song": "Sleigh Ride",
    "year": "1963",
    "seconds": "60",
    "lyric": "Our cheeks are nice and rosy",
    "number": "3632",
  },
  {
    "artist": "Dinah Shore",
    "song": "Sleigh Ride in July",
    "year": "1945",
    "seconds": "60",
    "lyric": "Come in out of the moonlight",
    "number": "3633",
  },
  {
    "artist": "Johnny Paycheck",
    "song": "Slide Off Your Satin Sheets",
    "year": "1977",
    "seconds": "50",
    "lyric": "On dreams and crystal balls",
    "number": "3634",
  },
  {
    "artist": "Clarence Carter",
    "song": "Slip Away",
    "year": "1968",
    "seconds": "60",
    "lyric": "I feel a deep burning inside",
    "number": "3635",
  },
  {
    "artist": "Paul Simon",
    "song": "Slip Slidin’ Away",
    "year": "1977",
    "seconds": "50",
    "lyric": "We’re gliding down the highway",
    "number": "3636",
  },
  {
    "artist": "War",
    "song": "Slippin’ into Darkness",
    "year": "1972",
    "seconds": "40",
    "lyric": "Laughing at the moon",
    "number": "3637",
  },
  {
    "artist": "Beach Boys",
    "song": "Sloop John B",
    "year": "1966",
    "seconds": "40",
    "lyric": "Let me go home",
    "number": "3638",
  },
  {
    "artist": "Kylie Minogue",
    "song": "Slow",
    "year": "2003",
    "seconds": "50",
    "lyric": "Spun me one eighty degrees",
    "number": "3639",
  },
  {
    "artist": "The Pointer Sisters",
    "song": "Slow Hand",
    "year": "1981",
    "seconds": "70",
    "lyric": "Come and go in a heated rush",
    "number": "3640",
  },
  {
    "artist": "Pee Wee King & His Golden Cowboys",
    "song": "Slow Poke",
    "year": "1951",
    "seconds": "60",
    "lyric": "Every time you snap your finger",
    "number": "3641",
  },
  {
    "artist": "Foghat",
    "song": "Slow Ride",
    "year": "1975",
    "seconds": "50",
    "lyric": "We can roll all night",
    "number": "3642",
  },
  {
    "artist": "Webb Pierce",
    "song": "Slowly",
    "year": "1954",
    "seconds": "60",
    "lyric": "My heart, I know you’re stealing",
    "number": "3643",
  },
  {
    "artist": "John Mellencamp",
    "song": "Small Town",
    "year": "1985",
    "seconds": "50",
    "lyric": "The people who love me",
    "number": "3644",
  },
  {
    "artist": "Steve Wariner",
    "song": "Small Town Girl",
    "year": "1987",
    "seconds": "50",
    "lyric": "Ten stories to my desk",
    "number": "3645",
  },
  {
    "artist": "Nirvana",
    "song": "Smells Like Teen Spirit",
    "year": "1991",
    "seconds": "50",
    "lyric": "I feel stupid and contagious",
    "number": "3646",
  },
  {
    "artist": "Lonestar",
    "song": "Smile",
    "year": "1999",
    "seconds": "50",
    "lyric": "Kiss me twice for goodbye",
    "number": "3647",
  },
  {
    "artist": "Nat King Cole",
    "song": "Smile",
    "year": "1954",
    "seconds": "50",
    "lyric": "Though your heart is aching",
    "number": "3648",
  },
  {
    "artist": "The Flying Machine",
    "song": "Smile a Little Smile For Me",
    "year": "1969",
    "seconds": "50",
    "lyric": "Lift up your pretty chin",
    "number": "3649",
  },
  {
    "artist": "The Killers",
    "song": "Smile Like You Mean It",
    "year": "2005",
    "seconds": "60",
    "lyric": "From the back of the restaurant",
    "number": "3650",
  },
  {
    "artist": "The Undisputed Truth",
    "song": "Smiling Faces Sometimes",
    "year": "1971",
    "seconds": "50",
    "lyric": "A frown turned upside down",
    "number": "3651",
  },
  {
    "artist": "Carrie Underwood",
    "song": "Smoke Break",
    "year": "2015",
    "seconds": "50",
    "lyric": "Instead of driving a tractor",
    "number": "3652",
  },
  {
    "artist": "Sanford-Townsend Band",
    "song": "Smoke From a Distant Fire",
    "year": "1977",
    "seconds": "60",
    "lyric": "You pulled the rug right out",
    "number": "3653",
  },
  {
    "artist": "The Platters",
    "song": "Smoke Gets In Your Eyes",
    "year": "1958",
    "seconds": "50",
    "lyric": "When your heart’s on fire",
    "number": "3654",
  },
  {
    "artist": "Deep Purple",
    "song": "Smoke on the Water",
    "year": "1973",
    "seconds": "50",
    "lyric": "Burned down the gambling house",
    "number": "3655",
  },
  {
    "artist": "Red Foley",
    "song": "Smoke on the Water",
    "year": "1944",
    "seconds": "50",
    "lyric": "Fire and flesh and steel",
    "number": "3656",
  },
  {
    "artist": "Brownsville Station",
    "song": "Smokin’ in the Boy’s Room",
    "year": "1974",
    "seconds": "40",
    "lyric": "Sitting in the classroom",
    "number": "3657",
  },
  {
    "artist": "Ronnie Milsap",
    "song": "Smoky Mountain Rain",
    "year": "1980",
    "seconds": "60",
    "lyric": "A phone booth in the rain",
    "number": "3658",
  },
  {
    "artist": "Santana",
    "song": "Smooth",
    "year": "1999",
    "seconds": "40",
    "lyric": "I hear you whisper",
    "number": "3659",
  },
  {
    "artist": "Michael Jackson",
    "song": "Smooth Criminal",
    "year": "1988",
    "seconds": "50",
    "lyric": "She ran underneath the table",
    "number": "3660",
  },
  {
    "artist": "Sade",
    "song": "Smooth Operator",
    "year": "1984",
    "seconds": "50",
    "lyric": "Diamond night and ruby lights",
    "number": "3661",
  },
  {
    "artist": "Joe Henderson",
    "song": "Snap Your Fingers",
    "year": "1962",
    "seconds": "60",
    "lyric": "Back to you on bended knee",
    "number": "3662",
  },
  {
    "artist": "The Royal Guardsmen",
    "song": "Snoopy vs. the Red Baron",
    "year": "1967",
    "seconds": "60",
    "lyric": "But he’d run out of tricks",
    "number": "3663",
  },
  {
    "artist": "Anne Murray",
    "song": "Snowbird",
    "year": "1970",
    "seconds": "40",
    "lyric": "Spread your tiny wings",
    "number": "3664",
  },
  {
    "artist": "Atlanta Rhythm Section",
    "song": "So In To You",
    "year": "1977",
    "seconds": "60",
    "lyric": "From your head to your toe",
    "number": "3665",
  },
  {
    "artist": "Woody Guthrie",
    "song": "So Long, It’s Been Good to Know You",
    "year": "1935",
    "seconds": "60",
    "lyric": "Sighed and cried, hugged and kissed",
    "number": "3666",
  },
  {
    "artist": "Leonard Cohen",
    "song": "So Long, Marianne",
    "year": "1967",
    "seconds": "50",
    "lyric": "Come over to the window",
    "number": "3667",
  },
  {
    "artist": "Bryan White",
    "song": "So Much For Pretending",
    "year": "1996",
    "seconds": "60",
    "lyric": "I was out of my league",
    "number": "3668",
  },
  {
    "artist": "George Strait",
    "song": "So Much Like My Dad",
    "year": "1992",
    "seconds": "60",
    "lyric": "You can’t kiss the hurt away",
    "number": "3669",
  },
  {
    "artist": "The Everly Brothers",
    "song": "So Sad (To Watch Good Love Go Bad)",
    "year": "1960",
    "seconds": "40",
    "lyric": "To see us part",
    "number": "3670",
  },
  {
    "artist": "Ne-Yo",
    "song": "So Sick",
    "year": "2006",
    "seconds": "40",
    "lyric": "Change my answering machine",
    "number": "3671",
  },
  {
    "artist": "Carrie Underwood",
    "song": "So Small",
    "year": "2007",
    "seconds": "60",
    "lyric": "Time’s flying by, moving so fast",
    "number": "3672",
  },
  {
    "artist": "Van Halen",
    "song": "So This is Love?",
    "year": "1981",
    "seconds": "60",
    "lyric": "Got me on pins and needles",
    "number": "3673",
  },
  {
    "artist": "Pink",
    "song": "So What",
    "year": "2008",
    "seconds": "50",
    "lyric": "I’m still a rock star",
    "number": "3674",
  },
  {
    "artist": "Sheryl Crow",
    "song": "Soak Up the Sun",
    "year": "2002",
    "seconds": "50",
    "lyric": "Every time I turn around",
    "number": "3675",
  },
  {
    "artist": "Janis Ian",
    "song": "Society’s Child",
    "year": "1967",
    "seconds": "50",
    "lyric": "Raise my head up high",
    "number": "3676",
  },
  {
    "artist": "John Michael Montgomery",
    "song": "Sold (The Grundy County Auction Incident)",
    "year": "1995",
    "seconds": "60",
    "lyric": "The lady in the second row",
    "number": "3677",
  },
  {
    "artist": "The Shirelles",
    "song": "Soldier Boy",
    "year": "1962",
    "seconds": "50",
    "lyric": "I’ll be true to you",
    "number": "3678",
  },
  {
    "artist": "Peter Gabriel",
    "song": "Solsbury Hill",
    "year": "1977",
    "seconds": "60",
    "lyric": "Eagle flew out of the night",
    "number": "3679",
  },
  {
    "artist": "Blake Shelton",
    "song": "Some Beach",
    "year": "2004",
    "seconds": "60",
    "lyric": "A man with a big cigar",
    "number": "3680",
  },
  {
    "artist": "John Denver",
    "song": "Some Days Are Diamonds (Some Days Are Stone)",
    "year": "1981",
    "seconds": "60",
    "lyric": "Blows a chill in my bones",
    "number": "3681",
  },
  {
    "artist": "Perry Como",
    "song": "Some Enchanted Evening",
    "year": "1949",
    "seconds": "40",
    "lyric": "Fly to her side",
    "number": "3682",
  },
  {
    "artist": "Tanya Tucker",
    "song": "Some Kind of Trouble",
    "year": "1992",
    "seconds": "70",
    "lyric": "I heard a knock on my door",
    "number": "3683",
  },
  {
    "artist": "Grand Funk Railroad",
    "song": "Some Kind of Wonderful",
    "year": "1974",
    "seconds": "70",
    "lyric": "Chills run up and down my spine",
    "number": "3684",
  },
  {
    "artist": "The Power Station",
    "song": "Some Like It Hot",
    "year": "1985",
    "seconds": "60",
    "lyric": "Feel the heat burning you up",
    "number": "3685",
  },
  {
    "artist": "Bryan Adams",
    "song": "Somebody",
    "year": "1985",
    "seconds": "50",
    "lyric": "And they cut right through",
    "number": "3686",
  },
  {
    "artist": "The 1975",
    "song": "Somebody Else",
    "year": "2016",
    "seconds": "50",
    "lyric": "You’re looking through your phone",
    "number": "3687",
  },
  {
    "artist": "Keith Urban",
    "song": "Somebody Like You",
    "year": "2002",
    "seconds": "50",
    "lyric": "Couldn’t walk a straight line",
    "number": "3688",
  },
  {
    "artist": "Gotye (feat. Kimbra)",
    "song": "Somebody That I Used to Know",
    "year": "2012",
    "seconds": "50",
    "lyric": "So happy you could die",
    "number": "3689",
  },
  {
    "artist": "Jefferson Airplane",
    "song": "Somebody to Love",
    "year": "1967",
    "seconds": "50",
    "lyric": "But in your head, baby",
    "number": "3690",
  },
  {
    "artist": "Queen",
    "song": "Somebody to Love",
    "year": "1976",
    "seconds": "60",
    "lyric": "Can barely stand on my feet",
    "number": "3691",
  },
  {
    "artist": "The Killers",
    "song": "Somebody Told Me",
    "year": "2004",
    "seconds": "40",
    "lyric": "You had a boyfriend",
    "number": "3692",
  },
  {
    "artist": "Jamie O’Neal",
    "song": "Somebody’s Hero",
    "year": "2005",
    "seconds": "60",
    "lyric": "Left her footprints on the moon",
    "number": "3693",
  },
  {
    "artist": "Conway Twitty",
    "song": "Somebody’s Needin’ Somebody",
    "year": "1984",
    "seconds": "50",
    "lyric": "Up and walkin’ the floor",
    "number": "3694",
  },
  {
    "artist": "Rockwell (ft. Michael Jackson)",
    "song": "Somebody’s Watching Me",
    "year": "1984",
    "seconds": "60",
    "lyric": "I’m afraid to wash my hair",
    "number": "3695",
  },
  {
    "artist": "Mariah Carey",
    "song": "Someday",
    "year": "1991",
    "seconds": "40",
    "lyric": "You’ll change your mind",
    "number": "3696",
  },
  {
    "artist": "Ian Tyson",
    "song": "Someday Soon",
    "year": "1964",
    "seconds": "60",
    "lyric": "He loves his damned old rodeos",
    "number": "3697",
  },
  {
    "artist": "Diana Ross & the Supremes",
    "song": "Someday We’ll Be Together",
    "year": "1969",
    "seconds": "60",
    "lyric": "I know you won my heart",
    "number": "3698",
  },
  {
    "artist": "Luke Bryan",
    "song": "Someone Else Calling You Baby",
    "year": "2010",
    "seconds": "50",
    "lyric": "You’re over my love, girl",
    "number": "3699",
  },
  {
    "artist": "Faith Hill",
    "song": "Someone Else’s Dream",
    "year": "1996",
    "seconds": "60",
    "lyric": "Smile and wave from the parade",
    "number": "3700",
  },
  {
    "artist": "Adele",
    "song": "Someone Like You",
    "year": "2011",
    "seconds": "60",
    "lyric": "Turn up out of the blue",
    "number": "3701",
  },
  {
    "artist": "Van Morrison",
    "song": "Someone Like You",
    "year": "1987",
    "seconds": "60",
    "lyric": "The light to come shining through",
    "number": "3702",
  },
  {
    "artist": "Elton John",
    "song": "Someone Saved My Life Tonight",
    "year": "1975",
    "seconds": "60",
    "lyric": "And butterflies are free to fly",
    "number": "3703",
  },
  {
    "artist": "The Beatles",
    "song": "Something",
    "year": "1969",
    "seconds": "40",
    "lyric": "Will my love grow?",
    "number": "3704",
  },
  {
    "artist": "Miranda Lambert and Carrie Underwood",
    "song": "Somethin’ Bad",
    "year": "2014",
    "seconds": "50",
    "lyric": "The cash underneath my mattress",
    "number": "3705",
  },
  {
    "artist": "Kip Moore",
    "song": "Somethin’ ‘Bout a Truck",
    "year": "2012",
    "seconds": "70",
    "lyric": "A girl in a red sun dress",
    "number": "3706",
  },
  {
    "artist": "Carrie Underwood",
    "song": "Something in the Water",
    "year": "2014",
    "seconds": "40",
    "lyric": "Fight back the tears",
    "number": "3707",
  },
  {
    "artist": "The Chainsmokers and Coldplay",
    "song": "Something Just Like This",
    "year": "2017",
    "seconds": "50",
    "lyric": "The moon and its eclipse",
    "number": "3708",
  },
  {
    "artist": "Tim McGraw",
    "song": "Something Like That",
    "year": "1999",
    "seconds": "50",
    "lyric": "On my white tee shirt",
    "number": "3709",
  },
  {
    "artist": "Sugarland",
    "song": "Something More",
    "year": "2005",
    "seconds": "40",
    "lyric": "Fill my coffee cup",
    "number": "3710",
  },
  {
    "artist": "Frank & Nancy Sinatra",
    "song": "Somethin’ Stupid",
    "year": "1967",
    "seconds": "60",
    "lyric": "I know I stand in line",
    "number": "3711",
  },
  {
    "artist": "Clint Black",
    "song": "Something That We Do",
    "year": "1997",
    "seconds": "50",
    "lyric": "Flying high and laying low",
    "number": "3712",
  },
  {
    "artist": "Oscar Brand",
    "song": "Something to Sing About",
    "year": "1962",
    "seconds": "60",
    "lyric": "The sound of Mount Royal’s chimes",
    "number": "3713",
  },
  {
    "artist": "Kenny Rogers & the First Edition",
    "song": "Something’s Burning",
    "year": "1970",
    "seconds": "50",
    "lyric": "Hear our young hearts beating",
    "number": "3714",
  },
  {
    "artist": "Etta James",
    "song": "Something’s Got a Hold on Me",
    "year": "1962",
    "seconds": "40",
    "lyric": "My heart feels heavy",
    "number": "3715",
  },
  {
    "artist": "Britney Spears",
    "song": "Sometimes",
    "year": "1999",
    "seconds": "40",
    "lyric": "I’m scared of you",
    "number": "3716",
  },
  {
    "artist": "Dan Hill",
    "song": "Sometimes When We Touch",
    "year": "1977",
    "seconds": "50",
    "lyric": "Close my eyes and hide",
    "number": "3717",
  },
  {
    "artist": "U2",
    "song": "Sometimes You Can’t Make It on Your Own",
    "year": "2005",
    "seconds": "60",
    "lyric": "I don’t pick up the phone",
    "number": "3718",
  },
  {
    "artist": "Keith Urban",
    "song": "Somewhere in My Car",
    "year": "2014",
    "seconds": "60",
    "lyric": "And pulled it over my head",
    "number": "3719",
  },
  {
    "artist": "Ray Conniff Singers",
    "song": "Somewhere My Love",
    "year": "1966",
    "seconds": "60",
    "lyric": "Soft as the kiss of snow",
    "number": "3720",
  },
  {
    "artist": "Linda Ronstadt and James Ingram",
    "song": "Somewhere Out There",
    "year": "1986",
    "seconds": "60",
    "lyric": "If love can see us through",
    "number": "3721",
  },
  {
    "artist": "Highway 101",
    "song": "Somewhere Tonight",
    "year": "1987",
    "seconds": "50",
    "lyric": "He’s runnin’ wild and free",
    "number": "3722",
  },
  {
    "artist": "Kenny Chesney",
    "song": "Somewhere With You",
    "year": "2010",
    "seconds": "60",
    "lyric": "Laughing loud on a carnival ride",
    "number": "3723",
  },
  {
    "artist": "Alannah Myles",
    "song": "Song Instead of a Kiss",
    "year": "1992",
    "seconds": "60",
    "lyric": "A soothing touch in the afternoon",
    "number": "3724",
  },
  {
    "artist": "Neil Diamond",
    "song": "Song Sung Blue",
    "year": "1972",
    "seconds": "40",
    "lyric": "Sleeping on my pillow",
    "number": "3725",
  },
  {
    "artist": "Bob Dylan",
    "song": "Song to Woody",
    "year": "1962",
    "seconds": "60",
    "lyric": "A thousand miles from my home",
    "number": "3726",
  },
  {
    "artist": "Fleetwood Mac",
    "song": "Songbird",
    "year": "1977",
    "seconds": "70",
    "lyric": "For you, the sun will be shining",
    "number": "3727",
  },
  {
    "artist": "Jethro Tull",
    "song": "Songs From the Wood",
    "year": "1969",
    "seconds": "50",
    "lyric": "Wind to fill your sail",
    "number": "3728",
  },
  {
    "artist": "Tanya Tucker",
    "song": "Soon",
    "year": "1993",
    "seconds": "30",
    "lyric": "Tears and champagne",
    "number": "3729",
  },
  {
    "artist": "Justin Bieber",
    "song": "Sorry",
    "year": "2015",
    "seconds": "70",
    "lyric": "I know that I let you down",
    "number": "3730",
  },
  {
    "artist": "Elton John",
    "song": "Sorry Seems to Be the Hardest Word",
    "year": "1976",
    "seconds": "50",
    "lyric": "To make you love me",
    "number": "3731",
  },
  {
    "artist": "The Hollies",
    "song": "Sorry Suzanne",
    "year": "1969",
    "seconds": "70",
    "lyric": "I still love you more than ever",
    "number": "3732",
  },
  {
    "artist": "Sam & Dave",
    "song": "Soul Man",
    "year": "1967",
    "seconds": "70",
    "lyric": "Comin’ to you on a dusty road",
    "number": "3733",
  },
  {
    "artist": "Red Hot Chili Peppers",
    "song": "Soul to Squeeze",
    "year": "1993",
    "seconds": "50",
    "lyric": "The angels in my dream",
    "number": "3734",
  },
  {
    "artist": "Darryl Worley",
    "song": "Sounds Like Life to Me",
    "year": "2009",
    "seconds": "50",
    "lyric": "And the baby’s cutting teeth",
    "number": "3735",
  },
  {
    "artist": "Ed Sheeran (ft. Caila Cabello & Cardi B)",
    "song": "South of the Border",
    "year": "2019",
    "seconds": "50",
    "lyric": "Long hair, no wedding ring",
    "number": "3736",
  },
  {
    "artist": "Gene Autry",
    "song": "South of the Border",
    "year": "1939",
    "seconds": "70",
    "lyric": "I kissed the smile upon her face",
    "number": "3737",
  },
  {
    "artist": "Crosby, Stills & Nash",
    "song": "Southern Cross",
    "year": "1982",
    "seconds": "60",
    "lyric": "And my love is an anchor",
    "number": "3738",
  },
  {
    "artist": "Tim McGraw",
    "song": "Southern Girl",
    "year": "2013",
    "seconds": "50",
    "lyric": "They talk nice and slow",
    "number": "3739",
  },
  {
    "artist": "Neil Young",
    "song": "Southern Man",
    "year": "1970",
    "seconds": "60",
    "lyric": "Tall white mansions and little shacks",
    "number": "3740",
  },
  {
    "artist": "Glen Campbell",
    "song": "Southern Nights",
    "year": "1977",
    "seconds": "40",
    "lyric": "Running through the soul",
    "number": "3741",
  },
  {
    "artist": "Tim McGraw",
    "song": "Southern Voice",
    "year": "2009",
    "seconds": "70",
    "lyric": "I can feel it in my bones",
    "number": "3742",
  },
  {
    "artist": "David Bowie",
    "song": "Space Oddity",
    "year": "1969",
    "seconds": "50",
    "lyric": "And put your helmet on",
    "number": "3743",
  },
  {
    "artist": "Aretha Frankin",
    "song": "Spanish Harlem",
    "year": "1971",
    "seconds": "60",
    "lyric": "The moon is on the run",
    "number": "3744",
  },
  {
    "artist": "Bruce Springsteen",
    "song": "Spare Parts",
    "year": "1988",
    "seconds": "60",
    "lyric": "She lifted him in her arms",
    "number": "3745",
  },
  {
    "artist": "Taylor Swift",
    "song": "Sparks Fly",
    "year": "2011",
    "seconds": "60",
    "lyric": "And I’m a house of cards",
    "number": "3746",
  },
  {
    "artist": "Godsmack",
    "song": "Speak",
    "year": "2006",
    "seconds": "50",
    "lyric": "In time, you’ll run away",
    "number": "3747",
  },
  {
    "artist": "Dan + Shay",
    "song": "Speechless",
    "year": "2018",
    "seconds": "50",
    "lyric": "You’re fixing up your hair",
    "number": "3748",
  },
  {
    "artist": "Coldplay",
    "song": "Speed of Sound",
    "year": "2005",
    "seconds": "60",
    "lyric": "My head stuck under the sand",
    "number": "3749",
  },
  {
    "artist": "Spice Girls",
    "song": "Spice Up Your Life",
    "year": "1997",
    "seconds": "70",
    "lyric": "Chicas to the front, haha go round",
    "number": "3750",
  },
  {
    "artist": "Jim Stafford",
    "song": "Spiders and Snakes",
    "year": "1974",
    "seconds": "50",
    "lyric": "Down by the swimming hole",
    "number": "3751",
  },
  {
    "artist": "No Doubt",
    "song": "Spiderwebs",
    "year": "1995",
    "seconds": "50",
    "lyric": "Walk right through my ears",
    "number": "3752",
  },
  {
    "artist": "War",
    "song": "Spill the Wine",
    "year": "1970",
    "seconds": "70",
    "lyric": "In a big field of tall grass",
    "number": "3753",
  },
  {
    "artist": "Blood, Sweat & Tears",
    "song": "Spinning Wheel",
    "year": "1969",
    "seconds": "40",
    "lyric": "Catch a painted pony",
    "number": "3754",
  },
  {
    "artist": "Bruce Springsteen",
    "song": "Spirit in the Night",
    "year": "1973",
    "seconds": "50",
    "lyric": "My head out the window",
    "number": "3755",
  },
  {
    "artist": "Norman Greenbaum",
    "song": "Spirit in the Sky",
    "year": "1969",
    "seconds": "60",
    "lyric": "And they lay me to rest",
    "number": "3756",
  },
  {
    "artist": "Rush",
    "song": "Spirit of Radio",
    "year": "1980",
    "seconds": "60",
    "lyric": "There is magic at your fingers",
    "number": "3757",
  },
  {
    "artist": "Bobby Darin",
    "song": "Splish Splash",
    "year": "1958",
    "seconds": "60",
    "lyric": "I wrapped the towel around me",
    "number": "3758",
  },
  {
    "artist": "Classics IV",
    "song": "Spooky",
    "year": "1968",
    "seconds": "70",
    "lyric": "Then you smile and hold my hand",
    "number": "3759",
  },
  {
    "artist": "Howlin’ Wolf",
    "song": "Spoonful",
    "year": "1960",
    "seconds": "60",
    "lyric": "Save you from the desert sand",
    "number": "3760",
  },
  {
    "artist": "Soundgarden",
    "song": "Spoonman",
    "year": "1994",
    "seconds": "50",
    "lyric": "Come together with your hands",
    "number": "3761",
  },
  {
    "artist": "The Who",
    "song": "Squeeze Box",
    "year": "1975",
    "seconds": "50",
    "lyric": "And the dog can’t sleep",
    "number": "3762",
  },
  {
    "artist": "Fountains of Wayne",
    "song": "Stacy’s Mom",
    "year": "2003",
    "seconds": "40",
    "lyric": "I mowed your lawn",
    "number": "3763",
  },
  {
    "artist": "ZZ Top",
    "song": "Stages",
    "year": "1985",
    "seconds": "60",
    "lyric": "You left me standing all alone",
    "number": "3764",
  },
  {
    "artist": "Lloyd Price",
    "song": "Stagger Lee",
    "year": "1959",
    "seconds": "50",
    "lyric": "And he pulled his forty-four",
    "number": "3765",
  },
  {
    "artist": "Led Zeppelin",
    "song": "Stairway to Heaven",
    "year": "1971",
    "seconds": "60",
    "lyric": "In a tree by the brook",
    "number": "3766",
  },
  {
    "artist": "R.E.M.",
    "song": "Stand",
    "year": "1989",
    "seconds": "50",
    "lyric": "The trees would be falling",
    "number": "3767",
  },
  {
    "artist": "Rascal Flatts",
    "song": "Stand",
    "year": "2007",
    "seconds": "60",
    "lyric": "Wipe your hands, shake it off",
    "number": "3768",
  },
  {
    "artist": "Jo Dee Messina",
    "song": "Stand Beside Me",
    "year": "1998",
    "seconds": "50",
    "lyric": "And I moved three times",
    "number": "3769",
  },
  {
    "artist": "Ben E. King",
    "song": "Stand By Me",
    "year": "1961",
    "seconds": "60",
    "lyric": "The moon is the only light",
    "number": "3770",
  },
  {
    "artist": "Tammy Wynette",
    "song": "Stand By Your Man",
    "year": "1968",
    "seconds": "50",
    "lyric": "Two arms to cling to",
    "number": "3771",
  },
  {
    "artist": "Burton Cummings",
    "song": "Stand Tall",
    "year": "1976",
    "seconds": "60",
    "lyric": "Nothing here except my old piano",
    "number": "3772",
  },
  {
    "artist": "The Four Tops",
    "song": "Standing in the Shadows of Love",
    "year": "1966",
    "seconds": "50",
    "lyric": "Getting’ ready for the heartaches",
    "number": "3773",
  },
  {
    "artist": "The Four Lads",
    "song": "Standing on the Croner",
    "year": "1956",
    "seconds": "60",
    "lyric": "Watching all the girls go by",
    "number": "3774",
  },
  {
    "artist": "U2",
    "song": "Staring at the Sun",
    "year": "1997",
    "seconds": "50",
    "lyric": "Itch and burn and sting",
    "number": "3775",
  },
  {
    "artist": "David Bowie",
    "song": "Starman",
    "year": "1972",
    "seconds": "40",
    "lyric": "Waiting in the sky",
    "number": "3776",
  },
  {
    "artist": "Brad Paisley and Keith Urban",
    "song": "Start a Band",
    "year": "2008",
    "seconds": "50",
    "lyric": "My sister’s rock star boyfriend",
    "number": "3777",
  },
  {
    "artist": "Rolling Stones",
    "song": "Start Me Up",
    "year": "1981",
    "seconds": "60",
    "lyric": "Spread out the oil, the gasoline",
    "number": "3778",
  },
  {
    "artist": "Mel and Tim",
    "song": "Starting All Over Again",
    "year": "1972",
    "seconds": "60",
    "lyric": "Uphill climb to the finish line",
    "number": "3779",
  },
  {
    "artist": "Clint Black",
    "song": "State of Mind",
    "year": "1993",
    "seconds": "60",
    "lyric": "On a wing and a prayer",
    "number": "3780",
  },
  {
    "artist": "The Jacksons (ft. Mick Jagger)",
    "song": "State of Shock",
    "year": "1984",
    "seconds": "40",
    "lyric": "You got me paralyzed",
    "number": "3781",
  },
  {
    "artist": "Shakespear’s Sister",
    "song": "Stay",
    "year": "1992",
    "seconds": "50",
    "lyric": "You can break the spell",
    "number": "3782",
  },
  {
    "artist": "Sugarland",
    "song": "Stay",
    "year": "2007",
    "seconds": "50",
    "lyric": "I’m up off my knees",
    "number": "3783",
  },
  {
    "artist": "Brothers Osborne",
    "song": "Stay a Little Longer",
    "year": "2015",
    "seconds": "60",
    "lyric": "You slide up close to me",
    "number": "3784",
  },
  {
    "artist": "East 17",
    "song": "Stay Another Day",
    "year": "1994",
    "seconds": "80",
    "lyric": "I touch your face while you are sleeping",
    "number": "3785",
  },
  {
    "artist": "The Bells",
    "song": "Stay Awhile",
    "year": "1971",
    "seconds": "50",
    "lyric": "Into my room he creeps",
    "number": "3786",
  },
  {
    "artist": "Jimmy Wayne",
    "song": "Stay Gone",
    "year": "2003",
    "seconds": "50",
    "lyric": "It’s good for my heart",
    "number": "3787",
  },
  {
    "artist": "The Faces",
    "song": "Stay With Me",
    "year": "1974",
    "seconds": "50",
    "lyric": "Red lips, hair and fingernails",
    "number": "3788",
  },
  {
    "artist": "The Bee Gees",
    "song": "Stayin’ Alive",
    "year": "1978",
    "seconds": "40",
    "lyric": "I’m a dancing man",
    "number": "3789",
  },
  {
    "artist": "Toby Keith",
    "song": "Stays in Mexico",
    "year": "2004",
    "seconds": "50",
    "lyric": "A first grade school teacher",
    "number": "3790",
  },
  {
    "artist": "The Raconteurs",
    "song": "Steady as She Goes",
    "year": "2006",
    "seconds": "50",
    "lyric": "Life in a quiet town",
    "number": "3791",
  },
  {
    "artist": "Ben Harper",
    "song": "Steal My Kisses",
    "year": "2001",
    "seconds": "50",
    "lyric": "Hangin’ around you for days",
    "number": "3792",
  },
  {
    "artist": "Peter Gabriel",
    "song": "Steam",
    "year": "1993",
    "seconds": "70",
    "lyric": "You know your ladder from your snake",
    "number": "3793",
  },
  {
    "artist": "New Kids on the Block",
    "song": "Step by Step",
    "year": "1990",
    "seconds": "70",
    "lyric": "Step 3: it’s just you and me",
    "number": "3794",
  },
  {
    "artist": "Sawyer Brown",
    "song": "Step That Step",
    "year": "1985",
    "seconds": "60",
    "lyric": "You hold your head up high",
    "number": "3795",
  },
  {
    "artist": "Rush",
    "song": "Stick It Out",
    "year": "1993",
    "seconds": "60",
    "lyric": "Running back to cover your neck",
    "number": "3796",
  },
  {
    "artist": "Nelly Furtado",
    "song": "Sticks and Stones",
    "year": "2018",
    "seconds": "50",
    "lyric": "Shot down by your bullets",
    "number": "3797",
  },
  {
    "artist": "Ray Charles",
    "song": "Sticks and Stones",
    "year": "1960",
    "seconds": "50",
    "lyric": "Tryin’ to break us up",
    "number": "3798",
  },
  {
    "artist": "Tracy Lawrence",
    "song": "Sticks and Stones",
    "year": "1991",
    "seconds": "50",
    "lyric": "Tore my heart in two",
    "number": "3799",
  },
  {
    "artist": "Bill Anderson",
    "song": "Still",
    "year": "1963",
    "seconds": "50",
    "lyric": "Though you broke my heart",
    "number": "3800",
  },
  {
    "artist": "The Commodores",
    "song": "Still",
    "year": "1979",
    "seconds": "60",
    "lyric": "We were too blind to see",
    "number": "3801",
  },
  {
    "artist": "Paul Simon",
    "song": "Still Crazy After All These Years",
    "year": "1976",
    "seconds": "50",
    "lyric": "I sit by my window",
    "number": "3802",
  },
  {
    "artist": "George Jones",
    "song": "Still Doin’ Time",
    "year": "1981",
    "seconds": "70",
    "lyric": "And the ocean of liquor I drank",
    "number": "3803",
  },
  {
    "artist": "Ronnie Milsap",
    "song": "Still Losing You",
    "year": "1984",
    "seconds": "50",
    "lyric": "Stepped into an evening rain",
    "number": "3804",
  },
  {
    "artist": "Orleans",
    "song": "Still the One",
    "year": "1976",
    "seconds": "70",
    "lyric": "I want to talk to in bed",
    "number": "3805",
  },
  {
    "artist": "Glen Campbell",
    "song": "Still Within the Sound of My Voice",
    "year": "1987",
    "seconds": "60",
    "lyric": "A ship out on the sea",
    "number": "3806",
  },
  {
    "artist": "Johnny Nash",
    "song": "Stir It Up",
    "year": "1973",
    "seconds": "70",
    "lyric": "I’ll push the wood, light the fire",
    "number": "3807",
  },
  {
    "artist": "The 5th Dimension",
    "song": "Stoned Soul Picnic",
    "year": "1968",
    "seconds": "50",
    "lyric": "Lots of time and wine",
    "number": "3808",
  },
  {
    "artist": "Björk",
    "song": "Stonemilker",
    "year": "2015",
    "seconds": "50",
    "lyric": "Makes me feel your pain",
    "number": "3809",
  },
  {
    "artist": "Spice Girls",
    "song": "Stop",
    "year": "1997",
    "seconds": "40",
    "lyric": "You take an inch",
    "number": "3810",
  },
  {
    "artist": "Mac Davis",
    "song": "Stop and Smell the Roses",
    "year": "1974",
    "seconds": "50",
    "lyric": "A rough and rocky road",
    "number": "3811",
  },
  {
    "artist": "Stevie Nicks & Tom Petty",
    "song": "Stop Draggin’ My Heart Around",
    "year": "1981",
    "seconds": "60",
    "lyric": "Never look me in the eye",
    "number": "3812",
  },
  {
    "artist": "The Supremes",
    "song": "Stop! In the Name of Love",
    "year": "1965",
    "seconds": "50",
    "lyric": "Before you break my heart",
    "number": "3813",
  },
  {
    "artist": "The Hollies",
    "song": "Stop Stop Stop",
    "year": "1966",
    "seconds": "40",
    "lyric": "Sweating from my brow",
    "number": "3814",
  },
  {
    "artist": "Classics IV",
    "song": "Stormy",
    "year": "1968",
    "seconds": "50",
    "lyric": "And it’s windy and cold",
    "number": "3815",
  },
  {
    "artist": "Bryan Adams",
    "song": "Straight From the Heart",
    "year": "1983",
    "seconds": "50",
    "lyric": "You know I’ll never go",
    "number": "3816",
  },
  {
    "artist": "Heart",
    "song": "Straight On",
    "year": "1978",
    "seconds": "70",
    "lyric": "The feel of fortune, deal me in",
    "number": "3817",
  },
  {
    "artist": "Godsmack",
    "song": "Straight Out of Line",
    "year": "2003",
    "seconds": "70",
    "lyric": "You don’t need to lie to me",
    "number": "3818",
  },
  {
    "artist": "N.W.A.",
    "song": "Straight Outta Compton",
    "year": "1988",
    "seconds": "60",
    "lyric": "You can go toe to toe",
    "number": "3819",
  },
  {
    "artist": "Steppenwolf",
    "song": "Straight Shootin’ Woman",
    "year": "1974",
    "seconds": "60",
    "lyric": "Slow down and cool your temper",
    "number": "3820",
  },
  {
    "artist": "John Anderson",
    "song": "Straight Tequila Night",
    "year": "1991",
    "seconds": "50",
    "lyric": "She’s only sippin’ white wine",
    "number": "3821",
  },
  {
    "artist": "Ronnie Milsap",
    "song": "Stranger in My House",
    "year": "1983",
    "seconds": "60",
    "lyric": "She sits staring out the window",
    "number": "3822",
  },
  {
    "artist": "Tony Bennett",
    "song": "Stranger in Paradise",
    "year": "1953",
    "seconds": "60",
    "lyric": "But open your angel’s arms",
    "number": "3823",
  },
  {
    "artist": "Ronnie Milsap",
    "song": "Stranger Things Have Happened",
    "year": "1990",
    "seconds": "60",
    "lyric": "I heard footsteps on the stairs",
    "number": "3824",
  },
  {
    "artist": "Frank Sinatra",
    "song": "Strangers In the Night",
    "year": "1966",
    "seconds": "50",
    "lyric": "Your smile was so exciting",
    "number": "3825",
  },
  {
    "artist": "Ted Nugent",
    "song": "Stranglehold",
    "year": "1975",
    "seconds": "70",
    "lyric": "I like to tear up the street",
    "number": "3826",
  },
  {
    "artist": "The Beatles",
    "song": "Strawberry Fields Forever",
    "year": "1967",
    "seconds": "60",
    "lyric": "It must be high or low",
    "number": "3827",
  },
  {
    "artist": "Deana Carter",
    "song": "Strawberry Wine",
    "year": "1996",
    "seconds": "60",
    "lyric": "On the banks of the river",
    "number": "3828",
  },
  {
    "artist": "Rolling Stones",
    "song": "Street Fighting Man",
    "year": "1968",
    "seconds": "70",
    "lyric": "Sing for a rock ‘n’ roll band",
    "number": "3829",
  },
  {
    "artist": "Dwight Yoakam & Buck Owens",
    "song": "Streets of Bakersfield",
    "year": "1988",
    "seconds": "60",
    "lyric": "I’ve worn blisters on my heels",
    "number": "3830",
  },
  {
    "artist": "Bobby Bare",
    "song": "Streets of Baltimore",
    "year": "1966",
    "seconds": "60",
    "lyric": "Her heart was filled with laughter",
    "number": "3831",
  },
  {
    "artist": "Burl Ives",
    "song": "Streets of Laredo (Cowboy’s Lament)",
    "year": "1949",
    "seconds": "40",
    "lyric": "Beat the drum slowly",
    "number": "3832",
  },
  {
    "artist": "Ralph McTell",
    "song": "Streets of London",
    "year": "1969",
    "seconds": "60",
    "lyric": "Over the rim of his teacup",
    "number": "3833",
  },
  {
    "artist": "Bruce Springsteen",
    "song": "Streets of Philadelphia",
    "year": "1994",
    "seconds": "60",
    "lyric": "Hear the blood in my veins",
    "number": "3834",
  },
  {
    "artist": "Luke Bryan",
    "song": "Strip It Down",
    "year": "2015",
    "seconds": "60",
    "lyric": "Run my fingers down your back",
    "number": "3835",
  },
  {
    "artist": "Sheryl Crow",
    "song": "Strong Enough",
    "year": "1994",
    "seconds": "60",
    "lyric": "I’m throwing punches in the air",
    "number": "3836",
  },
  {
    "artist": "Tanya Tucker",
    "song": "Strong Enough to Bend",
    "year": "1988",
    "seconds": "60",
    "lyric": "We can sway with the wind",
    "number": "3837",
  },
  {
    "artist": "Britney Spears",
    "song": "Stronger",
    "year": "2000",
    "seconds": "60",
    "lyric": "Nothing you can do or say",
    "number": "3838",
  },
  {
    "artist": "Kanye West",
    "song": "Stronger",
    "year": "2007",
    "seconds": "60",
    "lyric": "I need you to hurry up",
    "number": "3839",
  },
  {
    "artist": "U2",
    "song": "Stuck in a Moment You Can’t Get Out Of",
    "year": "2001",
    "seconds": "60",
    "lyric": "The nights you filled with fireworks",
    "number": "3840",
  },
  {
    "artist": "Stealers Wheel",
    "song": "Stuck in the Middle With You",
    "year": "1972",
    "seconds": "60",
    "lyric": "Clowns to the left of me",
    "number": "3841",
  },
  {
    "artist": "Sugarland",
    "song": "Stuck Like Glue",
    "year": "2010",
    "seconds": "50",
    "lyric": "Pulling me right back in",
    "number": "3842",
  },
  {
    "artist": "Elvis Presley",
    "song": "Stuck on You",
    "year": "1960",
    "seconds": "50",
    "lyric": "You can shake an apple",
    "number": "3843",
  },
  {
    "artist": "Lionel Richie",
    "song": "Stuck on You",
    "year": "1984",
    "seconds": "50",
    "lyric": "Been a fool too long",
    "number": "3844",
  },
  {
    "artist": "Ariana Grande and Justin Bieber",
    "song": "Stuck with U",
    "year": "2020",
    "seconds": "60",
    "lyric": "One strike and you’re out, baby",
    "number": "3845",
  },
  {
    "artist": "Keith Urban",
    "song": "Stupid Boy",
    "year": "2006",
    "seconds": "50",
    "lyric": "And you crushed her plans",
    "number": "3846",
  },
  {
    "artist": "Connie Francis",
    "song": "Stupid Cupid",
    "year": "1958",
    "seconds": "40",
    "lyric": "Stop picking on me",
    "number": "3847",
  },
  {
    "artist": "Lady Gaga",
    "song": "Stupid Love",
    "year": "2020",
    "seconds": "60",
    "lyric": "I freak out, look at me",
    "number": "3848",
  },
  {
    "artist": "Taylor Swift",
    "song": "Style",
    "year": "2015",
    "seconds": "40",
    "lyric": "Burning flames or paradise",
    "number": "3849",
  },
  {
    "artist": "Bob Dylan",
    "song": "Subterranean Homesick Blues",
    "year": "1965",
    "seconds": "40",
    "lyric": "Girl by the whirlpool",
    "number": "3850",
  },
  {
    "artist": "The Postal Service",
    "song": "Such Great Heights",
    "year": "2003",
    "seconds": "50",
    "lyric": "The freckles in our eyes",
    "number": "3851",
  },
  {
    "artist": "Logic (ft. Lil Wayne, Wiz Khalifa, Imagine Dragons)",
    "song": "Sucker for Pain",
    "year": "2016",
    "seconds": "60",
    "lyric": "Take my hand through the flames",
    "number": "3852",
  },
  {
    "artist": "Billy Ocean",
    "song": "Suddenly",
    "year": "1985",
    "seconds": "50",
    "lyric": "Holding hands as we walk",
    "number": "3853",
  },
  {
    "artist": "Sara Evans",
    "song": "Suds in the Bucket",
    "year": "2004",
    "seconds": "50",
    "lyric": "Just up and walk away",
    "number": "3854",
  },
  {
    "artist": "David Bowie",
    "song": "Suffragette City",
    "year": "1976",
    "seconds": "60",
    "lyric": "Oh Henry, get off the phone",
    "number": "3855",
  },
  {
    "artist": "Brockhampton",
    "song": "Sugar",
    "year": "2019",
    "seconds": "50",
    "lyric": "Dancin’ in between the raindrops",
    "number": "3856",
  },
  {
    "artist": "The Jackson 5",
    "song": "Sugar Daddy",
    "year": "1971",
    "seconds": "60",
    "lyric": "The devil in you kills me",
    "number": "3857",
  },
  {
    "artist": "Neil Young",
    "song": "Sugar Mountain",
    "year": "1969",
    "seconds": "40",
    "lyric": "You’re underneath the stairs",
    "number": "3858",
  },
  {
    "artist": "Jimmy Gilmer & the Fireballs",
    "song": "Sugar Shack",
    "year": "1963",
    "seconds": "80",
    "lyric": "A black leotard and her feet are bare",
    "number": "3859",
  },
  {
    "artist": "The Archies",
    "song": "Sugar, Sugar",
    "year": "1969",
    "seconds": "50",
    "lyric": "You are my candy girl",
    "number": "3860",
  },
  {
    "artist": "Fall Out Boy",
    "song": "Sugar, We’re Goin’ Down",
    "year": "2005",
    "seconds": "60",
    "lyric": "Your number one with a bullet",
    "number": "3861",
  },
  {
    "artist": "The Mash",
    "song": "Suicide Is Painless",
    "year": "1970",
    "seconds": "60",
    "lyric": "Through early morning fog I see",
    "number": "3862",
  },
  {
    "artist": "Crosby, Stills & Nash",
    "song": "Suite: Judy Blue Eyes",
    "year": "1969",
    "seconds": "60",
    "lyric": "I am yours, you are mine",
    "number": "3863",
  },
  {
    "artist": "Dire Straits",
    "song": "Sultans of Swing",
    "year": "1978",
    "seconds": "50",
    "lyric": "A band is blowin’ Dixie",
    "number": "3864",
  },
  {
    "artist": "War",
    "song": "Summer",
    "year": "1976",
    "seconds": "50",
    "lyric": "Stretched out on a blanket",
    "number": "3865",
  },
  {
    "artist": "LFO",
    "song": "Summer Girls",
    "year": "1999",
    "seconds": "50",
    "lyric": "You buzz like a hornet",
    "number": "3866",
  },
  {
    "artist": "Cliff Richard and the Shadows",
    "song": "Summer Holiday",
    "year": "1963",
    "seconds": "70",
    "lyric": "No more worries for me or you",
    "number": "3867",
  },
  {
    "artist": "The Lovin’ Spoonful",
    "song": "Summer in the City",
    "year": "1966",
    "seconds": "60",
    "lyric": "Cool cat, looking for a kitty",
    "number": "3868",
  },
  {
    "artist": "John Travolta and Olivia Newton-John",
    "song": "Summer Nights",
    "year": "1978",
    "seconds": "50",
    "lyric": "He showed off, splashing around",
    "number": "3869",
  },
  {
    "artist": "Rascal Flatts",
    "song": "Summer Nights",
    "year": "2009",
    "seconds": "50",
    "lyric": "Big bonfire on the beach",
    "number": "3870",
  },
  {
    "artist": "Bryan Adams",
    "song": "Summer of ‘69",
    "year": "1985",
    "seconds": "40",
    "lyric": "‘Til my fingers bled",
    "number": "3871",
  },
  {
    "artist": "Frank Sinatra",
    "song": "Summer Wind",
    "year": "1966",
    "seconds": "60",
    "lyric": "Blowin’ in from across the sea",
    "number": "3872",
  },
  {
    "artist": "Clint Black",
    "song": "Summer’s Comin’",
    "year": "1995",
    "seconds": "60",
    "lyric": "The first one standin’ in line",
    "number": "3873",
  },
  {
    "artist": "Kenny Chesney",
    "song": "Summertime",
    "year": "2006",
    "seconds": "50",
    "lyric": "Cheap shades and a tattoo",
    "number": "3874",
  },
  {
    "artist": "Eddie Cochran",
    "song": "Summertime Blues",
    "year": "1958",
    "seconds": "60",
    "lyric": "But you’re too young to vote",
    "number": "3875",
  },
  {
    "artist": "Lana Del Rey",
    "song": "Summertime Sadness",
    "year": "2012",
    "seconds": "60",
    "lyric": "High heels off, I’m feeling alive",
    "number": "3876",
  },
  {
    "artist": "U2",
    "song": "Sunday Bloody Sunday",
    "year": "1983",
    "seconds": "50",
    "lyric": "Across the dead end street",
    "number": "3877",
  },
  {
    "artist": "Johnny Cash",
    "song": "Sunday Morning Coming Down",
    "year": "1970",
    "seconds": "50",
    "lyric": "Then I washed my face",
    "number": "3878",
  },
  {
    "artist": "Gordon Lightfoot",
    "song": "Sundown",
    "year": "1974",
    "seconds": "50",
    "lyric": "Back in her satin dress",
    "number": "3879",
  },
  {
    "artist": "Glen Campbell",
    "song": "Sunflower",
    "year": "1977",
    "seconds": "50",
    "lyric": "It steals your breath away",
    "number": "3880",
  },
  {
    "artist": "Post Malone & Swae Lee",
    "song": "Sunflower",
    "year": "2018",
    "seconds": "50",
    "lyric": "Crash at my place, baby",
    "number": "3881",
  },
  {
    "artist": "Corey Hart",
    "song": "Sunglasses at Night",
    "year": "1984",
    "seconds": "60",
    "lyric": "I turn to her and say",
    "number": "3882",
  },
  {
    "artist": "Bobby Hebb",
    "song": "Sunny",
    "year": "1966",
    "seconds": "50",
    "lyric": "I feel ten feet tall",
    "number": "3883",
  },
  {
    "artist": "The Kinks",
    "song": "Sunny Afternoon",
    "year": "1966",
    "seconds": "60",
    "lyric": "Sipping at my ice cold beer",
    "number": "3884",
  },
  {
    "artist": "Jonathan Edwards",
    "song": "Sunshine",
    "year": "1972",
    "seconds": "60",
    "lyric": "I promise you I’ll be singing",
    "number": "3885",
  },
  {
    "artist": "Cream",
    "song": "Sunshine of Your Love",
    "year": "1967",
    "seconds": "60",
    "lyric": "To give you my dawn surprise",
    "number": "3886",
  },
  {
    "artist": "John Denver",
    "song": "Sunshine On My Shoulders",
    "year": "1973",
    "seconds": "50",
    "lyric": "Sure to make you smile",
    "number": "3887",
  },
  {
    "artist": "Donovan",
    "song": "Sunshine Superman",
    "year": "1966",
    "seconds": "70",
    "lyric": "We stood on a beach at sunset",
    "number": "3888",
  },
  {
    "artist": "Bob Seger",
    "song": "Sunspot Baby",
    "year": "1976",
    "seconds": "60",
    "lyric": "But she packed up her bags",
    "number": "3889",
  },
  {
    "artist": "Nicki Minaj",
    "song": "Super Bass",
    "year": "2012",
    "seconds": "50",
    "lyric": "And I’m loving your tie",
    "number": "3890",
  },
  {
    "artist": "Rick James",
    "song": "Super Freak",
    "year": "1981",
    "seconds": "70",
    "lyric": "From her head down to her toenails",
    "number": "3891",
  },
  {
    "artist": "ABBA",
    "song": "Super Trouper",
    "year": "1980",
    "seconds": "40",
    "lyric": "Shining like the sun",
    "number": "3892",
  },
  {
    "artist": "Curtis Mayfield",
    "song": "Superfly",
    "year": "1973",
    "seconds": "50",
    "lyric": "But the time’s running out",
    "number": "3893",
  },
  {
    "artist": "Julie Andrews",
    "song": "Supercalifragilisticexpialidocious",
    "year": "1964",
    "seconds": "60",
    "lyric": "If you say it loud enough",
    "number": "3894",
  },
  {
    "artist": "Liz Phair",
    "song": "Supernova",
    "year": "1994",
    "seconds": "50",
    "lyric": "Angel with wings of fire",
    "number": "3895",
  },
  {
    "artist": "The Carpenters",
    "song": "Superstar",
    "year": "1971",
    "seconds": "70",
    "lyric": "You told me you loved me, baby",
    "number": "3896",
  },
  {
    "artist": "Murray Head & the Trinidad Singers",
    "song": "Superstar",
    "year": "1971",
    "seconds": "50",
    "lyric": "Your friends at the top",
    "number": "3897",
  },
  {
    "artist": "The Temptations",
    "song": "Superstar (Remember How You Got Where You Are)",
    "year": "1971",
    "seconds": "60",
    "lyric": "Turn up your nose and frown",
    "number": "3898",
  },
  {
    "artist": "Stevie Wonder",
    "song": "Superstition",
    "year": "1973",
    "seconds": "40",
    "lyric": "Writing’s on the wall",
    "number": "3899",
  },
  {
    "artist": "Blake Shelton",
    "song": "Sure Be Cool If You Did",
    "year": "2013",
    "seconds": "50",
    "lyric": "You’ve already knocked me dead",
    "number": "3900",
  },
  {
    "artist": "Jan & Dean",
    "song": "Surf City",
    "year": "1963",
    "seconds": "50",
    "lyric": "Two girls for every boy",
    "number": "3901",
  },
  {
    "artist": "Beach Boys",
    "song": "Surfin’ Safari",
    "year": "1962",
    "seconds": "50",
    "lyric": "We’re loading up our Woody",
    "number": "3902",
  },
  {
    "artist": "Beach Boys",
    "song": "Surfin’ U.S.A.",
    "year": "1963",
    "seconds": "50",
    "lyric": "A bushy, bushy, blonde hairdo",
    "number": "3903",
  },
  {
    "artist": "Elvis Presley",
    "song": "Surrender",
    "year": "1961",
    "seconds": "60",
    "lyric": "Your lips, your arms, your heart",
    "number": "3904",
  },
  {
    "artist": "Perry Como",
    "song": "Surrender",
    "year": "1946",
    "seconds": "60",
    "lyric": "A love you can cling to",
    "number": "3905",
  },
  {
    "artist": "Destiny’s Child",
    "song": "Survivor",
    "year": "2002",
    "seconds": "60",
    "lyric": "Hate on you in the magazines",
    "number": "3906",
  },
  {
    "artist": "Terry Stafford",
    "song": "Suspicion",
    "year": "1964",
    "seconds": "60",
    "lyric": "Whenever you are out of sight",
    "number": "3907",
  },
  {
    "artist": "Elvis Presley",
    "song": "Suspicious Minds",
    "year": "1969",
    "seconds": "40",
    "lyric": "Caught in a trap",
    "number": "3908",
  },
  {
    "artist": "Phil Collins",
    "song": "Sussudio",
    "year": "1985",
    "seconds": "40",
    "lyric": "She makes me nervous",
    "number": "3909",
  },
  {
    "artist": "Leonard Cohen",
    "song": "Suzanne",
    "year": "1967",
    "seconds": "60",
    "lyric": "She feeds you tea and oranges",
    "number": "3910",
  },
  {
    "artist": "Creedence Clearwater Revival",
    "song": "Suzie Q",
    "year": "1968",
    "seconds": "60",
    "lyric": "I like the way you walk",
    "number": "3911",
  },
  {
    "artist": "Jay-Z & T.I. (ft. Kanye West, Lil Wayne & M.I.A.)",
    "song": "Swagga Like Us",
    "year": "2008",
    "seconds": "40",
    "lyric": "I passed the dressing",
    "number": "3912",
  },
  {
    "artist": "Johnny Rivers",
    "song": "Swayin’ to the Music (Slow Dancing)",
    "year": "1977",
    "seconds": "60",
    "lyric": "Whisper to me, hold me tight",
    "number": "3913",
  },
  {
    "artist": "Frankie Valli",
    "song": "Swearin’ to God",
    "year": "1975",
    "seconds": "70",
    "lyric": "My heart and soul to you, baby",
    "number": "3914",
  },
  {
    "artist": "The 5th Dimension",
    "song": "Sweet Blindness",
    "year": "1968",
    "seconds": "40",
    "lyric": "Drink my Daddy’s wine",
    "number": "3915",
  },
  {
    "artist": "Neil Diamond",
    "song": "Sweet Caroline",
    "year": "1969",
    "seconds": "50",
    "lyric": "Hurtin’ runs off my shoulders",
    "number": "3916",
  },
  {
    "artist": "Guns N' Roses",
    "song": "Sweet Child O' Mine",
    "year": "1987",
    "seconds": "70",
    "lyric": "Pray for the thunder and the rain",
    "number": "3917",
  },
  {
    "artist": "The Stampeders",
    "song": "Sweet City Woman",
    "year": "1971",
    "seconds": "50",
    "lyric": "I can almost touch you",
    "number": "3918",
  },
  {
    "artist": "The Kendalls",
    "song": "Sweet Desire",
    "year": "1978",
    "seconds": "60",
    "lyric": "Your touch, it thrills me so",
    "number": "3919",
  },
  {
    "artist": "Jethro Tull",
    "song": "Sweet Dream",
    "year": "1969",
    "seconds": "60",
    "lyric": "Wrapped up tightly in the coat",
    "number": "3920",
  },
  {
    "artist": "Beyoncé",
    "song": "Sweet Dreams",
    "year": "2009",
    "seconds": "40",
    "lyric": "Clouds filled with stars",
    "number": "3921",
  },
  {
    "artist": "Eurythmics",
    "song": "Sweet Dreams (Are Made of This)",
    "year": "1983",
    "seconds": "70",
    "lyric": "Travel the world and the seven seas",
    "number": "3922",
  },
  {
    "artist": "Patsy Cline",
    "song": "Sweet Dreams of You",
    "year": "1963",
    "seconds": "50",
    "lyric": "I’ll never wear your ring",
    "number": "3923",
  },
  {
    "artist": "Aerosmith",
    "song": "Sweet Emotion",
    "year": "1975",
    "seconds": "50",
    "lyric": "Set your pants on fire",
    "number": "3924",
  },
  {
    "artist": "Michael McDonald",
    "song": "Sweet Freedom",
    "year": "1986",
    "seconds": "50",
    "lyric": "Dancin’ to a different drum",
    "number": "3925",
  },
  {
    "artist": "Creedence Clearwater Revival",
    "song": "Sweet Hitchhiker",
    "year": "1971",
    "seconds": "50",
    "lyric": "Ride on my fast machine",
    "number": "3926",
  },
  {
    "artist": "Lynyrd Skynyrd",
    "song": "Sweet Home Alabama",
    "year": "1974",
    "seconds": "70",
    "lyric": "Pick me up when I’m feeling blue",
    "number": "3927",
  },
  {
    "artist": "Chuck Berry",
    "song": "Sweet Little Sixteen",
    "year": "1958",
    "seconds": "60",
    "lyric": "Deep in the heart of Texas",
    "number": "3928",
  },
  {
    "artist": "The Commodores",
    "song": "Sweet Love",
    "year": "1976",
    "seconds": "60",
    "lyric": "Show me a mountain so high",
    "number": "3929",
  },
  {
    "artist": "Wadsworth Mansion",
    "song": "Sweet Mary",
    "year": "1971",
    "seconds": "50",
    "lyric": "A stomach full of love",
    "number": "3930",
  },
  {
    "artist": "Andy Williams",
    "song": "Sweet Memories",
    "year": "1968",
    "seconds": "50",
    "lyric": "Her laughter spills like water",
    "number": "3931",
  },
  {
    "artist": "Brenda Lee",
    "song": "Sweet Nothin’s",
    "year": "1960",
    "seconds": "60",
    "lyric": "My baby whispers in my ear",
    "number": "3932",
  },
  {
    "artist": "Buck Owens",
    "song": "Sweet Rosie Jones",
    "year": "1968",
    "seconds": "50",
    "lyric": "The sun was sinking low",
    "number": "3933",
  },
  {
    "artist": "The Chiffons",
    "song": "Sweet Talkin’ Guy",
    "year": "1966",
    "seconds": "60",
    "lyric": "Sweeter than sugar, kisses like wine",
    "number": "3934",
  },
  {
    "artist": "Electric Light Orchestra",
    "song": "Sweet Talkin’ Woman",
    "year": "1977",
    "seconds": "70",
    "lyric": "Waitin’ for the operator on the line",
    "number": "3935",
  },
  {
    "artist": "Keith Urban",
    "song": "Sweet Thing",
    "year": "2008",
    "seconds": "60",
    "lyric": "Climb on out of your window",
    "number": "3936",
  },
  {
    "artist": "Rufus (ft. Chaka Khan)",
    "song": "Sweet Thing",
    "year": "1975",
    "seconds": "70",
    "lyric": "You’re my heat, you are my fire",
    "number": "3937",
  },
  {
    "artist": "Van Morrison",
    "song": "Sweet Thing",
    "year": "1968",
    "seconds": "60",
    "lyric": "Sugar baby with your champagne eyes",
    "number": "3938",
  },
  {
    "artist": "Dinah Shore",
    "song": "Sweet Violets",
    "year": "1951",
    "seconds": "70",
    "lyric": "Covered all over from head to toe",
    "number": "3939",
  },
  {
    "artist": "U2",
    "song": "Sweetest Thing",
    "year": "1998",
    "seconds": "50",
    "lyric": "You can sew it up",
    "number": "3940",
  },
  {
    "artist": "Joan Baez",
    "song": "Swing Low, Sweet Chariot",
    "year": "1969",
    "seconds": "70",
    "lyric": "A band of angels comin’ after me",
    "number": "3941",
  },
  {
    "artist": "John Anderson",
    "song": "Swingin’",
    "year": "1983",
    "seconds": "50",
    "lyric": "Rollin’ up a garden hose",
    "number": "3942",
  },
  {
    "artist": "Bing Crosby",
    "song": "Swinging On a Star",
    "year": "1944",
    "seconds": "60",
    "lyric": "Carry moonbeams home in a jar",
    "number": "3943",
  },
  {
    "artist": "Dr. Hook & the Medicine Show",
    "song": "Sylvia’s Mother",
    "year": "1972",
    "seconds": "50",
    "lyric": "Catchin’ the nine o’clock train",
    "number": "3944",
  },
  {
    "artist": "Rolling Stones",
    "song": "Sympathy for the Devil",
    "year": "1968",
    "seconds": "70",
    "lyric": "I’m a man of wealth and taste",
    "number": "3945",
  },
  {
    "artist": "The Police",
    "song": "Synchronicity II",
    "year": "1983",
    "seconds": "50",
    "lyric": "Grandmother screaming at the wall",
    "number": "3946",
  },
  {
    "artist": "AC/DC",
    "song": "T.N.T.",
    "year": "1976",
    "seconds": "50",
    "lyric": "Lock up your back door",
    "number": "3947",
  },
  {
    "artist": "Bessie Smith",
    "song": "T’ain’t Nobody’s Business If I Do",
    "year": "1923",
    "seconds": "50",
    "lyric": "Leaves me in a pickle",
    "number": "3948",
  },
  {
    "artist": "Soft Cell",
    "song": "Tainted Love",
    "year": "1982",
    "seconds": "70",
    "lyric": "You drive into the heart of me",
    "number": "3949",
  },
  {
    "artist": "Rodney Atkins",
    "song": "Take a Back Road",
    "year": "2011",
    "seconds": "60",
    "lyric": "I’m looking for an exit sign",
    "number": "3950",
  },
  {
    "artist": "ABBA",
    "song": "Take a Chance on Me",
    "year": "1978",
    "seconds": "60",
    "lyric": "I dream I’m alone with you",
    "number": "3951",
  },
  {
    "artist": "Jason Aldean",
    "song": "Take a Little Ride",
    "year": "2012",
    "seconds": "60",
    "lyric": "Pretty pink toes on the dash",
    "number": "3952",
  },
  {
    "artist": "Bobby Vee",
    "song": "Take Good Care of My Baby",
    "year": "1961",
    "seconds": "60",
    "lyric": "Paint a rainbow all around her",
    "number": "3953",
  },
  {
    "artist": "Pink Floyd",
    "song": "Take It Back",
    "year": "1994",
    "seconds": "50",
    "lyric": "So I spy on her",
    "number": "3954",
  },
  {
    "artist": "Eagles",
    "song": "Take It Easy",
    "year": "1972",
    "seconds": "50",
    "lyric": "Seven women on my mind",
    "number": "3955",
  },
  {
    "artist": "Andy Taylor",
    "song": "Take It Easy (On Yourself)",
    "year": "1986",
    "seconds": "40",
    "lyric": "But I’m the pigeon",
    "number": "3956",
  },
  {
    "artist": "Eagles",
    "song": "Take It to the Limit",
    "year": "1975",
    "seconds": "60",
    "lyric": "So put me on a highway",
    "number": "3957",
  },
  {
    "artist": "Faith Hill",
    "song": "Take Me as I Am",
    "year": "1994",
    "seconds": "50",
    "lyric": "Need a bed of roses",
    "number": "3958",
  },
  {
    "artist": "Diana Ross",
    "song": "Take Me Higher",
    "year": "1995",
    "seconds": "50",
    "lyric": "Where the birds are flying",
    "number": "3959",
  },
  {
    "artist": "Cher",
    "song": "Take Me Home",
    "year": "1979",
    "seconds": "60",
    "lyric": "Music and candlelight, stars up above",
    "number": "3960",
  },
  {
    "artist": "John Denver",
    "song": "Take Me Home, Country Roads",
    "year": "1971",
    "seconds": "50",
    "lyric": "And driving down the road",
    "number": "3961",
  },
  {
    "artist": "Eddie Money",
    "song": "Take Me Home Tonight",
    "year": "1986",
    "seconds": "50",
    "lyric": "Anticipation is running through me",
    "number": "3962",
  },
  {
    "artist": "Doobie Brothers",
    "song": "Take Me in Your Arms",
    "year": "1975",
    "seconds": "50",
    "lyric": "Rock me a little while",
    "number": "3963",
  },
  {
    "artist": "Blackstreet & Mýa (ft. Blinky Blink & Mase)",
    "song": "Take Me There",
    "year": "1998",
    "seconds": "70",
    "lyric": "Close your eyes and count to five",
    "number": "3964",
  },
  {
    "artist": "Rascal Flatts",
    "song": "Take Me There",
    "year": "2007",
    "seconds": "40",
    "lyric": "Your first real kiss",
    "number": "3965",
  },
  {
    "artist": "Talking Heads",
    "song": "Take Me to the River",
    "year": "1978",
    "seconds": "80",
    "lyric": "Hug me, squeeze me, love me, tease me",
    "number": "3966",
  },
  {
    "artist": "Kool & the Gang",
    "song": "Take My Heart",
    "year": "1981",
    "seconds": "60",
    "lyric": "A dream of you and I",
    "number": "3967",
  },
  {
    "artist": "A-ha",
    "song": "Take On Me",
    "year": "1985",
    "seconds": "50",
    "lyric": "But I’ll be stumbling away",
    "number": "3968",
  },
  {
    "artist": "Ella Fitzgerald, Duke Ellington",
    "song": "Take the “A” Train",
    "year": "1957",
    "seconds": "60",
    "lyric": "Hurry, get on, now it’s coming",
    "number": "3969",
  },
  {
    "artist": "The Steve Miller Band",
    "song": "Take the Money and Run",
    "year": "1976",
    "seconds": "40",
    "lyric": "They headed down south",
    "number": "3970",
  },
  {
    "artist": "Ray Charles",
    "song": "Take These Chains From My Heart",
    "year": "1963",
    "seconds": "40",
    "lyric": "And set me free",
    "number": "3971",
  },
  {
    "artist": "Sam Hunt",
    "song": "Take Your Time",
    "year": "2014",
    "seconds": "50",
    "lyric": "Your eyes are so intimidating",
    "number": "3972",
  },
  {
    "artist": "Bachman Turner Overdrive",
    "song": "Takin’ Care of Business",
    "year": "1973",
    "seconds": "60",
    "lyric": "And if your train’s on time",
    "number": "3973",
  },
  {
    "artist": "Doobie Brothers",
    "song": "Takin’ It to the Streets",
    "year": "1976",
    "seconds": "60",
    "lyric": "Take this message to my brother",
    "number": "3974",
  },
  {
    "artist": "Coldplay",
    "song": "Talk",
    "year": "2005",
    "seconds": "50",
    "lyric": "You can climb a ladder",
    "number": "3975",
  },
  {
    "artist": "Anne Murray",
    "song": "Talk It Over in the Morning",
    "year": "1971",
    "seconds": "60",
    "lyric": "Pack our troubles off to bed",
    "number": "3976",
  },
  {
    "artist": "Crystal Gayle",
    "song": "Talking in Your Sleep",
    "year": "1978",
    "seconds": "60",
    "lyric": "I’ve been listening to your dreams",
    "number": "3977",
  },
  {
    "artist": "Buck Owens",
    "song": "Tall Dark Stranger",
    "year": "1969",
    "seconds": "70",
    "lyric": "Jump up on his pony and ride",
    "number": "3978",
  },
  {
    "artist": "Bob Dylan",
    "song": "Tangled Up in Blue",
    "year": "1975",
    "seconds": "50",
    "lyric": "Rain falling on my shoes",
    "number": "3979",
  },
  {
    "artist": "Smashing Pumpkins",
    "song": "Tarantula",
    "year": "2007",
    "seconds": "50",
    "lyric": "You and me go back",
    "number": "3980",
  },
  {
    "artist": "Jason Aldean",
    "song": "Tattoos On This Town",
    "year": "2011",
    "seconds": "60",
    "lyric": "Swinging out a little too far",
    "number": "3981",
  },
  {
    "artist": "Harry Chapin",
    "song": "Taxi",
    "year": "1972",
    "seconds": "60",
    "lyric": "She just looked out the window",
    "number": "3982",
  },
  {
    "artist": "Marion Harris",
    "song": "Tea For Two",
    "year": "1925",
    "seconds": "50",
    "lyric": "Picture me upon your knee",
    "number": "3983",
  },
  {
    "artist": "Crosby, Stills, Nash & Young",
    "song": "Teach Your Children",
    "year": "1970",
    "seconds": "50",
    "lyric": "And know they love you",
    "number": "3984",
  },
  {
    "artist": "Jethro Tull",
    "song": "Teacher",
    "year": "1970",
    "seconds": "60",
    "lyric": "The nest is full of nothing",
    "number": "3985",
  },
  {
    "artist": "Lorde",
    "song": "Team",
    "year": "2013",
    "seconds": "50",
    "lyric": "A hundred jewels on throats",
    "number": "3986",
  },
  {
    "artist": "Taylor Swift",
    "song": "Teardrops on My Guitar",
    "year": "2007",
    "seconds": "60",
    "lyric": "He’s the song in the car",
    "number": "3987",
  },
  {
    "artist": "NSYNC",
    "song": "Tearin’ Up My Heart",
    "year": "1997",
    "seconds": "60",
    "lyric": "I am down on my knees",
    "number": "3988",
  },
  {
    "artist": "Eric Clapton",
    "song": "Tears in Heaven",
    "year": "1992",
    "seconds": "50",
    "lyric": "Time can bend your knees",
    "number": "3989",
  },
  {
    "artist": "Mark Dinning",
    "song": "Teen Angel",
    "year": "1960",
    "seconds": "40",
    "lyric": "Can you hear me?",
    "number": "3990",
  },
  {
    "artist": "Katy Perry",
    "song": "Teenage Dream",
    "year": "2010",
    "seconds": "50",
    "lyric": "A fort out of sheets",
    "number": "3991",
  },
  {
    "artist": "Dion and the Belmonts",
    "song": "Teenager in Love",
    "year": "1959",
    "seconds": "60",
    "lyric": "I ask the stars up above",
    "number": "3992",
  },
  {
    "artist": "My Chemical Romance",
    "song": "Teenagers",
    "year": "2007",
    "seconds": "50",
    "lyric": "They sleep with a gun",
    "number": "3993",
  },
  {
    "artist": "Lady Gaga (ft. Beyoncé)",
    "song": "Telephone",
    "year": "2010",
    "seconds": "80",
    "lyric": "Text you with a drink in my hand",
    "number": "3994",
  },
  {
    "artist": "Electric Light Orchestra",
    "song": "Telephone Line",
    "year": "1977",
    "seconds": "60",
    "lyric": "Through the shadows of the night",
    "number": "3995",
  },
  {
    "artist": "Lonestar",
    "song": "Tell Her",
    "year": "2000",
    "seconds": "50",
    "lyric": "Look her in the eye",
    "number": "3996",
  },
  {
    "artist": "Billy Joel",
    "song": "Tell Her About It",
    "year": "1983",
    "seconds": "60",
    "lyric": "Let her know you need her",
    "number": "3997",
  },
  {
    "artist": "Aaron Neville",
    "song": "Tell It Like It Is",
    "year": "1966",
    "seconds": "60",
    "lyric": "I know deep down inside me",
    "number": "3998",
  },
  {
    "artist": "Ray Peterson",
    "song": "Tell Laura I Love Her",
    "year": "1960",
    "seconds": "50",
    "lyric": "His car overturned in flames",
    "number": "3999",
  },
  {
    "artist": "Travis Tritt",
    "song": "Tell Me I Was Dreaming",
    "year": "1995",
    "seconds": "60",
    "lyric": "Wiped the sleep from my eyes",
    "number": "4000",
  },
  {
    "artist": "Rufus & Chaka Khan",
    "song": "Tell Me Something Good",
    "year": "1974",
    "seconds": "40",
    "lyric": "You like me, baby",
    "number": "4001",
  },
  {
    "artist": "The Four Aces",
    "song": "Tell Me Why",
    "year": "1951",
    "seconds": "60",
    "lyric": "It is written on the wind",
    "number": "4002",
  },
  {
    "artist": "Dolly Parton, Emmylou Harris & Linda Ronstadt",
    "song": "Telling Me Lies",
    "year": "1987",
    "seconds": "80",
    "lyric": "I cover my ears, I close my eyes",
    "number": "4003",
  },
  {
    "artist": "Carrie Underwood",
    "song": "Temporary Home",
    "year": "2009",
    "seconds": "50",
    "lyric": "Little boy, six years old",
    "number": "4004",
  },
  {
    "artist": "The Grass Roots",
    "song": "Temptation Eyes",
    "year": "1971",
    "seconds": "60",
    "lyric": "Looking through my, my, my soul",
    "number": "4005",
  },
  {
    "artist": "Lee Roy Parnell",
    "song": "Tender Moment",
    "year": "1993",
    "seconds": "50",
    "lyric": "The touch of your hand",
    "number": "4006",
  },
  {
    "artist": "George Jones",
    "song": "Tender Years",
    "year": "1961",
    "seconds": "60",
    "lyric": "In your eyes, there’s a lovelight",
    "number": "4007",
  },
  {
    "artist": "Rosanne Cash",
    "song": "Tennessee Flat Top Box",
    "year": "1987",
    "seconds": "50",
    "lyric": "But give him his guitar",
    "number": "4008",
  },
  {
    "artist": "Red Foley and The Cumberland Valley Boys",
    "song": "Tennessee Saturday Night",
    "year": "1948",
    "seconds": "60",
    "lyric": "A fiddle and a cracked guitar",
    "number": "4009",
  },
  {
    "artist": "Patti Page",
    "song": "Tennessee Waltz",
    "year": "1950",
    "seconds": "50",
    "lyric": "Stole my sweetheart from me",
    "number": "4010",
  },
  {
    "artist": "George Jones",
    "song": "Tennessee Whiskey",
    "year": "1983",
    "seconds": "50",
    "lyric": "You poured out your heart",
    "number": "4011",
  },
  {
    "artist": "Bruce Springsteen",
    "song": "Tenth Avenue Freeze-Out",
    "year": "1975",
    "seconds": "40",
    "lyric": "But the sidewalk’s bright",
    "number": "4012",
  },
  {
    "artist": "Dan + Shay",
    "song": "Tequila",
    "year": "2018",
    "seconds": "60",
    "lyric": "Show up to the same bar",
    "number": "4013",
  },
  {
    "artist": "Joe Nichols",
    "song": "Tequila Makes Her Clothes Fall Off",
    "year": "2005",
    "seconds": "60",
    "lyric": "Her jacket in the bathroom stall",
    "number": "4014",
  },
  {
    "artist": "Eagles",
    "song": "Tequila Sunrise",
    "year": "1973",
    "seconds": "50",
    "lyric": "Starin’ slowly ’cross the sky",
    "number": "4015",
  },
  {
    "artist": "Rush",
    "song": "Test for Echo",
    "year": "1996",
    "seconds": "40",
    "lyric": "Miles of yellow tape",
    "number": "4016",
  },
  {
    "artist": "Hank Williams Jr.",
    "song": "Texas Women",
    "year": "1981",
    "seconds": "50",
    "lyric": "And I don’t ride bulls",
    "number": "4017",
  },
  {
    "artist": "Bone Thugs-N-Harmony",
    "song": "Tha Crossroads",
    "year": "1996",
    "seconds": "70",
    "lyric": "The devil’s all up in my face",
    "number": "4018",
  },
  {
    "artist": "Mariah Carey (ft. Joe and 98 Degrees)",
    "song": "Thank God I Found You",
    "year": "2000",
    "seconds": "40",
    "lyric": "You brought the sunlight",
    "number": "4019",
  },
  {
    "artist": "John Denver",
    "song": "Thank God I’m a Country Boy",
    "year": "1975",
    "seconds": "50",
    "lyric": "And working on the farm",
    "number": "4020",
  },
  {
    "artist": "Maurice Chevalier",
    "song": "Thank Heaven For Little Girls",
    "year": "1958",
    "seconds": "60",
    "lyric": "Send you crashing through the ceiling",
    "number": "4021",
  },
  {
    "artist": "Neil Diamond",
    "song": "Thank the Lord for the Night Time",
    "year": "1967",
    "seconds": "50",
    "lyric": "It’s a time to groove",
    "number": "4022",
  },
  {
    "artist": "Ariana Grande",
    "song": "Thank U, Next",
    "year": "2018",
    "seconds": "50",
    "lyric": "I’ll walk down the aisle",
    "number": "4023",
  },
  {
    "artist": "Sly & the Family Stone",
    "song": "Thank You (Falettinme Be Mice Elf Agin)",
    "year": "1970",
    "seconds": "40",
    "lyric": "We begin to wrestle",
    "number": "4024",
  },
  {
    "artist": "Brooks & Dunn",
    "song": "That Ain’t No Way to Go",
    "year": "1994",
    "seconds": "50",
    "lyric": "Lipstick letters across the mirror",
    "number": "4025",
  },
  {
    "artist": "Shania Twain",
    "song": "That Don’t Impress Me Much",
    "year": "1998",
    "seconds": "70",
    "lyric": "Warm in the middle of the night",
    "number": "4026",
  },
  {
    "artist": "The Isley Brothers",
    "song": "That Lady",
    "year": "1973",
    "seconds": "70",
    "lyric": "But her heart is made of stone",
    "number": "4027",
  },
  {
    "artist": "Buddy Holly",
    "song": "That’ll Be the Day",
    "year": "1957",
    "seconds": "80",
    "lyric": "Your hugs and kisses and your money too",
    "number": "4028",
  },
  {
    "artist": "Genesis",
    "song": "That’s All",
    "year": "1983",
    "seconds": "60",
    "lyric": "Running around, staying out all night",
    "number": "4029",
  },
  {
    "artist": "Dean Martin",
    "song": "That’s Amore",
    "year": "1953",
    "seconds": "60",
    "lyric": "When the stars make you drool",
    "number": "4030",
  },
  {
    "artist": "Kevin Denney",
    "song": "That’s Just Jesse",
    "year": "2002",
    "seconds": "50",
    "lyric": "And in a crowded elevator",
    "number": "4031",
  },
  {
    "artist": "Fifth Harmony",
    "song": "That’s My Girl",
    "year": "2016",
    "seconds": "50",
    "lyric": "Some dirt on your shoulder",
    "number": "4032",
  },
  {
    "artist": "Luke Bryan",
    "song": "That’s My Kind of Night",
    "year": "2013",
    "seconds": "60",
    "lyric": "Hang your t-shirt on a limb",
    "number": "4033",
  },
  {
    "artist": "The Everly Brothers",
    "song": "That’s Old Fashioned",
    "year": "1962",
    "seconds": "50",
    "lyric": "And we carve our initials",
    "number": "4034",
  },
  {
    "artist": "Don Williams",
    "song": "That’s the Thing About Love",
    "year": "1984",
    "seconds": "60",
    "lyric": "You’re a king on a throne",
    "number": "4035",
  },
  {
    "artist": "Jo Dee Messina",
    "song": "That’s the Way",
    "year": "2000",
    "seconds": "60",
    "lyric": "An easy ride on the merry-go-round",
    "number": "4036",
  },
  {
    "artist": "KC & the Sunshine Band",
    "song": "That’s the Way (I Like It)",
    "year": "1975",
    "seconds": "60",
    "lyric": "You whisper sweet in my ear",
    "number": "4037",
  },
  {
    "artist": "Céline Dion",
    "song": "That’s the Way It Is",
    "year": "1999",
    "seconds": "50",
    "lyric": "I can read your mind",
    "number": "4038",
  },
  {
    "artist": "Carly Simon",
    "song": "That’s the Way I’ve Always Heard It Should Be",
    "year": "1971",
    "seconds": "50",
    "lyric": "Two birds through the clouds",
    "number": "4039",
  },
  {
    "artist": "Janet Jackson",
    "song": "That’s the Way Love Goes",
    "year": "1993",
    "seconds": "50",
    "lyric": "A moth to a flame",
    "number": "4040",
  },
  {
    "artist": "Marvin Gaye",
    "song": "That’s the Way Love Is",
    "year": "1969",
    "seconds": "70",
    "lyric": "Your heart is carryin’ a heavy load",
    "number": "4041",
  },
  {
    "artist": "Dionne Warwick and Friends",
    "song": "That’s What Friends Are For",
    "year": "1985",
    "seconds": "60",
    "lyric": "For good times and bad times",
    "number": "4042",
  },
  {
    "artist": "Bruno Mars",
    "song": "That’s What I Like",
    "year": "2016",
    "seconds": "50",
    "lyric": "Jump in the Cadillac, girl",
    "number": "4043",
  },
  {
    "artist": "Craig Morgan",
    "song": "That’s What I Love About Sunday",
    "year": "2005",
    "seconds": "50",
    "lyric": "Likes to sing off key",
    "number": "4044",
  },
  {
    "artist": "Brooks & Dunn",
    "song": "That’s What It’s All About",
    "year": "2004",
    "seconds": "70",
    "lyric": "Smiles and says, “I love you Dad”",
    "number": "4045",
  },
  {
    "artist": "Kenny Chesney",
    "song": "That’s Why I’m Here",
    "year": "1998",
    "seconds": "50",
    "lyric": "The devil takes your hand",
    "number": "4046",
  },
  {
    "artist": "Simon & Garfunkel",
    "song": "The 59th Street Bridge Song (Feelin’ Groovy)",
    "year": "1971",
    "seconds": "60",
    "lyric": "Drop all its petals on me",
    "number": "4047",
  },
  {
    "artist": "The Hollies",
    "song": "The Air That I Breathe",
    "year": "1974",
    "seconds": "40",
    "lyric": "No cigarettes, no sleep",
    "number": "4048",
  },
  {
    "artist": "Bruce Springsteen",
    "song": "The Angel",
    "year": "1973",
    "seconds": "60",
    "lyric": "Baseball cards poked in his spokes",
    "number": "4049",
  },
  {
    "artist": "Dinah Shore",
    "song": "The Anniversary Song",
    "year": "1947",
    "seconds": "40",
    "lyric": "Two hearts gently beating",
    "number": "4050",
  },
  {
    "artist": "Blake Shelton",
    "song": "The Baby",
    "year": "2002",
    "seconds": "60",
    "lyric": "A shrimp boat in the Bayou",
    "number": "4051",
  },
  {
    "artist": "Lester Flatt & Earl Scruggs",
    "song": "The Ballad of Jed Clampett",
    "year": "1963",
    "seconds": "60",
    "lyric": "So they loaded up the truck",
    "number": "4052",
  },
  {
    "artist": "Johnny Cash",
    "song": "The Ballad of Ira Hayes",
    "year": "1964",
    "seconds": "50",
    "lyric": "Throw a dog a bone",
    "number": "4053",
  },
  {
    "artist": "Harry Belafonte",
    "song": "The Banana Boat Song",
    "year": "1956",
    "seconds": "70",
    "lyric": "Six foot, seven foot, eight foot bunch",
    "number": "4054",
  },
  {
    "artist": "Dolly Parton",
    "song": "The Bargain Store",
    "year": "1975",
    "seconds": "50",
    "lyric": "Love is all you need",
    "number": "4055",
  },
  {
    "artist": "Johnny Horton",
    "song": "The Battle of New Orleans",
    "year": "1959",
    "seconds": "50",
    "lyric": "We took a little bacon",
    "number": "4056",
  },
  {
    "artist": "Sonny & Cher",
    "song": "The Beat Goes On",
    "year": "1967",
    "seconds": "60",
    "lyric": "Chasing girls to get a kiss",
    "number": "4057",
  },
  {
    "artist": "George Strait",
    "song": "The Best Day",
    "year": "2000",
    "seconds": "60",
    "lyric": "Fishin’ poles, a cooler of Cokes",
    "number": "4058",
  },
  {
    "artist": "Styx",
    "song": "The Best of Times",
    "year": "1981",
    "seconds": "50",
    "lyric": "A boat against the tide",
    "number": "4059",
  },
  {
    "artist": "Luther Vandross & Janet Jackson",
    "song": "The Best Things in Life Are Free",
    "year": "1997",
    "seconds": "60",
    "lyric": "I know you are my baby",
    "number": "4060",
  },
  {
    "artist": "George Strait",
    "song": "The Big One",
    "year": "1994",
    "seconds": "60",
    "lyric": "On the Richter scale of romance",
    "number": "4061",
  },
  {
    "artist": "Jerry Reed",
    "song": "The Bird",
    "year": "1982",
    "seconds": "40",
    "lyric": "He counted my money",
    "number": "4062",
  },
  {
    "artist": "The Used",
    "song": "The Bird and the Worm",
    "year": "2007",
    "seconds": "60",
    "lyric": "Holding his breath half to death",
    "number": "4063",
  },
  {
    "artist": "Jewel Akens",
    "song": "The Birds and the Bees",
    "year": "1965",
    "seconds": "70",
    "lyric": "I look into your big brown eyes",
    "number": "4064",
  },
  {
    "artist": "Elton John",
    "song": "The Bitch is Back",
    "year": "1974",
    "seconds": "60",
    "lyric": "But the fever’s gonna catch you",
    "number": "4065",
  },
  {
    "artist": "Maren Morris",
    "song": "The Bones",
    "year": "2019",
    "seconds": "40",
    "lyric": "The glass could shatter",
    "number": "4066",
  },
  {
    "artist": "Simon & Garfunkel",
    "song": "The Boxer",
    "year": "1969",
    "seconds": "60",
    "lyric": "I come looking for a job",
    "number": "4067",
  },
  {
    "artist": "Monica, Brandy Norwood",
    "song": "The Boy is Mine",
    "year": "1998",
    "seconds": "70",
    "lyric": "Let’s sit and talk face to face",
    "number": "4068",
  },
  {
    "artist": "Thin Lizzy",
    "song": "The Boys are Back in Town",
    "year": "1976",
    "seconds": "70",
    "lyric": "She was cool, she was red hot",
    "number": "4069",
  },
  {
    "artist": "Kenny Chesney",
    "song": "The Boys of Fall",
    "year": "2010",
    "seconds": "50",
    "lyric": "Helmet, cleats and shoulder pads",
    "number": "4070",
  },
  {
    "artist": "Don Henley",
    "song": "The Boys of Summer",
    "year": "1984",
    "seconds": "70",
    "lyric": "Your brown skin shinin’ in the sun",
    "number": "4071",
  },
  {
    "artist": "The Ataris",
    "song": "The Boys of Summer",
    "year": "2003",
    "seconds": "50",
    "lyric": "I’m driving by your house",
    "number": "4072",
  },
  {
    "artist": "Sammy Davis Jr.",
    "song": "The Candy Man",
    "year": "1972",
    "seconds": "60",
    "lyric": "You can even eat the dishes",
    "number": "4073",
  },
  {
    "artist": "Porter Wagoner",
    "song": "The Carroll County Accident",
    "year": "1968",
    "seconds": "60",
    "lyric": "The bloody seats, the broken glass",
    "number": "4074",
  },
  {
    "artist": "Fleetwood Mac",
    "song": "The Chain",
    "year": "1977",
    "seconds": "50",
    "lyric": "Listen to the wind blow",
    "number": "4075",
  },
  {
    "artist": "George Strait",
    "song": "The Chair",
    "year": "1985",
    "seconds": "60",
    "lyric": "Could I buy you a drink?",
    "number": "4076",
  },
  {
    "artist": "George Strait",
    "song": "The Chill of an Early Fall",
    "year": "1991",
    "seconds": "40",
    "lyric": "A storm coming on",
    "number": "4077",
  },
  {
    "artist": "Nat King Cole",
    "song": "The Christmas Song",
    "year": "1946",
    "seconds": "50",
    "lyric": "A turkey and some mistletoe",
    "number": "4078",
  },
  {
    "artist": "Joni Mitchell",
    "song": "The Circle Game",
    "year": "1970",
    "seconds": "50",
    "lyric": "Cartwheels turn to car wheels",
    "number": "4079",
  },
  {
    "artist": "War",
    "song": "The Cisco Kid",
    "year": "1973",
    "seconds": "40",
    "lyric": "Eat the salted peanuts",
    "number": "4080",
  },
  {
    "artist": "Roberta Flack & Donny Hathaway",
    "song": "The Closer I Get to You",
    "year": "1978",
    "seconds": "50",
    "lyric": "Lying here next to you",
    "number": "4081",
  },
  {
    "artist": "Conway Twitty",
    "song": "The Clown",
    "year": "1981",
    "seconds": "50",
    "lyric": "Walkin’ on a high wire",
    "number": "4082",
  },
  {
    "artist": "Porter Wagoner",
    "song": "The Cold Hard Facts of Life",
    "year": "1967",
    "seconds": "50",
    "lyric": "Pink champagne by candle light",
    "number": "4083",
  },
  {
    "artist": "Dr. Hook & the Medicine Show",
    "song": "The Cover of the Rolling Stone",
    "year": "1973",
    "seconds": "60",
    "lyric": "Buy five copies for my mother",
    "number": "4084",
  },
  {
    "artist": "Tim McGraw",
    "song": "The Cowboy in Me",
    "year": "2001",
    "seconds": "60",
    "lyric": "I still wake up fightin’ mad",
    "number": "4085",
  },
  {
    "artist": "Marty Robbins",
    "song": "The Cowboy in the Continental Suit",
    "year": "1964",
    "seconds": "60",
    "lyric": "He blew himself a smoke ring",
    "number": "4086",
  },
  {
    "artist": "Garth Brooks",
    "song": "The Dance",
    "year": "1990",
    "seconds": "40",
    "lyric": "Wasn’t I a king?",
    "number": "4087",
  },
  {
    "artist": "Charlie Daniels Band",
    "song": "The Devil Went Down to Georgia",
    "year": "1979",
    "seconds": "60",
    "lyric": "I’ll bet a fiddle of gold",
    "number": "4088",
  },
  {
    "artist": "Doobie Brothers",
    "song": "The Doctor",
    "year": "1989",
    "seconds": "40",
    "lyric": "To shake your blues",
    "number": "4089",
  },
  {
    "artist": "George Jones",
    "song": "The Door",
    "year": "1974",
    "seconds": "40",
    "lyric": "A thousand bombs exploding",
    "number": "4090",
  },
  {
    "artist": "Dave & Sugar",
    "song": "The Door is Always Open",
    "year": "1976",
    "seconds": "60",
    "lyric": "Saw your picture in the paper",
    "number": "4091",
  },
  {
    "artist": "Michael Peter Smith",
    "song": "The Dutchman",
    "year": "1963",
    "seconds": "60",
    "lyric": "The tulips bloom beneath the snow",
    "number": "4092",
  },
  {
    "artist": "Lady Gaga",
    "song": "The Edge of Glory",
    "year": "2011",
    "seconds": "60",
    "lyric": "I’ll be dancing in the flames",
    "number": "4093",
  },
  {
    "artist": "Gladys Knight & the Pips",
    "song": "The End of Our Road",
    "year": "1968",
    "seconds": "60",
    "lyric": "Sick and tired of your stuff",
    "number": "4094",
  },
  {
    "artist": "Skeeter Davis",
    "song": "The End of the World",
    "year": "1963",
    "seconds": "60",
    "lyric": "I wake up in the morning",
    "number": "4095",
  },
  {
    "artist": "Three Dog Night",
    "song": "The Family of Man",
    "year": "1972",
    "seconds": "40",
    "lyric": "Prayer books and meetings",
    "number": "4096",
  },
  {
    "artist": "Keith Urban (ft. Carrie Underwood)",
    "song": "The Fighter",
    "year": "2017",
    "seconds": "40",
    "lyric": "I’ll hold you tighter",
    "number": "4097",
  },
  {
    "artist": "Europe",
    "song": "The Final Countdown",
    "year": "1986",
    "seconds": "60",
    "lyric": "So many light years to go",
    "number": "4098",
  },
  {
    "artist": "Bob Seger",
    "song": "The Fire Down Below",
    "year": "1976",
    "seconds": "50",
    "lyric": "She’s steppin’ right on time",
    "number": "4099",
  },
  {
    "artist": "Cat Stevens",
    "song": "The First Cut is the Deepest",
    "year": "1967",
    "seconds": "60",
    "lyric": "To help me dry the tears",
    "number": "4100",
  },
  {
    "artist": "Roberta Flack",
    "song": "The First Time Ever I Saw Your Face",
    "year": "1972",
    "seconds": "60",
    "lyric": "The sun rose in your eyes",
    "number": "4101",
  },
  {
    "artist": "The Clancy Brothers & Tommy Makem",
    "song": "The Foggy Dew",
    "year": "1956",
    "seconds": "70",
    "lyric": "They hung out the flag of war",
    "number": "4102",
  },
  {
    "artist": "The Beatles",
    "song": "The Fool on the Hill",
    "year": "1967",
    "seconds": "60",
    "lyric": "And the eyes in his head",
    "number": "4103",
  },
  {
    "artist": "The Verve Pipe",
    "song": "The Freshmen",
    "year": "1997",
    "seconds": "50",
    "lyric": "We fell through the ice",
    "number": "4104",
  },
  {
    "artist": "Timbuk 3",
    "song": "The Future’s So Bright, I Gotta Wear Shades",
    "year": "1986",
    "seconds": "40",
    "lyric": "He wears dark glasses",
    "number": "4105",
  },
  {
    "artist": "Kenny Rogers",
    "song": "The Gambler",
    "year": "1978",
    "seconds": "60",
    "lyric": "And asked me for a light",
    "number": "4106",
  },
  {
    "artist": "Santana (ft. Michelle Branch)",
    "song": "The Game of Love",
    "year": "2002",
    "seconds": "50",
    "lyric": "I’m crying outside the door",
    "number": "4107",
  },
  {
    "artist": "Conway Twitty",
    "song": "The Games That Daddies Play",
    "year": "1976",
    "seconds": "50",
    "lyric": "And maybe camp out overnight",
    "number": "4108",
  },
  {
    "artist": "Bruce Springsteen",
    "song": "The Ghost of Tom Joad",
    "year": "1995",
    "seconds": "50",
    "lyric": "A campfire under the bridge",
    "number": "4109",
  },
  {
    "artist": "Stan Getz & Astrud Gilberto",
    "song": "The Girl from Ipanema",
    "year": "1964",
    "seconds": "70",
    "lyric": "Tall and tan and young and lovely",
    "number": "4110",
  },
  {
    "artist": "Michael Jackson & Paul McCartney",
    "song": "The Girl is Mine",
    "year": "1982",
    "seconds": "50",
    "lyric": "Roses and your silly dreams",
    "number": "4111",
  },
  {
    "artist": "The Mills Brothers",
    "song": "The Glow Worm",
    "year": "1952",
    "seconds": "60",
    "lyric": "Light up you little ol’ bug",
    "number": "4112",
  },
  {
    "artist": "Kenny Chesney",
    "song": "The Good Stuff",
    "year": "2002",
    "seconds": "60",
    "lyric": "Droppin’ the ring in the spaghetti",
    "number": "4113",
  },
  {
    "artist": "George Jones",
    "song": "The Grand Tour",
    "year": "1974",
    "seconds": "60",
    "lyric": "Step right up, come on in",
    "number": "4114",
  },
  {
    "artist": "The Platters",
    "song": "The Great Pretender",
    "year": "1956",
    "seconds": "60",
    "lyric": "Laughin’ and gay like a clown",
    "number": "4115",
  },
  {
    "artist": "Sia (ft. Kendrick Lamar)",
    "song": "The Greatest",
    "year": "2016",
    "seconds": "60",
    "lyric": "Running through the waves of love",
    "number": "4116",
  },
  {
    "artist": "Bread",
    "song": "The Guitar Man",
    "year": "1972",
    "seconds": "50",
    "lyric": "And you see his face",
    "number": "4117",
  },
  {
    "artist": "Dinah Shore",
    "song": "The Gypsy",
    "year": "1946",
    "seconds": "60",
    "lyric": "She can look in the future",
    "number": "4118",
  },
  {
    "artist": "The Supremes",
    "song": "The Happening",
    "year": "1967",
    "seconds": "60",
    "lyric": "I saw my dreams fall apart",
    "number": "4119",
  },
  {
    "artist": "Donna Fargo",
    "song": "The Happiest Girl in the Whole U.S.A.",
    "year": "1972",
    "seconds": "80",
    "lyric": "You make the coffee, I’ll make the bed",
    "number": "4120",
  },
  {
    "artist": "Jimmy Cliff",
    "song": "The Harder They Come",
    "year": "1972",
    "seconds": "60",
    "lyric": "A free man in my grave",
    "number": "4121",
  },
  {
    "artist": "98 Degrees",
    "song": "The Hardest Thing",
    "year": "1998",
    "seconds": "40",
    "lyric": "My hands are tied",
    "number": "4122",
  },
  {
    "artist": "Reba McEntire",
    "song": "The Heart Won’t Lie",
    "year": "1993",
    "seconds": "70",
    "lyric": "I tried to pick up the phone",
    "number": "4123",
  },
  {
    "artist": "The Animals",
    "song": "The House of the Rising Sun",
    "year": "1964",
    "seconds": "60",
    "lyric": "She sewed my new blue jeans",
    "number": "4124",
  },
  {
    "artist": "David Bowie",
    "song": "The Jean Genie",
    "year": "1972",
    "seconds": "50",
    "lyric": "And sleeps in the capsule",
    "number": "4125",
  },
  {
    "artist": "Miranda Lambert",
    "song": "The House That Built Me",
    "year": "2010",
    "seconds": "50",
    "lyric": "Handprints on the front steps",
    "number": "4126",
  },
  {
    "artist": "Brandi Carlile",
    "song": "The Joke",
    "year": "2017",
    "seconds": "60",
    "lyric": "Carrying your baby on your back",
    "number": "4127",
  },
  {
    "artist": "The Steve Miller Band",
    "song": "The Joker",
    "year": "1973",
    "seconds": "70",
    "lyric": "I play my music in the sun",
    "number": "4128",
  },
  {
    "artist": "Buck Owens",
    "song": "The Kansas City Song",
    "year": "1970",
    "seconds": "50",
    "lyric": "With my two open arms",
    "number": "4129",
  },
  {
    "artist": "Siouxsie & the Banshees",
    "song": "The Killing Jar",
    "year": "1988",
    "seconds": "70",
    "lyric": "A glass hand cuts through the water",
    "number": "4130",
  },
  {
    "artist": "Elton John",
    "song": "The Last Song",
    "year": "1992",
    "seconds": "60",
    "lyric": "Between a father and his son",
    "number": "4131",
  },
  {
    "artist": "Rolling Stones",
    "song": "The Last Time",
    "year": "1965",
    "seconds": "50",
    "lyric": "Well I told you once",
    "number": "4132",
  },
  {
    "artist": "Engelbert Humperdinck",
    "song": "The Last Waltz",
    "year": "1967",
    "seconds": "60",
    "lyric": "I fell in love with you",
    "number": "4133",
  },
  {
    "artist": "Tame Impala",
    "song": "The Less I Know the Better",
    "year": "2015",
    "seconds": "60",
    "lyric": "Your eyes turn away from mine",
    "number": "4134",
  },
  {
    "artist": "Box Tops",
    "song": "The Letter",
    "year": "1967",
    "seconds": "50",
    "lyric": "A ticket for an aeroplane",
    "number": "4135",
  },
  {
    "artist": "John Michael Montgomery",
    "song": "The Little Girl",
    "year": "2000",
    "seconds": "60",
    "lyric": "And sit there on the couch",
    "number": "4136",
  },
  {
    "artist": "Jan and Dean",
    "song": "The Little Old Lady from Pasadena",
    "year": "1964",
    "seconds": "40",
    "lyric": "She drives real fast",
    "number": "4137",
  },
  {
    "artist": "Little Eva",
    "song": "The Loco-Motion",
    "year": "1962",
    "seconds": "40",
    "lyric": "My little baby sister",
    "number": "4138",
  },
  {
    "artist": "The Beatles",
    "song": "The Long and Winding Road",
    "year": "1970",
    "seconds": "50",
    "lyric": "You left me standing here",
    "number": "4139",
  },
  {
    "artist": "Brooks & Dunn",
    "song": "The Long Goodbye",
    "year": "2001",
    "seconds": "40",
    "lyric": "Running on a carousel",
    "number": "4140",
  },
  {
    "artist": "Eagles",
    "song": "The Long Run",
    "year": "1979",
    "seconds": "60",
    "lyric": "Our love is a strong one",
    "number": "4141",
  },
  {
    "artist": "Dusty Springfield",
    "song": "The Look of Love",
    "year": "1967",
    "seconds": "70",
    "lyric": "I can hardly wait to hold you",
    "number": "4142",
  },
  {
    "artist": "Jackson 5",
    "song": "The Love You Save",
    "year": "1970",
    "seconds": "60",
    "lyric": "We played tag in grade school",
    "number": "4143",
  },
  {
    "artist": "Taylor Swift",
    "song": "The Man",
    "year": "2020",
    "seconds": "50",
    "lyric": "I’m so sick of running",
    "number": "4144",
  },
  {
    "artist": "The Killers",
    "song": "The Man",
    "year": "2017",
    "seconds": "40",
    "lyric": "Money in the bank",
    "number": "4145",
  },
  {
    "artist": "Chris Young",
    "song": "The Man I Want to Be",
    "year": "2009",
    "seconds": "50",
    "lyric": "The mirror likes to see",
    "number": "4146",
  },
  {
    "artist": "George Strait",
    "song": "The Man in Love With You",
    "year": "1994",
    "seconds": "50",
    "lyric": "You can hold on to",
    "number": "4147",
  },
  {
    "artist": "Gene Pitney",
    "song": "(The Man Who Shot) Liberty Valance",
    "year": "1962",
    "seconds": "50",
    "lyric": "The point of a gun",
    "number": "4148",
  },
  {
    "artist": "Johnny Cash",
    "song": "The Matador",
    "year": "1963",
    "seconds": "40",
    "lyric": "Walk proud and slow",
    "number": "4149",
  },
  {
    "artist": "Zedd, Maren Morris & Grey",
    "song": "The Middle",
    "year": "2018",
    "seconds": "50",
    "lyric": "Step back for a minute",
    "number": "4150",
  },
  {
    "artist": "Manfred Mann",
    "song": "The Mighty Quinn",
    "year": "1968",
    "seconds": "50",
    "lyric": "Everybody’s gonna jump for joy",
    "number": "4151",
  },
  {
    "artist": "Eminem (feat. Rihanna)",
    "song": "The Monster",
    "year": "2013",
    "seconds": "40",
    "lyric": "Stop holding your breath",
    "number": "4152",
  },
  {
    "artist": "Maureen McGovern",
    "song": "The Morning After",
    "year": "1973",
    "seconds": "50",
    "lyric": "Hold on through the night",
    "number": "4153",
  },
  {
    "artist": "Charlie Rich",
    "song": "The Most Beautiful Girl",
    "year": "1973",
    "seconds": "60",
    "lyric": "Tell her I need my baby",
    "number": "4154",
  },
  {
    "artist": "Prince",
    "song": "The Most Beautiful Girl in the World",
    "year": "1994",
    "seconds": "60",
    "lyric": "One by one from the sky",
    "number": "4155",
  },
  {
    "artist": "Rod Stewart (ft. The Temptations)",
    "song": "The Motown Song",
    "year": "1991",
    "seconds": "60",
    "lyric": "Put the speakers in the window",
    "number": "4156",
  },
  {
    "artist": "Shirley Ellis",
    "song": "The Name Game",
    "year": "1965",
    "seconds": "50",
    "lyric": "I can make a rhyme",
    "number": "4157",
  },
  {
    "artist": "Cliff Richard and the Shadows",
    "song": "The Next Time",
    "year": "1962",
    "seconds": "50",
    "lyric": "I’m a fool to weep",
    "number": "4158",
  },
  {
    "artist": "Peter Cetera (with Amy Grant)",
    "song": "The Next Time I Fall",
    "year": "1986",
    "seconds": "60",
    "lyric": "My heart up on the shelf",
    "number": "4159",
  },
  {
    "artist": "Paper Lace",
    "song": "The Night Chicago Died",
    "year": "1974",
    "seconds": "40",
    "lyric": "Shouting in the street",
    "number": "4160",
  },
  {
    "artist": "Vicki Lawrence",
    "song": "The Night the Lights Went Out in Georgia",
    "year": "1973",
    "seconds": "70",
    "lyric": "He got mad and he saw red",
    "number": "4161",
  },
  {
    "artist": "Joan Baez",
    "song": "The Night They Drove Old Dixie Down",
    "year": "1971",
    "seconds": "50",
    "lyric": "All the bells were ringin’",
    "number": "4162",
  },
  {
    "artist": "Shirley Ellis",
    "song": "The Nitty Gritty",
    "year": "1963",
    "seconds": "50",
    "lyric": "Sink down into your soul",
    "number": "4163",
  },
  {
    "artist": "Led Zeppelin",
    "song": "The Ocean",
    "year": "1973",
    "seconds": "80",
    "lyric": "Singing in the sunshine, laughing in the rain",
    "number": "4164",
  },
  {
    "artist": "Elton John",
    "song": "The One",
    "year": "1992",
    "seconds": "50",
    "lyric": "Fire flying from your hands",
    "number": "4165",
  },
  {
    "artist": "George Jones",
    "song": "The One I Loved Back Then (The Corvette Song)",
    "year": "1985",
    "seconds": "60",
    "lyric": "The old man scratched his head",
    "number": "4166",
  },
  {
    "artist": "Johnny Cash",
    "song": "The One on the Right Is on the Left",
    "year": "1966",
    "seconds": "40",
    "lyric": "Play your banjo well",
    "number": "4167",
  },
  {
    "artist": "Air Supply",
    "song": "The One That You Love",
    "year": "1981",
    "seconds": "50",
    "lyric": "Hold me in your arms",
    "number": "4168",
  },
  {
    "artist": "Justin Moore",
    "song": "The Ones That Didn’t Make It Back Home",
    "year": "2019",
    "seconds": "60",
    "lyric": "Two uniforms and her heart stopped",
    "number": "4169",
  },
  {
    "artist": "Bryan Adams",
    "song": "The Only Thing That Looks Good On Me Is You",
    "year": "1996",
    "seconds": "50",
    "lyric": "Yeah, we stick like glue",
    "number": "4170",
  },
  {
    "artist": "Jason Aldean, Luke Bryan, Eric Church",
    "song": "The Only Way I Know",
    "year": "2012",
    "seconds": "40",
    "lyric": "Dig a little deeper",
    "number": "4171",
  },
  {
    "artist": "Aerosmith",
    "song": "The Other Side",
    "year": "1990",
    "seconds": "70",
    "lyric": "The devil in the deep blue sea",
    "number": "4172",
  },
  {
    "artist": "Crispian St. Peters",
    "song": "The Pied Piper",
    "year": "1966",
    "seconds": "70",
    "lyric": "And I’ll show you where it’s at",
    "number": "4173",
  },
  {
    "artist": "Céline Dion",
    "song": "The Power of Love",
    "year": "1993",
    "seconds": "50",
    "lyric": "And you are my man",
    "number": "4174",
  },
  {
    "artist": "Huey Lewis & the News",
    "song": "The Power of Love",
    "year": "1985",
    "seconds": "70",
    "lyric": "No credit card to ride this train",
    "number": "4175",
  },
  {
    "artist": "Foo Fighters",
    "song": "The Pretender",
    "year": "2007",
    "seconds": "40",
    "lyric": "Send in your skeletons",
    "number": "4176",
  },
  {
    "artist": "Bruce Springsteen",
    "song": "The Promised Land",
    "year": "1978",
    "seconds": "50",
    "lyric": "Rising from the desert floor",
    "number": "4177",
  },
  {
    "artist": "Sheb Wooley",
    "song": "The Purple People Eater",
    "year": "1958",
    "seconds": "60",
    "lyric": "Through the horn in his head",
    "number": "4178",
  },
  {
    "artist": "George Jones",
    "song": "The Race is On",
    "year": "1964",
    "seconds": "60",
    "lyric": "Aching and pain in my heart",
    "number": "4179",
  },
  {
    "artist": "Freddy Fender",
    "song": "The Rains Came",
    "year": "1977",
    "seconds": "40",
    "lyric": "My pillow’s soaking wet",
    "number": "4180",
  },
  {
    "artist": "The Jaggerz",
    "song": "The Rapper",
    "year": "1970",
    "seconds": "50",
    "lyric": "For some coffee or tea",
    "number": "4181",
  },
  {
    "artist": "Duran Duran",
    "song": "The Reflex",
    "year": "1984",
    "seconds": "60",
    "lyric": "But I’m dancing on the valentine",
    "number": "4182",
  },
  {
    "artist": "Bruce Springsteen",
    "song": "The Rising",
    "year": "2002",
    "seconds": "40",
    "lyric": "Like a catfish dancing’",
    "number": "4183",
  },
  {
    "artist": "Bruce Springsteen",
    "song": "The River",
    "year": "1981",
    "seconds": "70",
    "lyric": "Tan and wet down at the reservoir",
    "number": "4184",
  },
  {
    "artist": "Bette Midler",
    "song": "The Rose",
    "year": "1980",
    "seconds": "50",
    "lyric": "Far beneath the bitter snows",
    "number": "4185",
  },
  {
    "artist": "The Spinners",
    "song": "The Rubberband Man",
    "year": "1976",
    "seconds": "60",
    "lyric": "Hand me down my walkin’ cane",
    "number": "4186",
  },
  {
    "artist": "Men Without Hats",
    "song": "The Safety Dance",
    "year": "1983",
    "seconds": "60",
    "lyric": "From our hats to our feet",
    "number": "4187",
  },
  {
    "artist": "Green Day and U2",
    "song": "The Saints Are Coming",
    "year": "2006",
    "seconds": "50",
    "lyric": "I cried to my daddy",
    "number": "4188",
  },
  {
    "artist": "Marty Robbins",
    "song": "The Shoe Goes on the Other Foot Tonight",
    "year": "1966",
    "seconds": "70",
    "lyric": "I’ll push my heartaches out of sight",
    "number": "4189",
  },
  {
    "artist": "Clint Black",
    "song": "The Shoes You’re Wearing",
    "year": "1998",
    "seconds": "50",
    "lyric": "Before they hit the ground",
    "number": "4190",
  },
  {
    "artist": "Betty Everett",
    "song": "The Shoop Shoop Song (It’s in his Kiss)",
    "year": "1964",
    "seconds": "40",
    "lyric": "And squeeze him tight",
    "number": "4191",
  },
  {
    "artist": "Tennessee Ernie",
    "song": "The Shotgun Boogie",
    "year": "1951",
    "seconds": "60",
    "lyric": "Rabbits are jumpin’ in the grass",
    "number": "4192",
  },
  {
    "artist": "Leo Sayer",
    "song": "The Show Must Go On",
    "year": "1973",
    "seconds": "40",
    "lyric": "Big cigars, gigantic cars",
    "number": "4193",
  },
  {
    "artist": "Queen",
    "song": "The Show Must Go On",
    "year": "1991",
    "seconds": "60",
    "lyric": "Behind the curtain, in the pantomime",
    "number": "4194",
  },
  {
    "artist": "The 1975",
    "song": "The Sound",
    "year": "2016",
    "seconds": "70",
    "lyric": "I know the sound of your heart",
    "number": "4195",
  },
  {
    "artist": "Paul Simon",
    "song": "The Sound of Silence",
    "year": "1965",
    "seconds": "40",
    "lyric": "Narrow streets of cobblestone",
    "number": "4196",
  },
  {
    "artist": "Bessie Smith",
    "song": "The St. Louis Blues",
    "year": "1925",
    "seconds": "70",
    "lyric": "To see the evening sun go down",
    "number": "4197",
  },
  {
    "artist": "Marty Robbins",
    "song": "The Story of My Life",
    "year": "1957",
    "seconds": "60",
    "lyric": "The night your lips met mine",
    "number": "4198",
  },
  {
    "artist": "Taylor Swift",
    "song": "The Story of Us",
    "year": "2011",
    "seconds": "50",
    "lyric": "And I’m dying to know",
    "number": "4199",
  },
  {
    "artist": "Ray Stevens",
    "song": "The Streak",
    "year": "1974",
    "seconds": "50",
    "lyric": "Fastest thing on two feet",
    "number": "4200",
  },
  {
    "artist": "Billy Squier",
    "song": "The Stroke",
    "year": "1981",
    "seconds": "50",
    "lyric": "You make my backbone slide",
    "number": "4201",
  },
  {
    "artist": "Arcade Fire",
    "song": "The Suburbs",
    "year": "2010",
    "seconds": "60",
    "lyric": "Grab your mother’s keys, we’re leavin’",
    "number": "4202",
  },
  {
    "artist": "Sade",
    "song": "The Sweetest Taboo",
    "year": "1985",
    "seconds": "60",
    "lyric": "I’d stand out in the rain",
    "number": "4203",
  },
  {
    "artist": "10cc",
    "song": "The Things We Do For Love",
    "year": "1977",
    "seconds": "70",
    "lyric": "Walking in the rain and the snow",
    "number": "4204",
  },
  {
    "artist": "The Browns",
    "song": "The Three Bells",
    "year": "1959",
    "seconds": "40",
    "lyric": "Chapel bells were ringing",
    "number": "4205",
  },
  {
    "artist": "B.B. King",
    "song": "The Thrill is Gone",
    "year": "1970",
    "seconds": "60",
    "lyric": "I know I’ll be over it",
    "number": "4206",
  },
  {
    "artist": "Garth Brooks",
    "song": "The Thunder Rolls",
    "year": "1990",
    "seconds": "40",
    "lyric": "Raindrops on the windshield",
    "number": "4207",
  },
  {
    "artist": "Blondie",
    "song": "The Tide is High",
    "year": "1980",
    "seconds": "60",
    "lyric": "I’m gonna be you’re number one",
    "number": "4208",
  },
  {
    "artist": "The Kingston Trio",
    "song": "The Tijuana Jail",
    "year": "1959",
    "seconds": "40",
    "lyric": "I was shootin’ dice",
    "number": "4209",
  },
  {
    "artist": "The Black Eyed Peas",
    "song": "The Time (Dirty Bit)",
    "year": "2010",
    "seconds": "50",
    "lyric": "Mirror, mirror, on the wall",
    "number": "4210",
  },
  {
    "artist": "Bob Dylan",
    "song": "The Times, They are A-Changin’",
    "year": "1964",
    "seconds": "40",
    "lyric": "You better start swimmin’",
    "number": "4211",
  },
  {
    "artist": "The Miracles",
    "song": "The Tracks of My Tears",
    "year": "1965",
    "seconds": "60",
    "lyric": "A good look at my face",
    "number": "4212",
  },
  {
    "artist": "Jason Aldean",
    "song": "The Truth",
    "year": "2009",
    "seconds": "60",
    "lyric": "I’m still strung out over you",
    "number": "4213",
  },
  {
    "artist": "Johnny Mathis",
    "song": "The Twelfth of Never",
    "year": "1957",
    "seconds": "40",
    "lyric": "Like roses need rain",
    "number": "4214",
  },
  {
    "artist": "Mitch Miller and the Gang",
    "song": "The Twelve Days of Christmas",
    "year": "1961",
    "seconds": "60",
    "lyric": "Ten pipers piping, nine drummers drumming",
    "number": "4215",
  },
  {
    "artist": "Chubby Checker",
    "song": "The Twist",
    "year": "1960",
    "seconds": "60",
    "lyric": "‘Til we tear the house down",
    "number": "4216",
  },
  {
    "artist": "The Irish Rovers",
    "song": "The Unicorn",
    "year": "1968",
    "seconds": "50",
    "lyric": "Green alligators and long-necked geese",
    "number": "4217",
  },
  {
    "artist": "Tom Petty & the Heartbreakers",
    "song": "The Waiting",
    "year": "1981",
    "seconds": "40",
    "lyric": "Is the hardest part",
    "number": "4218",
  },
  {
    "artist": "Etta James",
    "song": "The Wallflower (Dance With Me, Henry)",
    "year": "1955",
    "seconds": "70",
    "lyric": "Get the lead out of your feet",
    "number": "4219",
  },
  {
    "artist": "Dion",
    "song": "The Wanderer",
    "year": "1962",
    "seconds": "50",
    "lyric": "I tear open my shirt",
    "number": "4220",
  },
  {
    "artist": "Cher",
    "song": "The Way of Love",
    "year": "1972",
    "seconds": "50",
    "lyric": "And you fall in love",
    "number": "4221",
  },
  {
    "artist": "Barbra Streisand",
    "song": "The Way We Were",
    "year": "1974",
    "seconds": "60",
    "lyric": "Light the corner of my mind",
    "number": "4222",
  },
  {
    "artist": "The Temptations",
    "song": "The Way You Do the Things You Do",
    "year": "1964",
    "seconds": "60",
    "lyric": "You swept me off my feet",
    "number": "4223",
  },
  {
    "artist": "Frank Sinatra",
    "song": "The Way You Look Tonight",
    "year": "1964",
    "seconds": "50",
    "lyric": "And your cheeks so soft",
    "number": "4224",
  },
  {
    "artist": "Faith Hill",
    "song": "The Way You Love Me",
    "year": "2000",
    "seconds": "50",
    "lyric": "With your arms around me",
    "number": "4225",
  },
  {
    "artist": "Michael Jackson",
    "song": "The Way You Make Me Feel",
    "year": "1987",
    "seconds": "50",
    "lyric": "And we’ll paint the town",
    "number": "4226",
  },
  {
    "artist": "Noel Paul Stookey",
    "song": "The Wedding Song (There is Love)",
    "year": "1971",
    "seconds": "60",
    "lyric": "A man shall leave his mother",
    "number": "4227",
  },
  {
    "artist": "Travis Tritt and Marty Stuart",
    "song": "The Whiskey Ain’t Workin’",
    "year": "1991",
    "seconds": "60",
    "lyric": "To lay this old bottle down",
    "number": "4228",
  },
  {
    "artist": "Hank Thompson",
    "song": "The Wild Side of Life",
    "year": "1952",
    "seconds": "60",
    "lyric": "To call you on the phone",
    "number": "4229",
  },
  {
    "artist": "Jimi Hendrix",
    "song": "The Wind Cries Mary",
    "year": "1967",
    "seconds": "50",
    "lyric": "A broom is drearily sweeping",
    "number": "4230",
  },
  {
    "artist": "George Jones",
    "song": "The Window Up Above",
    "year": "1960",
    "seconds": "60",
    "lyric": "Our hearts fit like a glove",
    "number": "4231",
  },
  {
    "artist": "Redbone",
    "song": "The Witch Queen of New Orleans",
    "year": "1972",
    "seconds": "60",
    "lyric": "She’ll put a spell on you",
    "number": "4232",
  },
  {
    "artist": "Shania Twain",
    "song": "The Woman in Me (Needs the Man in You)",
    "year": "1995",
    "seconds": "60",
    "lyric": "And it’s you I run to",
    "number": "4233",
  },
  {
    "artist": "Kenny Chesney",
    "song": "The Woman With You",
    "year": "2004",
    "seconds": "50",
    "lyric": "Dancin’ backwards in high heels",
    "number": "4234",
  },
  {
    "artist": "Elvis Presley",
    "song": "The Wonder of You",
    "year": "1969",
    "seconds": "40",
    "lyric": "You touch my hand",
    "number": "4235",
  },
  {
    "artist": "Brad Paisley",
    "song": "The World",
    "year": "2006",
    "seconds": "60",
    "lyric": "The teller down at the bank",
    "number": "4236",
  },
  {
    "artist": "Jhené Aiko",
    "song": "The Worst",
    "year": "2014",
    "seconds": "50",
    "lyric": "You got me face down",
    "number": "4237",
  },
  {
    "artist": "Gordon Lightfoot",
    "song": "The Wreck of the Edmund Fitzgerald",
    "year": "1976",
    "seconds": "50",
    "lyric": "The faces and the names",
    "number": "4238",
  },
  {
    "artist": "Waylon Jennings",
    "song": "The Wurlitzer Prize (I Don’t Want to Get Over You)",
    "year": "1977",
    "seconds": "60",
    "lyric": "Alone at a table for two",
    "number": "4239",
  },
  {
    "artist": "Tom T. Hall",
    "song": "The Year Clayton Delaney Died",
    "year": "1971",
    "seconds": "50",
    "lyric": "I was a barefoot kid",
    "number": "4240",
  },
  {
    "artist": "Gene Autry",
    "song": "The Yellow Rose of Texas",
    "year": "1931",
    "seconds": "60",
    "lyric": "Eyes are as bright as diamonds",
    "number": "4241",
  },
  {
    "artist": "Cliff Richard and the Shadows",
    "song": "The Young Ones",
    "year": "1962",
    "seconds": "50",
    "lyric": "Young hearts shouldn’t be afraid",
    "number": "4242",
  },
  {
    "artist": "Brad Paisley",
    "song": "Then",
    "year": "2009",
    "seconds": "50",
    "lyric": "In the front porch light",
    "number": "4243",
  },
  {
    "artist": "Dionne Warwick & the Spinners",
    "song": "Then Came You",
    "year": "1974",
    "seconds": "70",
    "lyric": "Running through the back of my mind",
    "number": "4244",
  },
  {
    "artist": "The Crystals",
    "song": "Then He Kissed Me",
    "year": "1963",
    "seconds": "60",
    "lyric": "So I whispered I love you",
    "number": "4245",
  },
  {
    "artist": "Trace Adkins",
    "song": "Then They Do",
    "year": "2003",
    "seconds": "60",
    "lyric": "And I’m yelling up the stairs",
    "number": "4246",
  },
  {
    "artist": "Aaron Tippin",
    "song": "There Ain’t Nothin’ Wrong With the Radio",
    "year": "1992",
    "seconds": "50",
    "lyric": "Around on four bald tires",
    "number": "4247",
  },
  {
    "artist": "Trisha Yearwood",
    "song": "There Goes My Baby",
    "year": "1998",
    "seconds": "60",
    "lyric": "Out of the clear blue sky",
    "number": "4248",
  },
  {
    "artist": "Jack Greene",
    "song": "There Goes My Everything",
    "year": "1966",
    "seconds": "50",
    "lyric": "The one of my dreams",
    "number": "4249",
  },
  {
    "artist": "Kenny Chesney",
    "song": "There Goes My Life",
    "year": "2003",
    "seconds": "40",
    "lyric": "He checked the oil",
    "number": "4250",
  },
  {
    "artist": "Bobby Vinton",
    "song": "There! I’ve Said It Again",
    "year": "1963",
    "seconds": "50",
    "lyric": "I try to drum up",
    "number": "4251",
  },
  {
    "artist": "Webb Pierce",
    "song": "There Stands the Glass",
    "year": "1954",
    "seconds": "60",
    "lyric": "Fill it up to the brim",
    "number": "4252",
  },
  {
    "artist": "Bryan Adams",
    "song": "There Will Never Be Another Tonight",
    "year": "1991",
    "seconds": "70",
    "lyric": "Ride your broom right into my room",
    "number": "4253",
  },
  {
    "artist": "Charlie Rich",
    "song": "There Won’t Be Anymore",
    "year": "1973",
    "seconds": "50",
    "lyric": "And broke my loving heart",
    "number": "4254",
  },
  {
    "artist": "Johnny Cash",
    "song": "There You Go",
    "year": "1956",
    "seconds": "40",
    "lyric": "You’re by his side",
    "number": "4255",
  },
  {
    "artist": "Billie Eilish",
    "song": "Therefore I Am",
    "year": "2020",
    "seconds": "60",
    "lyric": "You think that you’re the man",
    "number": "4256",
  },
  {
    "artist": "Billy Ocean",
    "song": "There’ll Be Sad Songs (To Make You Cry)",
    "year": "1986",
    "seconds": "50",
    "lyric": "A river running so deep",
    "number": "4257",
  },
  {
    "artist": "Conway Twitty",
    "song": "There’s a Honky Tonk Angel",
    "year": "1974",
    "seconds": "50",
    "lyric": "Happy just holding my hand",
    "number": "4258",
  },
  {
    "artist": "Herman’s Hermits",
    "song": "There’s a Kind of Hush All Over the World",
    "year": "1967",
    "seconds": "60",
    "lyric": "Closer now and you will see",
    "number": "4259",
  },
  {
    "artist": "Ethel Merman",
    "song": "There’s No Business Like Show Business",
    "year": "1953",
    "seconds": "70",
    "lyric": "Change it for a sack of gold",
    "number": "4260",
  },
  {
    "artist": "Ronnie Milsap",
    "song": "(There’s) No Gettin’ Over Me",
    "year": "1981",
    "seconds": "50",
    "lyric": "So you can walk out",
    "number": "4261",
  },
  {
    "artist": "Tony Martin",
    "song": "There’s No Tomorrow",
    "year": "1949",
    "seconds": "70",
    "lyric": "So kiss me and hold me tight",
    "number": "4262",
  },
  {
    "artist": "Queen",
    "song": "These Are the Days of Our Lives",
    "year": "1991",
    "seconds": "60",
    "lyric": "You can’t turn back the clock",
    "number": "4263",
  },
  {
    "artist": "Otis Redding",
    "song": "These Arms of Mine",
    "year": "1962",
    "seconds": "70",
    "lyric": "And I need your tender lips too",
    "number": "4264",
  },
  {
    "artist": "Nancy Sinatra",
    "song": "These Boots Are Made For Walkin’",
    "year": "1966",
    "seconds": "60",
    "lyric": "A brand new box of matches",
    "number": "4265",
  },
  {
    "artist": "Rascal Flatts",
    "song": "These Days",
    "year": "2002",
    "seconds": "60",
    "lyric": "Punch the clock, head for home",
    "number": "4266",
  },
  {
    "artist": "Rudimental (ft. Jess Glynne, Dan Caplen & Macklemore)",
    "song": "These Days",
    "year": "2018",
    "seconds": "60",
    "lyric": "Three years of ups and downs",
    "number": "4267",
  },
  {
    "artist": "Heart",
    "song": "These Dreams",
    "year": "1986",
    "seconds": "60",
    "lyric": "On a watch with no hands",
    "number": "4268",
  },
  {
    "artist": "The Guess Who",
    "song": "These Eyes",
    "year": "1969",
    "seconds": "50",
    "lyric": "Arms long to hold you",
    "number": "4269",
  },
  {
    "artist": "The Carpenters",
    "song": "(They Long to Be) Close To You",
    "year": "1970",
    "seconds": "60",
    "lyric": "Sprinkled moon dust in your hair",
    "number": "4270",
  },
  {
    "artist": "Perry Como",
    "song": "They Say It’s Wonderful",
    "year": "1946",
    "seconds": "50",
    "lyric": "Falling in love is grand",
    "number": "4271",
  },
  {
    "artist": "Jethro Tull",
    "song": "Thick as a Brick (Part 1)",
    "year": "1972",
    "seconds": "50",
    "lyric": "Building castles by the sea",
    "number": "4272",
  },
  {
    "artist": "Prince",
    "song": "Thieves in the Temple",
    "year": "1990",
    "seconds": "50",
    "lyric": "Kicking me in my heart",
    "number": "4273",
  },
  {
    "artist": "Bobby Darin",
    "song": "Things",
    "year": "1962",
    "seconds": "50",
    "lyric": "A kiss in the dark",
    "number": "4274",
  },
  {
    "artist": "Aretha Franklin",
    "song": "Think",
    "year": "1968",
    "seconds": "60",
    "lyric": "You’re trying to do to me",
    "number": "4275",
  },
  {
    "artist": "Christopher Cross",
    "song": "Think of Laura",
    "year": "1983",
    "seconds": "60",
    "lyric": "I know you and you’re here",
    "number": "4276",
  },
  {
    "artist": "Buck Owens",
    "song": "Think of Me",
    "year": "1966",
    "seconds": "50",
    "lyric": "I wait by the telephone",
    "number": "4277",
  },
  {
    "artist": "Chris Young and Cassadee Pope",
    "song": "Think of You",
    "year": "2016",
    "seconds": "60",
    "lyric": "Read between all of the lines",
    "number": "4278",
  },
  {
    "artist": "Frank Ocean",
    "song": "Thinkin Bout You",
    "year": "2012",
    "seconds": "60",
    "lyric": "A tornado flew around my room",
    "number": "4279",
  },
  {
    "artist": "Ed Sheeran",
    "song": "Thinking Out Loud",
    "year": "2014",
    "seconds": "60",
    "lyric": "The light of a thousand stars",
    "number": "4280",
  },
  {
    "artist": "The Beatles",
    "song": "This Boy",
    "year": "1964",
    "seconds": "50",
    "lyric": "‘Til he’s seen you cry",
    "number": "4281",
  },
  {
    "artist": "The Smiths",
    "song": "This Charming Man",
    "year": "1983",
    "seconds": "50",
    "lyric": "Punctured bicycle on a hillside",
    "number": "4282",
  },
  {
    "artist": "The Oak Ridge Boys",
    "song": "This Crazy Love",
    "year": "1987",
    "seconds": "70",
    "lyric": "Raise your hand or call your bluff",
    "number": "4283",
  },
  {
    "artist": "Gary Lewis & the Playboys",
    "song": "This Diamond Ring",
    "year": "1965",
    "seconds": "60",
    "lyric": "She took it off her finger",
    "number": "4284",
  },
  {
    "artist": "Gary Puckett & the Union Gap",
    "song": "This Girl is a Woman Now",
    "year": "1969",
    "seconds": "50",
    "lyric": "She cried a single tear",
    "number": "4285",
  },
  {
    "artist": "Herb Alpert",
    "song": "This Guy’s in Love With You",
    "year": "1968",
    "seconds": "40",
    "lyric": "My hands are shaking",
    "number": "4286",
  },
  {
    "artist": "Childish Gambino",
    "song": "This is America",
    "year": "2018",
    "seconds": "50",
    "lyric": "I know you wanna party",
    "number": "4287",
  },
  {
    "artist": "Brad Paisley",
    "song": "This is Country Music",
    "year": "2010",
    "seconds": "50",
    "lyric": "To drink a cold one",
    "number": "4288",
  },
  {
    "artist": "Montell Jordan",
    "song": "This is How We Do It",
    "year": "1995",
    "seconds": "50",
    "lyric": "Bring the old school back",
    "number": "4289",
  },
  {
    "artist": "Florida Georgia Line (ft. Luke Bryan)",
    "song": "This is How We Roll",
    "year": "2014",
    "seconds": "50",
    "lyric": "Automatic like a free throw",
    "number": "4290",
  },
  {
    "artist": "Jim Reeves",
    "song": "This Is It",
    "year": "1965",
    "seconds": "50",
    "lyric": "Even the clock has stopped",
    "number": "4291",
  },
  {
    "artist": "Kenny Loggins",
    "song": "This Is It",
    "year": "1979",
    "seconds": "40",
    "lyric": "Stand up and fight",
    "number": "4292",
  },
  {
    "artist": "Scotty McCreery",
    "song": "This Is It",
    "year": "2018",
    "seconds": "50",
    "lyric": "You’re walkin’ down the aisle",
    "number": "4293",
  },
  {
    "artist": "James House",
    "song": "This is Me Missing You",
    "year": "1995",
    "seconds": "40",
    "lyric": "Rain on the rooftops",
    "number": "4294",
  },
  {
    "artist": "Faith Hill",
    "song": "This Kiss",
    "year": "1998",
    "seconds": "60",
    "lyric": "It’s the way you love me",
    "number": "4295",
  },
  {
    "artist": "Woody Guthrie",
    "song": "This Land is Your Land",
    "year": "1940",
    "seconds": "50",
    "lyric": "Walking that ribbon of highway",
    "number": "4296",
  },
  {
    "artist": "Maroon 5",
    "song": "This Love",
    "year": "2004",
    "seconds": "60",
    "lyric": "The fire burning in her eyes",
    "number": "4297",
  },
  {
    "artist": "The Drifters",
    "song": "This Magic Moment",
    "year": "1960",
    "seconds": "60",
    "lyric": "Your lips are close to mine",
    "number": "4298",
  },
  {
    "artist": "Leon Russell",
    "song": "This Masquerade",
    "year": "1972",
    "seconds": "60",
    "lyric": "Every time I see your eyes",
    "number": "4299",
  },
  {
    "artist": "Neil Young",
    "song": "This Note’s For You",
    "year": "1988",
    "seconds": "40",
    "lyric": "Don’t need no money",
    "number": "4300",
  },
  {
    "artist": "The Isley Brothers",
    "song": "This Old Heart of Mine",
    "year": "1966",
    "seconds": "50",
    "lyric": "To hide my hurt inside",
    "number": "4301",
  },
  {
    "artist": "Martina McBride",
    "song": "This One’s for the Girls",
    "year": "2003",
    "seconds": "50",
    "lyric": "Wished upon a shooting star",
    "number": "4302",
  },
  {
    "artist": "Waylon Jennings",
    "song": "This Time",
    "year": "1974",
    "seconds": "60",
    "lyric": "Kiss you, touch you, hold you",
    "number": "4303",
  },
  {
    "artist": "Donna Summer",
    "song": "This Time I Know It’s For Real",
    "year": "1989",
    "seconds": "60",
    "lyric": "Walk a tightrope way up high",
    "number": "4304",
  },
  {
    "artist": "Conway Twitty",
    "song": "This Time I’ve Hurt Her More Than She Loves Me",
    "year": "1975",
    "seconds": "60",
    "lyric": "No more crying on her shoulder",
    "number": "4305",
  },
  {
    "artist": "Nat King Cole",
    "song": "Those Lazy Hazy Crazy Days of Summer",
    "year": "1963",
    "seconds": "50",
    "lyric": "Soda and pretzels and beer",
    "number": "4306",
  },
  {
    "artist": "Mary Hopkin",
    "song": "Those Were the Days",
    "year": "1968",
    "seconds": "50",
    "lyric": "Raise a glass or two",
    "number": "4307",
  },
  {
    "artist": "Bryan Adams",
    "song": "Thought I’d Died and Gone to Heaven",
    "year": "1992",
    "seconds": "50",
    "lyric": "I lie awake beside you",
    "number": "4308",
  },
  {
    "artist": "Dan Seals",
    "song": "Three Time Loser",
    "year": "1987",
    "seconds": "50",
    "lyric": "I was back in school",
    "number": "4309",
  },
  {
    "artist": "The Commodores",
    "song": "Three Times a Lady",
    "year": "1978",
    "seconds": "60",
    "lyric": "To the end of our rainbow",
    "number": "4310",
  },
  {
    "artist": "Michael Jackson",
    "song": "Thriller",
    "year": "1984",
    "seconds": "50",
    "lyric": "You hear the door slam",
    "number": "4311",
  },
  {
    "artist": "Bruce Springsteen",
    "song": "Thunder Road",
    "year": "1975",
    "seconds": "80",
    "lyric": "And let the wind blow back your hair",
    "number": "4312",
  },
  {
    "artist": "AC/DC",
    "song": "Thunderstruck",
    "year": "1990",
    "seconds": "60",
    "lyric": "The drums beating in my heart",
    "number": "4313",
  },
  {
    "artist": "The Beatles",
    "song": "Ticket to Ride",
    "year": "1965",
    "seconds": "50",
    "lyric": "Why she’s riding so high",
    "number": "4314",
  },
  {
    "artist": "Brad Paisley",
    "song": "Ticks",
    "year": "2007",
    "seconds": "50",
    "lyric": "Half of your butterfly tattoo",
    "number": "4315",
  },
  {
    "artist": "Dawn (ft. Tony Orlando)",
    "song": "Tie a Yellow Ribbon ‘Round the Ole Oak Tree",
    "year": "1973",
    "seconds": "50",
    "lyric": "I’ll stay on the bus",
    "number": "4316",
  },
  {
    "artist": "Rolf Harris",
    "song": "Tie Me Kangaroo Down Sport",
    "year": "1960",
    "seconds": "60",
    "lyric": "And take me koala back, Jack",
    "number": "4317",
  },
  {
    "artist": "Conway Twitty",
    "song": "Tight Fittin’ Jeans",
    "year": "1981",
    "seconds": "60",
    "lyric": "But I’ll buy you a beer",
    "number": "4318",
  },
  {
    "artist": "Archie Bell & the Drells",
    "song": "Tighten Up",
    "year": "1968",
    "seconds": "50",
    "lyric": "Sock it to me now",
    "number": "4319",
  },
  {
    "artist": "Alive ‘N Kickin’",
    "song": "Tighter, Tighter",
    "year": "1970",
    "seconds": "40",
    "lyric": "Opened up my eyes",
    "number": "4320",
  },
  {
    "artist": "Ke$ha",
    "song": "Tik Tok",
    "year": "2009",
    "seconds": "50",
    "lyric": "DJ, blow my speakers up",
    "number": "4321",
  },
  {
    "artist": "Tammy Wynette",
    "song": "’Til I Can Make It On My Own",
    "year": "1976",
    "seconds": "40",
    "lyric": "I lean on you",
    "number": "4322",
  },
  {
    "artist": "The Everly Brothers",
    "song": "’Til I Kissed You",
    "year": "1959",
    "seconds": "60",
    "lyric": "Realize what a kiss could be",
    "number": "4323",
  },
  {
    "artist": "Keith Urban",
    "song": "’Til Summer Comes Around",
    "year": "2009",
    "seconds": "50",
    "lyric": "Stuck on a Ferris wheel",
    "number": "4324",
  },
  {
    "artist": "Don Williams",
    "song": "’Til the Rivers All Run Dry",
    "year": "1976",
    "seconds": "60",
    "lyric": "The sun falls from the sky",
    "number": "4325",
  },
  {
    "artist": "Barbara Mandrell",
    "song": "’Til You’re Gone",
    "year": "1982",
    "seconds": "60",
    "lyric": "I hear you on the radio",
    "number": "4326",
  },
  {
    "artist": "Perry Como",
    "song": "Till the End of Time",
    "year": "1945",
    "seconds": "50",
    "lyric": "Through laughter and through tears",
    "number": "4327",
  },
  {
    "artist": "Britney Spears (ft. Nicki Minaj and Ke$ha)",
    "song": "Till the World Ends",
    "year": "2011",
    "seconds": "50",
    "lyric": "Your tongue tied in knots",
    "number": "4328",
  },
  {
    "artist": "The Beatles",
    "song": "Till There Was You",
    "year": "1963",
    "seconds": "60",
    "lyric": "But I never heard it singing",
    "number": "4329",
  },
  {
    "artist": "Taylor Swift",
    "song": "Tim McGraw",
    "year": "2006",
    "seconds": "50",
    "lyric": "The moon like a spotlight",
    "number": "4330",
  },
  {
    "artist": "Pink Floyd",
    "song": "Time",
    "year": "1974",
    "seconds": "50",
    "lyric": "You missed the starting gun",
    "number": "4331",
  },
  {
    "artist": "Cyndi Lauper",
    "song": "Time After Time",
    "year": "1983",
    "seconds": "50",
    "lyric": "I hear the clock tick",
    "number": "4332",
  },
  {
    "artist": "Anne Murray",
    "song": "Time Don’t Run Out On Me",
    "year": "1985",
    "seconds": "60",
    "lyric": "Catch your eye across the room",
    "number": "4333",
  },
  {
    "artist": "Jim Croce",
    "song": "Time In a Bottle",
    "year": "1973",
    "seconds": "50",
    "lyric": "A box just for wishes",
    "number": "4334",
  },
  {
    "artist": "Josh Turner",
    "song": "Time is Love",
    "year": "2012",
    "seconds": "50",
    "lyric": "Where I need to be",
    "number": "4335",
  },
  {
    "artist": "Rolling Stones",
    "song": "Time Is on My Side",
    "year": "1964",
    "seconds": "50",
    "lyric": "But you’ll come running back",
    "number": "4336",
  },
  {
    "artist": "The Zombies",
    "song": "Time of the Season",
    "year": "1969",
    "seconds": "50",
    "lyric": "Is he rich like me?",
    "number": "4337",
  },
  {
    "artist": "Pitbull and Ne-Yo",
    "song": "Time of Our Lives",
    "year": "2014",
    "seconds": "60",
    "lyric": "A squirrel looking for a nut",
    "number": "4338",
  },
  {
    "artist": "MGMT",
    "song": "Time to Pretend",
    "year": "2007",
    "seconds": "60",
    "lyric": "Wake up for the morning commute",
    "number": "4339",
  },
  {
    "artist": "The Outsiders",
    "song": "Time Won’t Let Me",
    "year": "1966",
    "seconds": "60",
    "lyric": "To hold you in my arms",
    "number": "4340",
  },
  {
    "artist": "Kylie Minogue",
    "song": "Timebomb",
    "year": "2012",
    "seconds": "60",
    "lyric": "Feel the rhythm in my heartbeat",
    "number": "4341",
  },
  {
    "artist": "Dallas Smith",
    "song": "Timeless",
    "year": "2019",
    "seconds": "60",
    "lyric": "And fifty years down the road",
    "number": "4342",
  },
  {
    "artist": "Paul Anka",
    "song": "Times of Your Life",
    "year": "1975",
    "seconds": "50",
    "lyric": "The laughter and the tears",
    "number": "4343",
  },
  {
    "artist": "America",
    "song": "Tin Man",
    "year": "1974",
    "seconds": "50",
    "lyric": "Quick to take the bait",
    "number": "4344",
  },
  {
    "artist": "Mitchell Ayres’ Orchestra (ft. Perry Como)",
    "song": "Tina Marie",
    "year": "1955",
    "seconds": "60",
    "lyric": "Carry you off in my arms",
    "number": "4345",
  },
  {
    "artist": "The Clovers",
    "song": "Ting-A-Ling",
    "year": "1952",
    "seconds": "50",
    "lyric": "And call me angel child",
    "number": "4346",
  },
  {
    "artist": "Elton John",
    "song": "Tiny Dancer",
    "year": "1972",
    "seconds": "50",
    "lyric": "You’ll marry a music man",
    "number": "4347",
  },
  {
    "artist": "J-Kwon",
    "song": "Tipsy",
    "year": "2004",
    "seconds": "40",
    "lyric": "Bottom of the ninth",
    "number": "4348",
  },
  {
    "artist": "Al Green",
    "song": "Tired of Being Alone",
    "year": "1971",
    "seconds": "60",
    "lyric": "In my dreams, nobody but you",
    "number": "4349",
  },
  {
    "artist": "The Kinks",
    "song": "Tired of Waiting For You",
    "year": "1965",
    "seconds": "40",
    "lyric": "‘Til I met you",
    "number": "4350",
  },
  {
    "artist": "David Guetta (feat. Sia)",
    "song": "Titanium",
    "year": "2011",
    "seconds": "50",
    "lyric": "But all your bullets ricochet",
    "number": "4351",
  },
  {
    "artist": "Julio Iglesias & Willie Nelson",
    "song": "To All the Girls I’ve Loved Before",
    "year": "1984",
    "seconds": "50",
    "lyric": "In and out my door",
    "number": "4352",
  },
  {
    "artist": "Tony Martin",
    "song": "To Each His Own",
    "year": "1946",
    "seconds": "70",
    "lyric": "To open each door there’s a key",
    "number": "4353",
  },
  {
    "artist": "The Teddy Bears",
    "song": "To Know Him Is To Love Him",
    "year": "1958",
    "seconds": "50",
    "lyric": "How blind can he be?",
    "number": "4354",
  },
  {
    "artist": "Conway Twitty",
    "song": "To See My Angel Cry",
    "year": "1969",
    "seconds": "60",
    "lyric": "Dry the tears from her eyes",
    "number": "4355",
  },
  {
    "artist": "Lulu",
    "song": "To Sir With Love",
    "year": "1967",
    "seconds": "40",
    "lyric": "From crayons to perfume",
    "number": "4356",
  },
  {
    "artist": "Vern Gosdin",
    "song": "Today My World Slipped Away",
    "year": "1982",
    "seconds": "40",
    "lyric": "Straight to the church",
    "number": "4357",
  },
  {
    "artist": "Taylor Swift",
    "song": "Today Was a Fairytale",
    "year": "2010",
    "seconds": "50",
    "lyric": "I saw you standin’ there",
    "number": "4358",
  },
  {
    "artist": "Zac Brown Band",
    "song": "Toes",
    "year": "2009",
    "seconds": "40",
    "lyric": "Bikinis and palm trees",
    "number": "4359",
  },
  {
    "artist": "Janet Jackson",
    "song": "Together Again",
    "year": "1997",
    "seconds": "50",
    "lyric": "I know you are free",
    "number": "4360",
  },
  {
    "artist": "Giorgio Moroder & Philip Oakey",
    "song": "Together in Electric Dreams",
    "year": "1984",
    "seconds": "50",
    "lyric": "Love comes as a surprise",
    "number": "4361",
  },
  {
    "artist": "The Kingston Trio",
    "song": "Tom Dooley",
    "year": "1958",
    "seconds": "60",
    "lyric": "Hangin’ from a white oak tree",
    "number": "4362",
  },
  {
    "artist": "Rush",
    "song": "Tom Sawyer",
    "year": "1981",
    "seconds": "50",
    "lyric": "Love and life are deep",
    "number": "4363",
  },
  {
    "artist": "Andrea McArdle",
    "song": "Tomorrow",
    "year": "1977",
    "seconds": "60",
    "lyric": "Stick out my chin and grin",
    "number": "4364",
  },
  {
    "artist": "Chris Young",
    "song": "Tomorrow",
    "year": "2011",
    "seconds": "50",
    "lyric": "We’re like fire and gasoline",
    "number": "4365",
  },
  {
    "artist": "Marni Nixon & Jimmy Bryant",
    "song": "Tonight",
    "year": "1961",
    "seconds": "40",
    "lyric": "Shooting sparks into space",
    "number": "4366",
  },
  {
    "artist": "Marty Robbins",
    "song": "Tonight Carmen",
    "year": "1967",
    "seconds": "60",
    "lyric": "Brand new sheets on the bed",
    "number": "4367",
  },
  {
    "artist": "Keith Urban",
    "song": "Tonight I Wanna Cry",
    "year": "2005",
    "seconds": "50",
    "lyric": "Pictures of you and I",
    "number": "4368",
  },
  {
    "artist": "Bob Dylan",
    "song": "Tonight I’ll Be Staying Here With You",
    "year": "1969",
    "seconds": "60",
    "lyric": "Throw my ticket out the window",
    "number": "4369",
  },
  {
    "artist": "Enrique Iglesias (ft. Ludacris & DJ Frank E)",
    "song": "Tonight (I’m Lovin’ You)",
    "year": "2010",
    "seconds": "60",
    "lyric": "From the window to the wall",
    "number": "4370",
  },
  {
    "artist": "Jason Aldean",
    "song": "Tonight Looks Good on You",
    "year": "2015",
    "seconds": "50",
    "lyric": "Lean in for another kiss",
    "number": "4371",
  },
  {
    "artist": "Smashing Pumpkins",
    "song": "Tonight, Tonight",
    "year": "1996",
    "seconds": "60",
    "lyric": "In your city by the lake",
    "number": "4372",
  },
  {
    "artist": "Tony Martin",
    "song": "Tonight We Love",
    "year": "1941",
    "seconds": "40",
    "lyric": "We touch the stars",
    "number": "4373",
  },
  {
    "artist": "Rod Stewart",
    "song": "Tonight’s the Night",
    "year": "1977",
    "seconds": "80",
    "lyric": "Kick off your shoes and sit right down",
    "number": "4374",
  },
  {
    "artist": "Marvin Gaye",
    "song": "Too Busy Thinking About My Baby",
    "year": "1969",
    "seconds": "60",
    "lyric": "I got a one track mind",
    "number": "4375",
  },
  {
    "artist": "Sam Smith",
    "song": "Too Good at Goodbyes",
    "year": "2017",
    "seconds": "50",
    "lyric": "Leave me in the dirt",
    "number": "4376",
  },
  {
    "artist": "Kool & the Gang",
    "song": "Too Hot",
    "year": "1979",
    "seconds": "50",
    "lyric": "Fever in our hearts, girl",
    "number": "4377",
  },
  {
    "artist": "Cornelius Brothers & Sister Rose",
    "song": "Too Late to Turn Back Now",
    "year": "1972",
    "seconds": "60",
    "lyric": "I believe I’m falling in love",
    "number": "4378",
  },
  {
    "artist": "Elvis Presley",
    "song": "Too Much",
    "year": "1957",
    "seconds": "60",
    "lyric": "Need your huggin’, please be mine",
    "number": "4379",
  },
  {
    "artist": "Styx",
    "song": "Too Much Time on My Hands",
    "year": "1981",
    "seconds": "40",
    "lyric": "Sitting on this barstool",
    "number": "4380",
  },
  {
    "artist": "Johnny Mathis & Deniece Williams",
    "song": "Too Much, Too Little, Too Late",
    "year": "1978",
    "seconds": "50",
    "lyric": "All our bridges tumbled down",
    "number": "4381",
  },
  {
    "artist": "Drake",
    "song": "Toosie Slide",
    "year": "2020",
    "seconds": "50",
    "lyric": "Black leather gloves, no sequins",
    "number": "4382",
  },
  {
    "artist": "The Carpenters",
    "song": "Top of the World",
    "year": "1974",
    "seconds": "60",
    "lyric": "Got the sun in my eyes",
    "number": "4383",
  },
  {
    "artist": "Natalie Imbruglia",
    "song": "Torn",
    "year": "1997",
    "seconds": "50",
    "lyric": "Lying naked on the floor",
    "number": "4384",
  },
  {
    "artist": "Mary MacGregor",
    "song": "Torn Between Two Lovers",
    "year": "1976",
    "seconds": "50",
    "lyric": "Empty place inside of me",
    "number": "4385",
  },
  {
    "artist": "Bobby Lewis",
    "song": "Tossin’ and Turnin’",
    "year": "1961",
    "seconds": "70",
    "lyric": "I kicked the blankets on the floor",
    "number": "4386",
  },
  {
    "artist": "Bonnie Tyler",
    "song": "Total Eclipse of the Heart",
    "year": "1983",
    "seconds": "70",
    "lyric": "Listening to the sound of my tears",
    "number": "4387",
  },
  {
    "artist": "The Staple Singers",
    "song": "Touch a Hand, Make a Friend",
    "year": "1974",
    "seconds": "60",
    "lyric": "You feel it in your heart",
    "number": "4388",
  },
  {
    "artist": "The Doors",
    "song": "Touch Me",
    "year": "1968",
    "seconds": "50",
    "lyric": "The heavens stop the rain",
    "number": "4389",
  },
  {
    "artist": "Diana Ross",
    "song": "Touch Me In the Morning",
    "year": "1973",
    "seconds": "60",
    "lyric": "Laugh at the wind behind us",
    "number": "4390",
  },
  {
    "artist": "Alabama",
    "song": "Touch Me When We’re Dancing",
    "year": "1986",
    "seconds": "60",
    "lyric": "Let your love flow through me",
    "number": "4391",
  },
  {
    "artist": "Mariah Carey",
    "song": "Touch My Body",
    "year": "2008",
    "seconds": "50",
    "lyric": "My temperature’s through the roof",
    "number": "4392",
  },
  {
    "artist": "Grateful Dead",
    "song": "Touch of Grey",
    "year": "1987",
    "seconds": "50",
    "lyric": "Paint by numbers morning sky",
    "number": "4393",
  },
  {
    "artist": "Conway Twitty (ft. Joni Lee)",
    "song": "Touch the Hand",
    "year": "1975",
    "seconds": "60",
    "lyric": "Reached out and pushed me away",
    "number": "4394",
  },
  {
    "artist": "Bruce Springsteen",
    "song": "Tougher Than the Rest",
    "year": "1988",
    "seconds": "50",
    "lyric": "All dressed up in blue",
    "number": "4395",
  },
  {
    "artist": "Gene McDaniels",
    "song": "Tower of Strength",
    "year": "1961",
    "seconds": "40",
    "lyric": "I’d watch you cry",
    "number": "4396",
  },
  {
    "artist": "Britney Spears",
    "song": "Toxic",
    "year": "2004",
    "seconds": "50",
    "lyric": "The taste of your lips",
    "number": "4397",
  },
  {
    "artist": "Classics IV",
    "song": "Traces",
    "year": "1969",
    "seconds": "60",
    "lyric": "Pages from an old love letter",
    "number": "4398",
  },
  {
    "artist": "The Cuff Links",
    "song": "Tracy",
    "year": "1969",
    "seconds": "50",
    "lyric": "Bounces me off the ceiling",
    "number": "4399",
  },
  {
    "artist": "Led Zeppelin",
    "song": "Trampled Under Foot",
    "year": "1975",
    "seconds": "50",
    "lyric": "Heavy metal underneath your hood",
    "number": "4400",
  },
  {
    "artist": "Creedence Clearwater Revival",
    "song": "Travelin’ Band",
    "year": "1970",
    "seconds": "50",
    "lyric": "I’m flyin’ ‘cross the land",
    "number": "4401",
  },
  {
    "artist": "Cliff Richard and the Shadows",
    "song": "Travelin’ Light",
    "year": "1959",
    "seconds": "50",
    "lyric": "No comb and no toothbrush",
    "number": "4402",
  },
  {
    "artist": "Ricky Nelson",
    "song": "Travelin’ Man",
    "year": "1961",
    "seconds": "70",
    "lyric": "We walked in the sand of Waikiki",
    "number": "4403",
  },
  {
    "artist": "Dixie Chicks",
    "song": "Travelin’ Soldier",
    "year": "2002",
    "seconds": "70",
    "lyric": "A piccolo player in the marching band",
    "number": "4404",
  },
  {
    "artist": "Cornelius Brothers & Sister Rose",
    "song": "Treat Her Like a Lady",
    "year": "1971",
    "seconds": "60",
    "lyric": "In the palms of my hand",
    "number": "4405",
  },
  {
    "artist": "Pat Benatar",
    "song": "Treat Me Right",
    "year": "1980",
    "seconds": "50",
    "lyric": "Maybe you’ll see the light",
    "number": "4406",
  },
  {
    "artist": "Ella Mai",
    "song": "Trip",
    "year": "2018",
    "seconds": "60",
    "lyric": "It’s too good to be true",
    "number": "4407",
  },
  {
    "artist": "The Jimmy Castor Bunch",
    "song": "Troglodyte (Cave Man)",
    "year": "1972",
    "seconds": "40",
    "lyric": "Swimming or washing clothes",
    "number": "4408",
  },
  {
    "artist": "George Strait",
    "song": "Troubadour",
    "year": "2008",
    "seconds": "60",
    "lyric": "I rode in on a song",
    "number": "4409",
  },
  {
    "artist": "Loretta Lynn",
    "song": "Trouble in Paradise",
    "year": "1974",
    "seconds": "60",
    "lyric": "I know the sign so well",
    "number": "4410",
  },
  {
    "artist": "Grateful Dead",
    "song": "Truckin’",
    "year": "1970",
    "seconds": "60",
    "lyric": "Cats you meet on the street",
    "number": "4411",
  },
  {
    "artist": "George Strait",
    "song": "True",
    "year": "1998",
    "seconds": "60",
    "lyric": "The light in a baby’s smile",
    "number": "4412",
  },
  {
    "artist": "Cyndi Lauper",
    "song": "True Colors",
    "year": "1986",
    "seconds": "50",
    "lyric": "You with the sad eyes",
    "number": "4413",
  },
  {
    "artist": "Buddy Holly",
    "song": "True Love Ways",
    "year": "1958",
    "seconds": "60",
    "lyric": "Sometimes we’ll sigh, sometimes we’ll cry",
    "number": "4414",
  },
  {
    "artist": "Lionel Richie",
    "song": "Truly",
    "year": "1982",
    "seconds": "60",
    "lyric": "Head over heels with your love",
    "number": "4415",
  },
  {
    "artist": "Savage Garden",
    "song": "Truly Madly Deeply",
    "year": "1997",
    "seconds": "60",
    "lyric": "Stand with you on a mountain",
    "number": "4416",
  },
  {
    "artist": "Etta James",
    "song": "Trust In Me",
    "year": "1961",
    "seconds": "60",
    "lyric": "And love will see us through",
    "number": "4417",
  },
  {
    "artist": "Glen Campbell",
    "song": "Try a Little Kindness",
    "year": "1969",
    "seconds": "60",
    "lyric": "Your brother standing by the road",
    "number": "4418",
  },
  {
    "artist": "Otis Redding",
    "song": "Try a Little Tenderness",
    "year": "1966",
    "seconds": "60",
    "lyric": "Young girls, they do get weary",
    "number": "4419",
  },
  {
    "artist": "Aaliyah",
    "song": "Try Again",
    "year": "2000",
    "seconds": "60",
    "lyric": "Our chemistry is off the chain",
    "number": "4420",
  },
  {
    "artist": "Andy Williams",
    "song": "Try to Remember",
    "year": "1966",
    "seconds": "70",
    "lyric": "Grass was green and grain was yellow",
    "number": "4421",
  },
  {
    "artist": "The Oak Ridge Boys",
    "song": "Trying to Love Two Women",
    "year": "1980",
    "seconds": "40",
    "lyric": "One’s got my money",
    "number": "4422",
  },
  {
    "artist": "The Fabulous Thunderbirds",
    "song": "Tuff Enuff",
    "year": "1986",
    "seconds": "60",
    "lyric": "A lion and a grizzly bear",
    "number": "4423",
  },
  {
    "artist": "Rolling Stones",
    "song": "Tumbling Dice",
    "year": "1972",
    "seconds": "50",
    "lyric": "Burn the candle right down",
    "number": "4424",
  },
  {
    "artist": "Gene Autry",
    "song": "Tumbling Tumbleweeds",
    "year": "1935",
    "seconds": "40",
    "lyric": "I’ll keep rolling along",
    "number": "4425",
  },
  {
    "artist": "Bruce Springsteen",
    "song": "Tunnel of Love",
    "year": "1987",
    "seconds": "70",
    "lyric": "Fat man sitting on a little stool",
    "number": "4426",
  },
  {
    "artist": "Van Morrison",
    "song": "Tupelo Honey",
    "year": "1971",
    "seconds": "60",
    "lyric": "An angel of the first degree",
    "number": "4427",
  },
  {
    "artist": "Gid Tanner & His Skillet Lickers",
    "song": "Turkey in the Straw",
    "year": "1926",
    "seconds": "50",
    "lyric": "I hitched up the wagon",
    "number": "4428",
  },
  {
    "artist": "Tyrone Davis",
    "song": "Turn Back the Hands of Time",
    "year": "1970",
    "seconds": "60",
    "lyric": "Wishing today on a four-leaf clover",
    "number": "4429",
  },
  {
    "artist": "Loverboy",
    "song": "Turn Me Loose",
    "year": "1980",
    "seconds": "50",
    "lyric": "I’m even on my knees",
    "number": "4430",
  },
  {
    "artist": "Ronnie Milsap",
    "song": "Turn That Radio On",
    "year": "1990",
    "seconds": "50",
    "lyric": "Back in your arms again",
    "number": "4431",
  },
  {
    "artist": "Vicki Sue Robinson",
    "song": "Turn the Beat Around",
    "year": "1976",
    "seconds": "80",
    "lyric": "Move your feet when you feel the beat",
    "number": "4432",
  },
  {
    "artist": "Bob Seger",
    "song": "Turn the Page",
    "year": "1973",
    "seconds": "50",
    "lyric": "You lie awake in bed",
    "number": "4433",
  },
  {
    "artist": "Pete Seeger",
    "song": "Turn! Turn! Turn!",
    "year": "1962",
    "seconds": "50",
    "lyric": "A time to be born",
    "number": "4434",
  },
  {
    "artist": "Autograph",
    "song": "Turn Up the Radio",
    "year": "1984",
    "seconds": "50",
    "lyric": "When I’m sleeping it off",
    "number": "4435",
  },
  {
    "artist": "ZZ Top",
    "song": "Tush",
    "year": "1975",
    "seconds": "60",
    "lyric": "I been bad, I been good",
    "number": "4436",
  },
  {
    "artist": "Little Richard",
    "song": "Tutti-Frutti",
    "year": "1956",
    "seconds": "60",
    "lyric": "I got a girl named Daisy",
    "number": "4437",
  },
  {
    "artist": "LaVern Baker",
    "song": "Tweedlee Dee",
    "year": "1954",
    "seconds": "40",
    "lyric": "See my honey tonight",
    "number": "4438",
  },
  {
    "artist": "The Beatles",
    "song": "Twist and Shout",
    "year": "1963",
    "seconds": "60",
    "lyric": "You know you look so good",
    "number": "4439",
  },
  {
    "artist": "Keith Sweat",
    "song": "Twisted",
    "year": "1996",
    "seconds": "70",
    "lyric": "You had my heart from the start",
    "number": "4440",
  },
  {
    "artist": "Sam Cooke",
    "song": "Twistin’ the Night Away",
    "year": "1962",
    "seconds": "60",
    "lyric": "Dancing with a chick in slacks",
    "number": "4441",
  },
  {
    "artist": "Carrie Underwood",
    "song": "Two Black Cadillacs",
    "year": "2012",
    "seconds": "50",
    "lyric": "Driving in a slow parade",
    "number": "4442",
  },
  {
    "artist": "Dolly Parton",
    "song": "Two Doors Down",
    "year": "1978",
    "seconds": "40",
    "lyric": "They’re laughing and drinkin’",
    "number": "4443",
  },
  {
    "artist": "Shenandoah",
    "song": "Two Dozen Roses",
    "year": "1989",
    "seconds": "60",
    "lyric": "I brought flowers to your door",
    "number": "4444",
  },
  {
    "artist": "Boys Like Girls (feat. Taylor Swift)",
    "song": "Two is Better Than One",
    "year": "2009",
    "seconds": "60",
    "lyric": "The way you roll your eyes",
    "number": "4445",
  },
  {
    "artist": "Emmylou Harris",
    "song": "Two More Bottles of Wine",
    "year": "1978",
    "seconds": "60",
    "lyric": "But I’m sweeping out a warehouse",
    "number": "4446",
  },
  {
    "artist": "The Beatles",
    "song": "Two of Us",
    "year": "1970",
    "seconds": "50",
    "lyric": "You and me, Sunday driving",
    "number": "4447",
  },
  {
    "artist": "Meat Loaf",
    "song": "Two Out of Three Ain’t Bad",
    "year": "1978",
    "seconds": "60",
    "lyric": "Baby we can talk all night",
    "number": "4448",
  },
  {
    "artist": "Garth Brooks",
    "song": "Two Piña Coladas",
    "year": "1997",
    "seconds": "40",
    "lyric": "One for each hand",
    "number": "4449",
  },
  {
    "artist": "Spin Doctors",
    "song": "Two Princes",
    "year": "1991",
    "seconds": "60",
    "lyric": "One has diamonds in his pockets",
    "number": "4450",
  },
  {
    "artist": "Tanya Tucker",
    "song": "Two Sparrows in a Hurricane",
    "year": "1992",
    "seconds": "50",
    "lyric": "A head full of dreams",
    "number": "4451",
  },
  {
    "artist": "Eddie Money",
    "song": "Two Tickets to Paradise",
    "year": "1977",
    "seconds": "50",
    "lyric": "Won’t you pack your bags",
    "number": "4452",
  },
  {
    "artist": "Tina Turner",
    "song": "Typical Male",
    "year": "1986",
    "seconds": "60",
    "lyric": "Loosen off the shirt and tie",
    "number": "4453",
  },
  {
    "artist": "MC Hammer",
    "song": "U Can’t Touch This",
    "year": "1990",
    "seconds": "50",
    "lyric": "So pass them a mic",
    "number": "4454",
  },
  {
    "artist": "Usher",
    "song": "U Got it Bad",
    "year": "2001",
    "seconds": "70",
    "lyric": "Hang up and you call right back",
    "number": "4455",
  },
  {
    "artist": "Prince (ft. Sheena Easton)",
    "song": "U Got the Look",
    "year": "1987",
    "seconds": "60",
    "lyric": "You walked in, I woke up",
    "number": "4456",
  },
  {
    "artist": "Rihanna (feat. Jay-Z)",
    "song": "Umbrella",
    "year": "2007",
    "seconds": "60",
    "lyric": "Stack chips for the rainy day",
    "number": "4457",
  },
  {
    "artist": "Tim McGraw",
    "song": "Unbroken",
    "year": "2002",
    "seconds": "50",
    "lyric": "Pieces of a jagged heart",
    "number": "4458",
  },
  {
    "artist": "Ray Charles",
    "song": "Unchain My Heart",
    "year": "1961",
    "seconds": "60",
    "lyric": "A bag of beans for me",
    "number": "4459",
  },
  {
    "artist": "The Righteous Brothers",
    "song": "Unchained Melody",
    "year": "1965",
    "seconds": "50",
    "lyric": "I’ve hungered for your touch",
    "number": "4460",
  },
  {
    "artist": "Paul & Linda McCartney",
    "song": "Uncle Albert/Admiral Halsey",
    "year": "1971",
    "seconds": "40",
    "lyric": "Hands across the water",
    "number": "4461",
  },
  {
    "artist": "Bill Monroe",
    "song": "Uncle Pen",
    "year": "1950",
    "seconds": "40",
    "lyric": "Hung up his fiddle",
    "number": "4462",
  },
  {
    "artist": "Rolling Stones",
    "song": "Under My Thumb",
    "year": "1966",
    "seconds": "60",
    "lyric": "A Siamese cat of a girl",
    "number": "4463",
  },
  {
    "artist": "Queen & David Bowie",
    "song": "Under Pressure",
    "year": "1981",
    "seconds": "80",
    "lyric": "Pushing down on me, pressing down on you",
    "number": "4464",
  },
  {
    "artist": "The Drifters",
    "song": "Under the Boardwalk",
    "year": "1964",
    "seconds": "60",
    "lyric": "The hot dogs and french fries",
    "number": "4465",
  },
  {
    "artist": "Red Hot Chili Peppers",
    "song": "Under the Bridge",
    "year": "1991",
    "seconds": "50",
    "lyric": "I walk through her hills",
    "number": "4466",
  },
  {
    "artist": "Buck Owens",
    "song": "Under the Influence of Love",
    "year": "1961",
    "seconds": "60",
    "lyric": "But I just close my eyes",
    "number": "4467",
  },
  {
    "artist": "Alan O’Day",
    "song": "Undercover Angel",
    "year": "1977",
    "seconds": "70",
    "lyric": "I’m gonna take you in my arms",
    "number": "4468",
  },
  {
    "artist": "Johnny Cash",
    "song": "Understand Your Man",
    "year": "1964",
    "seconds": "50",
    "lyric": "The wild goose in winter",
    "number": "4469",
  },
  {
    "artist": "Carrie Underwood",
    "song": "Undo It",
    "year": "2010",
    "seconds": "70",
    "lyric": "Your photos don’t have a picture frame",
    "number": "4470",
  },
  {
    "artist": "The Guess Who",
    "song": "Undun",
    "year": "1969",
    "seconds": "40",
    "lyric": "She’s lost the sun",
    "number": "4471",
  },
  {
    "artist": "Rihanna",
    "song": "Unfaithful",
    "year": "2006",
    "seconds": "60",
    "lyric": "I feel it in the air",
    "number": "4472",
  },
  {
    "artist": "Nat King Cole",
    "song": "Unforgettable",
    "year": "1952",
    "seconds": "40",
    "lyric": "Though near or far",
    "number": "4473",
  },
  {
    "artist": "The Brotherhood of Man",
    "song": "United We Stand",
    "year": "1970",
    "seconds": "60",
    "lyric": "The world around you falls apart",
    "number": "4474",
  },
  {
    "artist": "Buffy Sainte-Marie",
    "song": "Universal Soldier",
    "year": "1964",
    "seconds": "50",
    "lyric": "And he’s six feet four",
    "number": "4475",
  },
  {
    "artist": "TLC",
    "song": "UnPretty",
    "year": "1999",
    "seconds": "60",
    "lyric": "Tie you up in my shoes",
    "number": "4476",
  },
  {
    "artist": "X Ambassadors",
    "song": "Unsteady",
    "year": "2015",
    "seconds": "50",
    "lyric": "But if you love me",
    "number": "4477",
  },
  {
    "artist": "George Strait",
    "song": "Unwound",
    "year": "1981",
    "seconds": "50",
    "lyric": "Well she packed my bags",
    "number": "4478",
  },
  {
    "artist": "Bobby Darin",
    "song": "(Up a) Lazy River",
    "year": "1960",
    "seconds": "50",
    "lyric": "By the old mill stream",
    "number": "4479",
  },
  {
    "artist": "Creedence Clearwater Revival",
    "song": "Up Around the Bend",
    "year": "1970",
    "seconds": "50",
    "lyric": "Come on the risin’ wind",
    "number": "4480",
  },
  {
    "artist": "Morgan Wallen",
    "song": "Up Down",
    "year": "2018",
    "seconds": "60",
    "lyric": "But the bobber’s in the pond",
    "number": "4481",
  },
  {
    "artist": "The Drifters",
    "song": "Up On the Roof",
    "year": "1963",
    "seconds": "60",
    "lyric": "To the top of the stairs",
    "number": "4482",
  },
  {
    "artist": "The 5th Dimension",
    "song": "Up, Up and Away",
    "year": "1967",
    "seconds": "50",
    "lyric": "Ride in my beautiful balloon",
    "number": "4483",
  },
  {
    "artist": "Ani DiFranco",
    "song": "Up Up Up Up Up Up",
    "year": "1998",
    "seconds": "50",
    "lyric": "The steam from the sewer",
    "number": "4484",
  },
  {
    "artist": "Joe Cocker & Jennifer Warnes",
    "song": "Up Where We Belong",
    "year": "1982",
    "seconds": "70",
    "lyric": "The eagles cry, on a mountain high",
    "number": "4485",
  },
  {
    "artist": "Diana Ross",
    "song": "Upside Down",
    "year": "1980",
    "seconds": "60",
    "lyric": "Around and round you’re turning me",
    "number": "4486",
  },
  {
    "artist": "Jack Johnson",
    "song": "Upside Down",
    "year": "2006",
    "seconds": "40",
    "lyric": "We’ll sing and dance",
    "number": "4487",
  },
  {
    "artist": "Stevie Wonder",
    "song": "Uptight (Everything’s Alright)",
    "year": "1966",
    "seconds": "60",
    "lyric": "The apple of my girl’s eye",
    "number": "4488",
  },
  {
    "artist": "Mark Ronson (feat. Bruno Mars)",
    "song": "Uptown Funk",
    "year": "2014",
    "seconds": "60",
    "lyric": "Call the police and the fireman",
    "number": "4489",
  },
  {
    "artist": "Billy Joel",
    "song": "Uptown Girl",
    "year": "1983",
    "seconds": "50",
    "lyric": "In her white bread world",
    "number": "4490",
  },
  {
    "artist": "Pink Floyd",
    "song": "Us & Them",
    "year": "1973",
    "seconds": "60",
    "lyric": "And the lines on the map",
    "number": "4491",
  },
  {
    "artist": "Bill Withers",
    "song": "Use Me",
    "year": "1972",
    "seconds": "50",
    "lyric": "You were in my shoes",
    "number": "4492",
  },
  {
    "artist": "Kings of Leon",
    "song": "Use Somebody",
    "year": "2008",
    "seconds": "50",
    "lyric": "Painted faces fill the places",
    "number": "4493",
  },
  {
    "artist": "The O’Jays",
    "song": "Use ta Be My Girl",
    "year": "1978",
    "seconds": "70",
    "lyric": "The girl was so right for me",
    "number": "4494",
  },
  {
    "artist": "Fats Domino",
    "song": "Valley of Tears",
    "year": "1957",
    "seconds": "50",
    "lyric": "A kiss and a song",
    "number": "4495",
  },
  {
    "artist": "The Ides of March",
    "song": "Vehicle",
    "year": "1970",
    "seconds": "60",
    "lyric": "Take you to the nearest star",
    "number": "4496",
  },
  {
    "artist": "America",
    "song": "Ventura Highway",
    "year": "1972",
    "seconds": "60",
    "lyric": "Chewing on a piece of grass",
    "number": "4497",
  },
  {
    "artist": "Shocking Blue",
    "song": "Venus",
    "year": "1969",
    "seconds": "60",
    "lyric": "I’m your fire, at your desire",
    "number": "4498",
  },
  {
    "artist": "U2",
    "song": "Vertigo",
    "year": "2004",
    "seconds": "50",
    "lyric": "Turn it up loud, Captain",
    "number": "4499",
  },
  {
    "artist": "Lana Del Rey",
    "song": "Video Games",
    "year": "2011",
    "seconds": "50",
    "lyric": "In his favorite sun dress",
    "number": "4500",
  },
  {
    "artist": "Don McLean",
    "song": "Vincent",
    "year": "1972",
    "seconds": "50",
    "lyric": "Portraits hung in empty halls",
    "number": "4501",
  },
  {
    "artist": "Coldplay",
    "song": "Violet Hill",
    "year": "2008",
    "seconds": "50",
    "lyric": "There we sat in snow",
    "number": "4502",
  },
  {
    "artist": "Mariah Carey",
    "song": "Vision of Love",
    "year": "1990",
    "seconds": "40",
    "lyric": "Prayed through the nights",
    "number": "4503",
  },
  {
    "artist": "Coldplay",
    "song": "Viva La Vida",
    "year": "2008",
    "seconds": "70",
    "lyric": "Be my mirror, my sword and shield",
    "number": "4504",
  },
  {
    "artist": "Madonna",
    "song": "Vogue",
    "year": "1990",
    "seconds": "50",
    "lyric": "It’s called a dance floor",
    "number": "4505",
  },
  {
    "artist": "Alice in Chains",
    "song": "Voices",
    "year": "2013",
    "seconds": "60",
    "lyric": "Think I’ll go back to sleep",
    "number": "4506",
  },
  {
    "artist": "Chris Young",
    "song": "Voices",
    "year": "2008",
    "seconds": "60",
    "lyric": "Running around here in my brain",
    "number": "4507",
  },
  {
    "artist": "Dean Martin",
    "song": "Volaré",
    "year": "1958",
    "seconds": "60",
    "lyric": "Fly way up to the clouds",
    "number": "4508",
  },
  {
    "artist": "Johnny Cash",
    "song": "Wabash Cannonball",
    "year": "1966",
    "seconds": "50",
    "lyric": "The rumble and the roar",
    "number": "4509",
  },
  {
    "artist": "Old Crow Medicine Show",
    "song": "Wagon Wheel",
    "year": "2004",
    "seconds": "60",
    "lyric": "Like the wind and the rain",
    "number": "4510",
  },
  {
    "artist": "Buck Owens",
    "song": "Waitin’ in Your Welfare Line",
    "year": "1966",
    "seconds": "50",
    "lyric": "The shirt on my back",
    "number": "4511",
  },
  {
    "artist": "Rolling Stones",
    "song": "Waiting on a Friend",
    "year": "1981",
    "seconds": "40",
    "lyric": "Watching girls passing by",
    "number": "4512",
  },
  {
    "artist": "Brad Paisley",
    "song": "Waitin’ on a Woman",
    "year": "2008",
    "seconds": "60",
    "lyric": "He sat down in his overalls",
    "number": "4513",
  },
  {
    "artist": "Wham!",
    "song": "Wake Me Up Before You Go-Go",
    "year": "1984",
    "seconds": "50",
    "lyric": "Hangin’ on like a yo-yo",
    "number": "4514",
  },
  {
    "artist": "Green Day",
    "song": "Wake Me Up When September Ends",
    "year": "2005",
    "seconds": "40",
    "lyric": "Falling from the stars",
    "number": "4515",
  },
  {
    "artist": "The Everly Brothers",
    "song": "Wake Up Little Susie",
    "year": "1957",
    "seconds": "60",
    "lyric": "The movie’s over, it’s four o’clock",
    "number": "4516",
  },
  {
    "artist": "Pantera",
    "song": "Walk",
    "year": "1993",
    "seconds": "50",
    "lyric": "Can you hear the violins?",
    "number": "4517",
  },
  {
    "artist": "Joe South",
    "song": "Walk a Mile in My Shoes",
    "year": "1970",
    "seconds": "60",
    "lyric": "The wings of a little angel",
    "number": "4518",
  },
  {
    "artist": "The Left Banke",
    "song": "Walk Away Renée",
    "year": "1966",
    "seconds": "60",
    "lyric": "See me follow you back home",
    "number": "4519",
  },
  {
    "artist": "Tony Martin",
    "song": "Walk Hand in Hand",
    "year": "1956",
    "seconds": "50",
    "lyric": "Lift your head up high",
    "number": "4520",
  },
  {
    "artist": "The 4 Seasons",
    "song": "Walk Like a Man",
    "year": "1963",
    "seconds": "60",
    "lyric": "To cut me down to size",
    "number": "4521",
  },
  {
    "artist": "The Bangles",
    "song": "Walk Like an Egyptian",
    "year": "1986",
    "seconds": "60",
    "lyric": "Hanging out in the donut shop",
    "number": "4522",
  },
  {
    "artist": "Pink",
    "song": "Walk Me Home",
    "year": "2019",
    "seconds": "60",
    "lyric": "The way you roll your eyes",
    "number": "4523",
  },
  {
    "artist": "U2",
    "song": "Walk On",
    "year": "2001",
    "seconds": "50",
    "lyric": "Your glass heart should crack",
    "number": "4524",
  },
  {
    "artist": "Dionne Warwick",
    "song": "Walk On By",
    "year": "1964",
    "seconds": "50",
    "lyric": "And I start to cry",
    "number": "4525",
  },
  {
    "artist": "Lou Reed",
    "song": "Walk on the Wild Side",
    "year": "1972",
    "seconds": "60",
    "lyric": "Plucked her eyebrows on the way",
    "number": "4526",
  },
  {
    "artist": "The Everly Brothers",
    "song": "Walk Right Back",
    "year": "1961",
    "seconds": "50",
    "lyric": "Bring your love to me",
    "number": "4527",
  },
  {
    "artist": "Gus Cannon & Hosea Woods",
    "song": "Walk Right In",
    "year": "1929",
    "seconds": "50",
    "lyric": "A new way of walkin’",
    "number": "4528",
  },
  {
    "artist": "Aerosmith",
    "song": "Walk This Way",
    "year": "1975",
    "seconds": "50",
    "lyric": "Started with a little kiss",
    "number": "4529",
  },
  {
    "artist": "George Jones",
    "song": "Walk Through This World With Me",
    "year": "1964",
    "seconds": "40",
    "lyric": "New horizons I see",
    "number": "4530",
  },
  {
    "artist": "Trisha Yearwood (feat. Don Henley)",
    "song": "Walkaway Joe",
    "year": "1992",
    "seconds": "50",
    "lyric": "On a tank of dreams",
    "number": "4531",
  },
  {
    "artist": "Clint Black",
    "song": "Walkin’ Away",
    "year": "1990",
    "seconds": "40",
    "lyric": "I’ve stopped lookin’ behind",
    "number": "4532",
  },
  {
    "artist": "Love Unlimited",
    "song": "Walkin’ in the Rain With the One I Love",
    "year": "1972",
    "seconds": "60",
    "lyric": "So in love with each other",
    "number": "4533",
  },
  {
    "artist": "Katrina and the Waves",
    "song": "Walking on Sunshine",
    "year": "1985",
    "seconds": "50",
    "lyric": "You knock on my door",
    "number": "4534",
  },
  {
    "artist": "Smash Mouth",
    "song": "Walkin’ on the Sun",
    "year": "1997",
    "seconds": "60",
    "lyric": "Six to eight years to arrive",
    "number": "4535",
  },
  {
    "artist": "Tanya Tucker",
    "song": "Walking Shoes",
    "year": "1990",
    "seconds": "50",
    "lyric": "Wearin’ my old travelin’ hat",
    "number": "4536",
  },
  {
    "artist": "Fats Domino",
    "song": "Walking to New Orleans",
    "year": "1960",
    "seconds": "60",
    "lyric": "I’ll see you by and by",
    "number": "4537",
  },
  {
    "artist": "Tom Petty & the Heartbreakers",
    "song": "Walls",
    "year": "1996",
    "seconds": "60",
    "lyric": "You got a heart so big",
    "number": "4538",
  },
  {
    "artist": "Slim Dusty",
    "song": "Waltzing Matilda",
    "year": "1960",
    "seconds": "50",
    "lyric": "And grabbed him with glee",
    "number": "4539",
  },
  {
    "artist": "Michael Jackson",
    "song": "Wanna Be Startin’ Somethin’",
    "year": "1983",
    "seconds": "50",
    "lyric": "And the pain is thunder",
    "number": "4540",
  },
  {
    "artist": "Spice Girls",
    "song": "Wannabe",
    "year": "1996",
    "seconds": "50",
    "lyric": "If you really bug me",
    "number": "4541",
  },
  {
    "artist": "Honey Cone",
    "song": "Want Ads",
    "year": "1971",
    "seconds": "60",
    "lyric": "My man and I are through",
    "number": "4542",
  },
  {
    "artist": "Sugarland",
    "song": "Want To",
    "year": "2006",
    "seconds": "50",
    "lyric": "Your ring around my neck",
    "number": "4543",
  },
  {
    "artist": "Perry Como",
    "song": "Wanted",
    "year": "1954",
    "seconds": "60",
    "lyric": "A jury may find her guilty",
    "number": "4544",
  },
  {
    "artist": "Bon Jovi",
    "song": "Wanted: Dead or Alive",
    "year": "1986",
    "seconds": "70",
    "lyric": "I’m a cowboy, on a steel horse",
    "number": "4545",
  },
  {
    "artist": "Edwin Starr",
    "song": "War",
    "year": "1970",
    "seconds": "60",
    "lyric": "Tears to thousands of mothers’ eyes",
    "number": "4546",
  },
  {
    "artist": "Van Morrison",
    "song": "Warm Love",
    "year": "1973",
    "seconds": "50",
    "lyric": "The green grass so tall",
    "number": "4547",
  },
  {
    "artist": "Carrie Underwood",
    "song": "Wasted",
    "year": "2007",
    "seconds": "50",
    "lyric": "It fell like broken glass",
    "number": "4548",
  },
  {
    "artist": "Def Leppard",
    "song": "Wasted",
    "year": "1979",
    "seconds": "50",
    "lyric": "I’m stuck in a hole",
    "number": "4549",
  },
  {
    "artist": "Tiësto (ft. Matthew Koma)",
    "song": "Wasted",
    "year": "2014",
    "seconds": "70",
    "lyric": "Lay all your laundry on the bed",
    "number": "4550",
  },
  {
    "artist": "Cloud Nothings",
    "song": "Wasted Days",
    "year": "2012",
    "seconds": "50",
    "lyric": "I’m losing all my time",
    "number": "4551",
  },
  {
    "artist": "Freddy Fender",
    "song": "Wasted Days and Wasted Nights",
    "year": "1975",
    "seconds": "40",
    "lyric": "For making me blue",
    "number": "4552",
  },
  {
    "artist": "Crosby, Stills & Nash",
    "song": "Wasted on the Way",
    "year": "1982",
    "seconds": "50",
    "lyric": "Water moving underneath the bridge",
    "number": "4553",
  },
  {
    "artist": "Keith Urban",
    "song": "Wasted Time",
    "year": "2016",
    "seconds": "60",
    "lyric": "And the rope by the river",
    "number": "4554",
  },
  {
    "artist": "Dallas Smith",
    "song": "Wastin’ Gas",
    "year": "2014",
    "seconds": "50",
    "lyric": "Steal a kiss, maybe two",
    "number": "4555",
  },
  {
    "artist": "Billie Eilish",
    "song": "Watch",
    "year": "2017",
    "seconds": "50",
    "lyric": "My heart skips eight beats",
    "number": "4556",
  },
  {
    "artist": "Tim McGraw",
    "song": "Watch the Wind Blow By",
    "year": "2003",
    "seconds": "60",
    "lyric": "Lay down in the tall grass",
    "number": "4557",
  },
  {
    "artist": "Bobby Goldsboro",
    "song": "Watching Scotty Grow",
    "year": "1970",
    "seconds": "60",
    "lyric": "A handsome lad, that’s my boy",
    "number": "4558",
  },
  {
    "artist": "Brad Paisley",
    "song": "Water",
    "year": "2010",
    "seconds": "40",
    "lyric": "Grab your swimming trunks",
    "number": "4559",
  },
  {
    "artist": "Adele",
    "song": "Water Under the Bridge",
    "year": "2016",
    "seconds": "70",
    "lyric": "I can bring you to your knees",
    "number": "4560",
  },
  {
    "artist": "TLC",
    "song": "Waterfalls",
    "year": "1994",
    "seconds": "70",
    "lyric": "Stick to the rivers and the lakes",
    "number": "4561",
  },
  {
    "artist": "ABBA",
    "song": "Waterloo",
    "year": "1974",
    "seconds": "60",
    "lyric": "The history book on the shelf",
    "number": "4562",
  },
  {
    "artist": "Stonewall Jackson",
    "song": "Waterloo",
    "year": "1959",
    "seconds": "40",
    "lyric": "But lost his pants",
    "number": "4563",
  },
  {
    "artist": "Tracy Byrd",
    "song": "Watermelon Crawl",
    "year": "1994",
    "seconds": "50",
    "lyric": "She wiggled and she giggled",
    "number": "4564",
  },
  {
    "artist": "Harry Styles",
    "song": "Watermelon Sugar",
    "year": "2019",
    "seconds": "50",
    "lyric": "Strawberries on a summer evenin’",
    "number": "4565",
  },
  {
    "artist": "Van Morrison",
    "song": "Wavelength",
    "year": "1978",
    "seconds": "50",
    "lyric": "My lover in the grass",
    "number": "4566",
  },
  {
    "artist": "Elvis Presley",
    "song": "Way Down",
    "year": "1977",
    "seconds": "40",
    "lyric": "A hundred magic fingers",
    "number": "4567",
  },
  {
    "artist": "Taylor Swift",
    "song": "We Are Never Ever Getting Back Together",
    "year": "2012",
    "seconds": "60",
    "lyric": "You go talk to your friends",
    "number": "4568",
  },
  {
    "artist": "Queen",
    "song": "We Are the Champions",
    "year": "1977",
    "seconds": "50",
    "lyric": "Sand kicked in my face",
    "number": "4569",
  },
  {
    "artist": "USA for Africa",
    "song": "We Are the World",
    "year": "1985",
    "seconds": "50",
    "lyric": "By turning stone to bread",
    "number": "4570",
  },
  {
    "artist": "Fun. (feat. Janelle Monáe)",
    "song": "We Are Young",
    "year": "2012",
    "seconds": "50",
    "lyric": "Burn brighter than the sun",
    "number": "4571",
  },
  {
    "artist": "Pat Benatar",
    "song": "We Belong",
    "year": "1984",
    "seconds": "70",
    "lyric": "Close your eyes and try to sleep",
    "number": "4572",
  },
  {
    "artist": "Mariah Carey",
    "song": "We Belong Together",
    "year": "2005",
    "seconds": "60",
    "lyric": "Talk to me on the phone",
    "number": "4573",
  },
  {
    "artist": "The Beatles",
    "song": "We Can Work It Out",
    "year": "1966",
    "seconds": "60",
    "lyric": "For fussing and fighting, my friend",
    "number": "4574",
  },
  {
    "artist": "Miley Cyrus",
    "song": "We Can’t Stop",
    "year": "2013",
    "seconds": "50",
    "lyric": "Red cups and sweaty bodies",
    "number": "4575",
  },
  {
    "artist": "Charley Pride",
    "song": "We Could",
    "year": "1974",
    "seconds": "50",
    "lyric": "Brings a girl and boy",
    "number": "4576",
  },
  {
    "artist": "Brad Paisley",
    "song": "We Danced",
    "year": "2000",
    "seconds": "60",
    "lyric": "But I’m afraid I left my purse",
    "number": "4577",
  },
  {
    "artist": "Tina Turner",
    "song": "We Don’t Need Another Hero",
    "year": "1985",
    "seconds": "50",
    "lyric": "Castles built in the air",
    "number": "4578",
  },
  {
    "artist": "Rihanna (feat. Calvin Harris)",
    "song": "We Found Love",
    "year": "2011",
    "seconds": "50",
    "lyric": "Yellow diamonds in the light",
    "number": "4579",
  },
  {
    "artist": "The Animals",
    "song": "We Gotta Get Out of This Place",
    "year": "1965",
    "seconds": "60",
    "lyric": "Girl, you’re so young and pretty",
    "number": "4580",
  },
  {
    "artist": "Vengaboys",
    "song": "We Like to Party",
    "year": "1998",
    "seconds": "60",
    "lyric": "The wheels of steel are turnin’",
    "number": "4581",
  },
  {
    "artist": "George Strait",
    "song": "We Really Shouldn’t Be Doing This",
    "year": "1998",
    "seconds": "50",
    "lyric": "Think about a goodbye kiss",
    "number": "4582",
  },
  {
    "artist": "Pete Seeger",
    "song": "We Shall Overcome",
    "year": "1963",
    "seconds": "70",
    "lyric": "We shall all be free, some day",
    "number": "4583",
  },
  {
    "artist": "Clint Black",
    "song": "We Tell Ourselves",
    "year": "1992",
    "seconds": "50",
    "lyric": "Determined not to wind up",
    "number": "4584",
  },
  {
    "artist": "Toby Keith",
    "song": "We Were in Love",
    "year": "1997",
    "seconds": "60",
    "lyric": "A fire down in our souls",
    "number": "4585",
  },
  {
    "artist": "Keith Urban (ft. Miranda Lambert)",
    "song": "We Were Us",
    "year": "2013",
    "seconds": "60",
    "lyric": "Shotgun sunset, a cool mint kiss",
    "number": "4586",
  },
  {
    "artist": "Queen",
    "song": "We Will Rock You",
    "year": "1977",
    "seconds": "60",
    "lyric": "You got mud on your face",
    "number": "4587",
  },
  {
    "artist": "The 5th Dimension",
    "song": "Wedding Bell Blues",
    "year": "1969",
    "seconds": "50",
    "lyric": "‘Til you marry me Bill",
    "number": "4588",
  },
  {
    "artist": "Barry Manilow",
    "song": "Weekend in New England",
    "year": "1976",
    "seconds": "50",
    "lyric": "I feel the wind blow",
    "number": "4589",
  },
  {
    "artist": "Brooks & Dunn",
    "song": "We’ll Burn That Bridge",
    "year": "1993",
    "seconds": "60",
    "lyric": "Good to see your dancin’ feet",
    "number": "4590",
  },
  {
    "artist": "Gale Garnett",
    "song": "We’ll Sing in the Sunshine",
    "year": "1964",
    "seconds": "60",
    "lyric": "But darling, don’t cling to me",
    "number": "4591",
  },
  {
    "artist": "Hey Rosetta!",
    "song": "Welcome",
    "year": "2011",
    "seconds": "50",
    "lyric": "All the doctors blinking hard",
    "number": "4592",
  },
  {
    "artist": "John Sebastian",
    "song": "Welcome Back",
    "year": "1976",
    "seconds": "60",
    "lyric": "We got him on the spot",
    "number": "4593",
  },
  {
    "artist": "Jim Reeves",
    "song": "Welcome to My World",
    "year": "1964",
    "seconds": "40",
    "lyric": "Waiting just for you",
    "number": "4594",
  },
  {
    "artist": "My Chemical Romance",
    "song": "Welcome to the Black Parade",
    "year": "2006",
    "seconds": "50",
    "lyric": "To see a marching band",
    "number": "4595",
  },
  {
    "artist": "Brad Paisley",
    "song": "Welcome to the Future",
    "year": "2009",
    "seconds": "50",
    "lyric": "He wrote a hundred letters",
    "number": "4596",
  },
  {
    "artist": "Guns N’ Roses",
    "song": "Welcome to the Jungle",
    "year": "1987",
    "seconds": "60",
    "lyric": "You can taste the bright lights",
    "number": "4597",
  },
  {
    "artist": "Grand Funk Railroad",
    "song": "We’re an American Band",
    "year": "1973",
    "seconds": "60",
    "lyric": "On the road for forty days",
    "number": "4598",
  },
  {
    "artist": "The White Stripes",
    "song": "We’re Going to be Friends",
    "year": "2001",
    "seconds": "60",
    "lyric": "Climb the fence, books and pens",
    "number": "4599",
  },
  {
    "artist": "Warren Zevon",
    "song": "Werewolves of London",
    "year": "1978",
    "seconds": "60",
    "lyric": "Big dish of beef chow mein",
    "number": "4600",
  },
  {
    "artist": "Kenny Rogers & Sheena Easton",
    "song": "We’ve Got Tonight",
    "year": "1983",
    "seconds": "50",
    "lyric": "Look at the stars now",
    "number": "4601",
  },
  {
    "artist": "The Carpenters",
    "song": "We’ve Only Just Begun",
    "year": "1970",
    "seconds": "60",
    "lyric": "Before the rising sun, we fly",
    "number": "4602",
  },
  {
    "artist": "Dinah Washington",
    "song": "What a Difference a Day Makes",
    "year": "1959",
    "seconds": "60",
    "lyric": "Brought the sun and the flowers",
    "number": "4603",
  },
  {
    "artist": "Ronnie Milsap",
    "song": "What a Difference You’ve Made in My Life",
    "year": "1977",
    "seconds": "60",
    "lyric": "You replaced all the broken parts",
    "number": "4604",
  },
  {
    "artist": "Doobie Brothers",
    "song": "What a Fool Believes",
    "year": "1979",
    "seconds": "50",
    "lyric": "Back in her long ago",
    "number": "4605",
  },
  {
    "artist": "Christina Aguilera",
    "song": "What a Girl Wants",
    "year": "1999",
    "seconds": "50",
    "lyric": "Keeps me in your arms",
    "number": "4606",
  },
  {
    "artist": "Louis Armstrong",
    "song": "What a Wonderful World",
    "year": "1967",
    "seconds": "50",
    "lyric": "I see trees of green",
    "number": "4607",
  },
  {
    "artist": "Heart",
    "song": "What About Love",
    "year": "1985",
    "seconds": "50",
    "lyric": "Look back on your life",
    "number": "4608",
  },
  {
    "artist": "Anne Murray",
    "song": "What About Me",
    "year": "1973",
    "seconds": "50",
    "lyric": "And I’ll just sit quiet",
    "number": "4609",
  },
  {
    "artist": "Daughtry",
    "song": "What About Now",
    "year": "2008",
    "seconds": "50",
    "lyric": "Shadows fill an empty heart",
    "number": "4610",
  },
  {
    "artist": "Lonestar",
    "song": "What About Now",
    "year": "2000",
    "seconds": "50",
    "lyric": "We’ll be kickin’ up dust",
    "number": "4611",
  },
  {
    "artist": "Reba McEntire",
    "song": "What Am I Gonna Do About You?",
    "year": "1986",
    "seconds": "50",
    "lyric": "The kid down the street",
    "number": "4612",
  },
  {
    "artist": "Dottie West & Kenny Rogers",
    "song": "What Are We Doin’ In Love?",
    "year": "1981",
    "seconds": "40",
    "lyric": "Like uptown and downtown",
    "number": "4613",
  },
  {
    "artist": "Chaka Khan",
    "song": "What Cha’ Gonna Do For Me?",
    "year": "1981",
    "seconds": "50",
    "lyric": "When the chips are down",
    "number": "4614",
  },
  {
    "artist": "George Strait",
    "song": "What Do You Say to That?",
    "year": "1999",
    "seconds": "70",
    "lyric": "Loving you won’t ever be old hat",
    "number": "4615",
  },
  {
    "artist": "Kelly Clarkson",
    "song": "What Doesn’t Kill You (Stronger)",
    "year": "2012",
    "seconds": "60",
    "lyric": "You know the bed feels warmer",
    "number": "4616",
  },
  {
    "artist": "Ronnie Milsap",
    "song": "What Goes On When the Sun Goes Down",
    "year": "1976",
    "seconds": "50",
    "lyric": "And working eight long hours",
    "number": "4617",
  },
  {
    "artist": "Melanie",
    "song": "What Have They Done to My Song Ma?",
    "year": "1972",
    "seconds": "70",
    "lyric": "Tied it up in a plastic bag",
    "number": "4618",
  },
  {
    "artist": "Janet Jackson",
    "song": "What Have You Done for Me Lately?",
    "year": "1986",
    "seconds": "60",
    "lyric": "I thought I’d lose my breath",
    "number": "4619",
  },
  {
    "artist": "Rascal Flatts",
    "song": "What Hurts the Most",
    "year": "2006",
    "seconds": "50",
    "lyric": "The rain on the roof",
    "number": "4620",
  },
  {
    "artist": "The Romantics",
    "song": "What I Like About You",
    "year": "1979",
    "seconds": "60",
    "lyric": "Keep on whispering in my ear",
    "number": "4621",
  },
  {
    "artist": "Lady Antebellum",
    "song": "What If I Never Get Over You",
    "year": "2019",
    "seconds": "50",
    "lyric": "Lost in that last goodbye",
    "number": "4622",
  },
  {
    "artist": "Jack Scott",
    "song": "What In the World’s Come Over You",
    "year": "1960",
    "seconds": "50",
    "lyric": "Dreaming of your tender kisses",
    "number": "4623",
  },
  {
    "artist": "Led Zeppelin",
    "song": "What Is and What Should Never Be",
    "year": "1969",
    "seconds": "60",
    "lyric": "You wake up with the sunrise",
    "number": "4624",
  },
  {
    "artist": "Aerosmith",
    "song": "What It Takes",
    "year": "1990",
    "seconds": "50",
    "lyric": "The toss of the dice",
    "number": "4625",
  },
  {
    "artist": "Lee Roy Parnell",
    "song": "What Kind of Fool Do You Think I Am?",
    "year": "1992",
    "seconds": "70",
    "lyric": "Throwing my good heart after bad love",
    "number": "4626",
  },
  {
    "artist": "Chicago",
    "song": "What Kind of Man Would I Be?",
    "year": "1989",
    "seconds": "40",
    "lyric": "You pull me through",
    "number": "4627",
  },
  {
    "artist": "Garth Brooks",
    "song": "What She Doing Now?",
    "year": "1991",
    "seconds": "50",
    "lyric": "I dialed her old number",
    "number": "4628",
  },
  {
    "artist": "Jackie DeShannon",
    "song": "What the World Needs Now Is Love",
    "year": "1965",
    "seconds": "60",
    "lyric": "Oceans and rivers enough to cross",
    "number": "4629",
  },
  {
    "artist": "Adam Lambert",
    "song": "Whataya Want From Me",
    "year": "2009",
    "seconds": "50",
    "lyric": "Need a second to breathe",
    "number": "4630",
  },
  {
    "artist": "Pablo Cruise",
    "song": "Whatcha Gonna Do?",
    "year": "1977",
    "seconds": "40",
    "lyric": "When she says goodbye",
    "number": "4631",
  },
  {
    "artist": "Jason DeRulo",
    "song": "Whatcha Say",
    "year": "2009",
    "seconds": "50",
    "lyric": "So wrong for so long",
    "number": "4632",
  },
  {
    "artist": "Ray Charles",
    "song": "What’d I Say",
    "year": "1959",
    "seconds": "60",
    "lyric": "Love your daddy all night long",
    "number": "4633",
  },
  {
    "artist": "B.J. Thomas",
    "song": "Whatever Happened to Old Fashioned Love",
    "year": "1983",
    "seconds": "50",
    "lyric": "And it’s hard to find",
    "number": "4634",
  },
  {
    "artist": "Zac Brown Band",
    "song": "Whatever It Is",
    "year": "2009",
    "seconds": "50",
    "lyric": "Makes my heart go haywire",
    "number": "4635",
  },
  {
    "artist": "Imagine Dragons",
    "song": "Whatever It Takes",
    "year": "2017",
    "seconds": "50",
    "lyric": "Pull me like a ripcord",
    "number": "4636",
  },
  {
    "artist": "Marvin Gaye",
    "song": "What’s Going On",
    "year": "1971",
    "seconds": "60",
    "lyric": "Come on, talk to me brother",
    "number": "4637",
  },
  {
    "artist": "George Strait",
    "song": "What’s Going On in Your World",
    "year": "1989",
    "seconds": "60",
    "lyric": "And I’ll hang up the phone",
    "number": "4638",
  },
  {
    "artist": "Busta Rhymes (ft. Janet Jackson)",
    "song": "What’s It Gonna Be",
    "year": "1999",
    "seconds": "50",
    "lyric": "You’ll be ready to floss",
    "number": "4639",
  },
  {
    "artist": "Tina Turner",
    "song": "What’s Love Got To Do With It?",
    "year": "1984",
    "seconds": "50",
    "lyric": "The touch of your hand",
    "number": "4640",
  },
  {
    "artist": "Blink 182",
    "song": "What’s My Age Again?",
    "year": "1999",
    "seconds": "50",
    "lyric": "She broke up with me",
    "number": "4641",
  },
  {
    "artist": "Tom Jones",
    "song": "What’s New Pussycat?",
    "year": "1965",
    "seconds": "60",
    "lyric": "Powder your cute little pussycat nose",
    "number": "4642",
  },
  {
    "artist": "Tanya Tucker",
    "song": "What’s Your Mama’s Name?",
    "year": "1973",
    "seconds": "40",
    "lyric": "A little green-eyed girl",
    "number": "4643",
  },
  {
    "artist": "Lynyrd Skynyrd",
    "song": "What’s Your Name?",
    "year": "1977",
    "seconds": "50",
    "lyric": "Shootin’ you straight, little girl?",
    "number": "4644",
  },
  {
    "artist": "Journey",
    "song": "Wheel in the Sky",
    "year": "1978",
    "seconds": "50",
    "lyric": "I’m stranded in the sleet",
    "number": "4645",
  },
  {
    "artist": "Percy Sledge",
    "song": "When a Man Loves a Woman",
    "year": "1966",
    "seconds": "50",
    "lyric": "She can do no wrong",
    "number": "4646",
  },
  {
    "artist": "Babyface",
    "song": "When Can I See You Again?",
    "year": "1993",
    "seconds": "50",
    "lyric": "Can my heart beat again?",
    "number": "4647",
  },
  {
    "artist": "Prince & the Revolution",
    "song": "When Doves Cry",
    "year": "1984",
    "seconds": "50",
    "lyric": "Ocean of violets in bloom",
    "number": "4648",
  },
  {
    "artist": "Vince Gill",
    "song": "When I Call Your Name",
    "year": "1990",
    "seconds": "50",
    "lyric": "A note on the table",
    "number": "4649",
  },
  {
    "artist": "Kenny Chesney",
    "song": "When I Close My Eyes",
    "year": "1996",
    "seconds": "70",
    "lyric": "You’re in my dreams throughout the day",
    "number": "4650",
  },
  {
    "artist": "Green Day",
    "song": "When I Come Around",
    "year": "1995",
    "seconds": "50",
    "lyric": "I heard you crying loud",
    "number": "4651",
  },
  {
    "artist": "Nat King Cole",
    "song": "When I Fall in Love",
    "year": "1956",
    "seconds": "40",
    "lyric": "Too many moonlight kisses",
    "number": "4652",
  },
  {
    "artist": "Brad Paisley and Dolly Parton",
    "song": "When I Get Where I’m Going",
    "year": "2005",
    "seconds": "70",
    "lyric": "And run my fingers through his mane",
    "number": "4653",
  },
  {
    "artist": "Leo Sayer",
    "song": "When I Need You",
    "year": "1977",
    "seconds": "60",
    "lyric": "Keeping me warm night and day",
    "number": "4654",
  },
  {
    "artist": "Clint Black (ft. Lisa Hartman Black)",
    "song": "When I Said I Do",
    "year": "1999",
    "seconds": "50",
    "lyric": "Here alone in our dreams",
    "number": "4655",
  },
  {
    "artist": "Janet Jackson",
    "song": "When I Think of You",
    "year": "1986",
    "seconds": "60",
    "lyric": "You hold me in your arms",
    "number": "4656",
  },
  {
    "artist": "Eminem",
    "song": "When I’m Gone",
    "year": "2005",
    "seconds": "60",
    "lyric": "Pulls out a tiny necklace locket",
    "number": "4657",
  },
  {
    "artist": "The Beatles",
    "song": "When I’m Sixty-Four",
    "year": "1967",
    "seconds": "60",
    "lyric": "Knit a sweater by the fireside",
    "number": "4658",
  },
  {
    "artist": "John Anderson",
    "song": "When It Comes to You",
    "year": "1992",
    "seconds": "70",
    "lyric": "Fire of love is dead and cold",
    "number": "4659",
  },
  {
    "artist": "Luke Combs",
    "song": "When It Rains It Pours",
    "year": "2017",
    "seconds": "50",
    "lyric": "She woke up fighting mad",
    "number": "4660",
  },
  {
    "artist": "Van Halen",
    "song": "When It’s Love",
    "year": "1988",
    "seconds": "50",
    "lyric": "To fill in the holes",
    "number": "4661",
  },
  {
    "artist": "Clint Black",
    "song": "When My Ship Comes In",
    "year": "1993",
    "seconds": "50",
    "lyric": "No sun on the Rockies",
    "number": "4662",
  },
  {
    "artist": "Jason Aldean",
    "song": "When She Says Baby",
    "year": "2013",
    "seconds": "60",
    "lyric": "Runnin’ my fist through the wall",
    "number": "4663",
  },
  {
    "artist": "The Four Tops",
    "song": "When She Was My Girl",
    "year": "1981",
    "seconds": "60",
    "lyric": "She’s so foxy, can’t you see",
    "number": "4664",
  },
  {
    "artist": "Billy Ocean",
    "song": "When the Going Gets Tough, the Tough Get Going",
    "year": "1986",
    "seconds": "50",
    "lyric": "Darling, I’ll climb any mountain",
    "number": "4665",
  },
  {
    "artist": "George Jones",
    "song": "When the Grass Grows Over Me",
    "year": "1968",
    "seconds": "50",
    "lyric": "I’m hurting through and through",
    "number": "4666",
  },
  {
    "artist": "Hoyt Axton",
    "song": "When the Morning Comes",
    "year": "1974",
    "seconds": "40",
    "lyric": "In an empty bed",
    "number": "4667",
  },
  {
    "artist": "Billie Eilish",
    "song": "When the Party’s Over",
    "year": "2018",
    "seconds": "70",
    "lyric": "Tore my shirt to stop you bleeding",
    "number": "4668",
  },
  {
    "artist": "Tim McGraw",
    "song": "When the Stars Go Blue",
    "year": "2006",
    "seconds": "50",
    "lyric": "Dancin’ in your wooden shoes",
    "number": "4669",
  },
  {
    "artist": "Kenny Chesney",
    "song": "When the Sun Goes Down",
    "year": "2004",
    "seconds": "40",
    "lyric": "Toes ticklin’ the sand",
    "number": "4670",
  },
  {
    "artist": "Adele",
    "song": "When We Were Young",
    "year": "2016",
    "seconds": "60",
    "lyric": "So scared to face my fears",
    "number": "4671",
  },
  {
    "artist": "The Everly Brothers",
    "song": "When Will I Be Loved",
    "year": "1960",
    "seconds": "70",
    "lyric": "She always breaks my heart in two",
    "number": "4672",
  },
  {
    "artist": "Mariah Carey & Whitney Houston",
    "song": "When You Believe",
    "year": "1998",
    "seconds": "50",
    "lyric": "You’re blinded by your pain",
    "number": "4673",
  },
  {
    "artist": "Journey",
    "song": "When You Love a Woman",
    "year": "1996",
    "seconds": "70",
    "lyric": "You know she’s standing by your side",
    "number": "4674",
  },
  {
    "artist": "Keith Whitley",
    "song": "When You Say Nothing At All",
    "year": "1988",
    "seconds": "50",
    "lyric": "The smile on your face",
    "number": "4675",
  },
  {
    "artist": "Jere Mahoney",
    "song": "When You Were Sweet Sixteen",
    "year": "1900",
    "seconds": "70",
    "lyric": "I saw you on the village green",
    "number": "4676",
  },
  {
    "artist": "The Killers",
    "song": "When You Were Young",
    "year": "2006",
    "seconds": "60",
    "lyric": "But you can dip your feet",
    "number": "4677",
  },
  {
    "artist": "Bryan Adams (ft. Melanie C)",
    "song": "When You’re Gone",
    "year": "1998",
    "seconds": "60",
    "lyric": "Driving up and down these streets",
    "number": "4678",
  },
  {
    "artist": "Jerry Reed",
    "song": "When You’re Hot, You’re Hot",
    "year": "1971",
    "seconds": "60",
    "lyric": "There was a big old cop",
    "number": "4679",
  },
  {
    "artist": "Dr. Hook & the Medicine Show",
    "song": "When You’re in Love With a Beautiful Woman",
    "year": "1978",
    "seconds": "40",
    "lyric": "You watch her eyes",
    "number": "4680",
  },
  {
    "artist": "Shakira",
    "song": "Whenever, Wherever",
    "year": "2002",
    "seconds": "60",
    "lyric": "Count the freckles on your body",
    "number": "4681",
  },
  {
    "artist": "Clint Black",
    "song": "Where Are You Now?",
    "year": "1991",
    "seconds": "60",
    "lyric": "And like a ball of yarn",
    "number": "4682",
  },
  {
    "artist": "The Supremes",
    "song": "Where Did Our Love Go?",
    "year": "1964",
    "seconds": "50",
    "lyric": "Burning, yearning feelin’ inside me",
    "number": "4683",
  },
  {
    "artist": "Lead Belly",
    "song": "Where Did You Sleep Last Night (In the Pines)",
    "year": "1944",
    "seconds": "60",
    "lyric": "Where the sun don’t ever shine",
    "number": "4684",
  },
  {
    "artist": "Cat Stevens",
    "song": "Where Do the Children Play?",
    "year": "1970",
    "seconds": "50",
    "lyric": "Summer from a slot machine",
    "number": "4685",
  },
  {
    "artist": "Ronnie Milsap",
    "song": "Where Do the Nights Go?",
    "year": "1987",
    "seconds": "60",
    "lyric": "Kiss you and hold you tight",
    "number": "4686",
  },
  {
    "artist": "No Mercy",
    "song": "Where Do You Go?",
    "year": "1996",
    "seconds": "60",
    "lyric": "You are running back to me",
    "number": "4687",
  },
  {
    "artist": "Céline Dion",
    "song": "Where Does My Heart Beat Now?",
    "year": "1990",
    "seconds": "50",
    "lyric": "I’m reaching for the arms",
    "number": "4688",
  },
  {
    "artist": "Buck Owens",
    "song": "Where Does the Good Times Go",
    "year": "1966",
    "seconds": "40",
    "lyric": "Cold beneath my touch",
    "number": "4689",
  },
  {
    "artist": "Pete Seeger",
    "song": "Where Have All the Flowers Gone?",
    "year": "1965",
    "seconds": "40",
    "lyric": "Girls have picked them",
    "number": "4690",
  },
  {
    "artist": "Alan Jackson",
    "song": "Where I Come From",
    "year": "2001",
    "seconds": "40",
    "lyric": "It’s cornbread and chicken",
    "number": "4691",
  },
  {
    "artist": "The Pixies",
    "song": "Where is My Mind",
    "year": "1988",
    "seconds": "60",
    "lyric": "A little fish bumped into me",
    "number": "4692",
  },
  {
    "artist": "The Black Eyed Peas",
    "song": "Where is the Love?",
    "year": "2003",
    "seconds": "70",
    "lyric": "And would you turn the other cheek?",
    "number": "4693",
  },
  {
    "artist": "Roberta Flack & Donny Hathaway",
    "song": "Where is the Love?",
    "year": "1972",
    "seconds": "50",
    "lyric": "A sudden change of heart",
    "number": "4694",
  },
  {
    "artist": "David Houston",
    "song": "Where Love Used To Live",
    "year": "1968",
    "seconds": "60",
    "lyric": "Four men in a big truck",
    "number": "4695",
  },
  {
    "artist": "Keith Urban",
    "song": "Where the Blacktop Ends",
    "year": "2001",
    "seconds": "50",
    "lyric": "And run in bare feet",
    "number": "4696",
  },
  {
    "artist": "Connie Francis",
    "song": "Where the Boys Are",
    "year": "1961",
    "seconds": "60",
    "lyric": "A smiling’ face, a warm embrace",
    "number": "4697",
  },
  {
    "artist": "Tim McGraw",
    "song": "Where the Green Grass Grows",
    "year": "1998",
    "seconds": "60",
    "lyric": "Red ants marching into the night",
    "number": "4698",
  },
  {
    "artist": "U2",
    "song": "Where the Streets Have No Name",
    "year": "1987",
    "seconds": "70",
    "lyric": "I want to tear down the walls",
    "number": "4699",
  },
  {
    "artist": "Pete Seeger",
    "song": "Which Side Are You On?",
    "year": "1967",
    "seconds": "60",
    "lyric": "Or will you be a man?",
    "number": "4700",
  },
  {
    "artist": "The Poppy Family",
    "song": "Which Way You Goin’, Billy?",
    "year": "1970",
    "seconds": "60",
    "lyric": "My heart and my soul, babe",
    "number": "4701",
  },
  {
    "artist": "The Beatles",
    "song": "While My Guitar Gently Weeps",
    "year": "1968",
    "seconds": "50",
    "lyric": "I look at you all",
    "number": "4702",
  },
  {
    "artist": "Jhené Aiko",
    "song": "While We’re Young",
    "year": "2017",
    "seconds": "60",
    "lyric": "And put me in your pocket",
    "number": "4703",
  },
  {
    "artist": "Toby Keith",
    "song": "Whiskey Girl",
    "year": "2004",
    "seconds": "50",
    "lyric": "I jump behind the wheel",
    "number": "4704",
  },
  {
    "artist": "Brad Paisley and Alison Krauss",
    "song": "Whiskey Lullaby",
    "year": "2004",
    "seconds": "60",
    "lyric": "Her face down in the pillow",
    "number": "4705",
  },
  {
    "artist": "Bing Crosby",
    "song": "White Christmas",
    "year": "1942",
    "seconds": "70",
    "lyric": "To hear sleigh bells in the snow",
    "number": "4706",
  },
  {
    "artist": "Taylor Swift",
    "song": "White Horse",
    "year": "2008",
    "seconds": "40",
    "lyric": "I’m not a princess",
    "number": "4707",
  },
  {
    "artist": "The Big Bopper",
    "song": "White Lightning",
    "year": "1957",
    "seconds": "50",
    "lyric": "He’d fill up a jug",
    "number": "4708",
  },
  {
    "artist": "Jefferson Airplane",
    "song": "White Rabbit",
    "year": "1967",
    "seconds": "60",
    "lyric": "The White Knight is talking backwards",
    "number": "4709",
  },
  {
    "artist": "Cream",
    "song": "White Room",
    "year": "1968",
    "seconds": "50",
    "lyric": "Silver horses run down moonbeams",
    "number": "4710",
  },
  {
    "artist": "Billy Idol",
    "song": "White Wedding",
    "year": "1982",
    "seconds": "60",
    "lyric": "Hey little sister, who’s your Superman?",
    "number": "4711",
  },
  {
    "artist": "The Who",
    "song": "Who Are You?",
    "year": "1978",
    "seconds": "60",
    "lyric": "The breeze blew back my hair",
    "number": "4712",
  },
  {
    "artist": "Blake Shelton",
    "song": "Who Are You When I’m Not Looking?",
    "year": "2010",
    "seconds": "50",
    "lyric": "Eat a box of chocolates",
    "number": "4713",
  },
  {
    "artist": "Bo Diddley",
    "song": "Who Do You Love?",
    "year": "1956",
    "seconds": "60",
    "lyric": "A cobra snake for a necktie",
    "number": "4714",
  },
  {
    "artist": "Spice Girls",
    "song": "Who Do You Think You Are?",
    "year": "1996",
    "seconds": "80",
    "lyric": "Swing it, shake it, move it, make it",
    "number": "4715",
  },
  {
    "artist": "The Four Seasons",
    "song": "Who Loves You",
    "year": "1975",
    "seconds": "50",
    "lyric": "Help you through the night",
    "number": "4716",
  },
  {
    "artist": "AC/DC",
    "song": "Who Made Who",
    "year": "1986",
    "seconds": "60",
    "lyric": "Feel it runnin’ down your spine",
    "number": "4717",
  },
  {
    "artist": "Barry Mann",
    "song": "Who Put the Bomp",
    "year": "1961",
    "seconds": "60",
    "lyric": "I’d like to shake his hand",
    "number": "4718",
  },
  {
    "artist": "Heart",
    "song": "Who Will You Run To?",
    "year": "1987",
    "seconds": "60",
    "lyric": "Take away the tears you cry",
    "number": "4719",
  },
  {
    "artist": "Keith Urban",
    "song": "Who Wouldn’t Wanna Be Me?",
    "year": "2003",
    "seconds": "50",
    "lyric": "No money in my pockets",
    "number": "4720",
  },
  {
    "artist": "The Monotones",
    "song": "Who Wrote the Book of Love",
    "year": "1958",
    "seconds": "60",
    "lyric": "Chapter one says to love her",
    "number": "4721",
  },
  {
    "artist": "Kenny Chesney",
    "song": "Who You’d Be Today",
    "year": "2005",
    "seconds": "70",
    "lyric": "I hear you laughin’ in the rain",
    "number": "4722",
  },
  {
    "artist": "Reba McEntire",
    "song": "Whoever’s in New England",
    "year": "1986",
    "seconds": "60",
    "lyric": "And left them in the hallway",
    "number": "4723",
  },
  {
    "artist": "Led Zeppelin",
    "song": "Whole Lotta Love",
    "year": "1969",
    "seconds": "50",
    "lyric": "Every inch of my love",
    "number": "4724",
  },
  {
    "artist": "Fats Domino",
    "song": "Whole Lotta Lovin’",
    "year": "1958",
    "seconds": "50",
    "lyric": "My love starts tumbling down",
    "number": "4725",
  },
  {
    "artist": "AC/DC",
    "song": "Whole Lotta Rosie",
    "year": "1978",
    "seconds": "40",
    "lyric": "Right around the clock",
    "number": "4726",
  },
  {
    "artist": "Jerry Lee Lewis",
    "song": "Whole Lotta Shakin’ Going On",
    "year": "1957",
    "seconds": "60",
    "lyric": "We got chicken in the barn",
    "number": "4727",
  },
  {
    "artist": "Creedence Clearwater Revival",
    "song": "Who’ll Stop the Rain",
    "year": "1970",
    "seconds": "50",
    "lyric": "Tryin’ to find the sun",
    "number": "4728",
  },
  {
    "artist": "Journey",
    "song": "Who’s Crying Now?",
    "year": "1981",
    "seconds": "50",
    "lyric": "One love, feeds the fire",
    "number": "4729",
  },
  {
    "artist": "George Jones",
    "song": "Who’s Gonna Fill Their Shoes",
    "year": "1985",
    "seconds": "40",
    "lyric": "Rolling through the night",
    "number": "4730",
  },
  {
    "artist": "U2",
    "song": "Who’s Gonna Ride Your Wild Horses",
    "year": "1992",
    "seconds": "50",
    "lyric": "You’re a piece of glass",
    "number": "4731",
  },
  {
    "artist": "Buck Owens & the Buckaroos",
    "song": "Who’s Gonna Mow Your Grass",
    "year": "1969",
    "seconds": "50",
    "lyric": "Dry your big blue eyes",
    "number": "4732",
  },
  {
    "artist": "El DeBarge",
    "song": "Who’s Johnny",
    "year": "1986",
    "seconds": "40",
    "lyric": "My heart’s in overdrive",
    "number": "4733",
  },
  {
    "artist": "The Jackson 5",
    "song": "Who’s Lovin’ You",
    "year": "1969",
    "seconds": "40",
    "lyric": "My head hangin’ down",
    "number": "4734",
  },
  {
    "artist": "Connie Francis",
    "song": "Who’s Sorry Now?",
    "year": "1957",
    "seconds": "40",
    "lyric": "Right to the end",
    "number": "4735",
  },
  {
    "artist": "Toby Keith",
    "song": "Who’s That Man?",
    "year": "1994",
    "seconds": "50",
    "lyric": "My dog in my backyard",
    "number": "4736",
  },
  {
    "artist": "Toby Keith",
    "song": "Who’s Your Daddy?",
    "year": "2002",
    "seconds": "50",
    "lyric": "Knockin’ on my door, baby",
    "number": "4737",
  },
  {
    "artist": "Shania Twain",
    "song": "Whose Bed Have Your Boots Been Under?",
    "year": "1995",
    "seconds": "50",
    "lyric": "Or you better start walkin’",
    "number": "4738",
  },
  {
    "artist": "Annie Lennox",
    "song": "Why",
    "year": "1992",
    "seconds": "50",
    "lyric": "Keep your big mouth shut",
    "number": "4739",
  },
  {
    "artist": "Frankie Avalon",
    "song": "Why",
    "year": "1959",
    "seconds": "50",
    "lyric": "No broken hearts for us",
    "number": "4740",
  },
  {
    "artist": "Jason Aldean",
    "song": "Why",
    "year": "2005",
    "seconds": "60",
    "lyric": "Before I’ll say ‘I love you’",
    "number": "4741",
  },
  {
    "artist": "Van Halen",
    "song": "Why Can’t This Be Love?",
    "year": "1986",
    "seconds": "40",
    "lyric": "Winding me up inside",
    "number": "4742",
  },
  {
    "artist": "Timmy Thomas",
    "song": "Why Can’t We Live Together?",
    "year": "1973",
    "seconds": "50",
    "lyric": "You are still my brother",
    "number": "4743",
  },
  {
    "artist": "Frankie Lymon",
    "song": "Why Do Fools Fall in Love",
    "year": "1956",
    "seconds": "60",
    "lyric": "The rain fall from up above",
    "number": "4744",
  },
  {
    "artist": "Willie Nelson",
    "song": "Why Do I Have To Choose?",
    "year": "1983",
    "seconds": "60",
    "lyric": "Walk around and sing the blues",
    "number": "4745",
  },
  {
    "artist": "Josh Turner",
    "song": "Why Don’t We Just Dance",
    "year": "2010",
    "seconds": "50",
    "lyric": "And we move the couch",
    "number": "4746",
  },
  {
    "artist": "Santana (ft. Alex Band)",
    "song": "Why Don’t You & I?",
    "year": "2003",
    "seconds": "50",
    "lyric": "Little wings on my shoes",
    "number": "4747",
  },
  {
    "artist": "Dinah Shore",
    "song": "Why Don’t You Fall in Love with Me?",
    "year": "1943",
    "seconds": "50",
    "lyric": "You’re drivin’ me crazy, baby",
    "number": "4748",
  },
  {
    "artist": "Hank Williams",
    "song": "Why Don’t You Love Me",
    "year": "1950",
    "seconds": "60",
    "lyric": "And my eyes are still blue",
    "number": "4749",
  },
  {
    "artist": "Ronnie Milsap",
    "song": "Why Don’t You Spend the Night?",
    "year": "1980",
    "seconds": "50",
    "lyric": "I know it’s almost two",
    "number": "4750",
  },
  {
    "artist": "Kris Kristofferson",
    "song": "Why Me",
    "year": "1973",
    "seconds": "50",
    "lyric": "My soul’s in your hands",
    "number": "4751",
  },
  {
    "artist": "Styx",
    "song": "Why Me",
    "year": "1980",
    "seconds": "60",
    "lyric": "Hard times come, hard times go",
    "number": "4752",
  },
  {
    "artist": "The Judds",
    "song": "Why Not Me?",
    "year": "1984",
    "seconds": "50",
    "lyric": "The girl next door, baby",
    "number": "4753",
  },
  {
    "artist": "Rascal Flatts",
    "song": "Why Wait?",
    "year": "2010",
    "seconds": "50",
    "lyric": "I know a little church",
    "number": "4754",
  },
  {
    "artist": "Jana Kramer",
    "song": "Why Ya Wanna",
    "year": "2012",
    "seconds": "60",
    "lyric": "Show up in an old t-shirt",
    "number": "4755",
  },
  {
    "artist": "Dolly Parton",
    "song": "Why’d You Come in Here Lookin’ Like That?",
    "year": "1989",
    "seconds": "40",
    "lyric": "In your cowboy boots",
    "number": "4756",
  },
  {
    "artist": "Glen Campbell",
    "song": "Wichita Lineman",
    "year": "1968",
    "seconds": "60",
    "lyric": "And I drive the main road",
    "number": "4757",
  },
  {
    "artist": "Chris Isaak",
    "song": "Wicked Game",
    "year": "1990",
    "seconds": "60",
    "lyric": "To let me dream of you",
    "number": "4758",
  },
  {
    "artist": "Dixie Chicks",
    "song": "Wide Open Spaces",
    "year": "1998",
    "seconds": "50",
    "lyric": "Dad yells, “Check the oil”",
    "number": "4759",
  },
  {
    "artist": "John Anderson",
    "song": "Wild and Blue",
    "year": "1982",
    "seconds": "60",
    "lyric": "And the shades all pulled down",
    "number": "4760",
  },
  {
    "artist": "Rolling Stones",
    "song": "Wild Horses",
    "year": "1971",
    "seconds": "50",
    "lyric": "We’ll ride them some day",
    "number": "4761",
  },
  {
    "artist": "Van Morrison",
    "song": "Wild Night",
    "year": "1971",
    "seconds": "60",
    "lyric": "And all the girls walk by",
    "number": "4762",
  },
  {
    "artist": "Faith Hill",
    "song": "Wild One",
    "year": "1993",
    "seconds": "70",
    "lyric": "Three years old on her daddy’s knee",
    "number": "4763",
  },
  {
    "artist": "Johnny O’Keefe",
    "song": "Wild One",
    "year": "1958",
    "seconds": "40",
    "lyric": "Shiver and a shake",
    "number": "4764",
  },
  {
    "artist": "Flo Rida (ft. Sia)",
    "song": "Wild Ones",
    "year": "2011",
    "seconds": "50",
    "lyric": "And I’m on the prowl",
    "number": "4765",
  },
  {
    "artist": "The Troggs",
    "song": "Wild Thing",
    "year": "1966",
    "seconds": "50",
    "lyric": "You make my heart sing",
    "number": "4766",
  },
  {
    "artist": "Talking Heads",
    "song": "Wild Wild Life",
    "year": "1986",
    "seconds": "50",
    "lyric": "I ride a hot potato",
    "number": "4767",
  },
  {
    "artist": "Cat Stevens",
    "song": "Wild World",
    "year": "1971",
    "seconds": "50",
    "lyric": "Breakin’ my heart you’re leavin’",
    "number": "4768",
  },
  {
    "artist": "Taylor Swift",
    "song": "Wildest Dreams",
    "year": "2015",
    "seconds": "60",
    "lyric": "His hands are in my hair",
    "number": "4769",
  },
  {
    "artist": "Michael Martin Murphey",
    "song": "Wildfire",
    "year": "1975",
    "seconds": "50",
    "lyric": "A whirlwind by her side",
    "number": "4770",
  },
  {
    "artist": "Skylark",
    "song": "Wildflower",
    "year": "1973",
    "seconds": "60",
    "lyric": "Her eyes fought back the tears",
    "number": "4771",
  },
  {
    "artist": "Jim Stafford",
    "song": "Wildwood Weed",
    "year": "1974",
    "seconds": "50",
    "lyric": "Naked, singing on the windmill",
    "number": "4772",
  },
  {
    "artist": "Billy Preston",
    "song": "Will It Go ‘Round in Circles",
    "year": "1973",
    "seconds": "60",
    "lyric": "A bird up in the sky",
    "number": "4773",
  },
  {
    "artist": "Michael Jackson",
    "song": "Will You Be There?",
    "year": "1993",
    "seconds": "40",
    "lyric": "Lift me up slowly",
    "number": "4774",
  },
  {
    "artist": "The Shirelles",
    "song": "Will You Love Me Tomorrow?",
    "year": "1961",
    "seconds": "50",
    "lyric": "Love is in your eyes",
    "number": "4775",
  },
  {
    "artist": "Chicago",
    "song": "Will You Still Love Me?",
    "year": "1986",
    "seconds": "40",
    "lyric": "Two hearts drawn together",
    "number": "4776",
  },
  {
    "artist": "Johnny Otis",
    "song": "Willie and the Hand Jive",
    "year": "1958",
    "seconds": "60",
    "lyric": "Grandma gave baby sister a dime",
    "number": "4777",
  },
  {
    "artist": "Taylor Swift",
    "song": "willow",
    "year": "2020",
    "seconds": "60",
    "lyric": "A trophy or a champion ring",
    "number": "4778",
  },
  {
    "artist": "Solomon Linda",
    "song": "Wimoweh (The Lion Sleeps Tonight)",
    "year": "1939",
    "seconds": "60",
    "lyric": "Near the village, the quiet village",
    "number": "4779",
  },
  {
    "artist": "Bette Midler",
    "song": "Wind Beneath My Wings",
    "year": "1989",
    "seconds": "70",
    "lyric": "I can fly higher than an eagle",
    "number": "4780",
  },
  {
    "artist": "U2",
    "song": "Window in the Skies",
    "year": "2007",
    "seconds": "70",
    "lyric": "You can reach it from our bed",
    "number": "4781",
  },
  {
    "artist": "The Association",
    "song": "Windy",
    "year": "1967",
    "seconds": "60",
    "lyric": "Peekin’ out from under a stairway",
    "number": "4782",
  },
  {
    "artist": "Loretta Lynn",
    "song": "Wine, Women and Song",
    "year": "1964",
    "seconds": "50",
    "lyric": "You rob my piggy bank",
    "number": "4783",
  },
  {
    "artist": "Ferlin Husky",
    "song": "Wings of a Dove",
    "year": "1960",
    "seconds": "40",
    "lyric": "The body grows weak",
    "number": "4784",
  },
  {
    "artist": "Neal McCoy",
    "song": "Wink",
    "year": "1994",
    "seconds": "60",
    "lyric": "She was standing at the sink",
    "number": "4785",
  },
  {
    "artist": "Rascal Flatts",
    "song": "Winner at a Losing Game",
    "year": "2007",
    "seconds": "60",
    "lyric": "So I’ll pack up my things",
    "number": "4786",
  },
  {
    "artist": "Santana",
    "song": "Winning",
    "year": "1981",
    "seconds": "50",
    "lyric": "But it turned to dust",
    "number": "4787",
  },
  {
    "artist": "Guy Lombardo & his Royal Canadians",
    "song": "Winter Wonderland",
    "year": "1934",
    "seconds": "50",
    "lyric": "We can build a snowman",
    "number": "4788",
  },
  {
    "artist": "Engelbert Humperdinck",
    "song": "Winter World of Love",
    "year": "1969",
    "seconds": "70",
    "lyric": "I see the firelight in your eyes",
    "number": "4789",
  },
  {
    "artist": "Toby Keith",
    "song": "Wish I Didn’t Know Now",
    "year": "1994",
    "seconds": "60",
    "lyric": "In the palm of your hand",
    "number": "4790",
  },
  {
    "artist": "Billie Eilish",
    "song": "Wish You Were Gay",
    "year": "2019",
    "seconds": "60",
    "lyric": "Ten fingers tearing out my hair",
    "number": "4791",
  },
  {
    "artist": "Pink Floyd",
    "song": "Wish You Were Here",
    "year": "1975",
    "seconds": "50",
    "lyric": "Swimming in a fish bowl",
    "number": "4792",
  },
  {
    "artist": "Dusty Springfield",
    "song": "Wishin’ and Hopin’",
    "year": "1964",
    "seconds": "50",
    "lyric": "Get you into his arms",
    "number": "4793",
  },
  {
    "artist": "Chicago",
    "song": "Wishing You Were Here",
    "year": "1974",
    "seconds": "40",
    "lyric": "You’re on my mind",
    "number": "4794",
  },
  {
    "artist": "Eagles",
    "song": "Witchy Woman",
    "year": "1972",
    "seconds": "50",
    "lyric": "Sparks fly from her singertips",
    "number": "4795",
  },
  {
    "artist": "The Beatles",
    "song": "With a Little Help from my Friends",
    "year": "1967",
    "seconds": "80",
    "lyric": "You stand up and walk out on me",
    "number": "4796",
  },
  {
    "artist": "Wings",
    "song": "With a Little Luck",
    "year": "1978",
    "seconds": "50",
    "lyric": "The willow turns his back",
    "number": "4797",
  },
  {
    "artist": "Creed",
    "song": "With Arms Wide Open",
    "year": "2000",
    "seconds": "70",
    "lyric": "Tears of joy stream down my face",
    "number": "4798",
  },
  {
    "artist": "Ben Harper",
    "song": "With My Own Two Hands",
    "year": "2003",
    "seconds": "70",
    "lyric": "And I can clean up the earth",
    "number": "4799",
  },
  {
    "artist": "David Houston",
    "song": "With One Exception",
    "year": "1967",
    "seconds": "50",
    "lyric": "And she’s waiting at home",
    "number": "4800",
  },
  {
    "artist": "U2",
    "song": "With or Without You",
    "year": "1987",
    "seconds": "50",
    "lyric": "On a bed of nails",
    "number": "4801",
  },
  {
    "artist": "Jefferson Starship",
    "song": "With Your Love",
    "year": "1976",
    "seconds": "60",
    "lyric": "I’m going out of my mind",
    "number": "4802",
  },
  {
    "artist": "Halsey",
    "song": "Without Me",
    "year": "2018",
    "seconds": "50",
    "lyric": "Right back on your feet",
    "number": "4803",
  },
  {
    "artist": "David Guetta (ft. Usher)",
    "song": "Without You",
    "year": "2011",
    "seconds": "60",
    "lyric": "I am lost, I am vain",
    "number": "4804",
  },
  {
    "artist": "Harry Nilsson",
    "song": "Without You",
    "year": "1972",
    "seconds": "60",
    "lyric": "In your eyes your sorrow shows",
    "number": "4805",
  },
  {
    "artist": "Keith Urban",
    "song": "Without You",
    "year": "2011",
    "seconds": "60",
    "lyric": "I caught you looking my way",
    "number": "4806",
  },
  {
    "artist": "Tanya Tucker",
    "song": "(Without You) What Do I Do With Me?",
    "year": "1991",
    "seconds": "50",
    "lyric": "I’ve been rackin’ my brain",
    "number": "4807",
  },
  {
    "artist": "Claude King",
    "song": "Wolverton Mountain",
    "year": "1962",
    "seconds": "50",
    "lyric": "The bears and the birds",
    "number": "4808",
  },
  {
    "artist": "Dierks Bentley",
    "song": "Woman, Amen",
    "year": "2018",
    "seconds": "60",
    "lyric": "The cracks in my shattered heart",
    "number": "4809",
  },
  {
    "artist": "Deep Purple",
    "song": "Woman from Tokyo",
    "year": "1973",
    "seconds": "50",
    "lyric": "Fly into the rising sun",
    "number": "4810",
  },
  {
    "artist": "Aerosmith",
    "song": "Woman of the World",
    "year": "1974",
    "seconds": "40",
    "lyric": "She’ll turn my head",
    "number": "4811",
  },
  {
    "artist": "Loretta Lynn",
    "song": "Woman of the World (Leave My World Alone)",
    "year": "1969",
    "seconds": "60",
    "lyric": "Your spell is on my baby",
    "number": "4812",
  },
  {
    "artist": "Gary Puckett & the Union Gap",
    "song": "Woman, Woman",
    "year": "1968",
    "seconds": "60",
    "lyric": "The way men look at you",
    "number": "4813",
  },
  {
    "artist": "Britney Spears",
    "song": "Womanizer",
    "year": "2008",
    "seconds": "50",
    "lyric": "The swagger of a champion",
    "number": "4814",
  },
  {
    "artist": "Paul McCartney",
    "song": "Wonderful Christmastime",
    "year": "1979",
    "seconds": "40",
    "lyric": "So lift a glass",
    "number": "4815",
  },
  {
    "artist": "Eric Clapton",
    "song": "Wonderful Tonight",
    "year": "1977",
    "seconds": "60",
    "lyric": "And brushes her long blonde hair",
    "number": "4816",
  },
  {
    "artist": "Oasis",
    "song": "Wonderwall",
    "year": "1995",
    "seconds": "70",
    "lyric": "The fire in your heart is out",
    "number": "4817",
  },
  {
    "artist": "The Who",
    "song": "Won’t Get Fooled Again",
    "year": "1971",
    "seconds": "60",
    "lyric": "Pick up my guitar and play",
    "number": "4818",
  },
  {
    "artist": "Crosby, Stills, Nash & Young",
    "song": "Woodstock",
    "year": "1970",
    "seconds": "70",
    "lyric": "To get ourselves back to the garden",
    "number": "4819",
  },
  {
    "artist": "Sam the Sham and the Pharaohs",
    "song": "Wooly Bully",
    "year": "1965",
    "seconds": "70",
    "lyric": "Two big horns and a wooly jaw",
    "number": "4820",
  },
  {
    "artist": "Buddy Holly",
    "song": "Words of Love",
    "year": "1957",
    "seconds": "50",
    "lyric": "You whisper soft and true",
    "number": "4821",
  },
  {
    "artist": "The Mamas & the Papas",
    "song": "Words of Love",
    "year": "1966",
    "seconds": "40",
    "lyric": "Win a girl’s heart",
    "number": "4822",
  },
  {
    "artist": "Beyoncé",
    "song": "Work It Out",
    "year": "2002",
    "seconds": "40",
    "lyric": "The whole bee hive",
    "number": "4823",
  },
  {
    "artist": "Jim Croce",
    "song": "Workin’ at the Carwash Blues",
    "year": "1974",
    "seconds": "70",
    "lyric": "Walkin’ home in these soggy old shoes",
    "number": "4824",
  },
  {
    "artist": "Merle Haggard",
    "song": "Workin’ Man Blues",
    "year": "1969",
    "seconds": "50",
    "lyric": "Nine kids and a wife",
    "number": "4825",
  },
  {
    "artist": "The 4 Seasons",
    "song": "Working My Way Back to You",
    "year": "1966",
    "seconds": "50",
    "lyric": "With a burnin’ love inside",
    "number": "4826",
  },
  {
    "artist": "Big Maceo Merriweather",
    "song": "Worried Life Blues",
    "year": "1941",
    "seconds": "50",
    "lyric": "It hurts me so bad",
    "number": "4827",
  },
  {
    "artist": "The Brooklyn Bridge",
    "song": "Worst That Could Happen",
    "year": "1969",
    "seconds": "50",
    "lyric": "I heard you’re getting married",
    "number": "4828",
  },
  {
    "artist": "Dottie West",
    "song": "Would You Hold It Against Me?",
    "year": "1966",
    "seconds": "50",
    "lyric": "For he was my love",
    "number": "4829",
  },
  {
    "artist": "Tanya Tucker",
    "song": "Would You Lay With Me (In a Field of Stone)?",
    "year": "1973",
    "seconds": "40",
    "lyric": "Walk a thousand miles",
    "number": "4830",
  },
  {
    "artist": "Beach Boys",
    "song": "Wouldn’t It Be Nice",
    "year": "1966",
    "seconds": "50",
    "lyric": "Wake up in the morning",
    "number": "4831",
  },
  {
    "artist": "George Strait",
    "song": "Wrapped",
    "year": "2007",
    "seconds": "50",
    "lyric": "Chills roll down my skin",
    "number": "4832",
  },
  {
    "artist": "Brad Paisley",
    "song": "Wrapped Around",
    "year": "2001",
    "seconds": "60",
    "lyric": "We cuddle up on the couch",
    "number": "4833",
  },
  {
    "artist": "Miley Cyrus",
    "song": "Wrecking Ball",
    "year": "2013",
    "seconds": "80",
    "lyric": "I put you high up in the sky",
    "number": "4834",
  },
  {
    "artist": "George Strait",
    "song": "Write This Down",
    "year": "1999",
    "seconds": "60",
    "lyric": "I was running out of time",
    "number": "4835",
  },
  {
    "artist": "Beyoncé",
    "song": "XO",
    "year": "2013",
    "seconds": "50",
    "lyric": "They turn the lights out",
    "number": "4836",
  },
  {
    "artist": "Lil Uzi Vert",
    "song": "XO Tour Llif3",
    "year": "2017",
    "seconds": "50",
    "lyric": "Push me to the edge",
    "number": "4837",
  },
  {
    "artist": "Olivia Newton-John",
    "song": "Xanadu",
    "year": "1980",
    "seconds": "50",
    "lyric": "Open your eyes and see",
    "number": "4838",
  },
  {
    "artist": "The Coasters",
    "song": "Yakety Yak",
    "year": "1958",
    "seconds": "70",
    "lyric": "Take out the papers and the trash",
    "number": "4839",
  },
  {
    "artist": "The Oak Ridge Boys",
    "song": "Y’all Come Back Saloon",
    "year": "1977",
    "seconds": "60",
    "lyric": "In a voice soft and trembling",
    "number": "4840",
  },
  {
    "artist": "Usher (feat. Lil Jon & Ludacris)",
    "song": "Yeah!",
    "year": "2004",
    "seconds": "40",
    "lyric": "She’s a certified twenty",
    "number": "4841",
  },
  {
    "artist": "Al Stewart",
    "song": "Year of the Cat",
    "year": "1977",
    "seconds": "70",
    "lyric": "She locks up your arm in hers",
    "number": "4842",
  },
  {
    "artist": "Coldplay",
    "song": "Yellow",
    "year": "2000",
    "seconds": "60",
    "lyric": "I drew a line for you",
    "number": "4843",
  },
  {
    "artist": "Harry Belafonte",
    "song": "Yellow Bird",
    "year": "1957",
    "seconds": "50",
    "lyric": "High up in banana tree",
    "number": "4844",
  },
  {
    "artist": "Christie",
    "song": "Yellow River",
    "year": "1970",
    "seconds": "40",
    "lyric": "Sleepin’ underneath the moon",
    "number": "4845",
  },
  {
    "artist": "The Beatles",
    "song": "Yellow Submarine",
    "year": "1966",
    "seconds": "70",
    "lyric": "So we sailed up to the sun",
    "number": "4846",
  },
  {
    "artist": "Valdy",
    "song": "Yes I Can",
    "year": "1976",
    "seconds": "50",
    "lyric": "I’ll be a rodeo rider",
    "number": "4847",
  },
  {
    "artist": "Dinah Shore",
    "song": "Yes, My Darling Daughter",
    "year": "1940",
    "seconds": "60",
    "lyric": "My knees just turn to water",
    "number": "4848",
  },
  {
    "artist": "The Beatles",
    "song": "Yesterday",
    "year": "1965",
    "seconds": "50",
    "lyric": "A shadow hanging over me",
    "number": "4849",
  },
  {
    "artist": "The Carpenters",
    "song": "Yesterday Once More",
    "year": "1973",
    "seconds": "40",
    "lyric": "He’s breakin’ her heart",
    "number": "4850",
  },
  {
    "artist": "Chris Young",
    "song": "You",
    "year": "2011",
    "seconds": "50",
    "lyric": "Girl, you rock me harder",
    "number": "4851",
  },
  {
    "artist": "Toby Keith",
    "song": "You Ain’t Much Fun",
    "year": "1995",
    "seconds": "50",
    "lyric": "And I’m mowin’ the grass",
    "number": "4852",
  },
  {
    "artist": "Bachman Turner Overdrive",
    "song": "You Ain’t Seen Nothing Yet",
    "year": "1974",
    "seconds": "80",
    "lyric": "She looked at me with big brown eyes",
    "number": "4853",
  },
  {
    "artist": "Loretta Lynn",
    "song": "You Ain’t Woman Enough",
    "year": "1966",
    "seconds": "60",
    "lyric": "It’ll be over my dead body",
    "number": "4854",
  },
  {
    "artist": "Lady Gaga",
    "song": "You and I",
    "year": "2011",
    "seconds": "50",
    "lyric": "My lipstick on your face",
    "number": "4855",
  },
  {
    "artist": "Alice Cooper",
    "song": "You and Me",
    "year": "1977",
    "seconds": "50",
    "lyric": "Reach out and touch you",
    "number": "4856",
  },
  {
    "artist": "Dave Matthews Band",
    "song": "You and Me",
    "year": "2009",
    "seconds": "50",
    "lyric": "Eyes closed above the rooftops",
    "number": "4857",
  },
  {
    "artist": "Lifehouse",
    "song": "You and Me",
    "year": "2005",
    "seconds": "50",
    "lyric": "You got my head spinning",
    "number": "4858",
  },
  {
    "artist": "Helen Reddy",
    "song": "You and Me Against the World",
    "year": "1974",
    "seconds": "40",
    "lyric": "Frightened by the clown",
    "number": "4859",
  },
  {
    "artist": "Kenny Chesney (ft. Grace Potter)",
    "song": "You and Tequila",
    "year": "2011",
    "seconds": "60",
    "lyric": "High above the lights of town",
    "number": "4860",
  },
  {
    "artist": "Lionel Richie",
    "song": "You Are",
    "year": "1983",
    "seconds": "50",
    "lyric": "There’s no one above you",
    "number": "4861",
  },
  {
    "artist": "The Stylistics",
    "song": "You Are Everythiag",
    "year": "1971",
    "seconds": "50",
    "lyric": "As she turned the corner",
    "number": "4862",
  },
  {
    "artist": "Pete Seeger",
    "song": "You Are My Sunshine",
    "year": "1961",
    "seconds": "60",
    "lyric": "I hung my head and cried",
    "number": "4863",
  },
  {
    "artist": "Jack Greene",
    "song": "You Are My Treasure",
    "year": "1968",
    "seconds": "50",
    "lyric": "Your smiles are my pearls",
    "number": "4864",
  },
  {
    "artist": "Michael Jackson",
    "song": "You Are Not Alone",
    "year": "1995",
    "seconds": "50",
    "lyric": "I can hear your prayers",
    "number": "4865",
  },
  {
    "artist": "Joe Cocker",
    "song": "You Are So Beautiful",
    "year": "1974",
    "seconds": "50",
    "lyric": "You’re everything I hoped for",
    "number": "4866",
  },
  {
    "artist": "Ray Lamontagne",
    "song": "You Are the Best Thing",
    "year": "2008",
    "seconds": "50",
    "lyric": "You see right through me",
    "number": "4867",
  },
  {
    "artist": "Calum Scott and Leona Lewis",
    "song": "You Are the Reason",
    "year": "2018",
    "seconds": "40",
    "lyric": "Turn back the clock",
    "number": "4868",
  },
  {
    "artist": "Stevie Wonder",
    "song": "You Are the Sunshine of My Life",
    "year": "1973",
    "seconds": "70",
    "lyric": "You are the apple of my eye",
    "number": "4869",
  },
  {
    "artist": "Carly Simon",
    "song": "You Belong to Me",
    "year": "1978",
    "seconds": "60",
    "lyric": "Closed the book, locked the door",
    "number": "4870",
  },
  {
    "artist": "Jo Stafford",
    "song": "You Belong to Me",
    "year": "1952",
    "seconds": "60",
    "lyric": "See the pyramids along the Nile",
    "number": "4871",
  },
  {
    "artist": "Taylor Swift",
    "song": "You Belong With Me",
    "year": "2009",
    "seconds": "50",
    "lyric": "Laughing on a park bench",
    "number": "4872",
  },
  {
    "artist": "The Young Rascals",
    "song": "You Better Run",
    "year": "1966",
    "seconds": "50",
    "lyric": "I’m closin’ all the doors",
    "number": "4873",
  },
  {
    "artist": "Cher",
    "song": "You Better Sit Down Kids",
    "year": "1967",
    "seconds": "50",
    "lyric": "I’ll still be your Dad",
    "number": "4874",
  },
  {
    "artist": "The Who",
    "song": "You Better You Bet",
    "year": "1981",
    "seconds": "50",
    "lyric": "Cut you like a knife",
    "number": "4875",
  },
  {
    "artist": "Paul Simon",
    "song": "You Can Call Me Al",
    "year": "1986",
    "seconds": "60",
    "lyric": "A man walks down the street",
    "number": "4876",
  },
  {
    "artist": "America",
    "song": "You Can Do Magic",
    "year": "1982",
    "seconds": "50",
    "lyric": "You hypnotize with your eyes",
    "number": "4877",
  },
  {
    "artist": "Rolling Stones",
    "song": "You Can’t Always Get What You Want",
    "year": "1973",
    "seconds": "70",
    "lyric": "A glass of wine in her hand",
    "number": "4878",
  },
  {
    "artist": "Raydio",
    "song": "You Can’t Change That",
    "year": "1979",
    "seconds": "50",
    "lyric": "The color of your hair",
    "number": "4879",
  },
  {
    "artist": "The Supremes",
    "song": "You Can’t Hurry Love",
    "year": "1966",
    "seconds": "60",
    "lyric": "A game of give and take",
    "number": "4880",
  },
  {
    "artist": "George Strait",
    "song": "You Can’t Make a Heart Love Somebody",
    "year": "1994",
    "seconds": "60",
    "lyric": "Diamond burnin’ holes in his pockets",
    "number": "4881",
  },
  {
    "artist": "Brooks & Dunn",
    "song": "You Can’t Take the Honky Tonk Out of the Girl",
    "year": "2003",
    "seconds": "60",
    "lyric": "In time for the rehearsal dinner",
    "number": "4882",
  },
  {
    "artist": "Kenny Rogers",
    "song": "You Decorated My Life",
    "year": "1979",
    "seconds": "60",
    "lyric": "By the look on my face",
    "number": "4883",
  },
  {
    "artist": "Dinah Shore",
    "song": "You Do",
    "year": "1947",
    "seconds": "40",
    "lyric": "Give me three guesses",
    "number": "4884",
  },
  {
    "artist": "Barbra Streisand and Neil Diamond",
    "song": "You Don’t Bring Me Flowers",
    "year": "1978",
    "seconds": "50",
    "lyric": "And turn out the light",
    "number": "4885",
  },
  {
    "artist": "Dusty Springfield",
    "song": "You Don’t Have to Say You Love Me",
    "year": "1966",
    "seconds": "50",
    "lyric": "I’ll never tie you down",
    "number": "4886",
  },
  {
    "artist": "Marilyn McCoo & Billy Davis Jr.",
    "song": "You Don’t Have to Be a Star",
    "year": "1976",
    "seconds": "60",
    "lyric": "Baby, to be in my show",
    "number": "4887",
  },
  {
    "artist": "Tom Petty & the Heartbreakers",
    "song": "You Don’t Know How It Feels",
    "year": "1994",
    "seconds": "50",
    "lyric": "Head on down the road",
    "number": "4888",
  },
  {
    "artist": "Ray Charles",
    "song": "You Don’t Know Me",
    "year": "1962",
    "seconds": "60",
    "lyric": "You give your hand to me",
    "number": "4889",
  },
  {
    "artist": "Jim Croce",
    "song": "You Don’t Mess Around With Jim",
    "year": "1972",
    "seconds": "70",
    "lyric": "The soles of the big man’s feet",
    "number": "4890",
  },
  {
    "artist": "Britney Spears",
    "song": "(You Drive Me) Crazy",
    "year": "1999",
    "seconds": "70",
    "lyric": "I’m so excited, I’m in too deep",
    "number": "4891",
  },
  {
    "artist": "Bon Jovi",
    "song": "You Give Love a Bad Name",
    "year": "1986",
    "seconds": "60",
    "lyric": "Oh, oh, you’re a loaded gun",
    "number": "4892",
  },
  {
    "artist": "Keith Urban",
    "song": "You Gonna Fly",
    "year": "2011",
    "seconds": "50",
    "lyric": "Run through the red lights",
    "number": "4893",
  },
  {
    "artist": "Tom Petty & the Heartbreakers",
    "song": "You Got Lucky",
    "year": "1982",
    "seconds": "60",
    "lyric": "Good love is hard to find",
    "number": "4894",
  },
  {
    "artist": "Marv Johnson",
    "song": "You Got What It Takes",
    "year": "1959",
    "seconds": "50",
    "lyric": "Drive a big, fast car",
    "number": "4895",
  },
  {
    "artist": "Des’ree",
    "song": "You Gotta Be",
    "year": "1994",
    "seconds": "70",
    "lyric": "Keep your head up to the sky",
    "number": "4896",
  },
  {
    "artist": "Elton John",
    "song": "You Gotta Love Someone",
    "year": "1990",
    "seconds": "50",
    "lyric": "You can beat the clock",
    "number": "4897",
  },
  {
    "artist": "Kenny Chesney",
    "song": "You Had Me From Hello",
    "year": "1999",
    "seconds": "70",
    "lyric": "The moment I looked into your eyes",
    "number": "4898",
  },
  {
    "artist": "Dinah Shore",
    "song": "You Keep Coming Back Like a Song",
    "year": "1946",
    "seconds": "60",
    "lyric": "The perfume of roses in May",
    "number": "4899",
  },
  {
    "artist": "The Supremes",
    "song": "You Keep Me Hangin’ On",
    "year": "1966",
    "seconds": "70",
    "lyric": "Seeing you only breaks my heart again",
    "number": "4900",
  },
  {
    "artist": "George Strait",
    "song": "You Know Me Better Than That",
    "year": "1991",
    "seconds": "60",
    "lyric": "Blue jeans and buckets of beer",
    "number": "4901",
  },
  {
    "artist": "Debby Boone",
    "song": "You Light Up My Life",
    "year": "1978",
    "seconds": "60",
    "lyric": "And fill my nights with song",
    "number": "4902",
  },
  {
    "artist": "Lady Antebellum",
    "song": "You Look Good",
    "year": "2017",
    "seconds": "50",
    "lyric": "Cold beer in your hand",
    "number": "4903",
  },
  {
    "artist": "Keith Urban",
    "song": "You Look Good in My Shirt",
    "year": "2008",
    "seconds": "50",
    "lyric": "You walked up behind me",
    "number": "4904",
  },
  {
    "artist": "George Strait",
    "song": "You Look So Good in Love",
    "year": "1983",
    "seconds": "40",
    "lyric": "Flush on your cheeks",
    "number": "4905",
  },
  {
    "artist": "Jason Aldean",
    "song": "You Make It Easy",
    "year": "2018",
    "seconds": "50",
    "lyric": "Ruffle up your feathers, angel",
    "number": "4906",
  },
  {
    "artist": "The Stylistics",
    "song": "You Make Me Feel Brand New",
    "year": "1974",
    "seconds": "70",
    "lyric": "Believed in me through thick and thin",
    "number": "4907",
  },
  {
    "artist": "Leo Sayer",
    "song": "You Make Me Feel Like Dancing",
    "year": "1977",
    "seconds": "60",
    "lyric": "I’m in a spin you know",
    "number": "4908",
  },
  {
    "artist": "Frank Sinatra",
    "song": "You Make Me Feel So Young",
    "year": "1953",
    "seconds": "80",
    "lyric": "Songs to be sung, bells to be rung",
    "number": "4909",
  },
  {
    "artist": "Usher",
    "song": "You Make Me Wanna",
    "year": "1997",
    "seconds": "50",
    "lyric": "But I’m falling for you",
    "number": "4910",
  },
  {
    "artist": "Juice Newton",
    "song": "You Make Me Want to Make You Mine",
    "year": "1985",
    "seconds": "60",
    "lyric": "To be close by your side",
    "number": "4911",
  },
  {
    "artist": "Hall & Oates",
    "song": "You Make My Dreams Come True",
    "year": "1982",
    "seconds": "60",
    "lyric": "The flame that burns the candle",
    "number": "4912",
  },
  {
    "artist": "Billy Joel",
    "song": "You May Be Right",
    "year": "1980",
    "seconds": "60",
    "lyric": "Rode my motorcycle in the rain",
    "number": "4913",
  },
  {
    "artist": "David Houston",
    "song": "You Mean the World To Me",
    "year": "1967",
    "seconds": "60",
    "lyric": "I’m happy as I can be",
    "number": "4914",
  },
  {
    "artist": "The Cars",
    "song": "You Might Think",
    "year": "1984",
    "seconds": "60",
    "lyric": "The way I run you down",
    "number": "4915",
  },
  {
    "artist": "Bobby Darin",
    "song": "You Must Have Been a Beautiful Baby",
    "year": "1961",
    "seconds": "60",
    "lyric": "I can see the judge’s eyes",
    "number": "4916",
  },
  {
    "artist": "Taylor Swift",
    "song": "You Need to Calm Down",
    "year": "2019",
    "seconds": "70",
    "lyric": "Snakes and stones never broke my bones",
    "number": "4917",
  },
  {
    "artist": "Anne Murray",
    "song": "You Needed Me",
    "year": "1978",
    "seconds": "40",
    "lyric": "To stand alone again",
    "number": "4918",
  },
  {
    "artist": "Alanis Morissette",
    "song": "You Oughta Know",
    "year": "1995",
    "seconds": "50",
    "lyric": "I hate to bug you",
    "number": "4919",
  },
  {
    "artist": "Josh Groban",
    "song": "You Raise Me Up",
    "year": "2003",
    "seconds": "60",
    "lyric": "So I can stand on mountains",
    "number": "4920",
  },
  {
    "artist": "The Kinks",
    "song": "You Really Got Me",
    "year": "1964",
    "seconds": "50",
    "lyric": "I can’t sleep at night",
    "number": "4921",
  },
  {
    "artist": "Michael Jackson",
    "song": "You Rock My World",
    "year": "2001",
    "seconds": "60",
    "lyric": "And I’ll be all you need",
    "number": "4922",
  },
  {
    "artist": "Kenny Chesney",
    "song": "You Save Me",
    "year": "2006",
    "seconds": "50",
    "lyric": "My strings all get tangled",
    "number": "4923",
  },
  {
    "artist": "Sam Cooke",
    "song": "You Send Me",
    "year": "1957",
    "seconds": "70",
    "lyric": "To marry you and take you home",
    "number": "4924",
  },
  {
    "artist": "Hot Chocolate",
    "song": "You Sexy Thing",
    "year": "1976",
    "seconds": "70",
    "lyric": "I love the way you kiss me",
    "number": "4925",
  },
  {
    "artist": "AC/DC",
    "song": "You Shook Me All Night Long",
    "year": "1980",
    "seconds": "40",
    "lyric": "The earth was quaking",
    "number": "4926",
  },
  {
    "artist": "The Bee Gees",
    "song": "You Should Be Dancing",
    "year": "1976",
    "seconds": "50",
    "lyric": "My baby moves at midnight",
    "number": "4927",
  },
  {
    "artist": "Halsey",
    "song": "You Should Be Sad",
    "year": "2020",
    "seconds": "40",
    "lyric": "See your alligator tears",
    "number": "4928",
  },
  {
    "artist": "Billie Eilish",
    "song": "You Should See Me in a Crown",
    "year": "2018",
    "seconds": "50",
    "lyric": "Blood on a marble wall",
    "number": "4929",
  },
  {
    "artist": "Toby Keith",
    "song": "You Shouldn’t Kiss Me Like This",
    "year": "2000",
    "seconds": "50",
    "lyric": "My heart skipped a beat",
    "number": "4930",
  },
  {
    "artist": "The Turtles",
    "song": "You Showed Me",
    "year": "1969",
    "seconds": "50",
    "lyric": "We’re in love, we two",
    "number": "4931",
  },
  {
    "artist": "Dead or Alive",
    "song": "You Spin Me Round (Like a Record)",
    "year": "1984",
    "seconds": "70",
    "lyric": "Open up your loving arms, watch out",
    "number": "4932",
  },
  {
    "artist": "Dan Seals",
    "song": "You Still Move Me",
    "year": "1986",
    "seconds": "70",
    "lyric": "She picked me up off the ground",
    "number": "4933",
  },
  {
    "artist": "Joe Jones",
    "song": "You Talk Too Much",
    "year": "1960",
    "seconds": "50",
    "lyric": "You can make me scream",
    "number": "4934",
  },
  {
    "artist": "Joni Mitchell",
    "song": "You Turn Me On, I’m a Radio",
    "year": "1972",
    "seconds": "50",
    "lyric": "I’m a little bit corny",
    "number": "4935",
  },
  {
    "artist": "Rod Stewart",
    "song": "You Wear It Well",
    "year": "1972",
    "seconds": "50",
    "lyric": "Basement parties, your brother’s karate",
    "number": "4936",
  },
  {
    "artist": "Jewel",
    "song": "You Were Meant for Me",
    "year": "1996",
    "seconds": "50",
    "lyric": "I got my pancakes too",
    "number": "4937",
  },
  {
    "artist": "Sylvia Tyson",
    "song": "You Were on My Mind",
    "year": "1962",
    "seconds": "60",
    "lyric": "It’s way down in my shoes",
    "number": "4938",
  },
  {
    "artist": "Shania Twain",
    "song": "You Win My Love",
    "year": "1996",
    "seconds": "50",
    "lyric": "A fancy little pick-up truck",
    "number": "4939",
  },
  {
    "artist": "Billy Ray Cyrus",
    "song": "You Won’t Be Lonely Now",
    "year": "2000",
    "seconds": "60",
    "lyric": "Left in the rain to rust",
    "number": "4940",
  },
  {
    "artist": "Doris Day (with Les Brown)",
    "song": "You Won’t Be Satisfied (Until You Break My Heart)",
    "year": "1946",
    "seconds": "50",
    "lyric": "My poor heart is sagging’",
    "number": "4941",
  },
  {
    "artist": "The Beatles",
    "song": "You Won’t See Me",
    "year": "1965",
    "seconds": "40",
    "lyric": "So act your age",
    "number": "4942",
  },
  {
    "artist": "Tom Petty & the Heartbreakers",
    "song": "You Wreck Me",
    "year": "1995",
    "seconds": "60",
    "lyric": "The boy in the corduroy pants",
    "number": "4943",
  },
  {
    "artist": "Dinah Shore",
    "song": "You’d Be So Nice to Come Home To",
    "year": "1943",
    "seconds": "60",
    "lyric": "Under an August moon, burning above",
    "number": "4944",
  },
  {
    "artist": "George Strait",
    "song": "You’ll Be There",
    "year": "2005",
    "seconds": "50",
    "lyric": "And love is a ship",
    "number": "4945",
  },
  {
    "artist": "Barbara Lynn",
    "song": "You’ll Lose a Good Thing",
    "year": "1962",
    "seconds": "50",
    "lyric": "You know I love you",
    "number": "4946",
  },
  {
    "artist": "Lou Rawls",
    "song": "You’ll Never Find Another Love Like Mine",
    "year": "1976",
    "seconds": "70",
    "lyric": "But I’m the one who loves you",
    "number": "4947",
  },
  {
    "artist": "Keith Urban",
    "song": "You’ll Think of Me",
    "year": "2004",
    "seconds": "40",
    "lyric": "And take your cat",
    "number": "4948",
  },
  {
    "artist": "Kenny Chesney",
    "song": "Young",
    "year": "2001",
    "seconds": "50",
    "lyric": "But in the back seat",
    "number": "4949",
  },
  {
    "artist": "David Bowie",
    "song": "Young Americans",
    "year": "1975",
    "seconds": "70",
    "lyric": "Misses a step and cuts his hand",
    "number": "4950",
  },
  {
    "artist": "Lana Del Rey",
    "song": "Young and Beautiful",
    "year": "2013",
    "seconds": "60",
    "lyric": "Hot summer days, rock and roll",
    "number": "4951",
  },
  {
    "artist": "The Coasters",
    "song": "Young Blood",
    "year": "1957",
    "seconds": "70",
    "lyric": "I saw her standin’ on the corner",
    "number": "4952",
  },
  {
    "artist": "Gary Puckett & the Union Gap",
    "song": "Young Girl",
    "year": "1968",
    "seconds": "50",
    "lyric": "Beneath your perfume and make-up",
    "number": "4953",
  },
  {
    "artist": "Candi Staton",
    "song": "Young Hearts Run Free",
    "year": "1976",
    "seconds": "70",
    "lyric": "Turn loose a thousand times a day",
    "number": "4954",
  },
  {
    "artist": "Tab Hunter",
    "song": "Young Love",
    "year": "1957",
    "seconds": "60",
    "lyric": "One kiss from your sweet lips",
    "number": "4955",
  },
  {
    "artist": "Mitski",
    "song": "Your Best American Girl",
    "year": "2016",
    "seconds": "60",
    "lyric": "I’ll be singing to the birds",
    "number": "4956",
  },
  {
    "artist": "Hank Williams",
    "song": "Your Cheatin’ Heart",
    "year": "1953",
    "seconds": "60",
    "lyric": "Tears come down like falling rain",
    "number": "4957",
  },
  {
    "artist": "The Outfield",
    "song": "Your Love",
    "year": "1985",
    "seconds": "50",
    "lyric": "Stop my hands from shakin’",
    "number": "4958",
  },
  {
    "artist": "Conway Twitty",
    "song": "Your Love Had Taken Me That High",
    "year": "1978",
    "seconds": "50",
    "lyric": "Mountains reachin’ to the sky",
    "number": "4959",
  },
  {
    "artist": "Earl Thomas Conley",
    "song": "Your Love’s on the Line",
    "year": "1983",
    "seconds": "60",
    "lyric": "And your heart in a bind",
    "number": "4960",
  },
  {
    "artist": "Loggins and Messina",
    "song": "Your Mama Don’t Dance",
    "year": "1973",
    "seconds": "50",
    "lyric": "You hop into the backseat",
    "number": "4961",
  },
  {
    "artist": "Marvin Gaye, Tammi Terrell",
    "song": "Your Precious Love",
    "year": "1967",
    "seconds": "50",
    "lyric": "I look in the mirror",
    "number": "4962",
  },
  {
    "artist": "Elton John",
    "song": "Your Song",
    "year": "1970",
    "seconds": "50",
    "lyric": "I’d buy a big house",
    "number": "4963",
  },
  {
    "artist": "Buck Owens",
    "song": "Your Tender Loving Care",
    "year": "1967",
    "seconds": "60",
    "lyric": "The north wind and the snowflakes",
    "number": "4964",
  },
  {
    "artist": "White Town",
    "song": "Your Woman",
    "year": "1997",
    "seconds": "40",
    "lyric": "A charming, handsome man",
    "number": "4965",
  },
  {
    "artist": "Thurl Ravenscroft",
    "song": "You’re a Mean One, Mr. Grinch",
    "year": "1966",
    "seconds": "60",
    "lyric": "Your brain is full of spiders",
    "number": "4966",
  },
  {
    "artist": "The Fontane Sisters and Perry Como",
    "song": "You’re Adorable",
    "year": "1949",
    "seconds": "70",
    "lyric": "(F) – you’re a feather in my arms",
    "number": "4967",
  },
  {
    "artist": "Marvin Gaye, Tammi Terrell",
    "song": "You’re All I Need to Get By",
    "year": "1968",
    "seconds": "50",
    "lyric": "Push me up the hill",
    "number": "4968",
  },
  {
    "artist": "Robert Palmer",
    "song": "You’re Amazing",
    "year": "1990",
    "seconds": "50",
    "lyric": "I saw the light, dynamite",
    "number": "4969",
  },
  {
    "artist": "James Blunt",
    "song": "You’re Beautiful",
    "year": "2005",
    "seconds": "50",
    "lyric": "Yes, she caught my eye",
    "number": "4970",
  },
  {
    "artist": "The Beatles",
    "song": "You’re Going to Lose That Girl",
    "year": "1965",
    "seconds": "60",
    "lyric": "And I will treat her kind",
    "number": "4971",
  },
  {
    "artist": "Brooks & Dunn",
    "song": "You’re Gonna Miss Me When I’m Gone",
    "year": "1995",
    "seconds": "50",
    "lyric": "You walked on this heart",
    "number": "4972",
  },
  {
    "artist": "Paul Anka",
    "song": "(You’re) Having My Baby",
    "year": "1974",
    "seconds": "60",
    "lyric": "Baby, do you feel it growing?",
    "number": "4973",
  },
  {
    "artist": "Rod Stewart",
    "song": "You’re In My Heart (The Final Acclaim)",
    "year": "1977",
    "seconds": "40",
    "lyric": "You’ll be my breath",
    "number": "4974",
  },
  {
    "artist": "The Fontane Sisters and Perry Como",
    "song": "You’re Just in Love",
    "year": "1950",
    "seconds": "60",
    "lyric": "I seem to walk on air",
    "number": "4975",
  },
  {
    "artist": "Queen",
    "song": "You’re My Best Friend",
    "year": "1976",
    "seconds": "50",
    "lyric": "You’ve stood by me girl",
    "number": "4976",
  },
  {
    "artist": "Keith Urban",
    "song": "You’re My Better Half",
    "year": "2004",
    "seconds": "40",
    "lyric": "You pull me close",
    "number": "4977",
  },
  {
    "artist": "The Temptations",
    "song": "You’re My Everything",
    "year": "1967",
    "seconds": "60",
    "lyric": "Build my whole world around you",
    "number": "4978",
  },
  {
    "artist": "Charley Pride",
    "song": "You’re My Jamaica",
    "year": "1979",
    "seconds": "60",
    "lyric": "And rum punch in my hand",
    "number": "4979",
  },
  {
    "artist": "The Righteous Brothers",
    "song": "(You’re My) Soul and Inspiration",
    "year": "1966",
    "seconds": "50",
    "lyric": "Please, I’m begging you baby",
    "number": "4980",
  },
  {
    "artist": "Cilla Black",
    "song": "You’re My World",
    "year": "1964",
    "seconds": "60",
    "lyric": "The stars up in the skies",
    "number": "4981",
  },
  {
    "artist": "Linda Ronstadt",
    "song": "You’re No Good",
    "year": "1975",
    "seconds": "40",
    "lyric": "It left a scar",
    "number": "4982",
  },
  {
    "artist": "Ringo Starr",
    "song": "You’re Sixteen",
    "year": "1974",
    "seconds": "60",
    "lyric": "You walked out of my dreams",
    "number": "4983",
  },
  {
    "artist": "Carly Simon",
    "song": "You’re So Vain",
    "year": "1972",
    "seconds": "70",
    "lyric": "Your hat strategically dipped below one eye",
    "number": "4984",
  },
  {
    "artist": "George Strait",
    "song": "You’re Something Special To Me",
    "year": "1985",
    "seconds": "60",
    "lyric": "Wrapped in the arms of love",
    "number": "4985",
  },
  {
    "artist": "Marie Osmond & Paul Davis",
    "song": "You’re Still New to Me",
    "year": "1986",
    "seconds": "50",
    "lyric": "And cries on my shoulder",
    "number": "4986",
  },
  {
    "artist": "Shania Twain",
    "song": "You’re Still the One",
    "year": "1998",
    "seconds": "50",
    "lyric": "Look at us holding on",
    "number": "4987",
  },
  {
    "artist": "Elvis Presley",
    "song": "(You’re the) Devil in Disguise",
    "year": "1963",
    "seconds": "50",
    "lyric": "You look like an angel",
    "number": "4988",
  },
  {
    "artist": "Barry White",
    "song": "You’re the First, the Last, My Everything",
    "year": "1974",
    "seconds": "70",
    "lyric": "My sun, my moon, my guiding star",
    "number": "4989",
  },
  {
    "artist": "Chicago",
    "song": "You’re the Inspiration",
    "year": "1984",
    "seconds": "90",
    "lyric": "On my mind, in my heart, in my soul",
    "number": "4990",
  },
  {
    "artist": "The Oak Ridge Boys",
    "song": "You’re the One",
    "year": "1977",
    "seconds": "60",
    "lyric": "I have looked in the water",
    "number": "4991",
  },
  {
    "artist": "John Travolta, Olivia Newton John",
    "song": "You’re the One That I Want",
    "year": "1978",
    "seconds": "70",
    "lyric": "And my heart is set on you",
    "number": "4992",
  },
  {
    "artist": "Dolly Parton",
    "song": "You’re the Only One",
    "year": "1979",
    "seconds": "60",
    "lyric": "Your covers wouldn’t warm my feet",
    "number": "4993",
  },
  {
    "artist": "Bobby Darin",
    "song": "You’re the Reason I’m Living",
    "year": "1963",
    "seconds": "50",
    "lyric": "The stars in my heaven",
    "number": "4994",
  },
  {
    "artist": "Rascal Flatts",
    "song": "Yours If You Want It",
    "year": "2017",
    "seconds": "50",
    "lyric": "My jacket when it’s cold",
    "number": "4995",
  },
  {
    "artist": "James Taylor",
    "song": "You’ve Got a Friend",
    "year": "1971",
    "seconds": "60",
    "lyric": "And you need a helping hand",
    "number": "4996",
  },
  {
    "artist": "Randy Newman",
    "song": "You’ve Got a Friend In Me",
    "year": "1996",
    "seconds": "70",
    "lyric": "We stick together and see it through",
    "number": "4997",
  },
  {
    "artist": "Tanya Tucker",
    "song": "You’ve Got Me to Hold On To",
    "year": "1976",
    "seconds": "70",
    "lyric": "A little bit too dark to see",
    "number": "4998",
  },
  {
    "artist": "The Platters",
    "song": "(You’ve Got) The Magic Touch",
    "year": "1956",
    "seconds": "40",
    "lyric": "It rings a bell",
    "number": "4999",
  },
  {
    "artist": "The Beatles",
    "song": "You’ve Got to Hide Your Love Away",
    "year": "1965",
    "seconds": "50",
    "lyric": "Gather round, all you clowns",
    "number": "5000",
  },
  {
    "artist": "The Fortunes",
    "song": "You’ve Got Your Troubles",
    "year": "1965",
    "seconds": "50",
    "lyric": "Worried look upon your face",
    "number": "5001",
  },
  {
    "artist": "Monroe Brothers",
    "song": "You’ve Gotta Walk That Lonesome Valley",
    "year": "1936",
    "seconds": "60",
    "lyric": "In a den of hungry lions",
    "number": "5002",
  },
  {
    "artist": "The Righteous Brothers",
    "song": "You’ve Lost That Lovin’ Feelin’",
    "year": "1964",
    "seconds": "80",
    "lyric": "I get down on my knees for you",
    "number": "5003",
  },
  {
    "artist": "Blood, Sweat & Tears",
    "song": "You’ve Made Me So Very Happy",
    "year": "1969",
    "seconds": "60",
    "lyric": "Got mad and closed the door",
    "number": "5004",
  },
  {
    "artist": "Conway Twitty",
    "song": "You’ve Never Been This Far Before",
    "year": "1973",
    "seconds": "50",
    "lyric": "I taste your tender kisses",
    "number": "5005",
  },
  {
    "artist": "The Miracles",
    "song": "You’ve Really Got a Hold On Me",
    "year": "1963",
    "seconds": "50",
    "lyric": "I want to split now",
    "number": "5006",
  },
  {
    "artist": "George Jones",
    "song": "You’ve Still Got a Place in My Heart",
    "year": "1984",
    "seconds": "50",
    "lyric": "Turns his back on you",
    "number": "5007",
  },
  {
    "artist": "Ohio Express",
    "song": "Yummy Yummy Yummy",
    "year": "1968",
    "seconds": "60",
    "lyric": "I got love in my tummy",
    "number": "5008",
  },
  {
    "artist": "Beach House",
    "song": "Zebra",
    "year": "2010",
    "seconds": "40",
    "lyric": "Black and white horse",
    "number": "5009",
  },
  {
    "artist": "James Baskett",
    "song": "Zip-a-Dee-Doo-Dah",
    "year": "1946",
    "seconds": "70",
    "lyric": "My, oh, my, what a wonderful day",
    "number": "5010",
  },
  {
    "artist": "The Cranberries",
    "song": "Zombie",
    "year": "1993",
    "seconds": "50",
    "lyric": "Their tanks and their bombs",
    "number": "5011",
  }
]